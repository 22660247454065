<template>
    <div
        style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: #f8f8f8;"
    >
        <div style="position: absolute; display: flex; justify-content: flex-end; width: 100%">
            <!-- magnifier -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        style="width: 50px; height: 50px; border-radius: 8px;"
                        :style="magnifier ? 'background-color: #8CBD46 !important' : 'background-color: #eeeeee !important'"
                        x-small
                        elevation="0"
                        class="mr-4 mt-4"
                        v-on="on"
                        @click="toggleMagnifier()"
                    >
                        <img
                            :src="lupenIcon"
                            style="max-width: 25px; max-height: 25px;"
                            :alt="magnifier ? 'Lupe ausschalten (Tastaturkürzel: Alt + L)' : 'Lupe einschalten (Tastaturkürzel: Alt + L)'"
                        >
                    </v-btn>
                </template>
                <span v-if="magnifier">Lupe ausschalten (Alt + L)</span>
                <span v-else>Lupe einschalten (Alt + L)</span>
            </v-tooltip>

            <!-- keyboard -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        x-small
                        elevation="0"
                        class="mr-4 mt-4"
                        style="width: 50px; height: 50px; border-radius: 8px;"
                        :style="keyboard ? 'background-color: #8CBD46 !important' : 'background-color: #eeeeee !important'"
                        v-on="on"
                        @click="toggleKeyboard()"
                    >
                        <img
                            :src="keyboardIcon"
                            :alt="keyboard ? 'Tastatursteuerung ausschalten (Tastaturkürzel: Alt + T)' : 'Tastatursteuerung einschalten (Tastaturkürzel: Alt + T)'"
                            height="25"
                        >
                    </v-btn>
                </template>
                <span v-if="keyboard">Tastatursteuerung ausschalten (Alt + T)</span>
                <span v-else>Tastatursteuerung einschalten (Alt + T)</span>
            </v-tooltip>
        </div>

        <v-btn
            v-if="false"
            class="mt-5 mr-5 helpBtn questionmarkIcon"
            text
            fixed
            right
            top
            @click="showLoginTutorial()"
        >
            <v-img
                class="questionmarkIcon"
                :src="questionmark"
                contain
                alt="Fragezeichensymbol"
            />
        </v-btn>

        <v-form
            ref="loginForm"
            class="loginFormWrapper"
        >
            <div class="loginForm positionLoginForm">
                <!-- logo at top-->
                <div>
                    <v-img
                        :src="logo"
                        alt
                        style="width: 80%"
                        class="center mb-4"
                    />
                </div>

                <!-- login text fields -->
                <div class="loginFormInputs">
                    <div class="loginFormRow">
                        <v-text-field
                            v-model="userName"
                            required
                            autocomplete="username"
                            label="Nutzername"
                            outlined
                            light
                            hide-details
                            :disabled="showTwoFactor"
                            class="font-weight-bold"
                            color="#707070 !important"
                            @keydown="
                                (keyEvent) => {
                                    if (keyEvent.key === 'Enter') {
                                        clickLoginUser();
                                    }
                                }
                            "
                        >
                            <template v-slot:append>
                                <img
                                    :src="smileyIcon"
                                    alt
                                    style="display: block; height: 30px; margin-top: -4px; color: #747474;"
                                    @click="show2 = !show2"
                                >
                            </template>
                        </v-text-field>
                    </div>

                    <div class="loginFormRow">
                        <v-text-field
                            v-model="password"
                            required
                            autocomplete="current-password"
                            :type="show1 ? 'text' : 'password'"
                            label="Passwort"
                            outlined
                            light
                            hide-details
                            class="font-weight-bold"
                            color="#707070 !important"
                            :disabled="showTwoFactor"
                            @keydown="
                                (keyEvent) => {
                                    if (keyEvent.key === 'Enter') {
                                        clickLoginUser();
                                    }
                                }
                            "
                        >
                            <template v-slot:append>
                                <img
                                    tabindex="0"
                                    role="button"
                                    :alt="show1 ? 'Passwort ausblenden' : 'Passwort anzeigen'"
                                    :src="show1 ? eyeIcon : eyeOffIcon"
                                    style="
                    display: block;
                    height: 30px;
                    margin-top: -4px;
                    color: #747474;
                  "
                                    @click="show1 = !show1"
                                >
                            </template>
                        </v-text-field>
                    </div>

                    <div
                        v-if="showTwoFactor"
                        class="loginFormRow"
                    >
                        <v-text-field
                            v-model="twoFactorCode"
                            required
                            label="Zwei-Faktor-Authentisierung"
                            outlined
                            light
                            hide-details
                            class="font-weight-bold"
                            color="#707070 !important"
                            @keydown="
                                (keyEvent) => {
                                    if (keyEvent.key === 'Enter') {
                                        clickLoginUser();
                                    }
                                }
                            "
                        >
                            <template v-slot:append>
                                <v-icon
                                    alt
                                    style="display: block; height: 30px; margin-top: -4px; color: #747474;"
                                    @click="show2 = !show2"
                                >
                                    mdi-two-factor-authentication
                                </v-icon>
                            </template>
                        </v-text-field>
                    </div>
                </div>

                <!-- login buttons -->
                <v-row class="loginBtnWrapper mt-4">
                    <v-col
                        :class="($vuetify.breakpoint.width > 500 ? '' : 'd-flex justify-center') + ' pa-0'"
                    >
                        <v-dialog
                            v-model="qrScanLogin"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    id="qrLoginButton"
                                    aria-role="button"
                                    large
                                    outlined
                                    class="text-capitalize loginBtn"
                                    v-on="on"
                                >
                                    <img
                                        :src="qrLoginIcon"
                                        class="mr-2 icon"
                                        alt
                                    >
                                    QR-Code Login
                                </v-btn>
                            </template>
                            <v-card>
                                <!-- With v-if="qrScanLogin" will be q-r-scan-login closed properly-->
                                <!-- This is helpful when a first qr-code scan fails because of something like: backend unreachable-->
                                <!-- Because without closing it the same qr-code cannot be read 2 times in a row-->
                                <q-r-scan-login
                                    v-if="qrScanLogin"
                                    :close-function="() => { qrScanLogin = false; }"
                                    @login="qrScanLoginUser"
                                />
                            </v-card>
                        </v-dialog>
                    </v-col>
                    <v-col
                        :class="($vuetify.breakpoint.width > 500 ? 'ml-4' : 'd-flex justify-center') + ' pa-0'"
                    >
                        <v-btn
                            id="regularLoginBtn"
                            outlined
                            large
                            class="loginBtn text-capitalize"
                            @click="clickLoginUser"
                        >
                            <v-progress-circular
                                v-if="isLoading"
                                indeterminate
                                color="white"
                                size="20"
                                class="mr-2"
                            />
                            <img
                                v-else
                                :src="beitretenIcon"
                                class="mr-2 icon"
                                alt
                            >
                            Login
                        </v-btn>
                    </v-col>
                </v-row>

                <!-- stay logged in checkbox -->
                <v-row class="loginBtnWrapper">
                    <v-col>
                        <div
                            class="loginFormRow stayLoggedIn mb-0 d-flex justify-center align-center"
                        >
                            <v-checkbox
                                v-model="localStayLoggedIn"
                                class="mt-0 pt-0"
                                label="Angemeldet bleiben"
                                hide-details
                                color="#8cbe46"
                            />
                        </div>
                    </v-col>
                </v-row>

                <!-- add eklara to homescreen text -->
                <v-row id="homescreenTextWrapper">
                    <v-col
                        id="homescreenText"
                        :cols="$vuetify.breakpoint.width > 500 ? 11 : 10"
                        class="pt-0"
                    >
                        Füge die eKlara-App deinem Homescreen hinzu:
                        <a
                            href="https://www.youtube.com/watch?v=LcvvbcVRI14"
                            class="infobarLink"
                            aria-label="Füge die eKlara-App deinem Homescreen hinzu Beispielvideo"
                        >So geht's</a>
                    </v-col>
                    <!--          <v-col
              :cols="$vuetify.breakpoint.width > 500 ? 1 : 2"
              class="pa-0"
                    ></v-col>-->
                </v-row>
            </div>
        </v-form>

        <!-- supported browsers text -->
        <div class="infobar">
            <div class="infobarIcons">
                <img
                    class="infobarIcon"
                    :src="devices"
                    alt
                >
                <img
                    class="infobarIcon"
                    :src="internet"
                    alt
                >
            </div>

            <div class="infobarTexts">
                <span class="infobarText">
                    Unterstützte Internetbrowser:
                    <b>Safari, Chrome, Firefox.</b>
                </span>
                <span class="infobarText">
                    Eine
                    <b>stabile Internetverbindung</b> ist Voraussetzung.
                </span>
            </div>
        </div>

        <!--<TTS></TTS>-->
        <DatenschutzAbfrage
            v-if="displayAGB"
            :has-accepted-a-g-b="hasAcceptedAGB"
            @declinedAGB="() => { displayAGB = false }"
        />
        <!--      <DatenschutzAbfrage-->
        <!--              v-if="displayAGB"-->
        <!--              :hasAcceptedAGB="hasAcceptedAGB"-->
        <!--              @declinedAGB="() => { displayAGB = false }"-->
        <!--      />-->
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import logo from "../assets/login-logo.png";
import qrLoginIcon from "../assets/Icons/qr-code.svg";
import beitretenIcon from "../assets/Icons/beitretensvg-06.svg";
import keyboardIcon from '../assets/Icons/keyboard-24px.svg'
import lupenIcon from '../assets/Icons/lupe-18.svg'
import questionmark from "../assets/Tooltip/fragezeichen.svg";
import eyeIcon from "../assets/Icons/eye.svg";
import eyeOffIcon from "../assets/Icons/eye-off.svg";
import smileyIcon from "../assets/Icons/profile.svg";
import devices from "../assets/Icons/Login Tablet Mobil PC.svg";
import internet from "../assets/Icons/wlan.svg";

import QRScanLogin from "./QRScanLogin";
import DatenschutzAbfrage from "./Policys/DatenschutzAbfrage";
import { checkCookieAccepted, getCookie, setCookie } from '@/util/cookies';
import { AuthorizationError, ConnectionRefusedError } from "../util/error";

export default {
    name: "Login",
    components: {
        QRScanLogin,
        DatenschutzAbfrage,

        /*TTS,*/
    },
    props: {},
    data() {
        return {
            userName: "",
            password: "",
            showTwoFactor: false,
            twoFactorCode: "",
            show1: false,
            show2: false,
            qrScanLogin: false,
            isLoading: false,

            logo,
            qrLoginIcon,
            beitretenIcon,
            keyboardIcon,
            lupenIcon,
            questionmark,
            eyeIcon,
            eyeOffIcon,
            smileyIcon,
            devices,
            internet,

            showLoginTut: false,
            displayAGB: false,
            hasAcceptedAGB: false,
        };
    },
    computed: {
        ...mapGetters("auth", ["accountRole"]),
        ...mapState("auth", ["stayLoggedIn"]),
        ...mapState("magnifier", ["magnifier"]),
        ...mapState("util", ["keyboard"]),
        localStayLoggedIn: {
            get: function () {
                return this.stayLoggedIn;
            },
            set: function(newValue) {
                this.authChangeStayLoggedIn(newValue);
            }
        }
    },
    mounted() {
        // console.log('loggedIn', this.stayLoggedIn);
        const storageShowLoginTutorial = localStorage.getItem("showLoginTutorial");
        this.showLoginTut =
            storageShowLoginTutorial === null
                ? true
                : storageShowLoginTutorial === "true";
        // window.removeEventListener("keyup", this.shortcutMagnifier);
        window.addEventListener("keydown", this.handleKeyUp);
        this.closeProfileManagement();

        if (!checkCookieAccepted()) {
            this.toggleShowCookieLawWatcher();
            return;
        }
    },
    beforeDestroy() {
        window.removeEventListener("keydown", this.handleKeyUp);
    },
    methods: {
        ...mapActions("auth", {
            authLoginUser: "loginUser",
            authChangeStayLoggedIn: "changeStayLoggedIn",
        }),
        ...mapActions("auth", [ "get2FA"]),
        ...mapActions("util", ["toggleKeyboard", ""]),
        ...mapActions("magnifier", ["toggleMagnifier", "turnOffMagnifier"]),
        ...mapActions("tooltips", ["triggerShortcutTooltips"]),
        ...mapActions("util", ['loadServerTime', 'requestVersionNumber', 'toggleShowCookieLawWatcher']),
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapActions('profileManagement', ['closeProfileManagement']),

        handleKeyUp(event) {
            if (event.altKey && event.key === 't') {
                this.toggleKeyboard()
                this.triggerShortcutTooltips();
            }
            if (event.altKey && event.key === 'l') {
                this.toggleMagnifier();
                this.triggerShortcutTooltips();
            }
        },
        showLoginTutorial() {
            this.showLoginTut = true;
            localStorage.setItem("showLoginTutorial", "true");
        },

        async clickLoginUser() {
            if (!this.userName || !this.password) {
                this.showSnackbar({ message: "Nutzername oder Passwort nicht ausgefüllt ", color: "error" });
                return;
            }
            this.loginUser({ accountName: this.userName, password: this.password });
        },
        qrScanLoginUser({ accountName, password }) {
            this.loginUser({ accountName, password });
            this.qrScanLogin = false;
        },
        async loginUser({ accountName, password }) {

            this.isLoading = true;
            try {
                if (!this.twoFactorCode) {
                    const body = await this.get2FA(this.userName);
                    if(body.has2FA) {
                        this.showTwoFactor = true;
                        this.showSnackbar({ message: 'Ein gültiger Code wurde an ihre Email verschickt.', color: 'success'});
                        return;
                    }
                }
                await this.authLoginUser({ accountName, password, twoFactor: this.twoFactorCode });
                this.loadServerTime();
                this.requestVersionNumber();
                this.rerouteAccount(this.accountRole);
            } catch (err) {
                if (err instanceof ConnectionRefusedError) {
                    this.showSnackbar({ message: "Server nicht erreichbar. Haben Sie Internet? ", color: "error" });
                } else if (err instanceof AuthorizationError) {
                    this.showSnackbar({ message: "Kein Nutzer mit diesen Daten gefunden oder die Zwei-Faktor-Authentisierung war inkorrekt.", color: "error" });
                } else {
                    console.error(err);
                    this.showSnackbar({ message: "Unerwarteter Fehler.", color: "error" });
                }
            } finally {
                this.isLoading = false;
            }
        },
        async rerouteAccount(role) {
            switch (role) {
                case 'maintainer':
                    this.$router.push({ name: 'verwaltung.plan', query: this.$route.query });
                    break;
                case 'pupil':
                    this.$router.push({ name: 'schueler.plan', query: this.$route.query });
                    break;
                case 'teacher':
                    this.$router.push({ name: 'lehrer.plan', query: this.$route.query });
                    break;
                case 'parent':
                    this.$router.push({ name: 'eltern.plan', query: this.$route.query });
                    break;
                default:
                    console.error("No default role for role found", role);
                    break;
            }
        },
    },

};
</script>

<style lang="scss" scoped>
.helpBtn {
    z-index: 1;
    padding: 16px;
}

.loginBtnWrapper {
    display: flex;
    justify-content: center;
    width: 450px;
    max-width: 450px;
    margin: auto;
}

.loginBtn {
    color: white;
    background-color: #8cbd46;
    width: 100%;
}

#qrLoginButton {
    background-color: #3c3c3b;
}

.schoolBoardBackgroundWrapper {
    // https://www.w3schools.com/howto/howto_css_center-vertical.asp
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.schoolBoardBackground {
    margin-top: 5%;
    max-height: 900px;
    height: 90%;
}

.loginFormWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    // align-content: center;
    align-items: center;
    // overflow-x: visible;
    // box-sizing: contet-box;
}

.loginFormWrapper::before {
    display: block;
    width: 0;

    content: "";
    flex: 1 1 0;
    background-color: red;
}

.loginFormWrapper::after {
    width: 0;
    content: "";
    flex: 1 1 350px;
}

.loginForm {
    max-width: 600px;
    min-width: 100px;
    margin: 0 auto;
    padding: 28px;
    background-color: transparent;
    z-index: 1;
    flex: 0 0 auto;
}

.positionLoginForm {
    margin: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loginFormInputs {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginFormRow {
    width: 450px;
    max-width: 450px;
    margin-bottom: 15px;
}

#homescreenTextWrapper {
    display: flex;
    justify-content: center;
    max-width: 450px;
    margin: auto;
}

#homescreenText {
    font-size: small;
    vertical-align: bottom;
    text-align: center;
    line-height: 15px;
}

.stayLoggedIn {
    height: 35px;
}

#questionmark-container {
    position: fixed;
    top: 0;
    right: 0;
}

.colorSchueler {
    color: $schueler-blau;
    margin: auto;
}

.marginAuto {
    margin: auto;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.icon {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7458%)
        hue-rotate(61deg) brightness(109%) contrast(111%);
}

.questionmarkIcon {
    height: 5rem;
    width: 5rem;
}
.infobar {
    position: fixed;
    bottom: 16px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.infobarTexts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.infobarText {
    font-size: small;
    vertical-align: bottom;
    margin-left: 3px;
    margin-right: 3px;
    text-align: center;
    line-height: 20px;
}
.infobarIcons {
    display: flex;
    align-items: center;
}

.infobarIcon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.infobarLink {
    font-size: small;
    line-height: 30px;
}

@media only screen and (max-height: 400px) {
    .positionLoginForm {
        top: 50%;
    }
}

@media only screen and (max-width: 500px) {
    .helpBtn {
        top: 1%;
        right: 1%;
    }

    .loginForm {
        width: 300px;
    }

    .loginFormRow {
        width: 250px;
    }

    .loginBtnWrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 250px;
    }

    .loginBtn {
        width: 210px;
    }

    #regularLoginBtn {
        margin-top: 12px;
    }

    .infobarIcon {
        width: 20px;
        height: 20px;
    }
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}

@media only screen and (max-width: 850px), screen and (max-height: 600px) {
    .schoolBoardBackgroundWrapper {
        display: none;
    }

    .schoolBoardBackground {
        display: none;
    }

    .questionmarkIcon {
        height: 2.5rem;
        width: 2.5rem;
    }
}

@media all and (orientation: portrait) {
    .schoolBoardBackgroundWrapper {
        display: none;
    }

    .schoolBoardBackground {
        display: none;
    }
    // .infobar {
    //   position: fixed;
    //   bottom: 8px;
    //   margin-left: auto;
    //   margin-right: auto;
    //   flex-wrap: wrap;
    //   z-index: 1;
    //   display: flex;
    //   justify-content: center;
    //   width: 100%;

    // }
    // .infobarText{
    //   font-size: small;
    //   min-width: 250px!important;
    // }
    // .infobarIcons{
    //   display: flex;
    // }
    // .infobarIcon{
    //   max-width: 30px;
    //   margin-bottom: 15px;
    //   margin-right: 10px;
    // }
}
</style>
