<template>
    <div>
        <v-row class="mx-0">
            <div
                class="whiteBg"
                style="height: auto; min-width: 100px !important; width: 100%; min-height: 80vh; max-height: 80vh; border-radius: 15px; box-shadow: 1px 5px 5px silver; margin: auto auto 5em;"
            >
                <!-- header -->
                <div style="background-color: var(--v-gelb-base); border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white;">
                    <v-row
                        style="max-width: 100%; display: flex; align-items: center; height: 35px"
                        class="mx-2"
                    >
                        <v-col
                            cols="9"
                            md="8"
                            lg="9"
                            style="white-space: nowrap;"
                            class="pa-0 pl-2 font-style d-flex align-center noselect"
                        >
                            <div
                                v-if="shop === 'raabe' || shop === 'klett' || shop === 'ether'"
                                class="pointer d-flex flex-row align-center"
                            >
                                <v-tooltip
                                    :disabled="!showTooltips"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            id="backButton"
                                            color="white"
                                            fab
                                            text
                                            small
                                            dark
                                            v-on="on"
                                            @click="shop = ''"
                                        >
                                            <img
                                                :src="pfeilIcon"
                                                alt="Zurück"
                                                class="icon iconToWhite"
                                                style="transform: rotate(90deg); height: 20px;"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Zurück</span>
                                </v-tooltip>
                            </div>

                            <!-- <div class="pointer d-flex flex-row align-center" @click="shop = ''" v-if="shop === 'raabe' || shop === 'klett' || shop === 'ether'">
                                <img :src="linksIcon" alt="" class="iconToWhite mr-1" style="height: 20px;"/>
                                <p class="mr-4">Zurück</p>
                            </div> -->

                            <img
                                v-else
                                :src="fileIcon"
                                alt=""
                                class="iconToWhite mr-1"
                                style="height: 20px;"
                            >

                            <h1
                                v-if="shop === 'raabe'"
                                tabindex="0"
                                class="mb-0"
                                style="font-weight: bold"
                            >
                                Raabe
                            </h1>
                            <h1
                                v-else-if="shop === 'klett'"
                                tabindex="0"
                                class="mb-0"
                                style="font-weight: bold"
                            >
                                Klett
                            </h1>
                            <h1
                                v-else-if="shop === 'ether'"
                                tabindex="0"
                                class="mb-0"
                                style="font-weight: bold"
                            >
                                Etherpad
                            </h1>
                            <h1
                                v-else
                                tabindex="0"
                                class="mb-0"
                            >
                                Weitere Funktionen
                            </h1>
                        </v-col>
                        <v-col
                            cols="3"
                            md="4"
                            lg="3"
                            class="pa-0 pr-2 d-flex align-center justify-end"
                        />
                    </v-row>
                </div>

                <!-- body -->
                <div
                    class="mt-2 mx-4"
                    style="max-height: 70vh;"
                    :style="shop === 'raabe' || shop === 'klett' ? 'overflow-y: scroll' : ''"
                >
                    <!-- tiles -->
                    <div
                        v-if="shop === ''"
                        class="d-flex flex-row justify-space-between"
                    >
                        <!--                        <div @click="shop = 'raabe'" style="width: 100%" class="pointer">
                                                    <div class="iconDiv">
                                                        <img :src="raabeLogo" class="logoStyle" alt="Logo von Raabe Verlag">
                                                    </div>
                                                    <p class="text-center mt-2">Raabe</p>
                                                </div>

                                                <div @click="shop = 'klett'" class="pointer ml-4" style="width: 100%">
                                                    <div class="iconDiv">
                                                        <img :src="klettLogo" class="logoStyle" alt="Logo von Klett Verlag">
                                                    </div>
                                                    <p class="text-center mt-2">Klett</p>
                                                </div>-->
                        <button
                            class="iconDiv"
                            style="width: 30%"
                            @click="shop = 'ether'"
                        >
                            <img
                                :src="etherpadLogo"
                                class="logoStyle"
                                alt="Etherpad öffnen"
                            >
                            <span class="text-center mt-2">Etherpad</span>
                        </button>
                    </div>
                </div>

                <!-- tile content -->
                <div class="d-flex justify-center">
                    <a
                        v-if="shop === 'ether'"
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://schule2go.de:9002/"
                    >Neues Pad erstellen</a>

                    <div v-else-if="shop === 'raabe' || shop === 'klett'">
                        <a
                            v-if="shop === 'raabe'"
                            target="_blank"
                            rel="noreferrer noopener"
                            href="https://www.raabe.de/unterrichtsmaterial/sonstige/grundschule/?p=1&o=2&n=12"
                        >Zum Raabe-Shop</a>
                        <img
                            v-if="shop === 'raabe'"
                            :src="raabeShop"
                            style="width: 100%"
                            alt=""
                        >
                        <img
                            v-if="shop === 'raabe'"
                            :src="raabeShop"
                            style="width: 100%"
                            alt=""
                        >

                        <a
                            v-if="shop === 'klett'"
                            target="_blank"
                            rel="noreferrer noopener"
                            href="https://shop.klett-lerntraining.de/shop/grundschule"
                        >Zum Klett-Shop</a>
                        <img
                            v-if="shop === 'klett'"
                            :src="klettShop"
                            style="width: 100%"
                            alt=""
                        >
                        <img
                            v-if="shop === 'klett'"
                            :src="klettShop"
                            style="width: 100%"
                            alt=""
                        >
                    </div>
                </div>
            </div>
        </v-row>
    </div>
</template>

<script>
import klettLogo from "../assets/Bilder/klett.png"
import raabeLogo from "../assets/Bilder/raabeSquare.png"
import etherpadLogo from "../assets/Bilder/EtherpadLogo.svg"
import raabeShop from "../assets/Bilder/raabeShop.png"
import klettShop from "../assets/Bilder/klettShop.png"

import fileIcon from '../assets/Icons/leere-korrektur-78.svg'
import linksIcon from '../assets/Icons/links-10.svg';
import pfeilIcon from '../assets/Icons/unten-dropdown-filled-12.svg'
import {mapState} from "vuex";

export default {
    name: "Verlag",
    components: {},
    data() {
        return {
            etherpadLogo,
            raabeShop,
            klettShop,

            fileIcon,
            pfeilIcon,

            shop: '',
        }
    },
    computed: {
        ...mapState("tooltips", [ "showTooltips" ]),
    },
}
</script>

<style lang="scss" scoped>

.pointer {
    cursor: pointer;
}

.logoStyle {
    width: 99%;
    border-radius: 20px;
}

.iconDiv {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 1px 5px 5px silver;
    padding: 14px;
    min-width: 100px;
}

.whiteBg {
    background-color: #ffffff;
}

//make text not selectable
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

h1 {
    display: inherit;
    font-size: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    margin-right: inherit;
    font-weight: inherit;
}

</style>
