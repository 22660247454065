<template>
    <v-dialog
        v-model="showDeleteDialog"
        overlay-opacity="0.8"
        persistent
        max-width="400"
    >
        <v-card>
            <v-card-title class="headline">
                <p
                    tabindex="0"
                    class="mb-0"
                >
                    Nachricht löschen?
                </p>
            </v-card-title>
            <v-card-text>
                <p
                    tabindex="0"
                    class="mb-0"
                    style="width: fit-content"
                >
                    Wollen Sie diese Nachricht wirklich löschen?
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer />

                <v-btn
                    color="#bdbdbd"
                    class="optionBtn text-none"
                    style="color: white"
                    @click="closeDeleteDialog"
                >
                    <img
                        :src="cancelIcon"
                        class="icon iconToWhite mr-2"
                        alt="abbrechen"
                    >
                    Abbrechen
                </v-btn>
                <v-btn
                    color="rot"
                    class="optionBtn text-none"
                    style="color: white"
                    @click="clickDelete"
                >
                    <img
                        :src="finishIcon"
                        class="icon iconToWhite mr-2"
                        alt="bestätigen"
                    >
                    Löschen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import * as backend from "../../../../api/backend";

import { mapGetters, mapMutations, mapState } from "vuex";

import cancelIcon from "@/assets/Icons/abbrechen-08.svg";
import finishIcon from '@/assets/Icons/fertig-74.svg';

export default {
  name: "ChatRoomEventListItemDeleteDialog",
  components: {},
  props: {
    chatRoomEvent: { required: true, type: Object },
  },
  data: () => ({
    cancelIcon,
    finishIcon,

    showDeleteDialog: true,
  }),
  computed: {
    ...mapMutations("snackbar", ["showSnackbar"]),
  },
  async unmounted() {
    if (this.loadedAudio) URL.revokeObjectURL(this.loadedAudio);
    if (this.loadedTeacherPic) URL.revokeObjectURL(this.loadedTeacherPic);
    if (this.loadedImage) URL.revokeObjectURL(this.loadedImage);
  },
  methods: {
    clickDelete() {
      this.deleteMessage();
      this.closeDeleteDialog();
    },
    async deleteMessage() {
      try {
        await backend.redactChatRoomEvent(this.chatRoomEvent._id);
      } catch {
        this.showSnackbar({ message: "Löschen der Nachricht fehlgeschlagen", color: 'error'})
      }
    },
    closeDeleteDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>


<style lang="scss" scoped>
.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}

.headlineText {
    color: var(--v-dunkelgrau-base);
    margin-bottom: 0;
    font-weight: bold;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.icon {
    width: 15px;
    height: 15px;
}
</style>