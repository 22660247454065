<template>
    <div style="position: relative">
        <!-- Folder Edit TODO: Find a more beautiful solution than those two -->
        <div v-if="newFolders.length">
            <FileSystemListElement
                :type="'folder'"
                :item="newFolders[0]"
                :folder-id="currentFolderId"
                :folder-type="folderType"
                :screen-x="screenX"
                :screen-y="screenY"
                @canceledEditing="cancelFolderCreation"
                @folderEdited="(newFolderCreated) => createFolder(newFolders[0], newFolderCreated)"
            />
        </div>
        <div v-if="patchingFile">
            <FileSystemListElement
                :type="'file'"
                :item="patchingFile"
                :folder-id="currentFolderId"
                :folder-type="folderType"
                :screen-x="screenX"
                :screen-y="screenY"
                @canceledEditing="cancelPatchUpload(patchingFile)"
                @folderEdited="patchFolderUpload(patchingFile)"
            />
        </div>
        <!-- <div v-if="patchingFile || newFolders.length">
            <div
                id="hintBox"
                class="mt-2 px-8"
            >
                <v-icon
                    class="px-1"
                    style="font-size: 15px"
                >
                    fas fa-question
                </v-icon>
                <p
                    class="pl-1"
                    style="font-size: 12px"
                >
                    Wähle unten den gewünschten Zielordner für das Verschieben der
                    Datei und drücke dann das Häkchen rechts neben der Datei.
                </p>
            </div>
            <v-divider />
        </div> -->

        <!-- Header Row with Folder Path and Space Indicator-->
        <div
            class="px-7 pt-2"
            style="width: 100%"
        >
            <!-- Path -->
            <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                <div
                    class="directoryPath"
                    style="min-height: 25px"
                    tabindex="0"
                    @click="initRootDir"
                    @keypress.enter="initRootDir"
                >
                    <v-icon>mdi-folder-home</v-icon>
                    <span
                        style="margin-left: 2px; margin-right: 2px"
                    >{{ folderType === 'privateFolder' ? 'Privater Ordner' : folderType === 'teacherFolder' ? 'Lehrer Ordner' : 'Gruppen Ordner' }}</span>
                </div>
                <span>/</span>
                <div
                    v-for="(el, index) in folderPath"
                    :key="el._id + index + 'teacher'"
                    style="display: flex; flex-direction: row"
                >
                    <div
                        class="directoryPath"
                        @click="diveInFolderFromHeader(el._id)"
                    >
                        <v-icon style="margin-right: -2px; margin-left: 1px">
                            mdi-folder
                        </v-icon>
                        <span style="margin-left: 2px; margin-right: 2px">{{ el.name }}</span>
                    </div>
                    <span>/</span>
                </div>
            </div>
        </div>

        <div
            v-for="(el, index) in filesAndFolders()"
            :key="el._id + index + 'teacher'"
            :style="'width: ' + vfsGridWidthPct + '%; float: left'"
            style="position: relative"
        >
            <FileSystemListElement
                :type="el.type"
                :index="index"
                :item="el"
                :folder-type="folderType"
                :folder-id="currentFolderId"
                :screen-x="screenX"
                :screen-y="screenY"
                :scroll-top="scrollTop"
                :debug-mode="debugMode"
                :vfs="vfs"
                :view-file-function="() => { $emit('viewFile', el) }"
                @openFolder="() => diveInFolder(el._id)"
                @openDeleteDialog="() => openDeleteDialog(el)"
                @startedEditing="() => startedEditing(el)"
                @canceledEditing="canceledEditing(el)"
                @folderEdited="(newFolderCreated) => folderEdited(el, newFolderCreated)"
                @download="clickDownloadFolderUpload"
                @notifyMoveFile="() => notifyMoveFile(el, 'private')"
                @loadFullscreenPreview="loadFullscreenPreview(el)"
                @objectURL="(url) => FSLE_URL_Collection.push(url)"
                @publishDialog="openPublishDialog(el)"
                @openSubmissions="() => openSubmissions(el)"
                @updateVFS="(el) => vfs = el"
            />
        </div>
        <span
            style="visibility: hidden"
            min-height="500px"
        >
            .
            <br>.
            <br>.
            <br>.
            <br>.
            <br>.
            <br>.
            <br>.
            <br>.
            <br>.
            <br>
        </span>

        <!-- popups, overlays and snackbars -->
        <v-dialog
            v-model="deleteDialog"
            max-width="350px"
        >
            <v-card>
                <v-card-title>Löschen bestätigen</v-card-title>

                <v-card-text>{{ deleteInfoText }}</v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="rot"
                        dark
                        @click="confirmDelete"
                    >
                        <img
                            :src="recycleBinIcon"
                            style="height: 20px; filter: brightness(1000%)"
                            class="mr-2"
                        >
                        Löschen
                    </v-btn>

                    <v-btn
                        class="mr-2 text-capitalize"
                        color="gruen"
                        dark
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            style="height: 20px; filter: brightness(1000%)"
                            class="mr-2"
                        >
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Fullscreen Preview Popup -->
        <v-dialog
            v-model="fullscreenPreviewEnabled"
            scrollable
            style="overflow: hidden"
            transition="dialog-bottom-transition"
            max-width="80vw"
            @keydown="handleKeydown"
        >
            <div
                id="previewDialog"
                ref="previewDialog"
                style="display: flex; flex-direction: column"
            >
                <div
                    style="position: sticky; top: 0px; display:flex; justify-content: space-between; align-items: center; background-color: white; z-index: 10; height: 7vh"
                    width="100%"
                    class="pa-5"
                >
                    <v-btn
                        class="textTransformNone"
                        style="background-color: #ffffff !important; elevation: 0; box-shadow: none; cursor: default"
                        :ripple="false"
                    >
                        <v-icon>mdi-file</v-icon>
                        <span>{{ previewSelection.uploadedName }}</span>
                    </v-btn>
                    <v-btn
                        style="background-color: #ffffff !important; elevation: 0; box-shadow: none"
                        @click="() => { fullscreenPreviewEnabled = false }"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card style="background-color: var(--v-dunkelgrau-base); overflow: auto">
                    <v-card-text
                        :style="previewPDF ? 'display: flex; justify-content: space-around' : ''"
                    >
                        <!-- <div class="text-h2 pa-12">{{ previewSelection }}</div> -->
                        <v-img
                            v-if="!previewPDF"
                            :src="previewURL"
                            contain
                            aspect-ratio="1.4"
                            :alt="previewSelection.name || previewSelection.uploadedName"
                            :lazy-src="img_eklaraLogo"
                        >
                            <div class="fill-height bottom-gradient" />
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height fill-width ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey"
                                    />
                                </v-row>
                            </template>
                        </v-img>
                        <object
                            v-else
                            :data="previewURL + '#toolbar=0&navpanes=0&scrollbar=0&view=Fit'"
                            style="height: 80vh; width: 80vw"
                        />
                    </v-card-text>
                </v-card>
                <div
                    style="position: sticky; bottom: 0px; display:flex; justify-content: space-between; align-items: center; background-color: white; height: 7vh"
                    width="100%"
                    class="pa-5"
                >
                    <v-btn
                        class="textTransformNone"
                        style="background-color: #ffffff !important; elevation: 0; box-shadow: none"
                        @click="cyclePreview(-1)"
                    >
                        <v-icon>mdi-chevron-left</v-icon>
                        <span v-if="screenX > 1080">Vorheriges Dokument</span>
                    </v-btn>
                    <v-btn
                        v-if="(folderType === 'privateFolder' || (folderType === 'groupFolder' && accountRole === 'teacher')) && validMimetype"
                        class="textTransformNone"
                        :x-small="screenX < 450"
                        style="background-color: #F49F31"
                        dark
                        @click="openEditor"
                    >
                        <span v-if="screenX > 600">Im Editor öffnen</span>
                        <v-icon v-if="screenX <= 600">
                            mdi-open-in-app
                        </v-icon>
                    </v-btn>
                    <v-btn
                        v-if="folderType === 'groupFolder' && validMimetype && isTask(this.previewSelection) && accountRole === 'teacher'"
                        class="textTransformNone"
                        :x-small="screenX < 450"
                        style="background-color: #F49F31"
                        dark
                        @click="openSubmissions(previewSelection)"
                    >
                        <span v-if="screenX > 600">Abgaben öffnen</span>
                        <v-icon v-if="screenX <= 600">
                            mdi-folder-open
                        </v-icon>
                    </v-btn>
                    <v-btn
                        v-if="folderType === 'groupFolder' && validMimetype && isTaskActive(this.previewSelection) && accountRole === 'pupil'"
                        class="textTransformNone"
                        :x-small="screenX < 450"
                        style="background-color: #F49F31"
                        dark
                        @click="openEditor"
                    >
                        <span v-if="screenX > 600">Aufgabe bearbeiten</span>
                        <v-icon v-if="screenX <= 600">
                            mdi-brush
                        </v-icon>
                    </v-btn>
                    <div v-else-if="folderType === 'teacherFolder' || !validMimetype">
                        <v-btn
                            class="textTransformNone"
                            :small="screenX < 450"
                            style="background-color: #F49F31"
                            dark
                            @click="clickDownloadFolderUpload(previewSelection)"
                        >
                            <span v-if="screenX > 800">Herunterladen</span>
                            <v-icon :style="screenX > 800 ? 'margin-left: 10px' : ''">
                                mdi-download
                            </v-icon>
                        </v-btn>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    class="textTransformNone"
                                    :small="screenX < 450"
                                    style="background-color: #F49F31; position: relative; margin-left: 10px"
                                    dark
                                    v-on="on"
                                    @click="notifyMoveFile(previewSelection)"
                                >
                                    <span v-if="screenX > 600">Privat kopieren</span>
                                    <v-icon
                                        :style="screenX > 600 ? 'margin-left: 10px' : ''"
                                    >
                                        mdi-file-move-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>
                                Eine Kopie der Datei wird in ihrem privaten Ordner hinterlegt.
                                <br>So können auch eklara-Dokumente im Editor geöffnet werden.
                            </span>
                        </v-tooltip>
                    </div>
                    <v-btn
                        class="textTransformNone"
                        style="background-color: #ffffff !important; elevation: 0; box-shadow: none"
                        @click="cyclePreview(1)"
                    >
                        <span v-if="screenX > 1080">Nächstes Dokument</span>
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-dialog>

        <!-- Publishing dialog -->
        <PublishDocumentToDocRegDialog
            v-if="showPublishDialog"
            :document-details="documentToPublish"
            @close="() => { showPublishDialog = false; documentToPublish = null; }"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import recycleBinIcon from '../assets/Icons/papierkorb-110.svg'
import cancelIcon from "../assets/Icons/abbrechen-08.svg";
import img_eklaraLogo from '@/assets/EditorLibraryMock/eklara_logo.png';
import FileSystemListElement from "@/components/FileArchive/FileSystemListElement";
import PublishDocumentToDocRegDialog from "@/components/FileArchive/PublishDocumentToDocRegDialog";

export default {
    name: "VirtualFileSystem",
    components: {PublishDocumentToDocRegDialog, FileSystemListElement },
    props: {
        folderType: { required: true, type: String },
        folderDepth: { required: true, type: Number },
        refreshToggle: { required: false, type: String, default: '' },
        uploadLocalFileFunction: { required: false, type: Function },
        uploadFileFromSharedFunction: { required: false, type: Function },
        uploadFileFromPrivateFunction: { required: false, type: Function },
        scrollTop: { required: true, type: Number, default: 0 },
        searchResult: { required: false, type: Object },

        initalFolderIdTree: { required: false, type: Array }
    },
    data: () => ({
        folders: [],
        newFolders: [], // For displaying newly created Folders in the same list
        files: [],
        sortedAllElements: [],
        folderIdTree: [],
        currentFolderId: "",
        folderName: "",
        vfs: null,

        deleteDialog: false,
        elementToDelete: null,
        deleteMethod: '',
        deleteInfoText: '',

        patchingFile: null,
        sortAlphabeticalDescending: false,
        displayingSortResult: false,
        validMimetype: true,
        previewPDF: false,

        traveledFromPath: false,

        fullscreenPreviewEnabled: false,
        previewSelection: { name: 'Vorschau' },
        previewSelectionIndex: 0,
        previewURL: null,

        FSLE_URL_Collection: [],

        // Publishing Stuff
        documentToPublish: null,
        showPublishDialog: false,
        debugMode: false,

        //  Icons
        recycleBinIcon,
        cancelIcon,
        img_eklaraLogo,

        screenX: 1920,
        screenY: 1080,
    }),
    computed: {
        ...mapState("auth", ["token"]),
        ...mapGetters('auth', ['accountRole', 'accountId']),
        ...mapGetters('util', ['currentUploadGroup']),
        ...mapGetters('vfs', ['privateFolderSortDescending', 'teacherFolderSortDescending', 'groupFolderSortDescending', 'vfsGridWidthPct']),

        shownItems() {
            return this.folders;
        },
        folderPath() {
            let path = [];
            for (let i = 0; i < this.folderIdTree.length; i++) {
                if (!this.folderIdTree[i].root) {
                    path.push(this.folderIdTree[i]);
                }
            }
            return path;
        },
    },
    watch: {
        privateFolderSortDescending: {
            handler(val, oldVal) {
                if (this.folderType === 'privateFolder') {
                    this.sortAlphabeticalDescending = this.privateFolderSortDescending;
                }
            }
        },
        teacherFolderSortDescending: {
            handler(val, oldVal) {
                if (this.folderType === 'teacherFolder') {
                    this.sortAlphabeticalDescending = this.teacherFolderSortDescending;
                }
            }
        },
        groupFolderSortDescending: {
            handler(val, oldVal) {
                if (this.folderType === 'groupFolder') {
                    this.sortAlphabeticalDescending = this.groupFolderSortDescending;
                }
            }
        },

        searchResult() {
            if (this.displayingSortResult) {
                this.leaveCurrentFolder();
            } else {
                this.$emit('mutateFolderDepth', this.folderDepth + 1);
            }
            this.displayingSortResult = true;
            this.vfs = this.searchResult;
            this.folderIdTree.push(this.vfs);
            this.folders = this.vfs.folders;
            this.files = this.vfs.files;
            // dont parse these because we navigate towards a temporary folder
            // all actions should pretend to be in previous folder
            // this.currentFolderId = this.vfs._id;
            // this.notifyFolderSelection();
        },
        currentUploadGroup(newVal) {
            if (!newVal) {
                this.refreshCurrentFolder();
            }
        },
        refreshToggle(newVal) {
            if (newVal === this.folderType) {
                this.refreshCurrentFolder();
            }
        },
        // this watcher actually made me go insane looking for weird behaviour
        // when implementing code to jump larger distances inside the folderTree
        folderDepth(newVal, oldVal) {
            if (newVal - oldVal === -1) {
                if (this.traveledFromPath) {
                    this.traveledFromPath = false;
                    return;
                }
                this.leaveCurrentFolder();
            }
        },
    },
    mounted() {

        this.initRootDir();
        this.filesAndFolders();
        this.evalScreenSize();

        this.debugMode = this.$route.hash === '#debug';
    },
    methods: {
        ...mapActions("teacherFolder", [
            "getVFSFolderUploads",
            "getVFSFolderUpload",
            "createVFSFolder",
            "deleteVFSFolderUpload",
            "updateVFSFolder",
            "updateVFSFolderUpload",
            "deleteVFSFolder"
        ]),
        ...mapMutations('vfs', ['setPrivateFolderSelection', 'setTeacherFolderSelection', 'setGroupFolderSelection', 'setVfs']),
        ...mapActions('subjects', ['getThumbnail']),
        ...mapMutations('snackbar', [ 'showSnackbar' ]),

        folderEdited(el, newFolderCreated) {
            if (el.type === 'file') {
                this.patchFolderUpload(el);
            } else if (el.type === 'folder') {
                this.createFolder(el, newFolderCreated);
            }
        },

        canceledEditing(el) {
            if (el.type === 'file') {
                this.cancelPatchUpload(el);
            } else if (el.type === 'folder') {
                this.cancelFolderCreation(el)
            }
        },

        startedEditing(el) {
            if (el.type === 'file') {
                this.startPatchUpload(el);
            } else if (el.type === 'folder') {
                this.switchUIToEditMode(el)
            }
        },

        openSubmissions(el) {
            this.$emit('mutateFolderDepth', this.folderDepth + 1);
            let taskFolder = this.vfs.sharing_Tasks.find(task => task.file == el._id);
            taskFolder = taskFolder.submissions.filter((element) => {
                return (this.accountRole === 'teacher' || (this.accountRole === 'pupil' && element.account == this.accountId));
            });
            taskFolder = taskFolder.map((el) => el.file);
            this.vfs = {
            //  create something from sharing_Tasks.submissions[sharing_Tasks.file == clicked file]
                files: taskFolder,
                folders: [],
                root: false,
                name: 'Abgaben',
                // identifies the vfs for a special ruleset
                // don't allow menus that perform any modification to the submitted work
                rulesetTask: true,
            }
            this.folderIdTree.push(this.vfs);
            this.folders = this.vfs.folders;    
            this.files = this.vfs.files;
            this.fullscreenPreviewEnabled = false;
        },

        isTask(el) {
            // loading the initial vfs might be staggered but only in the root directory
            // where the tasks dont matter yet
            if (!this.vfs) {
                return false;
            }
            if (!this.vfs.sharing_Tasks) {
                return false;
            }
            return !!this.vfs.sharing_Tasks.find((task) => {
                // console.log(`evaluating task file ${task.file} against caller el ${el._id} ${task.file == el._id}`);
                return task.file == el._id;
            })
        },

        // for pupil, file has to be marked activeTask to be opened in Editor
        isTaskActive(el) {
            // loading the vfs might be slow but only in root directory
            // where the tasks dont matter yet
            if (!this.vfs) {
                return false;
            }
            if (!this.vfs.sharing_Tasks) {
                return false;
            }
            let found = this.vfs.sharing_Tasks.find((task) => {
                // console.log(`evaluating task file ${task.file} against caller el ${el._id} ${task.file == el._id}`);
                return task.file == el._id;
            });
            if (found) {
                return found.activeTask;
            } else {
                return false;
            }
        },


        filesAndFolders() {
            let all = [];
            let folders = [...this.folders];
            let files = [...this.files];

            if (!this.displayingSortResult) {
                folders.sort((a, b) => {
                    a = a.name.toLowerCase();
                    b = b.name.toLowerCase();
                    if (this.sortAlphabeticalDescending) {
                        return (a < b) ? 1 : (a > b) ? -1 : 0;
                    } else {
                        return (a < b) ? -1 : (a > b) ? 1 : 0;
                    }
                })
                files.sort((a, b) => {
                    a = (a.originalname || a.name).toLowerCase();
                    b = (b.originalname || b.name).toLowerCase();
                    if (this.sortAlphabeticalDescending) {
                        return (a < b) ? 1 : (a > b) ? -1 : 0;
                    } else {
                        return (a < b) ? -1 : (a > b) ? 1 : 0;
                    }
                })
            }

            for (let i = 0; i < folders.length; i++) {
                folders[i].type = 'folder';
                all.push(folders[i]);
            }

            for (let i = 0; i < files.length; i++) {
                files[i].type = 'file';
                all.push(files[i]);
            }

            // Restocde für Usecase, wenn man will, dass Ordner und Files untereinander gemischt werden
            // if(this.sortAlphabetical === 'ascending' || this.sortAlphabetical === 'descending') {
            //     all.sort((a, b) => {
            //         if(a.type === 'folder') {
            //             a = a.name.toLowerCase();
            //         } else {
            //             a = a.originalname.toLowerCase();
            //         }
            //         if(b.type === 'folder') {
            //             b = b.name.toLowerCase();
            //         } else {
            //             b = b.originalname.toLowerCase();
            //         }
            //         if(this.sortAlphabetical === 'ascending') {
            //             return (a < b) ? -1 : (a > b) ? 1 : 0;
            //         } else if(this.sortAlphabetical === 'descending') {
            //             return (a < b) ? 1 : (a > b) ? -1 : 0;
            //         }
            //     })
            // }

            if (this.folderType === 'privateFolder') {
                this.setPrivateFolderSelection(all.length);
            } else if (this.folderType === 'teacherFolder') {
                this.setTeacherFolderSelection(all.length);
            } else if (this.folderType === 'groupFolder') {
                this.setGroupFolderSelection(all.length);
            }
            this.sortedAllElements = all;
            return all;
        },

        loadFullscreenPreview(el) {

            this.previewSelection = el;
            for (let i = 0; i < this.sortedAllElements.length; i++) {
                if (el == this.sortedAllElements[i]) {
                    this.previewSelectionIndex = i;

                }
            }
            this.fullscreenPreviewEnabled = true;
            this.initThumbnail();
            setTimeout(() => {
                this.$refs.previewDialog.focus();
            }, 150);
        },

        /**
         * Toggles the dialog for publishing to document registry,
         * and sets publishable document
         * @param el publishable document
         */
        openPublishDialog(el) {
            this.documentToPublish = el;
            this.showPublishDialog = true;
        },

        // to prevent memory leaks, enforces a limit on maximum available URLs
        // generated through FileSystemListElements
        // this should be called whenever diving or leaving a folder
        clean_URLCollection() {
            for (let i = this.FSLE_URL_Collection.length; i > 15; i--) {
                URL.revokeObjectURL(this.FSLE_URL_Collection.shift());
            }
        },

        async initThumbnail() {
            this.validateMimetype();
            if (this.previewURL && this.previewURL !== img_eklaraLogo) {
                URL.revokeObjectURL(this.previewURL);
            }
            // request thumbnail identified by fileId
            let thumb = await this.getThumbnail(this.previewSelection._id);
            this.previewPDF = false;

            if (thumb) {
                this.previewURL = URL.createObjectURL(thumb);
                if (thumb.type === 'application/pdf') {
                    this.previewPDF = true;
                }
            } else {
                this.previewURL = img_eklaraLogo;
            }
        },

        cyclePreview(direction) {
            this.previewSelectionIndex += direction;
            if (this.previewSelectionIndex < 0) {
                this.previewSelectionIndex = this.sortedAllElements.length - 1;
            } else
                if (this.previewSelectionIndex >= this.sortedAllElements.length) {
                    this.previewSelectionIndex = 0;
                }
            this.previewSelection = this.sortedAllElements[this.previewSelectionIndex];
            if (this.previewSelection.type !== 'file') {
                this.cyclePreview(direction);
                return;
            }
            this.initThumbnail();
        },

        validateMimetype() {
            if (['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(this.previewSelection.uploadedName.split('.').pop().toLowerCase())) {
                this.validMimetype = true;
            } else {
                this.validMimetype = false;
            }
        },

        openEditor() {
            let params = {};
            if (this.folderType === 'groupFolder' && this.accountRole === 'pupil' && this.isTask) {
                // params get parsed to the open-Editor call, this sets the route-query up to inform the editor
                params.sharing = true;
                // parse the current vfs to the store and let editor retrieve relevant information
                this.setVfs(this.vfs);
            }
            this.$emit('viewFile', this.previewSelection, params);
        },

        evalScreenSize() {
            let win = window;
            let doc = document;
            let docElem = doc.documentElement;
            let body = doc.getElementsByTagName('body')[0];
            this.screenX = win.innerWidth || docElem.clientWidth || body.clientWidth;
            this.screenY = win.innerHeight || docElem.clientHeight || body.clientHeight;
            // if(this.screenX < 800 || this.screenY < 800) {
            //     this.smallUI = true;
            // }
            // alert(this.screenX + ' × ' + this.screenY);
        },

        handleKeydown(event) {
                        switch (event.code) {
                case 'ArrowLeft':
                    this.cyclePreview(-1);
                    break;
                case 'KeyA':
                    this.cyclePreview(-1);
                    break;
                case 'ArrowRight':
                    this.cyclePreview(1);
                    break;
                case 'KeyD':
                    this.cyclePreview(1);
                    break;
                case 'Enter':
                    if (this.validMimetype) {
                        this.openEditor();
                    }
                    break;
                case 'Space':
                    if (this.validMimetype) {
                        this.openEditor();
                    }
                    break;
                default:
                    break;
            }
        },

        async notifyFolderSelection(direction) {
            if (!direction && direction != 0) {
                direction = 1;
            }
            this.$emit("currentFolder", this.vfs, direction);
        },

        async notifyMoveFile(el) {
            let destination = 'teacher';
            if (this.folderType === 'teacherFolder') {
                destination = 'private';
            }
            this.$emit("notifyMoveFile", el, destination);
        },

        async clickDownloadFolderUpload(file) {
                        const res = await this.getVFSFolderUpload(
                file._id
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = file.uploadedName;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    setTimeout(function () {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                });
        },

        async initRootDir() {

            if (!this.initalFolderIdTree) {
                // No path to inital folder has been given so load root
                // use default root folder
                this.folderIdTree = [];
                this.vfs = await this.getVFSFolderUploads({ folderType: this.folderType });
                this.files = this.vfs.files;
                this.folders = this.vfs.folders;
                this.currentFolderId = this.vfs._id;
                this.folderIdTree.push(this.vfs);
                this.notifyFolderSelection(0);
                this.$emit('mutateFolderDepth', 1);
            } else {
                this.folderIdTree = this.initalFolderIdTree;
                this.vfs = this.folderIdTree[this.folderIdTree.length - 1];

                this.files = this.vfs.files;
                this.folders = this.vfs.folders;
                this.currentFolderId = this.vfs._id;
                this.notifyFolderSelection(0);
                this.$emit('mutateFolderDepth', this.folderIdTree.length);
                this.$emit('resetInitalFolderIdTree');
            }
        },
        // Folder Creation Bits
        switchUIToEditMode(el) {
            el.isMoving = true;
            this.newFolders = [el];
            const elIndex = this.shownItems.findIndex(item => item === el);
            this.shownItems.splice(elIndex, 1);
        },
        // vue/no-unused-properties cannot detect methods by $ref, so make a exception
        // eslint-disable-next-line vue/no-unused-properties
        startFolderCreation() {
            this.newFolders = [{ isEditing: true }];
            this.newFolders[0].isCreating = true;
            // todo: move initialization of possibleReceiver Accounts from ListElement to here, then pass as prop
        },
        // creates AND patches Folders
        async createFolder(folder, newFolderCreated = true) {
            if (this.folderType === 'groupFolder') {
                folder.receiverAccounts = await Promise.all(folder.receiverAccounts.map((el)=> {
                    return el.account;
                }));
                // params.sharing_Accounts = folder.receiverAccounts;
                // params.sharing_enabled = true;
                // params.sharing_discoverable = true;
            }
            if (newFolderCreated) {
                let params = {
                    name: folder.name,
                    currentFolderId: this.currentFolderId,
                    folderType: this.folderType, // invalid/undef value will default to privateFolder
                    sharing_Accounts: folder.receiverAccounts,
                    sharing_enabled: true,
                    sharing_discoverable: true,
                };
                let res = await this.createVFSFolder(params);
                if (res === 403) {
                    this.showSnackbar({ message: 'Keine Berechtigung vorhanden, nur der Besitzer kann Ordner erstellen.', color: "error" })
                } else {
                    this.showSnackbar({ message: 'Aktion gespeichert!' })
                }
                this.folderName = "";
                this.newFolders = [];
            } else {
                folder.parentFolderId = this.currentFolderId;
                let params = {
                    _id: folder._id,
                    parentFolderId: folder.parentFolderId,
                    name: folder.name,
                    sharing_Accounts: folder.receiverAccounts,
                    sharing_enabled: true,
                    sharing_discoverable: true,
                }
                if (!params.name) {
                    delete params.name;
                }
                let res = await this.updateVFSFolder(params);
                if (res.status === 200) {
                    this.showSnackbar({ message: 'Aktion gespeichert!' });
                }
                this.newFolders = [];
            }
            await this.refreshCurrentFolder();
        },
        cancelFolderCreation() {
            this.newFolders = [];
            this.refreshCurrentFolder();
        },
        //Patch uploads Bits
        startPatchUpload(file) {
            this.patchingFile = { ...file, isMoving: true };
            const elIndex = this.files.findIndex(item => item === file);
            this.files.splice(elIndex, 1);
        },
        cancelPatchUpload(file) {
            this.patchingFile = null;
            this.refreshCurrentFolder();
        },
        async patchFolderUpload(file) {
            let body = {
                fileId: file._id,
                name: file.name,
                parentFolderId: this.currentFolderId
            }

            let res = await this.updateVFSFolderUpload(body);
            if (res.status === 200) {
                this.showSnackbar({ message: 'Aktion gespeichert!' });
            }
            await this.refreshCurrentFolder();
            this.patchingFile = null;
        },

        async clickDeleteFolder(folderId) {
            await this.deleteVFSFolder(folderId);
            this.refreshCurrentFolder();
        },

        async clickDeleteFolderUpload(file) {
            await this.deleteVFSFolderUpload(file)
            this.refreshCurrentFolder();
        },

        openDeleteDialog(el) {
            const id = el._id;
            let method;
            if (el.type === 'file') {
                method = 'shared file'
            } else if (el.type === 'folder') {
                method = 'shared folder'
            }
            this.deleteDialog = true;
            this.elementToDelete = id;
            this.deleteMethod = method;
                        switch (method) {
                case 'shared file':
                    this.deleteInfoText = 'Wollen Sie die Datei wirklich unwiderruflich löschen?';
                    break;
                case 'shared folder':
                    this.deleteInfoText = 'Wollen Sie den Ordner wirklich unwiderruflich löschen? Es werden sämtliche Unterordner und Dateien gelöscht.';
                    break;
                default:
                    console.warn('undefined deleteMethod selection in openDeleteDialog VirtualFileSystem');
            }
        },

        confirmDelete() {
            switch (this.deleteMethod) {
                case 'shared file':
                    this.clickDeleteFolderUpload(this.elementToDelete);
                    break;
                case 'shared folder':
                    this.clickDeleteFolder(this.elementToDelete);
                    break;
                default:
                    console.warn('something went wrong in the confirmDelete method');
            }
            this.deleteDialog = false;
            this.elementToDelete = null;
            this.deleteMethod = '';
        },

        async diveInFolderFromHeader(folderId) {
            this.clean_URLCollection();
            let newFolder = this.folderIdTree[this.folderIdTree.length - 1];
            let directionCounter = 0;
            for (let i = this.folderIdTree.length - 1; i >= 0; i--) {

                if (this.folderIdTree[i]._id == folderId) {

                    newFolder = this.folderIdTree[i];
                    break;
                } else {
                    this.folderIdTree.pop();
                    directionCounter--;
                }
            }
            this.vfs = newFolder;
            this.folders = newFolder.folders;
            this.files = newFolder.files;
            this.currentFolderId = newFolder._id;
            this.traveledFromPath = true;
            this.notifyFolderSelection(directionCounter);
        },

        async diveInFolder(folderId) {
            this.clean_URLCollection();
            this.vfs = await this.getVFSFolderUploads({ folderType: this.folderType, folderId });
            this.folderIdTree.push(this.vfs);
            this.folders = this.vfs.folders;
            this.files = this.vfs.files;
            this.currentFolderId = this.vfs._id;
            this.notifyFolderSelection();
        },

        leaveCurrentFolder() {
            if (!this.vfs.root) {
                this.clean_URLCollection();
                this.folderIdTree.pop();
                const parent = this.folderIdTree[this.folderIdTree.length - 1]
                this.folders = parent.folders;
                this.files = parent.files;
                this.currentFolderId = parent._id;
                this.vfs = parent;
                this.notifyFolderSelection(0);
                this.displayingSortResult = false;
            }
        },

        async refreshCurrentFolder() {
            this.vfs = await this.getVFSFolderUploads({ folderType: this.folderType, folderId: this.currentFolderId });
            this.folderIdTree.pop();
            this.folderIdTree.push(this.vfs);
            this.folders = this.vfs.folders;
            this.files = this.vfs.files;
            this.currentFolderId = this.vfs._id;
        }
    }
}
</script>

<style scoped lang="scss">
.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}

.directoryPath:hover {
    background-color: #e4e3e3;
}

.textTransformNone {
    text-transform: none;
}

.directoryPath {
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
}

.fileSystemElement {
    display: flex;
    position: relative;
    border-radius: 4px;
    color: var(--v-dunkelgrau-base);
    box-shadow: 1px 2px 3px silver;
    width: 90%;
    white-space: nowrap;
    background-color: #fff;
}

.fileSystemElementName {
    cursor: pointer;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
    align-items: center;
}

.fileWidgetButton {
    height: 30px !important;
    width: 30px !important;
    min-width: 30px !important;
}

#hintBox {
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    color: grey;
}
</style>
