import * as backend from '../../api/backend';
import {checkResponseStatus} from '../../util/check';

const defaultState = {
    groupsInvalid: false,
    groups: [],
};

const mutations = {
    SET_GROUPS: (state, groups) => {
        state.groups = groups;
    },

    ADD_GROUP: (state, group) => {
        state.groups.push(group);
    },
    addGroupForTeacher: (state, group) => {
        if (!state.groups.find(current => current._id === group._id))
            state.groups.push(group);
    },

    REMOVE_GROUP: (state, index) => {
    },
    setGroupsInvalid: (state, status) => {
        state.groupsInvalid = status;
    },
};

const getters = {
    groups: state => state.groups,
    groupsById: (state, getters, rootState, rootGetters) => {
        return state.groups.reduce((acc, group) => {
            acc[group._id] = group;
            return acc;
        }, {});
    },
    getGroupStatus: state => state.groupsInvalid,
};

const actions = {
    async fetchGroups({ commit, dispatch }, params) {
        try {
            const res = await backend.getGroups(params);
            await checkResponseStatus(200, res);
            const groups = await res.json();
            commit('SET_GROUPS', groups);
            return res.status;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    
    async createGroup({commit, dispatch}, params) {
        try {
            const res = await backend.postGroup(params);
            await checkResponseStatus(201, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async editGroup({commit, dispatch}, params) {
        try {
            const id = params._id;
            delete params._id;
            const res = await backend.patchGroup(id, params);
            if (res.status === 409) {
                return 409;
            }
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async deleteGroup({commit, dispatch}, id) {
        try {
            const res = await backend.deleteGroup(id);
            if (res.status === 409) {
                return 409;
            }
            await checkResponseStatus(204, res);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        }
    },
    setGroupInvalidStatus: async ({commit}, status) => {
        commit('setGroupsInvalid', status);
    },

    async getGroups({commit, dispatch, getters}, update = false) {
        try {
            if (getters.groups && getters.groups.length > 0 && !update) {
                return getters.groups;
            }
            const res = await backend.getGroups();
            await checkResponseStatus(200, res);
            const groups = await res.json();
            commit('SET_GROUPS', groups);
            return groups;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getGroupsInfo({commit, dispatch}) {
        try {
            const res = await backend.getGroupsInfo();
            await checkResponseStatus(200, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getGroup({commit, dispatch, getters}, id) {
        try {
            if (getters.groups) {
                const group = getters.groups.find(g => g._id === id);
                if (group)
                    return group;
            } else {
                // Fetch groups if empty
                dispatch('getGroups', true);
            }
            const res = await backend.getGroup(id);
            await checkResponseStatus(200, res);
            const group = await res.json();
            return group;
        } catch (err) {
            console.log(err);
            console.error(err);
            return err?.response?.status;
        }
    },
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
};
