<template>
    <div>
        <progress-bar
            title="Hochladen"
            :show-progress="showAttachmentUploadProgress"
            :progress="attachmentUploadProgress"
            :abort-progress="fileXmlHttpRequest ? () => { fileXmlHttpRequest.abort() }: () => {}"
        />

        <div class="greyBox d-flex flex-column align-center">
            <div style="max-width: 500px">
                <div v-if="account.role === 'maintainer'">
                    <p class="font-weight-bold mb-1">
                        Briefkopf
                    </p>

                    <p class="mb-4">
                        Füllen Sie die folgenden Felder aus um den Briefkopf des Elternbrief-Exports anzupassen
                    </p>

                    <p>Name der Schule</p>
                    <v-text-field
                        v-model="schoolName"
                        outlined
                        dense
                        hide-details
                        class="mb-2"
                        style="max-width: 500px;"
                    />
                    <p>Straße und Nummer</p>
                    <v-text-field
                        v-model="schoolStreet"
                        outlined
                        dense
                        hide-details
                        class="mb-2"
                        style="max-width: 500px;"
                    />
                    <div
                        class="d-flex"
                        style="min-width: 100%"
                    >
                        <div style="width: 25%">
                            <p v-if="windowWidth < 500">
                                PLZ
                            </p>
                            <p v-else>
                                Postleitzahl
                            </p>
                            <v-text-field
                                v-model="schoolPostal"
                                outlined
                                dense
                                hide-details
                                class="mb-2"
                                style="max-width: 500px;"
                            />
                        </div>

                        <div
                            class="ml-2"
                            style="width: 75%"
                        >
                            <p>Ort</p>
                            <v-text-field
                                v-model="schoolCity"
                                outlined
                                dense
                                hide-details
                                class="mb-2"
                                style="max-width: 500px;"
                            />
                        </div>
                    </div>

                    <hr class="mb-2">
                    <p>E-Mail</p>
                    <v-text-field
                        v-model="schoolEmail"
                        outlined
                        dense
                        hide-details
                        class="mb-2"
                        style="max-width: 500px;"
                    />

                    <p>Telefon</p>
                    <v-text-field
                        v-model="schoolPhone"
                        outlined
                        dense
                        hide-details
                        class="mb-2"
                        style="max-width: 500px;"
                    />

                    <hr class="mb-2">

                    <div class="d-flex align-center justify-space-between">
                        <p>Logo <span style="color: red">im png-Format</span></p>

                        <v-btn
                            class="actionBtn mr-2"
                            x-small
                            @click="clickElement('fileInput')"
                        >
                            <img
                                :src="uploadIcon"
                                class="icon"
                                alt="Logo hochladen"
                            >
                        </v-btn>
                    </div>

                    <input
                        id="fileInput"
                        ref="fileInput"
                        type="file"
                        accept="image/png"
                        hidden
                        @change="fileInputChange"
                    >

                    <div class="d-flex-column">
                        <img
                            v-if="logoToPreview"
                            :src="logoToPreview"
                            style="max-height: 200px !important"
                            alt="Vorschau aktuelles Logo"
                        >
                        <p><small>Aktuelles Logo</small></p>
                    </div>

                    <div
                        v-if="logoPreview"
                        class="d-flex-column"
                    >
                        <img

                            :src="logoPreview"
                            style="max-height: 200px !important"
                            alt="Vorschau neues Logo"
                        >
                        <p><small>Neues Logo</small></p>
                    </div>
                </div>

                <div v-else>
                    <div v-if="account.role !== 'pupil'">
                        <p class="font-weight-bold mb-1">
                            Benachrichtigungen
                        </p>
                        <p class="mb-4">
                            Sie können ihre E-Mail Adresse angeben um Benachrichtigungen von eKlara per E-Mail zu erhalten.
                        </p>
                        <p>E-Mail</p>
                        <v-text-field
                            v-model="email"
                            outlined
                            dense
                            hide-details
                            class="mb-2"
                            style="max-width: 500px;"
                        />
                        <div
                            style="display: flex; flex-direction: column"
                            class="mb-6"
                        >
                            <div style="display: flex; flex-direction: row; justify-content: space-between">
                                <p>Schwarzes Brett</p>
                                <v-checkbox
                                    v-model="notificationBlackboard"
                                    hide-details
                                    color="black"
                                    style="margin-top: 0; padding-top: 0"
                                />
                            </div>
                            <div
                                v-if="account.role === 'parent'"
                                style="display: flex; flex-direction: row; justify-content: space-between"
                            >
                                <p>Elternbriefe</p>
                                <v-checkbox
                                    v-model="notificationParentalLetter"
                                    hide-details
                                    color="black"
                                    style="margin-top: 0; padding-top: 0"
                                />
                            </div>
                            <div
                                v-if="account.role === 'teacher'"
                                style="display: flex; flex-direction: row; justify-content: space-between"
                            >
                                <p>Entschuldigungen</p>
                                <v-checkbox
                                    v-model="notificationSicknote"
                                    hide-details
                                    color="black"
                                    style="margin-top: 0; padding-top: 0"
                                />
                            </div>
                        </div>
                    </div>
                    <p class="font-weight-bold mb-1">
                        Passwort ändern
                    </p>

                    <p class="mb-4">
                        Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Buchstaben und eine Zahl enthalten.
                    </p>
                    <p>Altes Passwort</p>
                    <v-text-field
                        v-model="oldPassword"
                        :type="showPassword ? 'text' : 'password'"
                        outlined
                        dense
                        hide-details
                        class="mb-2"
                        style="max-width: 500px;"
                    />

                    <!-- <p v-if="!userrole.securityQuestion">
                        Sicherheitsfrage auswählen
                    </p>
                    <p v-else>
                        Sicherheitsfrage
                    </p> -->
                    <!-- TODO: remember to use return-object if needed -->
                    <!-- <v-select
                        v-model="securityQuestion"
                        aria-label="Sicherheitsfrage"
                        outlined
                        dense
                        hide-details
                        :items="securityQuestions"
                        :menu-props="{ bottom: true, offsetY: true }"
                        no-data-text="Sicherheitsfragen konnten nicht geladen werden"
                        class="mb-2"
                        style="max-width: 500px;"
                    />

                    <div v-if="securityQuestion">
                        <p v-if="userrole.securityQuestion">
                            Sicherheitsfrage beantworten
                        </p>
                        <p v-else>
                            Neue Sicherheitsfrage setzen
                        </p>
                        <v-text-field
                            v-model="securityQuestionAnswer"
                            outlined
                            dense
                            hide-details
                            class="mb-2"
                            style="max-width: 600px;"
                        />
                    </div> -->

                    <p>Neues Passwort</p>
                    <v-text-field
                        v-model="newPassword"
                        :type="showPassword ? 'text' : 'password'"
                        outlined
                        dense
                        hide-details
                        class="mb-2"
                        style="max-width: 500px;"
                    />

                    <p>Neues Passwort wiederholen</p>
                    <v-text-field
                        v-model="newPasswordRepeat"
                        :type="showPassword ? 'text' : 'password'"
                        outlined
                        dense
                        hide-details
                        class="mb-2"
                        style="max-width: 500px;"
                    />

                    <div class="d-flex justify-center pa-0 ma-0">
                        <v-checkbox
                            v-model="showPassword"
                            aria-label="Passwort anzeigen"
                            hide-details
                            class="ma-0 pa-0 pl-1"
                        />
                        <p aria-hidden="true">
                            Passwort anzeigen
                        </p>
                    </div>

                    <div
                        v-if="accountRole === 'teacher' || accountRole === 'parent'"
                        class="d-flex pa-0 ma-0"
                    >
                        <v-checkbox
                            v-model="twoFactor"
                            aria-label="Zwei Faktor Authentifizierung"
                            hide-details
                            :disabled="!isEmailExisting"
                            class="ma-0 pa-0 pl-1 mb-1"
                        />
                        <p aria-hidden="true">
                            Zwei Faktor Authentifizierung
                        </p>
                    </div>

                    <div
                        v-if="(accountRole === 'teacher' || accountRole === 'parent') && twoFactorMenu"
                        class="d-flex pa-0 ma-0"
                        style="flex-direction: column; align-items: center;"
                    >
                        <v-text-field
                            v-model="twoFactorEmail"
                            label="E-Mail wiederholen"
                            outlined
                            dense
                            hide-details
                            class="mb-3"
                            style="max-width: 500px; width:100%;"
                        />
                        <v-text-field
                            v-model="twoFactorPassword"
                            label="Passwort wiederholen"
                            type="password"
                            outlined
                            dense
                            hide-details
                            class="mb-3"
                            style="max-width: 500px; width:100%;"
                        />
                        <v-text-field
                            v-model="twoFactorOTP"
                            label="Code aus erhaltener Mail"
                            outlined
                            dense
                            hide-details
                            class="mb-3"
                            style="max-width: 500px; width:100%;"
                        />
                        <v-btn
                            :disabled="!(twoFactorEmail.length > 0 && twoFactorPassword.length > 0 && twoFactorOTP.length > 0)"
                            class="changePwBtn text-none"
                            style="width: 50%"
                            elevation="0"
                            @click="activate2FA"
                        >
                            Bestätigen
                        </v-btn>
                    </div>

                    <!-- Simple Editor Stuff -->
                    <div
                        v-if="accountRole === 'teacher'"
                    >
                        <p class="font-weight-bold mb-1">
                            Editor-Modus
                        </p>
                        <v-checkbox
                            v-model="simpleEditorValue"
                            inset
                            :label="`Einfacher Editor-Modus für Lehrer`"
                        />
                    </div>

                    <div>
                        <p class="font-weight-bold mb-1">
                            Video Tutorials anzeigen
                        </p>
                        <v-checkbox
                            v-for="(videoTutorial, index) in videoTutorialsOfMyRole"
                            :key="index"
                            v-model="videoTutorial.seeAgain"
                            style="margin-top: 0; padding-top: 0"
                            :label="videoTutorial.title"
                            hide-details
                            @change="changeVideoTutorialSeeAgainState(videoTutorial.filename, videoTutorial.seeAgain)"
                        />
                    </div>
                </div>
                <!-- censored expression -->
                <div v-if="account.role !== 'pupil'">
                    <v-row class="d-flex mt-5 ml-0">
                        <p class="font-weight-bold ">
                            Zensurliste
                        </p>
                    </v-row>
                    <v-row class="d-flex mx-0">
                        <v-select
                            v-model="selectedExpression"
                            :menu-props="{ top: true, offsetY: true }"
                            :items="censoredExpressions"
                            item-value="_id"
                            :item-text="item => item.expression"
                            return-object
                            label="Begriff auswählen/hinzufügen"
                            no-data-text="Keine Begriffe vorhanden"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item
                                    hide-details
                                    @click="createDialog = !createDialog;setCensoredExpressions"
                                >
                                    <v-list-item-action>
                                        <v-icon>
                                            mdi-plus-circle
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Hinzufügen
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider />
                            </template>
                        </v-select>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="mx-2 mt-4"
                                    fab
                                    dark
                                    small
                                    color="error"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="if(selectedExpression !=''){deleteCensoredExpression(selectedExpression);}"
                                >
                                    <v-icon dark>
                                        mdi-delete-forever
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Begriff löschen</span>
                        </v-tooltip>    
                        <v-dialog
                            v-model="createDialog"
                            style="display:flex; justify-content:center"
                            max-width="700px"
                            transition="dialog-bottom-transition"
                        >
                            <v-card class="d-flex flex-column justify-content-center px-5">
                                <v-text-field
                                    v-model="expression"
                                    style="mt-2"
                                    label="Begriff eintragen"
                                    required
                                />
                                <v-row class="d-flex justify-space-between pb-3">
                                    <v-btn
                                        color="primary"
                                        class="ml-4"
                                        @click="createCensoredExpression"
                                    >
                                        Bestätigen
                                    </v-btn>

                                    <v-btn
                                        color="error"
                                        class="mr-4"
                                        @click="createDialog =! createDialog;expression=''"
                                    >
                                        Abbrechen
                                    </v-btn>
                                </v-row>
                            </v-card>
                        </v-dialog> 
                    </v-row>
                </div>
                <!-- end censor expression -->
                <div
                    id="btnContainer"
                    class="d-flex justify-space-between pa-0 ma-0 mt-5"
                >
                    <v-btn
                        class="backButton text-none"
                        elevation="0"
                        @click="handleBackClick"
                    >
                        &lt; Zurück zu eKlara
                    </v-btn>

                    <v-btn
                        id="custom-disabled"
                        :disabled="!checkPw && securityQuestionAnswer.length > 0"
                        color="gruen"
                        class="changePwBtn text-none"
                        elevation="0"
                        dark
                        @click="saveProfile"
                    >
                        Speichern
                    </v-btn>
                </div>
            </div>
        </div>
        <div>
            <v-dialog
                v-model="downloadPdfDialog"
                width="600px"
            >
                <v-card>
                    <v-card-title>Passwort herunterladen</v-card-title>
                    <v-card-text class="d-flex flex-column align-center">
                        <p
                            class="mb-4"
                        >
                            Das Passwort wurde erfolgreich geändert. Die neuen QR-Codes können über den folgenden Knopf heruntergeladen werden.
                        </p>
                        <v-btn
                            class="text-none mt-2"
                            elevation="0"
                            style="width: 150px"
                            @click="account.role !== 'parent' ? pdf() : parentPdf()"
                        >
                            QR Download
                        </v-btn>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end px-6 pb-4">
                        <v-btn
                            color="gruen"
                            class="text-none mt-2"
                            elevation="0"
                            dark
                            @click="handleFinish()"
                        >
                            Fertig
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import { compress } from "shrink-string";
import * as backend from '@/api/backend';

import ProgressBar from "@/components/ProgressBar";
import roboto from "@/assets/Fonts/Roboto-Regular.ttf";
import robotoBold from "@/assets/Fonts/Roboto-Bold.ttf";
import uploadIcon from '@/assets/Icons/hochladen-15.svg';
import { activate2FA, getActivate2Fa } from '../api/backend';

export default {
    name: "ProfileManagement",
    components: {
        ProgressBar,
    },
    data() {
        return {
            uploadIcon,

            securityQuestions: [],          // holds the available security questions
            securityQuestion: null,         // is a security question selected in the dialog
            securityQuestionAnswer: '',     // the answer to the selected security question

            email: '',
            notificationBlackboard: false,
            notificationParentalLetter: false,
            notificationSicknote: false,

            showPassword: false,
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',

            twoFactor: false,
            twoFactorMenu: false,
            twoFactorEmail: '',
            twoFactorPassword: '',
            twoFactorOTP: '',


            downloadPdfDialog: false,

            account: {},
            userrole: {},

            videoTutorialsOfMyRole: [],

            // Simple Editor Stuff
            simpleEditorValue: false,

            createDialog: false,
            expression:'',
            censoredExpressions: [],
            selectedExpression:'',

            schoolId: '',
            schoolName: '',
            schoolStreet: '',
            schoolPostal: '',
            schoolCity: '',
            schoolEmail: '',
            schoolPhone: '',
            logo: null,
            logoPreview: null,      // the image selected for upload
            logoToPreview: null,    // the image that is saved in backend

            fileXmlHttpRequest: null,
            showAttachmentUploadProgress: false,
            attachmentUploadProgress: 0.0,
        }
    },
    computed: {
        ...mapState('profileManagement', [ 'profileManagement' ]),
        ...mapState('util', [ 'windowWidth' ]),
        ...mapGetters('auth', [ 'accountRole' ]),
        ...mapGetters('videoTutorial', [ 'videoTutorials' ]),

        isEmailExisting() {
            return this.email && this.email !== "" && this.email.includes("@");
        },

        // checks if if new PWs are equal & password is at least of length 8 and contains one letter and one number
        checkPw() {
            const pwCheck = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})");

            if (this.newPassword.trim() === this.newPasswordRepeat.trim()) {
                return pwCheck.test(this.newPassword);
            }
            else {
                return false;
            }
        },
    },
    watch: {
        selectedExpression(newVal ,oldVal){
            console.log("old ",oldVal);
            console.log("new ",newVal)
            this.selectedExpression=newVal; 
        },
        censoredExpressions(newVal ,oldVal){
            console.log("old ",oldVal);
            console.log("new ",newVal)
        },
    },
    async mounted() {
        this.account = await this.getCurrentAccount();
        
        await this.setCensoredExpressions();

        if (this.account.role !== 'maintainer') {
            this.securityQuestions = await this.getSecurityQuestions();
        }

        switch (this.account.role) {
            case 'pupil':
                this.userrole = await this.getMePupil();
                break;
            case 'teacher':
                this.userrole = await this.getMeTeacher();
                break;
            case 'parent':
                this.userrole = await this.getMeParent();
                break;
            case 'maintainer':
                await this.getSchoolData();
                break;
            default:
                console.warn('This user role is not supported');
                break;
        }

        this.email = this.account.email;
        this.securityQuestions = this.account.securityQuestion;
        this.securityQuestion = this.account.securityQuestion;
        this.twoFactor = this.account.twoFactor ? this.account.twoFactor : false;
        this.$watch('twoFactor', this.twoFactorWatcher);

        // if securityQuestion has been set by user, remove all other choices
        // if (this.userrole.securityQuestion) {
        //     this.securityQuestion = this.userrole.securityQuestion;
        //     this.securityQuestions = this.userrole.securityQuestion;
        // }

        if (this.userrole) {
            this.simpleEditorValue = this.userrole.simpleEditor || false;
        }

        this.videoTutorialsOfMyRole = await this.setVideoTutorialStates();

        this.notificationBlackboard = this.account.notifications?.notificationBlackboard;
        this.notificationParentalLetter = this.account.notifications?.notificationParentalLetter;
        this.notificationSicknote = this.account?.notifications?.notificationSicknote;

    },
    methods: {
        ...mapMutations('videoTutorial', [ 'setTutorialSeeAgain' ]),
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapActions('profileManagement', ['toggleProfileManagement']),
        ...mapActions('pupils', ['getMePupil', 'editPupil']),
        ...mapActions('teachers', ['getMeTeacher', 'editTeacher', 'editTeacherProfile']),
        ...mapActions('parents', ['getMeParent', 'editParent']),
        ...mapActions('auth', ['getCurrentAccount', 'logoutUser', 'getSecurityQuestions']),
        ...mapActions('school', ['postSchool', 'getSchool', 'postSchoolLogo']),
        ...mapActions('videoTutorial', [ 'getTutorialProperties', 'updateTutorialProperties' ]),

        handleBackClick() {
            if (this.account.role === 'maintainer') {
                this.$router.push({ name: 'verwaltung.plan' });
            } else {
                this.toggleProfileManagement();
            }
        },

        async setVideoTutorialStates() {
            const videoTutorialofMyRole = this.videoTutorials.filter(tutorial => {
                if (this.userrole.babyView) {
                    return tutorial.role === 'baby';
                } else {
                    return tutorial.role === this.accountRole;
                }
            })
            for (const el of videoTutorialofMyRole) {
                const properties = await this.getTutorialProperties({ videoTutorialFilename: el.filename });
                el.seeAgain = properties.seeAgain;
            }
            return videoTutorialofMyRole;
        },

        changeVideoTutorialSeeAgainState(filename, seeAgain) {
            this.updateTutorialProperties(
                {
                    videoTutorialFilename: filename,
                    timesShownRemaining: 3,
                    seeAgain: seeAgain,
                });
            if(seeAgain) {
                this.showSnackbar({ message: "Video Tutorial eingeschaltet.", color: "success" });
            } else {
                this.showSnackbar({ message: "Video Tutorial ausgeschaltet.", color: "success" });
            }
        },

        async generateQRCodeData() {
            // create a QR PW, it's different from regular one for security reasons
            // it's saved in the QR code as base64 and if user uses same PW on multiple websites this helps a bit
            let qrPassword = ''
            let allCharacters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (let i = 0; i < 16; i++) {
                qrPassword += allCharacters.charAt(
                    Math.floor(Math.random() * allCharacters.length)
                );
            }
            const compressedName = await compress(this.account.accountName);
            const compressedPW = await compress(qrPassword);
            const qrCodeData = JSON.stringify({
                name: compressedName,
                pw: compressedPW,
            });
            return qrCodeData;
        },

        async getActivate2FA() {
            await backend.getActivate2Fa(this.account._id);
        },

        async activate2FA() {
            const res = await backend.activate2FA(this.account._id, {
                email: this.twoFactorEmail,
                password: this.twoFactorPassword,
                otp: this.twoFactorOTP,
                twoFactorEnabled: this.twoFactor,
            });
            if (res.status === 201) {
                this.showSnackbar({
                    message: `Zwei-Faktor-Authorisierung wurde erfolgreich ${this.twoFactor ? 'aktiviert' : 'deaktiviert'}!`,
                    color: 'success',
                });
            } else {
                this.showSnackbar({
                    message: 'Die eingegeben Daten stimmen nicht überein.',
                    color: 'error',
                })
            }
            this.twoFactorMenu = false;
        },

        async saveProfile() {
            if (this.account.role === 'maintainer') {
                await this.saveMaintainerProfile();
                return;
            }

            const accountPatchBody = {
                ...(this.oldPassword !== '' ? { 'oldPassword' : this.oldPassword } : {}),
                ...(this.newPassword !== '' ? { 'password' : this.newPassword } : {}),
                ...(this.newPassword !== '' ? { 'qrPassword' : await this.generateQRCodeData() } : {}),
                notifications: {
                    notificationBlackboard: this.notificationBlackboard,
                    notificationParentalLetter: this.notificationParentalLetter,
                    notificationSicknote: this.notificationSicknote,
                },
                email: this.email,
            }
            const accountPatchRes = await backend.patchAccount(this.account._id, accountPatchBody);

            const userRolePatchBody = {
                _id: this.userrole._id,
                simpleEditor: this.simpleEditorValue,
            }
            let updatedUser;
            if(this.account.role === 'teacher') {
                updatedUser = await this.editTeacher(userRolePatchBody);
            }

            if (accountPatchRes.status === 200 && !Number.isInteger(updatedUser)) {
                this.showSnackbar({ message: "Änderungen erfolgreich geändert.", color: "success" });
            }
            else {
                this.showSnackbar({ message: "Etwas hat nicht funktioniert.", color: "error" });
            }
        },

        async saveMaintainerProfile() {
            let data;

            if (this.$refs.fileInput.files[0].type !== 'image/png') {
                this.showSnackbar({ message: 'Das Logo muss im png-Format hochgeladen werden.', color: 'error'});
                return;
            }

            if (this.schoolId) {
                data = {
                    id: this.schoolId,
                    name: this.schoolName,
                    street: this.schoolStreet,
                    postal: this.schoolPostal,
                    city: this.schoolCity,
                    email: this.schoolEmail,
                    phone: this.schoolPhone,
                }
            } else {
                data = {
                    name: this.schoolName,
                    street: this.schoolStreet,
                    postal: this.schoolPostal,
                    city: this.schoolCity,
                    email: this.schoolEmail,
                    phone: this.schoolPhone,
                }
            }

            let res = await this.postSchool(data);

            const formData = new FormData();
            const file = this.$refs.fileInput.files;

            if (file.length > 0) {
                formData.append("file", file[0], file[0].name);

                this.fileXmlHttpRequest = backend.postSchoolLogo(res._id, file);

                this.fileXmlHttpRequest.onerror = (e) => {
                    console.error("attachment upload error:", e);
                    this.showAttachmentUploadProgress = false;
                    this.fileXmlHttpRequest = null;
                    this.$refs.fileInput.value = "";
                };

                this.fileXmlHttpRequest.onabort = () => {
                    console.warn("attachment upload aborted");
                    this.showAttachmentUploadProgress = false;
                    this.fileXmlHttpRequest = null;
                    this.$refs.fileInput.value = "";
                };

                this.fileXmlHttpRequest.addEventListener("load", () => {
                    if (res._id && this.fileXmlHttpRequest.status === 201) {
                        this.showSnackbar({ message: 'Daten erfolgreich hinterlegt.'});
                        this.getSchoolData();
                    } else if (this.fileXmlHttpRequest.status !== 201) {
                        this.showSnackbar({ message: 'Beim Hochladen des Logos ist ein Fehler aufgetreten.', color: 'error'});
                    } else {
                        this.showSnackbar({ message: 'Beim Hinterlegen der Daten ist ein Fehler aufgetreten.', color: 'error'});
                    }

                    this.showAttachmentUploadProgress = false;
                    this.fileXmlHttpRequest = null;
                    this.$refs.fileInput.value = "";
                });

                this.fileXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.attachmentUploadProgress = (e.loaded / e.total) * 100;
                });

                this.attachmentUploadProgress = 0.0;
                this.showAttachmentUploadProgress = true;
                this.fileXmlHttpRequest.send(formData);
            }

            this.logoPreview = null;
        },

        async getSchoolData() {
            let school = await this.getSchool();

            if (school) {
                this.schoolId = school._id;
                this.schoolName = school.name;
                this.schoolStreet = school.street;
                this.schoolPostal = school.postal;
                this.schoolCity = school.city;
                this.schoolPhone = school.phone;
                this.schoolEmail = school.email;
            }

            if (school.logo) {
                const res = await backend.getSchoolLogo(school._id, school.logo);
                let blob = await res.blob();
                this.logoToPreview = window.URL.createObjectURL(blob);
            }
        },

        clickElement(element) {
            document.getElementById(element).click();
        },

        fileInputChange() {
            let input = this.$refs.fileInput;
            this.logo = input.files[0];
            this.logoPreview = URL.createObjectURL(this.logo);
        },

        handleFinish() {
            this.downloadPdfDialog = false;
            this.password = '';

            // logout user, because PW was changed
            this.logoutUser();
            this.$router.push({ name: 'login' });
        },
        async setCensoredExpressions(){
            const response = await backend.getCensoredExpressions();
            const expressionList = await response.json();
            this.censoredExpressions = expressionList;
        },
        async createCensoredExpression(){
            const data = this.expression;
            const response = await backend.postExpression({expression: data});
            if (response.status === 201) {
                this.showSnackbar({ message: `"${data}" erfolgreich der Zensurliste hinzugefügt`, color: 'success'});
                await this.setCensoredExpressions();
                this.createDialog= false;
             } else {
                this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite und versuchen sie es erneut!', color: 'error'});
                this.createDialog= false;
            }
        },
        async deleteCensoredExpression(object){
            if(object._id != ''){
            const res = await backend.deleteExpression(object._id);
             if (res.status === 204) {
                 this.censoredExpressions = this.censoredExpressions.filter(el => el._id != object._id);
                this.showSnackbar({ message: `"${object.expression}" erfolgreich aus der Zensurliste gelöscht`, color: 'success'});
             }
             } else {
                this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite und versuchen sie es erneut!', color: 'error'});   
            }
        },

        twoFactorWatcher() {
            this.twoFactorMenu = true;
            this.getActivate2FA();
            this.showSnackbar({
                message: 'Zum Ändern der Zwei-Faktor-Authorisierung wird eine Bestätigung benötigt. Bitte überprüfen Sie Ihr Postfach.',
                color: 'success',
            })
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .v-label {
    color: rgba(0, 0, 0, 0.87) !important;
}

#custom-disabled.v-btn--disabled {
    background-color: var(--v-fgrau-base) !important;
    color: white;
}

.header {
    margin-bottom: 0.5em;
}

.icon {
    height: 20px;
}

.greyBox {
    height: 100vh;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: auto;
    padding-bottom: 80px;
}

.changePwBtn {
    border-radius: 10px;
}

.backButton {
    color: var(--v-dunkelgrau-base);
    border-radius: 10px !important;
}

.actionBtn {
    color: white;
    border-radius: 8px;
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
}

@media only screen and (max-width: 500px) {
    #btnContainer {
        flex-direction: column;
    }

    .changePwBtn {
        order: 1;
    }

    .backButton {
        margin-top: 20px;
        order: 2;
    }
}
</style>
