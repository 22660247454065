import * as backend from '../../api/backend'
import {checkResponseStatus} from "@/util/check";

const actions = {
    async getBlackboard ({ commit, dispatch }, params) {
        try {
            const response = await backend.getBlackboard();
            const blackboardJson = await response.json();
            return blackboardJson;
        } catch (e) {
            console.error(e);
            return e.response.status;
        }
    },
    async getParentBlackboard ({ commit, dispatch }, params) {
        try {
            const response = await backend.getParentBlackboard(params);
            return response.json();
        } catch (e) {
            return e.response.status;
        }
    },
    async createBlackboardEntry ({ commit, dispatch }, params) {
        try {
            const response = await backend.postBlackboard(params);
            await checkResponseStatus(201, response);
            return await response.json();
        } catch (e) {
            return e.response.status;
        }
    },
    async updateBlackboardEntry ({ commit, dispatch }, params) {
        try {
            const id = params._id;
            delete params._id;
            const response = await backend.patchBlackboard(id, params);
            await checkResponseStatus(201, response);
            return await response.json();
        } catch (e) {
            return e.response.status;
        }
    },
    async deleteBlackboardEntry ({ commit, dispatch }, params) {
        try {
            const id = params._id;
            delete params._id;

            const response = await backend.deleteBlackboard(id, params);
            await checkResponseStatus(201, response);
            return await response.json();
        } catch (e) {
            return e.response.status;
        }
    },
    async deleteBlackboardUpload ({ commit, dispatch }, params) {
      try {
        const response = await backend.deleteBlackboardUpload(params.blackboardId, params.fileId);
        await checkResponseStatus(204, response);
      } catch (e) {
        return e.response.status;
      }
    },

};

export default {
    namespaced: true,
    actions,
}
