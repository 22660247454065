import { checkResponseStatus } from '@/util/check';
import * as backend from '../../api/backend';

const defaultState = {
  teachers: [],
  meTeacher: null,
  teachersInvalid: false,
};

const mutations = {
    setTeachers: (state, list) => {
        state.teachers = list;
    },
    setTeachersInvalid: (state, status) => {
        state.teachersInvalid = status;
    },
    setMeTeacher: (state, me) => {
        state.meTeacher = me;
    }
};

const getters = {
  getTeacherStatus: state => state.teachersInvalid,
  teachers: state => state.teachers,
  teachersById: state => state.teachers.reduce((acc, teacher) => {
    acc[teacher._id] = teacher;
    return acc; 
  }, {}),
  teachersByAccountId: state => state.teachers.reduce((acc, teacher) => {
    acc[teacher.account] = teacher;
    return acc; 
  }, {}),
  simpleEditorForTeacher: state => state.meTeacher ? state.meTeacher.simpleEditor : undefined,
}

const actions = {
  setTeachersInvalidStatus ({ commit }, status) {
    commit('setTeachersInvalid', status);
  },
  async createTeacher ({ commit, dispatch }, params) {
    try {
      const res = await backend.postTeacher(params);
      await checkResponseStatus(201, res);
        return await res.json();
    }
    catch(err) {
        console.error(err);
            return err?.response?.status;
    }
  },

  async uploadTeacherPic ({ commit, dispatch }, params) {
    try {
      const res = await backend.postTeacherPicture(params);
      await checkResponseStatus(201, res);
      return await res.json();
    }
    catch(err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async editTeacher ({ commit, dispatch }, params) {
    try {
      const id = params._id
      delete params._id;
      const res = await backend.patchTeacher(id, params);
      if (res.status === 409) {
        return 409;
      }
      return await res.json();
    }
    catch(err) {
      console.error(err);
            return err?.response?.status;
    }
  },
    async editTeacherProfile({ commit }, params) {
        try {
            let id = params._id;
            delete params._id;
            return await backend.patchTeacherProfile(id, params);
        }
        catch(err) {
            return err;
        }
    },

  async deleteTeacher ({ commit, dispatch }, id) {
    try {
      const res = await backend.deleteTeacher(id);
      if(res.status === 409) {
        return 409;
      }
      await checkResponseStatus(204, res);
      return true;
    }
    catch(err) {
      console.error(err)
      return false;
    }
  },

  async deleteUpload ({ commit, dispatch }, id) {
    try {
      const res = await backend.deleteUpload(id);
      if(res.status === 409) {
        return 409;
      }
      await checkResponseStatus(204, res);
      return true;
    }
    catch(err) {
      console.error(err)
      return false;
    }
  },

  async getTeachers ({ commit, dispatch, getters }, update = false) {
    try {
      if (getters.teachers && getters.teachers.length > 0 && !update) {
          return getters.teachers;
      }

      const res = await backend.getTeachers();
      await checkResponseStatus(200, res);
      const tempArray = await res.json();
      const teachers = tempArray.filter(el => el.lastName !== 'Nachname');
      commit('setTeachers', teachers);
      return teachers;
    }
    catch(err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async getMeTeacher ({ commit }) {
    try {
      const res = await backend.getMeTeacher();
      await checkResponseStatus(200, res);
      const result = await res.json();
      commit('setMeTeacher', result)
      return result;
    }
    catch(err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async getProfilePicture ({ commit, dispatch }, teacherId) {
    try {
      const res = await backend.getProfilePicture(teacherId);
      await checkResponseStatus(200, res);
      return await res.blob();
    }
    catch (err) {
      console.error(err);
            return err?.response?.status;
    }
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  mutations,
  actions,
};
