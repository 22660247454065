<template>
    <div class="d-flex">
        <div style="line-height: 20px">
            <div
                class="d-flex align-center"
                style="min-height: 24px"
            >
                <div
                    v-if="!loadedImage"
                    style="display: flex"
                >
                    <p
                        :id="'bild_' + chatRoomEvent.text"
                        style="margin-right: 4px"
                    >
                        Bild
                    </p>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <img
                                tabindex="0"
                                role="button"
                                :src="downloadIcon"
                                alt="Bild laden"
                                :class="(!eventFromMe ? 'iconToWhite ' : '') + 'icon'"
                                v-on="on"
                            >
                        </template>
                        <span>Laden</span>
                    </v-tooltip>
                </div>
                <img
                    v-else
                    :id="chatRoomEvent.text"
                    :src="loadedImage"
                    style="cursor: pointer; max-width: 100%"
                    @click="imgFullscreen = loadedImage"
                >
            </div>
        </div>
        <v-spacer />
        <div style="display: flex; flex-direction: column">
            <ChatRoomEventListItemDeleteButton
                v-if="eventFromMe || chatRoomMembershipRole === 'moderator'"
                :chat-room-event="chatRoomEvent"
            />

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn
                        x-small
                        class="pa-0"
                        style="
              position: relative;
              float: right;
              width: 25px !important;
              min-width: 25px !important;
              height: 25px;
              margin-top: 8px;
            "
                        v-on="on"
                        @click="downloadImage(loadedImage)"
                        @keypress.enter="downloadImage(loadedImage)"
                    >
                        <img
                            :src="downloadIcon"
                            alt="Bild herunterladen"
                            height="20px"
                        >
                    </v-btn>
                </template>
                <span>Herunterladen</span>
            </v-tooltip>
        </div>

        <!-- Fullscreen image dialog -->
        <v-dialog
            v-if="imgFullscreen"
            :value="imgFullscreen"
            overlay-opacity="0.9"
            overlay-color="black"
            style="max-height: 90%"
            max-width="90%"
            width="unset"
            @click:outside="imgFullscreen = null"
        >
            <v-row>
                <v-col
                    cols="1"
                    align-self="start"
                >
                    <img
                        :src="downloadIcon"
                        style="width: 36px; margin-left: 24px"
                        class="iconToWhite pointer"
                        @click="downloadImage(imgFullscreen)"
                    >
                </v-col>
                <v-spacer />
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        large
                        dark
                        @click="imgFullscreen = null"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row
                justify="center"
                no-gutters
            >
                <v-col
                    cols="12"
                    class="px-2"
                >
                    <img
                        :src="imgFullscreen"
                        style="max-width: 100%; max-height: 100%"
                        alt=""
                    >
                </v-col>
            </v-row>
        </v-dialog>
    </div>
</template>
<script>
import * as backend from "../../../../api/backend";
import ChatRoomEventListItemDeleteButton from "./ChatRoomEventListItemDeleteButton";
import downloadIcon from "../../../../assets/Icons/download-116.svg";
import { mapState } from 'vuex';

export default {
  name: "ChatRoomEventListImageItem",
  components: {
    ChatRoomEventListItemDeleteButton,
  },
  props: {
    chatRoomEvent: { type: Object, required: true },
    chatRoomMembershipRole: { required: true, type: String },
  },
  data: () => ({
    loadedImage: null,
    imgFullscreen: null,

    downloadIcon,
  }),
  computed: {
    ...mapState("auth", ["account"]),
    eventFromMe() {
            return this.chatRoomEvent.author === this.account._id;
    }
  },
  mounted() {
    // File is possibly not uploaded yet.
    if(this.chatRoomEvent.file) {
        this.loadImageFile(this.chatRoomEvent._id);
    } 
  },
  unmount() {
    if (this.loadedImage) URL.revokeObjectURL(this.loadedImage);
  },
  methods: {
    async loadImageFile(chatRoomEventId) {
      const fileResponse = await backend.getChatRoomEventFile(chatRoomEventId);
      const blob = await fileResponse.blob();
      const blobUrl = URL.createObjectURL(blob);
      this.loadedImage = blobUrl;
    },
    fromMe(event) {
      return event.author === this.account._id;
    },
    downloadImage(fileUrl) {
      let el = document.createElement("a");
      el.setAttribute("href", fileUrl);
      el.setAttribute("download", this.fileName);
      document.body.appendChild(el);
      el.click();
      el.remove();
    },
  },
};
</script>
<style scoped>
.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}


.icon {
  height: 20px;
}

</style>