<template>
    <v-dialog
        v-model="localOpen"
        style="border-radius: 15px"
        @click:outside="closeDialog"
    >
        <v-card
            style="z-index: 15; overflow-x: hidden; border-radius: 15px"
            class="ma-0"
        >
            <div
                style="border-radius: 15px; width: 100%; box-shadow: 1px 5px 5px silver; background: white;"
            >
                <!--#region Header -->
                <div
                    id="weeklyViewHeaderContainer"
                    class="d-flex align-center justify-space-between"
                >
                    <div
                        class="pa-0 text-left"
                        style="font-size: x-large; display: flex; align-content: center"
                    >
                        <img
                            :src="stundenplanIcon"
                            alt="Stundenplan"
                            style="height: 35px;"
                            class="iconToWhite"
                        >
                        <p
                            v-if="!editMode"
                            class="pl-2 mb-0"
                        >
                            Stundenplan
                        </p>
                        <v-autocomplete
                            v-else
                            :value="currentGroup"
                            auto-select-first
                            label="Nach Gruppe suchen"
                            single-line
                            dark
                            dense
                            hide-details
                            item-text="name"
                            class="pl-6"
                            :items="teacherGroups"
                            :filter="customFilter"
                            no-data-text="Keine passende Gruppe"
                            @change="autocompleteChange"
                        />
                    </div>

                    <div
                        class="pa-0"
                    >
                        <v-btn
                            class="mr-4 text-capitalize"
                            style="background-color: #f8f8f880; color: white"
                            @click="jumpToCurrentDay()"
                        >
                            Heute
                        </v-btn>
                        <v-badge
                            color="red"
                            :content="pastWeek"
                            :value="pastWeek"
                            overlap
                            left
                        >
                            <v-btn
                                class="mx-2"
                                style="background-color: #f8f8f880"
                                elevation="2"
                                icon
                                small
                                @click="subWeek"
                            >
                                <img
                                    :src="linksIcon"
                                    alt="Pfeil nach links"
                                    style="height: 20px;"
                                    class="iconToWhite"
                                >
                            </v-btn>
                        </v-badge>

                        <v-badge
                            color="red"
                            :content="futureWeek"
                            :value="futureWeek"
                            overlap
                        >
                            <v-btn
                                class="mx-2"
                                style="background-color: #f8f8f880"
                                elevation="2"
                                icon
                                small
                                @click="addWeek"
                            >
                                <img
                                    :src="rechtsIcon"
                                    alt="Pfeil nach rechts"
                                    style="height: 20px;"
                                    class="iconToWhite"
                                >
                            </v-btn>
                        </v-badge>
                    </div>

                    <p class="px-0 text-right">
                        {{ getDisplayMonthWrapper + ' ' + getWeekDayDateWrapper('mon', date).getFullYear() }}
                    </p>

                    <p
                        class="px-0 text-right"
                        style="font-size: medium"
                    >
                        KW {{ getWeekDayDateWrapper('mon', date).getWeek() }}
                    </p>

                    <div
                        class="d-inline-flex px-0 text-right"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    :disabled="!canEdit"
                                    x-small
                                    elevation="2"
                                    style="width: 48px; height: 48px; margin-right: 16px"
                                    :style="`background-color: ${editMode? '#e6231e80': '#f8f8f880'}`"
                                    v-on="on"
                                    @click="toggleEditMode"
                                >
                                    <img
                                        :src="bearbeitenIcon"
                                        alt="Bearbeiten"
                                        style="height: 32px"
                                        :style="`${editMode ? 'filter: brightness(1.25)' : ''}`"
                                        class="iconToWhite"
                                    >
                                </v-btn>
                            </template>
                            <span>{{ canEdit ? 'Hier können sie den Stundenplan ihrer Klasse bearbeiten.' : 'Sie müssen die/der KlassenlehrerIn einer Klasse sein um deren Stundenplan zu bearbeiten.' }}</span>
                        </v-tooltip>

                        <v-btn
                            x-small
                            elevation="2"
                            style="background-color: #f8f8f880; width: 48px; height: 48px"
                            @click="closeDialog"
                        >
                            <img
                                :src="schliessenIcon"
                                alt="Schließensymbol"
                                style="height: 40px;"
                                class="iconToWhite"
                            >
                        </v-btn>
                    </div>
                </div>
                <!--#endregion -->

                <!--#region Timetable -->
                <v-row style="width: 99%; margin: 1em auto 0 auto;">
                    <th style="width: 7.5%">
                        <img
                            :src="uhrIcon"
                            alt="Uhrsymbol"
                            width="24"
                            height="24"
                        >
                    </th>

                    <div style="width: 2%" />

                    <div
                        v-for="(dayString, index) in dayStrings"
                        :key="`dailyHeaderInWeek${dayString}${index}`"
                        class="d-flex centerHeader"
                        :class="`${dayString === 'tue' || dayString === 'thu' ? 'greyDayBody' : ''}`"
                        style="width: 18%; border-radius: 5px 5px 0 0"
                    >
                        <!-- spacer -->
                        <div
                            v-if="dayString !== 'mon'"
                            style="width: 5%; height: 100%"
                        />

                        <v-row>
                            <th
                                :class="`
                                    ${isDisplayDateTodayShort(nicelyFormattedWeekDayDate(dayString))
                                ? 'colHeaderCurrent' : 'colHeader'}
                                     text-center`"
                                style="width: 100%; padding-top: 5px"
                            >
                                {{ nicelyFormattedWeekDayDate(dayString) }}
                            </th>
                        </v-row>
                    </div>
                </v-row>

                <vue-custom-scrollbar
                    class="scroll-area"
                    :settings="settings"
                >
                    <v-row
                        v-for="(timeslot,index) in slots"
                        :key="index"
                        style="width: 99%; margin: 0 auto 0 auto;"
                    >
                        <!--#region Timeslots -->
                        <td
                            style="width: 7.5%; color: var(--v-dunkelgrau-base); font-weight: normal;"
                            class="d-flex align-center justify-center pa-2"
                        >
                            {{ ("0" + new Date(timeslot.start).getHours()).slice(-2) + ":" + ("0" + new Date(timeslot.start).getMinutes()).slice(-2) }}
                            <br>
                            {{ ("0" + new Date(timeslot.end).getHours()).slice(-2) + ":" + ("0" + new Date(timeslot.end).getMinutes()).slice(-2) }}
                        </td>

                        <div style="width: 2%" />
                        <!--#endregion -->

                        <!--#region Days -->
                        <!-- can't rename index in v-for -->
                        <div
                            v-for="(dayString) in dayStrings"
                            :key="`dayInWeek${dayString}`"
                            class="d-flex"
                            :class="`${dayString === 'tue' || dayString === 'thu' ? 'greyDayBody' : ''}`"
                            style="width: 18%"
                        >
                            <!-- spacer -->
                            <div
                                v-if="dayString !== 'mon'"
                                style="width: 5%; height: 100%"
                            />

                            <!--                                class="centerFach"-->
                            <v-row
                                :style="index !== Object.keys(slots).length - 1 ? '' : 'border-radius: 0 0 10px 10px'"
                            >
                                <div
                                    v-for="appointment in getAppointmentsPerDay(timeslot._id, dayString)"
                                    :key="appointment._id + dayString"
                                    style="width: 100%"
                                >
                                    <FachVerwaltung
                                        v-if="editMode"
                                        :group="currentGroup"
                                        :request-group="requestGroups"
                                        :timeslot="timeslot"
                                        :day="getWeekDayDateWrapper(dayString, date)"
                                        :appointment="appointment"
                                    />
                                    <FachLehrer
                                        v-else
                                        class="py-0"
                                        :is-running="isTimeslotCurrentlyRunning(timeslot, dayString)"
                                        :appointment="appointment"
                                        :request-appointments="requestAppointments"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                    />
                                </div>
                                <div
                                    v-if="!getAppointmentsPerDay(timeslot._id, dayString).length"
                                    style="width: 100%"
                                >
                                    <FachVerwaltung
                                        v-if="editMode"
                                        :group="currentGroup"
                                        :request-group="requestGroups"
                                        :timeslot="timeslot"
                                        :day="getWeekDayDateWrapper(dayString, date)"
                                    />
                                    <FachLehrer
                                        v-else
                                        :is-running="isTimeslotCurrentlyRunning(timeslot, dayString)"
                                        :request-appointments="requestAppointments"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                    />
                                </div>
                            </v-row>
                        </div>
                        <!--#endregion -->
                    </v-row>
                </vue-custom-scrollbar>
                <!--#endregion -->
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import * as backend from "../../api/backend";

import FachLehrer from "../Lehrer/FachLehrer";
import FachVerwaltung from "@/components/Verwaltung/FachVerwaltung";
import vueCustomScrollbar from "vue-custom-scrollbar";
import linksIcon from '../../assets/Icons/links-filled-10.svg'
import rechtsIcon from '../../assets/Icons/rechts-filled-11.svg'
import schliessenIcon from '../../assets/Icons/abbrechen-08.svg'
import stundenplanIcon from '../../assets/Icons/timetable.svg'
import uhrIcon from '../../assets/Icons/uhr-04.svg'
import bearbeitenIcon from '../../assets/Icons/bearbeiten-komplimentär-weiß-88.svg'
import { getWeekDayDate, getDayDateLocaleString, getDisplayMonthLong, shortWeekDayWithLocaleDateString } from "@/util/timetable";

export default {
    name: "WeeklyViewDialog",
    components: {
        FachLehrer,
        vueCustomScrollbar,
        FachVerwaltung,
    },
    props: {
        slotsProp: { required: true, type: Array },
        appointmentsProp: { required: true, type: Array },
        setEditMode: { required: false, type: Boolean },
    },
    data: () => ({
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
            //left out keyboard
            handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch']
        },
        date: new Date(),
        today: new Date(),
        pastWeek: 0,
        futureWeek: 0,
        slots: [],
        appointments: [],
        localOpen: false,
        dayStrings: ['mon', 'tue', 'wed', 'thu', 'fri'],

        teacher: null,
        // Edit mode
        editMode: false,
        groups: [],
        groupIndex: 0,
        // Icons
        stundenplanIcon,
        linksIcon,
        rechtsIcon,
        schliessenIcon,
        uhrIcon,
        bearbeitenIcon,
    }),
    computed: {
        currentGroup() {
            return this.teacherGroups[this.groupIndex];
        },
        currentGroupAppointments() {
            return this.currentGroup ? this.currentGroup.appointments || [] : [];
        },
        teacherGroups() {
            return this.groups.filter(group => group.leaders.includes(this.teacher._id));
        },
        canEdit() {
            return this.currentGroup !== null && this.currentGroup !== undefined;
        },
        nicelyFormattedWeekDayDate() {
            return (dayString) => {
                let shortDay = 'Mo., ';
                switch (dayString) {
                    case 'mon': shortDay = 'Mo., '; break;
                    case 'tue': shortDay = 'Di., '; break;
                    case 'wed': shortDay = 'Mi., '; break;
                    case 'thu': shortDay = 'Do., '; break;
                    case 'fri': shortDay = 'Fr., '; break;
                }

                const weekDayDate = this.getWeekDayDateWrapper(dayString, this.date);
                return shortDay + getDayDateLocaleString(weekDayDate);
            }
        },
        //to display month string in header
        getDisplayMonthWrapper() {
            return getDisplayMonthLong(this.date);
        },
    },
    async mounted() {
        this.localOpen = true;
        await this.requestAppointments();
        await this.requestTeacher();
        await this.requestGroups();
        if(this.setEditMode === true) {
            if(this.canEdit) {
                this.editMode = true;
            } else {
                this.showSnackbar({ message: 'Sie sind kein Klassenlehrer. Nur Klassenlehrer können die Stundenpläne ihrer Klasse bearbeiten.', color: 'error', timeout: 6500 });
            }
            this.$emit("resetEditModeChanger");
        }
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions("groups", ["getGroups"]),
        ...mapActions("teachers", ["getMeTeacher"]),
        ...mapActions("copypaste", ["turnOffCopyMode"]),
        ...mapMutations("snackbar", ["showSnackbar"]),

        customFilter(item, queryText, itemText) {
            const textOne = item.name.toLowerCase();
            const searchText = queryText.toLowerCase();

            return textOne.indexOf(searchText) > -1;
        },
        autocompleteChange(value) {
          this.groupIndex = this.teacherGroups.findIndex(group => group.name === value)
        },

        closeDialog() {
            this.localOpen = false;
            this.$emit('closeDialog');
        },
        receivedEmit(callback) {
            this.$emit('start-bbb-tut', callback);
        },
        async requestAppointments(useBackend) {
            try {
                this.slots = this.slotsProp || await this.getSlots();
                if(useBackend) {
                    const res = await backend.getSchoolSubjectAppointments();
                    this.appointments = await res.json();
                    await this.requestGroups();
                } else {
                    this.appointments = this.appointmentsProp || await this.getAppointments();
                }
                await this.checkParams();
                this.countBadgesWeek();
            } catch (e) {
                console.warn(e);
            }
        },
        async requestTeacher() {
            this.teacher = await this.getMeTeacher();
        },
        async requestGroups() {
            this.groups = await this.getGroups(true);
        },
        async checkParams() {
            let appointment = this.appointments.find((el) => { return this.$route.query.appointment === el._id })
            if (appointment) {
                this.date = new Date(appointment.day)
                /*appointment['open'] = true*/
            }
        },
        countBadges() {
            this.future = 0;
            this.past = 0;
            if (!Array.isArray(this.appointments)) return;
            this.appointments.forEach((el) => {
                if (!this.sameDay(new Date(el.day), this.date) && el.teacherUploads) {
                    if (new Date(el.day) > this.date) {
                        this.future += el.teacherUploads.filter((element) => !element.seenByMe).length;
                    } else {
                        this.past += el.teacherUploads.filter((element) => !element.seenByMe).length;
                    }
                }
            })
        },

        countBadgesWeek() {
            this.futureWeek = 0;
            this.pastWeek = 0;
            if (!Array.isArray(this.appointments)) return;
            this.appointments.forEach((el) => {
                if (!(new Date(el.day).getWeek() === this.date.getWeek()) && el.teacherUploads) {
                    if (new Date(el.day) > this.date) {
                        this.futureWeek += el.teacherUploads.filter((element) => !element.seenByMe).length;
                    } else {
                        this.pastWeek += el.teacherUploads.filter((element) => !element.seenByMe).length;
                    }
                }
            })
        },
        addWeek() {
            this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + 7);
            this.countBadges();
            this.countBadgesWeek();
        },
        subWeek() {
            this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 7);
            this.countBadges();
            this.countBadgesWeek();
        },
        jumpToCurrentDay() {
            this.date = this.today;
        },
        getWeekDayDateWrapper(dayString, date) {
            return getWeekDayDate(dayString, date);
        },
        //WEEKLY VIEW Version | compare displayDate with today, return true if equal, to color current day
        isDisplayDateTodayShort(compareDate) {
            const heute = getDayDateLocaleString(this.today);
            return (compareDate.toString()).slice(5, compareDate.toString().length) === heute;
        },
        getAppointmentsPerDay(slot, day) {
            day = getWeekDayDate(day, this.date);
            if(this.editMode) {
                return this.currentGroupAppointments.filter((el) => {
                    return (el.timeslot === slot && this.sameDay(new Date(el.day), day))
                })
            } else {
                return this.appointments.filter((el) => {
                    return (el.timeslot === slot && this.sameDay(new Date(el.day), day))
                })
            }
        },
        sameDay(date, otherDate) {
            return date.toLocaleDateString() === otherDate.toLocaleDateString();
        },
        isTimeslotCurrentlyRunning(timeslot, day) {
            const currentTimeAndDay = this.today;
            const appointment = this.getAppointmentsPerDay(timeslot._id, day)[0];
            if (appointment) {
                const appointmentDate = new Date(appointment.day).toDateString();
                if (appointmentDate === currentTimeAndDay.toDateString()) {
                    // If we are in here that means the appointment is today
                    if (new Date(timeslot.start).toLocaleTimeString('de-DE') <= currentTimeAndDay.toLocaleTimeString('de-DE')
                        && new Date(timeslot.end).toLocaleTimeString('de-DE') > currentTimeAndDay.toLocaleTimeString('de-DE')) {
                        // Currenttime is between start and end of timeslot
                        return true;
                    }
                }
            }
            return false;
        },
        async toggleEditMode() {
            this.editMode = !this.editMode;

            if (!this.editMode) {
                this.appointments = await this.getAppointments(true);
                this.turnOffCopyMode();
            }
        },
    }
}
</script>

<style lang="scss" scoped>
table,
th,
td {
    border: none;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

th {
    vertical-align: top;
}

table tr th:first-child,
table tr td:first-child {
    padding-top: 10px;
    width: 10px !important;
}

table tr th:last-child,
table tr td:last-child {
    width: 5px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table,
tr,
td {
    border: none;
}

h1 {
    display: inherit;
    font-size: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    margin-right: inherit;
    font-weight: inherit;
}

h2 {
    display: inherit;
    font-size: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    margin-right: inherit;
    font-weight: inherit;
}

.fach {
    cursor: pointer;
}

.whiteBg {
    background-color: #ffffff;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
        hue-rotate(36deg) brightness(104%) contrast(105%);
}

.iconToBlue {
    filter: invert(61%) sepia(43%) saturate(515%) hue-rotate(175deg)
        brightness(87%) contrast(82%);
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(65vh - 30px);
}

.widgetContainer {
    height: auto;
    width: 100%;
    min-height: 80vh;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto auto 5em;
}

.planWidgetDisplayDate {
    display: inline-block;
    font-weight: normal;
    font-size: large;
}

.planWidgetTodayButtonActive {
    background-color: #6995cd !important;
    color: white;
    border-radius: 8px;
}

.planWidgetTodayButton {
    background-color: var(--v-hellgrau-base) !important;
    color: var(--v-dunkelgrau-base);
    border-radius: 8px;
    box-shadow: 1px 5px 5px silver;
}

.colHeader {
    color: var(--v-dunkelgrau-base);
    width: 18%;
}

.colHeaderCurrent {
    color: #6995cd;
    width: 18%;
}

.greyDayHeader {
    background-color: var(--v-hellgrau-base);
    border-radius: 10px 10px 0 0;
}

.greyDayBody {
    background-color: var(--v-hellgrau-base);
}

.centerFach {
    padding-left: 1%;
}

.centerHeader {
    display: flex;
    align-content: center;
}

@media only screen and (min-width: 901px) and (max-width: 1050px) {
    .calWeek {
        display: none;
    }
}

@media only screen and (max-width: 959px) {
    .clockIcon {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .clockIcon {
        display: initial;
    }

    .widgetContainer {
        width: 90%;
    }
}

#weeklyViewHeaderContainer {
    background-color: #6995CD;
    border-radius: 15px 15px 0 0;
    padding: 10px;
    color: white;
}
</style>
