import * as backend from "@/api/backend";
import {checkResponseStatus} from "@/util/check";

const defaultState = {
};

const mutations = {
};

const getters = {
    videoTutorialsByFilename( state, getters ) {
        return getters.videoTutorials.reduce((acc, tutorial) => {
            acc[tutorial.filename] = tutorial;
            return acc;
        }, {})
    },
    videoTutorials() {
        return [{
            _id: 'Verwaltung_15Min_erklaert.mp4',
            filename: 'Verwaltung_15Min_erklaert.mp4',
            title: 'eKlara für die Verwaltung in 15 Minuten erklärt',
            location: 'verwaltung.plan',
            role: 'maintainer',
        },
        {
            _id: 'Lehrer_in_8Min_erklaert.mp4',
            filename: 'Lehrer_in_8Min_erklaert.mp4',
            title: 'eKlara für Lehrer in 8 Minuten erklärt',
            location: 'lehrer.plan',
            role: 'teacher',
        },
        {
            _id: 'Normal_in_4Min_erklaert.mp4',
            filename: 'Normal_in_4Min_erklaert.mp4',
            title: 'eKlara in 4 Minuten erklärt',
            location: 'schueler.plan',
            role: 'pupil',
        },
        {
            _id: 'Einfach_in_4Min_erklaert.mp4',
            filename: 'Einfach_in_4Min_erklaert.mp4',
            title: 'eKlara in 4 Minuten erklärt',
            location: 'baby.plan',
            role: 'baby',
        },
        {
            _id: 'Eltern_in_5Min_erklaert.mp4',
            filename: 'Eltern_in_5Min_erklaert.mp4',
            title: 'eKlara in 5 Minuten erklärt',
            location: 'eltern.plan',
            role: 'parent',
        },
        // Not used currently
        // {
        //     _id: 'HA_erstellt_Autokorrektur.mp4',
        //     filename: 'HA_erstellt_Autokorrektur.mp4',
        //     title: 'Der eKlara Editor erklärt',
        //     location: 'lehrer.create',
        //     role: 'teacher',
        // },
        {
            _id: 'Lehrer_bbb_erklaert.mp4',
            filename: 'Lehrer_bbb_erklaert.mp4',
            title: 'Ein Überblick über BBB',
            location: 'lehrer.bbb',
            role: 'teacher',
        },
        {
            _id: 'Normal_bbb_in_3Min_erklaert.mp4',
            filename: 'Normal_bbb_in_3Min_erklaert.mp4',
            title: 'Ein Überblick über BBB',
            location: 'schueler.bbb',
            role: 'pupil',
        },
        {
            _id: 'Einfach_bbb_in_3Min_erklaert.mp4',
            filename: 'Einfach_bbb_in_3Min_erklaert.mp4',
            title: 'Ein Überblick über BBB',
            location: 'baby.bbb',
            role: 'baby',
        }];
    }
};

const actions = {
    async getTutorialProperties({ commit, state }, params) {
        try {
            const videoTutorialFilename = params.videoTutorialFilename;
            delete params.videoTutorialFilename;
            const res = await backend.getVideoTutorialUserProperties(videoTutorialFilename, params);
            return await res.json();
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async updateTutorialProperties({ commit, state }, params) {
        try {
            const videoTutorialFilename = params.videoTutorialFilename;
            delete params.videoTutorialFilename;
            await backend.patchVideoTutorialUserProperties(videoTutorialFilename, params);
        }
        catch(err) {
            console.log('error', err);
            console.error(err);
            return err?.response?.status;
        }
    }
};


export default {
    namespaced: true,
    state: defaultState,
    mutations,
    getters,
    actions,
};
