<template>
    <div>
        <v-btn
            id="muelleimerBtn"
            class="mr-1"
            color="grau"
            icon
            :small="windowWidth <= 900 && windowWidth > 770"
            :x-small="windowWidth <= 770"
            @click="openAreYouSure"
        >
            <v-icon>fas fa-trash-alt</v-icon>
        </v-btn>

        <!-- Are you sure dialog for delete chat -->
        <AreYouSurePopup
            v-if="showAreYouSure"
            :show-dialog="showAreYouSure"
            :content-text-prop="'Dieser Chat und alle Daten darin (Text- und Sprachnachrichten) werden endgültig gelöscht!'"
            @close="showAreYouSure = false"
            @ok="deleteRoom"
        />
    </div>
</template>
<script>
import {mapActions, mapMutations} from "vuex";
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup";

export default {
    name: 'DeleteChatButton',
    components: {AreYouSurePopup},
    props: {
        room: { required: true, type: Object },
    },
    data: () => ({
        showAreYouSure: false,
    }),
    computed: {
        windowWidth() {
            return window.innerWidth;
        },
    },
    methods: {
        ...mapActions('matrix', [ 'deleteMatrixRoom' ]),
        ...mapMutations("snackbar", ["showSnackbar"]),

        openAreYouSure(event) {
            event.stopPropagation();
            this.showAreYouSure = true;
        },

        async deleteRoom() {
            try {
                const response = await this.deleteMatrixRoom(this.room._id);
                if (response.status !== 200) {
                   if (response === 404) {
                       this.showSnackbar({
                           message: `Der Chatraum wurde nicht gefunden. Die Raumliste wurde aktualisiert.`,
                           color: 'warning'
                       });
                   }
                } else {
                    const resJson = await response.json();
                    if (resJson) {
                        this.$emit('updateRoomList');
                        this.showSnackbar({
                            message: 'Chatraum wurde erfolgreich gelöscht!',
                            color: 'success'
                        });
                    }
                }
            } catch (e) {
                this.showSnackbar({
                    message: `Ein Fehler ist aufgetreten! ${e.message}`,
                    color: 'error'
                });
            }
            this.$emit('updateRoomList');
            this.showAreYouSure = false;
        }
    }
}
</script>

<style scoped lang="scss">
#muelleimerBtn {
    height: 45px;
    min-width: 45px;
    width: 45px;
}

@media only screen and (max-width: 900px) {
    #muelleimerBtn {
        height: 40px;
        min-width: 40px;
        width: 40px;
    }
}
</style>
