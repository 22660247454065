<template>
    <div class="group-list-container">
        <div class="group-chat-header py-4 pl-4">
            <CreateChatRoomPage
                v-if="addNew"
                class="pr-4"
                style="display: flex; width: 100%"
                @close="addNew = false"
                @selectRoom="selectRoom"
            />
            <div
                v-else
                class="d-flex align-center justify-space-between"
                style="width: 100%"
            >
                <p
                    id="gruppenHeading"
                    tabindex="0"
                    class="mb-0"
                >
                    Chatgruppen
                </p>
                <v-spacer />
                <v-tooltip
                    :disabled="!showTooltips"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            id="newChatButton"
                            tabindex="0"
                            x-small
                            fab
                            plain
                            text
                            min-width="20"
                            class="pa-0 mr-2"
                            style="margin-left: auto; margin-right: 0; cursor: pointer; height: 20px; width: 20px;"
                            v-on="on"
                            @click="
                                () => {
                                    addNew = true;
                                    contact = '';
                                    setFocusOn('searchArea');
                                }
                            "
                        >
                            <img
                                alt="Neuer Chat"
                                :src="hinzufuegenIcon"
                                class="iconToWhite"
                                style="display: block; height: 20px"
                            >
                        </v-btn>
                    </template>
                    <span>Neuer Chat</span>
                </v-tooltip>
                <ChatWidgetMenu
                    :show-tooltips="showTooltips"
                    :can-user-leave-chatroom="false"
                />
            </div>
        </div>
        <vue-custom-scrollbar
            v-if="mappedChatRooms.length > 0"
            class="scroll-area"
            :settings="settings"
        >
            <div class="chat-body pa-0">
                <div
                    v-for="room in mappedChatRooms"
                    :key="room._id"
                    style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: solid;
            border-color: rgba(0, 0, 0, 0.12);
            border-width: 1px;
          "
                    class="group-item"
                    tabindex="0"
                    :color="room.isOpen ? '' : 'red'"
                    @click="() => selectRoom(room)"
                    @keypress.enter="() => selectRoom(room)"
                    @keypress.space="() => selectRoom(room)"
                >
                    <div class="ma-3">
                        <!-- ":value" in v-badge equals "to render or not to render" -->
                        <v-badge
                            color="red"
                            :content="room.newMessagesCount"
                            :value="room.newMessagesCount"
                            overlap
                            offset-x="15"
                            style="z-index: 9"
                        >
                            <v-avatar
                                medium
                                fab
                                color="#6994cc"
                                height="56"
                                width="56"
                            >
                                <img
                                    v-if="room.type === 'privateChat'"
                                    aria-hidden="true"
                                    :src="chatIcon"
                                    alt="Chat"
                                    class="iconToWhite chatIcon"
                                >
                                <img
                                    v-else
                                    aria-hidden="true"
                                    :src="groupIcon"
                                    alt="Chat"
                                    class="iconToWhite chatIcon"
                                >
                            </v-avatar>
                        </v-badge>
                    </div>
                    <div
                        role="button"
                        class="greyText chat-name"
                    >
                        {{ room.displayName }}
                    </div>
                    <v-spacer />
                    <!-- Can be switched to menu component later -->
                    <!-- v-if="canUserDeleteRoom(room)" -->
                    <!-- <DeleteChatButtonV2
                        :room="room"
                    /> -->
                    <!-- v-if="!isTeacher(room) && room.name !== 'Lehrer'" -->
                    <!-- @lockStatusChanged="handleLockStatusChanged" -->
                    <img
                        v-if="room.type === 'groupChat'"
                        :src="room.isOpen ? schlossOffenIcon : schlossIcon"
                        :class="room.isOpen ? 'iconToGreen' : 'iconToGrey'"
                        :alt="room.isOpen ? 'Chat offen' : 'Chat geschlossen'"
                        style="width: 32px; height: 32px; margin-right: 16px"
                    >
                </div>
            </div>
        </vue-custom-scrollbar>

        <div
            v-else
            class="pa-4 mb-0"
            style="color: var(--v-dunkelgrau-base)"
        >
            Keine Gruppen
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
// import DeleteChatButtonV2 from "@/components/ChatV2/Components/DeleteChatButtonV2";
import vueCustomScrollbar from "vue-custom-scrollbar";
import hinzufuegenIcon from "../../assets/Icons/hinzufuegen-07.svg";
import chatIcon from "../../assets/Icons/chat-alternative-filled-102.svg";
import CreateChatRoomPage from "./Components/CreateChatRoomPage";
import groupIcon from "../../assets/Icons/gruppen-normal-25.svg";
import schlossIcon from "../../assets/Icons/lock-closed.svg";
import schlossOffenIcon from "../../assets/Icons/lock-open.svg";
import infoIcon from "@/assets/Icons/info-2-weiß-89.svg";
import ToggleChatNotificationsButton
    from "@/components/ChatV2/Components/ToggleBrowserChatNotifications/ToggleChatNotificationsButton";
import ChatWidgetMenu from "@/components/ChatV2/Components/ChatWidgetMenu";

export default {
  name: "ChatWidgetGroupListV2",
  components: {
      ChatWidgetMenu,
    // DeleteChatButtonV2,
    vueCustomScrollbar,
    CreateChatRoomPage,
  },
  props: {
    showTooltips: { required: false, default: true },
    currentChild: { required: false, type: Object },
  },
  data: () => ({
    matrixUsers: [],
    maintainers: [],
    addNew: false,
    settings: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false,
    },

    teachers: [],
    teacherPics: [], //collection of teacher profile pictures

    systemCreatedMatrixRoomsIds: [], // ids of group chats generated by the system

    hinzufuegenIcon,
    chatIcon,
    groupIcon,
    schlossIcon,
    schlossOffenIcon,
    infoIcon,
      person: {
          accessibility: {
              screenreader: false,
          }
      },
  }),
  computed: {
      ...mapState('translation', ['targetLang']),
      ...mapGetters("auth", ["accountRole", "accountId"]),
    ...mapGetters("chat", [
      "chatRooms",
      "chatRoomEvents",
      "chatRoomEventsByChatRoom",
    ]),
    mappedChatRooms() {
      // get the new messages in a chatRoom for a given account
      const getNewMessagesCount = (chatRoom, accountId) => {
        const accountTimestampObject = chatRoom.lastSeenTimestamps.find(
          ({ timestamp, account }) => {
            if (account === accountId) {
              return true;
            }
          }
        );
        const lastSeenTimestamp = accountTimestampObject?.timestamp || 0;

        const chatRoomEvents =
          this.chatRoomEventsByChatRoom[chatRoom._id] || [];
        const newChatRoomEvents = chatRoomEvents.filter(
          (chatRoomEvent) =>
            new Date(chatRoomEvent.createdAt) >= new Date(lastSeenTimestamp)
        );
        return newChatRoomEvents.length;
      };

      const getLastEventTime = (chatRoom) => {
        const chatRoomEvents =
          this.chatRoomEventsByChatRoom[chatRoom._id] || [];
        const lastChatRoomEvent = chatRoomEvents[chatRoomEvents.length - 1];
        const lastChatRoomEventTime = lastChatRoomEvent
          ? new Date(lastChatRoomEvent.createdAt).getTime()
          : 0;
        return lastChatRoomEventTime;
      };

      return this.chatRooms
        .map((room) => ({
          ...room,
          newMessagesCount: getNewMessagesCount(room, this.accountId),
        }))
        .sort(
          (room1, room2) => getLastEventTime(room2) - getLastEventTime(room1)
        );
    },

    canUserDeleteRoom(room) {
      return (room) => {
        return false;
      };
    },
  },
  watch: {
    currentChild: async function () {
      if (this.accountRole === "parent" && this.currentChild) {
        const appointments = await this.getAppointmentsParent(
          this.currentChild._id
        );
        appointments.forEach((el) => {
          if (el.teachers) {
            if (el.teachers instanceof Array) {
              el.teachers.forEach((element) => {
                const index = this.matrixUsers.indexOf(element);
                if (index === -1) {
                  this.matrixUsers.push(element);
                }
              });
            } else {
              this.matrixUsers.indexOf(el.teachers);
              if (this.matrixUsers.indexOf(el.teachers) === -1) {
                this.matrixUsers.push(el.teachers);
              }
            }
          }
        });
      }
    },
  },
  async mounted() {
    await Promise.all([this.requestAppointments(), this.requestPupil()]);
    await this.requestTeachers();
    await this.requestMaintainers();
    this.getSystemCreateRoomIds();
  },
  async unmounted() {
    this.teacherPics.forEach(async (el) => {
      if (el[2]) {
        URL.revokeObjectURL(el[2]);
      }
    });
  },
  methods: {
      ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
      ...mapActions("appointments", ["getAppointments", "getAppointmentsParent"]),
    ...mapActions("pupils", ["getPupils"]),
    ...mapActions("matrix", ["createCustomRoom"]),
    ...mapActions("parents", ["getParents"]),
    ...mapActions("teachers", [
      "getProfilePicture",
      "getTeachers",
      "getMeTeacher",
    ]),
    ...mapActions("maintainers", ["getMaintainers"]),
    ...mapActions("groups", ["getGroupsInfo"]),
    ...mapMutations("snackbar", ["showSnackbar"]),
    ...mapMutations("badges", ["setChatBadge"]),

    async setFocusOn(elementId) {
      await this.$nextTick();
      document.getElementById(elementId).focus();
    },
    async requestAppointments() {
      if (this.accountRole === "pupil") {
        const appointments = await this.getAppointments();
        appointments.forEach((el) => {
          if (el.teachers) {
            if (el.teachers instanceof Array) {
              el.teachers.forEach((element) => {
                const index = this.matrixUsers.indexOf(element);
                if (index === -1) {
                  this.matrixUsers.push(element);
                }
              });
            } else {
              this.matrixUsers.indexOf(el.teachers);
              if (this.matrixUsers.indexOf(el.teachers) === -1) {
                this.matrixUsers.push(el.teachers);
              }
            }
          }
        });
      }
      if (this.accountRole === "parent" && this.currentChild) {
        const appointments = await this.getAppointmentsParent(
          this.currentChild._id
        );
        appointments.forEach((el) => {
          if (el.teachers) {
            if (el.teachers instanceof Array) {
              el.teachers.forEach((element) => {
                const index = this.matrixUsers.indexOf(element);
                if (index === -1) {
                  this.matrixUsers.push(element);
                }
              });
            } else {
              this.matrixUsers.indexOf(el.teachers);
              if (this.matrixUsers.indexOf(el.teachers) === -1) {
                this.matrixUsers.push(el.teachers);
              }
            }
          }
        });
      }
    },
    async requestPupil() {
      if (this.accountRole === "teacher") {
        const pupils = await this.getPupils();
        const teachers = await this.getTeachers();
        const parents = await this.getParents();

        const mappedPupils = pupils.map((pupil) => ({
          ...pupil,
          account: pupil.account,
        }));
        let mappedTeachers = teachers.map((teacher) => ({
          ...teacher,
          account: teacher.account,
        }));
        const mappedParents = parents.map((parent) => ({
          ...parent,
          account: parent.account,
          name:
            pupils.find((pupil) => pupil._id === parent.pupils[0])?.name ||
            "Unbekannt",
          lastName: "Eltern",
        }));

        // filter out teacher that's using the chat so he can't try to start chat with himself
        const me = await this.getMeTeacher();
        mappedTeachers = mappedTeachers.filter(
          (teacher) =>
            teacher.name !== me.name && teacher.lastName !== me.lastName
        );

        this.matrixUsers = [
          ...mappedPupils,
          ...mappedTeachers,
          ...mappedParents,
        ].sort((a, b) => a.name.localeCompare(b.name));
        this.matrixUsers.map(
          (el) => (el.fullName = el.name + " " + el.lastName)
        );
      }
    },
    async requestTeachers() {
      this.teachers = await this.getTeachers();
      if (this.accountRole === "maintainer") {
        this.matrixUsers = await JSON.parse(JSON.stringify(this.teachers));
        this.matrixUsers.map(
          (el) => (el.fullName = el.name + " " + el.lastName)
        );
        this.matrixUsers.sort((a, b) => a.name.localeCompare(b.name));
      }

      //trim down the ridiculous size of duplicate matrixUsers, only keep unique entries
      this.matrixUsers = [
        ...new Set(this.matrixUsers.map((el) => JSON.stringify(el))),
      ].map((str) => JSON.parse(str));
      this.matrixUsers.map((el) => (el.fullName = el.name + " " + el.lastName));
      await this.setTeacherProfilePics();
    },
    async requestMaintainers() {
      if (this.accountRole === "teacher") {
        this.maintainers = await this.getMaintainers();
      }
    },
    selectRoom(room) {
      this.$emit("roomSelected", room);
    },
    // Get the highest role ('moderator', 'user') of a account in a chatRoom
    getChatRoomMembershipRole(chatRoom, accountId) {
        const memberships = chatRoom.memberships.filter((membership) =>
          membership.addressedAccounts.find(
            (addressedAccount) => addressedAccount === accountId
          )
        );

        const isModerator = memberships.find(
          (membership) => membership.role === "moderator"
        );
        return isModerator ? "moderator" : "member";
      },
    async setTeacherProfilePics() {
      this.teachers.map(async (teacher) => {
        let matchingTeacher = this.matrixUsers.find((matrixUser) => {
          return teacher.account == matrixUser.account;
        });
        if (matchingTeacher) {
          if (matchingTeacher.profilePicture) {
            let profilePictureBlob = await this.getProfilePicture(
              matchingTeacher._id
            );
            const objectURL = URL.createObjectURL(profilePictureBlob);
            this.teacherPics.push([
              matchingTeacher.name,
              matchingTeacher.lastName,
              objectURL,
            ]);
          }
        }
      });
    },

    // saves the ids of the system created matrix room ids into systemCreateMatrixRoomsIds
    async getSystemCreateRoomIds() {
      let groups = await this.getGroupsInfo();
      this.systemCreatedMatrixRoomsIds = groups.map((el) => el.matrixRoom);
      this.systemCreatedMatrixRoomsIds =
        this.systemCreatedMatrixRoomsIds.filter((el) => {
          return el !== undefined;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.group-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.group-chat-header-btn {
  padding: 0 !important;
  min-width: 0 !important;
}

.group-chat-header {
  background-color: #8cbd46; // 8CBD46 - 5% Luminosity
  color: white;
  min-height: 56px;
}

.chat-container {
  height: auto;
  width: 90%;
  min-height: 80vh;
  border-radius: 15px;
  box-shadow: 1px 5px 5px silver;
  margin: auto auto 5em;
}

.chat-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chat-header {
  background-color: #8ab450;
  border-radius: 15px 15px 0 0;
  font-size: larger;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: white;
}

.chat-header-btn {
  background-color: #f8f8f880 !important;
  width: 35px;
  height: 35px !important;
}

.chat-header-img {
  height: 20px;
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
  flex: 1;
}

.greyText {
  color: var(--v-dunkelgrau-base);
}

.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.roundImage {
  border-radius: 50%;
  height: 56px;
  width: 56px;
}

.chatIcon {
  height: 25px;
}

#eK-button-disabled.v-btn--disabled {
  opacity: 0.5;
  background-color: var(--v-grau-base) !important;
  color: white !important;
}

.formHeader {
  font-size: 16px;
  margin-top: 40px !important;
  margin-bottom: 20px !important;
}

.iconToGreen {
  filter: brightness(0) saturate(100%) invert(64%) sepia(40%) saturate(579%)
    hue-rotate(43deg) brightness(98%) contrast(91%) !important;
}

.iconToGrey {
  filter: brightness(0) saturate(100%) invert(89%) sepia(0%) saturate(0%)
    hue-rotate(326deg) brightness(87%) contrast(88%) !important;
}

</style>
