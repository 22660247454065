import * as backend from "@/api/backend";

const state = {
    targetLang: 'de',
    translatedText: '',
    translationAllowed: true,
    show: false,
};

const actions = {
    setTargetLang ({ commit, state}, lang){

        if (lang !== 'de' && !state.translationAllowed) {
            console.log('translation not allowed!!');
            commit('snackbar/showSnackbar', {
                color: 'warning',
                message: 'Frage deine*n Lehrer*in damit dir die Übersetzung freigeschaltet wird.'
            }, { root: true });
            return false;
        } else {
            commit('SET_TARGET_LANG', lang);
            return true;
        }
    },
    setTranslatedText ({commit}, text){
        commit('SET_TRANSLATED_TEXT', text);
    },
    showTranslation ({commit}, show) {
        commit('SET_SHOW', show);
    },
    setTranslationAllowed ({ state, commit }, allowed) {
        if (!allowed && state.targetLang !== 'de') {
            commit('SET_TARGET_LANG', 'de');
        }
        commit('SET_TRANSLATION_ALLOWED', allowed);
    },

    async translateToTargetLang({ commit, dispatch }, {targetLang, textToTranslate, textFormat}) {
        try {
            let params;
            if(textFormat) {
                params = {
                    targetLang: targetLang,
                    textToTranslate: textToTranslate,
                    textFormat: textFormat,
                };
            } else {
                params = {
                    targetLang: targetLang,
                    textToTranslate: textToTranslate,
                    textFormat: 'text',
                };
            }

            const res = await backend.translateToTargetLang(params);

            const json = await res.json();
            return json.data.translations[0].translatedText;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    }
};

const getters = {
    getTargetLang: state => state.targetLang,
    getTranslatedText: state => state.translatedText,
    isTranslationShown: state => state.show,
};

const mutations = {
    SET_TARGET_LANG: (state, lang) => {
        state.targetLang = lang;
    },
    SET_TRANSLATED_TEXT: (state, text) => {
        state.translatedText = text;
    },
    SET_SHOW: (state, show) => {
        state.show = show;
    },
    SET_TRANSLATION_ALLOWED: (state, allowed) => {
        state.translationAllowed = allowed;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
