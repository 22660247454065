<template>
    <v-dialog
        v-model="showDialog"
        max-width="290"
        @click:outside="close"
    >
        <v-card>
            <v-card-title class="text-h5">
                Sind sie sicher?
            </v-card-title>

            <v-card-text>
                {{ contentText }}
            </v-card-text>

            <v-card-actions>
                <v-spacer />

                <v-btn
                    class="optionBtn text-none ml-2"
                    color="rot"
                    dark
                    @click="close"
                >
                    <img
                        :src="cancelIcon"
                        class="icon iconToWhite mr-2"
                        alt="abbrechen"
                    >
                    Abbrechen
                </v-btn>

                <v-btn
                    class="optionBtn text-none ml-2"
                    color="fhellgruen"
                    dark
                    @click="okClicked"
                >
                    <img
                        :src="finishIcon"
                        class="icon iconToWhite mr-2"
                        alt="bestätigen"
                    >
                    Löschen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import cancelIcon from '@/assets/Icons/abbrechen-08.svg';
import finishIcon from '@/assets/Icons/fertig-74.svg';

export default {
    name: 'AreYouSurePopup',
    props: {
        showDialog: { required: true, type: Boolean },
        contentTextProp: { required: false, type: String, default: '' },
    },
    data() {
        return {
            cancelIcon,
            finishIcon,
        }
    },
    computed: {
        contentText() {
            return this.contentTextProp || 'Wollen Sie diese Aktion wirklich ausführen?';
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        okClicked() {
            this.$emit('ok');
        }
    }
}
</script>

<style scoped>
.icon {
    height: 20px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}
</style>
