<template>
    <div>
        <!-- Schuljahreswechsel dialog -->
        <v-dialog
            v-model="changeYearDialog"
            max-height="95vh"
            persistent
            :max-width="chYr === 0 || chYr === 4 ? '600px' : chYr === 2 ? '1200px' : '700px'"
        >
            <!-- step 1 | confirm password -->
            <v-card v-if="chYr === 0">
                <v-card-title class="d-flex justify-space-between">
                    <p>Wollen Sie einen Schuljahreswechsel machen?</p>
                    <v-btn
                        icon
                        small
                        @click="discardChangesDialog = true"
                    >
                        <img
                            :src="cancelIcon"
                            class="iconBtn"
                            alt="Schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <p class="mb-2">
                        Es werden <b>umfassende Veränderungen</b> vorgenommen und
                        <b>Daten gelöscht</b>. Geben Sie zur Sicherheit daher bitte Ihr
                        Passwort ein, um fortzufahren.
                    </p>
                    <p>Passwort</p>
                    <v-text-field
                        v-model="confirmPassword"
                        :type="showPassword ? 'text' : 'password'"
                        outlined
                        dense
                        hide-details
                        class="mb-2"
                        style="max-width: 500px;"
                        @keypress.enter="clickContinue"
                    />
                    <v-checkbox
                        v-model="showPassword"
                        label="Passwort anzeigen"
                        color="dunkelgrau"
                        hide-details
                        class="ma-0 pa-0 pl-1"
                    />
                    <p
                        v-if="confirmPasswordWrong"
                        class="redText"
                    >
                        Falsches Passwort. Versuchen Sie es nochmal.
                    </p>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        elevation="0"
                        class="text-none"
                        color="gruen"
                        dark
                        @click="clickContinue"
                    >
                        Schuljahreswechsel beginnen
                    </v-btn>
                </v-card-actions>
            </v-card>

            <!-- step 2 | change group names -->
            <v-card v-else-if="chYr === 1">
                <v-card-title class="d-flex justify-space-between">
                    <p>Schuljahreswechsel (1/3) - Gruppen</p>
                    <v-btn
                        icon
                        small
                        @click="discardChangesDialog = true"
                    >
                        <img
                            :src="cancelIcon"
                            class="iconBtn"
                            alt="Schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <p class="mb-2">
                        Legen Sie die neuen Gruppenbezeichungen fest.
                        Sollten Sie der Gruppe einen neuen Namen geben wollen,
                        <b>geben</b> Sie als Gruppenname den neuen Namen ein
                        oder <b>wählen</b> Sie einen vorhandenen Eintrag aus dem Menü.
                        Wenn eine Klasse keinen Nachfolger hat, wie z.B. ein Abschlussjahrgang,
                        wählen Sie <b>nicht fortsetzen</b> aus.
                    </p>

                    <!-- just titles -->
                    <div
                        class="d-flex justify-space-between align-center mt-6"
                        style="width: 95%; color: black; font-weight: bold"
                    >
                        <div style="width: 40%">
                            <p>Bisher</p>
                        </div>
                        <div>
                            →
                        </div>
                        <div style="width: 40%">
                            <p>Neu</p>
                        </div>
                    </div>

                    <!-- actual groups -->
                    <vue-custom-scrollbar
                        class="scroll-area"
                        :settings="settings"
                        style="width: 100%"
                    >
                        <div
                            v-for="(group, index) in completeList"
                            :key="group._id"
                            class="d-flex justify-space-between align-baseline"
                            style="width: 95%"
                        >
                            <!-- old group names -->
                            <div style="width: 40%">
                                <v-text-field
                                    v-model="group.name"
                                    hide-details
                                    disabled
                                />
                            </div>

                            <div>
                                →
                            </div>

                            <!-- new group names -->
                            <div style="width: 40%">
                                <CustomDropDownAutocomplete
                                    :ref="`newGroupName${index}`"
                                    :index="index"
                                    :default-text="`${group.newStatus === 'discontinued' ? 'Nicht weitergeführt' : 'Gruppenname'}`"
                                    :items="groupNames"
                                    :rules="[rules.required, rules.unique]"
                                    :given-entry="givenNewEntry(index)"
                                    :custom-entry="true"
                                    :custom-function="() => setGroupDiscontinued(index, group._id)"
                                    @updateGroupName="(newName) => updateGroupName(index, newName)"
                                />
                            </div>
                        </div>
                    </vue-custom-scrollbar>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        elevation="0"
                        class="text-none"
                        color="dunkelgrau"
                        dark
                        @click="clickContinue"
                    >
                        Bezeichnungen speichern und weiter
                    </v-btn>
                </v-card-actions>
            </v-card>

            <!-- step 3 | assign pupils to groups -->
            <v-card
                v-else-if="chYr === 2"
                :height="chYr === 2 ? '750px' : 'auto'"
            >
                <v-card-title class="d-flex justify-space-between">
                    <p>Schuljahreswechsel (2/3) - Nutzer</p>
                    <v-btn
                        icon
                        small
                        @click="discardChangesDialog = true"
                    >
                        <img
                            :src="cancelIcon"
                            class="iconBtn"
                            alt="Schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text
                    class="d-flex flex-column justify-space-between"
                    style="min-height: 75%; max-height: 75%"
                >
                    <div>
                        <p class="mb-2">
                            Prüfen Sie nun Bezeichnungen und Mitglieder der Gruppen.
                            Hierzu können Sie Schüler in andere Klassen verschieben,
                            angeben ob Schüler nicht versetzt werden oder ob sie die Schule wechseln.
                            Ebenso können Klassenlehrer geändert werden.
                            Mit der Suche können Sie nach bestimmten Lehrer-, Schüler- oder Gruppennamen suchen.
                        </p>

                        <!-- search field -->
                        <div
                            class="d-flex justify-start align-center"
                            style="width: 95%"
                        >
                            <div style="width: 40%">
                                <v-text-field
                                    placeholder="Schüler/Lehrer oder Gruppe suchen..."
                                    outlined
                                    dense
                                    hide-details
                                    class="mb-2"
                                    @input="searchInputChanged"
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div
                            class="d-flex justify-space-between align-center mb-2"
                            style="width: 95%; color: black; font-weight: bold"
                        >
                            <div style="width: 45%">
                                <p>Bisher</p>
                            </div>
                            <div>
                                →
                            </div>
                            <div style="width: 45%">
                                <p>Neu</p>
                            </div>
                        </div>

                        <!-- actual groups -->
                        <vue-custom-scrollbar
                            class="scroll-area"
                            :settings="settings"
                            style="width: 100%"
                        >
                            <div
                                class="d-flex justify-space-between align-center py-1"
                                style="width: 95%"
                            >
                                <!-- groups as they are currently -->
                                <div style="width: 45%;">
                                    <v-expansion-panels
                                        v-model="oldPanels"
                                        accordion
                                        flat
                                        style="border: 1px solid var(--v-grau-base)"
                                    >
                                        <v-expansion-panel
                                            v-for="(group, index) in displayableCompleteList"
                                            :key="`oldGroup${group._id}`"
                                            class="groupExpansionPanel"
                                            @change="panelOpened(index, group._id)"
                                        >
                                            <v-expansion-panel-header
                                                class="pa-4"
                                                style="background-color: var(--v-hellgrau-base)"
                                            >
                                                <div class="d-flex justify-space-between align-center">
                                                    <div class="d-flex flex-column justify-start">
                                                        <p style="font-size: large">
                                                            {{ group.name }}
                                                        </p>
                                                        <p style="color: var(--v-dunkelgrau-base); font-size: small; margin-top: 3px">
                                                            <span
                                                                v-for="(leader, index) in group.leaders"
                                                                :key="index"
                                                            >
                                                                <!-- eslint-disable-next-line -->
                                                                {{ teacherShorthand(leader) + `${index < group.leaders.length - 1 ? ', ' : ''}` }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content
                                                v-for="participant in group.participants"
                                                :key="participant._id"
                                                class="pa-3"
                                                :class="{'inactivePupil': participant.status && participant.status !== 'normal'}"
                                            >
                                                <div class="d-flex justify-space-between align-center">
                                                    <div style="width: 30%">
                                                        <p>
                                                            {{ participant.lastName }}
                                                        </p>
                                                    </div>
                                                    <div style="width: 30%">
                                                        <p>
                                                            {{ participant.name }}
                                                        </p>
                                                    </div>
                                                    <!-- TODO: Put Group Icons in here as soon as we develop them -->
                                                    <div style="width: 40%">
                                                        <!-- Normal Menu with submenu -->
                                                        <ManageStudentsMenuWithSubmenu
                                                            :pupil="participant"
                                                            :other-classes="newList"
                                                            :disabled-classes="newList.filter(newGroup =>
                                                                newGroup.participants
                                                                    .find(pupil => participant._id === pupil._id))"
                                                            @changedClass="(otherClass) => {
                                                                pupilChangesClass(participant._id, group._id, otherClass)
                                                            }"
                                                            @pupilTransferStatusChanged="(newStatus) =>
                                                                pupilTransferStatusChanged(participant._id, newStatus)"
                                                        />
                                                    </div>
                                                </div>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </div>

                                <div>
                                    →
                                </div>

                                <!-- Groups after the school year change -->
                                <div style="width: 45%">
                                    <v-expansion-panels
                                        v-model="newPanels"
                                        accordion
                                        flat
                                        style="border: 1px solid var(--v-grau-base)"
                                    >
                                        <v-expansion-panel
                                            v-for="(group, index) in displayableNewList"
                                            :key="`newGroup${group._id}`"
                                            class="groupExpansionPanel"
                                            :disabled="group.status === 'discontinued'"
                                            @change="panelOpened(index, group._id)"
                                        >
                                            <v-expansion-panel-header
                                                class="pa-4"
                                                style="background-color: var(--v-hellgrau-base); max-height: 66px"
                                            >
                                                <div class="d-flex justify-space-between align-center">
                                                    <div class="d-flex flex-column justify-start">
                                                        <p style="font-size: large">
                                                            {{ group.name }}
                                                        </p>
                                                        <v-select
                                                            v-model="newList.find((el) => el._id === group._id).leaders"
                                                            :items="teachers"
                                                            item-value="_id"
                                                            :item-text="item => teacherShorthand(item)"
                                                            class="input"
                                                            style="font-size: small; margin-top: 3px; overflow-y: scroll; overflow-x: hidden; height: 30px"
                                                            dense
                                                            multiple
                                                            hide-details
                                                            return-object
                                                            @click="(event) => { event.stopPropagation() }"
                                                        />
                                                    </div>
                                                </div>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content
                                                v-for="participant in group.participants"
                                                :key="participant._id"
                                                class="pa-3"
                                                :class="{'inactivePupil': participant.status && participant.status !== 'normal'}"
                                            >
                                                <div class="d-flex justify-space-between align-center">
                                                    <div style="width: 30%">
                                                        <p>
                                                            {{ participant.lastName }}
                                                        </p>
                                                    </div>
                                                    <div style="width: 30%">
                                                        <p>
                                                            {{ participant.name }}
                                                        </p>
                                                    </div>
                                                    <!-- TODO: Put Group Icons in here as soon as we develop them -->
                                                    <div style="width: 40%">
                                                        <v-btn
                                                            text
                                                            disabled
                                                            dark
                                                            style="text-transform: inherit"
                                                        >
                                                            <v-icon
                                                                v-if="participant.status === 'changeSchools'"
                                                                left
                                                                style="
                                                                    transform: rotate(45deg);
                                                                    color: white !important;
                                                                    font-size: small"
                                                            >
                                                                fas fa-arrow-up
                                                            </v-icon>
                                                            <v-icon
                                                                v-else-if="participant.status === 'notTransferred'"
                                                                left
                                                                style="color: white !important; font-size: small"
                                                            >
                                                                fas fa-times-circle
                                                            </v-icon>
                                                            <p
                                                                v-if="participant.status && participant.status !== 'normal'"
                                                            >
                                                                {{ readableStatus(participant.status) }}
                                                            </p>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </div>
                            </div>
                        </vue-custom-scrollbar>
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn
                        elevation="0"
                        class="text-none"
                        @click="clickBack"
                    >
                        Zurück zu Gruppen
                    </v-btn>
                    <v-btn
                        elevation="0"
                        class="text-none"
                        color="dunkelgrau"
                        dark
                        @click="clickContinue"
                    >
                        Angaben speichern und weiter
                    </v-btn>
                </v-card-actions>
            </v-card>

            <!-- step 4 | keep data -->
            <v-card v-else-if="chYr === 3">
                <v-card-title class="d-flex justify-space-between">
                    <p>Schuljahreswechsel (3/3) - Daten</p>
                    <v-btn
                        icon
                        small
                        @click="discardChangesDialog = true"
                    >
                        <img
                            :src="cancelIcon"
                            class="iconBtn"
                            alt="Schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col class="mb-5">
                            <p>
                                Legen Sie fest welche Daten <b>behalten</b> werden sollen.
                                Je mehr behalten wird, desto mehr Speicherplatz wird benötigt.
                                Dies kann über die Laufzeit zu <b>gesteigerten Kosten</b> führen.
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex justify-space-between">
                            <p>Chatverläufe</p>
                            <v-checkbox
                                v-model="options.keepChat"
                                color="dunkelgrau"
                                hide-details
                                class="ma-0 pa-0 pl-1"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex justify-space-between">
                            <p>Stundenpläne</p>
                            <v-checkbox
                                v-model="options.keepAppointments"
                                color="dunkelgrau"
                                hide-details
                                class="ma-0 pa-0 pl-1"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex justify-space-between">
                            <p>Privater Ordner</p>
                            <v-checkbox
                                v-model="options.keepPrivateFolder"
                                color="dunkelgrau"
                                hide-details
                                class="ma-0 pa-0 pl-1"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex justify-space-between">
                            <p>Accounts von Schülern, welche die Schule verlassen</p>
                            <v-checkbox
                                v-model="options.keepLeavingStudents"
                                color="dunkelgrau"
                                hide-details
                                class="ma-0 pa-0 pl-1"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="loadingButton || responseIndex > 0">
                        <v-col class="d-flex justify-space-between">
                            <p
                                v-for="(item, index) in errorChangedClasses"
                                :key="`errorChangedClasses${item.name}${index}`"
                                style="color: var(--v-rot-base)"
                            >
                                {{ `Bei Wechsel der Klasse ${item.name} ist ein Fehler aufgetreten! ${item.error}` }}
                            </p>
                            <p style="color: var(--v-gruen-base)">
                                {{ `Es wurden ${responseIndex + 1} von ${mergedList.length} Klassen in das neue Schuljahr gewechselt.` }}
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn
                        elevation="0"
                        class="text-none"
                        @click="clickBack"
                    >
                        Zurück zu Nutzer
                    </v-btn>

                    <v-btn
                        v-if="!setButtonToClose"
                        elevation="0"
                        class="text-none"
                        color="gruen"
                        dark
                        :loading="loadingButton"
                        @click="areYouSureDialog = true"
                    >
                        Schuljahreswechsel abschließen
                    </v-btn>
                    <v-btn
                        v-else
                        elevation="0"
                        class="text-none"
                        @click="closeAfterSuccessfulChange"
                    >
                        Schuljahreswechsel schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- are you sure dialog -->
        <v-dialog
            v-model="areYouSureDialog"
            max-width="600px"
        >
            <v-card>
                <v-card-title>Schuljahreswechsel vollziehen?</v-card-title>
                <v-card-text>
                    Hiermit vollziehen Sie den Schuljahreswechsel.
                    Die Änderungen können nur schwer rückgängig gemacht werden!
                    Dieser Vorgang kann einige Minuten dauern.
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn
                        elevation="0"
                        class="text-none"
                        color="grau"
                        dark
                        @click="areYouSureDialog = false"
                    >
                        Abbrechen
                    </v-btn>

                    <v-btn
                        elevation="0"
                        class="text-none"
                        color="gruen"
                        dark
                        @click="clickContinue"
                    >
                        Schuljahreswechsel vollziehen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- discard changes dialog -->
        <v-dialog
            v-model="discardChangesDialog"
            max-width="600px"
        >
            <v-card>
                <v-card-title>Schuljahreswechsel abbrechen?</v-card-title>
                <v-card-text>Wollen Sie den Schuljahreswechsel wirklich abbrechen und alle Eingaben verwerfen?</v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn
                        elevation="0"
                        class="text-none"
                        color="rot"
                        dark
                        @click="discardChanges"
                    >
                        Eingaben verwerfen
                    </v-btn>

                    <v-btn
                        elevation="0"
                        class="text-none"
                        color="dunkelgrau"
                        dark
                        @click="discardChangesDialog = false"
                    >
                        Schuljahreswechsel fortsetzen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as backend from "@/api/backend";
import {mapActions, mapGetters, mapMutations} from "vuex";
import vueCustomScrollbar from "vue-custom-scrollbar";

import cancelIcon from '@/assets/Icons/abbrechen-08.svg';
import CustomDropDownAutocomplete from "@/components/Utils/CustomDropDownAutocomplete";
import ManageStudentsMenuWithSubmenu from "@/components/Verwaltung/Schuljahrwechsel/ManageStudentsMenuWithSubmenu";

export default {
    name: "ChangeSchoolYear",
    components: {
        ManageStudentsMenuWithSubmenu,
        CustomDropDownAutocomplete,
        vueCustomScrollbar,
    },
    props: {
        changeYearDialog: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            //#region Icons
            cancelIcon,
            //endregion

            chYr: 0,                    // which step of the process you are on, starts at 0
            confirmPassword: '',
            confirmPasswordWrong: false,    // show hint if given password is wrong
            showPassword: false,
            groups: [],
            groupNames: [],
            teachers: [],
            pupils: [],

            rules: {
                required: value => !!value || 'Pflichtfeld',
                unique: (value, index) => this.isNewNameUnique(value, index) || 'Muss einzigartig sein',
            },

            // keeps should all be true on default
            options: {
                keepChat: true,
                keepHomework: true,
                keepAppointments: true,
                keepPrivateFolder: true,
                keepLeavingStudents: true,
            },

            //v-models of expansion panels in screen 2 / 3
            oldPanels: 0,
            newPanels: 0,
            lastPressedPanelIndex: 0,

            discardChangesDialog: false,
            areYouSureDialog: false,
            completeList: [],
            newList: [],
            mergedList: [], // This will be in the payload at the end
            studentsLeavingSchoolList: [],
            loadingButton: false,
            responseIndex: 0,
            errorChangedClasses: [],
            setButtonToClose: false,
            searchString: null,

            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
        }
    },
    computed: {
        ...mapGetters('auth', [ 'accountId' ]),
        displayableCompleteList() {
            if (this.searchString) {
                return this.filterListContentAccordingToSearchString(this.completeList);
            }
            console.log(this.completeList);
            return this.completeList;
        },
        displayableNewList() {
            if (this.searchString) {
                return this.filterListContentAccordingToSearchString(this.newList);
            }
            console.log('new List: ', this.newList);
            return this.newList;
        },
        givenNewEntry() {
            return (index) => {
                if (this.newList[index]) {
                    if (this.newList[index].status === 'discontinued') {
                        return 'Nicht weitergeführt';
                    }
                    return this.newList[index].name;
                }
                return '';
            }
        },
        teacherShorthand() {
            return (leader) => {
                let prefix = '';
                if (leader.gender === 'w') {
                    prefix = 'Fr. ';
                } else if (leader.gender === 'm') {
                    prefix = 'Hr. ';
                }

                return prefix + leader.lastName;
            }
        },
        readableStatus() {
            return (status) => {
                let readableStatus = 'Nicht versetzt';

                if (status === 'changeSchools') {
                    readableStatus = 'Schulwechsel';
                }
                if (status === 'changedClass') {
                    readableStatus = 'Aus anderer Klasse'
                }

                return readableStatus;
            }
        }
    },
    async mounted() {
        this.groups = (await this.getGroups(true)).filter(el => el.name !== 'Alle');
        this.teachers = await this.getTeachers(true);
        this.pupils = await this.getPupils(true);

        this.groups.sort((a, b) => {
            a = a.name.toLowerCase();
            b = b.name.toLowerCase();
            return (a < b) ? -1 : (a > b) ? 1 : 0;
        });
        console.log('teachers: ', this.teachers);
        for (let i = 0; i < this.groups.length; i++) {
            const group = JSON.parse(JSON.stringify(this.groups[i]));
            // const group = { ...this.groups[i] };
            group.leaders = this.teachers.filter((teacher) => group.leaders.includes(teacher._id));
            // Participants come already filled in from the backend
            this.completeList.push(group);
            this.groupNames.push(group.name);
        }
    },
    methods: {
        ...mapActions('teachers', ['getTeachers']),
        ...mapActions('groups', [ 'getGroups' ]),
        ...mapActions('pupils', ['getPupils']),
        ...mapActions('maintainers', ['confirmMaintainerPassword', 'changeSchoolYear']),
        ...mapActions('auth', ['getCurrentAccount']),
        ...mapMutations('snackbar', [ 'showSnackbar' ]),

        /**
         * Most important method of them all
         * @returns {Promise<void>}
         */
        async clickContinue() {
            try {
                let block = false;
                switch (this.chYr) {
                    case 0:
                        //check password, if wrong show hint or something otherwise continue
                        // eslint-disable-next-line no-case-declarations
                        const response = await this.confirmMaintainerPassword({
                            password: this.confirmPassword,
                        });
                        if (!response.status || response.status > 205) {
                            this.confirmPasswordWrong = true;
                            block = true;
                        }
                        break;
                    case 1:
                        this.newList = [];
                        for (let i = 0; i < this.completeList.length; i++) {
                            const oldGroup = JSON.parse(JSON.stringify(this.completeList[i]));
                            const errorCheck = this.$refs[`newGroupName${i}`][0].errorStatus;
                            if (errorCheck) {
                                block = true;
                            }

                            const newNameCheck = !!oldGroup.newName;
                            if (!newNameCheck && oldGroup.newStatus !== 'discontinued') {
                                this.$refs[`newGroupName${i}`][0].errorMessage = 'Pflichtfeld';
                                block = true;
                            }

                            //Put renamed groups in newList
                            if (!block) {
                                if (!oldGroup.newStatus || oldGroup.newStatus !== 'discontinued') {
                                    oldGroup.status = 'normal';
                                } else if (oldGroup.newStatus === 'discontinued') {
                                    oldGroup.status = 'discontinued';
                                }
                                delete oldGroup.newStatus;

                                if (oldGroup.status !== 'discontinued') {
                                    oldGroup.name = oldGroup.newName;
                                }
                                delete oldGroup.newName;

                                oldGroup.participants.map((pupil) => {
                                    delete pupil.oldClass;
                                    delete pupil.targetClass;
                                    delete pupil.status;
                                })

                                this.newList.push(oldGroup);
                            }
                        }

                        break;
                    case 2:
                        // eslint-disable-next-line no-case-declarations
                        const mergedList = [];
                        // eslint-disable-next-line no-case-declarations
                        let listOfStudentsLeavingSchool = [];

                        for (let i = 0; i < this.completeList.length; i++) {
                            const group = { ...this.completeList[i] };
                            listOfStudentsLeavingSchool =
                                listOfStudentsLeavingSchool.concat(group.participants
                                .filter(pupil => pupil.status === 'changeSchools')
                                .map(pupil => pupil._id));

                            group.participants = group.participants
                                .filter(pupil => pupil.status === 'notTransferred')
                                .map(pupil => pupil._id);

                            group.leaders = group.leaders.map(leader => leader._id);

                            // group.appointments = group.appointments.map(appointment => appointment._id);

                            delete group.newName;
                            delete group.newStatus;
                            delete group._id;
                            delete group.appointments;
                            mergedList.push(group);
                        }

                        for (let i = 0; i < this.newList.length; i++) {
                            const group = { ...this.newList[i] };
                            if (!group.status || group.status !== 'discontinued') {
                                group.participants = group.participants
                                    .filter(pupil => pupil.status !== 'notTransferred' && pupil.status !== 'changeSchools')
                                    .map(pupil => pupil._id);

                                // group.appointments = group.appointments.map(appointment => appointment._id);

                                group.leaders = group.leaders.map(leader => leader._id);
                                console.log('group.leaders',group.leaders);
                                // find already present group
                                const presentGroup = mergedList.find(item => item.name === group.name);
                                if (!presentGroup) {
                                    // Push new group directly into mergedList
                                    delete group.newName;
                                    delete group.newStatus;
                                    delete group._id;
                                    delete group.appointments;
                                    mergedList.push(group);
                                } else {
                                    presentGroup.name = group.name;
                                    presentGroup.participants = presentGroup.participants.concat(group.participants);
                                    presentGroup.leaders = group.leaders;
                                }
                            } else {
                                listOfStudentsLeavingSchool =
                                    listOfStudentsLeavingSchool.concat(group.participants
                                    .filter(pupil => pupil.status !== 'notTransferred')
                                    .map(pupil => pupil._id));
                            }
                        }
                        this.mergedList = mergedList;
                        this.studentsLeavingSchoolList = listOfStudentsLeavingSchool;
                        console.log('mergedList',this.mergedList);
                        break;
                    case 3:
                        //Set Button loading status
                        this.loadingButton = true;
                        this.responseIndex = 0;
                        this.areYouSureDialog = false;

                        // update options for better naming
                        // eslint-disable-next-line no-case-declarations
                        const options = { ...this.options };
                        options.deleteLeavingStudents = !options.keepLeavingStudents;
                        delete options.keepLeavingStudents;

                        // Send request
                        // eslint-disable-next-line no-case-declarations
                        const responses = await this.mergedList.reduce(async (previousValuesPromise, newGroup) => {
                            const previous = await previousValuesPromise;
                            const newResponse = await this.changeSchoolYear({
                                newGroups: [{ ...newGroup }],
                                studentsLeavingSchool: [],
                                options
                            });
                            const newPromise = {
                                status: newResponse.status,
                                name: newGroup.name,
                            };

                            if (newPromise.status !== 200) {
                                newPromise.error = newResponse.message;
                                this.errorChangedClasses.push(newPromise);
                            }
                            this.responseIndex = previous.length;

                            return [ ...previous, newPromise ]
                        }, Promise.resolve([]));
                        // Send leaving Students so they dont get forgotten
                        await this.changeSchoolYear({
                            newGroups: [],
                            studentsLeavingSchool: this.studentsLeavingSchoolList,
                            options
                        });
                        block = true;

                        this.loadingButton = false;
                        this.setButtonToClose = true;
                        break;
                    default:
                        this.$emit('close');
                        console.warn('reached last implemented step');
                        this.chYr = -1;
                        break;
                }

                if (!block) {

                    this.chYr++;
                }
            } catch (e) {
                console.error(e);
            }
        },

        clickBack() {
            // if go back from 2 to 1, clear possible class changes in completeList
            if (this.chYr === 2) {
                this.completeList.map((group) => {
                    group.participants.map((pupil) => {
                        delete pupil.targetClass;
                        delete pupil.oldClass;
                        delete pupil.status;
                    })
                })
            }
            this.setButtonToClose = false;
            this.chYr--;
        },

        async closeAfterSuccessfulChange() {
            await this.getGroups(true)
            this.showSnackbar({
                message: 'Schuljahr erfolgreich gewechselt!',
                color: 'success'
            });
            this.$emit('close');
            this.chYr = -1;
            this.$router.go();
        },

        discardChanges() {
            this.discardChangesDialog = false;
            this.$emit('close');

            // reset all the data fields
            this.chYr = 0;
            this.confirmPassword = '';
            this.confirmPasswordWrong = false;
            this.showPassword = false;
            this.groups = [];
            this.teachers = [];
            this.pupils = [];
            this.keepChat = true;
            this.keepHomework = true;
            this.keepAppointments = true;
            this.keepPrivateFolder = true;
        },

        //#region change Group Names and Rules
        updateGroupName(index, newName) {
            this.completeList[index].newName = newName;
            this.completeList[index].newStatus = 'normal';

            // call all other dropdowns and let them update themselves
            this.completeList.filter(element => element.newName)
                .forEach((element, elIndex) => {
                    if (elIndex !== index) {
                        this.$refs[`newGroupName${elIndex}`][0].checkForErrors(element.newName);
                    }
                })
        },
        isNewNameUnique(newName, index) {
            const foundStuff = this.completeList.find((group, elIndex) => {
                if (elIndex === index) {
                    return false;
                }
                return group.newName === newName;
            });
            return !foundStuff;
        },
        setGroupDiscontinued(index, groupId) {

            //TODO change newStatus to enum
            const needed = this.$refs[`newGroupName${index}`][0];
            needed.autocompleteValue = 'Nicht weitergeführt';
            const group = this.completeList.find(group => group._id === groupId);
            group.newStatus = 'discontinued';
            delete group.newName;
        },
        //#endregion

        //#region change Pupil Versetzungs status
        panelOpened(index, groupId) {
            this.$nextTick(() => {
                // Check if panel is open in oldPanels
                if (this.lastPressedPanelIndex === index) {
                    this.oldPanels = undefined;
                    this.newPanels = undefined;
                    this.lastPressedPanelIndex = -1;
                } else {
                    this.newPanels = index;
                    this.oldPanels = index;
                    this.lastPressedPanelIndex = index;
                }
            });
        },
        pupilTransferStatusChanged(pupilId, newStatus) {
            //Find pupil as participant in old and new list
            const oldGroup = this.completeList.find(group => group.participants.find(pupil => pupil._id === pupilId));

            const newGroup = this.newList.find(group => group.participants.find(pupil => pupil._id === pupilId));

            if (oldGroup) {
                const oldPupilEntry = oldGroup.participants.find(pupil => pupil._id === pupilId);
                if (oldPupilEntry.status === 'changedClass') {
                    // Revert class change (needs only be done here)
                    this.pupilChangesClass(pupilId, newGroup._id, oldGroup._id);
                }
                this.$set(oldPupilEntry, 'status', newStatus);
            }

            if (newGroup) {
                const newPupilEntry = newGroup.participants.find(pupil => pupil._id === pupilId);
                this.$set(newPupilEntry, 'status', newStatus);
            }
        },
        pupilChangesClass(pupilId, oldClassId, newClassId) {
            const oldPupilClass = this.completeList.find(group => group.participants.find(pupil => pupil._id === pupilId))
            const pupil = oldPupilClass.participants.find(pupil => pupil._id === pupilId);

            const newClass = this.newList.find(group => group._id === newClassId);
            // Remove pupil from old Group
            const oldClass = this.newList.find(group => group._id === oldClassId);
            const pupilIndexInOldClass = oldClass.participants.findIndex(pupil => pupil._id === pupilId);
            oldClass.participants.splice(pupilIndexInOldClass, 1);

            // Add pupil to new Group
            newClass.participants.push(pupil);

            pupil.targetClass = { _id: newClass._id, name: newClass.name };
            pupil.oldClass = { _id: oldClass._id, name: oldClass.name };
        },
        //#endregion

        //#region Suche nach Schülern, Lehrern oder Gruppen
        searchInputChanged(event) {
            this.searchString = event;
        },
        filterListContentAccordingToSearchString(list) {
            // set Panels open to 0 to make sure it doesnt bug around
            this.$nextTick(() => {
                this.newPanels = 0;
                this.oldPanels = 0;
            });

            // Filtered list that will be returned at the end
            const filteredResult = [];

            for (let i = 0; i < list.length; i++) {
                const group = list[i];

                // Filter if group name has searchString inside
                if (group.name.includes(this.searchString)) {
                   filteredResult.push(group);
                   continue;
                }

                // Check if one of the leaders name or lastname have searchString inside
                if (!filteredResult.includes(group) &&
                    (group.leaders.filter(leader => leader.name.includes(this.searchString)).length > 0
                    || group.leaders.filter(leader => leader.lastName.includes(this.searchString)).length > 0)) {
                    filteredResult.push(group);
                    continue;
                }

                // Lastly, check if name or lastname of a pupil have searchString inside
                if (!filteredResult.includes(group) &&
                    (group.participants.find(pupil => pupil.name.includes(this.searchString)
                        || pupil.lastName.includes(this.searchString)))) {
                    filteredResult.push(group);
                }
            }

            return filteredResult;
        },
        //#endregion
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../node_modules/vuetify/src/styles/styles';

.v-card__title {
    padding-top: 40px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.v-card__text {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.v-card__actions {
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-bottom: 40px !important;
}

// removes top padding of v-cols
.col {
  padding-top: 0 !important;
}

.iconBtn {
    height: 30px;
    width: 30px;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: 42vh;
}

.redText {
    color: var(--v-rot-base);
}

.inactivePupil {
    background-color: var(--v-dunkelgrau-base);

    p {
        color: white;
    }
}


</style>
<style lang="scss">

.v-expansion-panel-content__wrap {
    padding: 0 !important;
}
</style>
