<template>
    <div>
        <!-- Popup Menu -->
        <v-dialog
            :value="dialogActive"
            color
            width="600"
            :scrollable="false"
            style="padding-top: 50px; z-index: 100; overflow-y: hidden;"
            @input="onDialogActiveChange"
        >
            <v-card>
                <!-- Popup-Header -->
                <div
                    class="px-1 align-start"
                    style="display: inline-flex; width: 100%; background-color: var(--v-dunkelgrau-base); font-size: larger; padding-top: 10px; padding-bottom: 14px; color: white;"
                >
                    <div
                        class="mt-1"
                        style="margin-left: 10px; display:flex; align-items: center; justify-content: center; width: 100%"
                    >
                        <img
                            :src="videoWeissIcon"
                            class="mx-2 videoIconPopup"
                            alt="Video-Icon"
                        >
                        <p
                            class="ma-0"
                            style="display: inline-block; font-size: 20px !important"
                        >
                            Externe Gäste
                        </p>
                        <v-spacer />
                        <v-btn
                            x-small
                            class="RightTitleText d-flex align-center mr-2 ml-2"
                            style="background-color: #f8f8f880; width: 35px; height: 35px;"
                            @click="closeDialog()"
                        >
                            <img
                                :src="abbrechenIcon"
                                class="boardIconDialog"
                                alt="abbrechen-Icon"
                            >
                        </v-btn>
                    </div>
                </div>
                <v-card-text>
                    <h3 class="mb-1 mt-4 mb-2">
                        Neuen Raum anlegen
                    </h3>
                    <div :class="windowWidth < 500 ? 'flex-column' : '' + ' d-flex mb-3'">
                        <input
                            v-model="inputName"
                            placeholder="Raumname"
                            class="input mr-2"
                            type="text"
                            @keydown.enter="clickCreateExternalBbbRoom"
                        >
                        <v-btn
                            color="gruen"
                            class="createRoomButton text-none"
                            :class="windowWidth < 500 ? 'mt-1' : ''"
                            @click="clickCreateExternalBbbRoom"
                        >
                            Raum anlegen
                        </v-btn>
                    </div>
                    <h3 class="mt-6 mb-1">
                        Bestehende Räume
                    </h3>

                    <v-row
                        v-for="externalRoom in externalRooms"
                        :key="externalRoom._id"
                        class="externalRoomItem"
                    >
                        <v-col class="d-flex align-center">
                            {{ externalRoom.name }}
                        </v-col>

                        <v-col class="d-flex justify-end">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="button mr-2"
                                        :color="externalRoom.isOpen ? 'gruen' : 'fgrau'"
                                        v-on="on"
                                        @click="clickCopyMeetingExternalJoinUrl(externalRoom)"
                                    >
                                        <v-icon
                                            style="color: white; max-width: 20px; max-height: 20px;"
                                            alt="Link für Externe kopieren"
                                        >
                                            mdi-share-variant-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Link für Externe kopieren</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="button mr-2"
                                        :color="externalRoom.isOpen ? 'gruen' : 'fgrau'"
                                        v-on="on"
                                        @click="joinBbb(externalRoom)"
                                    >
                                        <img
                                            :src="beitretenKameraIcon"
                                            alt="Videokonferenz beitreten"
                                        >
                                    </v-btn>
                                </template>
                                <span>Meeting beitreten</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        :color="externalRoom.isOpen ? 'gruen' : 'fgrau'"
                                        class="button mr-2"
                                        v-on="on"
                                        @click="clickToggleIsOpen(externalRoom)"
                                    >
                                        <img
                                            :src="externalRoom.isOpen ? schlossOffenIcon : schlossIcon"
                                            :alt="externalRoom.isOpen ? 'Raum schließen' : 'Raum öffnen'"
                                        >
                                    </v-btn>
                                </template>
                                <span v-if="externalRoom.isOpen">Raum schließen</span>
                                <span v-else>Raum öffnen</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="button"
                                        v-on="on"
                                        @click="clickDeleteExternalBbbRoom(externalRoom);"
                                    >
                                        <img
                                            :src="papierkorbIcon"
                                            alt="Löschen"
                                        >
                                    </v-btn>
                                </template>
                                <span>Löschen</span>
                            </v-tooltip>
                        </v-col>
                        <hr>
                    </v-row>
                    <span v-if="externalRooms.length === 0">Keine Räume angelegt.</span>
                </v-card-text>
                <!-- Create extra container for copying data into clipboard-->
                <!-- Because it doesnt work outside the modal -->
                <div
                    ref="copyUrlContainer"
                    type="text"
                />
            </v-card>
        </v-dialog>
        <VideoTutorial
            v-if="videoTutorial && showVideoTutorial && !videoTutorialClosed"
            :video-tutorial="videoTutorial"
            @close="onVideoTutorialClose"
        />
    </div>
</template>

<script>
import {mapActions, mapState, mapMutations, mapGetters} from 'vuex';
import * as backendApi from '../../api/backend.js';

import VideoTutorial from "../../components/Tutorial/VideoTutorial.vue";

import videoWeissIcon from '@/assets/Icons/kamera-video-weiss-72.svg';
import abbrechenIcon from "../../assets/Icons/abbrechen-08.svg";
import papierkorbIcon from '../../assets/Icons/papierkorb-109.svg'
import schlossIcon from '../../assets/Icons/lock-closed-white.svg';
import schlossOffenIcon from '../../assets/Icons/lock-open.svg';
import beitretenKameraIcon from "../../assets/Icons/kamera-video-weiss-72.svg";

export default {
    name: "JoinExternalDialog",
    components: {
        VideoTutorial,
    },
    props: {},
    data() {
        return {
            dialogActive: true,
            videoWeissIcon,
            abbrechenIcon,
            papierkorbIcon,
            schlossIcon,
            schlossOffenIcon,
            beitretenKameraIcon,

            inputName: "",
            externalRooms: [],

             // Show video Tutorial is set to true when button is clicked
            // videoTutorialClosed is set to false when VideoTutorial is closed
            videoTutorial: false,
            showVideoTutorial: false,
            videoTutorialClosed: false,
            videoTutorialOnCloseJoinExternalRoom: null,
        };
    },
    computed: {
        ...mapGetters("auth", ["accountRole"]),
        ...mapState('util', ['windowWidth']),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
    },
    watch: {},
    async mounted() {
        this.loadExternalBbbRooms();
        this.videoTutorial = this.videoTutorialsByFilename['Lehrer_bbb_erklaert.mp4'];
    },
    methods: {
        ...mapActions('util', ['toggleLoading']),
        ...mapMutations('snackbar', ['showSnackbar']),

         onVideoTutorialClose() {
            this.videoTutorial = false;
            this.videoTutorialClosed = true;
            this.joinBbb(this.videoTutorialOnCloseJoinExternalRoom)
        },
        async clickCopyMeetingExternalJoinUrl(externalBbbRoom) {
            const domain = `${location.protocol}//${location.hostname}${(location.port ? ':' + location.port : '')}`;
            const tempInput = document.createElement("input");
            tempInput.style = "position: absolute; left: -1000px; top: -1000px";
            tempInput.value = `${domain}/join-external/${externalBbbRoom._id}?token=${externalBbbRoom.token}`;
            this.$refs.copyUrlContainer.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            this.$refs.copyUrlContainer.removeChild(tempInput);
            this.showSnackbar({message: "Link kopiert!"});
        },
        async clickToggleIsOpen(externalBbbRoom) {
            await this.patchExternalBbbRoom(externalBbbRoom._id,
                {
                    ...externalBbbRoom,
                    isOpen: !externalBbbRoom.isOpen,
                });
            await this.loadExternalBbbRooms();
        },
        async loadExternalBbbRooms() {
            const response = await backendApi.getExternalBbbRooms();
            const externalRooms = await response.json();
            this.externalRooms = externalRooms;

        },
        async postExternalBbbRoom({name = "example", isOpen = true} = {}) {
            const response = await backendApi.postExternalBbbRoom({name, isOpen});
            const postedRoom = await response.json();
        },
        async patchExternalBbbRoom(externalBbbRoomId, externalBbbRoom = {}) {
            const response = await backendApi.patchExternalBbbRoom(externalBbbRoomId, externalBbbRoom);
            const patchedRoom = await response.json();
        },
        async deleteExternalBbbRoom(externalBbbRoom) {
            const response = await backendApi.deleteExternalBbbRoom(externalBbbRoom._id);

        },
        async clickDeleteExternalBbbRoom(externalBbbRoomId) {
            await this.deleteExternalBbbRoom(externalBbbRoomId);
            await this.loadExternalBbbRooms();
        },
        async clickCreateExternalBbbRoom() {
            await this.postExternalBbbRoom({name: this.inputName});
            await this.loadExternalBbbRooms();
            this.inputName = '';
        },
        onDialogActiveChange(newValue) {
            if (!newValue) {
                this.closeDialog();
            }
        },

        async joinBbb(externalBbbRoom) {
            const res = await backendApi.getExternalBbbRoomJoinAsModeratorUrl(
                externalBbbRoom._id
            );
            this.toggleLoading(true);
            const {redirectUrl} = await res.json();
            if (res.status === 409) {
                this.showSnackbar({message: "Aktuell ist der Raum geschlossen!", color: "error"});
                this.toggleLoading(false);
                return;
            } 
            
            if(this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                this.videoTutorialOnCloseJoinExternalRoom = externalBbbRoom;
                return;
            }
            window.location.href = redirectUrl;
            this.toggleLoading(false);

        },

        closeDialog() {
            this.$emit('closeDialog');
        },
    }
}
</script>
<style scoped lang="scss">
.externalRoomItem {
    border-width: 1px;
    border-bottom-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
}

.createRoomButton {
    color: white;
    margin-left: auto;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.input {
    display: block;
    width: 200px;
    border-radius: 5px;
    border-width: 0px;
    color: black;
    padding: 1px 5px 1px 5px;
    border: none !important;
    background-color: var(--v-hellgrau-base);
    overflow: hidden;
    appearance: none;
}

.errorInput {
    border: 2px solid red !important;
}

.roundIcon {
    border-radius: 50%;
}

.boardIconDialog {
    height: 25px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.popUpRow {
    width: 95%;
    margin: auto;
}

.videoIconPopup {
    height: 25px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

@media only screen and (max-width: 900px) {
    .popUpRow {
        margin-left: 33%;
        margin-right: 66%;
    }
}

.button {
    height: 36px;
    min-width: 36px !important;
    width: 38px;
    box-shadow: none;

    img {
        height: 20px !important;
        width: 20px !important;
        margin: 0 !important;
        filter: brightness(1000%);
    }
}
</style>
