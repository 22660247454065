<template>
    <div class="letters">
        <v-row
            cols="12"
            class="dialogRow"
        >
            <v-col
                cols="10"
                class="sectionHeaderCol d-flex align-center"
            >
                <img
                    :src="toDoIcon"
                    class="icon"
                    alt=""
                >
                <p class="pl-2 mb-0 font-weight-bold">
                    Aktueller Elternbrief
                </p>
            </v-col>

            <v-col
                cols="2"
                class="sectionHeaderCol mr-0"
            >
                <v-btn
                    height="24"
                    width="24"
                    size="24"
                    x-small
                    color="rgba(248, 248, 248, 0.4)"
                    @click="collapseLetterLocal = !collapseLetterLocal"
                >
                    <img
                        :src="leftIcon"
                        :style="collapseLetterLocal ? 'transform: rotate(270deg)' : 'transform: rotate(90deg)'"
                        :alt="collapseLetterLocal ? 'Brief einklappen' : 'Brief aufklappen'"
                    >
                </v-btn>
            </v-col>
        </v-row>

        <!-- letter and content -->
        <vue-custom-scrollbar
            class="scroll-area-currentLetter"
            :settings="settings"
        >
            <div class="activeLetter pointer mx-2 pa-2">
                <!-- title and creation date -->
                <div class="d-flex justify-space-between align-center">
                    <h1 class="greyText ellipsis font-weight-bold">
                        {{ currentLetter.title }}
                    </h1>

                    <p class="greyText">
                        {{ createDate(currentLetter.createdAt) }}
                    </p>
                </div>

                <div v-if="!collapseLetterLocal">
                    <hr class="thinHr">
                    <!-- Disable v-html xss checking entry.content is provided only by developers -->
                    <!-- eslint-disable vue/no-v-html -->
                    <p
                        class="greyText"
                        v-html="sanitizeHtml(urlify(currentLetter.content))"
                    />
                    <!-- eslint-enable vue/no-v-html -->
                    
                    <!-- poll -->
                    <div v-if="currentLetter.poll && !collapseLetterLocal">
                        <poll :poll-id="currentLetter.poll" />
                    </div>

                    <div v-if="currentLetter.files.length > 0">
                        <hr class="thinHr">

                        <h1 class="greyText font-weight-bold">
                            Anhang
                        </h1>

                        <div
                            v-for="(file, index) in currentLetter.files"
                            :key="file._id"
                            class="d-flex justify-space-between align-center"
                            :class="index === currentLetter.files.length-1 ? '' : 'mb-2'"
                        >
                            <p class="greyText ellipsis">
                                {{ file.uploadedName }}
                            </p>
                            <v-btn
                                class="actionBtn"
                                x-small
                                @click="downloadAttachment(currentLetter._id, currentLetter.files[index])"
                            >
                                <img
                                    :src="downloadIcon"
                                    class="icon"
                                    alt="Datei herunterladen"
                                >
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mx-2">
                <hr class="greyLine mt-2">
            </div>

            <v-row
                cols="12"
                class="dialogRow"
            >
                <v-col
                    cols="10"
                    class="sectionHeaderCol d-flex align-center"
                >
                    <img
                        :src="mailIcon"
                        class="icon"
                        alt=""
                    >
                    <p class="pl-2 mb-0 font-weight-bold">
                        Rückmeldungen
                    </p>
                </v-col>

                <v-col
                    cols="2"
                    class="sectionHeaderCol mr-0"
                >
                    <v-btn
                        height="24"
                        width="24"
                        size="24"
                        x-small
                        color="rgba(248, 248, 248, 0.4)"
                        @click="collapseLetterLocal = !collapseLetterLocal"
                    >
                        <img
                            :src="leftIcon"
                            :style="collapseLetterLocal ? 'transform: rotate(90deg)' : 'transform: rotate(270deg)'"
                            :alt="collapseLetterLocal ? 'Rückmeldungen aufklappen' : 'Rückmeldungen einklappen '"
                        >
                    </v-btn>
                </v-col>
            </v-row>
        </vue-custom-scrollbar>

        <vue-custom-scrollbar
            v-if="collapseLetterLocal"
            class="scroll-area-responses"
            :settings="settings"
        >
            <div
                v-if="currentLetter.targetAccounts.length >
                    (currentLetter.requiresSignature ? currentLetter.parentalLetterResponses.length : currentLetter.seenBy.length)"
            >
                <v-row
                    cols="12"
                    class="dialogRow"
                >
                    <v-col class="sectionHeaderCol d-flex align-center">
                        <p class="d-flex align-center mb-0 font-weight-bold">
                            <img
                                :src="missingDocIcon"
                                class="icon mr-2"
                                alt=""
                            >
                            Nicht {{ currentLetter.requiresSignature ? 'unterschrieben' : 'gesehen' }}
                        </p>
                    </v-col>
                </v-row>

                <v-row
                    v-for="account in notSignedCurrentLetter"
                    :key="account._id"
                >
                    <v-col class="py-0">
                        <div class="notSigned d-flex align-center px-4 py-2 mb-1">
                            <p class="greyText">
                                {{ account.name.slice(0, 1) + '. ' + account.lastName }}
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <div
                v-if="signedCurrentLetter.length > 0 || (!currentLetter.requiresSignature && currentLetter.seenBy.length > 0)"
            >
                <v-row
                    cols="12"
                    class="dialogRow"
                >
                    <v-col class="sectionHeaderCol d-flex align-center">
                        <p class="d-flex align-center mb-0 font-weight-bold">
                            <img
                                :src="docIcon"
                                class="icon mr-2"
                                alt=""
                            >
                            {{ currentLetter.requiresSignature ? 'Unterschrieben' : 'Gesehen' }}
                        </p>
                    </v-col>
                </v-row>

                <v-row
                    v-for="response in signedCurrentLetter"
                    :key="response.account"
                    cols="12"
                    class="pointer"
                    @click="() => { if (currentLetter.requiresSignature) previewSignature(response) }"
                >
                    <v-col
                        cols="8"
                        class="py-0 pr-0"
                    >
                        <div class="signed d-flex align-center pl-4 py-2 mb-1">
                            <p class="greyText ellipsis">
                                {{ response.name.slice(0, 1) + '. ' + response.lastName }}
                            </p>
                        </div>
                    </v-col>
                    <v-col
                        cols="4"
                        class="py-0 pl-0"
                    >
                        <div class="signed d-flex justify-end align-center px-4 py-2 mb-1">
                            <p
                                class="greyText"
                                style="height: 1.5em"
                            >
                                {{ currentLetter.requiresSignature ? createDate(currentLetter.createdAt) : '' }}
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </vue-custom-scrollbar>

        <v-dialog
            v-model="signaturePreview"
            width="400"
        >
            <v-card v-if="signaturePreview">
                <v-card-title class="header">
                    Unterschrift für {{ letterResponse.name.slice(0,1) + '. ' + letterResponse.lastName }}
                    <v-spacer />
                    <v-btn
                        class="optionBtn ml-2"
                        x-small
                        color="rgba(230, 45, 45, 0.4)"
                        @click="signaturePreview = false"
                    >
                        <img
                            :src="schliessenIcon"
                            class="icon iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <img
                        :src="signatureToPreview"
                        style="width: 352px !important"
                        alt="Unterschrift"
                    >
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="optionBtn ml-2 text-capitalize"
                        x-small
                        color="green"
                        @click="downloadSignature(letterResponse._id)"
                    >
                        <img
                            :src="downloadIcon"
                            class="icon iconToWhite"
                            alt="herunterladen"
                        >
                        <p
                            class="ml-2"
                            style="color: white"
                        >
                            Herunterladen
                        </p>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import * as backend from "@/api/backend";
import sanitizeHtml from '../util/sanitizeHtml';
import {mapActions} from "vuex";
import Poll from "@/components/Poll"
import VueCustomScrollbar from "vue-custom-scrollbar"

import docIcon from '@/assets/Icons/bearbeitet-17.svg';
import downloadIcon from "@/assets/Icons/runterladen-14.svg";
import leftIcon from '@/assets/Icons/links-filled-10.svg';
import mailIcon from '@/assets/Icons/letter.svg';
import missingDocIcon from '@/assets/Icons/kein-dokument-68.svg';
import toDoIcon from '@/assets/Icons/to-do-round.svg';
import schliessenIcon from '@/assets/Icons/abbrechen-08.svg';

export default {
    name: 'MessagesLetter',
    components: {Poll, VueCustomScrollbar},
    props: {
        collapseLetter: {type: Boolean, required: true},
        currentLetter: {required: true},
        currentSickNote: {required: true},
        notSignedCurrentLetter: {required: true},
        signedCurrentLetter: {required: true},
        settings: {required: true},
    },
    data() {
        return {
            docIcon,
            downloadIcon,
            leftIcon,
            mailIcon,
            missingDocIcon,
            toDoIcon,
            schliessenIcon,

            collapseLetterLocal: null,

            signatureToPreview: null,
            signaturePreview: false,
            letterResponse: null,
            sanitizeHtml,
        }
    },
    watch: {
        collapseLetterLocal() {
            this.$emit('collapseLetter', this.collapseLetterLocal);
        }
    },
    mounted() {
        this.collapseLetterLocal = this.collapseLetter;
    },
    methods: {
        ...mapActions('parentalLetter', ['getSignature']),

        // Creates date of format DD.MM.YY with leading zeros
        createDate(date) {
            return new Date(date).toLocaleDateString([], {year: '2-digit', month: '2-digit', day: '2-digit'});
        },

        async previewSignature(response) {
            this.letterResponse = response;
            const res = await this.getSignature(response._id);
            let blob = await res.blob();
            this.signatureToPreview = window.URL.createObjectURL(blob);
            this.signaturePreview = true;
        },

        async downloadSignature(responseId) {
            try {
                const res = await this.getSignature(responseId);
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = 'unterschrift';
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function () {
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);

            } catch (e) {
                console.error(e);
            }
        },

        urlify(text) {
            // eslint-disable-next-line no-useless-escape
            var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|software|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
            return text.toString().replace(urlRegex, function (url) {
                url = url.includes('http') ? url : 'http://' + url;
                return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
            })
        },

        async downloadAttachment(letterId, file) {
            try {
                const res = await backend.getParentalLetterFile(letterId, file._id);
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = file.uploadedName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function () {
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            } catch (e) {
                console.error(e);
            }
        },
    },
}
</script>
<style lang="scss" scoped>

// custom disabled button, it uses an id and important as the regular vuetify button styling already uses disabled
// https://stackoverflow.com/a/63762909

.icon {
    height: 20px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.greyText {
    color: var(--v-dunkelgrau-base);
}

.pointer {
    cursor: pointer;
}

.notSigned {
    background-color: rgba(212, 0, 0, 0.3);
}

.signed {
    background-color: rgba(43, 171, 109, 0.3);
}

.scroll-area-currentLetter {
    position: relative;
    margin: auto;
    max-height: 65vh;
}

.scroll-area-responses {
    position: relative;
    margin: auto;
    max-height: 50vh;
}

.ellipsis {
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.sectionHeaderCol {
    padding-top: 0;
    padding-bottom: 0;
}

.letters {
    p {
        color: var(--v-dunkelgrau-base);
    }
}

.activeLetter {
    background-color: var(--v-hellgrau-base);
    border-radius: 12px;
}

.actionBtn {
    color: white;
    border-radius: 8px;
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
}

.thinHr {
    height: 0;
    border: none;
    border-bottom: 1px solid;
    color: var(--v-dunkelgrau-base);
    background-color: var(--v-dunkelgrau-base);
}

.greyLine {
    color: var(--v-hellgrau-base);
    background-color: var(--v-hellgrau-base);
    border: 1px solid;
}

.dialogRow {
    width: 99%;
    padding: 0 8px;
    margin: 0.5em auto 0.5em auto;
}

.header {
    background-color: var(--v-flila-base);
    border-radius: 15px 15px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}
</style>
