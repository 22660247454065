<template>
    <div>
        <v-row
            id="edit"
            class="mx-5"
        >
            <div
                class="editContainer"
                style="background-color: #ffffff;border-radius: 15px 15px 15px 15px"
            >
                <v-row
                    class="mx-0"
                    style="background-color: var(--v-fgrau-base); border-radius: 15px 15px 0 0"
                >
                    <v-col
                        style="color: white; font-size: larger;"
                        class="ma-1 ml-5"
                    >
                        <v-avatar
                            style="width: 24px; "
                            color="rgba(255,255,255,0.8)"
                        >
                            <img
                                :src="fachIcon"
                                class=""
                            >
                        </v-avatar>
                        Fach anlegen
                    </v-col>
                    <v-col
                        class="mr-6"
                        style="display: flex; justify-content: flex-end;"
                    >
                        <v-tooltip
                            top
                            nudge-right="100"
                            :open-on-click="true"
                            :open-on-hover="false"
                            content-class="infoTextToolTip"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="#FFFFFF40"
                                    x-small
                                    class="mainFunctionButtons ml-3 my-auto"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <img :src="infoIcon">
                                </v-btn>
                            </template>
                            <span>Hier können Sie Fächer für Ihre Schule anlegen. Wählen Sie eine Farbe, einen Leiter und
                                ein Icon. Sie können die Farben und Icons auch mehrfach benutzen.</span>
                        </v-tooltip>
                        <v-btn
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#FFFFFF40"
                            dark
                            elevation="0"
                            @click="$emit('stopEvent')"
                        >
                            <span>Abbrechen</span>
                        </v-btn>
                        <v-btn
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#69CD70"
                            dark
                            elevation="4"
                            @click="saveSubject"
                        >
                            <span>Speichern</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="mx-2 mb-2">
                    <v-row>
                        <div style="min-width: 25px!important;" />
                        <v-col>
                            <div style="font-weight: bold; margin-bottom: 10px">
                                Fachdaten
                            </div>
                            <v-row>
                                <v-col cols="2">
                                    Name:
                                </v-col>
                                <v-col cols="7">
                                    <input
                                        v-model="name"
                                        type="text"
                                        class="input"
                                        :class="{ errorInput: fields.name }"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>

                            <v-dialog
                                v-model="dialog"
                                fullscreen
                                hide-overlay
                                transition="dialog-bottom-transition"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-row>
                                        <v-col
                                            cols="2"
                                            style="display: flex; flex-flow: column wrap;"
                                        >
                                            <v-row>
                                                <v-col>
                                                    Lehrer:
                                                </v-col>
                                                <v-col cols="7">
                                                    <v-row
                                                        v-for="(item, index) in members.filter((el) => el.selected)"
                                                        :key="index"
                                                    >
                                                        <div
                                                            class="px-3 py-1 mb-1"
                                                            style="min-width: 200px; background-color: var(--v-hellgrau-base); border-radius: 8px"
                                                        >
                                                            {{ item.name + ' ' + item.lastName }}
                                                        </div>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row style="ailgn-self: end;">
                                                <v-btn
                                                    style="max-height: 25px; color: black"
                                                    dark
                                                    small
                                                    color="#EAEAEA"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="() => { oldMembers = JSON.parse(JSON.stringify(members)) }"
                                                >
                                                    Auswählen
                                                </v-btn>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </template>
                                <v-card>
                                    <v-toolbar
                                        dark
                                        color="#6995CD"
                                    >
                                        <v-toolbar-title>
                                            <v-text-field
                                                v-model="search2"
                                                prepend-icon="mdi-magnify"
                                                label="Nach Personen suchen"
                                                single-line
                                                dark
                                                hide-details
                                                class="pl-6"
                                            />
                                        </v-toolbar-title>
                                        <v-spacer />
                                        <v-toolbar-items>
                                            <v-btn
                                                dark
                                                elevation="3"
                                                style="background-color: #3BAA69; color: white; max-height: 40px; margin-top: 10px; border-radius: 8px"
                                                text
                                                @click="confirmSelection()"
                                            >
                                                Auswählen
                                            </v-btn>
                                            <v-btn
                                                x-small
                                                class="mx-4"
                                                elevation="3"
                                                style="background-color: #e6221e80; border-radius: 8px; margin-top: 10px; width: 40px; max-height: 40px"
                                                @click="() => { members = JSON.parse(JSON.stringify(oldMembers)); dialog = false; search2 = '' }"
                                            >
                                                <img
                                                    :src="abbrechenIcon"
                                                    style="height: 40px; filter: brightness(1000%)"
                                                >
                                            </v-btn>
                                        </v-toolbar-items>
                                    </v-toolbar>

                                    <v-data-table
                                        :key="members._id"
                                        :headers="headers2"
                                        :items="members"
                                        :search="search2"
                                        disable-pagination
                                        hide-default-footer
                                        fixed-header
                                        class="ma-3"
                                        locale="de-DE"
                                        no-data-text="Keine Daten verfügbar"
                                        no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                                    >
                                        <template v-slot:item="row">
                                            <tr>
                                                <td>{{ row.item.lastName }}</td>
                                                <td>{{ row.item.name }}</td>
                                                <td>{{ row.item.gender }}</td>
                                                <td>{{ row.item.note }}</td>
                                                <td>
                                                    <v-checkbox
                                                        v-model="row.item.selected"
                                                        class="mx-2"
                                                        rounded
                                                        small
                                                        light
                                                    />
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-dialog>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                            class="mt-8"
                        >
                            <v-row>
                                <v-col cols="3">
                                    Farbe:
                                </v-col>
                                <v-col cols="9">
                                    <verwaltung-color-picker
                                        :current-color="color"
                                        :callback="(result) => { color = result; validateInput(); }"
                                    />
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="3">
                                    Icon:
                                </v-col>
                                <v-col cols="9">
                                    <img
                                        class="iconSelect"
                                        :selected="icon == 'englischIcon'"
                                        :src="englischIcon"
                                        @click="icon = 'englischIcon'"
                                    >
                                    <img
                                        class="iconSelect"
                                        :selected="icon == 'deutschIcon'"
                                        :src="deutschIcon"
                                        @click="icon = 'deutschIcon'"
                                    >
                                    <img
                                        class="iconSelect"
                                        :selected="icon == 'matheIcon'"
                                        :src="matheIcon"
                                        @click="icon = 'matheIcon'"
                                    >
                                    <img
                                        class="iconSelect"
                                        :selected="icon == 'bioIcon'"
                                        :src="bioIcon"
                                        @click="icon = 'bioIcon'"
                                    >
                                    <img
                                        class="iconSelect"
                                        :selected="icon == 'chemieIcon'"
                                        :src="chemieIcon"
                                        @click="icon = 'chemieIcon'"
                                    >
                                    <img
                                        class="iconSelect"
                                        :selected="icon == 'erdkundeIcon'"
                                        :src="erdkundeIcon"
                                        @click="icon = 'erdkundeIcon'"
                                    >
                                    <img
                                        class="iconSelect"
                                        :selected="icon == 'geschichteIcon'"
                                        :src="geschichteIcon"
                                        @click="icon = 'geschichteIcon'"
                                    >
                                    <img
                                        class="iconSelect"
                                        :selected="icon == 'physikIcon'"
                                        :src="physikIcon"
                                        @click="icon = 'physikIcon'"
                                    >
                                    <img
                                        class="iconSelect"
                                        :selected="icon == 'politikIcon'"
                                        :src="politikIcon"
                                        @click="icon = 'politikIcon'"
                                    >
                                    <img
                                        class="iconSelect"
                                        :selected="icon == 'religionIcon'"
                                        :src="religionIcon"
                                        @click="icon = 'religionIcon'"
                                    >
                                    <img
                                        class="iconSelect"
                                        :selected="icon == 'sportIcon'"
                                        :src="sportIcon"
                                        @click="icon = 'sportIcon'"
                                    >
                                    <img
                                        class="iconSelect"
                                        :selected="icon == 'wirtschaftIcon'"
                                        :src="wirtschaftIcon"
                                        @click="icon = 'wirtschaftIcon'"
                                    >
                                    <img
                                        class="iconSelect"
                                        :selected="icon == 'musikIcon'"
                                        :src="musikIcon"
                                        @click="icon = 'musikIcon'"
                                    >
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-row>
    </div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from "vuex";

import bioIcon from '../../assets/Icons/f-biologie-35.svg'
import chemieIcon from '../../assets/Icons/f-chemie-37.svg'
import deutschIcon from '../../assets/Icons/f-deutsch-29.svg'
import englischIcon from '../../assets/Icons/f-englisch-30.svg'
import erdkundeIcon from '../../assets/Icons/f-erdkunde-31.svg'
import geschichteIcon from '../../assets/Icons/f-geschichte-40.svg'
import matheIcon from '../../assets/Icons/f-mathe-32.svg'
import musikIcon from '../../assets/Icons/f-musik-84.svg'
import physikIcon from '../../assets/Icons/f-physik-36.svg'
import politikIcon from '../../assets/Icons/f-politik-34.svg'
import religionIcon from '../../assets/Icons/f-religion-39.svg'
import sportIcon from '../../assets/Icons/f-sport-38.svg'
import wirtschaftIcon from '../../assets/Icons/f-wirtschaft-33.svg'
import infoIcon from "../../assets/Icons/ausrufezeichen-62.svg";
import abbrechenIcon from "../../assets/Icons/abbrechen-08.svg";
import fachIcon from "../../assets/Icons/fächer-05.svg";

// Import the styles too, globally
import VerwaltungColorPicker from "./VerwaltungColorPicker";

export default {
    name: "FachErstellen",
    components: {
        VerwaltungColorPicker,
    },
    data() {
        return {
            bioIcon,
            chemieIcon,
            deutschIcon,
            englischIcon,
            erdkundeIcon,
            geschichteIcon,
            matheIcon,
            musikIcon,
            physikIcon,
            politikIcon,
            religionIcon,
            sportIcon,
            wirtschaftIcon,
            infoIcon,
            abbrechenIcon,
            fachIcon,

            showLoadingOverlay: false,
            dialog: false,
            

            icon: 'deutschIcon',
            name: '',
            color: '#fcd20a',

            fields: {
                name: false,
                color: false,
                teacher: false
            },

            oldSubject: null,
            height: 0,

            search2: '',
            headers2: [
                { text: 'Name', value: 'lastName' },
                { text: 'Vorname', value: 'name' },
                { text: 'Geschlecht', value: 'gender' },
                { text: 'Notiz', value: 'note' },
                { text: 'Ausgewählt', value: 'selected'},
            ],
            members: [],
            oldMembers: [],
            subjects: [],
        };
    },
    computed: {
        ...mapGetters('teachers', [ 'getTeacherStatus' ]),
        ...mapGetters('subjects', ['getSubjectInvalidStatus']),
    },
    watch: {
        getTeacherStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestTeachers();
            }
        },
        getSubjectInvalidStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestSubjects();
            }
        },
    },
    async created() {
        this.height = window.innerHeight * 0.4
    },
    async mounted() {
        this.showLoadingOverlay = true;
        this.requestSubjects();
        this.requestTeachers();
    },
    methods: {
        ...mapActions("teachers", ["getTeachers", 'setTeachersInvalidStatus']),
        ...mapActions("subjects", ["getSubjects","createSubject", "editSubject", "deleteSubject", 'setSubjectListStatus']),
        ...mapMutations("snackbar", ["showSnackbar"]),

        async requestTeachers() {
            this.showLoadingOverlay = true;
            this.members = await this.getTeachers();
            this.showLoadingOverlay = false;
            this.setTeachersInvalidStatus(false);
        },
        async requestSubjects() {
            this.subjects = await this.getSubjects();
            this.setSubjectListStatus(false);
        },

        confirmSelection() {
            this.dialog = false;
            this.search2 = '';

            // If editing an existing subject, update members to show selected teachers correctly next to "Lehrer:"
            if (this.oldSubject) {
                this.members = JSON.parse(JSON.stringify(this.oldMembers));
            }
        },
        
        validateInput() {
            let result = true;

            this.fields.name = false;
            this.fields.color = false;

            if (this.name.replace(/\s/g, '') === '') {
                this.fields.name = true;
                result = false;
            }

            if (this.color.replace(/\s/g, '') === '') {
                this.fields.color = true;
                result = false;
            }

            return result;
        },

        async saveSubject() {
            try {
                this.dialog = false;
                this.showLoadingOverlay = true;
                if (!this.validateInput()) {
                    this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                    return;
                }
                let teachers = this.members.filter((el) => {
                    return el.selected;
                });

                let sendTeachers = [];
                teachers.forEach((el) => {
                    sendTeachers.push(el._id)
                });

                let data = {
                    name: this.name,
                    color: this.color,
                    icon: this.icon,
                    teachers: sendTeachers,
                };
                let response = await this.createSubject(data);
                if (Number.isInteger(response)) {
                    if (response === 409) {
                        this.showSnackbar({ message: 'Name bereits vergeben. Bitte geben Sie einen anderen Fachnamen ein!', color: 'error'});
                    } else {
                        this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                    }
                } else {
                    this.subjects.push(response);
                    this.setSubjectListStatus(true);
                    this.showSnackbar({ message: 'Gespeichert!'});
                    this.$emit('stopEvent');
                }
                this.showLoadingOverlay = false;
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr {
        height: 40px;
    }

    table tr th:first-child,
    table tr td:first-child {
        width: 10px !important;
    }

    table tr th,
    table tr td {
        width: 9em;
        border: none;
    }
    table, tr, td {
        border: none;
    }

    input[type="date"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-height: 1.6rem;
    }

    .input{
        display: block;
        width: 100%;
        max-width: 100%;
        border-radius: 5px;
        border-width:0px;color: black;
        padding: 1px 5px 1px 5px;
        border: none !important;
        background-color: var(--v-hellgrau-base);
        overflow: hidden;
        appearance:none
    }

    .input[disabled]{
        color: grey;
        background-color: #CACACA;
    }

    .errorInput {
        border: 2px solid red !important;
    }

    .iconSelect {
        width: 42px;
        padding: 2px;
        border: solid 2px #EAEAEA;
        border-radius: 10px;
        margin-right: 2px;
    }

    .iconSelect[selected] {
        width: 42px;
        padding: 2px;
        border: solid 2px #737373;
        border-radius: 10px;
    }

    .mainControlButtons {
        font-weight: 300;
        font-size: 10px;
        border-radius: 8px;

        & span {
            line-height: 0 !important;
        }
    }

    .mainFunctionButtons {
        width: 38px !important;
        height: 38px !important;
        border-radius: 8px;

        img {
            width: 27px !important;
            height: 27px !important;
            filter: brightness(1000%);
        }
    }

    .infoTextToolTip {
        max-width: 250px;
        background-color: var(--v-blau-base);
    }
</style>

