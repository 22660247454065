<template>
    <div>
        <div
            class="fileRow mx-2 d-flex align-center"
        >
            <!-- Fach icon -->
            <v-tooltip
                :disabled="!showTooltips"
                bottom
            >
                <template v-slot:activator="{ on }">
                    <v-avatar
                        class="ml-2 smallBabyViewActionBtn fachLogo"
                        :style="`background-color: ${item.fachColor} !important`"
                        v-on="on"
                    >
                        <img
                            :id="'toDoIcon-' + index"
                            tabindex="0"
                            :src="getSrc(item.fachIcon)"
                            class="smallBabyViewActionBtnImg"
                            :alt="item.groupName"
                            :class="index === 0 ? 'firstItemOderSo' : ''"
                        >
                    </v-avatar>
                </template>
                <span> {{ item.groupName }}</span>
            </v-tooltip>

            <!--colored line instead of icon if width < 600-->
            <div
                class="fachLine ma-0 pa-0"
                :style="`background-color: ${item.fachColor} !important`"
            />
            <p
                :id="'toDoItem-' + index"
                style="width: fit-content"
                class="fileTitle mx-2 my-0"
                tabindex="0"
                @focus="readExerciseName(item)"
            >
                {{ item.title }}
            </p>
            <div
                v-if="category === 'toDo'"
                class="my-0 mr-2"
                style="display: flex; margin-left: auto"
            >
                <!-- buttons -->
                <!-- 1st button -->
                <v-tooltip
                    v-if="!item.pipReference"
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            small
                            class="babyViewActionBtn ml-3"
                            v-on="on"
                            @click="clickDownloadTeacherUpload(item)"
                        >
                            <img
                                :src="runterladenIcon"
                                class="babyViewActionBtnImg"
                                alt="Herunterladen"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span>Herunterladen</span>
                        <img
                            v-if="hoeren"
                            :src="herunterladenMetacom"
                            width="100"
                            style="margin: auto"
                            alt=""
                        >
                    </div>
                </v-tooltip>
                <!-- Show drop down for pip videos, so the user can choose which video to download -->
                <v-menu
                    v-else-if="item.pipReference"
                    offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            tabindex="1"
                            class="btnStyle ml-1 babyViewActionBtn"
                            elevation="0"
                            x-small
                            v-bind="attrs"
                            v-on="on"
                        >
                            <img
                                :src="arrowDown"
                                class="babyViewActionBtnImg"
                                :alt="downloadMenuOpen ? 'Menü einklappen' : 'Menü ausklappen'"
                            >
                        </v-btn>
                    </template>
                    <div class="small-drop-down-menu">
                        <v-btn
                            tabindex="1"
                            style="text-transform: inherit; color: var(--v-dunkelgrau-base)"
                            text
                            class="d-flex justify-start"
                            @click="() => clickDownloadTeacherUpload(item)"
                        >
                            <img
                                :src="runterladenIcon"
                                class="mr-1 babyViewActionBtnImg"
                                alt="Herunterladen"
                            >
                            Hauptvideo herunterladen
                        </v-btn>
                        <v-btn
                            tabindex="1"
                            style="text-transform: inherit; color: var(--v-dunkelgrau-base)"
                            text
                            class="d-flex justify-start"
                            @click="() => clickDownloadTeacherUpload(babyFilesToDo.find(value => value._id === item.pipReference))"
                        >
                            <img
                                :src="runterladenIcon"
                                class="mr-1 babyViewActionBtnImg"
                                alt="Herunterladen"
                            >
                            DGS-Video herunterladen
                        </v-btn>
                    </div>
                </v-menu>

                <!-- 2nd button -->
                <v-tooltip
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            small
                            class="babyViewActionBtn ml-3"
                            v-on="on"
                            @click="pupilUploadFile"
                        >
                            <img
                                :src="kameraIcon"
                                class="babyViewActionBtnImg"
                                alt="Hochladen"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span>Hochladen</span>
                        <img
                            v-if="hoeren"
                            :src="hochladenMetacom"
                            width="100"
                            style="margin: auto"
                            alt=""
                        >
                    </div>
                </v-tooltip>

                <!-- 3rd button -->
                <v-tooltip
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            dark
                            x-small
                            class="babyViewActionBtn move ml-3"
                            v-on="on"
                            @click="$emit('moveWithoutAnswer')"
                        >
                            <img
                                :src="tick1Icon"
                                class="babyViewActionBtnImg editImg"
                                alt="Ohne Abgabe abgeben"
                            >
                        </v-btn>
                    </template>
                    <span>Ohne Abgabe abgeben</span>
                </v-tooltip>

                <!-- 4th button -->
                <!-- start of v-if -->
                <v-tooltip
                    v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop())"
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-badge
                            color="red"
                            content="1"
                            :value="!item.seenByMe"
                            overlap
                            left
                            offset-x="25"
                            style="z-index: 8; width: 100%; height: 48px !important;"
                        >
                            <v-btn
                                small
                                class="babyViewActionBtn edit ml-3"
                                dark
                                :loading="videoLoading"
                                v-on="on"
                                @click="play(item)"
                            >
                                <img
                                    :src="rechtsIcon"
                                    class="babyViewActionBtnImg editImg"
                                    alt="Video abspielen"
                                >
                            </v-btn>
                        </v-badge>
                    </template>
                    <span>Video abspielen</span>
                </v-tooltip>
                <v-tooltip
                    v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(item.title.split('.').pop())"
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-badge
                            color="red"
                            content="1"
                            :value="!item.seenByMe"
                            overlap
                            left
                            offset-x="25"
                            style="z-index: 8; width: 100%; height: 48px !important;"
                        >
                            <v-btn
                                small
                                class="babyViewActionBtn edit ml-3"
                                dark
                                :loading="videoLoading"
                                v-on="on"
                                @click="playAudio(item)"
                            >
                                <img
                                    :src="rechtsIcon"
                                    class="babyViewActionBtnImg editImg"
                                    alt="Audio abspielen"
                                >
                            </v-btn>
                        </v-badge>
                    </template>
                    <span>Audio abspielen</span>
                </v-tooltip>
                <v-tooltip
                    v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(item.title.split('.').pop().toLowerCase())
                        && (item.isAssignment || item.isAssignment == null)"
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-badge
                            color="red"
                            content="1"
                            :value="!item.seenByMe"
                            overlap
                            left
                            offset-x="25"
                            style="z-index: 8; width: 100%; height: 48px !important;"
                        >
                            <v-btn
                                :id="item._id + '-editButton'"
                                small
                                class="babyViewActionBtn edit ml-3"
                                v-on="on"
                                @click="openEdit(item)"
                            >
                                <img
                                    :src="bearbeitenIcon"
                                    class="babyViewActionBtnImg editImg"
                                    alt="im Editor bearbeiten"
                                >
                            </v-btn>
                        </v-badge>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span>Bearbeiten</span>
                        <div style="margin: auto">
                            <img
                                v-if="hoeren"
                                :src="hausaufgabenMetacom"
                                width="100"
                                class="mr-1"
                                alt=""
                            >
                            <img
                                v-if="hoeren"
                                :src="toDoMetacom"
                                width="100"
                                alt=""
                            >
                        </div>
                    </div>
                </v-tooltip>
                <v-tooltip
                    v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(item.title.split('.').pop().toLowerCase())"
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-badge
                            color="red"
                            content="1"
                            :value="!item.seenByMe"
                            overlap
                            left
                            offset-x="25"
                            style="z-index: 8; width: 100%; height: 48px !important;"
                        >
                            <v-btn
                                small
                                class="babyViewActionBtn edit ml-3"
                                v-on="on"
                                @click="handleOpenAnsichtClick"
                            >
                                <img
                                    :src="previewIcon"
                                    class="babyViewActionBtnImg editImg"
                                    alt="Ansehen"
                                >
                            </v-btn>
                        </v-badge>
                    </template>
                    <span>Ansehen</span>
                </v-tooltip>
                <div
                    v-else
                    style="display: inherit"
                >
                    <v-tooltip
                        :disabled="!showTooltips"
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                dark
                                x-small
                                class="babyViewActionBtn edit ml-3"
                                elevation="0"
                                style="background-color: #8CBD46;"
                                v-on="on"
                                @click="fileNotSupported(item)"
                            >
                                <img
                                    :src="infoIcon"
                                    class="babyViewActionBtnImg editImg"
                                    alt="Dateiformat nicht unterstützt"
                                >
                            </v-btn>
                        </template>
                        <span>Dateiformat nicht unterstützt</span>
                    </v-tooltip>
                </div>
                <!-- end of v-if -->
            </div>
            <div
                v-else-if="category === 'done'"
                class="my-0 mr-2"
                style="display: flex; margin-left: auto"
            >
                <v-tooltip
                    v-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(item.title.split('.').pop().toLowerCase())"
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            small
                            class="babyViewActionBtn ml-3"
                            v-on="on"
                            @click="handleOpenAnsichtClick"
                        >
                            <!--                                        <v-icon style="width: 20px; color: #939393" alt="Vorschausymbol">fas fa-eye</v-icon>-->
                            <img
                                :src="previewIcon"
                                style="width: 30px; color: #939393"
                                alt="Vorschau"
                            >
                        </v-btn>
                    </template>
                    <span>Vorschau</span>
                </v-tooltip>

                <v-tooltip
                    v-if="wasMovedByMe"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            small
                            class="babyViewActionBtn ml-3"
                            plain
                            text
                            v-on="on"
                        >
                            <img
                                :src="infoIcon"
                                class="babyViewActionBtnImg"
                                alt="Das Material wurde ohne Abgabe abgegeben"
                            >
                        </v-btn>
                    </template>
                    <span>Material wurde ohne Abgabe abgegeben</span>
                </v-tooltip>

                <v-tooltip
                    v-else-if="!wasMovedByMe"
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-bind="on"
                            small
                            class="babyViewActionBtn ml-3"
                            @click="downloadCorrectFile(item)"
                        >
                            <img
                                :src="runterladenIcon"
                                class="babyViewActionBtnImg"
                                alt="Herunterladen"
                            >
                        </v-btn>
                    </template>
                    <span>Herunterladen</span>
                </v-tooltip>
            </div>
            <div
                v-else
                class="my-0 mr-2"
                style="display: flex; margin-left: auto"
            >
                <v-tooltip
                    v-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(item.title.split('.').pop().toLowerCase())"
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-badge
                            color="red"
                            content="1"
                            :value="!item.seenByMe"
                            overlap
                            left
                            offset-x="25"
                            style="z-index: 8; width: 100%; height: 48px !important;"
                        >
                            <v-btn
                                small
                                class="babyViewActionBtn ml-3"
                                v-on="on"
                                @click="handleOpenAnsichtClick"
                            >
                                <!--                                        <v-icon style="width: 20px; color: #939393" alt="Vorschausymbol">fas fa-eye</v-icon>-->
                                <img
                                    :src="previewIcon"
                                    style="width: 30px; color: #939393"
                                    alt="Vorschau"
                                >
                            </v-btn>
                        </v-badge>
                    </template>
                    <span>Vorschau</span>
                </v-tooltip>

                <v-tooltip
                    v-else
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-badge
                            color="red"
                            content="1"
                            :value="!item.seenByMe"
                            overlap
                            left
                            offset-x="25"
                            style="z-index: 8; width: 100%; height: 48px !important;"
                        >
                            <v-btn
                                v-bind="on"
                                small
                                class="babyViewActionBtn ml-3"
                                @click="downloadCorrectFile(item)"
                            >
                                <img
                                    :src="runterladenIcon"
                                    class="babyViewActionBtnImg"
                                    alt="Herunterladen"
                                >
                            </v-btn>
                        </v-badge>
                    </template>
                    <span>Herunterladen</span>
                </v-tooltip>
            </div>
        </div>
        <div
            v-if="previewData"
            class="mx-2 mb-1 d-flex justify-center"
            style="max-height: 15vh; background-color: gray; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;"
        >
            <!-- Display image preview -->
            <v-progress-circular
                v-if="previewData.loading"
                indeterminate
                class="my-3"
                color="#F49F31"
            />
            <img
                v-else-if="['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop().toLowerCase()) && previewData.preview"
                :src="previewData.preview"
                style="cursor: pointer; max-width: 345px; max-height: 15vh"
                alt=""
                @click="() => ['png', 'jpg', 'jpeg', 'bmp', ].includes(item.title.split('.').pop().toLowerCase()) ? openEdit(item) : play(item)"
            >
            <!-- Display pdf preview -->
            <object
                v-else-if="['pdf'].includes(item.title.split('.').pop().toLowerCase())"
                type="application/pdf"
                :data="previewData.preview"
                style="cursor: pointer; max-width: 345px; max-height: 15vh"
                aria-hidden="true"
                tabindex="-1"
                @click="() => openEdit(item)"
            />
            <div
                v-else-if="previewData.fetched"
                class="my-2 grey--text text--lighten-5 subtitle-1"
            >
                Es ist keine Vorschau verfügbar
            </div>
        </div>
    </div>
</template>

<script>
import { textToSpeech } from "@/util/textToSpeech";
import * as backend from "@/api/backend";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import bioIcon from "@/assets/Icons/f-biologie-35.svg";
import chemieIcon from "@/assets/Icons/f-chemie-37.svg";
import deutschIcon from "@/assets/Icons/f-deutsch-29.svg";
import englischIcon from "@/assets/Icons/f-englisch-30.svg";
import erdkundeIcon from "@/assets/Icons/f-erdkunde-31.svg";
import geschichteIcon from "@/assets/Icons/f-geschichte-40.svg";
import matheIcon from "@/assets/Icons/f-mathe-32.svg";
import musikIcon from "@/assets/Icons/f-musik-84.svg";
import physikIcon from "@/assets/Icons/f-physik-36.svg";
import politikIcon from "@/assets/Icons/f-politik-34.svg";
import religionIcon from "@/assets/Icons/f-religion-39.svg";
import sportIcon from "@/assets/Icons/f-sport-38.svg";
import wirtschaftIcon from "@/assets/Icons/f-wirtschaft-33.svg";
import infoIcon from "@/assets/Icons/info-45.svg";
import bearbeitenIcon from "@/assets/Icons/bearbeiten-komplimentär-weiß-88.svg";
import rechtsIcon from "@/assets/Icons/rechts-filled-11.svg";
import runterladenIcon from "@/assets/Icons/runterladen-12.svg";
import kameraIcon from "@/assets/Icons/kamera-15.svg";
import arrowDown from "@/assets/Icons/unten-dropdown-12.svg";
import previewIcon from "@/assets/Icons/preview-85.svg";
import tick1Icon from "@/assets/Icons/tick-1.svg";
import hausaufgabenMetacom from "@/assets/METACOM/Hausis/hausaufgaben.png";
import toDoMetacom from "@/assets/METACOM/Hausis/machen.png";
import herunterladenMetacom from "@/assets/METACOM/Hausis/speichern.png";
import hochladenMetacom from "@/assets/METACOM/Hausis/fotoapparat.png";

export default {
    name: 'HausiWidgetListItem',
    props: {
        item: { required: true, type: Object },
        index: { required: true, type: Number },
        category: { required: true, type: String },
        account: { required: true, type: String },
        showTooltips: { required: false, type: Boolean, default: true },
        hoeren: { required: false, type: Boolean, default: false },
        sehen: { required: false, type: Boolean, default: false },
    },
    data: () => ({
        videoLoading: false,

        previewData: null,

        infoIcon,
        bearbeitenIcon,
        rechtsIcon,
        runterladenIcon,
        kameraIcon,
        arrowDown,
        previewIcon,
        tick1Icon,

        hausaufgabenMetacom,
        toDoMetacom,
        herunterladenMetacom,
        hochladenMetacom,

        fachIcons: [
            {name: 'bioIcon', icon: bioIcon},
            {name: 'chemieIcon', icon: chemieIcon},
            {name: 'deutschIcon', icon: deutschIcon},
            {name: 'englischIcon', icon: englischIcon},
            {name: 'erdkundeIcon', icon: erdkundeIcon},
            {name: 'geschichteIcon', icon: geschichteIcon},
            {name: 'matheIcon', icon: matheIcon},
            {name: 'musikIcon', icon: musikIcon},
            {name: 'physikIcon', icon: physikIcon},
            {name: 'politikIcon', icon: politikIcon},
            {name: 'religionIcon', icon: religionIcon},
            {name: 'sportIcon', icon: sportIcon},
            {name: 'wirtschaftIcon', icon: wirtschaftIcon},
        ],
    }),
    computed: {
        ...mapState("auth", ["token"]),
        ...mapMutations("snackbar", ["showSnackbar"]),

        wasMovedByMe() {
            if (this.item.isMoved) {
                for (let i = 0; i < this.item.isMoved.length; i++) {
                    if (this.item.isMoved[i] === this.account) {
                        return true;
                    }
                }
            }
            return false;
        },
    },
    mounted() {
        this.previewData = this.item.previewData;
    },
    methods: {
        ...mapActions('util', ['insertLastOpenedInEditor']),

        getSrc(icon) {
            const foundItem = this.fachIcons.find(item => item.name === icon);
            return foundItem.icon;
        },
        async readExerciseName(item) {

            await textToSpeech(item.title, 'de');
        },
        async clickDownloadTeacherUpload(file) {
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(file.appointmentId, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = file.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    setTimeout(() => {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                });
        },
        pupilUploadFile() {

            // Move to emit to avoid giving appointments to list item
            this.$emit('uploadPupilAnswer');
        },
        async downloadCorrectFile(file) {
            if (file.editParent && !file.editLeafs.length) {
                // File is Correction and seen by user
                await this.clickDownloadTeacherUpload(file);
            } else {
                // File is pupil upload that is not corrected
                // await this.clickDownloadPupilUpload(file);
                this.$emit('downloadPupilUpload');
            }
        },
        async play(file) {
            this.$emit('playVideo');
        },
        async playAudio(file) {
            this.$emit('playAudio');
        },
        openEdit(item) {
            const title = item.title;
            const file = item.file;
            const id = item._id;
            const group = item.groupName;
            const color = item.fachColor;
            const appointmentId = item.appointmentId;
            this.insertLastOpenedInEditor(id);

            this.$router.push({
                name: 'editor',
                params: { mode: 'pupil' },
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: title,
                        fId: file,
                        uploadid: id
                    }
            });
        },

        handleOpenAnsichtClick() {
            this.$emit('openViewer');
        },

        fileNotSupported(el) {
            this.showSnackbar({
                message: "Dieses Dateiformat kann in eKlara nicht bearbeitet werden. Bitte lade die Datei herunter.",
                color: "error"
            });
        },
    }
}
</script>
<style scoped lang="scss">
.fileRow {
    background-color: rgb(112 112 112 / 15%);
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    height: 76px !important;
}

.emptyRow {
    background-color: transparent;
    border-radius: 5px;
    height: 35px !important;

    font-size: 25px;

    & p {
        text-align: center;
        width: 100%;
        margin-bottom: 0;
    }
}

.fachLine {
    display: none;
}
.fileTitle {
    margin-bottom: 0 !important;
    font-size: 25px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.babyViewActionBtn {
    min-width: 50px !important;
    width: 50px !important;
    height: 50px !important;
}

.fachLine {
    display: none;
}

.sectionHeaderText {
    color: #F49F31;
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 0;
    display: inline-block;
}

.sectionHeaderCol {
    padding-top: 0;
    padding-bottom: 0;
}

.small-drop-down-menu {
    display: block;
    background-color: #F8F8F8;
    padding-top: 5px;
    padding-bottom: 5px;

    & button {
        width: 100%;
        padding: 25px 10px !important;
    }
}

.smallBabyViewActionBtn {
    height: 40px !important;
    min-width: 40px !important;
    width: 40px !important;
    border-radius: 5px;
    box-shadow: 1px 2px 3px silver;
}

.babyViewActionBtnImg {
    width: 35px !important;
    height: 35px !important;
}

.smallBabyViewActionBtnImg {
    width: 28px !important;
    height: 28px !important;
    filter: brightness(1000%);
}

.editImg {
    filter: brightness(1000%);
}

.edit {
    background-color: #8CBD46 !important;
}

.move {
    background-color: #F49F31 !important;
}

.icon {
    height: 20px;
}

.iconToWhite {
    filter: brightness(1000%);
}
</style>
