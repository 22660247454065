import { checkResponseStatus } from '@/util/check';
import * as backend from '../../api/backend';

const state = {
  parents: [],
}

const getters = {
  parents: (state) => state.parents,
  parentsByAccountId: state => state.parents.reduce((acc, parent) => {
    acc[parent.account] = parent;
    return acc;
  }, {}),
}

const mutations = {
  setParents: (state, parents) => {
    state.parents = parents;
  },
}

const actions = {
  async getParents({ commit }) {
    try {
      const res = await backend.getParents();
      await checkResponseStatus(200, res);
      const parents = await res.json();
      commit('setParents', parents);
      return parents;
    }
    catch (err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async getParent({ commit, dispatch }, params) {
    try {
      const res = await backend.getParent(params);
      await checkResponseStatus(200, res);
      return await res.json();
    }
    catch (err) {
      console.error(err);
            return err?.response?.status;
    }
  },

    // returns pupilParent for requesting parent
  async getMeParent ({ dispatch }) {
    try {
      const res = await backend.getMeParent();
      await checkResponseStatus(200, res);

      dispatch('translation/setTranslationAllowed', true, { root: true });

      return await res.json();
    }
    catch (err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async createParent({ commit, dispatch }, params) {
    try {
      const res = await backend.postParent(params);
      await checkResponseStatus(201, res);
      return await res.json();
    }
    catch (err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async editParent({ commit, dispatch }, params) {
    try {
      const id = params._id
      delete params._id;
      const res = await backend.patchParent(id, params);
      if (res.status === 409) {
        return 409;
      }
      return await res.json();
    }
    catch (err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async deleteParent({ commit, dispatch }, id) {
    try {
      const res = await backend.deleteParent(id);
      if (res.status === 204) {
        return 204;
      }
      return await res.json();
    }
    catch (err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async getChildTeacher({ commit, dispatch }, childId) {
    try {
      const res = await backend.getChildTeacher(childId)
      await checkResponseStatus(200, res);
      return await res.json();
    }
    catch (err) {
      return err.response;
    }
  },
}

export default {
  state,
  getters,
  mutations,
  namespaced: true,
  actions,
};
