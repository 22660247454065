<template>
    <div class="group-chat-wrapper">
        <div class="group-chat-header d-flex align-center justify-space-between">
            <v-tooltip
                :disabled="!showTooltips"
                bottom
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        id="backButton"
                        class="ma-2 group-chat-header-btn"
                        color="rgba(248, 248, 248, 0.4)"
                        small
                        v-on="on"
                        @click="clickBack"
                    >
                        <img
                            :src="pfeilIcon"
                            class="icon iconToWhite"
                            style="transform: rotate(90deg)"
                            alt="Zurück"
                        >
                    </v-btn>
                </template>
                <span>Zurück</span>
            </v-tooltip>
            <h2
                id="chatName"
                tabindex="0"
                class="group-chat-header-text mb-0"
                style="cursor: pointer"
                @click="showChatRoomInfo(chatRoom)"
            >
                {{ chatRoom.displayName }}
            </h2>
            <v-spacer />
            <ToggleChatRoomOpenButton
                v-if="chatRoomMembershipRole === 'moderator'"
                class="mr-2"
                :chat-room="chatRoom"
            />
            <ChatWidgetMenu
                :show-tooltips="showTooltips"
                :current-room="chatRoom"
                :settings="chatRoomMembershipRole === 'moderator'"
                :can-user-leave-chat-room="chatRoomMembershipRole === 'member' && accountRole === 'teacher' || accountRole === 'maintainer' "
                @updateRoomList="clickBack"
                @deleteChatRoom="deleteChatRoom"
                @leaveChatRoom="leaveChatRoom"
            />
        </div>
        <!-- show no area to send Messages, because Chatroom is locked -->
        <div
            v-if="chatRoom && !chatRoom.isOpen"
            class="chat-send-wrapper align-center justify-center"
            style="width: 100%; height: 72px"
        >
            <img
                :src="schlossIcon"
                class="iconToGrey mr-2"
                style="width: 32px; height: 32px"
                alt="Chat geschlossen"
            >
            Chat gesperrt. Nachrichten können nicht gesendet werden.
        </div>

        <div
            v-if="chatRoom"
            id="chatBody"
            class="chat-body"
        >
            <ScrollAreaMagnifier
                ref="scrollAreaMagnifier"
                class="scroll-area-magnifier"
                :settings="settings"
                :usage="'chatWidget'"
                @ps-y-reach-start="loadMore"
            >
                <v-overlay
                    v-if="poll"
                    absolute
                    overlay-opacity="0.8"
                />
                <ChatRoomEventListItem
                    v-for="event in mappedChatRoomEvents"
                    :key="event._id+event.updatedAt"
                    :chat-room-membership-role="chatRoomMembershipRole"
                    :chat-room-event="event"
                    class="my-2"
                    style="
            position: relative;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
          "
                />
            </ScrollAreaMagnifier>
        </div>


        <!-- text area for composing message -->
        <div
            v-if="chatRoom && chatRoom.isOpen"
            class="chat-send-wrapper align-center"
            style="width: 100%"
        >
            <v-textarea
                id="chatTextArea"
                ref="chatTextArea"
                v-model="inputMessage"
                label="Nachricht"
                hide-details
                flat
                solo
                auto-grow
                no-resize
                oncontextmenu="return false;"
                placeholder="Nachricht eingeben..."
                rows="1"
                row-height="25"
                style="max-height: 100px; overflow-x: hidden; overflow-y: auto"
                class="chat-send-input pl-5 my-3"
                @focusin="scrollWidgetContainer"
                @keypress="textAreaChatChanged"
                @keyup.enter.exact.prevent="sendMessage"
                @keydown.enter.shift.exact.prevent="inputMessage += '\r'"
            >
                <template v-slot:append>
                    <v-btn
                        v-if="accountRole === 'teacher'"
                        icon
                        class="pointer d-flex justify-center"
                        @click="poll = true"
                    >
                        <img
                            :src="pollIcon"
                            class="bigIcon"
                            alt="Umfrage erstellen"
                        >
                    </v-btn>
                    <ChatRoomImageUploadButton :chat-room="chatRoom" />
                </template>
            </v-textarea>

            <ChatRoomMicrophoneAudioUploadButton
                v-if="!inputMessage"
                oncontextmenu="return false;"
                style="z-index: 10 !important"
                :chat-room-id="chatRoom._id"
            />
            <v-tooltip
                v-else
                :disabled="!showTooltips"
                top
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        text
                        class="ma-0"
                        v-on="on"
                        @click="sendMessage"
                    >
                        <img
                            :src="sendenIcon"
                            alt="Nachricht senden"
                            height="30"
                        >
                    </v-btn>
                </template>
                <span>Senden</span>
            </v-tooltip>
        </div>


        <!-- creat poll card/bottom sheet -->
        <v-card v-if="poll">
            <v-card-text style="background-color: white">
                <Poll
                    :chat-mode="true"
                    @closePollCard="poll = false"
                />
            </v-card-text>
        </v-card>

        <ChatRoomInfoDialog
            v-if="showChatRoomInfoDialog"
            :chat-room="chatRoom"
            @closeDialog="showChatRoomInfoDialog = false"
        />
    </div>
</template>

<script>
//import * as sdk from "matrix-js-sdk";
import * as backend from "../../api/backend";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { bus } from "@/main";

import pfeilIcon from "../../assets/Icons/unten-dropdown-filled-12.svg";
import downloadIcon from "../../assets/Icons/download-116.svg";
import sendenIcon from "../../assets/Icons/senden-23.svg";
import pollIcon from "../../assets/Icons/survey.svg";
import schlossIcon from "../../assets/Icons/lock-closed.svg";


import Poll from "@/components/Poll";
import ScrollAreaMagnifier from "@/components/ScrollAreaMagnifier";
import OpenChatRoomSettingsButton from "./Components/OpenChatRoomSettingsButton";
import ChatRoomEventListItem from "./Components/ChatRoomEventListItem/ChatRoomEventListItem.vue";
import ChatRoomImageUploadButton from "./Components/ChatRoomImageUploadButton.vue";
import ChatRoomMicrophoneAudioUploadButton from "./Components/ChatRoomMicrophoneAudioUploadButton.vue";
import LeaveChatRoomButton from "./LeaveChatRoomButton.vue";
import ToggleChatRoomOpenButton from "./ToggleChatRoomOpenButton.vue";
import ChatWidgetMenu from "@/components/ChatV2/Components/ChatWidgetMenu";
import ChatRoomInfoDialog from "@/components/ChatV2/Components/ChatRoomInfoDialog";

export default {
  name: "ChatWidgetGroupChatV2",
  components: {
    ChatWidgetMenu,
    ScrollAreaMagnifier,
    ChatRoomEventListItem,
    ChatRoomImageUploadButton,
    ChatRoomMicrophoneAudioUploadButton,
    ToggleChatRoomOpenButton,
    ChatRoomInfoDialog,
    Poll,
  },
  props: {
    showTooltips: { required: false, default: true },
    chatRoomId: { required: true, type: String },
    currentChild: { required: false, type: Object },
  },
  data: () => ({
    inputMessage: "",
    settings: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: true,
    },
    offsetHeightChat: 32,

    pfeilIcon,
    sendenIcon,
    pollIcon,
    schlossIcon,

    poll: false,
    // waitForMessageWatcherEnabled: false,
    isTouchDevice: false,

    showChatRoomInfoDialog: false,
    systemCreatedMatrixRoomsIds: [], // ids of group chats generated by the system
  }),
  computed: {
    ...mapState("util", ["keyboard", "windowWidth"]),
    ...mapGetters("auth", ["accountRole", "accountId"]),
    ...mapState("translation", ["targetLang"]),
    ...mapState("auth", ["token", "account"]),
    ...mapState("magnifier", ["magnifier"]),
    ...mapState("chat", ["chatRooms", "chatRoomEvents"]),
    ...mapGetters("chat", ["chatRoomEventsByChatRoom", "chatRooms"]),
    chatRoom() {
      // Dont deliver currentChatRoom over props because it might be outdated.
      // e.g. it contains the lastReadTimestamps which should be always up to date.

      // Use chatRoom object via store where it is updated regularly via websockets
      return this.chatRooms.find(
        (chatRoom) => chatRoom._id === this.chatRoomId
      );
    },
    mappedChatRoomEvents() {
      return this.chatRoomEventsByChatRoom[this.chatRoom._id] || [];
    },
    chatRoomMembershipRole() {
      const memberships = this.chatRoom.memberships.filter((membership) =>
        membership.addressedAccounts.find(
          (addressedAccount) => addressedAccount === this.account._id
        )
      );

      const isModerator = memberships.find(
        (membership) => membership.role === "moderator"
      );

      return isModerator ? "moderator" : "member";
    },
  },
  watch: {
    currentChild: async function () {
      if (this.accountRole === "parent") {
        // TODO
      }
    },
    mappedChatRoomEvents: {
      immediate: true,
      // Update the last seen timestamp if there are new unread messages
      async handler(events) {
        const accountTimestampObject =  this.chatRoom.lastSeenTimestamps.find(({ timestamp, account }) => {
            if (account === this.account._id) {
              return true;
            }
          });
        const lastSeenTimestamp = accountTimestampObject?.timestamp || 0;

        const chatRoomEvents =
          this.chatRoomEventsByChatRoom[this.chatRoom._id] || [];
        const newChatRoomEvents = chatRoomEvents.filter(
          (chatRoomEvent) =>
            new Date(chatRoomEvent.createdAt) >= new Date(lastSeenTimestamp)
        );

        if (newChatRoomEvents.length > 0) {
          await backend.updateChatRoomLastSeenTimestamp(this.chatRoom._id);
        }
      },
    },
  },
  async created() {
    while (!this.$refs.scroll) {
      await this.sleep(500);
    }
    this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight;
  },
  async mounted() {
    bus.$off("sendPollMessage");
    // listens to emitted event via bus (bus is created in main.js)
    bus.$on("sendPollMessage", (pollId) => {
      this.sendPollMessage(pollId);
    });

    // https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript/4819886#4819886
    this.isTouchDevice =
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;
  },
  methods: {
    ...mapActions("translation", [
      "setTranslatedText",
      "showTranslation",
      "translateToTargetLang",
    ]),
    ...mapActions("appointments", ["getAppointments", "getAppointmentsParent"]),
    ...mapActions("pupils", ["getPupils", "getMePupil"]),
    ...mapActions("teachers", ["getTeachers", "getProfilePicture"]),
    ...mapActions("parents", ["getParents"]),
    ...mapActions("maintainers", ["getMaintainers"]),
    ...mapActions("groups", ["getGroupsInfo"]),
    ...mapActions("chat", ["sendTextMessage", "redactChatRoomEvent"]),
    async sendPollMessage(poll) {
      await backend.postChatRoomEvent({
        chatRoom: this.chatRoom._id,
        type: "pollMessage",
        poll: poll._id,
      });
    },
    deleteChatRoom() {
      this.$emit("deleteChatRoom", this.chatRoomId);
    },
    leaveChatRoom() {
      this.$emit("leaveChatRoom", this.chatRoomId);
    },
    textAreaChatChanged() {
      let el = document.getElementById("chatTextArea");
      this.offsetHeightChat = el.offsetHeight > 100 ? 100 : el.offsetHeight;
    },
    scrollWidgetContainer() {
      if (this.windowWidth < 900) {
        // use scrollintoview
        this.$refs.chatTextArea.$el.scrollIntoView(true);
      }
    },

    async loadMore() {},
    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    sendMessage() {
      if (this.inputMessage.trim()) {
        this.sendTextMessage({
          text: this.inputMessage,
          chatRoomId: this.chatRoom._id,
        });
        this.inputMessage = "";
      }
    },
    clickBack() {
      this.$emit("exitRoom");
    },

    async showChatRoomInfo(room) {
      this.showChatRoomInfoDialog = true;
    },
  },
};
</script>

<style>
.chat-send-input .v-input__append-inner {
  margin-top: 8px !important;
}

.chat-send-input .v-text-field__slot textarea {
  padding-right: 0px !important;
}
</style>

<style lang="scss" scoped>
.chat-send-input {
  background-color: white;
  border-radius: 50px;
  width: 100%;
}

.chat-send-wrapper {
  display: inline-flex;
  background-color: #f2f2f2;
  padding: 0 12px;
  flex: none;
}

.group-chat-wrapper {
  flex: auto;
  display: flex;
  flex-direction: column;
  // overflow: auto;
  background-color: white !important;
  border-radius: 0 0 15px 15px;
}

.scroll-area-magnifier {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.chat-body {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.group-chat-header-btn {
  padding: 0 !important;
  min-width: 0 !important;
  border-radius: 8px;
  width: 36px !important;
  height: 36px !important;
  flex: none;
}

.group-chat-header-text {
  color: white;
}

.group-chat-header {
  background-color: #8cbd46; // 3caa68 - 5% Luminosity
  height: 56px;
  flex: none;
}

.chat-header-btn {
  background-color: white !important;
  width: 35px;
  height: 35px !important;
}

.chat-header-img {
  height: 20px;
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {

  // position: relative;
  // margin: auto;
  // max-height: calc(80vh - 176px);
  // height: calc(80vh - 179px);
  // overflow: hidden;
  // margin-bottom: 2px;
}

.scroll-area-disabled {
  // position: relative;
  // margin: auto;
  // max-height: calc(80vh - 176px);
  // height: calc(80vh - 179px);
  // overflow: hidden;
  // margin-bottom: 2px;
}

.chat-message {
  position: relative;
  border-radius: 5px;
  max-width: 75%;
  margin: 2px 2px 2px 19px;
}

.myMessage {
  margin-left: auto !important;
  margin-right: 19px !important;
}

/*removes darker background after element is clicked
also works without the specific class in front*/
.myMessage:before {
  opacity: 0 !important;
}
.chat-message:before {
  opacity: 0 !important;
}

.chat-message-content {
  border-radius: 0 5px 5px 5px;
  box-shadow: 1px 2px 3px silver;
  background-color: var(--v-blau-base);
  /*background-color: #9b91c8;*/ //use me when using chat gender colors again
  color: white;
  padding: 9px;
}
.male-chat {
  background-color: var(--v-blau-base) !important;
}
.female-chat {
  background-color: var(--v-pink-base) !important;
}

.myMessage-content {
  border-radius: 5px 5px 0 5px !important;
  background-color: var(--v-grau-base);
  color: black;
}

.progressbar {
  border-radius: 5px;
}

.icon {
  height: 20px;
}

.bigIcon {
  height: 30px;
}

.pointer {
  cursor: pointer;
}

.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.greyText {
  color: var(--v-dunkelgrau-base);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
h2 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

#mandatoryFloatBtn {
  position: absolute;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translateY(-50%);
  bottom: 2%;
}

.formHeader {
  font-size: 16px;
  margin-bottom: 20px !important;
}

.optionBtn {
  border-radius: 8px;
  min-width: 35px !important;
  height: 35px !important;
}

/* width */
::-webkit-scrollbar {
  width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 6px;
  width: 7px;
}
::-webkit-scrollbar-thumb:hover {
  background: #999;
  border-radius: 6px;
  width: 11px;
}
</style>
