<template>
    <!-- teacher -->
    <v-btn
        id="schlossBtn"
        icon
        small
        @click.stop.prevent="toggleChatRoomOpen"
    >
        <img
            :src="isOpen ? schlossOffenIcon : schlossIcon"
            :alt="isOpen ? 'Chat schließen' : 'Chat öffnen'"
        >
    </v-btn>
</template>

<script>
import schlossIcon from "../../assets/Icons/lock-closed.svg";
import schlossOffenIcon from "../../assets/Icons/lock-open.svg";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import * as backend from "../../api/backend.js";

export default {
  name: "ToggleChatRoomOpenButton",
  props: {
    chatRoom: { required: true, type: Object },
  },
  data() {
    return {
      schlossIcon,
      schlossOffenIcon,
    };
  },
  computed: {
    ...mapGetters("auth", ["accountRole"]),
    ...mapState("translation", ["targetLang"]),
    ...mapState("util", ["windowWidth"]),
    isOpen() {
      return this.chatRoom.isOpen;
    },
  },

  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),

    async toggleChatRoomOpen() {
      const res = await backend.patchChatRoom(this.chatRoom._id, {
        isOpen: !this.chatRoom.isOpen,
      });

      if (res.status === 200 && !this.chatRoom.isOpen) {
        this.showSnackbar({
          message: "Der Chat ist nun für alle Schüler geöffnet.",
        });
      } else if (res.status === 200 && this.chatRoom.isOpen) {
        this.showSnackbar({
          message:
            "Der Chat ist nun für alle Schüler geschlossen. Schüler können nicht mehr beitreten. Bereits beigetretene Schüler können nicht mehr schreiben und keine Nachrichten löschen.",
        });
      } else {
        this.showSnackbar({
          message: "Unerwarteter Fehler beim Schliessen/Öffnen des Chats. Bitte wenden sie sich an den Support.",
          color: "error",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn {
  border-radius: 8px;
}



#schlossBtn {
  img {
    width: 24px;
    height: 24px;
    filter: brightness(1000%);
  }
}

</style>
