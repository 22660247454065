<template>
    <div
        :id="`fileSystemElement${item._id}`"
        class="pa-3 my-2 mx-auto fileSystemElement greyBorder"
        style="display: flex; justify-content: center; position: relative"
        :style="getStyle()"
    >
        <div
            :style="getStyleList()"
            style="width: 100%; align-items: center"
            @click="handleClickElement"
        >
            <div v-if="vfsGridWidthPct===100">
                <v-icon
                    v-if="type !== 'folder'"
                    class="pr-1"
                    small
                    style="display: flex; justify-content: flex-start; align-content: center"
                >
                    mdi-file
                </v-icon>
                <v-icon
                    v-if="type === 'folder' && folderType === 'privateFolder'"
                    class="pr-1"
                    small
                    style="display: flex; justify-content: flex-start; align-content: center"
                >
                    mdi-folder
                </v-icon>
                <v-icon
                    v-else-if="type === 'folder' && (folderType === 'teacherFolder' || folderType === 'documentRegistry')"
                    class="pr-1"
                    small
                    style="display: flex; justify-content: flex-start; align-content: center"
                >
                    mdi-folder-account
                </v-icon>
            </div>
            <div
                class="fileSystemElementNameCentered"
                :style="vfsGridWidthPct === 100 ? 'max-width: 20vw' : ''"
            >
                <div v-if="item.isEditing || isEditing || (folderType === 'groupFolder' && (item.isMoving || isMoving))">
                    <v-text-field
                        v-model="editedNameValue"
                        dense
                        outlined
                        single-line
                        hide-details
                        :placeholder="editedNameValue ? editedNameValue : 'Neuer Ordner'"
                        style="margin-bottom: 6px"
                    />
                    <!-- select receiver(s) page -->
                    <div
                        v-show="item.type !== 'file' && folderType === 'groupFolder' && (item.isMoving || isMoving || item.isCreating)"
                        class="pa-2"
                    >
                        <span> Ordner teilen mit: </span>
                        <div class="d-flex align-center">
                            <img
                                :src="searchIcon"
                                alt="Suche"
                                class="icon mr-2"
                            >

                            <v-text-field
                                v-model="searchTreeView"
                                class="ma-0 pt-0 pb-2"
                                hide-details
                                clearable
                            />
                        </div>
                        <hr class="greyLine">

                        <vue-custom-scrollbar
                            class="scroll-area-treeview"
                            settings="settings"
                        >
                            <v-treeview
                                v-model="receiverAccounts"
                                :items="possibleReceivers"
                                item-children="participants"
                                item-key="_id"
                                item-text="displayName"
                                :search="searchTreeView"
                                selected-color="#AAC937"
                                selectable
                                dense
                                class="mt-2"
                                return-object
                            >
                                <template v-slot:prepend="{ item }">
                                    <div class="d-flex align-center">
                                        <img
                                            v-if="item.gender"
                                            :src="pupilIcon"
                                            :class="item.gender === 'w' ? 'iconToFemale' : (item.gender === 'm' ? 'iconToMale' : 'iconToDiverse')"
                                            height="20"
                                            alt=""
                                        >
                                        <img
                                            v-else
                                            :src="groupIcon"
                                            alt=""
                                        >
                                    </div>
                                </template>
                            </v-treeview>
                        </vue-custom-scrollbar>
                    </div>
                </div>
                <div v-else>
                    <div
                        style="display: flex"
                        tabindex="0"
                        @click.stop="handleClickElement"
                        @keypress.enter="handleClickElement"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="accountRole === 'pupil' && isTask"
                                    style="margin-right: 5px;"
                                    :style="alreadySubmitted ? 'color: green !important;' : activeTask ? '' : 'color: red !important;'"
                                    v-on="on"
                                > 
                                    {{ alreadySubmitted ? 'mdi-check-circle-outline' : activeTask ? 'mdi-checkbox-blank-circle-outline' : 'mdi-lock-outline' }}
                                </v-icon>
                            </template>
                            <span> {{ alreadySubmitted ? 'Du hast deine Abgabe eingereicht' : activeTask ? 'Die Aufgabe muss noch erledigt werden' : 'Die Aufgabe wurde beendet' }} </span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="accountRole === 'teacher' && isTask"
                                    style="margin-right: 5px"
                                    v-on="on"
                                > 
                                    {{ activeTask ? 'mdi-lock-open-variant-outline' : 'mdi-lock-outline' }}
                                </v-icon>
                            </template>
                            <span> {{ activeTask ? 'SuS können die Aufgabe bearbeiten' : 'SuS können die Aufgabe nicht mehr bearbeiten' }} </span>
                        </v-tooltip>
                        <!-- todo: find out if this task has been solved -->
                        <v-icon
                            v-if="false"
                            style="margin-right: 5px"
                        > 
                            mdi-check-circle-outline
                        </v-icon>
                        <p
                            :id="'fileName' + item._id"
                            class=""
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            {{ item.name || item.uploadedName }}
                        </p>
                        <!-- Nicos version with scrolling name, needs fixing first
                <p :id="'fileName' + item._id" class="hoverTransform" @mouseover="transformX" @mouseleave="removeStyleTag" :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''">
                    {{ item.name || item.uploadedName }}
                </p> -->
                    </div>
                </div>
            </div>
            <!-- Thumbnail-Preview -->
            <div
                v-if="vfsGridWidthPct<100"
                style="display: flex; align-items: center; justify-content: space-around"
            >
                <div
                    :class="getThumbnailStyle()"
                    style="margin-bottom: 5px; overflow:hidden"
                >
                    <v-img
                        v-if="item.type === 'file' && !previewPDF"
                        :src="preview"
                        contain
                        aspect-ratio="1.5"
                        :alt="item.name || item.uploadedName"
                        :lazy-src="img_eklaraLogo"
                    >
                        <div class="fill-height bottom-gradient" />
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height fill-width ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey"
                                />
                            </v-row>
                        </template>
                    </v-img>
                    <embed
                        v-else-if="item.type === 'file' && previewPDF"
                        :title="item.uploadedName"
                        :src="preview"
                        align="center"
                        type="application/pdf"
                        border="0"
                        loading="lazy"
                        class="iOS_ResponsiveHack"
                    >
                    <v-icon
                        v-else-if="type === 'folder' && folderType === 'privateFolder'"
                        class="pr-1"
                        x-large
                        style="display: flex; justify-content: center; align-content: space-around; margin-top: 20px"
                    >
                        mdi-folder
                    </v-icon>
                    <v-icon
                        v-else-if="type === 'folder' && (folderType === 'teacherFolder' || folderType === 'documentRegistry' || folderType === 'groupFolder') && (!isEditing && !item.isEditing && !isMoving && !item.isMoving)"
                        class="pr-1"
                        x-large
                        style="display: flex; justify-content: center; align-content: space-around; margin-top: 20px"
                    >
                        mdi-folder-account
                    </v-icon>
                </div>
            </div>
        </div>
        <div v-if="item.isEditing || isEditing || item.isMoving || isMoving">
            <v-btn
                x-small
                elevation="0"
                class="ml-2 pa-0 fileWidgetButton"
                @click="cancelFolderEdit"
            >
                <img
                    :src="cancelIcon"
                    style="height: 20px;"
                >
            </v-btn>
            <v-btn
                x-small
                elevation="0"
                class="ml-2 pa-0 fileWidgetButton"
                @click="acceptFolderEdit"
            >
                <img
                    :src="acceptIcon"
                    style="height: 20px;"
                >
            </v-btn>
        </div>
        <!-- Normal Buttons -->
        <div
            v-else
            class="fileSystemElementName"
            style="justify-content: flex-end; align-items: flex-end; position: absolute; bottom: 8px; right: 8px"
        >
            <!--            @click.stop="handleClickElement"-->
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                        v-if="(folderType !== 'documentRegistry' || (folderType === 'documentRegistry' && type === 'file')) && !(folderType === 'groupFolder' && accountRole === 'pupil' && type === 'folder')"
                        ref="fileSystemElMenuButton"
                        x-small
                        elevation="0"
                        class="ml-2 pa-0 fileWidgetButton"
                        v-on="{ ...tooltip }"
                        @click="openMenu = !openMenu"
                    >
                        <img
                            :src="menuIcon"
                            class="pa-1"
                            style="height: 20px; filter: brightness(0); opacity: 0.8"
                        >
                    </v-btn>
                </template>
                <span>Weitere Aktionen</span>
            </v-tooltip>
            <div
                v-if="openMenu"
                v-click-outside="clickOutsideMenu"
                :style="`position: absolute; right: ${vfsGridWidthPct > 50 ? 30 : -10}px; z-index: 5000`"
                style="position: absolute; right: 30px;"
                class="d-flex flex-wrap flex-column align-stretch"
            >
                <!-- Menu Content -->
                <v-btn
                    v-if="type === 'file'"
                    elevation="0"
                    class="pa-0 text-none fileSystemElementOptionsMenuButton"
                    @click="() => { $emit('loadFullscreenPreview', item) }"
                >
                    <v-icon style="width: 20px; color: #939393">
                        mdi-eye
                    </v-icon>
                    <p
                        class="pl-2"
                        :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                    >
                        Vorschau
                    </p>
                </v-btn>
                <v-btn
                    v-if="type === 'file' && folderType === 'privateFolder'"
                    elevation="0"
                    class="pa-0 text-none fileSystemElementOptionsMenuButton"
                    @click="viewFileFunction"
                >
                    <v-icon style="width: 20px; color: #939393">
                        mdi-brush
                    </v-icon>
                    <p
                        class="pl-2"
                        :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                    >
                        Bearbeiten
                    </p>
                </v-btn>
                <v-btn
                    v-if="type === 'file'"
                    elevation="0"
                    class="pa-0 text-capitalize fileSystemElementOptionsMenuButton"
                    @click="() => { if (type === 'file') $emit('download', item) }"
                >
                    <v-icon style="width: 20px; color: #939393">
                        mdi-cloud-download
                    </v-icon>
                    <p
                        class="pl-2"
                        :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                    >
                        Herunterladen
                    </p>
                </v-btn>
                <v-btn
                    v-if="folderType !== 'documentRegistry' && !(accountRole === 'pupil' && folderType === 'groupFolder') && !vfs.rulesetTask"
                    elevation="0"
                    class="pa-0 text-capitalize fileSystemElementOptionsMenuButton"
                    @click="handleDeleteButtonPress"
                >
                    <img
                        :src="papierkorbIcon"
                        style="height: 20px;"
                    >
                    <p
                        class="pl-2"
                        :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                    >
                        Löschen
                    </p>
                </v-btn>
                <v-btn
                    v-if="folderType !== 'documentRegistry' && folderType !== 'groupFolder' && !vfs.rulesetTask"
                    elevation="0"
                    class="pa-0 text-capitalize fileSystemElementOptionsMenuButton"
                    @click="handleEditButtonPress()"
                >
                    <img
                        :src="penIcon"
                        style="height: 20px;"
                    >
                    <p
                        class="pl-2"
                        :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                    >
                        Umbenennen
                    </p>
                </v-btn>
                <v-btn
                    v-if="folderType !== 'documentRegistry' && !(folderType === 'groupFolder' && (accountRole === 'pupil' || (type === 'folder' && accountId !== item.owner))) && !vfs.rulesetTask"
                    elevation="0"
                    class="pa-0 text-capitalize fileSystemElementOptionsMenuButton"
                    @click="handleMoveButtonPress()"
                >
                    <img
                        :src="penIcon"
                        style="height: 20px;"
                    >
                    <p
                        class="pl-2"
                        :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                    >
                        {{ folderType === 'groupFolder' ? "Verwalten" : "Verschieben" }}
                    </p>
                </v-btn>
                <v-btn
                    v-if="type === 'file' && accountRole === 'teacher' && folderType !== 'documentRegistry' && folderType !== 'groupFolder'"
                    elevation="0"
                    class="pa-0 text-capitalize fileSystemElementOptionsMenuButton"
                    @click="() => { $emit('notifyMoveFile') }"
                >
                    <v-icon
                        color="grey darken-1"
                        size="20"
                    >
                        mdi-file-move-outline
                    </v-icon>
                    <p
                        class="px-2"
                        :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                    >
                        {{ `${folderType === 'teacherFolder' ? 'private' : 'geteilte'} Kopie` }}
                    </p>
                </v-btn>
                <v-btn
                    v-if="debugMode && type === 'file' && accountRole === 'teacher' && folderType === 'teacherFolder'"
                    elevation="0"
                    class="pa-0 text-capitalize fileSystemElementOptionsMenuButton"
                    @click="() => { $emit('publishDialog') }"
                >
                    <v-icon
                        color="grey darken-1"
                        size="20"
                    >
                        mdi-file-move-outline
                    </v-icon>
                    <p
                        class="px-2"
                        :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                    >
                        Veröffentlichen
                    </p>
                </v-btn>
                <v-btn
                    v-if="folderType === 'groupFolder' && type === 'file' && isTask && (accountRole === 'teacher' || (accountRole === 'pupil' && alreadySubmitted))"
                    elevation="0"
                    class="pa-0 text-capitalize fileSystemElementOptionsMenuButton"
                    @click="handleOpenSubmissionsButtonPress()"
                >
                    <v-icon style="width: 20px; color: #939393">
                        mdi-folder-open-outline
                    </v-icon>
                    <p
                        class="pl-2"
                        :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                    >
                        Abgaben öffnen
                    </p>
                </v-btn>
                <v-btn
                    v-if="folderType === 'groupFolder' && accountRole === 'teacher' && type === 'file' && !vfs.rulesetTask"
                    elevation="0"
                    class="pa-0 text-capitalize fileSystemElementOptionsMenuButton"
                    @click="setIsTask()"
                >
                    <v-icon style="width: 20px; color: #939393">
                        {{ isTask ? activeTask ? 'mdi-lock-outline' : 'mdi-lock-open-variant-outline' : 'mdi-calendar-check-outline' }}
                    </v-icon>
                    <p
                        class="pl-2"
                        :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                    >
                        {{ isTask ? activeTask ? 'Aufgabe schließen' : 'Aufgabe öffnen' : 'Als Aufgabe markieren' }}
                    </p>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import papierkorbIcon from '@/assets/Icons/papierkorb-110.svg';
import penIcon from '@/assets/Editing/stift.svg';
import cancelIcon from "@/assets/Icons/abbrechen-08.svg";
import acceptIcon from '@/assets/Icons/fertig-74.svg';
import menuIcon from '@/assets/Icons/einstellungen-03.svg';
import img_eklaraLogo from '@/assets/EditorLibraryMock/eklara_logo.png';
import searchIcon from '@/assets/Icons/lupe-18.svg';
import pupilIcon from '@/assets/Icons/profile.svg';
import groupIcon from '@/assets/Icons/gruppen-25.svg';
import vueCustomScrollbar from "vue-custom-scrollbar";
import * as backend from "@/api/backend";

export default {
    name: 'FileSystemListElement',
    components: {
        vueCustomScrollbar,
    },
    props: {
        type: { required: true, type: String },
        index: { required: false, type: Number },
        screenX: { required: true, type: Number },
        screenY: { required: false, type: Number },
        item: { required: true, type: Object },
        folderType: { required: false, type: String },
        folderId: { required: false, type: String },
        debugMode: { required: false, type: Boolean, default: false },
        viewFileFunction: { required: false, type: Function, default: () => {} },
        vfs: { required: false, type: Object },
        indexLoadingPermission: { required: false, type: Array },
        notifyLoading: { required: false, type: Boolean },
    },
    data: () => ({
        isEditing: false,
        isMoving: false,
        editedNameValue: null,
        preview: null,
        previewPDF: false,
        thumbnailLoaded: false,
        openMenu: false,

        // groupFolder data
        possibleReceivers: [],
        searchTreeView: '',
        receiverAccounts: [],
        activeTask: false,
        isTask: false,
        alreadySubmitted: false,

        // Icons
        papierkorbIcon,
        penIcon,
        cancelIcon,
        acceptIcon,
        menuIcon,
        img_eklaraLogo,
        searchIcon,
        pupilIcon,
        groupIcon,
    }),
    computed: {
        ...mapGetters('auth', [ 'accountRole', 'accountId' ]),
        ...mapGetters('vfs', ['vfsGridWidthPct']),
    },
    watch: {
        folderId(val, oldVal) {
            // console.log('new folderId '+this.folderId);
            this.refreshReceivers();
        },
        notifyLoading: {
            handler() {
                if (!this.thumbnailLoaded) {
                    this.initThumbnail();
                }
            }
        }
    },
    mounted() {
        if (this.type === 'folder' && this.item.name) {
            this.editedNameValue = this.item.name;
        } else if (this.type === 'file' && this.item.uploadedName) {
            this.editedNameValue = this.item.uploadedName;
        }
        this.initThumbnail();
        this.refreshReceivers();
        
        if (this.folderType === 'groupFolder' && this.type === 'file') {
            if(!this.vfs) {
                return;
            }
            if (this.vfs.sharing_Tasks) {
                let foundTask = this.vfs.sharing_Tasks.find((el) => el.file == this.item._id);
                this.activeTask = foundTask ? foundTask.activeTask : false;
                this.isTask = foundTask ? true : false;
                let foundSubmission = false;
                if (foundTask) {
                    if (foundTask.submissions.length>0) {
                        foundSubmission = foundTask.submissions.find((sub) => sub.account == this.accountId);
                    }
                }
                this.alreadySubmitted = foundSubmission ? true : false;
            } else {
                this.activeTask = false;
                this.isTask = false;
                this.alreadySubmitted = false;
            }
        }
    },
    methods: {
        ...mapMutations('snackbar', [ 'showSnackbar' ]),
        ...mapActions('subjects', ['getThumbnail']),
        ...mapActions('parentalLetter', ['getParentalLetterList']),
        ...mapActions('teacherFolder', ['getVFSSharing']),

        async initThumbnail() {
            if(this.type === 'folder') {
                return;
            }
            if (this.indexLoadingPermission) {
                if (this.indexLoadingPermission[this.index] !== 'proceed') {
                    return;
                } else {
                    this.thumbnailLoaded = true;         
                }
            }
             // request thumbnail identified by fileId
            let thumb = null;
            if (this.item.itemType && this.item.itemType === 'Document') {
                const thumbnailResponse = await backend.getDocumentRegistryDocumentFile(
                    this.item.value,
                    this.item.thumbnail);
                thumb = await thumbnailResponse.blob();
            } else {
                thumb = await this.getThumbnail(this.item._id);
            }
            this.previewPDF = false;
            if(thumb) {
                this.preview = URL.createObjectURL(thumb);
                this.$emit('objectURL',this.preview);
                if(thumb.type === 'application/pdf') {
                    this.previewPDF = true;
                }
            } else {
                this.preview = img_eklaraLogo;
            }
            this.$emit('finishedLoading', this.index);
        },

        clickOutsideMenu(event) {
            // Check if event.path contains fileSystemElMenuButton
            if (event.path && !event.path.includes(this.$refs.fileSystemElMenuButton.$el)) {
                this.openMenu = false;
            }

            if (!event.path) {
                const path = [];
                let currentElem = this.target;
                while (currentElem) {
                    path.push(currentElem);
                    currentElem = currentElem.parentElement;
                }
                if (!path.includes(this.$refs.fileSystemElMenuButton.$el)) {
                    this.openMenu = false;
                }
            }
        },

        handleClickElement() {
            if (!(this.isEditing || this.item.isEditing)) {
                if(this.folderType === 'privateFolder') {
                    if(this.type === 'file') {
                        this.$emit('loadFullscreenPreview', this.item);
                        return;
                    }
                    this.$emit('openFolder');
                    return;
                } else {
                    if(this.type === 'file') {
                        this.$emit('loadFullscreenPreview', this.item);
                        return;
                    }
                    this.$emit('openFolder');
                    return;
                }
            }
        },
        handleDeleteButtonPress() {
            this.$emit('openDeleteDialog');
        },
        handleEditButtonPress() {
            // console.log('handleEditButtonPress');
            // initialize sharing_Accoungs
            if (this.accountRole === 'teacher') {
                this.getPossibleReceivers();
            }
            this.item.isEditing = true;
            this.editedNameValue = this.item.name || this.item.uploadedName;
            this.isEditing = true;
        },
        handleMoveButtonPress() {
            this.isMoving = true;
            this.$emit('startedEditing');
        },
        handleOpenSubmissionsButtonPress() {
            this.$emit('openSubmissions');
        },
        async setIsTask() {
            let body = {
                activeTask: !this.activeTask,
            };
            let vfs = await backend.updateVFSSetActiveTask(this.item._id, body);
            vfs = await vfs.json();
            this.activeTask = !this.activeTask;
            this.$emit('updateVFS', vfs);
        },
        cancelFolderEdit() {
            this.isEditing = false;
            this.isMoving = false;
            this.item.isEditing = false;
            this.item.isMoving = false;
            this.$emit('canceledEditing');
        },
        acceptFolderEdit() {
            const newFolder = !this.item._id;
            this.item.name = this.editedNameValue;
            this.isEditing = false;
            this.isMoving = false;
            this.item.isEditing = false;
            this.item.isMoving = false;
            this.item.receiverAccounts = this.receiverAccounts;
            this.$emit('folderEdited', newFolder);
        },
        getStyle() {
            let str = '';
            if (this.activeTask) {
                str = 'background-color: #f49f314d';
            }
            if (this.vfsGridWidthPct === 100) {
                return str+'justify-content: flex-start !important; flex-direction: row';
            }
            return str+'';
        },
        getThumbnailStyle() {
            let str = this.item.type === 'file' ?
                        this.vfsGridWidthPct < 50 ?
                            this.screenX < 900 ?
                                'pa-1 previewDiv33_SingleWidgetView mx-auto' : 'pa-1 previewDiv33 mx-auto'
                            : this.screenX < 900 ?
                                'pa-1 previewDiv50_SingleWidgetView mx-auto' : 'pa-1 previewDiv50 mx-auto'
                        : !(this.isEditing || this.item.isEditing || this.isMoving || this.item.isMoving || this.item.isCreating) ?
                            this.vfsGridWidthPct < 50 ?
                                this.screenX < 900 ?
                                    'previewFolderDiv33_SingleWidgetView' : 'previewFolderDiv33'
                                : this.screenX < 900 ?
                                    'previewFolderDiv50_SingleWidgetView' : 'previewFolderDiv50'
                            : '';
            if (this.activeTask) {
                str += '; background: #f49f314d !important';
            }
            return str;
        },
        getStyleList() {
            if (this.vfsGridWidthPct === 100) {
                return 'display: flex';
            }
            return '';
        },

        async getPossibleReceivers() {
            try {
                let addItemText = await this.getVFSSharing(this.folderId);
                for (let i = 0; i < addItemText.length; i++) {
                    // grab the name of the Group
                    addItemText[i].displayName = addItemText[i].name;
                    // visually preselect possible recievers with currently set receivers
                    // for every possible receiver account
                    for (let j = 0; j < addItemText[i].participants.length; j++) {
                        // for every currently Shared AccountId
                        if (this.item.sharing_Accounts) {
                            for (let k = 0; k < this.item.sharing_Accounts.length; k++) {
                                // add to selected Model if matching (user sees the menu with current setting)
                                if (this.item.sharing_Accounts[k] == addItemText[i].participants[j].account) {
                                    this.receiverAccounts.push(addItemText[i].participants[j]);
                                }
                            }
                        }
                    }
                }
                this.possibleReceivers = addItemText;
            } catch (e) {
                console.error(e);
            }
        },

        async refreshReceivers() {
            if (this.folderType === 'groupFolder' && (this.isEditing || this.item.isEditing || this.isMoving || this.item.isMoving || this.item.isCreating)) {
                this.getPossibleReceivers();
            }
        }
    }
}
</script>

<style scoped lang="scss">
.hoverTransform:hover {
  animation-name: transformOut;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-delay: 0.1s;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
}
//@keyframes transformOut {
//  0% {}
//  100% { transform: translateX(-380px) }
//}

.fileSystemElement {
    display: flex;
    // position: relative;
    border-radius: 4px;
    color: var(--v-dunkelgrau-base);
    box-shadow: 1px 2px 3px silver;
    width: 90%; white-space: nowrap;
    background-color: #FFF;
}

.greyBorder:hover {
  background-color: var(--v-dunkelgrau-base)1f !important;
}

.greyBorder {
    border-radius: 6px !important;
    border-width: 1px;
    border-color: #a5a5a5;
    border-style: solid;
    box-shadow: none !important;
}

.previewDiv50 {
  min-height:12vh;
  min-width:12vw;
  max-height: 12vh;
  max-width: 12vw;
//   background-color: white;
  cursor: pointer;
}

.previewDiv50_SingleWidgetView {
  min-height:14vh;
  min-width: 32vw;
  max-height: 14vh;
  max-width: 32vw;
//   background-color: white;
  cursor: pointer;
}

.previewDiv33 {
  min-height:10vh;
  min-width:8vw;
  max-height: 10vh;
  max-width: 8vw;
//   background-color: white;
  cursor: pointer;
}

.previewDiv33_SingleWidgetView {
  min-height: 12vh;
  min-width: 24vw;
  max-height: 12vh;
  max-width: 24vw;
//   background-color: white;
  cursor: pointer;
}

.previewFolderDiv50 {
  min-height:12vh;
//   min-width:22vw;
  max-height: 12vh;
//   max-width: 22vw;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.previewFolderDiv50_SingleWidgetView {
  min-height:14vh;
//   min-width:32vh;
  max-height: 14vh;
//   max-width: 32vh;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.previewFolderDiv33 {
  min-height:10vh;
//   min-width:15vh;
  max-height: 10vh;
//   max-width: 15vh;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.previewFolderDiv33_SingleWidgetView {
  min-height:12vh;
//   min-width:24vh;
  max-height: 12vh;
//   max-width: 24vh;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.iOS_ResponsiveHack {
    // i dont know how this works but ios devs recommend this to create responsive fit for embed pdfs
    width: 1px;
    min-width: 100%;
    *width: 100%;
}

.fileSystemElementName {
    cursor: pointer;
    width: 90%;
    display: inline-flex;
    align-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    //overflow: hidden;

    transform: translate3d(0,0,0);

}

.icon {
    height: 20px;
}

.iconToFemale {
    filter: brightness(0) saturate(100%) invert(40%) sepia(81%) saturate(2866%) hue-rotate(326deg) brightness(92%) contrast(97%);
}

.iconToMale {
    filter: brightness(0) saturate(100%) invert(36%) sepia(17%) saturate(1992%) hue-rotate(192deg) brightness(93%) contrast(80%);
}

.iconToDiverse {
    filter: brightness(0) saturate(100%) invert(15%) sepia(52%) saturate(5688%) hue-rotate(281deg) brightness(93%) contrast(89%);
}

.fileSystemElementNameCentered {
    cursor: pointer;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.fileWidgetButton {
    height: 30px !important;
    width: 30px !important;
    min-width: 30px !important;
}

.fileSystemElementOptionsMenuButton {
    display: inline-flex;
    justify-content: flex-start;
    padding-left: 5px !important;
    border-radius: 0;
}

.editorOptionsFade {
    opacity: 0;
    transition: 1s ease-out 3s;
}

.editorGreyDivFade {
    background-color: transparent;
    transition: 1s ease-out 3s;
}

.v-menu__content {
    position: fixed;
    overflow: visible;
    top: auto !important;
    left: auto !important;
    contain: initial;
    transform-origin: inherit !important;
    box-shadow: none !important;
}

.elementOuter:hover {
    & .editorOptionsFade {
        opacity: 1;
        transition: none;
    }
    & .editorGreyDivFade {
        background-color: var(--v-editorGrey-base);
        transition: none;
    }
}

</style>
