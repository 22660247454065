// Normal http(s) address of your backend
const getBackendAddress = () => {
  if(!process.env.VUE_APP_BACKEND_ADDRESS) {
    console.warn("VUE_APP_BACKEND_ADDRESS not defined. Have set up your .env?")
  }
  if(process.env.VUE_APP_BACKEND_ADDRESS === 'PRODUCTION_BACKEND_ADDRESS') {
    return `https://${window.location.host}/backend`
  }
  return process.env.VUE_APP_BACKEND_ADDRESS;
}

// Backend address for webSocket connects
const getBackendWebSocketAddress = () => {
  if(!process.env.VUE_APP_BACKEND_ADDRESS) {
    console.warn("VUE_APP_BACKEND_ADDRESS not defined. Have set up your .env?")
  }
  if(process.env.VUE_APP_BACKEND_ADDRESS === 'PRODUCTION_BACKEND_ADDRESS') {
    return `wss://${window.location.host}/backend/ws`
  }

  const backendAddress = new URL( process.env.VUE_APP_BACKEND_ADDRESS);
  backendAddress.protocol = 'ws';
  backendAddress.pathname = '/ws'
  return backendAddress;
}

export {
  getBackendAddress,
  getBackendWebSocketAddress,
}
