<template>
    <div>
        <v-row class="mx-0 mt-3 mb-4">
            <div style="height: auto; width: 90%; margin: auto; border-radius: 15px; box-shadow: 1px 5px 5px silver;">
                <div style=" background-color: #6995CD; border-radius: 15px 15px 0 0; font-size: x-large; padding-top: 20px; padding-bottom: 13px; text-align: center; color: white;">
                    <div style="display: flex; width: 50%">
                        <div
                            class="mt-1"
                            style="margin-left: 3%"
                        >
                            <p
                                class="ma-0"
                                style="display: inline-block;"
                            >
                                Angelegte Schulzeiten
                            </p>
                        </div>
                        <!--                        Angelegte Schulzeiten-->
                    </div>
                </div>
                <div
                    style="display: flex; font-size: larger; font-family: Roboto; margin-top: 24px; margin-left: 26px"
                    class=""
                >
                    <img
                        :src="schulStundenIcon"
                        style="height: 24px; margin-right: 10px"
                    >
                    Schulzeiten
                </div>
                <v-data-table
                    :headers="headers"
                    :items="slots"
                    :search="search"
                    sort-by="start"
                    disable-pagination
                    hide-default-footer
                    fixed-header
                    :height="height"
                    class="ma-3"
                    locale="de-DE"
                    no-data-text="Keine Daten verfügbar"
                    no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{ ("0" + new Date(row.item.start).getHours()).slice(-2) + ":" + ("0" + new Date(row.item.start).getMinutes()).slice(-2) }}</td>
                            <td>{{ ("0" + new Date(row.item.end).getHours()).slice(-2) + ":" + ("0" + new Date(row.item.end).getMinutes()).slice(-2) }}</td>
                            <td>
                                <v-btn
                                    class="mx-2"
                                    color="#EAEAEA"
                                    style="border-radius: 5px; text-transform: inherit"
                                    elevation="1"
                                    small
                                    light
                                    @click="edit(row.item)"
                                >
                                    Slot bearbeiten
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <v-overlay
                    absolute
                    :value="showLoadingOverlay"
                    style="width: 100% !important;"
                >
                    <v-progress-circular
                        v-if="showLoadingOverlay"
                        indeterminate
                        size="100"
                    />
                </v-overlay>
            </div>
        </v-row>

        <!-- Zeitslots anlegen / bearbeiten -->
        <v-row
            id="edit"
            class="mx-0 my-3"
        >
            <div
                class="editContainer"
                style=""
            >
                <v-row
                    class="mx-0"
                    style="background-color: #6995CD; border-radius: 15px 15px 0 0"
                >
                    <v-col
                        style="color: white; font-size: larger;"
                        class="ma-1 ml-5"
                    >
                        <v-avatar
                            style="width: 24px; "
                            color="rgba(255,255,255,0.8)"
                        >
                            <img
                                :src="schulStundenIcon"
                                class=""
                            >
                        </v-avatar>

                        Schulzeiten {{ oldSlot ? 'bearbeiten' : 'anlegen' }}
                    </v-col>
                    <v-col
                        class="mr-6"
                        style="display: flex; justify-content: flex-end;"
                    >
                        <v-btn
                            color="#FFFFFF40"
                            x-small
                            class="mainFunctionButtons ml-3 my-auto"
                            :disabled="!oldSlot"
                            @click="deleteDialog = true"
                        >
                            <img :src="loeschIcon">
                        </v-btn>
                        <v-tooltip
                            top
                            nudge-right="100"
                            :open-on-click="true"
                            :open-on-hover="false"
                            content-class="infoTextToolTip"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="#FFFFFF40"
                                    x-small
                                    class="mainFunctionButtons ml-3 my-auto"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <img :src="infoIcon">
                                </v-btn>
                            </template>
                            <span>Hier können Sie Schulstunden für Ihre Schule anlegen. Geben Sie der Stunde einen
                                Namen und legen Sie fest von wann bis wann die Stunde gehen soll. Die Stunde wird
                                in allen Stundenplänen als festgelegte Uhrzeit angezeigt.</span>
                        </v-tooltip>
                        <v-btn
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#FFFFFF40"
                            dark
                            elevation="0"
                            @click="stopEdit"
                        >
                            <span>Abbrechen</span>
                        </v-btn>
                        <v-btn
                            v-if="oldSlot"
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#69CD70"
                            dark
                            elevation="4"
                            @click="editThisSlot"
                        >
                            <span>Speichern</span>
                        </v-btn>
                        <v-btn
                            v-else
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#69CD70"
                            dark
                            elevation="4"
                            @click="saveSlot"
                        >
                            <span>Speichern</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="mx-2 mb-2">
                    <v-row>
                        <v-col
                            class="mx-5"
                            md=""
                        >
                            <v-row>
                                <v-col cols="2">
                                    Von:
                                </v-col>
                                <v-col cols="4">
                                    <input
                                        v-model="from"
                                        style="margin-left: -12px"
                                        type="time"
                                        class="input"
                                        :class="{ errorInput: fields.from }"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="mx-5">
                            <v-row>
                                <v-col cols="2">
                                    Bis:
                                </v-col>
                                <v-col cols="4">
                                    <input
                                        v-model="till"
                                        type="time"
                                        class="input"
                                        :class="{ errorInput: fields.till }"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-row>

        <!--        Delete dialog-->
        <v-dialog
            v-model="deleteDialog"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    Zeitslot löschen?
                </v-card-title>
                <v-card-text>
                    Sind Sie sicher, dass Sie den Zeitslot löschen möchten?
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color=""
                        text
                        @click="deleteDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="red red-1"
                        text
                        @click="() => {deleteThisSlot(); deleteDialog = false}"
                    >
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
    import infoIcon from "../../assets/Icons/ausrufezeichen-62.svg";
    import schulStundenIcon from "../../assets/Icons/uhr-04.svg";
    import loeschIcon from '../../assets/Icons/papierkorb-verwaltung-109.svg';

    export default {
        name: "Zeitslots",
        components: {

        },
        props: {

        },
        data() {
            return {
                showLoadingOverlay: false,

                from: '',
                till: '',

                fields: {
                    from: false,
                    till: false,
                },

                deleteDialog: false,
                oldSlot: null,
                height: 0,
                search: '',
                headers: [
                    { text: 'Von', value: 'start' },
                    { text: 'Bis', value: 'end' },
                    { text: '', sortable: false},
                ],

                slots: [
                ],

                infoIcon,
                schulStundenIcon,
                loeschIcon,
            };
        },
        computed: {
            ...mapGetters("timeslots", ['getTimeslotsInvalid']),
        },
        watch: {
            getTimeslotsInvalid(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.requestSlots();
                }
            },
        },
        async created() {
            this.height = window.innerHeight * 0.4
        },
        async mounted() {
            this.showLoadingOverlay = true;
            this.requestSlots();
        },
        methods: {
            ...mapActions("timeslots", ["getSlots", "createSlot", "editSlot", "deleteSlot", 'setTimeslotsStatus']),
            ...mapMutations("snackbar", ["showSnackbar"]),

            async requestSlots() {
                this.showLoadingOverlay = true;
                this.slots = await this.getSlots();
                this.showLoadingOverlay = false;
                this.setTimeslotsStatus(false);
            },
            async edit(group) {
                this.$vuetify.goTo('#edit');
                this.oldSlot = group;
                this.from = ("0" + new Date(group.start).getHours()).slice(-2) + ":" + ("0" + new Date(group.start).getMinutes()).slice(-2);
                this.till = ("0" + new Date(group.end).getHours()).slice(-2) + ":" + ("0" + new Date(group.end).getMinutes()).slice(-2);
            },
            stopEdit() {
                this.from = '';
                this.till = '';
                this.oldSlot = null;

                this.fields.from = false;
                this.fields.till = false;
            },
             async deleteThisSlot() {
                try {
                    this.showLoadingOverlay = true;
                    let response = await this.deleteSlot(this.oldSlot._id);
                    if (response) {
                        const index = this.slots.indexOf(this.oldSlot);
                        if (index > -1) {
                            this.slots.splice(index, 1);
                        }
                        this.setTimeslotsStatus(true);
                        this.stopEdit();
                        this.showSnackbar({ message: 'Gespeichert!'});
                    } else {
                        this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    console.error(e);
                    this.showLoadingOverlay = false;
                }
            },
            validateInput() {
                let result = true;

                this.fields.from = false;
                this.fields.till = false;

                if (!this.from) {
                    this.fields.from = true;
                    result = false;
                }

                if (!this.till) {
                    this.fields.till = true;
                    result = false;
                }

                return result;
            },

            async editThisSlot() {
                try {
                    this.showLoadingOverlay = true;
                    if (!this.validateInput()) {
                        this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                        this.showLoadingOverlay = false;
                        return;
                    }

                    // Use new Date(0) instead of new Date()
                    // because new Date() initializes with date of creation
                    // When one timeslot is created in summertime (CEST; Central European Summertime; UTC+02:00)
                    // and another timeslot is created in wintertime (CET; Central European Time; UTC+01:00)
                    // then its sorting can get messed up.
                    // Because 10:30 am CEST is before 10:00 am CET when converting the times to UTC
                    // And on server the times are sorted by UTC and not local time
                    // Note: This is only relevant because school starts e.g. 9am independently of summer or wintertime
                    let fromDate = new Date(0);
                    fromDate.setHours(Number(this.from.split(':')[0]));
                    fromDate.setMinutes(Number(this.from.split(':')[1]));
                    fromDate.setSeconds(0);

                    let tillDate = new Date(0);
                    tillDate.setHours(Number(this.till.split(':')[0]));
                    tillDate.setMinutes(Number(this.till.split(':')[1]));
                    tillDate.setSeconds(0);
                    let data = {
                        _id: this.oldSlot._id,
                        start: fromDate,
                        end: tillDate,
                    };

                    let response = await this.editSlot(data);
                    if (Number.isInteger(response)) {
                        this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                    } else {
                        const index = this.slots.indexOf(this.oldSlot);
                        if (index > -1) {
                            this.slots.splice(index, 1);
                        }
                        this.setTimeslotsStatus(true);
                        await this.requestSlots();
                        this.stopEdit();
                        this.showSnackbar({ message: 'Gespeichert!'});
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    console.error(e);
                    this.showLoadingOverlay = false;
                }
            },
            async saveSlot() {
                try {
                    this.showLoadingOverlay = true;
                    if (!this.validateInput()) {
                        this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                        return;
                    }

                    // Use new Date(0) instead of new Date()
                    // because new Date() initializes with date of creation
                    // When one timeslot is created in summertime (CEST; Central European Summertime; UTC+02:00)
                    // and another timeslot is created in wintertime (CET; Central European Time; UTC+01:00)
                    // then its sorting can get messed up.
                    // Because 10:30 am CEST is before 10:00 am CET when converting the times to UTC
                    // And on server the times are sorted by UTC and not local time
                    // Note: This is only relevant because school starts e.g. 9am independently of summer or wintertime
                    let fromDate = new Date();
                    fromDate.setHours(Number(this.from.split(':')[0]));
                    fromDate.setMinutes(Number(this.from.split(':')[1]));
                    fromDate.setSeconds(0);

                    let tillDate = new Date();
                    tillDate.setHours(Number(this.till.split(':')[0]));
                    tillDate.setMinutes(Number(this.till.split(':')[1]));
                    tillDate.setSeconds(0);
                    let data = {
                        start: fromDate,
                        end: tillDate,
                    };

                    let response = await this.createSlot(data);
                    if (Number.isInteger(response)) {
                        this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                    } else {
                        this.setTimeslotsStatus(true);
                        await this.requestSlots();
                        this.stopEdit();
                        this.showSnackbar({ message: 'Gespeichert!'});
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    console.error(e);
                    this.showLoadingOverlay = false;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr {
        height: 40px;
    }

    table tr th:first-child,
    table tr td:first-child {
        width: 10px !important;
    }

    table tr th,
    table tr td {
        width: 9em;
        border: none;
    }
    table, tr, td {
        border: none;
    }

    input[type="date"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-height: 1.6rem;
    }

    .input{
        display: block;
        width: 100%;
        max-width: 100%;
        border-radius: 5px;
        border-width:0px;color: black;
        padding: 1px 5px 1px 5px;
        border: none !important;
        background-color: var(--v-hellgrau-base);
        overflow: hidden;
        appearance:none
    }

    .input[disabled]{
        color: grey;
        background-color: #CACACA;
    }

    .editContainer {
        min-height: 40vh;
        height: fit-content;
        width: 90%;
        border-radius: 15px;
        box-shadow: 1px 5px 5px silver;
        margin: auto;
        margin-bottom: 5em
    }

    .mainControlButtons {
        font-weight: 300;
        font-size: 10px;
        border-radius: 8px;

        & span {
            line-height: 0 !important;
        }
    }

    .mainFunctionButtons {
        width: 38px !important;
        height: 38px !important;
        border-radius: 8px;

        img {
            width: 27px !important;
            height: 27px !important;
            filter: brightness(1000%);
        }
    }

    .errorInput {
        border: 2px solid red !important;
    }

    .infoTextToolTip {
        max-width: 250px;
        background-color: var(--v-blau-base);
    }
</style>
