<template>
    <v-btn
        v-if="accountRole === 'teacher'"
        icon
        class="pointer d-flex justify-center"
        @click="$refs.attachmentInputChat.click()"
    >
        <img
            :src="paperClipIcon"
            class="bigIcon"
            alt="Anhang einfügen"
        >
        <input
            ref="attachmentInputChat"
            class="visually-hidden"
            type="file"
            accept="image/*"
            @change="sendImageMessage"
        >
    </v-btn>
</template>
<script>
import * as backend from "../../../api/backend";
import { mapGetters, mapMutations } from "vuex";
import paperClipIcon from "../../../assets/Icons/Bueroklammer-94.svg";

export default {
  name: "ChatRoomImageUploadButton",
  props: {
      chatRoom: { required: true, type: Object},
  },
  data: () => ({
    paperClipIcon,
  }),
  computed: {
    ...mapGetters("auth", ["accountRole", "accountId"]),
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),

    async sendImageMessage() {
      const files = this.$refs.attachmentInputChat.files;
      console.log(this.$refs.attachmentInputChat.files);

      for (let i = 0; i < files.length; i++) {
        const createdChatRoomEventResponse = await backend.postChatRoomEvent({
          type: "imageMessage",
          chatRoom: this.chatRoom._id,
        });
        const createdChatRoomEvent = await createdChatRoomEventResponse.json();

        const formData = new FormData();
        formData.append("file", files[i], files[i].name);

        const fileXmlHttpRequest = await backend.postChatRoomEventFile(
          createdChatRoomEvent._id,
          files
        );

        fileXmlHttpRequest.onerror = (e) => {
          console.error("attachment upload error:", e);
          // this.showAttachmentUploadProgress = false;
          this.$refs.attachmentInputChat.value = "";
        };
        fileXmlHttpRequest.onabort = () => {
          console.warn("attachment upload aborted");
          // this.showAttachmentUploadProgress = false;
          this.$refs.attachmentInputChat.value = "";
        };
        fileXmlHttpRequest.addEventListener("load", () => {
          if (
            createdChatRoomEventResponse.status === 201 &&
            fileXmlHttpRequest.status === 201
          ) {
            this.showSnackbar({ message: "Bild versendet." });
          } else if (fileXmlHttpRequest.status !== 201) {
            this.showSnackbar({
              message: "Beim Hochladen des Bildes ist ein Fehler aufgetreten.",
              color: "error",
            });
          }
          // this.showAttachmentUploadProgress = false;
          this.$refs.attachmentInputChat.value = "";
        });
        fileXmlHttpRequest.upload.addEventListener("progress", (e) => {
          // this.attachmentUploadProgress = (e.loaded / e.total) * 100;
        });
        // this.attachmentUploadProgress = 0.0;
        // this.showAttachmentUploadProgress = true;
        fileXmlHttpRequest.send(formData);
      }
    },
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}

.bigIcon {
  height: 30px;
}
</style>