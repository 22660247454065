<template>
    <div>
        <v-row class="mx-0">
            <div class="whiteBg widgetContainer">
                <!--Header of Chat-->
                <div
                    style="background-color: #8cbd46; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; text-align: left; color: white;"
                    @click="readWidgetInfoText()"
                >
                    <v-row
                        style="max-width: 100%; display: flex; align-items: center; height: 35px"
                        class="mx-2"
                    >
                        <v-col
                            cols="9"
                            md="8"
                            lg="9"
                            class="pa-0 pl-2 text-left d-flex align-center"
                            style="font-size: larger"
                        >
                            <img
                                :src="chatIcon"
                                alt
                                class="iconToWhite mr-1"
                                style="height: 20px"
                            >
                            <h1
                                id="chatHeading"
                                tabindex="0"
                                class="mb-0"
                            >
                                Chat
                            </h1>
                        </v-col>
                        <v-col
                            cols="3"
                            md="4"
                            lg="3"
                            class="pa-0 pr-2 d-flex align-center justify-end"
                        >
                            <img
                                :src="infoIcon"
                                alt
                                style="height: 15px;"
                            >
                        </v-col>
                    </v-row>
                </div>
                <ChatWidgetGroupList
                    v-if="!viewingRoom"
                    :current-child="currentChild"
                    :rooms="rooms"
                    @handleLockStatusChanged="handleLockStatusChanged"
                    @roomSelected="handleRoomSelected"
                    @setRoomList="setRoomList"
                    @updateRoomList="updateRoomList"
                />
                <ChatWidgetGroupChat
                    v-else
                    :current-child="currentChild"
                    :room="viewingRoom"
                    :matrix-client="matrixClient"
                    :show-tooltips="showTooltips"
                    @exitRoom="handleExitRoom"
                    @sendMessage="sendMessage"
                    @deleteMessage="deleteMessage"
                    @updateRoomList="updateRoomList"
                />
            </div>
        </v-row>
    </div>
</template>

<script>
import ChatWidgetGroupList from "./ChatWidgetGroupList";
import ChatWidgetGroupChat from "./ChatWidgetGroupChat";
import { getMatrixAddress } from "../../util/getMatrixAddress";
import * as BackendApi from "../../api/backend";
import * as sdk from "matrix-js-sdk";
import loglevel from "loglevel";
import { bus } from "@/main";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

import chatIcon from "../../assets/Icons/chat-alternative-filled-102.svg";
import infoIcon from "@/assets/Icons/info-2-weiß-89.svg";

export default {
    name: "ChatWidget",
    components: { ChatWidgetGroupChat, ChatWidgetGroupList },
    props: {
        showTooltips: { required: false, default: true },
        currentChild: { required: false, type: Object },
    },
    data: () => ({
        chatIcon,
        infoIcon,

        matrixRooms: [],
        rooms: [],          // they are being requested in setRoomList
        matrixClient: null,
        viewingRoom: null,
        groups: [],
        person: {
            accessibility: {
                screenreader: false,
            }
        },
    }),
    computed: {
        ...mapState('translation', ['targetLang',]),
        ...mapGetters('auth', ['accountRole']),
    },
    async mounted() {
        this.prepareClient();
        this.requestMatrixGroups();
        if (this.accountRole === 'maintainer') {
            this.groups = await this.getGroupsInfo();
        }
        this.requestPerson();

        bus.$off('sendPollMessage');
        // listens to emitted event via bus (bus is created in main.js)
        bus.$on('sendPollMessage', (pollId) => { this.sendPollMessage(pollId) });
    },
    async beforeDestroy() {
        if (this.matrixClient) {
            // Stop client to end requests
            await this.matrixClient.stopClient();
            // Remove references to make chat widget component available for garbage collection
            this.matrixClient.on("sync", () => {});
            this.matrixClient.on("Room", () => {});
            this.matrixClient.on("Room.timeline", () => {});
            this.matrixClient = null;
        }
    },
    methods: {
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        ...mapActions('groups', ['getGroupsInfo']),
        ...mapActions('matrix', ['getMatrixRoomLockStatus', 'getMatrixRoomLockStatusById']),
        ...mapActions('pupils', ['getMePupil']),
        ...mapMutations('snackbar', ['showSnackbar']),

        async requestPerson() {
            if (this.accountRole === 'pupil') {
                this.person = await this.getMePupil();
            }
        },

        async requestMatrixGroups() {
            this.matrixRooms = await this.getMatrixRoomLockStatus();
        },

        async updateRoomList() {
            await this.requestMatrixGroups();
            this.setRoomList();
        },

        async prepareClient() {
            const matrixUserResponse = await BackendApi.getCurrentMatrixUser();
            const matrixUser = await matrixUserResponse.json();

            // Transform name like @alice-1603048221873-estundenplan-localhost-simon:estundenplan-synapse-development
            // to alice-1603048221873-estundenplan-localhost-simon
            const loginName = matrixUser.name.slice(1).split(":")[0];

            const matrixServerUrl = getMatrixAddress();
            const loginDataResponse = await fetch(
                `${matrixServerUrl}/_matrix/client/r0/login`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        type: "m.login.password",
                        user: loginName,
                        password: matrixUser.password,
                    }),
                }
            );

            const loginData = await loginDataResponse.json();

            // Log only higher priority messages of matrix in order to avoid log spam
            const logger = loglevel.getLogger("matrix");
            logger.setLevel(loglevel.levels.INFO);

            this.matrixClient = sdk.createClient({
                baseUrl: matrixServerUrl,
                accessToken: loginData.access_token,
                userId: loginData.user_id,
            });

            this.matrixClient.on("sync", (...params) => { this.handleMatrixClientOnSync(...params); });
            // this.matrixClient.on("Room", (...params) => { this.handleMatrixClientOnRoom(...params); });
            this.matrixClient.on("Room.timeline", (...params) => { this.handleMatrixClientOnRoomTimeline(...params) });

            this.matrixClient.startClient();
        },
        async sendMessage(message) {
            if (this.accountRole === 'pupil') {
                const res = await this.getMatrixRoomLockStatusById(this.viewingRoom.roomId);
                if (res.status === 401) {
                    this.showSnackbar({ message: "Der Chat ist momentan gesperrt.", color: "error" });
                    return;
                }
            }
            const content = {
                body: message,
                msgtype: "m.text",
            };
            this.matrixClient.sendEvent(
                this.viewingRoom.roomId,
                "m.room.message",
                content,
                "",
                (err, res) => {
                    if (err) {
                        console.error(err);
                    }
                    this.prepareClient();
                }
            );
        },

        async sendPollMessage(poll) {
            let message = '$pollId:' + poll._id;
            const content = {
                body: message,
                msgtype: "m.text",
            };
            this.matrixClient.sendEvent(
                this.viewingRoom.roomId,
                "m.room.message",
                content,
                "",
                (err, res) => {
                    console.error(err);
                    this.prepareClient();
                }
            );
        },

        async deleteMessage(message) {
            if (this.accountRole === 'pupil') {
                const res = await this.getMatrixRoomLockStatusById(this.viewingRoom.roomId);
                if (res.status === 401) {
                    this.showSnackbar({ message: "Der Chat ist momentan gesperrt.", color: "error" });
                    return;
                }
            }
            this.matrixClient.redactEvent(
                this.viewingRoom.roomId,
                message.event.event_id,
                message._txnId,
                (err, res) => {
                    console.error(err);
                }
            );
        },

        async handleExitRoom() {
            await this.matrixClient.sendReceipt(
                this.viewingRoom.timeline[this.viewingRoom.timeline.length - 1],
                "m.read"
            );
            this.viewingRoom = null;
            await this.$nextTick();
            await this.$nextTick();
            document.getElementById("gruppenHeading").focus();
        },

        async changeRoomStatus(room, isOpen) {
            if (room.isOpen === isOpen) {
                return;
            }
            this.rooms = this.rooms.map((el) => {
                if (el.roomId === room.roomId) {
                    el.isOpen = isOpen;
                }
                return el;
            });
        },

        async handleRoomSelected(room) {
            if (this.accountRole === 'pupil') {
                const res = await this.getMatrixRoomLockStatusById(room.roomId);
                if (res.status === 401) {
                    this.changeRoomStatus(room, false);
                    this.showSnackbar({ message: "Der Chat ist momentan gesperrt.", color: "error" });
                    return;
                }
            }
            if (this.accountRole === 'pupil') {
                this.changeRoomStatus(room, true);
            }
            await this.matrixClient.joinRoom(room.roomId);
            await this.matrixClient.sendReceipt(
                room.timeline[room.timeline.length - 1],
                "m.read"
            );
            this.viewingRoom = room;
            await this.$nextTick();
            document.getElementById("backButton").focus();
        },

        handleMatrixClientOnSync(state, prevState, data) {
            switch (state) {
                case "PREPARED":
                    this.setRoomList();
                    break;
            }
        },
        handleMatrixClientOnRoom() {
            this.setRoomList();
            if (!this.viewingRoom) {
                // printRoomList();
                // rl.prompt();
            }
        },
        handleMatrixClientOnRoomTimeline(event, room, toStartOfTimeline) {
            if (toStartOfTimeline) {
                return; // don't print paginated results
            }
            if (!this.viewingRoom || this.viewingRoom.roomId !== room.roomId) {
                return; // not viewing a room or viewing the wrong room.
            }
        },
        handleLockStatusChanged(isOpen, roomId) {
            this.rooms = this.rooms.map((room) => {
                if (room.roomId === roomId) {
                    room.isOpen = isOpen;
                }
                return room;
            });
        },
        setRoomList() {
            const roomList = this.matrixClient.getRooms().filter((room) => room._selfMembership === "join");
            roomList.sort(function(a, b) {
                // < 0 = a comes first (lower index) - we want high indexes = newer
                var aMsg = a.timeline[a.timeline.length - 1];
                if (!aMsg) {
                    return -1;
                }
                var bMsg = b.timeline[b.timeline.length - 1];
                if (!bMsg) {
                    return 1;
                }
                if (aMsg.getTs() > bMsg.getTs()) {
                    return -1;
                } else if (aMsg.getTs() < bMsg.getTs()) {
                    return 1;
                }
                return 0;
            });
            this.rooms = roomList;
            // adds property isOpen from backend to matrixRoom
            this.rooms = this.rooms.map((room) => {
                const matchingRooms = this.matrixRooms.filter((matrixRoom) => {
                    return room.roomId === matrixRoom.roomId;
                })
                if (matchingRooms.length > 0) {
                    room._id = matchingRooms[0]._id;
                    room.isOpen = matchingRooms[0].isOpen;
                } else {
                    //defaulting to open room if no match is found in backend, this should not happen
                    room.isOpen = true;
                }
                return room;
            });
        },

        async readWidgetInfoText() {
            if (!this.person.accessibility.screenreader) {
                if ('speechSynthesis' in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis Not Supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                    return
                }

                let text;
                if (this.accountRole === 'pupil') {
                    text = 'Im Chat kannst du Kontakt zu deinen Lehrern aufnehmen.';
                } else if (this.accountRole === 'teacher') {
                    text = 'Im Chat können Sie Kontakt zu Schülern und Eltern aufnehmen.';
                } else if (this.accountRole === 'parent') {
                    text = 'Im Chat können Sie zu zuständigen Lehrer Ihres Kindes Kontakt aufnehmen.';
                } else {
                    text = 'Im Chat können Sie sich mit Ihren Kontakten austauschen.'
                }

                if (this.targetLang !== 'de') {
                    text = await this.translateToTargetLang({
                        targetLang: this.targetLang, textToTranslate: text
                    });
                }

                if (window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel();
                    this.showTranslation(false);
                } else {
                    this.setTranslatedText(text);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)

                    let msg = new SpeechSynthesisUtterance();
                    msg.text = text;
                    if (this.isLangPackageAvailable()) {
                        msg.lang = this.targetLang;
                        window.speechSynthesis.speak(msg);
                    }
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
    }
}
</script>

<style lang="scss" scoped>
.widgetContainer {
    height: auto;
    width: 100%;
    max-width: 30vw;
    min-height: 80vh;
    max-height: 80vh;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto auto 5em;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.chat-header {
    background-color: #3baa69;
    border-radius: 15px 15px 0 0;
    font-size: larger;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: left;
    color: white;
}

.chat-header-btn {
    background-color: #f8f8f880 !important;
    width: 35px;
    height: 35px !important;
}

.chat-header-img {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
    position: relative;
    max-height: calc(80vh - 75px);
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.whiteBg {
    background-color: #ffffff;
}

//make text not selectable
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

h1 {
    display: inherit;
    font-size: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    margin-right: inherit;
    font-weight: inherit;
}

@media only screen and (max-width: 900px) {
    .widgetContainer {
        width: 90%;
        max-width: none;
    }
}
</style>
