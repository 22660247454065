<template>
    <div style="position: relative">
        <progress-bar
            title="Hochladen"
            :show-progress="showPrivateUploadProgress"
            :progress="privateUploadProgress"
            :abort-progress="privateXmlHttpRequest ? () => { privateXmlHttpRequest.abort() } : () => { }"
        />

        <progress-bar
            title="Hochladen"
            :show-progress="showSubjectUploadProgress"
            :progress="subjectUploadProgress"
            :abort-progress="subjectXmlHttpRequest ? () => { subjectXmlHttpRequest.abort() } : () => { }"
        />
        <v-row
            class="mx-0"
            style="width: 100%;"
        >
            <div
                class="whiteBg widgetContainer"
                style="position: relative"
            >
                <!--Header of Dateien-->
                <div
                    style="background-color: #F49F31; border-radius: 15px 15px 0 0; color: white; min-height: 30px;"
                >
                    <v-row
                        style="max-width: 100%; height: 55px"
                        class="mx-2 mb-1"
                        justify="center"
                        align="center"
                        dense
                    >
                        <v-col
                            cols="auto"
                            class="pa-0 pl-2 d-flex align-center"
                            style="font-size: larger;"
                        >
                            <img
                                v-if="!fach && !day && !month && !privateFolder && !teacherFolder && !docRegistry && !groupFolder"
                                :src="fileIcon"
                                class="iconToWhite mr-1"
                                style="height: 20px;"
                                alt
                            >
                            <v-btn
                                v-else
                                aria-label="Zurück"
                                tabindex="0"
                                :size="20"
                                class="optionBtn mr-1"
                                x-small
                                color="rgba(248, 248, 248, 0.4)"
                                @click="handleBackButton"
                            >
                                <img
                                    :src="leftIcon"
                                    class="icon iconToWhite"
                                    alt="zurück"
                                >
                            </v-btn>
                            <h1
                                v-if="!privateFolder && !teacherFolder && !docRegistry && !groupFolder"
                                id="dateienHeading"
                                tabindex="0"
                                class="mb-0"
                                @click="readWidgetInfoText()"
                            >
                                {{
                                    teacherFolder ? 'Geteilt'
                                    : (privateFolder ? 'Privat'
                                        : (docRegistry ? 'Online-Bibliothek' : 'Dateien'))
                                }}
                            </h1>
                        </v-col>
                        <v-col>
                            <v-slide-x-transition>
                                <!--
                                For some reason the clearable prop is not properly working on the textfield.
                                It sets the string to null instead of '' and the custom callback with @click:clear gets overridden -.-
                                -->
                                <v-text-field
                                    v-if="!privateFolder && !teacherFolder && !groupFolder"
                                    v-model="searchString"
                                    dark
                                    hide-details
                                    prepend-inner-icon="mdi-magnify"
                                    :append-icon="searchString.trim().length > 0 ? 'mdi-close' : ''"
                                    :placeholder="privateFolder ? 'Im privaten Ordner...'
                                        : teacherFolder ? 'Im Lehrer Ordner...'
                                            : groupFolder ? 'Im Gruppen Ordner...'
                                                : docRegistry ? 'In Online Bibliothek...'
                                                    : fach && subjectFolder ? 'In ' + fach[0].schoolSubject.name + ' allgemein...'
                                                        : fach ? 'In ' + fach[0].schoolSubject.name + '...' : 'In Fächern...'"
                                    :class="(windowWidth > 1000 ? 'mr-6 ml-4' : 'mx-2') + ' pt-0 mt-0'"
                                    aria-hidden="true"
                                    @input="week = null; month = null; day = null;"
                                    @click:append="searchString = ''"
                                />
                                <v-text-field
                                    v-else-if="true"
                                    v-model="searchString"
                                    dark
                                    hide-details
                                    prepend-inner-icon="mdi-magnify"
                                    :append-icon="searchString.trim().length > 0 ? 'mdi-close' : ''"
                                    :placeholder="privateFolder ? 'Im privaten Ordner...'
                                        : teacherFolder ? 'Im Lehrer Ordner...'
                                            :groupFolder ? 'Im Gruppen Ordner...'
                                                : fach && subjectFolder ? 'In ' + fach[0].schoolSubject.name + ' allgemein...'
                                                    : fach ? 'In ' + fach[0].schoolSubject.name + '...' : 'In Fächern...'"
                                    :class="(windowWidth > 1000 ? 'mr-6 ml-4' : 'mx-2') + ' pt-0 mt-0'"
                                    aria-hidden="true"
                                    @keyup.enter="if (searchString !== '') { executeSearch() }"
                                    @click:append="searchString = ''"
                                />
                            </v-slide-x-transition>
                        </v-col>
                        <!--                        <v-col @click="readWidgetInfoText()" cols="auto"-->
                        <!--                               class="pa-0 pr-2 d-flex align-center justify-end">-->
                        <!--                            <img :src="infoRoundIcon" style="height: 15px;">-->
                        <!--                        </v-col>-->
                        <v-col
                            v-if="privateFolder || teacherFolder || groupFolder"
                            cols="auto"
                            class="pa-0 pr-2 d-flex align-center justify-end"
                        >
                            <!-- Buttons for + Menu and leave to main widget screen -->
                            <!-- Leave to main widget screen -->
                            <v-btn
                                class="optionBtn ml-2"
                                x-small
                                color="rgba(248, 248, 248, 0.4)"
                                @click="() => { privateFolder = false; teacherFolder = false; groupFolder = false; }"
                            >
                                <img
                                    :src="schliessenIcon"
                                    class="pa-1"
                                    style="height: 30px; filter: brightness(1000%)"
                                >
                            </v-btn>

                            <FileSystemPlusMenu
                                :folder-type="`${privateFolder ? 'privateFolder' : teacherFolder ? 'teacherFolder' : groupFolder ? 'groupFolder' : ''}`"
                                :start-folder-creation="handleNewFolder"
                                :upload-local-file-function="handleUploadButtonClick"
                                :upload-file-from-shared-function="() => { fileWidgetUploadDialog = true; copyDestination = 'private'; }"
                                :upload-file-from-private-function="() => { fileWidgetUploadDialog = true; copyDestination = 'teacher'; }"
                            />
                        </v-col>
                    </v-row>
                </div>
                <div
                    v-if="!privateFolder && !teacherFolder && !docRegistry && !groupFolder"
                    class="pa-3 my-1 mx-auto d-flex"
                    :style="'position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'"
                    @click="privateFolder = true; folderDepth = 1;"
                >
                    <!-- <img :src="schlossIcon" class="iconSelect" style="background-color: #F49F31"> -->
                    <v-icon>mdi-folder</v-icon>
                    <div
                        class="pl-1"
                        style="height: 24px"
                    >
                        <p
                            tabindex="0"
                            class="mb-0"
                            style="width: fit-content;"
                            @keypress.enter="privateFolder = true; folderDepth = 1;"
                        >
                            Mein privater Ordner
                        </p>
                    </div>
                </div>
                <div
                    v-if="!privateFolder && !teacherFolder && !docRegistry && !groupFolder && (accountRole === 'teacher' || accountRole === 'pupil')"
                    class="pa-3 my-1 mx-auto d-flex"
                    :style="'position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'"
                    @click="groupFolder = true; folderDepth = 1;"
                >
                    <v-icon>mdi-folder-account</v-icon>
                    <div
                        class="pl-1"
                        style="height: 24px"
                    >
                        <p
                            tabindex="0"
                            class="mb-0"
                            style="width: fit-content; height: fit-content;"
                        >
                            Meine Gruppenordner
                        </p>
                    </div>
                </div>
                <div
                    v-if="!privateFolder && !teacherFolder && !docRegistry & !groupFolder && accountRole === 'teacher'"
                    class="pa-3 my-1 mx-auto d-flex"
                    :style="'position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'"
                    @click="teacherFolder = true; folderDepth = 1;"
                    @keypress.enter="teacherFolder = true; folderDepth = 1;"
                >
                    <!-- <img
                        :src="schlossIcon"
                        class="iconSelect"
                        style="background-color: #F49F31"
                    > -->
                    <v-icon>mdi-folder-account</v-icon>
                    <div
                        class="pl-1"
                        style="height: 24px"
                    >
                        <p
                            tabindex="0"
                            class="mb-0"
                            style="width: fit-content; height: fit-content;"
                        >
                            Lehrer Ordner
                        </p>
                    </div>
                </div>
                <div
                    v-if="!privateFolder && !teacherFolder && !docRegistry && !groupFolder && accountRole === 'teacher'"
                    class="pa-3 my-1 mx-auto d-flex"
                    :style="'position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'"
                    @click="gotoDocumentRegistryFolder"
                    @keypress.enter="gotoDocumentRegistryFolder"
                >
                    <!-- <img :src="schlossIcon" class="iconSelect" style="background-color: #F49F31"> -->
                    <v-icon>mdi-folder</v-icon>
                    <div
                        class="pl-1"
                        style="height: 24px"
                    >
                        <p
                            tabindex="0"
                            class="mb-0"
                            style="width: fit-content; height: fit-content;"
                        >
                            Online Bibliothek
                        </p>
                    </div>
                </div>
                <!--directory tree-->
                <div
                    v-if="!privateFolder && !teacherFolder && !docRegistry && !groupFolder"
                    style="margin-left: 5%"
                    class="pt-2 grey--text font-weight-bold"
                >
                    <!-- If user is searching globally -->
                    <div
                        v-if="searchString.trim().length > 0"
                        style="display: inline-flex; cursor: pointer"
                    >
                        <p
                            tabindex="0"
                            aria-label="Suchergebnisse:"
                            class="mb-0 mr-2"
                            style="width: fit-content"
                        >
                            Suchergebnisse:
                        </p>
                    </div>

                    <div
                        v-else
                        style="display: inline-flex; cursor: pointer"
                        @click="week = null; month = null; fach = null; day = null; subjectFolder = false"
                    >
                        <p
                            :id="(!fach && !month && !week && !day) ? 'currentPath' : ''"
                            tabindex="0"
                            aria-label="Dateipfad: Fächer "
                            class="mb-0"
                            style="width: fit-content"
                            @keypress.enter="week = null; month = null; fach = null; day = null; subjectFolder = false"
                        >
                            Fächer/
                        </p>
                    </div>

                    <div
                        v-if="fach"
                        style="display: inline-flex; cursor: pointer"
                        @click="week = null; month = null; day = null"
                    >
                        <p
                            :id="(fach && !month && !week && !day) ? 'currentPath' : ''"
                            tabindex="0"
                            :aria-label="'Aktueller Dateipfad: ' + 'Fach: ' + fach[0].schoolSubject.name"
                            class="mb-0"
                            style="width: fit-content"
                            @keypress.enter="week = null; month = null; day = null"
                        >
                            {{ fach[0].schoolSubject.name }}/
                        </p>
                    </div>

                    <div
                        v-if="month"
                        style="display: inline-flex; cursor: pointer"
                        @click="week = null; day = null"
                    >
                        <p
                            :id="(fach && month && !week && !day) ? 'currentPath' : ''"
                            tabindex="0"
                            :aria-label="'Aktueller Dateipfad: ' + 'Fach: ' + fach[0].schoolSubject.name + ', Monat:  ' + monthNames[new Date(month[0].day).getMonth()]"
                            class="mb-0"
                            style="width: fit-content"
                            @keypress.enter="week = null; day = null"
                        >
                            {{ monthNames[new Date(month[0].day).getMonth()] }}/
                        </p>
                    </div>

                    <div
                        v-if="week"
                        style="display: inline-flex; cursor: pointer"
                        @click="day = null"
                    >
                        <p
                            :id="(fach && month && week && !day) ? 'currentPath' : ''"
                            tabindex="0"
                            :aria-label="'Aktueller Dateipfad: ' + 'Fach: ' + fach[0].schoolSubject.name + ', Monat:  ' + monthNames[new Date(month[0].day).getMonth()] + ', Kalenderwoche ' + new Date(week[0].day).getWeek()"
                            class="mb-0"
                            style="width: fit-content"
                            @keypress.enter="day = null"
                        >
                            {{ new Date(week[0].day).getWeek() }}/
                        </p>
                    </div>

                    <div
                        v-if="day"
                        style="display: inline-flex; cursor: pointer"
                    >
                        <p
                            :id="(fach && month && week && day) ? 'currentPath' : ''"
                            tabindex="0"
                            :aria-label="'Aktueller Dateipfad: ' + 'Fach: ' + fach[0].schoolSubject.name + ', Monat:  ' + monthNames[new Date(month[0].day).getMonth()] + ', Kalenderwoche ' + new Date(week[0].day).getWeek() + ', Datum: ' + new Date(day[0].day).toLocaleDateString()"
                            class="mb-0"
                            style="width: fit-content"
                        >
                            {{ new Date(day[0].day).toLocaleDateString() }}
                        </p>
                    </div>
                </div>

                <!--body of Dateien-->
                <vue-custom-scrollbar
                    class="scroll-area"
                    :settings="settings"
                    @ps-scroll-y="scrollEvent"
                    @ps-y-reach-end="scrolledToEnd"
                >
                    <!-- Searched files -->
                    <div
                        v-if="!privateFolder && !subjectFolder && !teacherFolder && !docRegistry && !groupFolder && searchString.trim().length > 0"
                        style="margin-left: 5%"
                    >
                        <div
                            v-for="(item) in filterAppointmentsGlobally"
                            :key="item._id + 'srch_appt'"
                        >
                            <div
                                style="display: inline-flex;"
                                class="pt-2 grey--text font-weight-bold"
                            >
                                <img
                                    v-if="getSrc(item.schoolSubject.icon)"
                                    alt
                                    class="iconSelect"
                                    :style="{ 'background-color': item.schoolSubject.color }"
                                    :src="getSrc(item.schoolSubject.icon)"
                                >
                                <div
                                    tabindex="0"
                                    aria-label="Suchergebnisse:"
                                    class="mb-0 ml-2 d-flex"
                                >
                                    {{ item.schoolSubject.name }}
                                    <div
                                        class="font-weight-regular grey--text text--darken-2 ml-2"
                                    >
                                        {{ new Date(item.day).toLocaleDateString([], { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit' }) }} {{ getSlotTime(item.timeslot) }} Uhr
                                    </div>
                                </div>
                            </div>
                            <!-- Teacher Uploaded material -->
                            <div
                                v-for="(el, index) in filterFileTeacherUploadsBySearch(item)"
                                :key="el._id + 'srch_file'"
                            >
                                <div
                                    v-if="index === 0"
                                    class="ml-3 grey--text font-weight-medium"
                                >
                                    Lehrer Dateien:
                                </div>
                                <v-badge
                                    color="red"
                                    content="1"
                                    :value="!el.seenByMe && accountRole === 'pupil'"
                                    overlap
                                    offset-x="40"
                                    offset-y="20"
                                    style="z-index: 8; width: 100%;"
                                >
                                    <div
                                        class="pa-3 my-1 mx-auto"
                                        :style="'display:flex; position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '"
                                    >
                                        <img
                                            v-if="getSrc(item.schoolSubject.icon)"
                                            alt
                                            class="iconSelect"
                                            :style="'cursor: pointer; background-color:' + item.schoolSubject.color"
                                            :src="getSrc(item.schoolSubject.icon)"
                                            @click="() => clickDownloadTeacherUpload(el)"
                                        >
                                        <div
                                            class="align-center pl-1 materialTitle"
                                            @click="() => clickDownloadTeacherUpload(el)"
                                        >
                                            <p
                                                tabindex="0"
                                                class="mb-0"
                                                style="width: fit-content"
                                            >
                                                {{ el.title }}
                                            </p>
                                        </div>
                                        <div v-if="accountRole === 'pupil'">
                                            <v-tooltip
                                                v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop())"
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        x-small
                                                        class="ml-2 pa-0"
                                                        elevation="0"
                                                        style="height: 24px"
                                                        v-on="on"
                                                        @click="() => play(el)"
                                                    >
                                                        <img
                                                            :src="rechtsIcon"
                                                            alt="Video abspielen"
                                                            style="height: 24px;"
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Video abspielen</span>
                                            </v-tooltip>

                                            <v-tooltip
                                                v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        x-small
                                                        class="ml-2 pa-0"
                                                        elevation="0"
                                                        style="height: 24px"
                                                        v-on="on"
                                                        @click="() => { playAudio(el, index); menu = false }"
                                                    >
                                                        <img
                                                            :src="rechtsIcon"
                                                            alt="Audio abspielen"
                                                            style="height: 24px;"
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Audio abspielen</span>
                                            </v-tooltip>

                                            <v-tooltip
                                                v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        aria-label="Vorschau"
                                                        x-small
                                                        class="ml-2 pa-0"
                                                        elevation="0"
                                                        style="height: 24px"
                                                        v-on="on"
                                                        @click="openAnsicht(el.title, el.file, el._id, el.appointment, undefined, item)"
                                                    >
                                                        <v-icon
                                                            style="width: 20px; color: #939393"
                                                        >
                                                            fas fa-eye
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Vorschau</span>
                                            </v-tooltip>

                                            <v-tooltip
                                                v-else
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        x-small
                                                        class="ml-2 pa-0"
                                                        elevation="0"
                                                        style="height: 24px"
                                                        v-on="on"
                                                        @click="fileNotSupported(el)"
                                                    >
                                                        <img
                                                            :src="infoIcon"
                                                            alt="Dateiformat nicht unterstützt"
                                                            style="height: 24px;"
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Dateiformat nicht unterstützt</span>
                                            </v-tooltip>
                                        </div>

                                        <div v-else-if="accountRole === 'teacher'">
                                            <v-btn
                                                v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop())"
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="() => play(el)"
                                            >
                                                <img
                                                    :src="rechtsIcon"
                                                    style="height: 20px;"
                                                >
                                            </v-btn>
                                            <v-btn
                                                v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="() => { playAudio(el, index); menu = false }"
                                            >
                                                <img
                                                    :src="rechtsIcon"
                                                    style="height: 24px;"
                                                >
                                            </v-btn>
                                            <v-btn
                                                v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="(event) => { event.stopPropagation(); openAnsicht(el.title, el.file, el._id, el.appointment, 'pupil', item) }"
                                            >
                                                <v-icon
                                                    style="width: 20px; color: #939393"
                                                >
                                                    fas fa-eye
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                v-else
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="fileNotSupported(el)"
                                            >
                                                <img
                                                    :src="infoIcon"
                                                    style="height: 24px;"
                                                >
                                            </v-btn>

                                            <v-btn
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="openDeleteDialog(el, 'teacher');"
                                            >
                                                <img
                                                    :src="papierkorbIcon"
                                                    style="height: 20px"
                                                >
                                            </v-btn>
                                        </div>

                                        <div>
                                            <v-tooltip
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        x-small
                                                        elevation="0"
                                                        class="ml-2 pa-0"
                                                        style="height: 24px"
                                                        v-on="on"
                                                        @click="() => clickDownloadTeacherUpload(el)"
                                                    >
                                                        <img
                                                            :src="runterladenIcon"
                                                            alt="Herunterladen"
                                                            style="height: 20px;"
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Herunterladen</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </v-badge>
                            </div>
                            <!-- Pupil uploaded material -->
                            <div
                                v-for="(el, index) in filterFilePupilUploadsBySearch(item)"
                                :key="el._id + 'srch_pup_file'"
                            >
                                <div
                                    v-if="index === 0"
                                    class="ml-3 grey--text font-weight-medium"
                                >
                                    Schüler Abgaben:
                                </div>
                                <v-badge
                                    color="red"
                                    content="1"
                                    :value="!el.seenByMe"
                                    overlap
                                    offset-x="40"
                                    offset-y="20"
                                    style="z-index: 8; width: 100%;"
                                >
                                    <div
                                        class="pa-3 my-1 mx-auto"
                                        :style="'display:flex; position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '"
                                    >
                                        <img
                                            v-if="getSrc(item.schoolSubject.icon)"
                                            class="iconSelect"
                                            :style="'cursor:pointer; background-color:' + item.schoolSubject.color"
                                            :src="getSrc(item.schoolSubject.icon)"
                                            @click="() => clickDownloadPupilUpload(el)"
                                        >
                                        <div
                                            style="height: 24px"
                                            class="d-flex align-center pl-1"
                                            :style="'cursor:pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '"
                                            @click="() => clickDownloadPupilUpload(el)"
                                        >
                                            {{ el.title }}
                                        </div>

                                        <div>
                                            <v-btn
                                                v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop())"
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="() => playPupil(el)"
                                            >
                                                <img
                                                    :src="rechtsIcon"
                                                    style="height: 20px;"
                                                >
                                            </v-btn>
                                            <v-btn
                                                v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="() => { playAudioPupil(el, index); menu = false }"
                                            >
                                                <img
                                                    :src="rechtsIcon"
                                                    style="height: 24px;"
                                                >
                                            </v-btn>
                                            <v-btn
                                                v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="openAnsichtTeacher(el.title, el.file, el._id, el.appointment, 'teacher', item)"
                                            >
                                                <v-icon
                                                    style="width: 20px; color: #939393"
                                                >
                                                    fas fa-eye
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                v-else
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="fileNotSupported(el)"
                                            >
                                                <img
                                                    :src="infoIcon"
                                                    style="height: 24px;"
                                                >
                                            </v-btn>
                                        </div>

                                        <div>
                                            <v-btn
                                                x-small
                                                elevation="0"
                                                class="ml-2 pa-0"
                                                style="width: 20px; height: 24px"
                                                @click="() => clickDownloadPupilUpload(el)"
                                            >
                                                <img
                                                    :src="offenIcon"
                                                    style="height: 20px"
                                                >
                                            </v-btn>
                                        </div>
                                        <div>
                                            <v-btn
                                                x-small
                                                elevation="0"
                                                class="ml-2 pa-0"
                                                style="width: 20px; height: 24px"
                                                @click="openDeleteDialog(el, 'pupil');"
                                            >
                                                <img
                                                    :src="papierkorbIcon"
                                                    style="height: 20px"
                                                >
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-badge>
                            </div>
                        </div>
                    </div>

                    <!-- Fach folders -->
                    <div v-else-if="!fach && !privateFolder && !teacherFolder && !docRegistry && !groupFolder">
                        <div
                            v-for="(el, index) in schoolSubjectGroups"
                            :key="el[0]._id + index + 'subject'"
                        >
                            <v-badge
                                color="red"
                                :content="count(el)"
                                :value="count(el)"
                                overlap
                                right
                                offset-x="40"
                                offset-y="20"
                                style="z-index: 8; width: 100%;"
                            >
                                <div
                                    class="pa-3 my-1 mx-auto d-flex"
                                    :style="'position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'"
                                    @click="selectSubject(el)"
                                >
                                    <img
                                        v-if="getSrc(el[0].schoolSubject.icon)"
                                        alt
                                        class="iconSelect"
                                        :style="{ 'background-color': el[0].schoolSubject.color }"
                                        :src="getSrc(el[0].schoolSubject.icon)"
                                    >
                                    <div
                                        class="pl-1 d-flex align-center"
                                        style="height: 24px"
                                    >
                                        <p
                                            role="button"
                                            tabindex="0"
                                            class="mb-0"
                                            style="width: fit-content"
                                            @keypress.enter="selectSubject(el);"
                                        >
                                            {{ el[0].schoolSubject.name }}
                                        </p>
                                    </div>
                                </div>
                            </v-badge>
                        </div>
                    </div>

                    <!--inside Fach folder-->
                    <div v-else-if="!privateFolder && !teacherFolder && !docRegistry && !groupFolder">
                        <div v-if="!day && !subjectFolder">
                            <!--Allgemein folder-->
                            <div
                                v-for="(el) in getFachByTeacher()"
                                :key="el[0]._id + 'general'"
                                class="pa-3 my-1 mx-auto d-flex"
                                :style="'position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'"
                                @click="selectSubjectFolder(el[0].teachers[0]._id)"
                            >
                                <img
                                    v-if="getSrc(fach[0].schoolSubject.icon)"
                                    alt
                                    class="iconSelect"
                                    :style="{ 'background-color': fach[0].schoolSubject.color }"
                                    :src="getSrc(fach[0].schoolSubject.icon)"
                                >
                                <div
                                    class="pl-1"
                                    style="height: 24px"
                                >
                                    <p
                                        role="button"
                                        tabindex="0"
                                        class="mb-0"
                                        style="width: fit-content"
                                        @keypress.enter="selectSubjectFolder(el[0].teachers[0]._id)"
                                    >
                                        Allgemein
                                        {{ getTeacherDisplayName(el[0].teachers) }}
                                    </p>
                                </div>
                            </div>

                            <!--Appointment folders-->
                            <div v-if="month">
                                <div v-if="week">
                                    <!-- select specific appointment (date + timeslot) -->
                                    <div
                                        v-for="(el, index) in subjectDateGroups"
                                        :key="el[0]._id + index + 'Aptmnt'"
                                    >
                                        <v-badge
                                            color="red"
                                            :content="count(el)"
                                            :value="count(el)"
                                            overlap
                                            right
                                            offset-x="40"
                                            offset-y="20"
                                            style="z-index: 8; width: 100%;"
                                        >
                                            <div
                                                class="pa-3 my-1 mx-auto d-flex"
                                                :style="'position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'"
                                                @click="selectDay(el)"
                                            >
                                                <img
                                                    v-if="getSrc(fach[0].schoolSubject.icon)"
                                                    alt
                                                    class="iconSelect"
                                                    :style="{ 'background-color': fach[0].schoolSubject.color }"
                                                    :src="getSrc(fach[0].schoolSubject.icon)"
                                                >
                                                <div
                                                    v-if="accountRole === 'pupil'"
                                                    class="pl-1 d-flex align-center"
                                                    style="height: 24px"
                                                >
                                                    <p
                                                        role="button"
                                                        tabindex="0"
                                                        class="mb-0"
                                                        style="width: fit-content"
                                                        @keypress.enter="selectDay(el)"
                                                    >
                                                        {{
                                                            new Date(el[0].day).toLocaleDateString() + ' ' + getTeacherDisplayName(el[0].teachers)
                                                        }}
                                                    </p>
                                                </div>
                                                <div
                                                    v-else
                                                    class="pl-1 d-flex align-center"
                                                    style="height: 24px"
                                                >
                                                    <p
                                                        role="button"
                                                        tabindex="0"
                                                        class="mb-0"
                                                        style="width: fit-content"
                                                        @keypress.enter="selectDay(el)"
                                                    >
                                                        {{
                                                            new Date(el[0].day).toLocaleDateString() + ' ' + getSlotTime(el[0].timeslot)
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </v-badge>
                                    </div>
                                </div>
                                <!-- select calendar week -->
                                <div v-else>
                                    <div
                                        v-for="(el, index) in subjectWeekGroups"
                                        :key="el[0]._id + index + 'week'"
                                    >
                                        <v-badge
                                            color="red"
                                            :content="count(el)"
                                            :value="count(el)"
                                            overlap
                                            right
                                            offset-x="40"
                                            offset-y="20"
                                            style="z-index: 8; width: 100%;"
                                        >
                                            <div
                                                class="pa-3 my-1 mx-auto d-flex"
                                                :style="'position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'"
                                                @click="selectWeek(el)"
                                            >
                                                <img
                                                    v-if="getSrc(fach[0].schoolSubject.icon)"
                                                    alt
                                                    class="iconSelect"
                                                    :style="{ 'background-color': fach[0].schoolSubject.color }"
                                                    :src="getSrc(fach[0].schoolSubject.icon)"
                                                >
                                                <div
                                                    class="pl-1 d-flex align-center"
                                                    style="height: 24px"
                                                >
                                                    <p
                                                        role="button"
                                                        tabindex="0"
                                                        class="mb-0"
                                                        style="width: fit-content"
                                                        @keypress.enter="selectWeek(el)"
                                                    >
                                                        Woche
                                                        {{ new Date(el[0].day).getWeek() }}
                                                    </p>
                                                </div>
                                            </div>
                                        </v-badge>
                                    </div>
                                </div>
                            </div>
                            <!-- select month -->
                            <div v-else>
                                <div
                                    v-for="(el, index) in subjectMonthGroups"
                                    :key="el[0]._id + index + 'month'"
                                >
                                    <v-badge
                                        color="red"
                                        :content="count(el)"
                                        :value="count(el)"
                                        overlap
                                        right
                                        offset-x="40"
                                        offset-y="20"
                                        style="z-index: 8; width: 100%;"
                                    >
                                        <div
                                            class="pa-3 my-1 mx-auto d-flex"
                                            :style="'position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'"
                                            @click="selectMonth(el)"
                                        >
                                            <img
                                                v-if="getSrc(fach[0].schoolSubject.icon)"
                                                alt
                                                class="iconSelect"
                                                :style="{ 'background-color': fach[0].schoolSubject.color }"
                                                :src="getSrc(fach[0].schoolSubject.icon)"
                                            >
                                            <div
                                                class="pl-1 d-flex align-center"
                                                style="height: 24px"
                                            >
                                                <p
                                                    role="button"
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                    @keypress.enter="selectMonth(el)"
                                                >
                                                    {{ monthNames[new Date(el[0].day).getMonth()] }}
                                                </p>
                                            </div>
                                        </div>
                                    </v-badge>
                                </div>
                            </div>
                        </div>

                        <!--inside Appointment folder-->
                        <div v-else-if="!subjectFolder">
                            <div
                                style="margin-left: 5%"
                                class="pt-2 font-weight-bold greyText"
                            >
                                <p
                                    role="button"
                                    tabindex="0"
                                    class="mb-0"
                                    style="width: fit-content"
                                >
                                    Material:
                                </p>
                            </div>
                            <div
                                v-for="(el, index) in teacherUploads"
                                :key="el._id + index + 'lesson'"
                            >
                                <v-badge
                                    color="red"
                                    content="1"
                                    :value="!el.seenByMe && accountRole === 'pupil'"
                                    overlap
                                    offset-x="40"
                                    offset-y="20"
                                    style="z-index: 8; width: 100%;"
                                >
                                    <div
                                        class="pa-3 my-1 mx-auto"
                                        :style="'display:flex; position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '"
                                    >
                                        <img
                                            v-if="getSrc(fach[0].schoolSubject.icon)"
                                            alt
                                            class="iconSelect"
                                            :style="'cursor: pointer; background-color:' + fach[0].schoolSubject.color"
                                            :src="getSrc(fach[0].schoolSubject.icon)"
                                            @click="() => clickDownloadTeacherUpload(el)"
                                        >
                                        <div
                                            class="align-center pl-1 materialTitle"
                                            @click="() => clickDownloadTeacherUpload(el)"
                                        >
                                            <p
                                                tabindex="0"
                                                class="mb-0"
                                                style="width: fit-content"
                                            >
                                                {{ el.title }}
                                            </p>
                                        </div>
                                        <div v-if="accountRole === 'pupil'">
                                            <v-tooltip
                                                v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop())"
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        x-small
                                                        class="ml-2 pa-0"
                                                        elevation="0"
                                                        style="height: 24px"
                                                        v-on="on"
                                                        @click="() => play(el)"
                                                    >
                                                        <img
                                                            :src="rechtsIcon"
                                                            alt="Video abspielen"
                                                            style="height: 24px;"
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Video abspielen</span>
                                            </v-tooltip>

                                            <v-tooltip
                                                v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        x-small
                                                        class="ml-2 pa-0"
                                                        elevation="0"
                                                        style="height: 24px"
                                                        v-on="on"
                                                        @click="() => { playAudio(el, index); menu = false }"
                                                    >
                                                        <img
                                                            :src="rechtsIcon"
                                                            alt="Audio abspielen"
                                                            style="height: 24px;"
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Audio abspielen</span>
                                            </v-tooltip>

                                            <v-tooltip
                                                v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        aria-label="Vorschau"
                                                        x-small
                                                        class="ml-2 pa-0"
                                                        elevation="0"
                                                        style="height: 24px"
                                                        v-on="on"
                                                        @click="openAnsicht(el.title, el.file, el._id, el.appointment)"
                                                    >
                                                        <v-icon
                                                            style="width: 20px; color: #939393"
                                                        >
                                                            fas fa-eye
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Vorschau</span>
                                            </v-tooltip>

                                            <v-tooltip
                                                v-else
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        x-small
                                                        class="ml-2 pa-0"
                                                        elevation="0"
                                                        style="height: 24px"
                                                        v-on="on"
                                                        @click="fileNotSupported(el)"
                                                    >
                                                        <img
                                                            :src="infoIcon"
                                                            alt="Dateiformat nicht unterstützt"
                                                            style="height: 24px;"
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Dateiformat nicht unterstützt</span>
                                            </v-tooltip>
                                        </div>

                                        <div v-else-if="accountRole === 'teacher'">
                                            <v-btn
                                                v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop())"
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="() => play(el)"
                                            >
                                                <img
                                                    :src="rechtsIcon"
                                                    style="height: 20px;"
                                                >
                                            </v-btn>
                                            <v-btn
                                                v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="() => { playAudio(el, index); menu = false }"
                                            >
                                                <img
                                                    :src="rechtsIcon"
                                                    style="height: 24px;"
                                                >
                                            </v-btn>
                                            <v-btn
                                                v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="(event) => { event.stopPropagation(); openAnsicht(el.title, el.file, el._id, el.appointment, 'pupil') }"
                                            >
                                                <v-icon
                                                    style="width: 20px; color: #939393"
                                                >
                                                    fas fa-eye
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                v-else
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="fileNotSupported(el)"
                                            >
                                                <img
                                                    :src="infoIcon"
                                                    style="height: 24px;"
                                                >
                                            </v-btn>

                                            <v-btn
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="openDeleteDialog(el, 'teacher');"
                                            >
                                                <img
                                                    :src="papierkorbIcon"
                                                    style="height: 20px"
                                                >
                                            </v-btn>
                                        </div>

                                        <div>
                                            <v-tooltip
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        x-small
                                                        elevation="0"
                                                        class="ml-2 pa-0"
                                                        style="height: 24px"
                                                        v-on="on"
                                                        @click="() => clickDownloadTeacherUpload(el)"
                                                    >
                                                        <img
                                                            :src="runterladenIcon"
                                                            alt="Herunterladen"
                                                            style="height: 20px;"
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Herunterladen</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </v-badge>
                            </div>

                            <div v-if="accountRole === 'teacher'">
                                <div
                                    style="margin-left: 5%"
                                    class="pt-2 font-weight-bold greyText"
                                >
                                    Korrigiert:
                                </div>
                                <div
                                    v-for="(el, index) in pupileUploads.filter((element) => isCorrectionAvailable(element))"
                                    :key="el._id + index + 'correction'"
                                >
                                    <div
                                        class="pa-3 my-1 mx-auto"
                                        :style="'display:flex; position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '"
                                    >
                                        <img
                                            v-if="getSrc(fach[0].schoolSubject.icon)"
                                            class="iconSelect"
                                            :style="'cursor: pointer;background-color:' + fach[0].schoolSubject.color"
                                            :src="getSrc(fach[0].schoolSubject.icon)"
                                            @click="() => clickDownloadPupilCorrection(el)"
                                        >
                                        <div
                                            class="pl-1"
                                            :style="'cursor: pointer;width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '"
                                            @click="() => clickDownloadPupilCorrection(el)"
                                        >
                                            {{ el.title }}
                                        </div>

                                        <div>
                                            <v-btn
                                                v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                dark
                                                x-small
                                                class="btnStyle"
                                                elevation="0"
                                                style="background-color: #8CBD46;"
                                                @click="fileNotSupported(el)"
                                            >
                                                <img
                                                    :src="infoIcon"
                                                    style="height: 20px; filter: brightness(1000%)"
                                                >
                                            </v-btn>
                                            <v-btn
                                                v-else
                                                x-small
                                                class="ml-2 pa-0"
                                                elevation="0"
                                                style="height: 24px"
                                                @click="openAnsichtTeacher(el.title, el.file, el._id, el.appointment, 'teacher')"
                                            >
                                                <v-icon
                                                    style="width: 20px; color: #939393"
                                                >
                                                    fas fa-eye
                                                </v-icon>
                                            </v-btn>
                                        </div>

                                        <div>
                                            <v-btn
                                                elevation="0"
                                                x-small
                                                class="ml-2 pa-0"
                                                style="width: 20px; height: 24px"
                                                @click="clickDownloadPupilCorrection(el)"
                                            >
                                                <img
                                                    :src="bearbeitetIcon"
                                                    style="height: 20px;"
                                                >
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style="margin-left: 5%"
                                    class="pt-2 font-weight-bold greyText"
                                >
                                    Unkorrigiert:
                                </div>
                                <div
                                    v-for="(el, index) in pupileUploads.filter((element) => !isCorrectionAvailable(element))"
                                    :key="el._id + index"
                                >
                                    <v-badge
                                        color="red"
                                        content="1"
                                        :value="!el.seenByMe"
                                        overlap
                                        offset-x="40"
                                        offset-y="20"
                                        style="z-index: 8; width: 100%;"
                                    >
                                        <div
                                            class="pa-3 my-1 mx-auto"
                                            :style="'display:flex; position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '"
                                        >
                                            <img
                                                v-if="getSrc(fach[0].schoolSubject.icon)"
                                                class="iconSelect"
                                                :style="'cursor:pointer; background-color:' + fach[0].schoolSubject.color"
                                                :src="getSrc(fach[0].schoolSubject.icon)"
                                                @click="() => clickDownloadPupilUpload(el)"
                                            >
                                            <div
                                                style="height: 24px"
                                                class="d-flex align-center pl-1"
                                                :style="'cursor:pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '"
                                                @click="() => clickDownloadPupilUpload(el)"
                                            >
                                                {{ el.title }}
                                            </div>

                                            <div>
                                                <v-btn
                                                    v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop())"
                                                    x-small
                                                    class="ml-2 pa-0"
                                                    elevation="0"
                                                    style="height: 24px"
                                                    @click="() => playPupil(el)"
                                                >
                                                    <img
                                                        :src="rechtsIcon"
                                                        style="height: 20px;"
                                                    >
                                                </v-btn>
                                                <v-btn
                                                    v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                    x-small
                                                    class="ml-2 pa-0"
                                                    elevation="0"
                                                    style="height: 24px"
                                                    @click="() => { playAudioPupil(el, index); menu = false }"
                                                >
                                                    <img
                                                        :src="rechtsIcon"
                                                        style="height: 24px;"
                                                    >
                                                </v-btn>
                                                <v-btn
                                                    v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                    x-small
                                                    class="ml-2 pa-0"
                                                    elevation="0"
                                                    style="height: 24px"
                                                    @click="openAnsichtTeacher(el.title, el.file, el._id, el.appointment, 'teacher')"
                                                >
                                                    <v-icon
                                                        style="width: 20px; color: #939393"
                                                    >
                                                        fas fa-eye
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    v-else
                                                    x-small
                                                    class="ml-2 pa-0"
                                                    elevation="0"
                                                    style="height: 24px"
                                                    @click="fileNotSupported(el)"
                                                >
                                                    <img
                                                        :src="infoIcon"
                                                        style="height: 24px;"
                                                    >
                                                </v-btn>
                                            </div>

                                            <div>
                                                <v-btn
                                                    x-small
                                                    elevation="0"
                                                    class="ml-2 pa-0"
                                                    style="width: 20px; height: 24px"
                                                    @click="() => clickDownloadPupilUpload(el)"
                                                >
                                                    <img
                                                        :src="offenIcon"
                                                        style="height: 20px"
                                                    >
                                                </v-btn>
                                            </div>
                                            <div>
                                                <v-btn
                                                    x-small
                                                    elevation="0"
                                                    class="ml-2 pa-0"
                                                    style="width: 20px; height: 24px"
                                                    @click="openDeleteDialog(el, 'pupil');"
                                                >
                                                    <img
                                                        :src="papierkorbIcon"
                                                        style="height: 20px"
                                                    >
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-badge>
                                </div>
                            </div>

                            <div v-else>
                                <div
                                    style="margin-left: 5%"
                                    class="pt-2 font-weight-bold greyText"
                                >
                                    <p
                                        tabindex="0"
                                        class="mb-0"
                                        style="width: fit-content;"
                                    >
                                        Deine Abgaben:
                                    </p>
                                </div>
                                <div
                                    v-for="(el, index) in pupileUploads"
                                    :key="el._id + index + 'hand in'"
                                >
                                    <div
                                        class="pa-3 my-3 mx-auto"
                                        :style="'display:flex; position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '"
                                    >
                                        <img
                                            v-if="getSrc(fach[0].schoolSubject.icon)"
                                            class="iconSelect"
                                            :style="'cursor:pointer; background-color:' + fach[0].schoolSubject.color"
                                            :src="getSrc(fach[0].schoolSubject.icon)"
                                            @click="() => clickDownloadPupilUpload(el)"
                                        >
                                        <div
                                            class="pl-1"
                                            :style="'cursor:pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '"
                                            @click="() => clickDownloadPupilUpload(el)"
                                        >
                                            <p
                                                tabindex="0"
                                                class="mb-0"
                                                style="width: fit-content;"
                                            >
                                                {{ el.title }}
                                            </p>
                                        </div>
                                        <div style="display: inline-block">
                                            <v-tooltip
                                                v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop())"
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        x-small
                                                        class="ml-2 pa-0"
                                                        elevation="0"
                                                        style="height: 24px"
                                                        v-on="on"
                                                        @click="() => playPupil(el)"
                                                    >
                                                        <img
                                                            :src="rechtsIcon"
                                                            alt="Video abspielen"
                                                            style="height: 24px;"
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Video abspielen</span>
                                            </v-tooltip>

                                            <v-tooltip
                                                v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        x-small
                                                        class="ml-2 pa-0"
                                                        elevation="0"
                                                        style="height: 24px"
                                                        v-on="on"
                                                        @click="() => { playAudioPupil(el, index); menu = false }"
                                                    >
                                                        <img
                                                            :src="rechtsIcon"
                                                            alt="Audio abspielen"
                                                            style="height: 24px;"
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Audio abspielen</span>
                                            </v-tooltip>

                                            <v-tooltip
                                                v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        aria-label="Vorschau"
                                                        class="btnStyle"
                                                        x-small
                                                        elevation="0"
                                                        color="rgba(0,0,0,0)"
                                                        v-on="on"
                                                        @click="(event) => { event.stopPropagation(); openAnsicht(el.title, el.file, el._id, el.appointment, 'teacher') }"
                                                    >
                                                        <!--                                                            <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                        <v-icon
                                                            style="width: 24px; color: #939393"
                                                        >
                                                            fas fa-eye
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Vorschau</span>
                                            </v-tooltip>

                                            <v-tooltip
                                                v-else
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        x-small
                                                        class="ml-2 pa-0"
                                                        elevation="0"
                                                        style="height: 24px"
                                                        v-on="on"
                                                        @click="fileNotSupported(el)"
                                                    >
                                                        <img
                                                            :src="infoIcon"
                                                            alt="Dateiformat nicht unterstützt"
                                                            style="height: 24px;"
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Dateiformat nicht unterstützt</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--inside Allgemein folder-->
                        <div v-else>
                            <input
                                id="subjectUploadInput"
                                ref="subjectUploadInput"
                                type="file"
                                hidden
                                @change="() => subjectUploadInputChange()"
                            >

                            <div
                                v-if="accountRole === 'teacher'"
                                class="d-flex"
                                style="justify-content: center"
                            >
                                <v-btn
                                    style="padding-left: 50px; padding-right: 50px; color: var(--v-dunkelgrau-base); border-radius: 10px"
                                    class="text-capitalize"
                                    elevation="0"
                                    @click="clickSubjectUploadButton"
                                >
                                    <img
                                        :src="hochladenIcon"
                                        style="height: 20px;"
                                        class="mr-2"
                                    >
                                    Hochladen
                                </v-btn>
                            </div>

                            <div v-if="subjectFiles.length > 0">
                                <div
                                    v-for="(el, index) in subjectFiles.filter(value => value.uploadedName.toLowerCase().includes(searchString.toLowerCase()))"
                                    :key="el._id + index + 'comm'"
                                >
                                    <div
                                        class="pa-3 my-3 mx-auto"
                                        :style="'display:flex; position: relative; border-radius: 10px; color: var(--v-dunkelgrau-base); box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '"
                                    >
                                        <div
                                            style="height: 24px"
                                            class="d-flex align-center"
                                            :style="'cursor: pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '"
                                            @click="() => clickDownloadSubjectUpload(el)"
                                        >
                                            <p
                                                tabindex="0"
                                                class="mb-0"
                                                style="width: fit-content;"
                                            >
                                                {{ el.uploadedName }}
                                            </p>
                                        </div>

                                        <div style="height: 24px">
                                            <v-btn
                                                v-if="accountRole === 'teacher'"
                                                x-small
                                                elevation="0"
                                                class="ml-2 pa-0"
                                                style="width: 20px; height: 24px"
                                                @click="openDeleteDialog(el, 'subject');"
                                            >
                                                <img
                                                    :src="papierkorbIcon"
                                                    style="height: 20px;"
                                                >
                                            </v-btn>

                                            <v-tooltip
                                                :disabled="!showTooltips"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        x-small
                                                        elevation="0"
                                                        class="ml-2 pa-0"
                                                        style="width: 20px; height: 24px"
                                                        v-on="on"
                                                        @click="() => clickDownloadSubjectUpload(el)"
                                                    >
                                                        <img
                                                            :src="runterladenIcon"
                                                            alt="Herunterladen"
                                                            style="height: 20px;"
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>Herunterladen</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else
                                class="px-4 my-3 mx-auto"
                                style="color: #737373"
                            >
                                <p
                                    tabindex="0"
                                    class="mb-0"
                                    style="width: fit-content;"
                                >
                                    Hier gibts noch keine
                                    Dateien
                                </p>
                            </div>
                        </div>
                    </div>

                    <!--inside Privater folder-->
                    <div
                        v-else-if="!teacherFolder && !docRegistry && !groupFolder && privateFolder"
                        class="my-3"
                    >
                        <VirtualFileSystem
                            ref="privateVFS"
                            :folder-type="'privateFolder'"
                            :folder-depth="folderDepth"
                            :refresh-toggle="`${currentFolderToRefresh}Folder`"
                            :scroll-top="scrollTop"
                            :upload-local-file-function="clickPrivateUploadButton"
                            :upload-file-from-shared-function="() => { fileWidgetUploadDialog = true; copyDestination = 'private'; }"
                            :search-result="searchResultPrivateFolder"
                            @currentFolder="updateCurrentFolder"
                            @mutateFolderDepth="(val) => mutateFolderDepth(val)"
                            @notifyMoveFile="handleMoveElementClicked"
                            @viewFile="(file) => openPrivateAnsicht(file)"
                        />
                    </div>

                    <div
                        v-else-if="!teacherFolder && !docRegistry && !privateFolder && groupFolder"
                        class="my-3"
                    >
                        <VirtualFileSystem
                            ref="groupVFS"
                            :folder-type="'groupFolder'"
                            :folder-depth="folderDepth"
                            :refresh-toggle="`${currentFolderToRefresh}Folder`"
                            :scroll-top="scrollTop"
                            :upload-local-file-function="clickPrivateUploadButton"
                            :upload-file-from-shared-function="() => { fileWidgetUploadDialog = true; copyDestination = 'private'; }"
                            :search-result="searchResultGroupFolder"
                            @currentFolder="updateCurrentFolder"
                            @mutateFolderDepth="(val) => mutateFolderDepth(val)"
                            @notifyMoveFile="handleMoveElementClicked"
                            @viewFile="(file, params) => openPrivateAnsicht(file, params)"
                        />
                    </div>

                    <!--inside Online folder-->
                    <div
                        v-else-if="!privateFolder && !teacherFolder && !groupFolder && docRegistry"
                        class="my-3"
                    >
                        <VirtualFileSystemDocRegistry
                            ref="docRegistryVFS"
                            :folder-type="'documentRegistry'"
                            :folder-depth="folderDepth"
                            :refresh-toggle="`${currentFolderToRefresh}Folder`"
                            :scrolled-to-end="endReached"
                            :inital-folder-id-tree="folderIdTreeToDocumentRegistry"
                            @currentFolder="updateCurrentFolder"
                            @mutateFolderDepth="(val) => mutateFolderDepth(val)"
                            @notifyMoveFile="handleMoveElementClicked"
                            @copyDocumentToBackend="prepareUploadFromDocumentRegistry"
                            @resetInitalFolderIdTree="() => { folderIdTreeToDocumentRegistry = null }"
                        />
                    </div>

                    <!--inside Teacher folder-->
                    <div
                        v-else
                        class="my-3"
                    >
                        <VirtualFileSystem
                            ref="teacherVFS"
                            :folder-type="'teacherFolder'"
                            :folder-depth="folderDepth"
                            :refresh-toggle="`${currentFolderToRefresh}Folder`"
                            :scroll-top="scrollTop"
                            :upload-local-file-function="clickTeacherFolderUploadButton"
                            :upload-file-from-private-function="() => { fileWidgetUploadDialog = true; copyDestination = 'teacher'; }"
                            :inital-folder-id-tree="vfsInitialFolderIdTree"
                            :search-result="searchResultTeacherFolder"
                            @currentFolder="updateCurrentFolder"
                            @mutateFolderDepth="(val) => mutateFolderDepth(val)"
                            @notifyMoveFile="handleMoveElementClicked"
                            @resetInitalFolderIdTree="() => { vfsInitialFolderIdTree = null }"
                        />
                    </div>
                </vue-custom-scrollbar>
                <!-- Info: Num of Elements, Change Sorting, ToggleView -->
                <div
                    v-if="subjectFolder === false && (teacherFolder || privateFolder || docRegistry || groupFolder)"
                    style="border-top-style: solid; border-top-color: rgba(200, 200, 200, 0.75);
                            display: flex; justify-content: space-between; align-items: center; padding-top: 4px; padding-bottom: 4px;
                            position:absolute; bottom: 0; width: 100%; background-color: white; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px"
                    min-height="50px"
                >
                    <span
                        style="margin-left: 10px; font-size: small; white-space: nowrap;
                                align-items: center; overflow: hidden; text-overflow: clip"
                    >{{ privateFolder ? privateFolderSelection > 1 ? privateFolderSelection + ' Elemente' : privateFolderSelection === 1 ? '1 Element' : 'Keine Elemente' :
                        teacherFolder ? teacherFolderSelection > 1 ? teacherFolderSelection + ' Elemente' : teacherFolderSelection === 1 ? '1 Element' : 'Keine Elemente' :
                        groupFolder ? groupFolderSelection > 1 ? groupFolderSelection + ' Elemente' : groupFolderSelection === 1 ? '1 Element' : 'Keine Elemente' :
                        docRegistry ? docRegistryFolderSelection > 1 ? docRegistryFolderSelection + ' Elemente' : docRegistryFolderSelection === 1 ? '1 Element' : 'Keine Elemente' : '' }}</span>
                    <v-spacer />
                    <v-btn
                        class="toolbarButton"
                        color="rgb(0, 0, 0, 0.01)"
                        small
                        tile
                        min-width="35px"
                        min-height="35px"
                        max-width="35px"
                        max-height="35px"
                        style="margin-right: 10px; margin-left: 10px"
                        @click="() => { toggleSorting() }"
                    >
                        <v-icon
                            style="opacity: 0.75"
                        >
                            {{ privateFolder ? privateFolderSortDescending ? 'mdi-sort-alphabetical-descending' : 'mdi-sort-alphabetical-ascending' :
                                teacherFolder ? teacherFolderSortDescending ? 'mdi-sort-alphabetical-descending' : 'mdi-sort-alphabetical-ascending' :
                                groupFolder ? groupFolderSortDescending ? 'mdi-sort-alphabetical-descending' : 'mdi-sort-alphabetical-ascending' :
                                docRegistry ? docRegistryFolderSortDescending ? 'mdi-sort-alphabetical-descending' : 'mdi-sort-alphabetical-ascending' : '' }}
                        </v-icon>
                    </v-btn>
                    <v-btn
                        class="toolbarButton"
                        color="rgb(0, 0, 0, 0.01)"
                        small
                        tile
                        min-width="35px"
                        min-height="35px"
                        max-width="35px"
                        max-height="35px"
                        style="margin-right: 10px; margin-left: 10px"
                        @click="mutateVfsGridWidthPct()"
                    >
                        <v-icon
                            v-if="vfsGridWidthPct === 33"
                            style="opacity: 0.75"
                        >
                            mdi-view-comfy
                        </v-icon>
                        <v-icon
                            v-else-if="vfsGridWidthPct === 50"
                            style="opacity: 0.75"
                        >
                            mdi-view-grid
                        </v-icon>
                        <v-icon
                            v-if="vfsGridWidthPct === 100"
                            style="opacity: 0.75"
                        >
                            mdi-view-list
                        </v-icon>
                    </v-btn>
                </div>
            </div>
        </v-row>

        <!-- popups, overlays and snackbars -->
        <v-dialog
            v-model="deleteDialog"
            max-width="350px"
        >
            <v-card>
                <v-card-title>Löschen bestätigen</v-card-title>

                <v-card-text>
                    Wollen Sie die Datei wirklich
                    <strong>unwiderruflich</strong> löschen?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="rot"
                        dark
                        @click="confirmDelete"
                    >
                        <img
                            :src="papierkorbIcon"
                            style="height: 20px; filter: brightness(1000%)"
                            class="mr-2"
                        >
                        Löschen
                    </v-btn>

                    <v-btn
                        class="mr-2 text-capitalize"
                        color="gruen"
                        dark
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="schliessenIcon"
                            style="height: 20px; filter: brightness(1000%)"
                            class="mr-2"
                        >
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay
            v-if="videoUrl"
            opacity="0.95"
            z-index="95"
        >
            <v-icon
                large
                style="position: fixed; top: 50px; right: 50px; z-index: 99"
                dark
                @click="videoUrl = false"
            >
                fas fa-times
            </v-icon>
            <video
                :src="videoUrl"
                controls
                :width="windowWidth > 900 ? '80%' : '40%%'"
                :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }"
            >Your browser does not support the video tag.</video>
        </v-overlay>

        <v-overlay
            v-if="audioUrl"
            opacity="0.95"
            z-index="95"
        >
            <v-icon
                large
                style="position: fixed; top: 50px; right: 50px; z-index: 99"
                dark
                @click="audioUrl = false"
            >
                fas fa-times
            </v-icon>
            <audio
                :src="audioUrl"
                controls
                :width="windowWidth > 900 ? '80%' : '40%%'"
                :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }"
            >Your browser does not support the video tag.</audio>
        </v-overlay>
        <file-widget-selection-dialog
            :show="fileWidgetUploadDialog"
            :hide-private-folder="privateFolder"
            :hide-shared-folder="teacherFolder"
            :item-selected-callback="prepareUploadFromFileWidget"
            @close="fileWidgetUploadDialog = false;"
        />
        <NameFile
            :show-dialog="fileWidgetNameDialog"
            :show-is-assignment="false"
            :original-name="newFileName"
            :callback="uploadViaFileWidget"
            @close="fileWidgetNameDialog = false"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import * as backend from "../api/backend";
import vueCustomScrollbar from "vue-custom-scrollbar";
import FileWidgetSelectionDialog from "@/components/Utils/FileWidgetSelectionDialog";
import VirtualFileSystem from "@/components/VirtualFileSystem";
import VirtualFileSystemDocRegistry from "@/components/VirtualFileSystemDocRegistry";

import fileIcon from '../assets/Icons/file.svg'
import bearbeitenIcon from '../assets/Icons/bearbeiten-normal-41.svg'
import rechtsIcon from '../assets/Icons/rechts-11.svg'
import offenIcon from "../assets/Icons/offen-18.svg";
import hochladenIcon from "../assets/Icons/hochladen-15.svg";
import bearbeitetIcon from '../assets/Icons/bearbeitet-17.svg'
import abbrechenIcon from "../assets/Icons/abbrechen-08.svg";
import schliessenIcon from "../assets/Icons/abbrechen-08.svg";
import runterladenIcon from '../assets/Icons/runterladen-14.svg'
import schlossIcon from '../assets/Icons/lock-closed-white.svg'
import papierkorbIcon from '../assets/Icons/papierkorb-110.svg'
import infoIcon from "../assets/Icons/info-45.svg";
import infoRoundIcon from "../assets/Icons/info-2-weiß-89.svg";
import leftIcon from "../assets/Icons/links-filled-10.svg";

import bioIcon from '../assets/Icons/f-biologie-weiss-35.svg'
import chemieIcon from '../assets/Icons/f-chemie-weiss-37.svg'
import deutschIcon from '../assets/Icons/f-deutsch-weiss-29.svg'
import englischIcon from '../assets/Icons/f-englisch-weiss-30.svg'
import erdkundeIcon from '../assets/Icons/f-erdkunde-weiss-31.svg'
import geschichteIcon from '../assets/Icons/f-geschichte-weiss-40.svg'
import matheIcon from '../assets/Icons/f-mathe-weiss-32.svg'
import musikIcon from '../assets/Icons/f-musik-weiss-82.svg'
import physikIcon from '../assets/Icons/f-physik-weiss-36.svg'
import politikIcon from '../assets/Icons/f-politik-weiss-34.svg'
import religionIcon from '../assets/Icons/f-religion-weiss-39.svg'
import sportIcon from '../assets/Icons/f-sport-weiss-38.svg'
import wirtschaftIcon from '../assets/Icons/f-wirtschaft-weiss-33.svg'

import ProgressBar from "@/components/ProgressBar";
import NameFile from "@/components/NameFile";
import leeresBlatt from "@/assets/Icons/leeres-blatt-110.svg";
import kariertesBlatt from "@/assets/Icons/kariertes blatt-112.svg";
import liniertesBlatt from "@/assets/Icons/liniertes-blatt-111.svg";
import dateiIcon from "@/assets/Icons/leere-korrektur-78.svg";
import arrowDown from "@/assets/Icons/unten-dropdown-12.svg";
import FileSystemPlusMenu from "@/components/FileArchive/FileSystemPlusMenu";

export default {
    name: "FileWidget",
    components: {
        FileSystemPlusMenu,
        FileWidgetSelectionDialog,
        ProgressBar,
        NameFile,
        vueCustomScrollbar,
        VirtualFileSystem,
        VirtualFileSystemDocRegistry,
    },
    props: {
        showTooltips: { required: false, default: true },
    },
    data() {
        return {
            monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni",
                "Juli", "August", "September", "Oktober", "November", "Dezember"
            ],

            person: {
                accessibility: {
                    screenreader: false,
                }
            },

            appointments: [],

            schoolSubjectGroups: [],

            subjectMonthGroups: [],
            subjectWeekGroups: [],
            subjectDateGroups: [],
            teacherUploads: [],
            pupileUploads: [],

            fach: null,

            scrollTop: 0,
            endReached: false,

            privateFolder: false,
            teacherFolder: false,
            subjectFolder: false,
            groupFolder: false,
            docRegistry: false,
            folderDepth: 0,

            searchString: "",
            searchResultPrivateFolder: {},
            searchResultTeacherFolder: {},
            searchResultGroupFolder: {},

            videoUrl: null,
            audioUrl: null,

            day: null,

            month: null,

            week: null,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false
            },

            deleteDialog: false,
            elementToDelete: null,
            deleteMethod: '',

            fileIcon,
            bearbeitenIcon,
            rechtsIcon,
            runterladenIcon,
            bearbeitetIcon,
            abbrechenIcon,
            offenIcon,
            hochladenIcon,
            schlossIcon,
            papierkorbIcon,
            infoIcon,
            infoRoundIcon,
            schliessenIcon,
            leftIcon,

            bioIcon,
            chemieIcon,
            deutschIcon,
            englischIcon,
            erdkundeIcon,
            geschichteIcon,
            matheIcon,
            musikIcon,
            physikIcon,
            politikIcon,
            religionIcon,
            sportIcon,
            wirtschaftIcon,

            leeresBlatt,
            kariertesBlatt,
            liniertesBlatt,
            dateiIcon,

            arrowDown,

            showPrivateUploadProgress: false,
            privateUploadProgress: 0.0,
            privateXmlHttpRequest: null,

            showSubjectUploadProgress: false,
            subjectUploadProgress: 0.0,
            subjectXmlHttpRequest: null,

            showNameFile: false,
            uploadFileName: "",
            teacherFiles: [],
            fileNameCallback: () => {
            },
            teacherId: '',
            subjectFiles: [],
            slots: [],

            fileWidgetUploadDialog: false,
            fileWidgetNameDialog: false,
            fileToUpload: null,
            newFileName: "",
            copyDestination: 'private',
            currentFolder: null,
            currentFolderToRefresh: '',

            // Quick and dirty by Simon
            // Inital 'path' for the vfs when you click teacherFolder
            vfsInitialFolderIdTree: null,
            // Path to the document registry folder that can be used as a 'vfsInitialFolderIdTree'
            folderIdTreeToDocumentRegistry: null,
        };
    },
    computed: {
        ...mapGetters("auth", ["accountRole", "accountId"]),
        ...mapState("auth", ["token"]),
        ...mapGetters('util', ['currentUploadGroup']),
        ...mapState('util', ['windowWidth']),
        ...mapState('translation', ['targetLang',]),
        ...mapGetters('vfs', ['privateFolderSelection', 'teacherFolderSelection', 'docRegistryFolderSelection', 'groupFolderSelection', 'privateFolderSortDescending', 'teacherFolderSortDescending', 'docRegistryFolderSortDescending', 'groupFolderSortDescending', 'vfsGridWidthPct']),
        ...mapState('documentRegistry', ['documentRegistryDocuments', 'documentRegistryRootFolder', 'documentRegistryFolders']),

        filterAppointmentsGlobally() {
            return this.appointments.filter(appointment => {
                // If the user has selected a subject, then only show files within that subject
                const specificSubjectFilter = (this.fach ? appointment.schoolSubject._id === this.fach[0].schoolSubject._id : true);
                // Now check how many files are found that contain the search string
                const hasTeacherUploads = appointment.teacherUploads.filter(upload => {
                    return upload.title.toLowerCase().includes(this.searchString.toLowerCase());
                }).length > 0;

                const hasPupilUploads = appointment.pupilUpload.filter(pupilFile => {
                    return pupilFile.title.toLowerCase().includes(this.searchString.toLowerCase());
                }).length > 0;

                return (specificSubjectFilter && (hasTeacherUploads || hasPupilUploads));
            });
            // return this.appointments.filter( // Filter only for appointments with uploads
            //     el => (this.fach ? el.schoolSubject._id === this.fach[0].schoolSubject._id : true) && el.teacherUploads.filter(   // Check if any of the upload matches the search string
            //         u => u.title.toLowerCase().includes(this.searchString.toLowerCase())).length > 0)
        },
    },
    beforeCreate() {
        Date.prototype.getWeek = function () {
            var date = new Date(this.getTime());
            date.setHours(0, 0, 0, 0);
            // Thursday in current week decides the year.
            date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
            // January 4 is always in week 1.
            var week1 = new Date(date.getFullYear(), 0, 4);
            // Adjust to Thursday in week 1 and count number of weeks from date to week1.
            return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                - 3 + (week1.getDay() + 6) % 7) / 7);
        }
    },
    async mounted() {
        await this.requestAppointments();
        if(this.accountRole === "teacher") {
            await this.requestTeacherFolder();
        }
        await this.requestSlots();
        await this.requestPerson();

        if(this.accountRole === "teacher") {
            await this.loadDocumentRegistryDocuments();
            await this.loadDocumentRegistryRootFolder();
            await this.loadDocumentRegistryFolders();
        }
    },
    async unmounted() {
        URL.revokeObjectURL(this.videoUrl);
        URL.revokeObjectURL(this.audioUrl);
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions("teacherFolder", ["getVFSFolderUploads", "deleteVFSFolderUpload"]),
        ...mapActions("subjectFolder", ["getSubjectFolder", "deleteSubjectFile"]),
        ...mapActions('documentRegistry', ['loadDocumentRegistryDocuments', 'loadDocumentRegistryRootFolder', 'loadDocumentRegistryFolders']),
        ...mapActions("util", ['toggleOpenAppointment', 'toggleCurrentUploadGroup', 'toggleCurrentUploadFolder']),
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        ...mapActions('pupils', ['getMePupil']),
        ...mapActions('vfs', ['searchVFS']),
        ...mapActions('files', ['safeNewPrivateFolderFile']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapMutations('vfs', ['setPrivateFolderSortDescending', 'setTeacherFolderSortDescending', 'setDocRegistryFolderSortDescending', 'setGroupFolderSortDescending', 'mutateVfsGridWidthPct']),

        async gotoDocumentRegistryFolder() {
            this.folderIdTreeToDocumentRegistry = [{
                files: [...this.documentRegistryDocuments],
                folders: [...this.documentRegistryFolders],
                root: this.documentRegistryRootFolder,
                name: 'documentRegistry',
            }];

            this.docRegistry = true;
            this.folderDepth = 1;
        },

        filterFileTeacherUploadsBySearch(appointment) {
            return appointment.teacherUploads.filter(   // Filter for the search string and map the appointment id on the upload (used for download, etc)
                u => u.title.toLowerCase().includes(this.searchString.toLowerCase()))
                .map(obj => ({ ...obj, appointment: appointment._id }))
        },
        filterFilePupilUploadsBySearch(appointment) {
            return appointment.pupilUpload.filter(   // Filter for the search string and map the appointment id on the upload (used for download, etc)
                u => u.title.toLowerCase().includes(this.searchString.toLowerCase()))
                .map(obj => ({ ...obj, appointment: appointment._id }))
        },
        async requestPerson() {
            if (this.accountRole === 'pupil') {
                this.person = await this.getMePupil();
            }
        },
        async executeSearch() {
            const searchResult = await this.searchVFS({
                folderType: this.privateFolder ? 'privateFolder' : this.teacherFolder ? 'teacherFolder' : '',
                query: this.searchString,
            })
            if (this.privateFolder) {
                this.searchResultPrivateFolder = searchResult;
            } else if (this.teacherFolder) {
                this.searchResultTeacherFolder = searchResult;
            } else if (this.groupFolder) {
              this.searchResultGroupFolder = searchResult;
            }
        },
        async updateCurrentFolder(vfs, direction) {
            this.currentFolder = vfs;
            this.folderDepth += direction;
        },

        // tracks the current y-offset of the scrollbar to adjust menus in subcomponents (context menu in FileSystemListElement)
        scrollEvent: function (event) {
            this.scrollTop = event.target.scrollTop;
        },
        scrolledToEnd(event) {
            this.endReached = true;
        },

        getTeacherDisplayName(teacher) {
            let prefix, name, lastName, wholeName, nameList = '';

            for (let i = 0; i < teacher.length; i++) {

                if (teacher[i].gender === 'm') {
                    prefix = 'Hr. ';
                } else if (teacher[i].gender === 'w') {
                    prefix = 'Fr. ';
                }

                if (teacher[i].name) {
                    name = teacher[i].name.slice(0, 1) + '. ';
                }

                lastName = teacher[i].lastName;
                wholeName = prefix + name + lastName;

                if (teacher.length > 1 && i > 0) {
                    nameList = nameList + ', ' + wholeName;
                } else {
                    nameList = wholeName;
                }
            }

            return nameList;
        },
        async requestTeacherFolder() {
            this.teacherFiles = await this.getVFSFolderUploads({ folderType: 'teacherFolder' });
        },
        async requestSlots() {
            this.slots = await this.getSlots();
        },

        getSlotTime(slotId) {
            const temp = this.slots.find((el) => el._id === slotId)
            return ("0" + new Date(temp.start).getHours()).slice(-2) + ":" + ("0" + new Date(temp.start).getMinutes()).slice(-2) + '-' + ("0" + new Date(temp.end).getHours()).slice(-2) + ":" + ("0" + new Date(temp.end).getMinutes()).slice(-2);
        },
        selectSubjectFolder(teacherId) {
            this.setFocusOn('currentPath');
            this.teacherId = teacherId;
            this.subjectFolder = true;
            this.requestSubjectFolder();
        },
        async requestSubjectFolder() {
            this.subjectFiles = await this.getSubjectFolder({
                subjectId: this.fach[0].schoolSubject._id,
                teacherId: this.teacherId
            });
            console.log('subjectFiles', this.subjectFiles)
        },

        // when resetting VFS to rootDir, bypass regular depth mutation directly to set value
        mutateFolderDepth(val) {
            if (val >= 0) {
                this.folderDepth = val;
            }
        },

        getSrc(icon) {
            return this[icon];
        },
        fileNotSupported(el) {
            this.showSnackbar({ message: "Dieses Dateiformat kann in eKlara nicht bearbeitet werden. Bitte lade die Datei herunter." });
        },
        count(el) {
            let returnValue = 0;
            if (this.accountRole === 'teacher') {
                el.forEach((element) => {
                    returnValue += element.pupilUpload.filter((file) => !file.seenByMe).length;
                });
            } else {
                el.forEach((element) => {
                    returnValue += element.teacherUploads.filter((file) => !file.seenByMe).length
                });
            }
            return returnValue;
        },

        getColor() {
            return this.thisSubject ? this['color' + this.thisSubject.color.substring(1)] : (this.thisAppointment ? this['color' + this.thisAppointment.schoolSubject.color.substring(1)] : this.colorb2b2b2)
        },

        async clickDownloadTeacherUpload(file) {
            try {
                file.seenByMe = true;
                const res = await backend.getTeacherUpload(file.appointment, file.file);
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function () {
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            } catch (e) {
                console.error(e);
            }
        },

        async clickDeleteUpload(subject) {
            let file = this.elementToDelete;
            const res = await backend.deleteUpload((file._id).toString());

            if (res.status === 204) {
                this.showSnackbar({ message: `Datei ${file.title} erfolgreich gelöscht`, color: "success" });

                //if delete pupil upload
                if (subject === 'pupil') {
                    let pos = this.pupileUploads.indexOf(file);
                    this.pupileUploads.splice(pos, 1);
                } else if (subject === 'teacher') { //else delete teacher upload
                    let pos = this.teacherUploads.indexOf(file);
                    this.teacherUploads.splice(pos, 1);
                }

                await this.requestAppointments();
                await this.selectSubject(this.schoolSubjectGroups.find((el) => {
                    return el[0].schoolSubject._id === this.fach[0].schoolSubject._id
                }));
            } else {
                this.showSnackbar({ message: "Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!" });
            }
        },

        async clickDownloadPupilUpload(file) {
            try {
                file.seenByMe = true;
                const res = await backend.getPupilUpload(
                    file.appointment, file.file
                );
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function () {
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);

            } catch (e) {
                console.error(e);
            }
        },

        async clickDownloadPupilCorrection(file) {
            let teacherCorrection = null;

            for (let i = 0; i < this.teacherUploads.length; i++) {
                if (this.teacherUploads[i].editParent) {
                    const index = file.editLeafs.indexOf(this.teacherUploads[i]._id);
                    if (index !== -1) {
                        teacherCorrection = this.teacherUploads[i];
                    }
                }
            }

            if (teacherCorrection) {
                teacherCorrection.seenByMe = true;

                const res = await backend.getTeacherUpload(file.appointment, teacherCorrection.file);

                fetch(res.url, {
                    method: 'GET',
                    headers: new Headers({
                        "Authorization": "Bearer " + this.token
                    })
                })
                    .then(response => response.blob())
                    .then(blob => {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = teacherCorrection.title;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        setTimeout(function () {
                            a.remove();
                            window.URL.revokeObjectURL(url);
                        }, 1000);
                    });
            }
        },

        async requestAppointments() {
            this.appointments = await this.getAppointments();
            this.schoolSubjectGroups = this.groupBy(this.appointments, 'schoolSubject');
        },

        async selectSubject(subject) {
            this.setFocusOn('currentPath');
            this.fach = subject;
            this.subjectMonthGroups = this.groupByMonth(this.fach);

            this.subjectMonthGroups.sort((a, b) => {
                let keyA = new Date(a[0].day),
                    keyB = new Date(b[0].day);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
        },
        async prepareUploadFromFileWidget(file) {

            let name;
            // legacy: teacherFolder uses nested file.file.*, privateFolder uses file.*
            if (file.uploadedName) {
                name = file.uploadedName;
                this.fileToUpload = file;

            } else if (file.file.uploadedName) {
                name = file.file.uploadedName;
                this.fileToUpload = file.file;
            } else {
                console.error('invalid file format');
            }
            let lastDot = name.lastIndexOf('.');
            this.newFileName = name.substring(0, lastDot);
            this.fileWidgetNameDialog = true;
        },

        //method that sends request to backend
        async uploadViaFileWidget(newFileName) {

            let data = {
                fileName: newFileName,
                fileId: this.fileToUpload._id,
                folderType: this.copyDestination + 'Folder',
            };

            const res = await backend.postVFSFolderUploadFromFileWidget(data);
            if (res.status === 201) {
                this.fileWidgetNameDialog = false;
                this.fileWidgetUploadDialog = false;
                if (this.copyDestination === 'private') {
                    this.currentFolderToRefresh = 'private';
                    this.showSnackbar({
                        message: "Die Datei '" + newFileName + "' wurde in ihren privaten Ordner kopiert"
                    });
                } else if (this.copyDestination === 'teacher') {
                    this.currentFolderToRefresh = 'teacher';
                    this.requestTeacherFolder();
                    this.showSnackbar({
                        message: "Die Datei '" + newFileName + "' wurde in den Lehrer Ordner kopiert"
                    });
                }
            }
        },

        async prepareUploadFromDocumentRegistry(el, url, destination) {
            let blob = await fetch(url).then(res => res.blob());
            let file = new File([blob], el.name, {type: "application/pdf" });
            await this.safeNewPrivateFolderFile({
                data: file,
                fileName: el.name,
            });
            this.showSnackbar({ message: "Die Datei wurde im privaten Ordner hinterlegt." });
        },

        toggleSorting() {
            if (this.privateFolder) {
                this.setPrivateFolderSortDescending(!this.privateFolderSortDescending);
            } else if (this.teacherFolder) {
                this.setTeacherFolderSortDescending(!this.teacherFolderSortDescending);
            } else if (this.docRegistry) {
                this.setDocRegistryFolderSortDescending(!this.docRegistryFolderSortDescending);
            } else if (this.groupFolder) {
                this.setGroupFolderSortDescending(!this.groupFolderSortDescending);
            }
        },

        async selectMonth(month) {
            this.setFocusOn('currentPath');
            this.month = month;

            this.subjectWeekGroups = this.groupByWeek(this.month);

            this.subjectWeekGroups.sort((a, b) => {
                let keyA = new Date(a[0].day),
                    keyB = new Date(b[0].day);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
        },

        async selectWeek(week) {
            this.setFocusOn('currentPath');
            this.week = week;

            this.subjectDateGroups = this.groupBy(this.week, 'day');

            this.subjectDateGroups.sort((a, b) => {
                let keyA = new Date(a[0].day),
                    keyB = new Date(b[0].day);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
        },

        async selectDay(day) {
            this.setFocusOn('currentPath');
            this.day = day;
            this.teacherUploads = [];
            this.pupileUploads = [];
            day.forEach((el) => {
                el.teacherUploads.forEach((element) => {
                    element['appointment'] = el._id
                });
                el.pupilUpload.forEach((element) => {
                    element['appointment'] = el._id
                });
                this.teacherUploads = this.teacherUploads.concat(el.teacherUploads);
                this.pupileUploads = this.pupileUploads.concat(el.pupilUpload);
            });
        },

        openEdit(name, id, uploadId, appointmentId) {
            const group = this.fach[0].schoolSubject.name;
            const color = this.fach[0].schoolSubject.color;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: 'editor',
                params: { mode: 'pupil' },
                query:
                {
                    group: group,
                    color: color,
                    aId: appointmentId,
                    title: fileTitle,
                    fId: fileId,
                    uploadid: uploadId
                }
            });
        },

        openAnsicht(name, id, uploadId, appointmentId, userGroup, fach = null) {
            let group, color;
            if (!fach) {
                group = this.fach[0].schoolSubject.name;
                color = this.fach[0].schoolSubject.color;
            } else {
                group = fach.schoolSubject.name;
                color = fach.schoolSubject.color;
            }
            const fileTitle = name;
            const fileId = id;
            const modes = {
                'teacher': 'creator',
                'pupil': 'viewer',
            }
            this.$router.push({
                name: 'editor',
                params: { mode: modes[this.accountRole] },
                query:
                {
                    group: group,
                    color: color,
                    aId: appointmentId,
                    title: fileTitle,
                    fId: fileId,
                    uploadid: uploadId,
                    ug: userGroup,
                    correction: true,
                }
            });
        },

        openEditTeacher(name, id, uploadId, appointmentId, fach = null) {
            let group, color;
            if (!fach) {
                group = this.fach[0].schoolSubject.name;
                color = this.fach[0].schoolSubject.color;
            } else {
                group = fach.schoolSubject.name;
                color = fach.schoolSubject.color;
            }
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: 'editor',
                params: { mode: 'teacher' },
                query:
                {
                    group: group,
                    color: color,
                    aId: appointmentId,
                    title: fileTitle,
                    fId: fileId,
                    uploadid: uploadId
                }
            });
        },

        openAnsichtTeacher(name, id, uploadId, appointmentId, ug, fach = null) {
            let group, color;
            if (!fach) {
                group = this.fach[0].schoolSubject.name;
                color = this.fach[0].schoolSubject.color;
            } else {
                group = fach.schoolSubject.name;
                color = fach.schoolSubject.color;
            }
            const fileTitle = name;
            const fileId = id;
            this.$router.push({
                name: 'editor',
                params: { mode: 'viewer' },
                query:
                {
                    group: group,
                    color: color,
                    aId: appointmentId,
                    title: fileTitle,
                    fId: fileId,
                    uploadid: uploadId,
                    correction: true,
                    ug: ug
                }
            });
        },

        isCorrectionAvailable(pupilFile) {
            for (let i = 0; i < this.teacherUploads.length; i++) {
                if (this.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(
                        this.teacherUploads[i]._id
                    );
                    if (index !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },

        getCurrentCorrection(pupilFile) {
            for (let i = 0; i < this.teacherUploads.length; i++) {
                if (this.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.teacherUploads[i]._id);
                    if (index !== -1) {
                        return this.teacherUploads[i];
                    }
                }
            }
            return null;
        },

        async play(file) {
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(file.appointment, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    this.videoUrl = url;

                });
        },

        async playPupil(file) {
            file.seenByMe = true;
            const res = await backend.getPupilUpload(file.appointment, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    this.videoUrl = url;

                });
        },

        async playAudio(file) {
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(file.appointment, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    this.audioUrl = url;

                });
        },
        async playAudioPupil(file) {
            file.seenByMe = true;
            const res = await backend.getPupilUpload(file.appointment, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    this.audioUrl = url;

                });
        },

        getFachByTeacher() {
            const temp = this.groupBy(this.fach, 'teacher');
            let returnValue = [];
            for (let i = 0; i < temp.length; i++) {
                if (temp[i][0].teachers.length === 1) {
                    if ((this.accountRole !== 'teacher' || temp[i][0].teachers[0].account === this.accountId) && !this.contains(returnValue, temp[i])) {
                        returnValue.push(temp[i]);
                    }
                } else {
                    temp[i][0].teachers.forEach((el, index) => {
                        let temp2 = { ...temp[i] };
                        temp2[0].teachers = [temp2[0].teachers[index]];
                        if ((this.accountRole !== 'teacher' || temp2[0].teachers[0].account === this.accountId) && !this.contains(returnValue, temp2)) {
                            returnValue.push(temp2);
                        }
                    })
                }
            }
            return returnValue;
        },
        contains(a, obj) {
            let i = a.length;
            while (i--) {
                if (a[i][0].teachers[0].account === obj[0].teachers[0].account) {
                    return true;
                }
            }
            return false;
        },
        groupBy(collection, prop) {
            let i = 0, val, index,
                values = [], result = [];
            for (; i < collection.length; i++) {
                val = collection[i][prop];
                index = values.findIndex((el) => {
                    return JSON.stringify(el) === JSON.stringify(val)
                });
                if (index > -1)
                    result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        },

        groupByMonth(collection) {
            let i = 0, val, index,
                values = [], result = [];
            for (; i < collection.length; i++) {
                val = collection[i]['day'];
                index = values.findIndex((el) => {
                    return JSON.stringify(new Date(el).getMonth()) === JSON.stringify(new Date(val).getMonth())
                });
                if (index > -1)
                    result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        },

        groupByWeek(collection) {
            let i = 0, val, index,
                values = [], result = [];
            for (; i < collection.length; i++) {
                val = collection[i]['day'];
                index = values.findIndex((el) => {
                    return JSON.stringify(new Date(el).getWeek()) === JSON.stringify(new Date(val).getWeek())
                });
                if (index > -1)
                    result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        },
        handleMoveElementClicked(file, destination) {

            this.copyDestination = destination;
            this.prepareUploadFromFileWidget(file);
        },
        async clickDownloadTeacherFolderUpload(file) {
            const res = await backend.getVFSFolderUpload(
                file._id
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = file.uploadedName;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    setTimeout(function () {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                });
        },
        // used from VFS folderType private & group
        openPrivateAnsicht(file, params) {
            const fileTitle = file.uploadedName;
            const fileId = file._id;
            const userGroup = 'private';

            let mode = 'creator';
            if (params) {
                if (this.accountRole === 'pupil' && !params.sharing ) {
                    mode = 'viewer';
                }
                if (this.accountRole === 'pupil' && params.sharing) {
                    mode = 'pupil';
                }
            }
            const query = {
                color: '#6996cd',
                title: fileTitle,
                fId: fileId,
                ug: userGroup,
                sharing: false,
            };
            if (params) {
                if (params.sharing) {
                    query.sharing = params.sharing;
                }
            } else {
                delete query.sharing;
            }
            this.$router.push({
                 name: 'editor',
                params: { mode },
                query,
            });
        },
        async clickDeleteTeacherFolderUpload(file) {
            await this.deleteVFSFolderUpload(file._id)
            this.requestTeacherFolder();
        },

        handleBackButton() {
            this.searchString = '';
            if (this.teacherFolder) {
                if (this.folderDepth <= 1) {
                    this.teacherFolder = false;
                }
                this.folderDepth--;
            }

            if (this.privateFolder) {
                if (this.folderDepth <= 1) {
                    this.privateFolder = false;
                }
                this.folderDepth--;
            }

            if (this.groupFolder) {
                if (this.folderDepth === 1) {
                    this.groupFolder = false;
                }
                this.folderDepth--;
            }

            if (this.docRegistry) {

                if (this.folderDepth <= 0) {
                    this.docRegistry = false;
                }
                this.folderDepth--;
            }

            (this.subjectFolder ? this.subjectFolder = false :
                (this.day ? this.day = null :
                    (this.week ? this.week = null :
                        (this.month ? this.month = null : this.fach = null))));
        },

        handleNewFolder() {
            if (this.privateFolder) {
                this.$refs.privateVFS.startFolderCreation();
            } else if (this.teacherFolder) {
                this.$refs.teacherVFS.startFolderCreation();
            } else if (this.groupFolder) {
                this.$refs.groupVFS.startFolderCreation();
            }
        },

        handleUploadButtonClick() {
            if (this.privateFolder) {
                this.clickPrivateUploadButton();
            } else if (this.teacherFolder) {
                this.clickTeacherFolderUploadButton();
            } else if (this.groupFolder) {
                this.clickGroupUploadButton();
            }
        },

        clickPrivateUploadButton() {
            this.toggleOpenAppointment(null);
            this.toggleCurrentUploadGroup('privateFolder');
            this.toggleCurrentUploadFolder(this.currentFolder);
            document.getElementById("uploadInput").click();
        },
        clickTeacherFolderUploadButton() {
            this.toggleOpenAppointment(null);
            this.toggleCurrentUploadGroup('teacherFolder');
            this.toggleCurrentUploadFolder(this.currentFolder);
            document.getElementById("uploadInput").click();
        },
        clickGroupUploadButton() {
            this.toggleOpenAppointment(null);
            this.toggleCurrentUploadGroup('groupFolder');
            this.toggleCurrentUploadFolder(this.currentFolder);
            document.getElementById("uploadInput").click();
        },
        
        async clickDownloadSubjectUpload(file) {
            const res = await backend.getSubjectFolderUpload(
                this.fach[0].schoolSubject._id,
                this.teacherId,
                file._id
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = file.uploadedName;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    setTimeout(function () {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                });
        },

        async clickDeleteSubjectUpload() {
            let file = this.elementToDelete;
            await this.deleteSubjectFile({
                subjectId: this.fach[0].schoolSubject._id,
                teacherId: this.teacherId,
                fileId: file._id
            })
            this.requestSubjectFolder();
        },

        clickSubjectUploadButton() {
            this.toggleOpenAppointment(this.fach[0].schoolSubject);
            this.toggleCurrentUploadGroup('subject');
            document.getElementById("uploadInput").click();
        },

        async subjectUploadInputChange() {
            const input = this.$refs.subjectUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf(".");
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.uploadSubjectFile(newFileName, fileExtension);
                };
                this.showNameFile = true;
            }
        },

        async uploadSubjectFile(newFileName, fileExtension) {
            const input = this.$refs.subjectUploadInput;
            const file = input.files[0];
            if (file) {

                if (file.size > 1000000000) {
                    this.showSnackbar({ message: "Datei zu groß, max. 1 GB" });
                    return;
                }

                const formData = new FormData();
                formData.append("file", file, `${newFileName}.${fileExtension}`);

                this.subjectXmlHttpRequest = backend.postSubjectFolderUpload(
                    this.fach[0].schoolSubject._id,
                    file
                );

                this.subjectXmlHttpRequest.onerror = (e) => {
                    console.error("Private upload error:", e);
                    this.showSubjectUploadProgress = false;
                    this.subjectXmlHttpRequest = null;
                    this.$refs.subjectUploadInput.value = "";
                };

                this.subjectXmlHttpRequest.onabort = (e) => {
                    console.warn("Private upload aborted");
                    this.showSubjectUploadProgress = false;
                    this.subjectXmlHttpRequest = null;
                    this.$refs.subjectUploadInput.value = "";
                };

                this.subjectXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.subjectUploadProgress = (e.loaded / e.total) * 100;
                });

                this.subjectXmlHttpRequest.addEventListener("load", (e) => {
                    if (this.subjectXmlHttpRequest.status !== 201) {
                        if (this.subjectXmlHttpRequest.status === 409) {
                            this.showSnackbar({ message: "Speicher überschritten. Datei konnte nicht gespeichert werden!" });
                        }
                        console.error(
                            "Private upload failed:",
                            this.subjectXmlHttpRequest.response
                        );
                    }

                    this.showSubjectUploadProgress = false;
                    this.requestSubjectFolder();
                    this.subjectXmlHttpRequest = null;
                    this.$refs.subjectUploadInput.value = "";
                });
                this.subjectUploadProgress = 0.0;
                this.showSubjectUploadProgress = true;
                this.subjectXmlHttpRequest.send(formData);
            }
        },
        async readWidgetInfoText() {
            if (!this.person.accessibility.screenreader) {
                if ('speechSynthesis' in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis Not Supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                    return
                }

                let text;
                if (this.accountRole === 'pupil') {
                    text = 'Im Dokumentenspeicher werden alle Dokumente von dir und deinem Lehrer gespeichert.';
                } else {
                    text = 'Im Dokumentenspeicher werden alle Dokumente von Ihnen und Ihren Schülern gespeichert.';
                }

                if (window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel();
                    this.showTranslation(false);
                } else {
                    if (this.targetLang !== 'de') {
                        text = await this.translateToTargetLang({
                            targetLang: this.targetLang, textToTranslate: text
                        });
                    }

                    this.setTranslatedText(text);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)

                    let msg = new SpeechSynthesisUtterance();
                    msg.text = text;
                    if (this.isLangPackageAvailable()) {
                        msg.lang = this.targetLang;
                        window.speechSynthesis.speak(msg);
                    }
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },

        setFocusOn(id) {
            setTimeout(() => {
                document.getElementById(id).focus();
            }, 10);
        },

        // open delete dialog but also save which file to delete and with which method
        openDeleteDialog(file, method) {
            this.deleteDialog = true;
            this.elementToDelete = file;
            this.deleteMethod = method;
        },

        confirmDelete() {
            switch (this.deleteMethod) {
                case 'subject':
                    this.clickDeleteSubjectUpload();
                    break;
                case 'pupil':
                    this.clickDeleteUpload('pupil');
                    break;
                case 'teacher':
                    this.clickDeleteUpload('teacher');
                    break;
                default:

            }

            this.deleteDialog = false;
            this.elementToDelete = null;
            this.deleteMethod = '';
        },
    }
}
</script>

<style lang="scss" scoped>
.whiteBg {
    background-color: #ffffff;
}

.icon {
    height: 20px;
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
        hue-rotate(36deg) brightness(104%) contrast(105%);
}

.toolbarButton {
    border-radius: 4px;
}

.widgetContainer {
    height: auto;
    width: 100%;
    max-width: 30vw;
    min-height: 80vh;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto auto 5em;
    display: flex;
    flex-direction: column;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}

.scroll-area {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
}

h1 {
    display: inherit;
    font-size: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    margin-right: inherit;
    font-weight: inherit;
}

.iconSelect {
    display: inline-flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: rgba(234, 234, 234, 0.7);
}

.greyText {
    color: var(--v-dunkelgrau-base);
}

/*.btnStyle {
    min-width: 36px;
    min-height: 36px;
    margin-top: -3px;
}*/

.materialTitle {
    cursor: pointer;
    height: 24px !important;
    width: 70%;
    padding-top: 4px;
    padding-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.squareBtn {
    min-width: 20px !important;
    min-height: 20px !important;
    width: 36px;
    height: 36px;
}

@media only screen and (max-width: 900px) {
    .widgetContainer {
        width: 90%;
        max-width: none;
    }
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}
</style>
