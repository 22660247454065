<template>
    <div>
        <v-row class="mx-0 mt-3 mb-4">
            <div style="height: auto; width: 90%; margin: auto; border-radius: 15px; box-shadow: 1px 5px 5px silver;">
                <div style=" background-color: var(--v-hellorange-base); border-radius: 15px 15px 0 0; font-size: larger; padding-top: 5px; padding-bottom: 20px; text-align: center; color: white;">
                    <div style="display: flex; width: 50%">
                        <img
                            :src="suchIcon"
                            style="height: 24px; margin-left: 24px; margin-top: 20px; filter: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(19%) hue-rotate(65deg) brightness(104%) contrast(105%);"
                            alt="Suchen-Symbol"
                        >

                        <v-autocomplete
                            v-model="search"
                            :items="groups"
                            item-text="name"
                            label="Klasse/Gruppe/AG suchen"
                            single-line
                            dark
                            hide-details
                            style="width: 50%"
                            class="pl-6"
                            no-results-text="Keine übereinstimmenden Klassen gefunden"
                            no-data-text="Keine Klassen gefunden"
                        />
                    </div>
                </div>
                <div
                    style="display: flex; font-size: larger; font-family: Roboto; margin-top: 24px; margin-left: 26px"
                    class=""
                >
                    <img
                        :src="gruppenIcon"
                        style="height: 24px; margin-right: 10px"
                        alt="Gruppen-Symbol"
                    >
                    Klassen
                </div>
                <v-data-table
                    :headers="headers"
                    :items="groups"
                    :search="search"
                    disable-pagination
                    hide-default-footer
                    fixed-header
                    sort-by="name"
                    :height="height"
                    class="ma-3"
                    locale="de-DE"
                    no-data-text="Keine Daten verfügbar"
                    no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{ row.item.name }}</td>
                            <td>
                                <v-btn
                                    class="mx-2"
                                    small
                                    color="#EAEAEA"
                                    style="border-radius: 5px; text-transform: inherit"
                                    elevation="1"
                                    light
                                    @click="edit(row.item)"
                                >
                                    Klasse bearbeiten
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <v-overlay
                    absolute
                    :value="showLoadingOverlay"
                    style="width: 100% !important;"
                >
                    <v-progress-circular
                        v-if="showLoadingOverlay"
                        indeterminate
                        size="100"
                    />
                </v-overlay>
            </div>
        </v-row>

        <!-- Klasse anlegen / bearbeiten -->
        <v-row
            id="edit"
            class="mx-0 my-3"
        >
            <div
                class="editContainer"
                style=""
            >
                <v-row
                    class="mx-0"
                    style="background-color: var(--v-hellorange-base); border-radius: 15px 15px 0 0"
                >
                    <v-col
                        style="color: white; font-size: larger;"
                        class="ma-1 ml-5"
                    >
                        <v-avatar
                            style="width: 24px; "
                            color="rgba(255,255,255,0.8)"
                        >
                            <img
                                :src="gruppenIcon"
                                alt="Klassen-Symbol"
                                class=""
                            >
                        </v-avatar>
                        {{ oldGroup ? 'Klasse bearbeiten' : 'Neue Klasse/Gruppe/AG anlegen' }}
                    </v-col>
                    <v-col
                        class="mr-6"
                        style="display: flex; justify-content: flex-end;"
                    >
                        <v-btn
                            color="#FFFFFF40"
                            x-small
                            class="mainFunctionButtons ml-3 my-auto"
                            :disabled="!oldGroup"
                            @click="deleteDialog = true"
                        >
                            <img
                                :src="loeschIcon"
                                alt="Löschen-Symbol"
                            >
                        </v-btn>
                        <v-tooltip
                            top
                            nudge-right="100"
                            :open-on-click="true"
                            :open-on-hover="false"
                            content-class="infoTextToolTip"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="#FFFFFF40"
                                    x-small
                                    class="mainFunctionButtons ml-3 my-auto"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <img
                                        :src="infoIcon"
                                        alt="Info-Symbol"
                                    >
                                </v-btn>
                            </template>
                            <span>Hier können Sie Klassen/Gruppen und vieles mehr anlegen. Eine Klasse kann z.B.
                                die 1a, eine Gruppe die Gruppe "Verwaltung", "Pfadfinder" oder ähnliches sein.</span>
                        </v-tooltip>
                        <v-btn
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#FFFFFF40"
                            dark
                            elevation="0"
                            @click="stopEdit"
                        >
                            <span>Abbrechen</span>
                        </v-btn>
                        <v-btn
                            v-if="oldGroup"
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#69CD70"
                            dark
                            elevation="4"
                            @click="editThisGroup"
                        >
                            <span>Speichern</span>
                        </v-btn>
                        <v-btn
                            v-else
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#69CD70"
                            dark
                            elevation="4"
                            @click="saveGroup"
                        >
                            <span>Speichern</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="mx-2 mb-2">
                    <v-row>
                        <div style="min-width: 25px!important;" />
                        <v-col>
                            <div style="font-weight: bold; margin-bottom: 10px">
                                Klassenndaten
                            </div>
                            <v-row>
                                <v-col cols="2">
                                    Name:
                                </v-col>
                                <v-col cols="7">
                                    <input
                                        v-model="name"
                                        :disabled="oldGroup"
                                        type="text"
                                        class="input"
                                        :class="{ errorInput: fields.name }"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="fields.name"
                                dense
                            >
                                <v-col
                                    class="pl-2; pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2">
                                    Leiter:
                                </v-col>
                                <v-col cols="7">
                                    <v-select
                                        v-model="leader"
                                        :items="teachers"
                                        item-value="_id"
                                        :item-text="item => item.name + ' ' + item.lastName"
                                        class="input"
                                        multiple
                                        hide-details
                                        return-object
                                        @change="validateInput"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <div style="font-weight: bold; margin-bottom: 33px" />
                            <v-dialog
                                :key="members._id"
                                v-model="dialog"
                                fullscreen
                                hide-overlay
                                transition="dialog-bottom-transition"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-row>
                                        <v-col cols="3">
                                            <v-col cols="3">
                                                Klassenmitglieder:
                                            </v-col>
                                            <v-col cols="1">
                                                <v-btn
                                                    class="mx-0 "
                                                    style="margin-top: -18px; max-height: 25px; color: black"
                                                    dark
                                                    small
                                                    color="#EAEAEA"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="() => { oldMembers = JSON.parse(JSON.stringify(members)) }"
                                                >
                                                    Auswählen
                                                </v-btn>
                                            </v-col>
                                        </v-col>
                                        <v-col cols="9">
                                            <v-col cols="2">
                                                <v-row
                                                    v-for="(item, index) in members.filter((el) => el.selected)"
                                                    :key="index"
                                                    style=" "
                                                >
                                                    <div
                                                        class="px-3 py-1 mb-1"
                                                        style="min-width: 200px; background-color: var(--v-hellgrau-base); border-radius: 8px"
                                                    >
                                                        {{ item.name + ' ' + item.lastName }}
                                                    </div>
                                                </v-row>
                                            </v-col>
                                        </v-col>
                                    </v-row>
                                </template>
                                <v-card>
                                    <v-toolbar
                                        dark
                                        color="#6995CD"
                                    >
                                        <v-toolbar-title>
                                            <v-text-field
                                                v-model="search2"
                                                prepend-icon="mdi-magnify"
                                                label="Nach Personen suchen"
                                                single-line
                                                dark
                                                hide-details
                                                class="pl-6"
                                                style="min-width: 300px"
                                            />
                                        </v-toolbar-title>
                                        <v-spacer />
                                        <v-toolbar-items>
                                            <v-btn
                                                dark
                                                elevation="3"
                                                style="background-color: #3BAA69; color: white; max-height: 40px; margin-top: 10px; border-radius: 8px"
                                                text
                                                @click="() => { dialog = false; }"
                                            >
                                                Auswählen
                                            </v-btn>
                                            <!--                                                    <v-btn dark elevation="3" style="background-color: #3BAA69; color: white; max-height: 40px; margin-top: 10px; border-radius: 8px" text v-else @click="saveGroup">Speichern</v-btn>-->
                                            <v-btn
                                                x-small
                                                class="mx-4"
                                                elevation="3"
                                                style="background-color: #e6221e80; border-radius: 8px; margin-top: 10px; width: 40px; max-height: 40px"
                                                @click="() => { members = JSON.parse(JSON.stringify(oldMembers)); dialog = false; }"
                                            >
                                                <img
                                                    :src="abbrechenIcon"
                                                    style="height: 40px; filter: brightness(1000%)"
                                                    alt="abbrechen-Symbol"
                                                >
                                            </v-btn>
                                        </v-toolbar-items>
                                    </v-toolbar>

                                    <v-data-table
                                        :headers="headers2"
                                        :items="members"
                                        :search="search2"
                                        disable-pagination
                                        hide-default-footer
                                        fixed-header
                                        class="ma-3"
                                        locale="de-DE"
                                        no-data-text="Keine Daten verfügbar"
                                        no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                                    >
                                        <template v-slot:item="row">
                                            <tr>
                                                <td>{{ row.item.lastName }}</td>
                                                <td>{{ row.item.name }}</td>
                                                <td>{{ row.item.gender }}</td>
                                                <td>{{ row.item.note }}</td>
                                                <td>
                                                    <v-checkbox
                                                        v-model="row.item.selected"
                                                        class="mx-2"
                                                        rounded
                                                        small
                                                        light
                                                    />
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-row>

        <v-dialog
            v-model="deleteDialog"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    Klasse löschen?
                </v-card-title>
                <v-card-text>
                    Sind Sie sicher, dass Sie die Klasse löschen möchten?
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color=""
                        text
                        @click="deleteDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="red red-1"
                        text
                        @click="() => {deleteThisGroup(); deleteDialog = false}"
                    >
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
    import * as backend from "../../api/backend";
    import suchIcon from '../../assets/Icons/suche-26.svg'
    import abbrechenIcon from "../../assets/Icons/abbrechen-08.svg";
    import gruppenIcon from "../../assets/Icons/gruppen-25.svg";
    import infoIcon from "../../assets/Icons/ausrufezeichen-62.svg";
    import loeschIcon from '../../assets/Icons/papierkorb-verwaltung-109.svg';

    export default {
        name: "Groups",
        components: {

        },
        props: {

        },
        data() {
            return {
                showLoadingOverlay: false,
                dialog: false,

                name: '',
                leader: [],
                fields: {
                    name: false,
                    leader: false,
                },

                deleteDialog: false,

                oldGroup: null,
                height: 0,
                search: '',
                headers: [
                    { text: 'Name', value: 'name' },
                    { text: '', sortable: false},
                ],


                search2: '',
                headers2: [
                    { text: 'Name', value: 'lastName' },
                    { text: 'Vorname', value: 'name' },
                    { text: 'Geschlecht', value: 'gender' },
                    { text: 'Notiz', value: 'note' },
                    { text: 'Ausgewählt', value: 'selected'},
                ],
                members: [],
                oldMembers: [],
                groups: [],

                teachers: [],
                suchIcon,
                gruppenIcon,
                infoIcon,
                abbrechenIcon,
                loeschIcon
            };
        },
        computed: {
            ...mapGetters("groups", ['getGroupStatus']),
            ...mapGetters('pupils', [ 'getPupilListStatus' ]),
            ...mapGetters('teachers', [ 'getTeacherStatus' ])
        },
        watch: {
            getGroupStatus(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.requestGroups();
                }
            },
            getPupilListStatus(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.requestPupils();
                }
            },
            getTeacherStatus(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.requestTeachers();
                }
            },
        },
        async created() {
            this.height = window.innerHeight * 0.4
        },
        async mounted() {
            this.showLoadingOverlay = true;
            this.requestGroups();
            this.requestPupils();
            this.requestTeachers();
        },
        methods: {
            ...mapActions("pupils", ["getPupils", 'setPupilListStatus']),
            ...mapActions("teachers", ["getTeachers", 'setTeachersInvalidStatus']),
            ...mapActions("groups", ["getGroups","createGroup", "editGroup", "deleteGroup", 'setGroupInvalidStatus']),
            ...mapMutations("snackbar", ["showSnackbar"]),

            async requestPupils() {
                this.members = await this.getPupils();
                this.setPupilListStatus(false);
            },
            async requestTeachers() {
                this.showLoadingOverlay = true;
                this.teachers = await this.getTeachers();
                this.showLoadingOverlay = false;
                this.setTeachersInvalidStatus(false);
            },

            //#region Group Server Methods (Get, Patch, Delete)
            async requestGroups() {
                const response = await backend.getGroups();
                const newArray = await response.json();
                this.groups = newArray.filter(el => el.name !== 'Alle');
                this.setGroupInvalidStatus(false);
            },
            async deleteThisGroup() {
                try {

                    this.showLoadingOverlay = true;
                    let response = await this.deleteGroup(this.oldGroup._id);
                    if (response) {
                        this.requestGroups();
                        this.setGroupInvalidStatus(true);
                        this.setPupilListStatus(true);
                        this.setTeachersInvalidStatus(true);
                        this.stopEdit();
                        this.showSnackbar({ message: 'Gespeichert!'});
                    } else {
                        this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});

                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    this.showLoadingOverlay = false;
                }
            },
            async editThisGroup() {
                try {
                    this.dialog = false;
                    this.showLoadingOverlay = true;
                    if (!this.validateInput()) {
                        this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                        return;
                    }
                    let participants = this.members.filter((el) => {
                        return el.selected;
                    });

                    let sendParticipants = [];
                    participants.forEach((el) => {
                        sendParticipants.push(el._id)
                    });

                    let data = {
                        _id: this.oldGroup._id,
                        name: this.name,
                        leaders: this.leader.map(leader => leader._id),
                        participants: sendParticipants,
                    };
                    let response = await this.editGroup(data);
                    if (Number.isInteger(response)) {
                        if (response === 409) {
                            this.showSnackbar({ message: 'Klassenname bereits vergeben. Bitte geben Sie einen anderen Namen ein!', color: 'error'});                        } else {
                            this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                        }
                    } else {
                        this.requestGroups();
                        this.setPupilListStatus(true);
                        this.setTeachersInvalidStatus(true);
                        this.setGroupInvalidStatus(true);
                        this.stopEdit();
                        this.showSnackbar({ message: 'Gespeichert!'});
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    this.showLoadingOverlay = false;
                }
            },
            async saveGroup() {
                try {
                    this.dialog = false;
                    this.showLoadingOverlay = true;
                    if (!this.validateInput()) {
                        this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                        return;
                    }
                    let participants = this.members.filter((el) => {
                        return el.selected;
                    });

                    let sendParticipants = [];
                    participants.forEach((el) => {
                        sendParticipants.push(el._id)
                    });

                    let data = {
                        name: this.name,
                        leaders: this.leader.map(leader => leader._id),
                        participants: sendParticipants,
                    };
                    let response = await this.createGroup(data);

                    if (Number.isInteger(response)) {
                        if (response === 409) {
                            this.showSnackbar({ message: 'Klassenname bereits vergeben. Bitte geben Sie einen anderen Namen ein!', color: 'error'});                        } else {
                            this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                        }
                    } else {
                        this.requestGroups();
                        this.setGroupInvalidStatus(true);

                        this.stopEdit();
                        this.showSnackbar({ message: 'Gespeichert!'});
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    this.showLoadingOverlay = false;
                }
            },
            //#endregion

            //#region Group UI Methods
            async edit(group) {
                this.$vuetify.goTo('#edit');
                this.oldGroup = group;
                this.name = group.name;
                this.members.forEach((el) => {
                    el.selected = false;
                });
                this.leader = group.leaders.map((leader) => {
                    return this.teachers.find(el => el._id === leader);
                });
                group.participants.forEach((el) => {
                    this.members
                        .find(element => el.account === element.account)
                        .selected = true;
                })
            },
            stopEdit() {
                this.name = '';
                this.leader = [];
                this.members.forEach((el) => {
                    el.selected = false;
                });
                this.oldGroup = null;

                this.fields.name = false;
                this.fields.leader = false;
            },
            validateInput() {
                let result = true;

                this.fields.name = false;
                this.fields.leader = false;

                if (this.name.replace(/\s/g, '') === '') {
                    this.fields.name = true;
                    result = false;
                }

                if (!this.leader) {
                    this.fields.leader = true;
                    result = false;
                }

                return result;
            },
        },
    };
</script>


<style lang="scss" scoped>
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr {
        height: 40px;
    }

    table tr th:first-child,
    table tr td:first-child {
        width: 10px !important;
    }

    table tr th,
    table tr td {
        width: 9em;
        border: none;
    }
    table, tr, td {
        border: none;
    }

    input[type="date"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-height: 1.6rem;
    }

    .editContainer {
        min-height: 40vh;
        height: fit-content;
        width: 90%;
        border-radius: 15px;
        box-shadow: 1px 5px 5px silver;
        margin: auto;
        margin-bottom: 5em
    }

    .mainControlButtons {
        font-weight: 300;
        font-size: 10px;
        border-radius: 8px;

        & span {
            line-height: 0 !important;
        }
    }

    .mainFunctionButtons {
        width: 38px !important;
        height: 38px !important;
        border-radius: 8px;

        img {
            width: 27px !important;
            height: 27px !important;
            filter: brightness(1000%);
        }
    }

    .input{
        display: block;
        width: 100%;
        max-width: 100%;
        border-radius: 5px;
        border-width: 0;
        color: black;
        padding: 1px 5px 1px 5px;
        border: none !important;
        background-color: var(--v-hellgrau-base);
        overflow: hidden;
        appearance:none
    }

    .input[disabled]{
        color: grey;
        background-color: #CACACA;
    }

    .errorInput {
        border: 2px solid red !important;
    }

    .infoTextToolTip {
        max-width: 250px;
        background-color: var(--v-blau-base);
    }
</style>
