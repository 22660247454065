<template>
    <!--Bottom Menu-->
    <nav
        role="navigation"
    >
        <!-- Floating Text to Speech button to be in front of an overlay -->
        <div
            v-if="textSelected"
            class="btmNav"
            style="z-index: 999; background-color: transparent"
        >
            <div
                class="d-flex flex-row justify-space-around align-center"
                style="height: 100%; width: 100%; background-color: transparent!important;"
            >
                <div
                    v-for="item in btmNavItems"
                    :key="item.name"
                    class="btmNavBtn pa-0"
                    style="background-color: transparent !important;pointer-events: none;"
                >
                    <!--textToSpeech when text is selected-->
                    <v-btn
                        elevation="0"
                        small
                        class="btmNavBtn pa-0"
                        style="z-index: 999"
                        @click.self="clickTTSFloat"
                    >
                        <TTSSmall
                            ref="ttsFloat"
                            :icon-size="'35px'"
                            alt="Vorlesen"
                        />
                    </v-btn>
                </div>
            </div>
        </div>

        <!--bottom navigation bar, used only for mobile devices-->
        <div
            class="btmNav"
        >
            <div
                class="d-flex flex-row justify-space-around align-center"
                style="height: 100%; width: 100%"
            >
                <v-tooltip
                    v-for="item in btmNavItems"
                    :key="item.name"
                    :disabled="!showTooltips"
                    top
                >
                    <template #activator="{ on }">
                        <v-btn
                            elevation="0"
                            small
                            class="btmNavBtn pa-0"
                            :style="currSlide === item.goToIndex && !btmNavMenu ? 'background-color:' + item.bgColor + '!important;' : ''"
                            v-on="on"
                            @click="switchSlide(item.goToIndex)"
                        >
                            <img
                                :src="item.icon"
                                class="btmNavIcon"
                                :alt="item.alt"
                                :style="currSlide === item.goToIndex && !btmNavMenu ? 'filter: brightness(1000%);' : item.color"
                            >
                        </v-btn>
                    </template>
                    <span>{{ item.alt }}</span>
                </v-tooltip>

                <!--menu activator-->
                <v-tooltip
                    v-if="!textSelected"
                    :disabled="!showTooltips"
                    top
                >
                    <template #activator="{ on }">
                        <v-btn
                            ref="btmMenuBtn"
                            elevation="0"
                            small
                            class="btmNavBtn pa-0"
                            :style="btmNavMenu ? 'background-color: #6995CD !important;' : ''"
                            v-on="on"
                            @click="handleBtmMenuClick"
                        >
                            <img
                                :src="menuIcon"
                                class="btmNavIcon"
                                alt="Ausklappbares Menü"
                                :style="btmNavMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'"
                            >
                        </v-btn>
                    </template>
                    <span>Ausklappbares Menü</span>
                </v-tooltip>

                <!--textToSpeech when text is selected-->
                <v-tooltip
                    v-else
                    :disabled="!showTooltips"
                    top
                >
                    <template #activator="{ on }">
                        <v-btn
                            elevation="0"
                            small
                            class="btmNavBtn pa-0"
                            style="z-index: 201"
                            v-on="on"
                            @click.self="clickTTS"
                        >
                            <TTSSmall
                                ref="tts"
                                :icon-size="'35px'"
                                alt="Vorlesen"
                            />
                        </v-btn>
                    </template>
                    <span>Vorlesen</span>
                </v-tooltip>

                <!--menu content-->
                <div
                    ref="btmMenu"
                    v-closable="{ exclude: ['btmMenuBtn', 'btmMenu'], handler: 'onClose' }"
                    class="d-flex flex-column pa-2 btmNavMenu"
                    :class="btmNavMenu ? '' : 'hideMe'"
                    :style="btmNavLangMenu ? 'box-shadow: -5px -2px 4px silver;' : 'border-top-left-radius: 12px;'"
                >
                    <div class="d-flex pa-2 mb-2">
                        <div
                            :style="getGenderColor()"
                            class="roundProfilePicBtmNav d-flex justify-center align-center"
                        >
                            <img
                                :src="profileIcon"
                                class="iconToWhite"
                                height="20"
                                width="20"
                                alt
                            >
                        </div>

                        <p
                            class="ml-2 ma-0 ellipsis greyText"
                        >
                            {{ person ? person.name : '' }}
                        </p>
                    </div>

                    <!-- Klassenraum Button -->
                    <JoinKlassenraumButton :bottom-nav="true" />

                    <!-- opens profile page -->
                    <v-btn
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn pointer preventColorInvert"
                        depressed
                        @click="$router.push('/profile-managment')"
                    >
                        <img
                            :src="profileOutlineIcon"
                            height="25"
                            alt
                        >
                        <p class="ml-2 ma-0 text-capitalize">
                            Profil
                        </p>
                    </v-btn>

                    <!--activator language menu-->
                    <div
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn pointer preventColorInvert"
                        style="background-color: #f5f5f5"
                        @click="btmNavLangMenu = !btmNavLangMenu"
                    >
                        <img
                            :src="btmNavLangMenu ? abbrechenIcon : currentTTSLangImg"
                            :alt="btmNavLangMenu ? 'Abbrechensymbol' : 'Sprachausgabesymbol'"
                            height="25"
                        >
                        <p class="ml-2 ma-0">
                            Sprache
                        </p>
                    </div>

                    <!--language menu content-->
                    <div
                        :class="btmNavLangMenu ? '' : 'hideMe'"
                        class="pa-2 pr-0 btmNavLangMenu"
                    >
                        <!--1st lang row-->
                        <div class="d-flex">
                            <v-btn
                                v-for="lang in btmNavLangItems.slice(0, 3)"
                                :key="lang.countryCode"
                                class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert"
                                :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')"
                                @click="changeLangBtmNav(lang.countryCode, lang.icon)"
                            >
                                <img
                                    v-if="lang.icon !== ''"
                                    :src="lang.icon"
                                    height="25"
                                    width="25"
                                    :alt="lang.alt"
                                >
                                <div
                                    v-else
                                    class="btmNavLangNoIcon"
                                />
                            </v-btn>
                        </div>

                        <!--2nd lang row-->
                        <div class="d-flex">
                            <v-btn
                                v-for="lang in btmNavLangItems.slice(3, 6)"
                                :key="lang.countryCode"
                                class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert"
                                :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')"
                                @click="changeLangBtmNav(lang.countryCode, lang.icon)"
                            >
                                <img
                                    v-if="lang.icon !== ''"
                                    :src="lang.icon"
                                    height="25"
                                    width="25"
                                    :alt="lang.alt"
                                >
                                <div
                                    v-else
                                    class="btmNavLangNoIcon"
                                />
                            </v-btn>
                        </div>

                        <!--3rd lang row-->
                        <div class="d-flex">
                            <v-btn
                                v-for="lang in btmNavLangItems.slice(6, 9)"
                                :key="lang.countryCode"
                                class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert"
                                :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')"
                                @click="changeLangBtmNav(lang.countryCode, lang.icon)"
                            >
                                <img
                                    v-if="lang.icon !== ''"
                                    :src="lang.icon"
                                    height="25"
                                    width="25"
                                    :alt="lang.alt"
                                >
                                <div
                                    v-else
                                    class="btmNavLangNoIcon"
                                />
                            </v-btn>
                        </div>

                        <!--4th lang row-->
                        <div class="d-flex">
                            <v-btn
                                v-for="lang in btmNavLangItems.slice(9, 12)"
                                :key="lang.countryCode"
                                class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert"
                                :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')"
                                @click="changeLangBtmNav(lang.countryCode, lang.icon)"
                            >
                                <img
                                    v-if="lang.icon !== ''"
                                    :src="lang.icon"
                                    height="25"
                                    width="25"
                                    :alt="lang.alt"
                                >
                                <div
                                    v-else
                                    class="btmNavLangNoIcon"
                                />
                            </v-btn>
                        </div>

                        <!--5th lang row, does not have mb-2 class-->
                        <div class="d-flex">
                            <v-btn
                                v-for="lang in btmNavLangItems.slice(12, 15)"
                                :key="lang.countryCode"
                                class="btmNavLangBtn d-flex mr-2 pa-2 preventColorInvert"
                                :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')"
                                @click="changeLangBtmNav(lang.countryCode, lang.icon)"
                            >
                                <img
                                    v-if="lang.icon !== ''"
                                    :src="lang.icon"
                                    height="25"
                                    width="25"
                                    :alt="lang.alt"
                                >
                                <div
                                    v-else
                                    class="btmNavLangNoIcon"
                                />
                            </v-btn>
                        </div>

                        <!--6th lang row, does not have mb-2 class-->
                        <div class="d-flex">
                            <div
                                v-for="lang in btmNavLangItems.slice(16, 18)"
                                :key="lang.countryCode"
                                class="btmNavLangBtn d-flex mr-2 pa-2 preventColorInvert"
                                :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg' : '')"
                                @click="changeLangBtmNav(lang.countryCode, lang.icon)"
                            >
                                <img
                                    v-if="lang.icon !== ''"
                                    :alt="lang.alt"
                                    :src="lang.icon"
                                    height="25"
                                    width="25"
                                >
                                <div
                                    v-else
                                    class="btmNavLangNoIcon"
                                />
                            </div>
                        </div>
                    </div>

                    <v-btn
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn pointer"
                        depressed
                        @click="reloadPage(); closeBtmNavMenus()"
                    >
                        <img
                            :src="aktualisierenIcon"
                            alt
                            height="25"
                        >
                        <p
                            class="ml-2 ma-0 text-capitalize"
                            style="font-size: x-small"
                        >
                            Aktualisieren
                        </p>
                    </v-btn>

                    <v-btn
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn pointer"
                        depressed
                        @click="$emit('startTut')"
                    >
                        <img
                            :src="tutorialIcon"
                            alt
                            height="25"
                        >
                        <p class="ml-2 ma-0 text-capitalize">
                            Tutorial
                        </p>
                    </v-btn>

                    <v-btn
                        v-if="sehen"
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn pointer"
                        depressed
                        @click="toggleMagnifier(); closeBtmNavMenus();"
                    >
                        <img
                            :src="lupenIcon"
                            height="25"
                            alt
                        >
                        <p class="ml-2 ma-0 text-capitalize">
                            Lupe
                        </p>
                    </v-btn>
                    <v-btn
                        v-ripple
                        style="width: 100%;"
                        aria-label="F A Q"
                        class="d-flex mb-2 pa-2 btmNavMenuBtn pointer"
                        depressed
                        @click="$router.push('/faq')"
                    >
                        <img
                            :src="fragezeichenIcon"
                            height="25"
                            alt
                        >
                        <p class="ml-2 ma-0 text-uppercase">
                            FAQ
                        </p>
                    </v-btn>
                    <v-btn
                        v-ripple
                        class="d-flex pa-2 btmNavMenuBtn pointer"
                        depressed
                        @click="clickLogoutUser(); closeBtmNavMenus()"
                    >
                        <img
                            :src="logoutIcon"
                            alt
                            height="25"
                        >
                        <p
                            class="ml-2 ma-0 text-capitalize"
                            style="color: var(--v-error-base)"
                        >
                            Abmelden
                        </p>
                    </v-btn>
                </div>
            </div>
        </div>
        <v-overlay
            v-if="btmNavMenu"
            style="z-index: 10"
            overlay-opacity="0.8"
            @click.native="closeTopMenus"
        />
    </nav>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

import TTSSmall from "../../../components/TextToSpeechSmall";
import JoinLehrerzimmerButton from "../../../components/Lehrer/JoinLehrerzimmerButton";
import JoinKlassenraumButton from "../../../components/JoinKlassenraumButton";
import JoinSprechzimmerButton from "../../../components/JoinSprechzimmerButton";
import JoinExternalButton from "../../../components/JoinExternal/JoinExternalButton";

import * as backend from "../../../api/backend";

import setGlobalButtonContrast from "../../../util/accessibilityButtonContrast";

import deFlagIcon from '../../../assets/Icons/deutsch-59.svg'
import frFlagIcon from '../../../assets/Icons/französisch-60.svg'
import enFlagIcon from '../../../assets/Icons/englisch-61.svg'
import trFlagIcon from '../../../assets/Icons/türkisch-97.svg'
import arFlagIcon from '../../../assets/Icons/syrisch-98.svg'
import ruFlagIcon from '../../../assets/Icons/russisch.svg'
import profileIcon from "../../../assets/Icons/profile.svg";
import profileOutlineIcon from "../../../assets/Icons/profil-schueler.svg";
import left from '../../../assets/Icons/links-filled-10.svg'
import right from '../../../assets/Icons/rechts-filled-11.svg'
import logoutIcon from '../../../assets/Icons/beitretensvg-red-06.svg'
import stundenplanIcon from '../../../assets/Icons/timetable.svg'
import brettIcon from '../../../assets/Icons/brett.svg'
import chatIcon from '../../../assets/Icons/chat-alternative-filled-102.svg'
import dateienIcon from '../../../assets/Icons/file.svg'
import menuIcon from '../../../assets/Icons/einstellungen-neu-103.svg'
import tutorialIcon from '../../../assets/Icons/tutorial.svg'
import fragezeichenIcon from '../../../assets/Icons/question-mark.svg'
import abbrechenIcon from '../../../assets/Icons/abbrechen-08.svg'
import lupenIcon from '../../../assets/Icons/lupe-18.svg'
import aktualisierenIcon from '../../../assets/Icons/aktualisieren-107.svg'
import logo from '../../../assets/logo_klassenraum.png'
import mikrofonIcon from '../../../assets/Icons/mikrofon-22.svg'
import daumenHochIcon from '../../../assets/Icons/daumen-hoch.svg'
import keyboardIcon from '../../../assets/Icons/keyboard-24px.svg'
import menuMetacom from '../../../assets/METACOM/Header/menueSW.png'
import lupeMetacom from '../../../assets/METACOM/Allgemein/suchenlupe.png'
import tastaturTippenMetacom from '../../../assets/METACOM/Allgemein/tastaturtippen.png'
// This variable will hold the reference to document's click handler, used for the v-directive closable
let handleOutsideClick

export default {
    name: "MenuSmallScreen",
    components: {
        TTSSmall,
        JoinKlassenraumButton,
    },
    directives: {
        //directive from https://tahazsh.com/detect-outside-click-in-vue with changes from Peter Means (in comments)
        closable: {
            bind(el, binding, vnode) {
                // Here's the click/touchstart handler (it is registered below)
                handleOutsideClick = (e) => {
                    e.stopPropagation()
                    // Get the handler method name and the exclude array from the object used in v-closable
                    const { handler, exclude } = binding.value

                    // This variable indicates if the clicked element is excluded
                    let clickedOnExcludedEl = false
                    exclude.forEach(refName => {
                        // We only run this code if we haven't detected any excluded element yet
                        if (!clickedOnExcludedEl) {
                            // Get the element using the reference name
                            const excludedEl = vnode.context.$refs[refName];
                            let excludedDomEl = null;
                            if (excludedEl) {
                                // If it's a vue component grab the element, otherwise it is the element
                                excludedDomEl = excludedEl.$el ? excludedEl.$el : excludedEl;
                                clickedOnExcludedEl = excludedDomEl.contains(e.target);
                            }
                        }
                    })
                    // We check to see if the clicked element is not the dialog element and not excluded
                    if (!el.contains(e.target) && !clickedOnExcludedEl) {
                        // If the clicked element is outside the dialog and not the button,
                        // then call the outside-click handler from the same component this directive is used in
                        vnode.context[handler]()
                    }
                }
                // Register click/touchstart event listeners on the whole page
                document.addEventListener('click', handleOutsideClick)
                document.addEventListener('touchstart', handleOutsideClick)
            },

            unbind() {
                // If the element that has v-closable is removed, then
                // unbind click/touchstart listeners from the whole page
                document.removeEventListener('click', handleOutsideClick)
                document.removeEventListener('touchstart', handleOutsideClick)
            }
        }
    },
    props: {
        isRunning: { type: Boolean, required: false, default: false },
        currSlide: { type: Number, required: true, }
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            menu: false,
            btmNavMenu: false,
            btmNavLangMenu: false,
            date: new Date(),
            appointments: [],
            person: null,
            currentTTSLangImg: deFlagIcon,
            page: 0,

            babyView: null,             //for displaying simplified view
            sehen: null,
            hoeren: null,
            motorik: null,

            callbackMethod: null,       //joinBBB method passed up from Fach-component

            slideChangeAllowed: true,   //to prevent spamming of btmNavBar, which would bring highlighting of btmNavIcons and currently displayed widget out of sync

            textSelected: false,
            selectedText: '',

            closeChat: false,
            closeBrett: false,
            buttonFocusLeft: false,
            buttonFocusRight: false,

            voiceControlDialog: false,
            voiceControlSuccessful: false,

            setGlobalButtonContrast,

            loaded: false,
            deFlagIcon,
            frFlagIcon,
            trFlagIcon,
            arFlagIcon,
            enFlagIcon,
            ruFlagIcon,
            profileIcon,
            profileOutlineIcon,
            left,
            right,
            logoutIcon,
            menuIcon,
            tutorialIcon,
            fragezeichenIcon,
            lupenIcon,
            abbrechenIcon,
            aktualisierenIcon,
            logo,
            mikrofonIcon,
            daumenHochIcon,
            keyboardIcon,
            menuMetacom,
            lupeMetacom,
            tastaturTippenMetacom,

            btmNavItems: [
                { name: 'stundenplan', icon: stundenplanIcon, alt: 'Uhr', goToIndex: 0, bgColor: '#6995CD', color: 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);' },
                { name: 'brett', icon: brettIcon, alt: 'Schwarzes Brett', goToIndex: 1, bgColor: '#9C9CA0', color: 'filter: brightness(0) saturate(100%) invert(47%) sepia(0%) saturate(98%) hue-rotate(150deg) brightness(90%) contrast(86%);' },
                { name: 'chat', icon: chatIcon, alt: 'Chat', goToIndex: 2, bgColor: '#8CBD46', color: 'filter: brightness(0) saturate(100%) invert(70%) sepia(22%) saturate(1027%) hue-rotate(43deg) brightness(94%) contrast(90%);' },
                { name: 'dateien', icon: dateienIcon, alt: 'Datei', goToIndex: 3, bgColor: '#F49F31', color: 'filter: brightness(0) saturate(100%) invert(67%) sepia(71%) saturate(1286%) hue-rotate(338deg) brightness(104%) contrast(91%);' },
            ],
            btmNavItemsSimple: [
                { name: 'stundenplan', icon: stundenplanIcon, goToIndex: 0, bgColor: '#6995CD', color: 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);', alt: 'Stundenplan' },
                { name: 'hausaufgaben', icon: dateienIcon, goToIndex: 1, bgColor: '#F49F31', color: 'filter: brightness(0) saturate(100%) invert(67%) sepia(71%) saturate(1286%) hue-rotate(338deg) brightness(104%) contrast(91%);', alt: 'Hausaufgaben' },
                { name: 'chat', icon: chatIcon, goToIndex: 2, bgColor: '#8cbd46', color: 'filter: brightness(0) saturate(100%) invert(70%) sepia(22%) saturate(1027%) hue-rotate(43deg) brightness(94%) contrast(90%);', alt: 'Chat' },
            ],
            btmNavLangItems: [
                /*1st lang row*/
                { countryCode: 'de', icon: deFlagIcon, alt: 'Deutsche Flagge' },
                { countryCode: 'en', icon: enFlagIcon, alt: 'Englische Flagge' },
                { countryCode: 'fr', icon: frFlagIcon, alt: 'Französische Flagge' },
                /*2nd lang row*/
                { countryCode: 'tr', icon: trFlagIcon, alt: 'Türkische Flagge' },
                { countryCode: 'ar', icon: arFlagIcon, alt: 'Arabische Flagge' },
                { countryCode: 'ru', icon: ruFlagIcon, alt: 'Russische Flagge' },
                /*3rd lang row*/
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                /*4th lang row*/
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                /*5th lang row*/
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                /*6th lang row*/
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                { icon: '', alt: '' },
            ]
        };
    },
    computed: {
        ...mapGetters("auth", ["accountRole"]),
        ...mapState("auth", ["stayLoggedIn"]),
        ...mapState("speechControl", ["speechControl"]),
        ...mapState("speechControl", ["word"]),
        ...mapState("magnifier", ["magnifier"]),
        ...mapState("util", ["keyboard", "bonusFunctions", 'browserSupported']),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips", "tabPressedOnce"]),
        ...mapState('translation', ['targetLang']),

        showMenuTooltips() {
            return (this.shortcutTooltips);
        }
    },
    watch: {
        word(newVal) {
            if (newVal === 'sprachsteuerung') {
                this.voiceControlSuccessful = true;
                setTimeout(() => {
                    this.voiceControlDialog = false;
                    this.voiceControlSuccessful = false;
                }, 2000);
            }
            if (newVal === 'abmelden') {
                this.logoutUser();
                this.$router.push({ name: 'login' });
            }
        },
        windowWidth(val) {
            if (val < 900) {
                this.turnOffMagnifier()
            }
        },
        selectedText() {
            if (this.selectedText.length) {
                if (!this.textSelected) {
                    this.textSelected = true;
                }
            } else {
                if (this.textSelected) {
                    this.textSelected = false;
                }
            }
        },
    },

    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyUp);
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('orientationchange', this.onOrientationChange);
    },
    async mounted() {
        this.requestBonusFunctions();
        window.addEventListener("keydown", this.handleKeyUp);
        window.addEventListener('resize', this.onResize);
        window.addEventListener('orientationchange', this.onOrientationChange);
        setInterval(() => {
            let selection = { ...this.selectedText };
            if (window.getSelection) {
                selection = window.getSelection();
            }
            // document.getSelection
            else if (document.getSelection) {
                selection = document.getSelection();
            }
            // document.selection
            else if (document.selection) {
                selection =
                    document.selection.createRange().text;
            }
            if (selection.toString() !== this.selectedText) {
                this.update = !this.update;
            }
            this.selectedText = selection.toString();

        }, 100);

        if (this.targetLang !== 'de') {
            this.currentTTSLangImg = this[`${this.targetLang}FlagIcon`];
        }

        this.date = new Date(await this.loadServerTime());
        this.requestAppointments();
        this.requestPerson();
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("pupils", ["getMePupil"]),
        ...mapActions("translation", ["setTargetLang"]),
        ...mapActions("auth", ["logoutUser"]),
        ...mapActions("util", ["loadServerTime", "toggleKeyboard", "requestBonusFunctions"]),
        ...mapActions("magnifier", ["toggleMagnifier", "turnOffMagnifier"]),
        ...mapActions("speechControl", ["toggleSpeechControl", "setSpeechControlWord"]),
        ...mapActions("tooltips", ["triggerShortcutTooltips", "toggleTooltips", "triggerTabPressedOnce"]),
        ...mapMutations("snackbar", ["showSnackbar"]),

        closeOtherPopups(otherPopup, ownPopup) {
            this[ownPopup] = false;
            this[otherPopup] = true;
        },
        handleKeyUp(event) {
            if (event.altKey && event.key === 'a') {

                this.clickLogoutUser()
                this.triggerShortcutTooltips();
            }
            if (event.key === 'Tab' && !this.tabPressedOnce && this.babyView) {
                this.triggerTabPressedOnce();
            }
            if (event.altKey && event.key === 't') {
                this.toggleKeyboard()
                this.triggerShortcutTooltips();
            }
            if (event.altKey && event.key === 'l') {
                this.toggleMagnifier();
                this.triggerShortcutTooltips();
            }
        },
        async setFocusOnNextPage() {
            await this.$nextTick();

            if (this.page === 1) {
                document.getElementById("dateienHeading").focus();
            } else if (this.page === 0) {
                document.getElementById("stundenplanHeading").focus();
            }

        },

        async subscribe() {
            navigator.serviceWorker.getRegistrations().then(async (registrations) => {
                // Register Push
                const subscription = await registrations[0].pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: this.urlBase64ToUint8Array('BKFBvlmQYh9nNP9ECrMgMj-3v__wDW8Q3M0NPcHpH3LXSq7YsYvSR_dj757TxzwL2hKr--gN6SuIyuy3H0OUgeo')
                });
                await backend.subscribe({ subscription });
            });
            this.showSnackbar({
                message: 'Benachrichtigungen aktiv für das Schwarze Brett',
                color: 'info'
            });
        },

        urlBase64ToUint8Array(base64String) {
            const padding = "=".repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                // eslint-disable-next-line no-useless-escape
                .replace(/\-/g, "+")
                .replace(/_/g, "/");

            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        },

        onEnd({ lastSentence, transcription }) {

            this.setSpeechControlWord(lastSentence.toLowerCase())
        },

        startBBBTut(callback) {
            this.$emit('startBbbTut', callback);
        },
        toggleControl() {
            if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) {
                if (!this.speechControl) {
                    this.toggleSpeechControl(true);
                    this.voiceControlDialog = true;
                } else {
                    this.showSnackbar({ message: 'Die Sprachsteuerung ist bereits aktiv. Lade die Seite neu um sie zu deaktivieren.', color: 'error' });
                }
            } else {
                this.showSnackbar({ message: 'Dieser Browser wird nicht unterstützt. Bitte benutzen Sie einen anderen Browser.', color: 'error' });
            }
        },
        openBBB() {
            this.callbackMethod();
        },
        onResize() {
            this.windowWidth = window.innerWidth;
            this.closeBtmNavMenus();
        },
        async requestAppointments(update = false) {
            this.appointments = await this.getAppointments(update);
        },

        async requestBabyViewAndAccessibilityMode() {
            let temp = await this.getMePupil();
            this.babyView = temp.babyView;
            if (temp.accessibility) {
                this.sehen = temp.accessibility.sehen;
                this.hoeren = temp.accessibility.hoeren;
                this.motorik = temp.accessibility.motorik;
            } else {
                this.sehen = false;
                this.hoeren = false;
                this.motorik = false;
            }
            this.loaded = true; //to check if they are babys
            if (this.babyView) {
                this.babyViewTut = true;
            }
        },

        async requestPerson() {
            this.person = await this.getMePupil();

            //set babyView and accessibility initale
            this.babyView = this.person.babyView;
            if (this.person.accessibility) {
                this.sehen = this.person.accessibility.sehen;
                this.hoeren = this.person.accessibility.hoeren;
                this.motorik = this.person.accessibility.motorik;

                if (this.person.accessibility.sehen) {
                    this.setGlobalButtonContrast(
                        'invert(75%) saturate(200%) brightness(150%)',
                        '5px solid orange'
                    );
                }
            } else {
                this.sehen = false;
                this.hoeren = false;
                this.motorik = false;
                this.setGlobalButtonContrast('none', 'none');
            }
            return this.person;
        },
        getGenderColor() {
            if (this.person) {
                let gender = this.person.gender;
                switch (gender) {
                    case "m": return 'background-color: #538DCA';
                    case "w": return 'background-color: #E5536E';
                    default: return 'background-color: #9B91C8';
                }
            } else return 'background-color: #9B91C8';
        },

        clickLogoutUser() {
            this.logoutUser();
            this.$router.push({ name: 'login' });
        },


        async changeLang(newLang, newLangImg) {
            const langChanged = await this.setTargetLang(newLang);
            this.currentTTSLangImg = langChanged ? newLangImg : this.currentTTSLangImg;
        },

        reloadPage() {
            this.$router.go();
        },

        /*btmNav methods*/
        async changeLangBtmNav(newLang, newLangImg) {
            //statement needed for empty menuIcons in btmNavLangMenu
            if (newLangImg !== '') {
                const langChanged = await this.setTargetLang(newLang);
                this.currentTTSLangImg = langChanged ? newLangImg : this.currentTTSLangImg;
                this.btmNavLangMenu = false;
            }
        },
        switchSlide(index) {
            if (index !== this.currSlide) {
                this.$emit("switchSlide", index);
                this.closeBtmNavMenus();
            }
        },
        closeBtmNavMenus() {
            this.btmNavLangMenu = false;
            this.btmNavMenu = false;
        },
        handleBtmMenuClick() {
            this.btmNavMenu = !this.btmNavMenu;
            this.btmNavLangMenu = false;
        },
        onClose() {
            this.closeBtmNavMenus();
        },
        onOrientationChange() {
            this.switchSlide(0);
            this.slideChangeAllowed = true;
        },
        clickTTS() {
            this.$refs.tts.$el.click();
        },
        clickTTSFloat() {
            this.$refs.ttsFloat.$el.click();
        },

        /*topMenu methods*/
        async changeLangNav(newLang, newLangImg) {
            //statement needed for empty menuIcons in btmNavLangMenu
            if (newLangImg !== '') {
                const langChanged = await this.setTargetLang(newLang);
                this.currentTTSLangImg = langChanged ? newLangImg : this.currentTTSLangImg;
                this.showSnackbar({ message: "Für eine optimale Nutzung der Übersetzungsfunktion verwenden sie bitte den Microsoft Edge oder Google Chrome Browser",
                color: "orange" });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr {
        height: 40px;
    }

    table tr th:first-child,
    table tr td:first-child {
        width: 10px !important;
    }

    table tr th,
    table tr td {
        width: 9em;
        border: none;
    }
    table, tr, td {
        border: none;
    }

    .fach {
        cursor: pointer;
    }

    .small {
        display: none;
    }

    .big {
        display: flex;
    }

    .headerAsOne {
        display: none !important;
    }

    .slick-slider {
        user-select: auto!important;
    }

    .ellipsis {
        text-overflow: ellipsis;

        /* Required for text-overflow to do anything */
        white-space: nowrap;
        overflow: hidden;
    }

    .greyText {
        color: var(--v-dunkelgrau-base);
    }

    .iconToWhite {
        filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
    }

    .roundProfilePic {
        border-radius: 50%;
        height: 35px;
        width: 35px;
    }

    .roundProfilePicBtmNav {
        border-radius: 50%;
        height: 25px;
        width: 25px;
    }

    .btmNav {
        display: none;
        z-index: 11;
        position: fixed;
        bottom: 0;
        width: 100% !important;
        height: 70px !important;
        background-color: var(--v-dunkelgrau-base);
    }

    .btmNavBtn {
        height: 50px !important;
        width: 50px !important;
        background-color: white !important;
        border-radius: 8px !important;
    }

    /*removes darker background after btn is clicked
    also works without the specific class in front*/
    .btmNavBtn.v-btn:before {
        opacity: 0 !important;
    }
    .arrowBtn:before {
      opacity: 0 !important;
    }

    .arrowBtn:focus {
        background-color: var(--v-primary-base) !important;
    }

    .imgFocus {
      filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%) !important;
    }

/*    .buttonFocusHover:hover {
      background-color: #6994cd !important;
    }*/

    .buttonFocusHover:hover .imgFocusHover{
      filter: invert(51%) sepia(66%) saturate(310%) hue-rotate(175deg) brightness(98%) contrast(91%) !important;
    }

    .btmNavIcon {
        height: 35px;
    }

    .hideMe {
        display: none !important;
    }

    .btmNavMenu {
        position: fixed;
        bottom: 70px;
        right: 0;
        width: 160px;
        max-width: 160px;
        background-color: #FFFFFF;
        box-shadow: 0 -2px 4px silver;
    }

    .btmNavMenuBtn {
        color: black;
        background-color: var(--v-editorGrey-base);
        border-radius: 8px;
        justify-content: flex-start;
    }

    .btmNavLangNoIcon {
        height: 25px;
        width: 25px;
        background-color: transparent;
    }

    .btmNavLangMenu {
        position: fixed;
        bottom: 70px;
        right: 160px;
        border-top-left-radius: 12px;
        background-color: #FFFFFF;
        box-shadow: -5px -2px 4px silver;
    }

    .btmNavLangBtn {
        width: 41px; height: 41px;  /*needed so that border and box-sizing work*/
        color: var(--v-dunkelgrau-base);
        border-radius: 8px;
        min-width: 41px !important;
    }

    /*if there is a flag/icon it has a grey background*/
    .langBtnBg{
        background-color: #EFEFEF !important;
    }

    .border {
        border: 3px #6995CD solid;
        box-sizing: border-box !important;
    }

    .pointer {
        cursor: pointer;
    }

    .btmNavMenuIconActive {
        background-color: #6995CD !important;
    }

    @media only screen and (max-width: 900px) {
        .small {
            display: block !important;
        }

        .big {
            display: none !important;
        }

        .topPadding {
            padding-top: 12px;
        }
        .headerSides {
            display: none !important;
        }

        .headerAsOne {
            display: inline-flex !important;
        }

        .headerMid {
            width: 100% !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .displayHide {
            display: none;
        }

        .btmNav {
            display: flex;
        }

        .moreBottom {
            bottom: 80px !important;
        }
    }

    @media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
        .headerSides {
            display: none !important;
        }

        .headerAsOne {
            display: inline-flex !important;
        }

        .headerMid {
            width: 100% !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .displayHide {
            display: none;
        }

        .btmNav {
            display: flex;
        }

        .moreBottom {
            bottom: 80px !important;
        }
    }

    .animateIcon {
        overflow: hidden;
    }

    .animateIcon:after {
        animation: shine 60s ease-in-out infinite;
        animation-fill-mode: forwards;
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background: rgba(255, 255, 255, 0.0);
        background: linear-gradient(
                to right,
                rgba(112, 112, 112, 0.2) 0%,
                rgba(112, 112, 112, 0.2) 77%,
                rgba(112, 112, 112, 0.5) 92%,
                rgba(112, 112, 112, 0.0) 100%
        );
    }

    /* Hover state - trigger effect */

    /* Active state */
    .animateIcon:active:after {
        opacity: 0;
    }

    @keyframes shine{
        1% {
            opacity: 1;
            top: -30%;
            left: -30%;
            transition-property: left, top, opacity;
            transition-duration: 0.7s, 0.7s, 0.15s;
            transition-timing-function: ease;
        }
        5% {
            opacity: 0;
            top: -30%;
            left: -30%;
            transition-property: left, top, opacity;
        }
    }

    .v-snack.v-snack--top {
        bottom: initial; /* or auto */
    }

    .v-snack.v-snack--bottom {
        top: initial; /* or auto */
    }

    .iconToWhite {
        filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
    }

    .onTop{
        z-index: 11!important;
    }
</style>
