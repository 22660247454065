import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';

const defaultState = {
  pupils: [],
  tutorialProgress: {},
  pupilListInvalid: false,
};

const mutations = {
  SET_TUTORIAL_PROGRESS: (state, { account, progress }) => {
    state.tutorialProgress = {
      ...state.tutorialProgress,
      [account]: progress,
    };
  },
  SET_PUPIL_LIST_STATUS: (state, status) => {
    state.pupilListInvalid = status;
  },
  SET_PUPILS: (state, list) => {
    state.pupils = list;
  },
};

const getters = {
  getTutorialProgress: state => state.tutorialProgress,
  getPupilListStatus: state => state.pupilListInvalid,
  pupilsByAccountId: state => state.pupils.reduce((acc, pupil) => {
    acc[pupil.account] = pupil;
    return acc; 
  }, {}),
  pupilsById: state => state.pupils.reduce((acc, pupil) => {
    acc[pupil._id] = pupil;
    return acc; 
  }, {}),
};

const actions = {
  setPupilListStatus({ commit }, status) {
    commit('SET_PUPIL_LIST_STATUS', status);
  },
  setTutorialProgress({ getters, commit }, { account, progress }) {
    const accountProgress = getters.getTutorialProgress[account] || [];
    let newAccountProgress = [];
    if (Array.isArray(progress)) {
      newAccountProgress = [...accountProgress, ...progress]
    } else {
      newAccountProgress = [...accountProgress, progress]
    }
    commit('SET_TUTORIAL_PROGRESS', { account, progress: newAccountProgress });
  },

  async createPupil({ commit, dispatch }, params) {
    try {
      const res = await backend.postPupil(params);
      await checkResponseStatus(201, res);
      const account = await res.json();
      return account;
    }
    catch (err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async editPupil({ commit, dispatch }, params) {
    try {
      const id = params._id
      delete params._id;
      const res = await backend.patchPupil(id, params);
      if (res.status === 409) {
        return 409;
      }
      const account = await res.json();
      return account;
    }
    catch (err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async deletePupile({ commit, dispatch }, id) {
    try {
      const res = await backend.deletePupil(id);
      if (res.status === 409) {
        return 409;
      }
      await checkResponseStatus(204, res);
      return true;
    }
    catch (err) {
      console.error(err)
      return false;
    }
  },

  async getPupil({ commit, dispatch }, id) {
    try {
      const res = await backend.getPupil(id);
      await checkResponseStatus(200, res);
      const pupil = await res.json();
      return pupil;
    }
    catch (err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async getPupils({ commit, dispatch, getters }, update = false) {
    try {
      if (getters.pupils && getters.pupils.length > 0 && !update) {
        return getters.pupils;
      }

      const res = await backend.getPupils();
      await checkResponseStatus(200, res);
      const pupils = await res.json();
      commit('SET_PUPILS', pupils);
      return pupils;
    }
    catch (err) {
      console.error(err);
            return err?.response?.status;
    }
  },

    async getMePupil ({ commit, dispatch }, ) {
        try {
            const res = await backend.getMePupil();
            await checkResponseStatus(200, res);

            const resJson = await res.json();
            // save translation allowed status in translation.js store module
            dispatch('translation/setTranslationAllowed', !!resJson.translationAllowed, { root: true });
            return resJson;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
