<template>
    <v-dialog
        v-model="showDialog"
        max-width="850px"
        persistent
        @click:outside="$emit('close')"
    >
        <v-card style="height: 80vh; overflow: hidden;">
            <v-card-title class="d-flex justify-space-between mb-5">
                <p>In der Online Bibliothek veröffentlichen</p>
                <v-btn @click="$emit('close')">
                    X
                </v-btn>
            </v-card-title>
            <v-card-text
                class="d-inline-flex flex-column justify-space-between"
                style="height: 87.5%"
            >
                <!-- content row -->
                <div class="d-flex flex-row">
                    <div
                        id="narrowLeftOverview"
                        class="d-flex flex-column justify-start mr-2"
                    >
                        <p class="header">
                            Name
                        </p>
                        <div class="mb-5">
                            <v-text-field
                                v-model="selectedName"
                                outlined
                                dense
                                hide-details
                                single-line
                                height="50px"
                            />
                        </div>
                        <p class="header">
                            Ausgewählter Ordner
                        </p>
                        <div class="mb-5">
                            <p>
                                {{ selectedFolder
                                    ? (selectedFolder.name === 'root' ? 'Online Bibliothek' : selectedFolder.name)
                                    : 'Online Bibliothek' }}
                            </p>
                        </div>
                        <p class="header">
                            Ausgewählte Tags
                        </p>
                        <div class="mb-5 docRegTagsWrapper">
                            <p v-if="!selectedTags.length">
                                Es wurden keine Tags ausgewählt.
                            </p>
                            <v-chip
                                v-for="(tag, index) in selectedTags"
                                :key="`selectedTag${tag._id}${index}`"
                                color="orange"
                                dark
                                class="ma-1"
                                label
                                @click="tagAction('undo', tag, index)"
                            >
                                {{ tag.name }}
                            </v-chip>
                        </div>
                    </div>
                    <div
                        id="largeRightOverview"
                        class="d-flex flex-column justify-start ml-2"
                    >
                        <p class="header mb-2">
                            Wählen Sie einen Ordner in der Online Bibliothek aus
                        </p>
                        <!-- Order ansicht der doc reg -->
                        <div class="d-flex justify-start">
                            <v-btn
                                v-if="currentFolderTree.length > 1"
                                text
                                x-small
                                @click="changeCurrentFolder(currentFolderTree[currentFolderTree.length - 2])"
                            >
                                <v-icon style="font-size: 16px; color: var(--v-dunkelgrau-base)">
                                    fas fa-chevron-left
                                </v-icon>
                            </v-btn>
                            <p style="font-weight: bold">
                                {{ displayableFolderPath }}
                            </p>
                        </div>
                        <div
                            id="docRegFoldersWrapper"
                            class="d-flex flex-wrap justify-space-evenly align-center"
                            style="max-width: 100%;"
                            :style="`min-height: ${displayableDocRegistryElements.items.length ? 'auto' : '150px'}`"
                        >
                            <p
                                v-if="!displayableDocRegistryElements.items
                                    || !displayableDocRegistryElements.items.length"
                                class="mx-auto"
                            >
                                Dieser Ordner hat keine Unterordner.
                            </p>
                            <EditorTemplateBrowserItem
                                v-for="(item, index) in displayableDocRegistryElements.items"
                                :key="`templateDoc${index}${item._id}`"
                                :item="item"
                                :type="'Folder'"
                                class="ma-1"
                                style="width: 150px; height: 150px;"
                                @folderSelected="(callback) => changeCurrentFolder(item, callback)"
                            />
                        </div>
                        <p class="header mt-5">
                            Wählen Sie eines oder mehrere Tags aus, die das Dokument gut beschreiben
                        </p>
                        <!-- Tag ansicht der doc reg -->
                        <div class="docRegTagsWrapper">
                            <p v-if="!shownTags.length">
                                Es wurden keine Tags gefunden.
                            </p>
                            <v-chip
                                v-for="(tag, index) in shownTags"
                                :key="`tagList${tag._id}${index}`"
                                color="hellgrau"
                                class="ma-1"
                                label
                                @click="tagAction('select', tag)"
                            >
                                {{ tag.name }}
                            </v-chip>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row justify-end align-baseline">
                    <v-btn
                        color="error"
                        class="mx-1"
                        style="text-transform: inherit"
                        dark
                        @click="$emit('close')"
                    >
                        Abbruch
                    </v-btn>
                    <v-btn
                        color="success"
                        class="mx-1"
                        style="text-transform: inherit"
                        dark
                        @click="sendDocumentToDocReg"
                    >
                        Senden
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import EditorTemplateBrowserItem from "@/components/Editor/EditorTemplateBrowserItem";

export default {
    name: 'PublishDocumentToDocRegDialog',
    components: { EditorTemplateBrowserItem },
    props: {
        documentDetails: { required: true, type: Object },
    },
    data: () => ({
        // Flag stuff
        showDialog: true,

        // File stuff
        uploadMessagesId: null,
        selectedTags: [],
        selectedName: '',

        // Registry Stuff
        currentFolder: null,
        currentFolderTree: [],
        folderCallback: () => {},
    }),
    computed: {
        ...mapState('documentRegistry', [ 'documentRegistryRootFolder', 'documentRegistryFolders', 'documentRegistryTags' ]),
        ...mapGetters('documentRegistry', [
            'documentRegistryDocumentsById',
            'documentRegistryFoldersById',
        ]),
        shownTags() {
            // return a list of tags gotten from docreg,
            // filtered to show only those not selected
            return this.documentRegistryTags
                .filter(tag => !this.selectedTags.includes(tag))
                .filter(tag => tag.name && !tag.name.match(/\d/))
                .sort((a, b) => {
                    a = a.name.toLowerCase();
                    b = b.name.toLowerCase();
                    return (a < b) ? -1 : (a > b) ? 1 : 0;
                });
        },
        selectedFolder() {
            return this.currentFolderTree[this.currentFolderTree.length -1];
        },
        displayableFolderPath() {
            let result = '';

            for (var i = 0; i < this.currentFolderTree.length; i++) {
                result += (this.currentFolderTree[i].parent ? this.currentFolderTree[i].name : 'Online Bibliothek') + '/';
            }
            return result;
        },
        displayableDocRegistryElements() {
            const currentFolder = this.currentFolder
                ? {...this.currentFolder}
                : {items: []};

            const returnFolder = {...currentFolder};
            returnFolder.items = [];
            const folders = [];

            if (currentFolder.items) {
                for (let i = 0; i < currentFolder.items.length; i++) {
                    let item = {...currentFolder.items[i]};

                    let data = null;
                    if (item.itemType === 'Folder') {
                        data = {...this.documentRegistryFoldersById[item.value]};
                        if (Object.keys(data).length) {
                            item.type = 'folder';
                            item.items = data.items;
                            item.name = data.name;
                            item.parent = data.parent;
                            folders.push(item);
                        }
                    }
                }

                folders.sort((a, b) => {
                    a = a.name.toLowerCase();
                    b = b.name.toLowerCase();
                    if (this.sortAlphabeticalDescending) {
                        return (a < b) ? 1 : (a > b) ? -1 : 0;
                    } else {
                        return (a < b) ? -1 : (a > b) ? 1 : 0;
                    }
                })

                returnFolder.items = folders;
            }
            return returnFolder;
        },
    },
    async created() {
        await this.loadDocumentRegistryRootFolder();
        await this.loadDocumentRegistryFolders();
        await this.loadDocumentRegistryTags();
        this.currentFolder = this.documentRegistryRootFolder;
        this.currentFolderTree.push(this.documentRegistryRootFolder);
    },
    updated() {
        if (this.displayableDocRegistryElements.items && this.folderCallback) {
            this.folderCallback();
            this.$nextTick(() => {
                this.folderCallback = () => { };
            });
        }
    },
    mounted() {
        this.uploadMessagesId = this.documentDetails._id;
        this.selectedName = this.documentDetails.uploadedName;
    },
    methods: {
        ...mapActions('documentRegistry', [
            'loadDocumentRegistryFolders',
            'loadDocumentRegistryTags',
            'loadDocumentRegistryDocuments',
            'loadDocumentRegistryRootFolder',
            'postDocumentRegistryDocument',
        ]),
        ...mapMutations('snackbar', [
           'showSnackbar'
        ]),

        tagAction(action, tag, index = 0) {
            switch (action) {
                case 'select':
                    this.selectedTags.push(tag);
                    break;
                case 'undo':
                    this.selectedTags.splice(index, 1);
                    break;
            }
        },

        /**
         * DocReg Folder Selection
         */
        changeCurrentFolder(folder, callback) {
            this.currentFolder = folder
            if (!this.currentFolderTree.includes(folder)) {
                this.currentFolderTree.push(folder)
            } else {
                //find index
                const folderIndex = this.currentFolderTree.findIndex(item => item._id === folder._id);
                // delete everything after index
                this.currentFolderTree.splice(folderIndex + 1);
            }
            this.folderCallback = callback;
        },

        /**
         * Send shit to backend
         */
        async sendDocumentToDocReg() {
            const params = {
                documentId: this.documentDetails._id,
                tags: this.selectedTags.map(tag => tag._id),
                name: this.selectedName,
                parent: this.selectedFolder.value || this.selectedFolder._id,
            };

            const response = await this.postDocumentRegistryDocument(params);
            if (response.status === 200) {
                this.showSnackbar({
                    color: 'success',
                    message: 'Dokument erfolgreich in der Online-Bibliothek veröffentlicht!'
                });
                this.$emit('close');
                this.loadDocumentRegistryDocuments();
            } else {
                this.showSnackbar({
                    color: 'warning',
                    message: `Konnte nicht veröffentlicht werden! ${response.status}`
                });
            }
        },
    },
}
</script>

<style scoped lang="scss">
.header {
    font-size: 1.17em;
    color: black;
}
#narrowLeftOverview {
    max-width: 30%;
    width: 30%;
    height: 100%;
}

#largeRightOverview {
    max-width: 70%;
    max-height: calc(80vh * 0.75);
    overflow-y: auto;
}

#docRegFoldersWrapper {
    width: 100%;
    border: 2px solid lightgray;
    border-radius: 0.5em;
    background-color: var(--v-hellgrau-base);
}

.docRegTagsWrapper {
    width: 100%;
    display: flex;
    justify-content: left;
    align-content: baseline;
    flex-wrap: wrap;
}
</style>
