import { render, staticRenderFns } from "./ChatRoomImageUploadButton.vue?vue&type=template&id=bd6a51aa&scoped=true&"
import script from "./ChatRoomImageUploadButton.vue?vue&type=script&lang=js&"
export * from "./ChatRoomImageUploadButton.vue?vue&type=script&lang=js&"
import style0 from "./ChatRoomImageUploadButton.vue?vue&type=style&index=0&id=bd6a51aa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd6a51aa",
  null
  
)

export default component.exports