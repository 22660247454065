<template>
    <div
        id="btnsContainer"
        class="d-flex justify-start"
    >
        <!-- parent -->
        <v-btn
            v-if="accountRole === 'parent'"
            id="joinRoomBtnPupil"
            :color="atLeastOneRoomOpen ? 'gruen' : 'fgrau'"
            elevation="0"
            @click="requestRooms(true)"
        >
            <img
                :src="videoWeissIcon"
                alt=""
            >
            <p>
                Sprechzimmer
            </p>
        </v-btn>

        <!-- teacher -->
        <v-btn
            v-if="accountRole === 'teacher'"
            id="joinRoomBtnTeacher"
            :color="roomIsOpen ? 'gruen' : 'fgrau'"
            style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
            class="px-2"
            elevation="0"
            @click="joinRoom(teacherRoomId)"
        >
            <img
                :src="videoWeissIcon"
                alt=""
            >
            <p>Eltern</p>
        </v-btn>

        <v-btn
            v-if="accountRole === 'teacher'"
            id="schlossBtn"
            :color="roomIsOpen ? 'gruen' : 'fgrau'"
            class="ml-0"
            elevation="0"
            style="border-top-left-radius: 0; border-bottom-left-radius: 0; border-left-style: solid; border-color: rgba(0, 0, 0, 0.33) !important"
            :small="windowWidth <= 900 && windowWidth > 770"
            :x-small="windowWidth <= 770"
            @click="toggleRoom"
        >
            <img
                :src="roomIsOpen ? schlossOffenIcon : schlossIcon"
                alt=""
            >
        </v-btn>

        <!-- parent can chose from teachers -->
        <div
            v-if="menu && windowWidth >= 770"
            id="roomMenu"
            v-click-outside="onClickOutsideMenu"
        >
            <v-row
                v-for="(room, index) in rooms"
                :key="index"
                class="pa-0 ma-3"
            >
                <v-col
                    cols="10"
                    class="pa-0"
                    @click="readKlassenraumInfo(getAppropriateTeacherDisplayName(room.teacher))"
                >
                    <v-btn
                        color="hellgrau"
                        width="95%"
                        class="d-flex justify-start"
                    >
                        <img
                            :src="getTeachPic(room.teacher)"
                            :class="(hasProfilePic(room.teacher) ? 'roundIcon' : '') + ' mr-2'"
                            alt=""
                        >
                        <p
                            class="text-capitalize ma-0"
                            style="color: var(--v-dunkelgrau-base)"
                        >
                            {{ getAppropriateTeacherDisplayName(room.teacher) }}
                        </p>
                    </v-btn>
                </v-col>

                <v-col
                    cols="2"
                    class="pa-0 pl-2 d-flex justify-end"
                    @click="joinRoom(room._id)"
                >
                    <v-btn
                        :color="room.isOpen ? 'gruen' : 'hellgrau'"
                        class="videoBtn"
                        x-small
                    >
                        <img
                            :src="room.isOpen ? videoWeissIcon : videoIcon"
                            :style="room.isOpen ? 'filter: brightness(1000%)' : ''"
                            alt=""
                        >
                    </v-btn>
                </v-col>
            </v-row>
        </div>

        <!-- parent can chose from teachers in MOBILE -->
        <div
            v-if="menu && windowWidth < 770"
            id="roomMenuBtm"
            v-click-outside="onClickOutsideMenu"
        >
            <v-row
                v-for="(room, index) in rooms"
                :key="index"
                class="pa-0 ma-3"
            >
                <v-btn
                    color="hellgrau"
                    class="d-flex justify-start"
                    style="width: 140px"
                    @click="readKlassenraumInfo(getAppropriateTeacherDisplayName(room.teacher))"
                >
                    <img
                        :src="getTeachPic(room.teacher)"
                        :class="(hasProfilePic(room.teacher) ? 'roundIcon' : '') + ' mr-2'"
                        alt=""
                    >
                    <p
                        class="ellipsis text-capitalize text-left ma-0"
                        style="width: 80px; color: var(--v-dunkelgrau-base)"
                    >
                        {{ getAppropriateTeacherDisplayName(room.teacher) }}
                    </p>
                </v-btn>

                <v-btn
                    :color="room.isOpen ? 'gruen' : 'hellgrau'"
                    class="videoBtn"
                    x-small
                    @click="joinRoom(room._id)"
                >
                    <img
                        :src="room.isOpen ? videoWeissIcon : videoIcon"
                        :style="room.isOpen ? 'filter: brightness(1000%)' : ''"
                        alt=""
                    >
                </v-btn>
            </v-row>
        </div>
        <VideoTutorial
            v-if="videoTutorial && showVideoTutorial && !videoTutorialClosed"
            :video-tutorial="videoTutorial"
            @close="onVideoTutorialClose"
        />
    </div>
</template>

<script>
import {mapActions, mapState, mapMutations, mapGetters } from 'vuex';

import VideoTutorial from "../components/Tutorial/VideoTutorial.vue";

import videoIcon from '@/assets/Icons/kamera-video-72.svg';
import videoWeissIcon from '@/assets/Icons/kamera-video-weiss-72.svg';
import schlossIcon from '@/assets/Icons/lock-closed.svg';
import schlossOffenIcon from '@/assets/Icons/lock-open.svg';
import lehrerIcon from '@/assets/Icons/teacher.svg';
import abbrechenIcon from "../assets/Icons/abbrechen-08.svg";

export default {
    name: "JoinSprechzimmerButton",
    components: {
        VideoTutorial,
    },
    props: {
        currentChild: { required: false, type: Object },
    },
    data() {
        return {
            videoIcon,
            videoWeissIcon,
            schlossIcon,
            schlossOffenIcon,
            menu: '',
            rooms: '',
            teacherRoomId: '',
            roomIsOpen: null,
            atLeastOneRoomOpen: null,      //to make Sprechzimmer-btn for parent green if >= 1 room isOpen
            teacherPics: [],

            person: null,
            currentChildId: null,

            // Show video Tutorial is set to true when button is clicked
            // videoTutorialClosed is set to false when VideoTutorial is closed
            videoTutorial: false,
            showVideoTutorial: false,
            videoTutorialClosed: false,
            videoTutorialOnCloseJoinRoomId: '',
        };
    },
    computed : {
        ...mapGetters("auth",["accountRole"]),
        ...mapState('translation', ['targetLang']),
        ...mapState('util', ['windowWidth']),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
    },
    watch: {
        async currentChild() {
            this.currentChildId = this.currentChild._id;
            this.atLeastOneRoomOpen = false;
            await this.requestRooms();
        }
    },
    async mounted() {
        await this.getTeacherRoom();
        if(this.accountRole === 'parent') {
            this.person = await this.getMeParent();
            this.currentChildId = this.person.pupils[0];
            await this.requestRooms();
            await this.setTeachProfilePics();
        }
        this.videoTutorial = this.accountRole === 'teacher'
            ? this.videoTutorialsByFilename['Lehrer_bbb_erklaert.mp4']
            : this.videoTutorialsByFilename['Normal_bbb_in_3Min_erklaert.mp4'];
    },
    async unmounted() {
        this.teacherPics.forEach(async (pic) => {
            URL.revokeObjectURL(pic[1]);
        });
    },
    methods: {
        ...mapActions('util', [ 'toggleLoading' ]),
        ...mapActions('rooms', [ 'getMyConferenceTeacher', 'getMyConferenceParent', 'changeConferenceRoomStatus', 'joinConferenceRoom' ]),
        ...mapActions('teachers', [ 'getProfilePicture' ]),
        ...mapActions('parents', [ 'getMeParent' ]),
        ...mapActions('translation', [ 'showTranslation', 'setTranslatedText', 'translateToTargetLang' ]),
        ...mapMutations('snackbar', [ 'showSnackbar' ]),

        onVideoTutorialClose() {
            this.videoTutorial = false;
            this.videoTutorialClosed = true;
            this.joinRoom(this.videoTutorialOnCloseJoinRoomId);
        },
        async joinRoom(id) {
            this.toggleLoading(true);

            const response = await this.joinConferenceRoom(id);
            if (this.accountRole === 'teacher') {
                if (!response.redirectUrl) {
                    this.showSnackbar({message: "Um das Sprechzimmer betreten zu können, müssen Sie es erst öffnen. Dazu müssen Sie das Schloss anklicken.", color: 'fgrau'});
                    this.toggleLoading(false);
                    return;
                }
            } else {
                if (!response.redirectUrl) {
                    this.showSnackbar({message: "Das Sprechzimmer ist geschlossen!", color: 'fgrau'});
                    this.toggleLoading(false);
                    return;
                }
            }

            if (!response || response === 422 || response === 404) {
                this.showSnackbar({message: "Das Sprechzimmer konnte nicht geladen werden - Bitte aktualisieren Sie die Seite!", color: 'error'});
                this.toggleLoading(false);
                return;
            }

            if(this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                this.videoTutorialOnCloseJoinRoomId = id;
                return;
            }

            window.location.href = response.redirectUrl;
            this.toggleLoading(false);
        },

        async toggleRoom() {
            let newStatus = !this.roomIsOpen;
            let data = {
                classroomId: this.teacherRoomId,
                newStatus: newStatus,
            }
            let res = await this.changeConferenceRoomStatus(data);

            if(res) {   // if successful also change the local one
                this.roomIsOpen = res.isOpen;

                // and show snackbar
                if (this.roomIsOpen) {
                    this.showSnackbar({ message: "Das Sprechzimmer ist nun geöffnet.", color: "success" });
                } else {
                    this.showSnackbar({ message: "Das Sprechzimmer wurde geschlossen.", color: "success" });
                }
            }
        },

        async requestRooms(menu) {
            // make sure route is only called initial and if parent opens menu, cause big route
            if (this.accountRole === 'parent' && !this.menu && this.currentChildId) {
                this.rooms = await this.getMyConferenceParent(this.currentChildId);
                for (let i = 0; i < this.rooms.length; i++) {
                    if (this.rooms[i] && this.rooms[i].isOpen) {
                        this.atLeastOneRoomOpen = true;
                        break;
                    }
                }
            }
            // if called on click of Sprechzimmer btn, also toggle the menu
            if(menu) {
                this.menu = !this.menu;
            }
        },

        getAppropriateTeacherDisplayName(teacher) {
            let prefix = '';

            if (teacher.gender === 'm') {
                prefix = 'Hr. ';
            } else if (teacher.gender === 'w') {
                prefix = 'Fr. ';
            }

            const lastName = teacher.lastName;

            return prefix + lastName;
        },

        async getTeacherRoom() {
            if(this.accountRole === 'teacher') {
                let room = await this.getMyConferenceTeacher();
                this.teacherRoomId = room._id;
                this.roomIsOpen = room.isOpen;
            }
        },

        onClickOutsideMenu() {
            this.menu = false;
        },

        async readKlassenraumInfo(teacher) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
            }

            let text = 'Um das Sprechzimmer von ' + teacher + ' zu betreten klicken Sie auf die Fläche mit dem Kamera-Symbol, wenn es grün ist. Ist die Fläche grau, ist das Sprechzimmer geschlossen.';

            if(window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel()
            } else {
                if (this.targetLang !== 'de') {
                    text = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: text
                    });

                    this.setTranslatedText(text);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)
                }

                let msg = new SpeechSynthesisUtterance();
                msg.text = text;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }
            return false;
        },

        // get profile pics from all teachers and save them in teacherPics array
        async setTeachProfilePics() {
            for (let i = 0; i < this.rooms.length; i++) {
                let profilePicBlob;
                if (this.rooms[i] && this.rooms[i].teacher.profilePicture) {
                    profilePicBlob = await this.getProfilePicture(this.rooms[i].teacher._id);

                    if (profilePicBlob !== 404) {
                        let objectURL = URL.createObjectURL(profilePicBlob);
                        this.teacherPics.push([this.rooms[i].teacher._id, objectURL]);
                    }
                }
            }
        },

        // checks if provided user has a profile pic
        hasProfilePic(user) {
            for (let j = 0; j < this.teacherPics.length; j++) {
                let currTeacher = this.teacherPics[j];
                if (currTeacher === undefined) {
                    continue;
                } else if (currTeacher[0] === user._id) {
                    return true;
                }
            }
            return false;
        },

        // get teacher profile pic from teacherPics array
        getTeachPic(user) {
            if (user._id == 0) {
                return null;
            }
            for (let j = 0; j < this.teacherPics.length; j++) {
                let currTeacher = this.teacherPics[j];
                if (currTeacher === undefined) {
                    continue;
                } else if (currTeacher[0] === user._id) {
                    return currTeacher[1];
                }
            }
            return lehrerIcon;
        },
    }
}
</script>

<style scoped lang="scss">
.v-btn {
    border-radius: 8px;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.roundIcon {
    border-radius: 50%;
}

#joinRoomBtnTeacher {
    color: white;
    text-transform: inherit;
    height: 50px !important;
    margin-left: 12px;

    img {
        height: 25px;
        filter: brightness(1000%);
        margin-right: 4px;
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

#joinRoomBtnPupil {
    color: white;
    text-transform: inherit;
    height: 50px !important;
    margin-left: 12px;

    img {
        height: 30px;
        filter: brightness(1000%);
        margin-right: 5px;
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

#schlossBtn {
    height: 50px;
    min-width: 50px;
    width: 50px;

    img {
        height: 30px;
        filter: brightness(1000%);
    }
}

#roomMenu {
    position: fixed;
    top: 74px;
    width: 250px;
    background-color: white;
    border-radius: 0 0 0 8px;
    box-shadow: 0 2px 4px silver;
    z-index: 10; //to be one higher than fach
    color: var(--v-grau-base);

    img {
        height: 25px;
    }
}

.videoBtn {
    height: 36px !important;
    width: 36px !important;
}

@media only screen and (max-width: 900px) {
    #joinRoomBtnTeacher {
        height: 40px !important;
        padding: 8px;
        margin-left: 8px;

        p {
            font-size: small;
        }

        img {
            height: 20px !important;
        }
    }

    #joinRoomBtnPupil {
        height: 40px !important;
        width: 144px;
        padding: 8px;
        margin-left: 0;
        margin-bottom: 8px;

        p {
            font-size: small;
        }

        img {
            height: 21px !important;
            margin-right: 8px !important;
        }
    }

    #schlossBtn {
        height: 40px;
        min-width: 40px;
        width: 40px;

        img {
            height: 21px !important;
            margin: 0 !important;
        }
    }
}

//when change to mobile nav on bottom
@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    #joinRoomBtnTeacher {
        padding: 8px !important;
        margin-left: 0 !important;
        margin-bottom: 8px;
        width: 104px;
    }

    #joinRoomBtnPupil {
        height: 41px !important;
    }

    #roomMenuBtm {
        position: fixed;
        bottom: 254px;
        right: 160px;
        width: 200px;
        background-color: white;
        border-radius: 8px 0 0 8px;
        box-shadow: 0 2px 4px silver;
        z-index: 2;
        color: var(--v-grau-base);

        img {
            height: 25px;
        }
    }

    #schlossBtn {
        margin-left: 0 !important;
        padding: 0 !important;
        width: 40px !important;

        img {
            margin: 0 !important;
        }
    }
}
</style>
