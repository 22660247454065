<template>
    <div
        class="d-flex"
        style="width: 100vw"
    >
        <MessagesWidget
            class="mt-3"
            style="width: calc((100vw - 112px)/3)"
        >
        </MessagesWidget>

        <schwarzes-brett
            class="mt-3"
            style="width: calc((100vw - 112px)/3)"
        >
        </schwarzes-brett>

        <chat-verwaltung
            style="width: calc((100vw - 112px)/3)"
        >
        </chat-verwaltung>
    </div>
</template>

<script>
import ChatVerwaltung from "@/components/Verwaltung/ChatVerwaltung";
import SchwarzesBrett from "@/components/Verwaltung/SchwarzesBrett";
import MessagesWidget from "@/components/MessagesWidget";

export default {
    name: "MessagesMaintainer",
    components: {SchwarzesBrett, ChatVerwaltung, MessagesWidget}
}
</script>

<style scoped>

</style>
