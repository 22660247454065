\<template>
    <div>
        <v-row class="mx-0">
            <div class="whiteBg widgetContainer">
                <!--Header of Chat-->
                <div
                    v-if="false"
                    class="widgetHeader"
                >
                    <img
                        :src="chatIcon"
                        alt
                        class="iconToWhite mr-2 ml-4"
                        style="height: 20px"
                        @click="readWidgetInfoText()"
                    >
                    <h1
                        tabindex="0"
                        class="mb-0"
                        style="font-size: larger"
                        @click="readWidgetInfoText()"
                    >
                        Chat
                    </h1>

                    <div style="flex: auto">
                        <!-- Spacer, fill empty space -->
                    </div>
                    <ToggleChatNotificationsButton />

                    <img
                        :src="infoIcon"
                        alt
                        class="mr-2"
                        style="height: 15px"
                        @click="readWidgetInfoText()"
                    >
                </div>
                <ChatWidgetGroupListV2
                    v-if="!selectedChatRoomId"
                    :current-child="currentChild"
                    @roomSelected="handleRoomSelected"
                />
                <ChatWidgetGroupChatV2
                    v-else
                    :current-child="currentChild"
                    :chat-room-id="selectedChatRoomId"
                    :show-tooltips="showTooltips"
                    @exitRoom="handleExitRoom"
                    @deleteChatRoom="deleteChatRoom"
                    @leaveChatRoom="leaveChatRoom"
                />
            </div>
        </v-row>
        <div style="margin: auto; max-width: 600px">
            <h2 class="mt-4 mb-2">
                Accounts
            </h2>
            <ul>
                <li
                    v-for="account in accounts"
                    :key="account._id"
                >
                    {{ account.accountName }} - {{ account.displayName }}
                </li>
            </ul>
            <h2 class="mt-4 mb-2">
                WebSocket Status TODO
            </h2>
            <h2 class="mt-4 mb-2">
                Subscription
            </h2>
            <h2 class="mt-4 mb-2">
                Devices
            </h2>
            <ul>
                <div
                    v-for="device in mappedDevices"
                    :key="device._id"
                    class="mb-4"
                >
                    <p>
                        <b>_id : {{ device._id }}
                            {{ device.isThisDevice ? "(This device)" : "" }}</b>
                    </p>
                    <p>lastLogin: {{ device.lastLoginPretty }}</p>
                    <p>userAgent: {{ device.userAgent }}</p>
                    <p>pushNotifications?: {{ device.subscription ? "Yes" : "No" }}</p>
                    <p>connected?: unkown</p>
                    <v-btn
                        x-small
                        @click="deleteDevice(device._id)"
                    >
                        Delete Device
                    </v-btn>
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
import ChatWidgetGroupListV2 from "./ChatWidgetGroupListV2";
import ChatWidgetGroupChatV2 from "./ChatWidgetGroupChatV2";
import * as BackendApi from "../../api/backend";
import { bus } from "@/main";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

import chatIcon from "../../assets/Icons/chat-alternative-filled-102.svg";
import infoIcon from "@/assets/Icons/info-2-weiß-89.svg";

import ToggleChatNotificationsButton from "./Components/ToggleBrowserChatNotifications/ToggleChatNotificationsButton";

export default {
  name: "ChatWidgetV2",
  components: { ChatWidgetGroupChatV2, ChatWidgetGroupListV2, ToggleChatNotificationsButton },
  props: {
    showTooltips: { required: false, default: true },
    currentChild: { required: false, type: Object },
  },
  data: () => ({
    chatIcon,
    infoIcon,

    selectedChatRoomId: null,
    person: {
      accessibility: {
        screenreader: false,
      },
    },
    devices: [],
  }),
  computed: {
    ...mapGetters("accounts", ["accounts"]),
    ...mapState("translation", ["targetLang"]),
    ...mapGetters("auth", ["accountRole"]),
    ...mapState("auth", ["deviceToken", "account"]),
    ...mapGetters("accountSets", ["accountSets", "accountSetsById"]),
    mappedDevices() {
      return this.devices.map((device) => ({
        ...device,
        isThisDevice: device.token === this.deviceToken,
        lastLoginPretty: new Date(device.lastLoginTime).toLocaleTimeString(),
      }));
    },
    thisDevice() {
      return this.mappedDevices.find((device) => device.isThisDevice) || {};
    },
  },
  async mounted() {
    this.fetchAccountSets();
    this.fetchAccounts();
    this.fetchGroups();
    this.fetchChats();
    this.loadDevices();

    this.requestPerson();
  },
  methods: {
    ...mapActions("groups", ["fetchGroups"]),
    ...mapActions("accounts", ["fetchAccounts"]),
    ...mapActions("translation", [
      "setTranslatedText",
      "showTranslation",
      "translateToTargetLang",
    ]),
    ...mapActions("groups", ["getGroupsInfo"]),
    ...mapActions("pupils", ["getMePupil"]),
    ...mapMutations("snackbar", ["showSnackbar"]),
    ...mapActions("chat", ["fetchChats"]),
    ...mapActions("accountSets", ["fetchAccountSets"]),
    async loadDevices() {
      const devicesResponse = await BackendApi.getDevices(this.account._id);
      this.devices = await devicesResponse.json();
    },
    async deleteDevice(deviceId) {
      await BackendApi.deleteDevice(this.account._id, deviceId);
      this.loadDevices();
    },


    // async subscribe() {
    //     navigator.serviceWorker.getRegistrations().then(async (registrations) => {
    //         // Register Push
    //         const subscription = await registrations[0].pushManager.subscribe({
    //             userVisibleOnly: true,
    //             applicationServerKey: this.urlBase64ToUint8Array('BKFBvlmQYh9nNP9ECrMgMj-3v__wDW8Q3M0NPcHpH3LXSq7YsYvSR_dj757TxzwL2hKr--gN6SuIyuy3H0OUgeo')
    //         });
    //         await backend.subscribe({
    //             subscription,
    //             deviceToken: this.deviceToken,
    //         });
    //     });
    //     this.showSnackbar({
    //         message: 'Benachrichtigungen aktiv',
    //         color: 'info'
    //     })
    // },
    async deleteChatRoom(chatRoomId) {
      // Close group chat
      this.selectedChatRoomId = null;

      const deleteResult = await BackendApi.deleteChatRoom(chatRoomId);
      if (deleteResult.status === 204) {
        this.showSnackbar({
          message: "Chatraum erfolgreich gelöscht",
          color: "success",
        });
      } else {
        this.showSnackbar({
          message: "Fehler beim Löschen des Chatraumes",
          color: "error",
        });
      }
    },
    async leaveChatRoom(chatRoomId) {
      // Close group chat
      this.selectedChatRoomId = null;

      const deleteResult = await BackendApi.leaveChatRoom(chatRoomId);
      if (deleteResult.status === 204) {
        this.showSnackbar({
          message: "Chatraum erfolgreich verlassen",
          color: "success",
        });
      } else {
        this.showSnackbar({
          message: "Fehler beim Verlassen des Chatraumes",
          color: "error",
        });
      }
    },
    async requestPerson() {
      if (this.accountRole === "pupil") {
        this.person = await this.getMePupil();
      }
    },
    async handleExitRoom() {
      this.selectedChatRoomId = null;
      // document.getElementById("gruppenHeading").focus();
    },

    // async changeRoomStatus(room, isOpen) {
    //     if (room.isOpen === isOpen) {
    //         return;
    //     }
    //     this.chatRooms = this.chatRooms.map((el) => {
    //         if (el.roomId === room.roomId) {
    //             el.isOpen = isOpen;
    //         }
    //         return el;
    //     });
    // },

    async handleRoomSelected(chatRoom) {
      this.selectedChatRoomId = chatRoom._id;
      await this.$nextTick();
      document.getElementById("backButton").focus();
    },

    async readWidgetInfoText() {
      if (!this.person.accessibility.screenreader) {
        if ("speechSynthesis" in window) {
          // Speech Synthesis supported 🎉
        } else {
          // Speech Synthesis Not Supported 😣
          alert("Sorry, your browser doesn't support text to speech!");
          return;
        }

        let text;
        if (this.accountRole === "pupil") {
          text = "Im Chat kannst du Kontakt zu deinen Lehrern aufnehmen.";
        } else if (this.accountRole === "teacher") {
          text = "Im Chat können Sie Kontakt zu Schülern und Eltern aufnehmen.";
        } else if (this.accountRole === "parent") {
          text =
            "Im Chat können Sie zu zuständigen Lehrer Ihres Kindes Kontakt aufnehmen.";
        } else {
          text = "Im Chat können Sie sich mit Ihren Kontakten austauschen.";
        }

        if (this.targetLang !== "de") {
          text = await this.translateToTargetLang({
            targetLang: this.targetLang,
            textToTranslate: text,
          });
        }

        if (window.speechSynthesis.speaking) {
          window.speechSynthesis.cancel();
          this.showTranslation(false);
        } else {
          this.setTranslatedText(text);
          this.showTranslation(true);

          if (this.showTextTimeout) {
            clearTimeout(this.showTextTimeout);
            this.showTextTimeout = null;
          }

          this.showTextTimeout = setTimeout(() => {
            this.showTranslation(false);
            this.showTextTimeout = null;
          }, 15000);

          let msg = new SpeechSynthesisUtterance();
          msg.text = text;
          if (this.isLangPackageAvailable()) {
            msg.lang = this.targetLang;
            window.speechSynthesis.speak(msg);
          }
        }
      }
    },

    isLangPackageAvailable() {
      for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
        if (
          window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)
        ) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.widgetContainer {
  height: 80vh;
  width: 100%;
  max-width: 30vw;
  min-height: 80vh;
  max-height: 80vh;
  border-radius: 15px;
  box-shadow: 1px 5px 5px silver;
  margin: auto auto 5em;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-header {
  background-color: #3baa69;
  border-radius: 15px 15px 0 0;
  font-size: larger;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  color: white;
}

.chat-header-btn {
  background-color: #f8f8f880 !important;
  width: 35px;
  height: 35px !important;
}

.chat-header-img {
  height: 20px;
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
  position: relative;
  max-height: calc(80vh - 75px);
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.whiteBg {
  background-color: #ffffff;
}

//make text not selectable
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

h1 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

@media only screen and (max-width: 900px) {
  .widgetContainer {
    width: 90%;
    max-width: none;
  }
}

.widgetHeader {
  background-color: #8cbd46;
  border-radius: 15px 15px 0 0;
  flex: 0 0 55px;
  padding-left: 8px;
  padding-right: 8px;
  color: white;
  display: flex;
  align-items: center;
}
</style>
