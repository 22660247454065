import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';

const defaultState = {
};

const mutations = {
};

const actions = {

  async getSubjectFolder ({ commit, dispatch }, data) {

    try {
      const res = await backend.getSubjectFolderUploads(data.subjectId, data.teacherId);
      await checkResponseStatus(200, res);
      const subjectFiles = await res.json();
      return subjectFiles;
    }
    catch(err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async deleteSubjectFile ({ commit, dispatch }, data) {

    try {
      const res = await backend.deleteSubjectFolderUpload(data.subjectId, data.teacherId, data.fileId);
      await checkResponseStatus(204, res);
    }
    catch(err) {
      console.error(err);
            return err?.response?.status;
    }
  },

};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
