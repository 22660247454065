<template>
    <!-- pick file to upload from FileWidget -->
    <v-dialog
        v-model="show"
        max-width="400"
        style="overflow: hidden;"
        scrollable
        @click:outside="$emit('close')"
    >
        <v-card>
            <v-card-title
                class="greyText d-flex justify-space-between px-5 py-3"
                style="box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3)"
            >
                <div>
                    Datei auswählen
                </div>

                <v-btn
                    elevation="2"
                    x-small
                    class="ml-2 pa-0 btnStyle"
                    style="width: 30px; height: 30px"
                    @click="$emit('close')"
                >
                    <img
                        :src="schliesenIcon"
                        alt="Schließensymbol"
                        style="height: 20px;"
                    >
                </v-btn>
            </v-card-title>

            <v-card-text
                class="pt-2"
                style="overflow-x: hidden;"
            >
                <div
                    v-if="loadFolders"
                    class="d-flex justify-center"
                >
                    <v-progress-circular indeterminate />
                </div>

                <vue-custom-scrollbar
                    v-if="!loadFolders"
                    name="test"
                    class="scroll-area-fileUpload mb-2"
                    :settings="settings"
                >
                    <span
                        v-if="subjectFolder.length === 0 && privateFolder.length === 0 && teacherFolder.length === 0"
                    >
                        Das Dateiarchiv enthält keine Dateien
                    </span>

                    <div
                        v-else
                        class="mb-2"
                    >
                        <div
                            v-if="privateFolder.length > 0 && !hidePrivateFolder"
                            class="mb-2"
                        >
                            <span class="font-weight-bold">Dateien aus dem privaten Ordner</span>

                            <br>
                            <v-btn
                                v-if="privateCustomFolderOpen"
                                text
                                small
                                class="text-capitalize"
                                @click="leaveCustomPrivateFolder"
                            >
                                <v-icon
                                    style="font-size: x-small"
                                    class="mr-1"
                                >
                                    fas fa-chevron-left
                                </v-icon>
                                Zurück
                            </v-btn>

                            <div
                                v-for="item in shownPrivateFolder"
                                :key="item.index"
                                class="fileDiv d-flex align-center py-2 px-3"
                                :class="{'justify-start': item.type === 'folder'}"
                                @click="handleListItemClick('privateFolder', item)"
                            >
                                <v-icon
                                    v-if="item.type === 'folder'"
                                    class="pr-1"
                                    style="font-size: 25px"
                                >
                                    mdi-folder
                                </v-icon>
                                <div class="ellipsis">
                                    {{ item.uploadedName || item.name }}
                                </div>

                                <div>
                                    <v-btn
                                        v-if="item.type === 'file'"
                                        class="squareBtn text-capitalize ml-2"
                                        elevation="0"
                                        @click="itemSelectedCallback(item)"
                                    >
                                        <img
                                            :src="hochladenIcon"
                                            class="iconSize"
                                            alt=""
                                        >
                                    </v-btn>
                                </div>
                            </div>

                            <div class="d-flex justify-center">
                                <v-btn
                                    v-if="privateFolderFull.files.length > 2 || privateFolderFull.folders.length > 0"
                                    class="mt-2"
                                    small
                                    @click="toggleSlice('private')"
                                >
                                    <img
                                        :src="arrowDown"
                                        class="iconSize"
                                        :style="privateFolderSliced ? '' : 'transform: rotate(180deg)'"
                                    >
                                </v-btn>
                            </div>
                        </div>

                        <div
                            v-if="subjectFolder.length > 0 && !hideSubjectFolder"
                            class="mb-2"
                        >
                            <span class="font-weight-bold">Dateien aus dem allgemeinen Fach-Ordner</span>

                            <div
                                v-for="file in subjectFolder"
                                :key="file.index"
                                class="fileDiv d-flex align-center py-2 px-3"
                                @click="itemSelectedCallback(file)"
                            >
                                <div class="ellipsis">
                                    {{ file.uploadedName }}
                                </div>

                                <div>
                                    <v-btn
                                        class="squareBtn text-capitalize ml-2"
                                        elevation="0"
                                        @click="itemSelectedCallback(file)"
                                    >
                                        <img
                                            :src="hochladenIcon"
                                            class="iconSize"
                                            alt=""
                                        >
                                    </v-btn>
                                </div>
                            </div>

                            <div class="d-flex justify-center">
                                <v-btn
                                    v-if="subjectFolderFull.length > 2"
                                    class="mt-2"
                                    small
                                    @click="toggleSlice('subject')"
                                >
                                    <img
                                        :src="arrowDown"
                                        class="iconSize"
                                        :style="subjectFolderSliced ? '' : 'transform: rotate(180deg)'"
                                    >
                                </v-btn>
                            </div>
                        </div>

                        <div v-if="teacherFolder.length > 0 && !hideSharedFolder">
                            <span class="font-weight-bold">Dateien aus dem Lehrer-Ordner</span>

                            <br>
                            <v-btn
                                v-if="teacherCustomFolderOpen"
                                text
                                small
                                class="text-capitalize"
                                @click="leaveCustomFolder"
                            >
                                <v-icon
                                    style="font-size: x-small"
                                    class="mr-1"
                                >
                                    fas fa-chevron-left
                                </v-icon>
                                Zurück
                            </v-btn>

                            <div
                                v-for="item in shownTeacherFolder"
                                :key="item.index"
                                class="fileDiv d-flex align-center py-2 px-3"
                                :class="{'justify-start': item.type === 'folder'}"
                                @click="handleListItemClick('teacherFolder', item)"
                            >
                                <v-icon
                                    v-if="item.type === 'folder'"
                                    class="pr-1"
                                    style="font-size: 25px"
                                >
                                    mdi-folder
                                </v-icon>
                                <div class="ellipsis">
                                    {{ item.uploadedName || item.name }}
                                </div>

                                <v-btn
                                    v-if="item.type === 'file'"
                                    class="squareBtn text-capitalize ml-2"
                                    elevation="0"
                                    @click="itemSelectedCallback(item)"
                                >
                                    <img
                                        :src="hochladenIcon"
                                        class="iconSize"
                                        alt=""
                                    >
                                </v-btn>
                            </div>

                            <div class="d-flex justify-center">
                                <v-btn
                                    v-if="teacherFolderFull.files.length > 2 || teacherFolderFull.folders.length > 0"
                                    class="mt-2"
                                    small
                                    @click="toggleSlice()"
                                >
                                    <img
                                        :src="arrowDown"
                                        class="iconSize"
                                        :style="teacherFolderSliced ? '' : 'transform: rotate(180deg)'"
                                    >
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </vue-custom-scrollbar>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vueCustomScrollbar from "vue-custom-scrollbar";
import schliesenIcon from "@/assets/Icons/abbrechen-08.svg";
import hochladenIcon from "@/assets/Icons/hochladen-15.svg";
import arrowDown from "@/assets/Icons/unten-dropdown-12.svg";

export default {
    name: "FileWidgetSelectionDialog",
    components: {
        vueCustomScrollbar,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false
        },
        subjectAppointment: {
            type: Object,
            required: false,
            default: null,
        },
        itemSelectedCallback: {
            type: Function,
            required: true
        },
        hidePrivateFolder: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideSharedFolder: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideSubjectFolder: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data: () => ({
        loadFolders: false,
        privateFolder: [],
        subjectFolder: [],
        teacherFolder: [],
        privateFolderFull: [],
        subjectFolderFull: [],
        teacherFolderFull: [],
        privateFolderSliced: true,
        subjectFolderSliced: true,
        teacherFolderSliced: true,

        //VFS Stuff
        teacherCustomFolderOpen: false,
        privateCustomFolderOpen: false,
        privateCustomFolder: null,
        customFolder: null,

        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
        },

        schliesenIcon,
        hochladenIcon,
        arrowDown,
    }),
    computed: {
        ...mapGetters('auth', [ 'accountRole' ]),
        shownPrivateFolder() {
            if (this.privateCustomFolder) {
                return this.privateCustomFolder.folders.map((item) => {
                    item.type = 'folder';
                    return item;
                }).concat(this.privateCustomFolder.files.map((item) => {
                    item.type = 'file';
                    return item;
                }))
            }
            return this.privateFolder;
        },
        shownTeacherFolder() {
            if (this.customFolder) {
                return this.customFolder.folders.map((item) => {
                    item.type = 'folder';
                    return item;
                }).concat(this.customFolder.files.map((item) => {
                    item.type = 'file';
                    return item;
                }))
            }
            return this.teacherFolder;
        }
    },
    mounted() {
        this.requestFilesFromFileWidget();
    },
    methods: {
        ...mapActions("teachers", ["deleteUpload", "getMeTeacher"]),
        ...mapActions("pupils", [ 'getMePupil' ]),
        ...mapActions("teacherFolder", ["getVFSFolderUploads"]),
        ...mapActions("subjectFolder", ["getSubjectFolder"]),

        async requestFilesFromFileWidget() {
            this.loadFolders = true;
            if (this.accountRole === 'teacher') {
                this.me = await this.getMeTeacher();
            } else {
                this.me = await this.getMePupil();
            }

            this.privateFolder.length = this.subjectFolder.length = this.teacherFolder.length = 0;

            let param = { folderType: 'privateFolder' };
            this.privateFolderFull = await this.getVFSFolderUploads(param);
            if (this.accountRole === 'teacher') {
                param.folderType = 'teacherFolder';
                this.teacherFolderFull = await this.getVFSFolderUploads(param);
            }
            if(this.subjectAppointment){
                this.subjectFolderFull = await this.getSubjectFolder({
                    subjectId: this.subjectAppointment.schoolSubject._id,
                    teacherId: this.me._id,
                });
            }

            //sort arrays alphabetically
            if(this.privateFolderFull.files) {
                this.privateFolderFull.files.sort(this.alphabetically);
            }
            if (this.subjectFolderFull) {
                this.subjectFolderFull.sort(this.alphabetically);
            }
            if(this.teacherFolderFull.files) {
                this.teacherFolderFull.files.sort(this.alphabetically);
            }

            //slice arrays initially
            this.privateFolder = this.privateFolderFull.files ? this.privateFolderFull.files.slice(0, 2) : null;
            this.subjectFolder = this.subjectFolderFull ? this.subjectFolderFull.slice(0, 2) : null;
            if(this.teacherFolderFull.files) {
                this.teacherFolder = this.teacherFolderFull.files.slice(0, 2);
            }

            this.privateFolderSliced = true;
            this.subjectFolderSliced = true;
            this.teacherFolderSliced = true;

            this.loadFolders = false;
        },
        alphabetically(a, b) {
            var x = a.uploadedName.toLowerCase();
            var y = b.uploadedName.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        },
        toggleSlice(folder) {
            switch (folder) {
                case 'private':
                    if (this.privateFolderSliced) {
                        this.privateFolder = this.privateFolderFull.folders.map((item) => {
                            item.type = 'folder';
                            return item;
                        }).concat(this.privateFolderFull.files.map((item) => {
                            item.type = 'file';
                            return item;
                        }));
                        this.privateFolderSliced = false;
                    } else {
                        this.privateFolder = this.privateFolderFull.files.slice(0, 2);
                        this.privateFolderSliced = true;
                    }
                    break;
                case 'subject':
                    if (this.subjectFolderSliced) {
                        this.subjectFolder = this.subjectFolderFull;
                        this.subjectFolderSliced = false;
                    } else {
                        this.subjectFolder = this.subjectFolderFull.slice(0, 2);
                        this.subjectFolderSliced = true;
                    }
                    break;
                default:
                    if (this.teacherFolderSliced) {
                        this.teacherFolder = this.teacherFolderFull.folders.map((item) => {
                            item.type = 'folder';
                            return item;
                        }).concat(this.teacherFolderFull.files.map((item) => {
                            item.type = 'file';
                            return item;
                        }));
                        this.teacherFolderSliced = false;
                    } else {
                        this.teacherFolder = this.teacherFolderFull.files.slice(0, 2);
                        this.teacherFolderSliced = true;
                    }
            }
        },
        async handleListItemClick(folderType, item) {
            switch (folderType) {
                case 'privateFolder':
                    if(this.privateFolderSliced) {
                        this.toggleSlice('private');
                    }
                    break;
                case 'teacherFolder':
                    if(this.teacherFolderSliced) {
                        this.toggleSlice();
                    }
                    break
            }
            if (item.type === 'file') {
                this.itemSelectedCallback(item)
            } else {
                // Open Folder
                const pulledFolder = await this.getVFSFolderUploads({ folderId: item._id, folderType })
                if (folderType === 'privateFolder') {
                    this.privateCustomFolder = pulledFolder;
                    this.privateCustomFolderOpen = true;
                } else {
                    this.customFolder = pulledFolder;
                    this.teacherCustomFolderOpen = true;
                }
            }
        },
        // TODO: Make customFolder more beautiful
        async leaveCustomFolder(folderType = 'teacherFolder') {
            const parentFolder = await this.getVFSFolderUploads({ folderId: this.customFolder.parentFolder, folderType })
            this.customFolder = parentFolder;

            // Check if customFolder parent is root
            if (parentFolder.root) {
                if (folderType === 'privateFolder') {
                    this.privateCustomFolder = null;
                    this.privateCustomFolderOpen = false;
                } else {
                    this.customFolder = null;
                    this.teacherCustomFolderOpen = false;
                }
            }
        },
        async leaveCustomPrivateFolder(folderType = 'privateFolder') {
            const parentFolder = await this.getVFSFolderUploads({ folderId: this.privateCustomFolder.parentFolder, folderType })
            this.privateCustomFolder = parentFolder;

            // Check if customFolder parent is root
            if (parentFolder.root) {
                this.privateCustomFolder = null;
                this.privateCustomFolderOpen = false;
            }
        },
    }
}
</script>

<style scoped lang="scss">

.appointmentItem {
    position: relative;
    color: var(--v-dunkelgrau-base);
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FFF;
    width: 100%;
    height: 100%;
}
.fileDiv {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    width: 99%;
    cursor: pointer;
}

.iconSize {
    height: 20px;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.squareBtn {
    min-width: 20px !important;
    min-height: 20px !important;
    width: 36px;
    height: 36px;
}

.scroll-area-fileUpload {
    position: relative;
    margin: auto;
    max-height: 75vh;
}
</style>
