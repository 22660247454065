import * as backend from '../../api/backend'
import { checkResponseStatus } from "@/util/check";

const actions = {
    // returns letters for corresponding role (teacher / parent)
    async getAllParentalLetters ({ commit, dispatch }, params) {
        try {
            const res = await backend.getAllParentalLetters(params);
            await checkResponseStatus(200, res);
            const letters = await res.json();
            return letters;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    // create letter, to attach files use backend.postParentalLetterFile with XmlHttpRequest
    async createLetter ({ commit, dispatch }, params) {
        try {
            const res = await backend.createParentalLetter(params);
            await checkResponseStatus(201, res);
            const letter = await res.json();
            letter.status = 201;
            return letter;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    // add given account ID to seenBy field of given letter
    async setSeenBy ({ commit, dispatch }, params) {
        try {
            const parentalLetterId = params.letterId;
            const res = await backend.setSeenBy(parentalLetterId, params);

            await checkResponseStatus(200, res);
            return res.status;
        }
        catch (err) {
            console.warn(err);
            return err;
        }
    },

    // only updates title, content, receivers and signature, files need additional method
    async updateParentalLetter ({ commit, dispatch }, params) {
        try {
            const letterId = params.id;
            const res = await backend.patchParentalLetter(letterId, params);
            await checkResponseStatus(200, res);
            return res;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    // delete letter and all it's responses (including the files)
    async deleteParentalLetter ({ commit, dispatch }, params) {
        try {
            const res = await backend.deleteParentalLetter(params);
            await checkResponseStatus(200, res);
            return res;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    // returns groups and participants which the teacher is the leader of
    async getParentalLetterList ({ commit, dispatch }, params) {
        try {
            const res = await backend.getParentalLetterList();
            await checkResponseStatus(200, res);
            const list = await res.json();
            return list;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getSignature({ commit, dispatch }, letterResponseId) {
        const response = await backend.getSignature(letterResponseId);

        await checkResponseStatus(200, response);
        return response;
    }
};

const mutations = {

};

const getters = {

};

const state = {

};

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}
