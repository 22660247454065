import * as backend from '../../api/backend';
import {checkResponseStatus} from "@/util/check";

const actions = {
    async getSchool({ commit, dispatch }, params) {
        try {
            const res = await backend.getSchool(params);
            if (await checkResponseStatus(400, res)) {
                return false;
            }
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async postSchool({ commit, dispatch }, params) {
        try {
            const res = await backend.createAndUpdateSchool(params);
            await checkResponseStatus(201, res);
            return await res.json();
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
};

export default {
    namespaced: true,
    actions
}
