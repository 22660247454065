<template>
    <div class="d-flex">
        <div
            style="line-height: 20px"
            @keypress.enter="playPause"
            @click="playPause"
        >
            <div
                class="d-flex align-center"
                style="min-height: 24px"
            >
                <p
                    tabindex="0"
                    class="mb-0 mr-1"
                >
                    Sprachnachricht
                </p>
                <v-tooltip
                    v-if="!loadedAudio"
                    :disabled="!showTooltips"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <img
                            tabindex="0"
                            role="button"
                            :src="downloadIcon"
                            alt="Sprachnachricht laden"
                            :class="
                                (!eventFromMe ? 'iconToWhite ' : '') + 'icon'
                            "
                            v-on="on"
                        >
                    </template>
                    <span>Laden</span>
                </v-tooltip>
                <v-tooltip
                    v-else-if="loadedAudio"
                    :disabled="!showTooltips"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <img
                            tabindex="0"
                            role="button"
                            :src="lautsprecherIcon"
                            alt="Sprachnachricht anhören"
                            :class="
                                (!eventFromMe ? 'iconToWhite ' : '') + 'icon'
                            "
                            v-on="on"
                        >
                    </template>
                    <span>Vorlesen</span>
                </v-tooltip>
                <img
                    v-else
                    tabindex="0"
                    role="button"
                    :src="lautsprecherAusIcon"
                    alt=""
                    :class="(!eventFromMe ? 'iconToWhite ' : '') + 'icon'"
                >
            </div>
        </div>
        <v-spacer />
        <ChatRoomEventListItemDeleteButton
            v-if="eventFromMe || chatRoomMembershipRole === 'moderator'"
            :chat-room-event="chatRoomEvent"
        />
    </div>
</template>
<script>
import { mapState } from "vuex";
import * as backend from "../../../../api/backend";

import ChatRoomEventListItemDeleteButton from "./ChatRoomEventListItemDeleteButton";

import downloadIcon from "../../../../assets/Icons/download-116.svg";
import lautsprecherIcon from "../../../../assets/Icons/lautsprecher-13.svg";


export default {
  name: "ChatRoomEventListAudioItem",
  components: {
    ChatRoomEventListItemDeleteButton,
  },
  props: {
    chatRoomEvent: { type: Object, required: true },
    chatRoomMembershipRole: { required: true, type: String },
  },
  data: () => ({
    loadedAudio: null,
    audio: new Audio(),

    downloadIcon,
    lautsprecherIcon,
  }),
  computed: {
    ...mapState("auth", ["account"]),
    ...mapState("tooltips", ["showTooltips"]),
    eventFromMe() {
            return this.chatRoomEvent.author === this.account._id;
    }
  },
  mounted() {
    this.loadAudioFile(this.chatRoomEvent._id);
  },
  unmount() {
    if (this.loadedAudio) URL.revokeObjectURL(this.loadedAudio);
  },
  methods: {
    fromMe(event) {
      return event.author === this.account._id;
    },
    async playPause() {
      if (this.audio) {
        this.audio.pause();
        this.audio = null;
      } else {
        if (!this.loadedAudio) {
          this.loadedAudio = await this.loadAudioFile(this.chatRoomEvent._id);
        }
        // Load audio file if necessary
        this.audio = new Audio(this.loadedAudio);
        this.audio.play();
      }
    },
    async loadAudioFile(chatRoomEventId) {
      const fileResponse = await backend.getChatRoomEventFile(chatRoomEventId);
      const blob = await fileResponse.blob();
      return URL.createObjectURL(blob);
    },
  },
};
</script>
<style scoped>
.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.icon {
  height: 20px;
}
</style>