<template>
    <div class="ma-4">
        <vue-custom-scrollbar
            class="scroll-area-specificSickNote"
            :settings="settings"
        >
            <div class="d-flex justify-space-between align-center">
                <p>{{ currentSickNote.title }}</p>
                <p>{{ createDate(currentSickNote.createdAt) }}</p>
            </div>

            <hr class="my-1">

            <p>Für: {{ currentSickNote.pupil.name + ' ' + currentSickNote.pupil.lastName }}</p>
            <div class="d-flex justify-space-between">
                <p>Von: {{ createDate(currentSickNote.from) }}</p>
                <p>Bis: {{ createDate(currentSickNote.till) }}</p>
            </div>

            <hr class="my-1">

            <div style="white-space: pre-line;">
                {{ currentSickNote.content }}
            </div>

            <hr
                v-if="currentSickNote.files.length > 0"
                class="my-1"
            >

            <div v-if="currentSickNote.files.length > 0">
                <p class="font-weight-bold">
                    {{ currentSickNote.files.length > 1 ? 'Anhänge' : 'Anhang' }}
                </p>
            </div>

            <div
                v-for="(file, index) in currentSickNote.files"
                :key="file._id"
                class="d-flex justify-space-between align-center"
                :class="index === currentSickNote.files.length-1 ? '' : 'mb-2'"
            >
                <p class="ellipsis">
                    {{ file.uploadedName }}
                </p>
                <v-btn
                    class="actionBtn mr-1"
                    x-small
                    @click="downloadSickNoteAttachment(currentSickNote._id, currentSickNote.files[index])"
                >
                    <img
                        :src="downloadIcon"
                        class="icon"
                        alt="Datei herunterladen"
                    >
                </v-btn>
            </div>

            <hr class="my-1">

            <div
                class="d-flex justify-space-between align-center"
            >
                <p class="ellipsis">
                    Unterschrift
                </p>
                <v-btn
                    class="actionBtn mr-1"
                    x-small
                    @click="downloadSickNoteSignature(currentSickNote._id)"
                >
                    <img
                        :src="downloadIcon"
                        class="icon"
                        alt="Unterschrift herunterladen"
                    >
                </v-btn>
            </div>
        </vue-custom-scrollbar>

        <p
            v-if="role === 'teacher' && this.currentSickNote.hasOwnProperty('accepted')"
            class="mt-2 font-weight-bold"
            :style="this.currentSickNote.accepted === true ? 'color: var(--v-gruen-base);' : 'color: var(--v-frot-base);'"
        >
            Entschuldigung ist {{ this.currentSickNote.accepted === true ? 'akzeptiert' : 'abgelehnt' }}.
        </p>
        <div
            v-else-if="role === 'teacher'"
            class="d-flex justify-space-between mt-4"
        >
            <v-btn
                class="text-none"
                color="frot"
                elevation="0"
                dark
                @click="handleSickNoteClick(0)"
            >
                Ablehnen
            </v-btn>

            <v-btn
                class="text-none"
                color="gruen"
                elevation="0"
                dark
                @click="handleSickNoteClick(1)"
            >
                Annehmen
            </v-btn>
        </div>
    </div>
</template>
<script>
import VueCustomScrollbar from "vue-custom-scrollbar"

import downloadIcon from "@/assets/Icons/runterladen-14.svg";
import * as backend from "@/api/backend";

export default {
    name: 'MessagesSickNote',
    components: {VueCustomScrollbar},
    props: {
        currentSickNote: {},
        handleAcceptSickNote: {},
        handleDeclineSickNote: {},
        role: {},
        settings: {}
    },
    data() {
        return {
            downloadIcon,
        }
    },
    methods: {
        // creates dates of format DD.MM.YY with leading zeros
        createDate(date) {
            return new Date(date).toLocaleDateString([], {year: '2-digit', month: '2-digit', day: '2-digit'});
        },

        handleSickNoteClick(number) {
            // number = 0 is decline, 1 is accept
            if (number === 0) {
                this.$emit('handleDeclineSickNote', this.currentSickNote._id);
                this.currentSickNote.accepted = false;
            } else if (number === 1) {
                this.$emit('handleAcceptSickNote', this.currentSickNote._id);
                this.currentSickNote.accepted = true;
            }

            // force update so info instead of accept/decline btns are shown
            this.$forceUpdate();
        },

        async downloadSickNoteAttachment(sickNoteId, file) {
            try {
                const res = await backend.getSickNoteFile(sickNoteId, file._id);
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = file.uploadedName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function(){
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);

            }
            catch (e) {
                console.error(e);
            }
        },

        async downloadSickNoteSignature(sickNoteId) {
            try {
                const res = await backend.getSickNoteSignature(sickNoteId);
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = 'Unterschrift.png';
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function(){
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);

            }
            catch (e) {
                console.error(e);
            }
        },
    }
}
</script>
<style lang="scss" scoped>

// custom disabled button, it uses an id and important as the regular vuetify button styling already uses disabled
// https://stackoverflow.com/a/63762909

.icon {
    height: 20px;
}

.scroll-area-specificSickNote {
    position: relative;
    margin: auto;
    padding-bottom: 8px;
    width: 100%;
    max-height: 62vh;
}

.ellipsis {
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.actionBtn {
    color: white;
    border-radius: 8px;
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
}
</style>
