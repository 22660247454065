<template>
    <v-dialog
        v-model="dialog"
        persistent
        @click:outside="handleClickOutside"
    >
        <!-- diary -->
        <v-card>
            <v-card-title
                id="headerBar"
                class="d-flex justify-space-between"
            >
                <div class="d-flex">
                    <v-btn
                        x-small
                        icon
                        style="height: 36px; width: 36px"
                        elevation="0"
                        @click="subDay"
                    >
                        <img
                            :src="arrowIcon"
                            alt="Tag zurück"
                            class="iconToWhite"
                            style="height: 20px"
                        >
                    </v-btn>

                    <v-menu
                        v-model="datePickerMenu"
                        :close-on-content-click="false"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <p
                                class="d-flex align-center"
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ getDisplayDate() }}
                            </p>
                        </template>

                        <v-date-picker
                            v-model="pickerDate"
                            :allowed-dates="weekdaysOnly"
                            locale="de-DE"
                            first-day-of-week="1"
                            no-title
                            show-week
                            color="dunkelgruen"
                        >
                            <v-spacer />
                            <v-btn
                                text
                                class="text-capitalize"
                                color="dunkelgruen"
                                @click="() => {datePickerMenu = false; date = new Date(pickerDate)}"
                            >
                                Bestätigen
                            </v-btn>
                        </v-date-picker>
                    </v-menu>

                    <v-btn
                        x-small
                        icon
                        style="height: 36px; width: 36px"
                        elevation="0"
                        @click="addDay"
                    >
                        <img
                            :src="arrowIcon"
                            alt="Tag vor"
                            class="iconToWhite"
                            style="height: 20px; transform: rotate(180deg)"
                        >
                    </v-btn>

                    <!-- Select class/group -->
                    <v-autocomplete
                        v-model="group"
                        :items="groups"
                        item-text="name"
                        label="Gruppe auswählen"
                        class="pa-0 ma-0 ml-4"
                        dark
                        single-line
                        hide-details
                        return-object
                        no-data-text="Gruppen konnten nicht geladen werden"
                    />
                </div>

                <div>
                    <!--<v-btn
                         elevation="0"
                         class="mr-2 text-none"
                         @click="exportDiary"
                     >
                         <img
                             :src="exportIcon"
                             alt=""
                             class="icon mr-2"
                         >
                         PDF-Export
                     </v-btn>-->

                    <v-btn
                        elevation="0"
                        class="mr-4 text-none"
                        color="gruen"
                        dark
                        @click="saveDiary"
                    >
                        Speichern
                    </v-btn>

                    <v-btn
                        x-small
                        style="height: 36px; width: 36px"
                        elevation="0"
                        @click="confirmCloseDialog = true"
                    >
                        <img
                            :src="closeIcon"
                            alt="Schließen"
                            style="height: 20px"
                        >
                    </v-btn>
                </div>
            </v-card-title>

            <v-card-text class="px-3 pb-3">
                <div
                    v-if="noAppointmentsAvailable"
                    class="sixteenPx ma-4 d-flex justify-center align-center"
                >
                    Kein Stundenplan für den ausgewählten Tag verfügbar.
                </div>

                <v-data-table
                    v-else
                    :headers="headers"
                    :items="diaryIn"
                    disable-pagination
                    hide-default-footer
                    locale="de-DE"
                    no-data-text="Keine Daten verfügbar"
                >
                    <template v-slot:item="appointment">
                        <tr>
                            <td>
                                <div
                                    class="sixteenPx d-flex align-center"
                                >
                                    <p class="mr-2">
                                        {{ diaryIn.indexOf(appointment.item) + 1 }}
                                    </p>
                                    <img
                                        :src="getIcon(appointment.item.schoolSubject.icon)"
                                        alt="Fachsymbol"
                                        class="iconSelect mr-2"
                                        style="max-height: 25px"
                                        :style="getBackgroundColor(appointment.item.schoolSubject.color)"
                                    >
                                    <p>
                                        {{ appointment.item.schoolSubject.name }}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <v-autocomplete
                                    v-if="teachersExists(appointment.item._id)"
                                    :key="rerenderTeachers"
                                    v-model="override[override.findIndex(el => el._id === appointment.item._id)].teachers"
                                    :items="allTeachers"
                                    :item-text="el => el.name.slice(0,1) + '. ' + el.lastName"
                                    label="Lehrer auswählen"
                                    class="pa-0 ma-0"
                                    single-line
                                    hide-details
                                    return-object
                                    multiple
                                    no-data-text="Lehrer konnten nicht geladen werden"
                                />

                                <div
                                    v-else
                                    class="d-flex align-center justify-space-between"
                                >
                                    <p class="sixteenPx">
                                        {{ displayTeacherNames(appointment.item.teachers) }}
                                    </p>

                                    <v-btn
                                        icon
                                        @click="editTeacher(appointment.item)"
                                    >
                                        <img
                                            :src="editIcon"
                                            class="icon"
                                            alt="bearbeiten"
                                        >
                                    </v-btn>
                                </div>
                            </td>
                            <td>
                                <!--<v-autocomplete
                                         v-model="topic"
                                         :items="bildungsplan"
                                         label="Aus Bildungsplan auswählen"
                                         class="pa-0 ma-0"
                                         single-line
                                         hide-details
                                         no-data-text="Bildungsplan konnte nicht geladen werden"
                                     >
                                 </v-autocomplete>-->
                                <v-textarea
                                    v-model="diaryOut[diaryIn.indexOf(appointment.item)].topic"
                                    rows="1"
                                    auto-grow
                                    hide-details
                                    placeholder="Thema eingeben"
                                    class="ma-0 pa-0"
                                />
                            </td>
                            <td
                                :class="showFullNote.includes(appointment.item._id) ? '' : 'ellipsis'"
                                :style="showFullNote.includes(appointment.item._id) ? '' : 'max-width: 200px'"
                            >
                                <div :class="showFullNote.includes(appointment.item._id) ? '' : 'ellipsis'">
                                    <div
                                        v-if="editNotes.includes(appointment.item._id)"
                                        class="d-flex align-center justify-space-between"
                                    >
                                        <v-textarea
                                            v-model="override[override.findIndex(el => el._id === appointment.item._id)].note"
                                            rows="1"
                                            auto-grow
                                            hide-details
                                            class="ma-0 pa-0"
                                        />

                                        <v-btn
                                            icon
                                            @click="stopEditNote(appointment.item._id)"
                                        >
                                            <img
                                                :src="closeIcon"
                                                class="icon"
                                                alt="bearbeiten beenden"
                                            >
                                        </v-btn>
                                    </div>

                                    <div
                                        v-else
                                        class="d-flex align-center justify-space-between"
                                    >
                                        <div
                                            class="d-flex align-center"
                                            style="width: 80%; max-width: 80%"
                                        >
                                            <img
                                                :src="infoIcon"
                                                class="icon mr-2"
                                                alt=""
                                            >

                                            <p
                                                class="sixteenPx pointer d-flex align-center"
                                                :class="showFullNote.includes(appointment.item._id) ? '' : 'ellipsis'"
                                                @click="clickToggleNote(appointment.item._id)"
                                            >
                                                {{ appointment.item.note }}
                                            </p>
                                        </div>


                                        <v-btn
                                            icon
                                            @click="editNote(appointment.item)"
                                        >
                                            <img
                                                :src="editIcon"
                                                class="icon"
                                                alt="bearbeiten"
                                            >
                                        </v-btn>
                                    </div>
                                </div>

                                <p
                                    v-for="(upload, index) in appointment.item.teacherUploads.filter(item => !item.editParent)"
                                    :key="upload._id"
                                    class="d-flex align-center"
                                    :class="index === 0 ? 'mt-2' : ''"
                                >
                                    <img
                                        :src="materialIcon"
                                        class="icon mr-2"
                                        alt=""
                                    >
                                    {{ upload.title }}
                                </p>
                            </td>
                            <td>
                                <div class="d-flex align-center">
                                    <v-textarea
                                        v-model="diaryOut[diaryIn.indexOf(appointment.item)].notice"
                                        rows="1"
                                        auto-grow
                                        hide-details
                                        placeholder="Bemerkung eingeben"
                                        class="ma-0 pa-0"
                                    />

                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="openLateDialog(diaryIn.indexOf(appointment.item))"
                                            >
                                                <img
                                                    :src="editIcon"
                                                    class="icon"
                                                    alt="bearbeiten"
                                                >
                                            </v-btn>
                                        </template>
                                        <span>Verspätungen eintragen</span>
                                    </v-tooltip>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!-- late dialog (Bemerkung Verspätung) -->
        <!-- using v-model and v-if so it behaves like with v-model but only ever loads data if lateDialog is true -->
        <v-dialog
            v-if="lateDialog"
            v-model="lateDialog"
            max-width="800"
        >
            <v-card>
                <v-card-text>
                    <v-row class="tableHeaders sixteenPx">
                        <v-col>Schüler</v-col>
                        <v-col>Verspätung</v-col>
                        <v-col>Notiz</v-col>
                    </v-row>

                    <v-row
                        v-for="(pupil, index) in diaryOut[lateDialogPupils].pupils"
                        :key="pupil._id + rerenderKey"
                    >
                        <v-col class="d-flex align-center sixteenPx">
                            {{ displayPupilName(pupil) }}
                        </v-col>
                        <v-col class="d-flex align-center justify-space-between sixteenPx">
                            <v-btn
                                elevation="0"
                                small
                                class="sixteenPx"
                                :disabled="!pupil.late || pupil.late === 0"
                                @click="decreaseLate(index)"
                            >
                                -
                            </v-btn>
                            {{ pupil.late ? pupil.late : 0 }} min
                            <v-btn
                                elevation="0"
                                small
                                class="sixteenPx"
                                @click="increaseLate(index)"
                            >
                                +
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-textarea
                                v-model="pupil.notice"
                                rows="1"
                                auto-grow
                                hide-details
                                class="ma-0 pa-0"
                                placeholder="Notiz eingeben"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- confirm close dialog -->
        <v-dialog
            v-model="confirmCloseDialog"
            max-width="400"
        >
            <v-card>
                <v-card-title>Klassenbuch schließen?</v-card-title>
                <v-card-text>
                    Wollen Sie das Klassenbuch wirklich schließen und die Eingaben verwerfen?
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between px-6">
                    <v-btn
                        elevation="0"
                        class="text-none"
                        color="rot"
                        dark
                        @click="resetDialog"
                    >
                        Verwerfen
                    </v-btn>

                    <v-btn
                        elevation="0"
                        class="text-none"
                        color="dunkelgrau"
                        dark
                        @click="confirmCloseDialog = false"
                    >
                        Fortsetzen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import {mapActions, mapMutations} from "vuex";

//#region import icons
import editIcon from '../../assets/Icons/bearbeiten-komplimentär-grau-88.svg';
import closeIcon from '../../assets/Icons/abbrechen-08.svg';
import materialIcon from '../../assets/Icons/material-93.svg';
import infoIcon from '../../assets/Icons/info-45.svg';
import arrowIcon from '../../assets/Icons/links-filled-10.svg';
//#endregion

//#region import subject icons
import bioIcon from "../../assets/Icons/f-biologie-weiss-35.svg";
import chemieIcon from "../../assets/Icons/f-chemie-weiss-37.svg";
import deutschIcon from "../../assets/Icons/f-deutsch-weiss-29.svg";
import englischIcon from "../../assets/Icons/f-englisch-weiss-30.svg";
import erdkundeIcon from "../../assets/Icons/f-erdkunde-weiss-31.svg";
import geschichteIcon from "../../assets/Icons/f-geschichte-weiss-40.svg";
import matheIcon from "../../assets/Icons/f-mathe-weiss-32.svg";
import musikIcon from "@/assets/Icons/f-musik-weiss-82.svg";
import physikIcon from "../../assets/Icons/f-physik-weiss-36.svg";
import politikIcon from "../../assets/Icons/f-politik-weiss-34.svg";
import religionIcon from "../../assets/Icons/f-religion-weiss-39.svg";
import sportIcon from "../../assets/Icons/f-sport-weiss-38.svg";
import wirtschaftIcon from "../../assets/Icons/f-wirtschaft-weiss-33.svg";
//#endregion

export default {
    name: "Diary",
    props: {
        diaryDialog: { required: false, type: Boolean, default: false },
    },
    data() {
        return {
            //#region Icons
            editIcon,
            closeIcon,
            materialIcon,
            infoIcon,
            arrowIcon,
            //#endregion

            me: null,                       // result of getMeTeacher
            dialog: false,                  // local version of diaryDialog
            lateDialog: false,
            lateDialogPupils: 0,
            confirmCloseDialog: false,
            datePickerMenu: false,

            date: this.getDay(),
            pickerDate: this.date,          // needs reformatting before set to date again
            groups: [],                     // to select which groups diary to display
            group: null,                    // selected group
            allTeachers: [],                // all teachers that exist in db
            diaryIn: [],                    // data pulled from backend
            noAppointmentsAvailable: false, // if no appointments are available for provided day
            diaryOut: [],                   // data that is to be posted to backend (e.g. topic, sick notes, late times)
            override: [],                   // teacher or fachinfo that is to be overridden in schoolSubjectAppointment

            showFullNote: '',
            editNotes: [],                  // id of which notes are in edit state

            rerenderKey: 0,                 // late attribute didn't want to update correctly :/
            rerenderTeachers: 0,            // teachers v-autocomplete wouldn't display correctly without force rerender

            headers: [
                { text: 'Fach', value: 'schoolSubject.name', sortable: false },
                { text: 'Lehrer', value: '', sortable: false, width: '15%' },
                { text: 'Lernthema', value: '', sortable: false, width: '25%' },
                { text: 'Fachinfo', value: 'notiz', sortable: false, width: '25%' },
                { text: 'Bemerkung', value: '', sortable: false, width: '25%' },
            ],
        }
    },
    watch: {
        group() {
            this.requestAppointments();
        },

        date() {
            this.requestAppointments();
        },
    },
    async mounted() {
        this.dialog = this.diaryDialog;
        await this.getInfo();
    },
    methods: {
        ...mapActions('teachers', ['getTeachers', 'getMeTeacher']),
        ...mapActions('diary', ['getDiary', 'postDiary']),
        ...mapActions('groups', ['getGroupsInfo']),
        ...mapMutations('snackbar', ['showSnackbar']),

        //#region get needed data
        async getInfo() {
            this.me = await this.getMeTeacher();
            await this.requestGroups();
            this.allTeachers = await this.getTeachers();
            await this.requestAppointments();
        },

        getDisplayDate() {
            return this.date.toLocaleDateString('de-DE', { weekday: 'long', year: '2-digit', month: '2-digit', day: '2-digit' });
        },

        async requestGroups() {
            this.groups = (await this.getGroupsInfo()).filter(group => group.name !== 'Alle');
            // Only keep groups the teacher is a leader of
            this.groups = this.groups.filter(group => group.leaders.includes(this.me._id));
            this.groups.sort(this.alphabetically);

            this.group = this.groups[0];
        },

        alphabetically(a, b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        },

        async requestAppointments() {
            let startOfDay = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 0, 0, 0, 0);
            // Teacher may not even be the leader of any group
            if(this.group !== undefined) {
                let body = {
                    startOfDay,
                    groupId: this.group._id,
                }
                this.diaryIn = await this.getDiary(body);

                // no appointments for provided day available
                if (this.diaryIn === 404) {
                    this.noAppointmentsAvailable = true;
                } else {
                    // Sort appointments by start time of slot (Copied from Plan.vue, then adapted)
                    this.diaryIn.forEach(element => {
                        let dateOldStart = new Date(element.timeslot.start);
                        dateOldStart.setFullYear(2020, 11, 18);
                        let dateOldEnd = new Date(element.timeslot.end);
                        dateOldEnd.setFullYear(2020, 11, 18);
                        element.timeslot.start = dateOldStart.toISOString();
                        element.timeslot.end = dateOldEnd.toISOString();
                    });
                    this.diaryIn.sort(function (a, b) {
                        return new Date(a.timeslot.start) - new Date(b.timeslot.start);
                    });

                    this.noAppointmentsAvailable = false;
                    this.diaryOut = [];
                    for (let i = 0; i < this.diaryIn.length; i++) {
                        this.diaryOut.push({appointment: this.diaryIn[i]._id});
                        this.diaryOut[i].pupils = this.diaryIn[i].participants;

                        this.diaryOut[i].topic = this.diaryIn[i].topic;
                        this.diaryOut[i].notice = this.diaryIn[i].notice;
                    }
                }
            } else {
                this.noDataText = "Sie sind nicht der/die LeiterIn einer Gruppe."
            }
        },

        getDay() {
            let date = new Date();
            while (!(date.getDay() % 6)) {
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate()-1);
            }
            return date;
        },
        //#endregion

        //#region dialog methods
        closeDialogs() {
            this.confirmCloseDialog = false;
            this.$emit('close');
        },

        handleClickOutside() {
            if (this.confirmedPassword) {
                this.confirmCloseDialog = true;
            } else {
                this.$emit('close');
            }
        },

        resetDialog() {
            this.diaryIn = [];
            this.diaryOut = [];
            this.closeDialogs();
        },

        openLateDialog(index) {
            this.lateDialogPupils = index;
            this.lateDialog = true;
        },
        //#endregion

        //#region icon/Fach methods
        getIcon(icon) {
            switch (icon) {
                case 'bioIcon': return bioIcon;
                case 'chemieIcon': return chemieIcon;
                case 'deutschIcon': return deutschIcon;
                case 'englischIcon': return englischIcon;
                case 'erdkundeIcon': return erdkundeIcon;
                case 'geschichteIcon': return geschichteIcon;
                case 'matheIcon': return matheIcon;
                case 'musikIcon': return musikIcon;
                case 'physikIcon': return physikIcon;
                case 'politikIcon': return politikIcon;
                case 'religionIcon': return religionIcon;
                case 'sportIcon': return sportIcon;
                case 'wirtschaftIcon': return wirtschaftIcon;
            }
        },

        getBackgroundColor(color) {
            let returnString = "background-color: ";
            return returnString + color;
        },
        //#endregion

        //#region teacher/Lehrer methods
        // concat teacher names if there is more than one
        displayTeacherNames(teacherArray) {
            let returnString = '';
            for (let i = 0; i < teacherArray.length; i++) {
                if (i === teacherArray.length - 1) {
                    returnString += teacherArray[i].name.slice(0,1) + '. ' + teacherArray[i].lastName;
                } else {
                    returnString += teacherArray[i].name.slice(0,1) + '. ' + teacherArray[i].lastName + ', ';
                }
            }
            return returnString;
        },

        editTeacher(item) {
            // see if override already has an entry with this id
            if (this.override.some(el => el._id === item._id)) {
                let indexOfEl = this.override.findIndex(el => el._id === item._id);
                // if this entry doesn't have a note field create it now
                if (!this.override[indexOfEl].teachers) {
                    this.override[indexOfEl].teachers = item.teachers;
                }
            } else {
                this.override.push({ _id: item._id, teachers: item.teachers });
            }
            this.rerenderTeachers++;
        },

        teachersExists(id) {
            if (this.override.some(el => el._id === id)) {
                if (this.override[this.override.findIndex(el => el._id === id)].teachers) {
                    return true;
                }
            }
        },
        //#endregion

        //#region note/Aufgabe methods
        clickToggleNote(elId) {
            if (this.showFullNote === elId) {
                this.showFullNote = '';
            } else {
                this.showFullNote = elId;
            }
        },

        editNote(item) {
            // see if override already has an entry with this id
            if (this.override.some(el => el._id === item._id)) {
                let indexOfEl = this.override.findIndex(el => el._id === item._id);
                // if this entry doesn't have a note field create it now
                if (!this.override[indexOfEl].note) {
                    this.override[indexOfEl].note = item.note;
                }
            } else {
                this.override.push({ _id: item._id, note: item.note });
            }
            // put id into editNote array
            if (!this.editNotes.includes(item._id)) {
                this.editNotes.push(item._id);
            }

            // forces a rerender and thus show the text-area correctly
            this.clickToggleNote(item._id);
        },

        stopEditNote(id) {
            // if the override array contained a note for this id, delete it from array
            if (this.override.some(el => el._id === id)) {
                let indexOfEl = this.override.findIndex(el => el._id === id);
                delete this.override[indexOfEl].note;
            }

            if (this.editNotes.includes(id)) {
                this.editNotes.splice(this.editNotes.indexOf(id), 1);
            }
        },
        //#endregion

        //#region notice/Bemerkung methods
        displayPupilName(pupil) {
            return pupil.name + ' ' + pupil.lastName.slice(0,1) + '.';
        },

        decreaseLate(index) {
            if (this.diaryOut[this.lateDialogPupils].pupils[index].late && this.diaryOut[this.lateDialogPupils].pupils[index].late >= 5) {
                this.diaryOut[this.lateDialogPupils].pupils[index].late -= 5
            }
            this.rerenderKey++;
        },

        increaseLate(index) {
            if (this.diaryOut[this.lateDialogPupils].pupils[index].late) {
                this.diaryOut[this.lateDialogPupils].pupils[index].late += 5
            } else {
                this.diaryOut[this.lateDialogPupils].pupils[index].late = 5;
            }
            this.rerenderKey++;
        },
        //#endregion

        //#region header methods
        async saveDiary() {
            let datum = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 0, 0);

            let body = {
                day: datum,
                groupId: this.group._id,
                content: this.diaryOut,
                override: this.override,
            }

            let res = await this.postDiary(body);
            if (res.status === 201) {
                this.resetDialog();
                this.showSnackbar({ message: "Klassenbuch erfolgreich gespeichert.", color: "success" });
            }
        },

        //only allow weekdays to be selected in date picker
        weekdaysOnly(val) {
            let date = new Date(val);
            //saturday is weekday 0, sunday is weekday 6, so monday-friday is 1-5
            return date.getDay() > 0 && date.getDay() < 6;
        },

        subDay() {
            this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()-1);
            while (!(this.date.getDay() % 6)) {
                this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()-1);
            }
        },

        addDay() {
            this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()+1);
            while (!(this.date.getDay() % 6)) {
                this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()+1);
            }
        }
        //#endregion
    }
}
</script>

<style lang="scss" scoped>
#headerBar {
    color: white;
    background-color: var(--v-dunkelgruen-base);
}

//#region style v-data-table
.v-data-table::v-deep th {
    font-size: 16px !important;
}

// add border between columns
.v-data-table::v-deep th:not(:first-child), td:not(:first-child) {
    border-left: 1px solid #dddddd;
}

// remove border between rows
.v-data-table td {
    border-bottom: none !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0 8px !important;
}
//#endregion

.sixteenPx {
    font-size: 16px;
}

.iconSelect {
    padding: 2px;
    border-radius: 5px;
}

.pointer {
    cursor: pointer;
}

.icon {
    max-height: 20px;
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.redText {
    color: var(--v-rot-base);
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableHeaders {
    font-weight: bold;
}

.tableHeaderCol {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
