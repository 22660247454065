import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';
import {getDocumentRegistryTags} from "../../api/backend";

const state = {
    documentRegistryRootFolder: [],
    documentRegistryFolders: [],
    documentRegistryDocuments: [],
    documentRegistryTags: [],
};

const getters = {
    documentRegistryDocumentsById: state => {
        return state.documentRegistryDocuments.reduce((acc, document) => {
            acc[document._id] = document;
            return acc;
        }, {});
    },
    documentRegistryFoldersById: state => {
        return state.documentRegistryFolders.reduce((acc, folder) => {
            acc[folder._id] = folder;
            return acc;
        }, {});
    },
};

const mutations = {
    setDocumentRegistryDocuments: (state, documents) => {
        state.documentRegistryDocuments = documents;
    },
    setDocumentRegistryRootFolder: (state, folder) => {
        state.documentRegistryRootFolder = folder;
    },
    setDocumentRegistryFolders: (state, folders) => {
        state.documentRegistryFolders = folders;
    },
    setDocumentRegistryTags: (state, tags) => {
        state.documentRegistryTags = tags;
    },
};

const actions = {
    async loadDocumentRegistryDocuments({ commit, dispatch }, params) {
        const res = await backend.getDocumentRegistryDocuments();
        await checkResponseStatus(200, res);
        const documents = await res.json();
        commit('setDocumentRegistryDocuments', documents);
    },

    async loadDocumentRegistryRootFolder({ commit, dispatch }) {
        const res = await backend.getDocumentRegistryRootFolder();
        await checkResponseStatus(200, res);
        const documents = await res.json();
        commit('setDocumentRegistryRootFolder', documents);
    },

    async loadDocumentRegistryFolders({ commit, dispatch }) {
        const res = await backend.getDocumentRegistryFolders();
        await checkResponseStatus(200, res);
        const documents = await res.json();
        commit('setDocumentRegistryFolders', documents);
    },

    async loadDocumentRegistryTags({ commit, dispatch }) {
        const res = await backend.getDocumentRegistryTags();
        await checkResponseStatus(200, res);
        const tags = await res.json();
        commit('setDocumentRegistryTags', tags);
    },

    // async getImageLibraryImage({ commit, dispatch }, imageId) {
    //     const res = await backend.getImageLibraryImage(imageId);
    //     await checkResponseStatus(200, res);
    //     const image = await res.blob();
    //     return image;
    // },

    async getDocumentRegistryDocumentById({ commit, params }, documentId) {
        const res = await backend.getDocumentRegistryDocument(documentId);
        await checkResponseStatus(200, res);
        return await res.json();
    },

    async postDocumentRegistryDocument({ commit }, params) {
        const documentId = params.documentId;
        delete params.documentId;
        const res = await backend.postDocumentRegistryDocumentFromBackend(documentId, params);
        // console.log('response', res);
        return res;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
