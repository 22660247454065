<template>
    <div
        @click="spawnImage"
        @dragstart="dragImage"
    >
        <v-img
            :src="src"
            contain
            height="95px"
            width="140px"
            class="my-2"
            :alt="imageLibraryImage.title"
        >
            <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                    <v-progress-circular
                        indeterminate
                        color="grey"
                    />
                </v-row>
            </template>
        </v-img>
        <p
            style="text-align: center"
            class="my-2"
            v-text="imageLibraryImage.title"
        />
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'EditorLibraryBarItem',
    props: {
        imageLibraryImage: { required: true, type: Object },
        index: { required: false, type: Number, default: 0 },
    },
    data: () => ({
        src: "",
    }),
    computed: {
        ...mapState('imageLibrary', ['imageLibraryIndex']),
    },
    mounted() {
        setTimeout(this.loadThumbnail, 1000);
    },
    unmounted() {
        URL.revokeObjectURL(this.src);
    },
    methods: {
        ...mapActions('imageLibrary', ['loadImageLibraryIndex', 'getImageLibraryImage']),
        loadThumbnail() {
            this.getImageLibraryImage(this.imageLibraryImage._id).then((result) => {
                this.src = URL.createObjectURL(result);
            });
        },
        dragImage(event) {
            const payload = {
                type: 'imageObject',
                title: this.imageLibraryImage.title,
                origin: 'ImageLibrary',
            };

            event.dataTransfer.setData('text/plain', JSON.stringify(payload));
            event.dataTransfer.setData('image/png', this.src);
        },
        async spawnImage() {
            //  Is this doing something?

            let blob = await fetch(this.src).then(r => r.blob());
            blob.name = this.imageLibraryImage._id;
            this.$emit('addImageField', this.src, blob, 'ImageLibrary');
        },
    },
}
</script>
