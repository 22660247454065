import sanitizeHtml from 'sanitize-html';

const sanitizeHtmlRules = {
  allowedTags: [
    ...(sanitizeHtml.defaults.allowedTags),
    'iframe',
    'img',
  ],
  allowedAttributes: {
    ...(sanitizeHtml.defaults.allowedAttributes),
    iframe: ['src', 'style', 'allowfullscreen', 'frameborder'], // Support quill editor videos,
    span: ['style'],
    em: ['style'],
    strong: ['style'],
    u: ['style'],
  },
  allowedClasses: {
    ...(sanitizeHtml.defaults.allowedClasses),
    span: [
      // Quill editor big text
      'ql-size-huge',
      // Quill editor very big text
      'ql-size-large',
    ],
    em: ['ql-size-huge', 'ql-size-large'],
    strong: ['ql-size-huge', 'ql-size-large'],
    u: ['ql-size-huge', 'ql-size-large'],
    iframe: ['ql-video'], // Support quill editor videos
  },
  allowedStyles: {
    ...(sanitizeHtml.defaults.allowedStyles),
    '*': {
      // Match HEX and RGB
      color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
    },
  },
  allowedSchemesByTag: {
    ...(sanitizeHtml.defaults.allowedSchemesByTag),
    img: ['data', 'https', 'http'],
  },
  allowedIframeDomains: ['youtube.com'], // Allow yt videos from quill editor
};

// Use sanitizeHtml package to protect against xss attacks
// This is done by filter out every element of a html string that doesn't fulfil some given rules
const sanitizeHtmlWithCustomRules = (htmlString) => sanitizeHtml(htmlString, sanitizeHtmlRules);

export default sanitizeHtmlWithCustomRules;
