<template>
    <div>
        <v-btn
            id="eK-button-disabled"
            color="frot"
            class="text-none"
            dark
            @click="openAreYouSure"
        >
            Chat Löschen
        </v-btn>

        <!-- Are you sure dialog for delete chat -->
        <AreYouSurePopup
            v-if="showAreYouSure"
            :show-dialog="true"
            :content-text-prop="'Dieser Chat und alle Daten darin (Text- und Sprachnachrichten) werden endgültig gelöscht!'"
            @close="showAreYouSure = false"
            @ok="deleteRoom"
        />
    </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup";

export default {
  name: "DeleteChatButtonV2",
  components: { AreYouSurePopup },
  props: {
    // chatRoom: { required: true, type: Object },
  },
  data: () => ({
    showAreYouSure: false,
  }),
  computed: {
    windowWidth() {
      return window.innerWidth;
    },
  },
  methods: {
    ...mapActions("matrix", ["deleteMatrixRoom"]),
    ...mapMutations("snackbar", ["showSnackbar"]),

    openAreYouSure(event) {
      this.showAreYouSure = true;
    },

    async deleteRoom() {
      this.$emit("deleteChatRoom");
    },
  },
};
</script>

<style scoped lang="scss">
#muelleimerBtn {
  height: 45px;
  min-width: 45px;
  width: 45px;
}

@media only screen and (max-width: 900px) {
  #muelleimerBtn {
    height: 40px;
    min-width: 40px;
    width: 40px;
  }
}
</style>
