const defaultState = {
    babyFilesToDoBadge: '',
    babyFilesCorrectedBadge: '',
    sickNoteBadge: '',
    parentalLetterBadge: '',
    blackboardBadge: '',
    timetableBadge: '',
    chatBadge: '',
};

const mutations = {
    setBabyFilesToDoBadge: (state, badge) => {
        state.babyFilesToDoBadge = badge;
    },
    setBabyFilesCorrectedBadge: (state, badge) => {
        state.babyFilesCorrectedBadge = badge;
    },
    setSickNoteBadge: (state, badge) => {
        state.sickNoteBadge = badge;
    },
    setParentalLetterBadge: (state, badge) => {
        state.parentalLetterBadge = badge;
    },
    setBlackboardBadge: (state, badge) => {
        state.blackboardBadge = badge;
    },
    setTimetableBadge: (state, badge) => {
      state.timetableBadge = badge;
    },
    setChatBadge: (state, badge) => {
        state.chatBadge = badge;
    }
};

const getters = {
    babyFilesToDoBadge: state => state.babyFilesToDoBadge,
    babyFilesCorrectedBadge: state => state.babyFilesCorrectedBadge,
    sickNoteBadge: state => state.sickNoteBadge,
    parentalLetterBadge: state => state.parentalLetterBadge,
    blackboardBadge: state => state.blackboardBadge,
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    getters
};
