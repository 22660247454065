<template>
    <v-dialog
        v-model="showProgress"
        overlay-opacity="0.7"
        :persistent="true"
        width="500"
    >
        <v-card
            id="card"
            class="pa-3"
        >
            <v-card-title>
                <h1>{{ title }}</h1>
            </v-card-title>
            <v-card-text>
                <v-container class="pa-0">
                    <v-row>
                        <v-col>
                            <v-progress-linear
                                id="progressbar"
                                v-model="progress"
                                height="100%"
                                color="primary"
                                :indeterminate="indeterminate"
                            >
                                <strong>{{ Math.ceil(progress) }}%</strong>
                            </v-progress-linear>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                elevation="2"
                                x-small
                                class="ml-2 pa-0"
                                style="width: 30px; height: 30px"
                                @click="abortProgress"
                            >
                                <img
                                    :src="schliesenIcon"
                                    style="height: 20px;"
                                >
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <p>Wenn der Upload lange dauert, kann es an einer langsamen Internet-Verbinung oder einer großen Datei liegen.</p>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import schliesenIcon from '../assets/Icons/abbrechen-08.svg'

export default {
  name: 'ProgressBar',
  props: {
    title: { type: String, required: true },
    showProgress: { type: Boolean, required: true },
    indeterminate: { type: Boolean, required: false, default: false },
    progress: { type: Number, required: true },
    abortProgress: { type: Function, required: true },
  },
  data() {
    return {
      schliesenIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
#card {
  border-radius: 5px;
}

#progressbar {
  border-radius: 5px;
}
</style>
