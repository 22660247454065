<template>
    <div style="height: calc(100vh - 240px);">
        <v-text-field
            v-model="searchInput"
            placeholder="Suche..."
            outlined
            dense
            height="50"
            background-color="hellgrau"
            style="max-height: 45px; border-radius: 5px; margin: 4px 4px 30px;"
            hide-details
            @input="searchLibrary"
        />

        <vue-custom-scrollbar
            class="scroll-area"
            :settings="settings"
            style="width: 100%"
        >
            <!-- this.$emit('onAddDocFromLibrary', fileArray); -->
            <EditorDocumentRegistryItem
                v-for="(item) in displayableDocuments"
                :key="item._id"
                :document-registry-document="item"
                class="mx-auto my-2 d-flex flex-column align-center justify-center"
                style="width: 175px; background-color: var(--v-hellgrau-base); border-radius: 5px"
                tabindex="17"
                draggable="true"
                @onAddDocFromLibrary="(fileArray) => $emit('onAddDocFromLibrary', fileArray)"
            />
            <p
                v-if="displayableDocuments.length <= 0"
                class="text-center"
                style="color: white"
            >
                Keine passenden Dokumente gefunden
            </p>
        </vue-custom-scrollbar>
        <v-btn
            v-if="countDisplayableDocuments < documentRegistryDocuments.length"
            class="mx-auto my-2 d-flex flex-column align-center justify-center text-none"
            style="width: 150px; color: #fff; margin-bottom: 8px;"
            elevation="3"
            tabindex="17"
            color="#f07d32"
            @click="loadMoreDocuments"
        >
            Mehr laden
        </v-btn>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import EditorDocumentRegistryItem from './EditorDocumentRegistryItem.vue';
import vueCustomScrollbar from "vue-custom-scrollbar";

export default {
    name: 'EditorDocumentRegistry',
    components: {
        EditorDocumentRegistryItem,
        vueCustomScrollbar,
    },
    data: () => ({
        searchInput: '',
        countDisplayableDocuments: 10,
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
        },
    }),
    computed: {
        ...mapState('documentRegistry', ['documentRegistryDocuments']),
        displayableDocuments() {
            // Create a copy because this.searchLibrary mutates the original array
            const documentRegistryCopy = this.documentRegistryDocuments.slice();

            // Search images
            const foundDocuments = this.searchLibrary(this.documentRegistryDocuments.slice(), this.searchInput);

            // Limit amount of displayable documents
            const limitedDocuments = foundDocuments.slice(0, this.countDisplayableDocuments);
            return limitedDocuments;
        }
    },
    mounted() {
        this.loadDocumentRegistryDocuments();
    },
    methods: {
        ...mapActions('documentRegistry', ['loadDocumentRegistryDocuments']),
        loadMoreDocuments() {
            this.countDisplayableDocuments += 10;
        },

        searchLibrary(library, searchTerm) {
            // if left empty, reset to alphabetical sorting
            if (this.searchInput === '') {
                // sort alphabetically, descending
                return library.sort((a, b) => a.name.localeCompare(b.name));
            }

            // https://stackoverflow.com/a/36566052
            // using a stackoverflow similarity based algorithm as placeholder
            // since this search will have to change depending on central repository specs
            // and how the tagging infrastructure will be set up on a later date

            function editDistance(s1, s2) {
                s1 = s1.toLowerCase();
                s2 = s2.toLowerCase();

                var costs = new Array();
                for (var i = 0; i <= s1.length; i++) {
                    var lastValue = i;
                    for (var j = 0; j <= s2.length; j++) {
                        if (i == 0)
                            costs[j] = j;
                        else {
                            if (j > 0) {
                                var newValue = costs[j - 1];
                                if (s1.charAt(i - 1) != s2.charAt(j - 1))
                                    newValue = Math.min(Math.min(newValue, lastValue),
                                        costs[j]) + 1;
                                costs[j - 1] = lastValue;
                                lastValue = newValue;
                            }
                        }
                    }
                    if (i > 0)
                        costs[s2.length] = lastValue;
                }
                return costs[s2.length];
            }

            function similarity(s1, s2) {
                let longer = s1;
                let shorter = s2;
                if (s1.length < s2.length) {
                    longer = s2;
                    shorter = s1;
                }
                let longerLength = longer.length;
                if (longerLength === 0) {
                    return 1.0;
                }
                return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
            }
            // generate similarity of every item in loaded library
            for (let item of library) {
                item.similarity = similarity(item.name, this.searchInput);
            }
            // sort by descending similarity
            return library.sort((a, b) => parseFloat(b.similarity) - parseFloat(a.similarity));
        }
    },
}
</script>

<style lang="scss" scoped>
.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(100vh - 36px - 240px - 57px - 36px);
    width: 250px;
}
</style>
