const schuelerEinfachFAQ = [
  // {
  //   title: 'Login kontaktlos',
  //   content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
  //     ' src="https://www.youtube-nocookie.com/embed/Fd4j2ViEsxM"\n' +
  //     ' frameborder="0" allow="accelerometer; autoplay;\n' +
  //     ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
  //     ' allowfullscreen\n></iframe>'
  // },
  {
    title: 'Funktionen vorgestellt',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
      ' src="https://www.youtube-nocookie.com/embed/5tjuH5jWPyE"\n' +
      ' frameborder="0" allow="accelerometer; autoplay;\n' +
      ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
      ' allowfullscreen\n></iframe>'
  },{
    title: 'Logout',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
      ' src="https://www.youtube-nocookie.com/embed/g4AE5dXWppI"\n' +
      ' frameborder="0" allow="accelerometer; autoplay;\n' +
      ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
      ' allowfullscreen\n></iframe>'
  }
];

const schuelerFAQ = [
  // {
  //   title: 'Login kontaktlos',
  //   content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
  //           ' src="https://www.youtube-nocookie.com/embed/Fd4j2ViEsxM"\n' +
  //           ' frameborder="0" allow="accelerometer; autoplay;\n' +
  //           ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
  //           ' allowfullscreen\n></iframe>'
  // },
  {
    title: 'Logout',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
            ' src="https://www.youtube-nocookie.com/embed/g4AE5dXWppI"\n' +
            ' frameborder="0" allow="accelerometer; autoplay;\n' +
            ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
            ' allowfullscreen\n></iframe>'
  },{
    title: 'Schwarzes Brett vorgestellt',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
            ' src="https://www.youtube-nocookie.com/embed/GFMIHmaGWsw"\n' +
            ' frameborder="0" allow="accelerometer; autoplay;\n' +
            ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
            ' allowfullscreen\n></iframe>'
  },{
    title: 'Chat vorgestellt',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
            ' src="https://www.youtube-nocookie.com/embed/j7T8VPpqRyM"\n' +
            ' frameborder="0" allow="accelerometer; autoplay;\n' +
            ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
            ' allowfullscreen\n></iframe>'
  },{
    title: 'Dateiarchiv vorgestellt',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
            ' src="https://www.youtube-nocookie.com/embed/3ZEzfI5ollM"\n' +
            ' frameborder="0" allow="accelerometer; autoplay;\n' +
            ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
            ' allowfullscreen\n></iframe>'
  },{
    title: 'Textmarker im Editor verwenden',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/bumSkCov5Oc"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },
];

const schuelerTippsFAQ = [
  {
    title: 'eKlara zum Homebildschirm hinzufügen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/LcvvbcVRI14"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Kamera und Mikrofon immer zulassen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/vWjE0nnCT9k"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Den Cache leeren',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/ZBh3uxEJI1U"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Die Internetgeschwindigkeit testen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/_H62Au037L8"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },
];

const lehrerFAQ = [
  // {
  //   title: 'Login kontaktlos',
  //   content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
  //       ' src="https://www.youtube-nocookie.com/embed/Fd4j2ViEsxM"\n' +
  //       ' frameborder="0" allow="accelerometer; autoplay;\n' +
  //       ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
  //       ' allowfullscreen\n></iframe>'
  // },
  // {
  //   title: 'Login',
  //   content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
  //       ' src="https://www.youtube-nocookie.com/embed/SOG0yJS0yOs"\n' +
  //       ' frameborder="0" allow="accelerometer; autoplay;\n' +
  //       ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
  //       ' allowfullscreen\n></iframe>'
  // },
  {
    title: 'Tutorials',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/Hxh855P9Zv8"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Logout',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/HacVovtmxDY"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Fachvorbereitung',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/nWdRkTNZzmE"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Videounterricht',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/oH1D-YLmovY"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Lehrereintrag im Schwarzen Brett',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/JpCOMFzRth8"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Chat',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/j7T8VPpqRyM"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Dateiarchiv',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/3ZEzfI5ollM"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Lehrerzimmer',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/qrSKR-PhZdk"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Infotext formatieren',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/Qk6k-H9N8Tk"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Aufgabe als Memo',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/y8sF_J2h4c0"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Im Editor eine Sprachnachricht hinterlassen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/CCbWq0dNoSk"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Im Editor Aufgaben erstellen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/o5m2K2Tte1w"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Arbeitsblatt mit Multiple Choice Aufgaben im Editor erstellen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/xuPSY_tbDP0"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Arbeitsblatt mit einzelnen Checkboxen (Kontrollkästchen) im Editor erstellen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/M5D_-Mocnac"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>' +
        '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/PKligMn-CZo"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Arbeitsblätter zwischenspeichern',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/kv8mX_dOmsY"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Textmarker im Editor verwenden',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/bumSkCov5Oc"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Hausaufgaben individualisieren',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/RzSj1D-onZ0"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Gebärdensprache in einem Video',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/s4w0uZ4J8JI"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Links und Videos in der Fachinfo nutzen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/EPu0u9bSrkg"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Automatischer Untertitel Funktion',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/E5ENoYfjOT4"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Unterstützte Dateiformate auf einen Blick',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/rB8dca2BKnw"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },
];

const lehrerTippsFAQ = [
  {
    title: 'eKlara zum Homebildschirm hinzufügen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/LcvvbcVRI14"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Kamera und Mikrofon immer zulassen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/vWjE0nnCT9k"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Den Cache leeren',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/ZBh3uxEJI1U"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Die Internetgeschwindigkeit testen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/_H62Au037L8"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },
];

const verwaltungFAQ = [
  {
    title: 'Einfach und schnell Stundenpläne mit der Kopierfunktion erstellen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/bKOque9k2sU"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Starten',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/almhWmFY6sk"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Schüler anlegen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/_AkmhumJfJ4"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Lehrer anlegen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/-OzYkdvwsqA"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Fächer anlegen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/GmMioKVGYa4"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Klassen anlegen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/CqPybzz4bDc"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Schulzeiten anlegen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/MWPpX3V0ids"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Schwarzes Brett benutzen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/CqPybzz4bDc"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Chatfunktion',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/CqPybzz4bDc"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },
];

const hilfefuerBBBAnforderungenFAQ = [
  {
    title: 'Anforderung an die Bandbreite (Internetgeschwindigkeit)',
    content: 'BigBlueButton benötigt eine stabile Internetverbindung mit einer konstanter Down- und Uploadgeschwindigkeit.' +
        '<br>Informationen zu den genauen Anforderungen und wie man die eigene Geschwindigkeit testen kann finden sie <a href="https://docs.bigbluebutton.org/support/faq.html#what-are-the-minimum-bandwidth-requirements-for-a-user" target="_blank">hier</a>.' +
        '<br><br>Bitte beachten sie auch, dass die Nutzung einer kabelgebundenen Internetverbindung (per Ethernet Kabel), statt einer drahtlosen Verbindung (per WLAN oder mobilem Internet), in der Regel immer zu einer konstanteren und schnelleren Internetverbindung führt.'
  },{
    title: 'Anforderung an das Endgerät',
    content: 'BigBlueButton hat Hardware Anforderungen an das Endgerät des Nutzers. Außerdem werden für die Nutzung Google Chrome und Mozilla Firefox empfohlen.' +
        '<br>Spezifikationen und weiter Informationen gibt es <a href="https://docs.bigbluebutton.org/support/faq.html#what-are-the-minimum-requirements-for-the-bigbluebutton-client" target="_blank">hier</a>.'
  },
];

const hilfefuerBBBProblemeFAQ = [
  {
    title: 'Einzelner Teilnehmer fliegt aus der Videokonferenz',
    content:
        '<p>Symptome:</p>' +
        '<p>• Lange Ladezeiten beim beitreten</p>' +
        '<p>• Teilnehmer kann BBB nicht beitreten</p>' +
        '<br>' +
        '<p>Mögliche Ursachen:</p>' +
        '<p>• Die Internetverbindung ist nicht stabil</p>' +
        '<p class="ml-6">🠖 Lösungsmöglichkeiten: </p>' +
        '<p class="ml-12">• Sollten sie eine drahtlose Internetverbindung (WLAN oder mobiles Internet) nutzen, versuchen sie auf eine kabelgebundene Internetverbindung umzusteigen. Dies führt in der Regel immer zu einer konstanteren und schnelleren Internetverbindung.</p>' +
        '<p class="ml-12">• Ihr ISP (Internet Service Provider, bspw. Telekom) stellt (aktuell) keine stabile Internetverbindung zur Verfügung. Ein Indiz, dass das Problem beim ISP liegt ist, dass sie auch keine anderen Websiten, wie z.B. www.wikipedia.de aufrufen können oder dass das Problem nur von gelegentlich unter den selben Bedingungen bei ihnen vor Ort auftritt.</p>' +
        '<p class="ml-12">• Möglicherweise ist die Internetverbindung nur nicht schnell genug. In BBB gibt es die Möglichkeit Datenvolumen einzusparen und damit auch die genutzte Bandbreite, bei sich selbst, zu reduzieren. Dazu klicken sie rechts oben auf das Menü und wählen "Einstellungen öffnen". Unter dem Menüpunkt "Datenvolumeneinsparung" haben sie die Möglichkeit nur für sich ihre und die Webcam anderer Konferenzteilnehmer oder die Bildschirmfreigabe zu deaktivieren.</p>'
  },{
    title: 'Einzelner Teilnehmer hat schlechte Mikrofon-/Kameraqualität',
    content:
        '<p>Symptome:</p>' +
        '<p>• Die Mikrofon-/Kameraqualität der anderen Konferenzteilnehmer ist beim Nutzer schlecht</p>' +
        '<p>• Die Mikrofon-/Kameraqualität des Nutzers ist bei anderen Konferenzteilnehmer schlecht</p>' +
        '<br>' +
        '<p>Mögliche Ursachen:</p>' +
        '<p>• Die Internetverbindung ist zu langsam</p>' +
        '<p class="ml-6">🠖 Lösungsmöglichkeiten: </p>' +
        '<p class="ml-12">• Tritt das Problem nicht mehr auf, wenn sie eine andere Internetverbindung nutzen, liegt das Problem an der Internetverbindung. Um das zu testen können sie mit einem Smartphone einen mobilen WLAN Hotspot für das Endgerät auf dem sie eKlara benutzen öffnen und sich mit diesem Verbinden. Mögliche Lösungen sind die Bandbreite des Internetanschluss zu erhöhen oder mobiles Internet mit einem Smartphone oder einem mobilen WLAN Router zu nutzen.</p>' +
        '<p class="ml-12">• Sollten sie eine drahtlose Internetverbindung (WLAN oder mobiles Internet) nutzen, versuchen sie auf eine kabelgebundene Internetverbindung umzusteigen. Dies führt in der Regel immer zu einer konstanteren und schnelleren Internetverbindung.</p>' +
        '<p>• Die Webcam/Kamera des Nutzers kann nur mit schlechter Qualität aufzeichnen</p>' +
        '<p class="ml-6">🠖 Lösungsmöglichkeiten: </p>' +
        '<p class="ml-12">• Wenn die Qualität des/der Mikrofons/Kamera besser ist, wenn sie sich mit BBB über ein anderes Endgerät, wie ein Smartphone verbinden oder ein/eine anderes Mikrofon/Kamera verwenden, liegt das Problem am verwendeten Equimpment. Sie können das Problem mit anderem/neuem Equipment lösen.</p>'
  },{
    title: 'Alle Teilnehmer haben mit Problemen (siehe Symptome) zu kämpfen',
    content:
        '<p>Symptome:</p>' +
        '<p>• Die Teilnehmer haben plötzlich keine Bild/Audio mehr</p>' +
        '<p>• Die Bild-/Audioqualität ist bei vielen/allen Teilnehmern schlecht/stockt</p>' +
        '<p>• Das Video von Teilnehmern bricht ab</p>' +
        '<p>• Grundsätzlich: Bei allen Teilnehmern der Videokonferenz tritt das gleiche Problem auf</p>' +
        '<br>' +
        '<p>Mögliche Ursachen:</p>' +
        '<p>• Der BBB Server ist überlastet</p>' +
        '<p class="ml-6">🠖 Lösungsmöglichkeiten: </p>' +
        '<p class="ml-12">• Die übertragene Datenmenge möglichst gering halten: Kamera/Webcam übertragung ausschalten/nur bei einer oder einzelnen Personen aktivieren.</p>' +
        '<p class="ml-12">• Die Anzahl der Teilnehmer in der Videokonferenz verringern</p>' +
        '<p class="ml-12">• Das eKlara Team per <a href="mailto:betreuung@eklassenraum.de" target="_blank">E-Mail</a> (betreuung@eklassenraum.de) kontaktieren und die folgenden Informationen mitschicken: Datum und Uhrzeit wann die Überlastung des Servers aufgetreiten ist, die URL des eKlaras ihrer Schule (z.B. xy-abc.eklara.de) und der Namen ihrer Schule.</p>'
  },{
    title: 'Die Webcam/Kamera kann nicht verwendet werden',
    content:
        '<p>Symptome:</p>' +
        '<p>• Die Webcam/Kamera kann nicht aktiviert werden</p>' +
        '<p>• Eine Fehlermeldung "Access denied" wird angezeigt.</p>' +
        '<br>' +
        '<p>Mögliche Ursachen:</p>' +
        '<p>• Die Kamera wird bereits von einer anderen Anwendung/Browsertab genutzt</p>' +
        '<p class="ml-6">🠖 Lösungsmöglichkeiten: </p>' +
        '<p class="ml-12">• Herausfinden welche/r Anwendung/Browsertab/Browserfenster die Kamera verwendet und diese/n schließen/beenden. Teilweise ist auch ein Neustart des Geräts notwendig.</p>' +
        '<p>• Safari unter macOS oder IOS blockiert die Nutzung der Kamera</p>' +
        '<p class="ml-6">🠖 Lösungsmöglichkeiten: </p>' +
        '<p class="ml-12">• Installieren sie auf ihrem Gerät den Browser Google Chrome.</p>'
  },{
    title: 'Der eigene Bildschirm kann nicht geteilt werden',
    content:
        '<p>Symptome:</p>' +
        '<p>• Das Teilen des eigenen Bildschirms als Präsentator schlägt fehl.</p>' +
        '<br>' +
        '<p>Mögliche Ursachen:</p>' +
        '<p>• macOS oder IOS blockiert die Nutzung der "Bildschirm teilen" Funktion</p>' +
        '<p class="ml-6">🠖 Lösungsmöglichkeiten: </p>' +
        '<p class="ml-12">• Leider kann es sein, dass das Teilen des Bildschirms unter macOS oder IOS aus Sicherheitsgründen vom Betriebssystem vollständig blockiert ist. Die einzige Lösung ist ein anderes Endgerät zu verwenden.</p>' +
        '<p>• Safari unter macOS blockiert die Nutzung der Kamera</p>' +
        '<p class="ml-6">🠖 Lösungsmöglichkeiten: </p>' +
        '<p class="ml-12">• Installieren sie auf ihrem Gerät den Browser Google Chrome.</p>'
  },{
    title: 'Eine Firewall blockiert den Verbindungsaufbau (Fehler 1007, Fehler 1004)',
    content:
        '<p>Symptome:</p>' +
        '<p>• Es ist kein Verbindungsaufbau mit BBB möglich und die Fehlermeldung 1007 oder 1004 wird angezeigt</p>' +
        '<br>' +
        '<p>Mögliche Ursachen:</p>' +
        '<p>• Eine Firewall oder eine andere Netzwerkkomponente blockiert den Verbindungsaufbau</p>' +
        '<p class="ml-6">🠖 Lösungsmöglichkeiten: </p>' +
        '<p class="ml-12">• Bitte kontaktieren sie uns per <a href="mailto:betreuung@eklassenraum.de" target="_blank">E-Mail</a> (betreuung@eklassenraum.de) mit genauen Informationen über die folgenden Fragen: Mit welchem Endgerät und welchem Browser tritt das Problem auf? Von welchem Standort verwenden sie eKlara (Schule/Zuhause/öffentliches Netzwerk/etc.)</p>'
  },
];

const neuesFAQ = [
  {
    title: 'Arbeitsblatt mit Multiple Choice Aufgaben im Editor erstellen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/xuPSY_tbDP0"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Arbeitsblatt mit einzelnen Checkboxen (Kontrollkästchen) im Editor erstellen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/M5D_-Mocnac"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>' +
        '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/PKligMn-CZo"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Lehrereintrag im Schwarzen Brett',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/JpCOMFzRth8"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Arbeitsblätter zwischenspeichern',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/kv8mX_dOmsY"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Textmarker im Editor verwenden',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/bumSkCov5Oc"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Automatischer Untertitel Funktion',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/E5ENoYfjOT4"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Einfach und schnell Stundenpläne mit der Kopierfunktion erstellen',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/bKOque9k2sU"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },{
    title: 'Unterstützte Dateiformate auf einen Blick',
    content: '<iframe height="300" class="px-2" style="width: 100%"\n' +
        ' src="https://www.youtube-nocookie.com/embed/rB8dca2BKnw"\n' +
        ' frameborder="0" allow="accelerometer; autoplay;\n' +
        ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        ' allowfullscreen\n></iframe>'
  },
];

export default {
  schuelerEinfachFAQ,
  schuelerFAQ,
  schuelerTippsFAQ,
  lehrerFAQ,
  lehrerTippsFAQ,
  verwaltungFAQ,
  hilfefuerBBBAnforderungenFAQ,
  hilfefuerBBBProblemeFAQ,
  neuesFAQ,
};
