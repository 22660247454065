<template>
    <div>
        <v-row class="mx-0 mt-3 mb-4">
            <div style="height: auto; width: 90%; margin: auto; ">
                <chat-widget />
            </div>
        </v-row>
    </div>
</template>

<script>
    import ChatWidget from "../Chat/ChatWidget";
    export default {
        name: "ChatVerwaltung",
        components: {ChatWidget}
    }
</script>

<style scoped>

</style>
