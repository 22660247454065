<template>
    <v-tooltip
        :disabled="!showTooltips"
        top
    >
        <template v-slot:activator="{ on: onTooltip }">
            <v-menu
                :close-on-content-click="false"
                offset-x
                offset-y
                nudge-left="200px"
                nudge-bottom="10px"
            >
                <template v-slot:activator="{ on: onMenu, attrs }">
                    <v-btn
                        tabindex="0"
                        x-small
                        fab
                        text
                        elevation="0"
                        min-width="20"
                        class="pa-0 mr-2"
                        style="cursor: pointer; height: 20px; width: 20px;"
                        v-bind="attrs"
                        v-on="{ ...onMenu, ...onTooltip }"
                    >
                        <v-icon style="font-size: 30px; color: white">
                            mdi-dots-vertical
                        </v-icon>
                    </v-btn>
                </template>
                <div
                    class="d-flex justify-center flex-column"
                    style="background-color: white; width: 200px"
                    :style="`height: ${menuContentHeight}px`"
                >
                    <LeaveChatRoomButton
                        v-if="canUserLeaveChatRoom"
                        :big="true"
                        :chat-room="currentRoom"
                        style="height: 50px"
                        @leaveChatRoom="() => { $emit('leaveChatRoom') }"
                    />
                    <OpenChatRoomSettingsButton
                        v-if="settings"
                        :big="true"
                        :chat-room="currentRoom"
                        style="height: 50px"
                        @updateRoomList="() => { $emit('updateRoomList') }"
                        @deleteChatRoom="() => { $emit('deleteChatRoom') }"
                    />
                    <ToggleChatNotificationsButton
                        :big="true"
                        style="height: 50px"
                    />
                    <v-btn
                        elevation="0"
                        block
                        class="pa-0"
                        style="text-transform: inherit; height: 50px"
                        @click="readWidgetInfoText"
                    >
                        <div
                            class="d-flex align-center"
                            style="width: 100%"
                        >
                            <img
                                class="mx-2"
                                :src="infoIcon"
                                alt
                                style="filter: brightness(0); height: 15px;"
                            >
                            <p class="mr-auto">
                                Info
                            </p>
                        </div>
                    </v-btn>
                </div>
            </v-menu>
        </template>
        <span>Menü</span>
    </v-tooltip>
</template>

<script>
import ToggleChatNotificationsButton
    from "@/components/ChatV2/Components/ToggleBrowserChatNotifications/ToggleChatNotificationsButton";
import infoIcon from "@/assets/Icons/info-2-weiß-89.svg";
import {mapActions, mapGetters, mapState} from "vuex";
import LeaveChatRoomButton from "@/components/ChatV2/LeaveChatRoomButton";
import OpenChatRoomSettingsButton from "@/components/ChatV2/Components/OpenChatRoomSettingsButton";

export default {
    name: "ChatWidgetMenu",
    components: {
        ToggleChatNotificationsButton,
        LeaveChatRoomButton,
        OpenChatRoomSettingsButton,
    },
    props: {
        currentRoom: { required: false, type: Object, default: null },
        showTooltips: { required: false, type: Boolean, default: true },
        settings: { required: false, type: Boolean, default: false },
        canUserLeaveChatRoom: { required: false, type: Boolean, default: false },
    },
    data: () => ({
        infoIcon,
        // Todo pull current person
        person: {
            accessibility: {
                screenreader: false,
            }
        },
    }),
    computed: {
        ...mapState('translation', ['targetLang']),
        ...mapGetters("auth", ["accountRole", "accountId"]),
        menuContentHeight() {
            
            let height = 100;
            if(this.settings) {
                height += 50;
            }

            if(this.canUserLeaveChatRoom) {
                height += 50;
            }

            return height;
        }
    },
    methods: {
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        async readWidgetInfoText() {
            if (!this.person.accessibility.screenreader) {
                if ('speechSynthesis' in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis Not Supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                    return
                }

                let text;
                if (this.accountRole === 'pupil') {
                    text = 'Im Chat kannst du Kontakt zu deinen Lehrern aufnehmen.';
                } else if (this.accountRole === 'teacher') {
                    text = 'Im Chat können Sie Kontakt zu Schülern und Eltern aufnehmen.';
                } else if (this.accountRole === 'parent') {
                    text = 'Im Chat können Sie zu zuständigen Lehrer Ihres Kindes Kontakt aufnehmen.';
                } else {
                    text = 'Im Chat können Sie sich mit Ihren Kontakten austauschen.'
                }

                if (this.targetLang !== 'de') {
                    text = await this.translateToTargetLang({
                        targetLang: this.targetLang, textToTranslate: text
                    });
                }

                if (window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel();
                    this.showTranslation(false);
                } else {
                    this.setTranslatedText(text);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)

                    let msg = new SpeechSynthesisUtterance();
                    msg.text = text;
                    if (this.isLangPackageAvailable()) {
                        msg.lang = this.targetLang;
                        window.speechSynthesis.speak(msg);
                    }
                }
            }
        },
        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
    }
}
</script>

<style scoped>

</style>
