<template>
    <div
        :class="inChat ? '' : 'mb-4'"
        style="width: 100%"
    >
        <!-- displaying a poll -->
        <div v-if="pollId">
            <!-- id is being used to jump to a mandatory poll in chat via button click -->
            <div
                id="mandatoryPoll"
                class="d-flex justify-center font-weight-bold"
            >
                <p v-if="poll && poll.mandatory">
                    Verpflichtende Umfrage
                </p>
            </div>

            <div v-if="poll">
                <p class="mb-1">
                    {{ poll.question }}
                </p>
                <div
                    v-for="option in poll.options"
                    :key="option._id"
                    style="position: relative; cursor: pointer"
                    @click="vote(option._id)"
                >
                    <!-- percentage bar for option -->
                    <div
                        v-if="poll.showVotes"
                        class="voteBarPercentage"
                        :class="votedOption === option._id ? 'voteBarPercentageVoted' : '' "
                        :style="{width: poll.showVotes ? getPercentage(poll, option) : '0%'}"
                    />
                    <!-- content of options -->
                    <div
                        class="d-flex align-center justify-space-between px-2 mb-1"
                        :class="votedOption === option._id ? 'votedAnswer' : 'pollAnswer'"
                        :style="poll.showVotes && votedOption === option._id ? 'background: var(--v-fdunkelgrau-base);' : ''"
                        style="width: 100%"
                    >
                        <div class="d-flex">
                            <v-radio
                                v-if="!votedOption"
                                readonly
                                class="ml-1"
                                style="z-index: 1"
                                :aria-label="option.text"
                            />
                            <!--                            <v-checkbox v-if="!votedOption" readonly hide-details class="ma-0 pa-0 pl-1" style="z-index: 1"></v-checkbox>-->
                            <p
                                aria-hidden="true"
                                class="voteBarContent"
                                :style="votedOption === option._id ? 'color: white' : 'color: black'"
                            >
                                {{ option.text }}
                            </p>
                        </div>
                        <div class="d-flex">
                            <p
                                v-if="poll.showVotes || role === 'teacher'"
                                class="voteBarContent"
                                :style="votedOption === option._id ? 'color: white' : 'color: black'"
                                aria-hidden="true"
                                @click="vote(option._id)"
                            >
                                {{ option.votes }}
                            </p>
                            <span
                                v-if="poll.showVotes || role === 'teacher'"
                                class="visually-hidden"
                            >{{ option.text + ' hat ' + option.votes + ' Stimmen' }}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    v-if="poll.allowUserOptions"
                    class="pollAnswer d-flex align-center justify-space-between px-2 mb-1"
                >
                    <v-text-field
                        v-model="ownOptionText"
                        :placeholder="poll.userOptionPlaceholder ? poll.userOptionPlaceholder : 'Eigene Antwort eingeben...'"
                        class="ownOption pa-0"
                        solo
                        flat
                        dense
                        hide-details
                        @keydown.enter="createNewOption"
                    />
                </div>
            </div>
        </div>

        <!-- poll creation -->
        <div v-else>
            <v-row
                v-if="chatMode"
                class="d-flex align-center mx-1 mb-6"
            >
                <div
                    class="d-flex justify-center"
                    style="width: 100%"
                >
                    <p style="font-size: 16px">
                        Umfrage erstellen
                    </p>
                </div>
                <v-btn
                    x-small
                    elevation="0"
                    style="position: absolute; right: 0; height: 30px; width: 30px"
                    class="mr-5"
                    @click="emitClose"
                >
                    <img
                        :src="closeIcon"
                        alt="Schließen"
                    >
                </v-btn>
            </v-row>

            <v-row class="greyRow mx-1 mb-1">
                <v-text-field
                    v-model="question"
                    placeholder="Frage eingeben..."
                    background-color="hellgrau"
                    class="inputBorder"
                    solo
                    flat
                    dense
                    hide-details
                />
            </v-row>

            <v-row
                class="greyRow mx-1 mb-1"
                :class="allowUserOptions ? 'inputBorder' : 'inputBorderTransparent'"
                tabindex="-1"
            >
                <v-text-field
                    v-model="userOptionPlaceholder"
                    :placeholder="allowUserOptions ? 'Platzhaltertext eingeben...' : 'Option „Sonstige“ deaktiviert'"
                    :readonly="!allowUserOptions"
                    background-color="hellgrau"
                    class="mb-1"
                    solo
                    flat
                    dense
                    hide-details
                    :tabindex="allowUserOptions ? '0' : '-1'"
                >
                    <template v-slot:append>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <img
                                    tabindex="0"
                                    v-bind="attrs"
                                    :src="allowUserOptions ? eye : eyeSlash"
                                    :alt="allowUserOptions ? 'Auge' : 'Auge durchgestrichen'"
                                    @click="toggleAllowUserOptions"
                                    @keydown.space="toggleAllowUserOptions"
                                    v-on="on"
                                >
                            </template>
                            <span v-text="allowUserOptions ? 'Freitextoption deaktivieren' : 'Freitextoption aktivieren'" />
                        </v-tooltip>
                    </template>
                </v-text-field>
            </v-row>

            <!-- option is unused, but still needed, otherwise you will get duplicate keys -->
            <v-row
                v-for="(option, index) in options"
                :key="index"
                class="greyRow rowHeight mx-1 mb-1"
                tabindex="-1"
            >
                <v-col
                    class="d-flex align-center pa-0 inputBorder"
                    tabindex="-1"
                >
                    <v-col
                        cols="1"
                        class="d-flex align-center pa-0"
                        tabindex="-1"
                    >
                        <!-- checkbox will be needed when poll can have multiple answers, not implemented yet though-->
                        <!-- <v-checkbox readonly hide-details class="ma-0 pa-0 pl-1" style="z-index: 1" />-->
                        <v-radio
                            readonly
                            class="ml-1"
                            style="z-index: 1"
                            tabindex="-1"
                        />
                    </v-col>
                    <v-text-field
                        :id="`option${index}`"
                        v-model="options[index]"
                        placeholder="Antworttext eingeben..."
                        background-color="hellgrau"
                        solo
                        flat
                        dense
                        hide-details
                        @keyup.enter="addOption(index)"
                        @keyup.delete="deleteOption(index)"
                    />
                </v-col>
            </v-row>

            <v-row class="d-flex justify-space-between mx-1 mb-8">
                <v-btn
                    small
                    elevation="0"
                    color="hellgrau"
                    style="width: calc(75% - 4px)"
                    class="mr-1"
                    @click="addOption"
                >
                    <p class="greenText symbolBtn">
                        +
                    </p>
                </v-btn>
                <v-btn
                    small
                    elevation="0"
                    color="hellgrau"
                    style="width: 25%"
                    @click="removeOption"
                >
                    <p :class="(options.length > 2 || allowUserOptions ? 'redText' : 'greyText') + ' symbolBtn'">
                        -
                    </p>
                </v-btn>
            </v-row>

            <v-row
                cols="12"
                class="d-flex align-center mx-1 mb-1"
            >
                <v-col
                    cols="1"
                    class="d-flex align-center pa-0"
                >
                    <v-checkbox
                        v-model="mandatory"
                        color="dunkelgrau"
                        hide-details
                        class="ma-0 pa-0 pl-1"
                    />
                </v-col>
                <v-col
                    cols="11"
                    class="pa-0 pl-1"
                >
                    <p>Teilnahme für alle verpflichtend</p>
                </v-col>
            </v-row>

            <v-row
                cols="12"
                class="d-flex align-center mx-1 mb-1"
            >
                <v-col
                    cols="1"
                    class="d-flex align-center pa-0"
                >
                    <v-checkbox
                        v-model="showVotes"
                        color="dunkelgrau"
                        hide-details
                        class="ma-0 pa-0 pl-1"
                    />
                </v-col>
                <v-col
                    cols="11"
                    class="pa-0 pl-1"
                >
                    <p>Ergebnisse für alle sichtbar</p>
                </v-col>
            </v-row>

            <div class="d-flex flex-column mt-6">
                <div class="d-flex justify-center">
                    <v-btn
                        v-if="chatMode"
                        id="custom-disabled"
                        :disabled="pollValid"
                        elevation="0"
                        dark
                        class="text-capitalize"
                        color="gruen"
                        @click="savePoll"
                    >
                        <span :style="pollValid ? 'color: black' : ''">Abschicken</span>
                    </v-btn>
                </div>
                <div
                    v-if="pollValid"
                    class="d-flex justify-center text-center"
                >
                    <p class="own-red-text">
                        Mindestens eine Frage und zwei Antwortmöglichkeiten eingeben (oder Freitext aktivieren)
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { bus } from "@/main";

import eye from '@/assets/Icons/eye.svg';
import eyeSlash from '@/assets/Icons/eye-off.svg';
import closeIcon from '@/assets/Icons/abbrechen-08.svg';

export default {
    name: "Poll",
    props: {
        chatMode: { type: Boolean, default: false },
        pollId: { type: String, required: false },
        inChat: { type: Boolean, default: false },
    },
    data() {
        return {
            eye,
            eyeSlash,
            closeIcon,

            role: null,

            poll: null,         // if not null, then this component displays the poll, otherwise it shows poll creation
            votedOption: '',    // to highlight voted option
            ownOptionText: '',

            question: '',
            allowUserOptions: false,
            userOptionPlaceholder: '',      // poll creator can type in a placeholder text if allowUserOptions is true
            mandatory: false,
            showVotes: false,
            options: [],
        }
    },
    computed: {
        pollValid() {
            let returnValue = true;
            let counter = 0;
            let arrEmpty = this.checkArrayForEmpty(this.options);

            // poll must contain a question
            if (!(this.question.trim() === '')) {
                counter++;
            }

            // poll must either contain two answer options
            if (this.options.length >= 2 && !arrEmpty && this.allowUserOptions === false) {
                counter++;
            }
            // or must have user options enabled
            else if (this.allowUserOptions) {
                counter++;
            }
            // if poll is valid return false, so that :disabled is false
            if (counter === 2) {
                returnValue = false;

                // emit to MessagesWidget to activate letter send btn
                this.$emit('pollValid');
            } else {
                // emit to MessagesWidget to deactivate letter send btn
                this.$emit('pollInvalid');
            }

            return returnValue;
        },
    },
    async mounted() {
        this.role = await this.accountRole();

        if (this.pollId) {
            await this.requestPoll();
        }

        this.options = ['', ''];

        bus.$off('createPollAndEmit');
        // listens to emitted event via bus (bus is created in main.js)
        bus.$on('createPollAndEmit', this.savePollAndPassToParent);
    },
    methods: {
        ...mapGetters('auth',[ 'accountId', 'accountRole' ]),
        ...mapActions('poll', [ 'createPoll', 'getPoll', 'voteOption', 'createOption' ]),

        toggleAllowUserOptions() {
            this.allowUserOptions = !this.allowUserOptions;
            if (!this.allowUserOptions) {
                this.userOptionPlaceholder = ''
            }
        },

        addOption(index) {
            // just checking if (index) doesn't work for 0
            if (index >= 0) {
                this.options.splice(index+1, 0, '');

                // now focus the new element added
                let a = "option";
                let b = index+1;
                let id = a.concat(b);
                document.getElementById(id).focus();
            } else {
                this.options.push('');
            }
        },

        // remove last option in array
        removeOption() {
            if (this.options.length > 2 || this.allowUserOptions) {
                this.options.splice(this.options.length - 1, 1);
            }
        },

        // delete option with given index if there are > 2 options
        deleteOption(index) {
            if (this.options[index] === '' && this.options.length > 2) {
                this.options.splice(index, 1);
            }
        },

        emitClose() {
            this.$emit('closePollCard');
        },

        // used if poll is created in ChatWidgetGroupChat
        async savePoll() {
            const optionsArray = [];
            for (let i = 0; i < this.options.length; i++) {
                optionsArray.push({ text: this.options[i], votes: 0 });
            }

            let body = {
                question: this.question,
                allowUserOptions: this.allowUserOptions,
                userOptionPlaceholder: this.userOptionPlaceholder,
                mandatory: this.mandatory,
                showVotes: this.showVotes,
                options: optionsArray,
            }
            let poll = await this.createPoll(body);
            this.$emit('closePollCard');
            bus.$emit('sendPollMessage', poll);
        },

        // used if poll is created in MessagesWidget
        async savePollAndPassToParent() {
            const options = [];
            for (let i = 0; i < this.options.length; i++) {
                options.push({ text: this.options[i], votes: 0 });
            }

            let body = {
                question: this.question,
                allowUserOptions: this.allowUserOptions,
                mandatory: this.mandatory,
                showVotes: this.showVotes,
                options,
            }
            let response = await this.createPoll(body);
            bus.$emit('pollCreated', response._id);
        },

        async requestPoll() {
            if (this.pollId === 'undefined') {
                console.warn('pollId is undefined');
            } else {
                this.poll = await this.getPoll(this.pollId);
                let me = await this.accountId();

                for (let i = 0; i < this.poll.votes.length; i++) {
                    if (this.poll.votes[i].account === me) {
                        this.votedOption = this.poll.votes[i].option;
                    }
                }
            }
        },

        async vote(optionId) {
            let params = {
                pollId: this.pollId,
                optionId: optionId
            };
            let res = await this.voteOption(params);
            if (res.status === '200') {
                this.votedOption = optionId;
                this.poll = res;    // update poll with the new values
            }
        },

        async createNewOption() {
            let option = { text: this.ownOptionText, votes: 0 };

            let params = {
                pollId: this.pollId,
                option: option,
            }
            this.ownOptionText = '';
            this.poll = await this.createOption(params); // update poll with the new values
        },

        getPercentage(poll, option) {
            let percentage;
            if (option.votes > 0) {
                percentage = ((option.votes/poll.hasVoted.length) * 100) + '%';
                // well 100% overlaps when showVotes is true, with this genius if-clause it doesn't ¯\_(ツ)_/¯
                if (percentage === '100%') {
                    percentage = '99%';
                }
            } else {
                percentage = '0%'
            }
            return percentage;
        },

        checkArrayForEmpty(array) {
            for (let i = 0; i < array.length; i++) {
                if (array[i] === '')
                    return true;
            }
            return false;
        }
    }
}
</script>

<style scoped>
/*removes the padding for the text-field where you can give your own poll option*/
.ownOption .v-input__slot {
    padding: 0 !important;
}
</style>

<style lang="scss" scoped>
// style disabled button
#custom-disabled.v-btn--disabled {
    background-color: var(--v-hellgrau-base) !important;
}

.greenText {
    color: var(--v-gruen-base);
}

.redText {
    color: var(--v-rot-base);
}

.greyText {
    color: var(--v-dunkelgrau-base);
}

.own-red-text {
    color: var(--v-editorWrong-base);
}

.inputBorder {
    border: 2px solid #B2B2B2;
    border-radius: 4px;
}

.inputBorder:focus-within {
    border: 2px solid black;
    border-radius: 4px;
}

.inputBorderTransparent {
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
}

.symbolBtn {
    font-size: 1.5rem;
    font-weight: bold;
}

.greyRow {
    background-color: var(--v-hellgrau-base);
    border-radius: 4px;
}

.rowHeight {
    height: 42px !important;
}

.pollAnswer {
    height: 42px;
    border: 1px solid black;
    border-radius: 4px;
    background: white;
}

.votedAnswer {
    color: white;
    height: 42px;
    border: 1px solid black;
    border-radius: 4px;
    background: var(--v-dunkelgrau-base);
}

.voteBarContent {
    z-index: 5;
}

.voteBarPercentage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--v-grau-base);
    border-radius: 4px;
    margin-left: 1px;
    margin-top: 1px;
    margin-right: 1px;
    height: 40px !important;
}

.voteBarPercentageVoted {
    background-color: var(--v-dunkelgrau-base) !important;
}
</style>
