<template>
    <div
        id="btnsContainer"
        class="d-flex justify-start"
    >
        <!--#region pupil -->
        <v-tooltip
            v-if="accountRole === 'pupil'"
            :value="shortcutTooltips"
            :disabled="!showTooltips || bottomNav"
            bottom
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    id="joinKlassenraumBtnPupil"
                    :aria-expanded="menu"
                    :color="atLeastOneKlassenraumOpen ? 'gruen' : 'fgrau'"
                    elevation="0"
                    :aria-label="atLeastOneKlassenraumOpen ? 'Klassenraum geöffnet' : 'Klassenraum geschlossen'"
                    aria-haspopup="menu"
                    v-on="on"
                    @click="requestKlassenraums(true)"
                >
                    <img
                        aria-hidden="true"
                        alt="Klassenraum"
                        :src="videoWeissIcon"
                    >
                    <p><span style="text-decoration: underline white">K</span>lassenraum</p>
                </v-btn>
            </template>
            <span v-if="atLeastOneKlassenraumOpen">Erstem <span style="text-decoration: underline white">K</span>lassenraum beitreten (Alt + K)</span>
            <span v-else><span style="text-decoration: underline white">K</span>lassenraum ist geschlossen</span>
        </v-tooltip>
        <!-- #endregion -->

        <!--#region teacher -->
        <v-btn
            v-if="accountRole === 'teacher'"
            id="joinKlassenraumBtnTeacher"
            :color="teacherClassroomIsOpen ? 'gruen' : 'fgrau'"
            style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
            class="px-2"
            elevation="0"
            @click="joinKlassenraum(teacherClassroomId)"
        >
            <img
                :alt="teacherClassroomIsOpen ? 'Klassenraum geöffnet' : 'Klassenraum geschlossen'"
                :src="videoWeissIcon"
            >
            <p>Schüler</p>
        </v-btn>

        <v-btn
            v-if="accountRole === 'teacher'"
            id="schlossBtn"
            :color="teacherClassroomIsOpen ? 'gruen' : 'fgrau'"
            class="ml-0"
            elevation="0"
            style="border-top-left-radius: 0; border-bottom-left-radius: 0; border-left-style: solid; border-color: rgba(0, 0, 0, 0.33) !important"
            :small="windowWidth <= 900 && windowWidth > 770"
            :x-small="windowWidth <= 770"
            @click="toggleKlassenraum"
        >
            <img
                :alt="teacherClassroomIsOpen ? 'Raum geöffnet' : 'Raum geschlossen'"
                :src="teacherClassroomIsOpen ? schlossOffenIcon : schlossIcon"
            >
        </v-btn>
        <!--#endregion -->

        <!-- pupil can chose from teachers -->
        <div
            v-if="menu && windowWidth >= 770"
            id="klassenraumMenu"
            v-click-outside="onClickOutsideMenu"
            role="menu"
        >
            <v-row
                v-for="(klassenraum, index) in klassenraums"
                :key="index"
                class="pa-0 ma-3"
            >
                <v-col
                    cols="10"
                    class="pa-0"
                    @click="joinKlassenraum(klassenraum._id)"
                >
                    <v-btn
                        tabindex="-1"
                        aria-hidden="true"
                        color="hellgrau"
                        width="95%"
                        class="d-flex justify-start"
                    >
                        <img
                            :src="getTeachPic(klassenraum.teacher)"
                            alt=""
                            :class="(hasProfilePic(klassenraum.teacher) ? 'roundIcon' : '') + ' mr-2'"
                        >
                        <p
                            class="text-capitalize ma-0"
                            style="color: var(--v-dunkelgrau-base)"
                        >
                            {{ getAppropriateTeacherDisplayName(klassenraum.teacher) }}
                        </p>
                    </v-btn>
                </v-col>

                <v-col
                    cols="2"
                    class="pa-0 pl-2 d-flex justify-end"
                    @click="joinKlassenraum(klassenraum._id)"
                >
                    <v-tooltip
                        :value="showSelectionTooltips"
                        :disabled="!showTooltips || !klassenraum.isOpen"
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="videoBtn"
                                x-small
                                role="button"
                                :aria-label="klassenraum.isOpen ? 'Klassenraum von ' + getAppropriateTeacherDisplayName(klassenraum.teacher) + ' beitreten' : 'Klassenraum von ' + getAppropriateTeacherDisplayName(klassenraum.teacher) + ' ist geschlossen'"
                                :color="klassenraum.isOpen ? 'gruen' : 'hellgrau'"
                                v-on="on"
                                @focusout="() => { if(index === klassenraums.length - 1) menu = false }"
                            >
                                <img
                                    :alt="klassenraum.isOpen ? 'Klassenraum beitreten (Alt + K)' : 'Klassenraum geschlossen'"
                                    :src="klassenraum.isOpen ? videoWeissIcon : videoIcon"
                                    :style="klassenraum.isOpen ? 'filter: brightness(1000%)' : ''"
                                >
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span><span style="text-decoration: underline white">K</span>lassenraum beitreten (Alt + K)</span>
                            <img
                                v-if="hoeren"
                                :src="beitretenMetacom"
                                width="100"
                                style="margin: auto"
                                alt="Klassenraum beitreten"
                            >
                        </div>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <!-- pupil can chose from teachers in MOBILE -->
        <div
            v-if="menu && windowWidth < 770"
            id="klassenraumMenuBtm"
            v-click-outside="onClickOutsideMenu"
        >
            <v-row
                v-for="(klassenraum, index) in klassenraums"
                :key="index"
                class="pa-0 ma-3"
            >
                <v-btn
                    color="hellgrau"
                    class="d-flex justify-start"
                    style="width: 140px"
                    @click="joinKlassenraum(klassenraum._id)"
                >
                    <img
                        :src="getTeachPic(klassenraum.teacher)"
                        alt="Profilsymbol"
                        :class="(hasProfilePic(klassenraum.teacher) ? 'roundIcon' : '') + ' mr-2'"
                    >
                    <p
                        class="ellipsis text-capitalize text-left ma-0"
                        style="width: 80px; color: var(--v-dunkelgrau-base)"
                    >
                        {{ getAppropriateTeacherDisplayName(klassenraum.teacher) }}
                    </p>
                </v-btn>

                <v-btn
                    :color="klassenraum.isOpen ? 'gruen' : 'hellgrau'"
                    class="videoBtn"
                    x-small
                    @click="joinKlassenraum(klassenraum._id)"
                >
                    <img
                        :src="klassenraum.isOpen ? videoWeissIcon : videoIcon"
                        :alt="klassenraum.isOpen ? 'Videokonferenz offen' : 'Videokonferenz geschlossen'"
                        :style="klassenraum.isOpen ? 'filter: brightness(1000%)' : ''"
                    >
                </v-btn>
            </v-row>
        </div>
        <VideoTutorial
            v-if="videoTutorial && showVideoTutorial && !videoTutorialClosed"
            :video-tutorial="videoTutorial"
            @close="onVideoTutorialClose"
        />
    </div>
</template>

<script>
import {mapActions, mapState, mapGetters, mapMutations} from 'vuex';

import VideoTutorial from "../components/Tutorial/VideoTutorial.vue";

import videoIcon from '@/assets/Icons/kamera-video-72.svg';
import videoWeissIcon from '@/assets/Icons/kamera-video-weiss-72.svg';
import schlossIcon from '@/assets/Icons/lock-closed.svg';
import schlossOffenIcon from '@/assets/Icons/lock-open.svg';
import lehrerIcon from '@/assets/Icons/teacher.svg';
import beitretenMetacom from '../assets/METACOM/Stundenplan/unterricht.png';

export default {
    name: "JoinKlassenraumButton",
    components: {
        VideoTutorial,
    },
    props: {
        bottomNav: {type: Boolean, required: false, default: false},
    },
    data() {
        return {
            videoIcon,
            videoWeissIcon,
            schlossIcon,
            schlossOffenIcon,
            beitretenMetacom,

            menu: '',
            klassenraums: '',
            teacherClassroomId: '',
            teacherClassroomIsOpen: null,
            atLeastOneKlassenraumOpen: null,      //to make Klassenraum-btn for pupil green if >= 1 room isOpen
            teacherPics: [],

            // Show video Tutorial is set to true when button is clicked
            // videoTutorialClosed is set to false when VideoTutorial is closed
            videoTutorial: false,
            showVideoTutorial: false,
            videoTutorialClosed: false,
            videoTutorialOnCloseJoinClassroomId: '',

            sehen: false,
            hoeren: false,
            babyView: false,
        };
    },
    computed : {
        ...mapGetters("auth",["accountRole"]),
        ...mapState('translation', ['targetLang']),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        ...mapState('util', ['windowWidth']),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
        showSelectionTooltips() {
            return (this.shortcutTooltips && this.menu);
        }
    },
    async mounted() {
        window.addEventListener("keyup", this.handleKeyUp);
        if (this.accountRole === 'pupil') {
            await this.requestBabyViewAndAccessibilityMode();
        }

        await this.getTeacherRoom();
        await this.requestKlassenraums();
        await this.setTeachProfilePics();
        this.videoTutorial = (this.accountRole === 'teacher')
            ? this.videoTutorialsByFilename['Lehrer_bbb_erklaert.mp4']
            : this.videoTutorialsByFilename['Normal_bbb_in_3Min_erklaert.mp4'];
    },
    async unmounted() {
        this.teacherPics.forEach(async (pic) => {
            URL.revokeObjectURL(pic[1]);
        });
    },
    beforeDestroy() {
        window.removeEventListener("keyup", this.handleKeyUp);
    },
    methods: {
        ...mapActions('util', [ 'toggleLoading' ]),
        ...mapActions('rooms', [ 'teacherClassroom', 'changeRoomStatus', 'joinClassroom' ]),
        ...mapActions('teachers', [ 'getProfilePicture' ]),
        ...mapActions('translation', [ 'showTranslation', 'setTranslatedText', 'translateToTargetLang' ]),
        ...mapActions('pupils', [ 'getMePupil' ]),
        ...mapActions('tooltips', [ 'triggerShortcutTooltips' ]),
        ...mapMutations('snackbar', [ 'showSnackbar' ]),

        async requestBabyViewAndAccessibilityMode() {
            let temp = await this.getMePupil();
            this.babyView = temp.babyView;
            if(temp.accessibility) {
                this.sehen = temp.accessibility.sehen;
                this.hoeren = temp.accessibility.hoeren;
            } else {
                this.sehen = false;
                this.hoeren = false;
            }
            this.loaded = true; //to check if they are babys
            if (this.babyView) {
                this.babyViewTut = true;
            }
        },
        handleKeyUp (event) {
            if (event.altKey && event.key === 'k') {
                if (this.atLeastOneKlassenraumOpen) {
                    this.joinKlassenraum(this.klassenraums[0]._id);
                    this.triggerShortcutTooltips();
                }
            }
        },
        onVideoTutorialClose() {
            this.videoTutorial = false;
            this.videoTutorialClosed = true;
            this.joinKlassenraum(this.videoTutorialOnCloseJoinClassroomId)
        },
        async joinKlassenraum(id) {
            this.toggleLoading(true);

            const response = await this.joinClassroom(id);
            if(this.accountRole === 'teacher') {
                if(!response.redirectUrl) {
                    this.showSnackbar({message: "Um den Klassenraum beitreten zu können, müssen Sie ihn erst öffnen. Dazu müssen Sie das Schloss anklicken.", color: "fgrau"});
                    this.toggleLoading(false);
                    return;
                }
            } else {
                if(!response.redirectUrl) {
                    this.showSnackbar({message: "Dieser Klassenraum ist geschlossen.", color: "fgrau"});
                    this.toggleLoading(false);
                    return;
                }
            }

            if(!response || response === 422 || response === 404) {
                this.showSnackbar({message: "Um den Klassenraum beitreten zu können, müssen Sie ihn erst öffnen. Dazu müssen Sie das Schloss anklicken.", color: "error"});
                this.toggleLoading(false);
                return;
            }

            if(this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                this.videoTutorialOnCloseJoinClassroomId = id;
                return;
            }
            window.location.href = response.redirectUrl;
            this.toggleLoading(false);
        },
        async toggleKlassenraum() {
            let newStatus = !this.teacherClassroomIsOpen;
            let data = {
                classroomId: this.teacherClassroomId,
                newStatus: newStatus,
            }
            let res = await this.changeRoomStatus(data);

            if(res) {   // if successful also change the local one
                this.teacherClassroomIsOpen = res.isOpen;

                // and show snackbar
                if (this.teacherClassroomIsOpen) {
                    this.showSnackbar({message: "Dieser Klassenraum ist nun geöffnet.", color: "success"});
                } else {
                    this.showSnackbar({message: "Der Klassenraum wurde geschlossen.", color: "success"});
                }
            }
        },

        async requestKlassenraums(menu) {
            // make sure route is only called initial and if pupil opens menu, cause big route
            if (this.accountRole === 'pupil' && !this.menu) {
                this.klassenraums = await this.teacherClassroom();
                for (let i = 0; i < this.klassenraums.length; i++) {
                    if (this.klassenraums[i].teacher === null) {
                        this.klassenraums[i].teacher = {
                            _id: null,
                            name: "Bugfix",
                            lastName: "Bugfix",
                            gender: "d",
                            note: "",
                            schoolSubjects: [],
                            profilePicture: "",
                            email: "bugfix@bugfix.de",
                            account: {
                                groups: [],
                                qrPassword: [],
                                role: 'teacher',
                                accountName: 'Bugfix',
                                privateFolder: [],
                                note: '',
                            }
                        }
                    }
                    if (this.klassenraums[i].isOpen) {
                        this.atLeastOneKlassenraumOpen = true;
                        break;
                    }
                }
            }
            // if called on click of Klassenraum btn, also toggle the menu
            if (menu && this.atLeastOneKlassenraumOpen) {
                this.menu = !this.menu;
            }
        },

        getAppropriateTeacherDisplayName(teacher) {
          const teacherBugfix = teacher || {};
          let prefix = '';

          if (teacherBugfix.gender === 'm') {
        prefix = 'Hr. ';
          } else if (teacherBugfix.gender === 'w') {
            prefix = 'Fr. ';
          }

          const lastName = teacherBugfix.lastName;

          return prefix + lastName;
        },

        async getTeacherRoom() {
            if (this.accountRole === 'teacher') {
                let room = await this.teacherClassroom();
                this.teacherClassroomId = room._id;
                this.teacherClassroomIsOpen = room.isOpen;
            }
        },

        onClickOutsideMenu() {
            this.menu = false;
        },

        // get profile pics from all teachers and save them in teacherPics array
        async setTeachProfilePics() {
            for (let i = 0; i < this.klassenraums.length; i++) {
                let profilePicBlob;
                if (this.klassenraums[i].teacher.profilePicture) {
                    profilePicBlob = await this.getProfilePicture(this.klassenraums[i].teacher._id);

                    if (profilePicBlob !== 404) {
                        let objectURL = URL.createObjectURL(profilePicBlob);
                        this.teacherPics.push([this.klassenraums[i].teacher._id, objectURL]);
                    }
                }
            }
        },

        // checks if provided user has a profile pic
        hasProfilePic(user) {
            if(!user) {
                return false;
            }
            for (let j = 0; j < this.teacherPics.length; j++) {
                let currTeacher = this.teacherPics[j];
                if (currTeacher === undefined) {
                    continue;
                } else if (currTeacher[0] === user._id) {
                    return true;
                }
            }
            return false;
        },

        // get teacher profile pic from teacherPics array
        getTeachPic(user) {
            if (!user) {
                return;
            }
            if (user._id == 0) {
                return null;
            }
            for (let j = 0; j < this.teacherPics.length; j++) {
                let currTeacher = this.teacherPics[j];
                if (currTeacher === undefined) {
                    continue;
                } else if (currTeacher[0] === user._id) {
                    return currTeacher[1];
                }
            }
            return lehrerIcon;
        },
    }
}
</script>

<style scoped lang="scss">
.v-btn {
  border-radius: 8px;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.roundIcon {
    border-radius: 50%;
}

#joinKlassenraumBtnTeacher {
    color: white;
    text-transform: inherit;
    height: 50px !important;
    margin-left: 12px;

    img {
      height: 25px;
      filter: brightness(1000%);
      margin-right: 4px;
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

#joinKlassenraumBtnPupil {
    color: white;
    text-transform: inherit;
    height: 50px !important;

    img {
      height: 25px;
      filter: brightness(1000%);
      margin-right: 5px;
    }

    p {
      font-size: large;
      font-weight: 400;
      line-height: 0;
      margin-bottom: 0;
    }
}

#schlossBtn {
    height: 50px;
    min-width: 50px;
    width: 50px;

    img {
        height: 30px;
        filter: brightness(1000%);
    }
}

#klassenraumMenu {
    position: fixed;
    top: 74px;
    width: 250px;
    background-color: white;
    border-radius: 0 0 0 8px;
    box-shadow: 0 2px 4px silver;
    z-index: 10; //to be one higher than fach
    color: var(--v-grau-base);

    img {
      height: 25px;
    }
}

.videoBtn {
    height: 36px !important;
    width: 36px !important;
}

@media only screen and (max-width: 900px) {
    #joinKlassenraumBtnTeacher {
        height: 40px !important;
        padding: 8px;
        margin-left: 8px;

        p {
            font-size: small;
        }

        img {
            height: 21px !important;
        }
    }

    #joinKlassenraumBtnPupil {
        height: 40px !important;
        width: 144px;
        padding: 8px;
        margin-left: 0;
        margin-bottom: 8px;

        p {
          font-size: small;
        }

        img {
            height: 21px !important;
            margin-right: 8px !important;
        }
    }

    #schlossBtn {
        height: 40px;
        min-width: 40px;
        width: 40px;

        img {
            height: 21px !important;
            margin: 0 !important;
        }
    }
}

//when change to mobile nav on bottom
@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    #joinKlassenraumBtnTeacher {
        padding: 8px !important;
        margin-left: 0 !important;
        margin-bottom: 8px;
        width: 104px;
    }

    #joinKlassenraumBtnPupil {
        height: 41px !important;
    }

    #klassenraumMenuBtm {
        position: fixed;
        bottom: 254px;
        right: 160px;
        width: 200px;
        background-color: white;
        border-radius: 8px 0 0 8px;
        box-shadow: 0 2px 4px silver;
        z-index: 2;
        color: var(--v-grau-base);

        img {
            height: 25px;
        }
    }

    #schlossBtn {
        margin-left: 0 !important;
        padding: 0 !important;
        width: 40px !important;

      img {
          margin: 0 !important;
      }
    }
}
</style>
