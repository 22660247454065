<template>
    <v-dialog
        :value="showDialog"
        width="400"
        @input="closeDialog"
    >
        <v-card
            class="cardStyle"
            style="height: 80vh; overflow: hidden"
        >
            <v-card-title class="d-flex align-center justify-space-between">
                <p>Chatraum erstellen</p>
                <v-btn
                    icon
                    @click="closeDialog"
                >
                    <v-icon style="transform: rotate(45deg)">
                        mdi-plus
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text
                class="group-list-container"
                style="height: calc(80vh - 55px - 60px)"
            >
                <vue-custom-scrollbar
                    class="scroll-area"
                    settings="settings"
                >
                    <h1 class="formHeader">
                        Gruppenname<span class="red--text bold">*</span>
                    </h1>
                    <v-text-field
                        v-model="newGroupChatName"
                        filled
                        dense
                        hide-details
                        class="mb-2"
                        style="max-width: 500px"
                    />

                    <h1 class="formHeader">
                        Teilnehmer hinzufügen (mind. 2)<span class="red--text bold">*</span>
                    </h1>
                    <div class="d-flex align-center">
                        <v-text-field
                            v-model="searchTreeView"
                            filled
                            dense
                            hide-details
                            clearable
                            style="max-width: 500px"
                        >
                            <template v-slot:prepend-inner>
                                <img
                                    :src="searchIcon"
                                    alt="Suche"
                                    class="icon mr-2"
                                >
                            </template>
                        </v-text-field>
                    </div>
                    <v-treeview
                        v-model="selectedAccountSetIds"
                        :items="selectableAccountSets"
                        item-text="displayName"
                        item-key="_id"
                        item-value="_id"
                        :search="searchTreeView"
                        selected-color="#AAC937"
                        selectable
                        dense
                        class="mt-2"
                        style="font-size: 16px"
                    />
                </vue-custom-scrollbar>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    id="eK-button-disabled"
                    color="fhellgruen"
                    class="text-none"
                    dark
                    :disabled="isGroupChatCreatable"
                    @click="createGroupChat"
                >
                    Erstellen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import * as backend from "../../../api/backend";
import { mapActions, mapGetters, mapState } from "vuex";
import vueCustomScrollbar from "vue-custom-scrollbar";

import searchIcon from "@/assets/Icons/lupe-18.svg";

export default {
  name: "ChatRoomCreateGroupChatDialog",
  components: {
    vueCustomScrollbar,
  },
  data: () => ({
    showDialog: true,
    newGroupChatName: "",
    searchTreeView: "",
    searchIcon,
    selectedAccountSetIds: [],
  }),
  computed: {
    ...mapGetters("accountSets", ["accountSets"]),
    ...mapGetters("accounts", ["accountsById"]),
    ...mapGetters("groups", ["groupsById"]),
    ...mapState("auth", ["account"]),
    isGroupChatCreatable() {
      return (
        this.newGroupChatName.trim() === "" ||
        this.selectedAccountSetIds.length < 1
      );
    },
    selectableAccountSets() {
      const getAccountSetDisplayName = (accountSet) => {
        if (accountSet.type === "account") {
          const account = this.accountsById[accountSet.account];
          return account ? account.displayName : account._id;
        } else if (accountSet.type === "groupLeaders") {
          const group = this.groupsById[accountSet.group];
          return `Gruppe "${group?.name}" Leiter`;
        } else if (accountSet.type === "groupAppointmentLeaders") {
          const group = this.groupsById[accountSet.group];
          return `Gruppe "${group?.name}" Fachlehrer`;
        } else if (accountSet.type === "groupParticipants") {
          const group = this.groupsById[accountSet.group];
          return `Gruppe "${group?.name}" Mitglieder`;
        } else if (accountSet.type === "allTeachers") {
          return "Alle Lehrer";
        } else if (accountSet.type === "allMaintainers") {
          return "Verwaltung";
        } else if (accountSet.type === "allPupils") {
          return "Alle Schüler";
        } else if (accountSet.type === "allParents") {
          return "Alle Eltern";
        }
      };

      return this.accountSets
        .filter((accountSet) => accountSet._id !== this.myAccountSet?._id)
        .map((accountSet) => ({
          ...accountSet,
          displayName: getAccountSetDisplayName(accountSet),
        }))
        .filter((accountSet) => !accountSet.displayName.includes("PersonAlle"));
    },
    myAccountSet() {
      return this.accountSets.find(
        (accountSet) =>
          accountSet.type === "account" &&
          accountSet.account === this.account._id
      );
    },
  },
  mounted() {},
  methods: {
    ...mapActions("matrix", ["createCustomRoom"]),

    async createGroupChat() {
      const memberships = this.selectedAccountSetIds.map((accountSet) => ({
        accountSet,
        role: "moderator",
      }));
      const myMembership = {
        accountSet: this.myAccountSet._id,
        role: "moderator",
      };

      await backend.postChatRoom({
        memberships: [...memberships, myMembership],
        name: this.newGroupChatName,
        description: "",
        type: "groupChat",
        isOpen: true,
      });

      //   await this.createCustomRoom({
      //     groupName: this.newGroupChatName,
      //     members: this.newGroupChatMembers,
      //   });
      this.closeDialog();
    },

    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss">
.cardStyle {
  .v-card__text {
    // unify border-radius
    .v-btn {
      border-radius: 8px;
    }
    .v-input__control > .v-input__slot {
      border-radius: 8px;
    }

    // remove border bottom line/underline from all the inputs in v-cards with this class
    .v-input__control > .v-input__slot:before,
    .v-input__control > .v-input__slot:after {
      border-style: none !important;
    }
  }
}
</style>
<style scoped lang="scss">
.group-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.scroll-area {
  flex: 1;
}

.formHeader {
  font-size: 16px;
  margin-top: 40px !important;
  margin-bottom: 20px !important;
}

.icon {
  height: 20px;
}

.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

#eK-button-disabled.v-btn--disabled {
  opacity: 0.5;
  background-color: var(--v-grau-base) !important;
  color: white !important;
}
</style>