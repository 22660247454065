<template>
    <div
        style="height: auto; overflow: unset"
        :style="`width: 100%;`"
        @click="$emit('focusMe')"
    >
        <div
            class="d-flex flex-column"
            style="height: 100%; width: 100%"
        >
            <img
                :style="`border: ${accountRole === 'teacher' ? '1px solid grey' : 'none'};
                        width: 100%; object-fit: contain`"
                :src="imgUrl"
                :class="{ 'soft-box-shadow': accountRole === 'teacher' }"
                style="max-height: 100%; height: 100%;"
                width="100%"
                height="auto"
                :alt="element.text"
                :tabindex="tabIndex"
            >
        </div>
        <v-tooltip
            bottom
            nudge-bottom="14"
        >
            <template v-slot:activator="{ on }">
                <div
                    v-if="accountRole === 'teacher'"
                    class="greyDiv editorOptionsFade"
                    style="position: absolute; display: flex; flex-direction: column; width: 100%"
                    v-on="on"
                >
                    <div style="display: flex; flex-direction: column; padding: 12px">
                        <span class="mt-1 mr-1">Alternativtext</span>
                        <input
                            v-model="element.text"
                            type="text"
                            style="width: 100%; border: solid 1px; border-radius: 6px"
                        >
                    </div>
                </div>
            </template>
            <span>Bitte geben sie einen Alternativtext für das Bild an</span>
        </v-tooltip>

        <!-- Sidebar Option Buttons -->
        <v-tooltip
            right
            :disabled="!elementOnCurrentPage"
        >
            <template v-slot:activator="{ on }">
                <div
                    v-if="accountRole === 'teacher'"
                    class="editorOptionsFade"
                    v-on="on"
                >
                    <v-btn
                        id="changeSizeButton"
                        x-small
                        @click="changeSize()"
                    >
                        <!--                                    <v-icon style="opacity: 0.75" large class="editorColorPrimaryOnHoverChildColorToWhite">mdi-format-size</v-icon>-->
                        <img
                            style="height: 32px"
                            class="iconToWhiteHover"
                            :src="currentSizeIcon"
                            alt="Größe des Elements verändern"
                        >
                    </v-btn>
                </div>
            </template>
            <span>Größe verändern</span>
        </v-tooltip>
    </div>
</template>

<script>
import * as backend from "@/api/backend";
import { mapGetters } from "vuex";
import sizeSmallIcon from '@/assets/Icons/EditorRedesign/schrift_klein.svg';
import sizeMediumIcon from '@/assets/Icons/EditorRedesign/schrift_mittel.svg';
import sizeLargeIcon from '@/assets/Icons/EditorRedesign/schrift_groß.svg';

export default {
    name: "EditorImageObject",
    props: {
        element: {type: Object, required: true},
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        tabIndex: {type: Number, required: false, default: 0},
        elementOnCurrentPage: { type: Boolean, required: false },
    },
    data: () => ({
        imgUrl: '',
        errorState: false,
        initialAltText: '',

        // Size Button Stuff
        sizeState: 0,
        currentSizeIcon: sizeSmallIcon,

        // Icons
        sizeSmallIcon,
        sizeMediumIcon,
        sizeLargeIcon,
    }),
    computed: {
        ...mapGetters("auth", ["accountRole"]),
    },
    watch: {
        element: {
            handler(oldElement, newElement) {
                if (newElement.fileUrl)
                    this.imgUrl = newElement.fileUrl;

                this.errorState = this.element.text === "" || this.element.text === this.initialAltText;
            },
            deep: true
        },
    },
    mounted() {
        if (!this.element.fileUrl || !this.element.fileBlob)
            this.loadImage();
        else
            this.imgUrl = this.element.fileUrl;

        if (this.accountRole === 'teacher' && this.element.text.includes(".")) {
            let lastIndex = this.element.text.lastIndexOf(".");
            this.element.text = this.element.text.substring(0, lastIndex);
            this.initialAltText = this.element.text;
        }
    },
    async unmounted() {
        URL.revokeObjectURL(this.imgUrl);
    },
    methods: {
        async loadImage() {
            // fileUrl: url, fileBlob: blob,
            const res = await backend.getUploadMessage(this.element.start);
            const blob = await res.blob();
            this.$set(this.element, 'fileBlob', blob);
            this.$set(this.element, 'fileUrl', window.URL.createObjectURL(blob));
            this.imgUrl = this.element.fileUrl;
        },

        //#region size change
        changeSize() {
            console.log('changeSize');
            // Changes size stuff from current case to new values
            switch (this.sizeState) {
                case 0:
                    this.currentSizeIcon = this.sizeMediumIcon;
                    this.sizeState = 1;
                    break;
                case 1:
                    this.currentSizeIcon = this.sizeLargeIcon;
                    this.sizeState = 2;
                    break;
                case 2:
                    this.currentSizeIcon = this.sizeSmallIcon;
                    this.sizeState = 0;
                    break;
            }
            this.$emit('resizeInStages', this.sizeState);
        },
        //#endregion
    }
}
</script>

<style scoped lang="scss">
#imgObjectOuter {
    width: fit-content;
    height: auto;
}

#changeSizeButton {
    position: absolute;
    top: 110px;
    right: -55px;
    margin-top: 10px;
    opacity: 0.9;
    height: 48px !important;
    width: 48px !important;
    min-width: 48px;
}

input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}
</style>
