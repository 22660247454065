<template>
    <div
        style="display: flex"
        :class="(fromMe(mappedChatRoomEvent) ? 'myMessage ' : ' ') + 'chat-message'"
        :var="(teacherPic = getTeacherPic(mappedChatRoomEvent))"
    >
        <!-- Message Header Area -->
        <v-row dense>
            <v-col class="pa-0 greyText">
                <div style="display: inline-flex">
                    <!-- For Teachers only, display avatar with Profile Picture -->
                    <v-avatar
                        v-if="teacherPic !== null"
                        class="mr-2"
                        medium
                        elevation="0"
                        fab
                        color="#6994cc"
                    >
                        <img
                            alt="Lehrer Profilbild"
                            :src="teacherPic ? teacherPic : lehrerIcon"
                            :style="teacherPic ? 'height: 48px' : 'height: 25px'"
                            :class="teacherPic ? 'roundImage' : 'iconToWhite'"
                        >
                    </v-avatar>
                    <p
                        :aria-hidden="true"
                        tabindex="0"
                        class="mb-0"
                        style="width: fit-content; height: fit-content"
                    >
                        {{ mappedChatRoomEvent.authorDisplayName }}
                    </p>
                    <p
                        tabindex="0"
                        class="mb-0 visually-hidden"
                        style="width: fit-content; float: right"
                    >
                        {{
                            "Nachricht von " +
                                mappedChatRoomEvent.authorDisplayName +
                                " am " +
                                new Date(getTime(mappedChatRoomEvent)).toLocaleDateString() +
                                " " +
                                getAccessibilityTime(
                                    new Date(getTime(mappedChatRoomEvent))
                                        .toLocaleTimeString()
                                        .slice(
                                            0,
                                            new Date(getTime(mappedChatRoomEvent)).toLocaleTimeString()
                                                .length - 3
                                        )
                                )
                        }}
                    </p>
                </div>
            </v-col>
            <v-col
                class="text-right greyText"
                style="font-size: smaller"
            >
                <p
                    :aria-hidden="true"
                    tabindex="0"
                    class="mb-0"
                    style="width: fit-content; float: right"
                >
                    {{
                        new Date(getTime(mappedChatRoomEvent)).toLocaleDateString() +
                            " " +
                            new Date(getTime(mappedChatRoomEvent))
                                .toLocaleTimeString()
                                .slice(
                                    0,
                                    new Date(getTime(mappedChatRoomEvent)).toLocaleTimeString()
                                        .length - 3
                                )
                    }}
                </p>
            </v-col>
        </v-row>
        <!-- Message Body Area -->

        <v-row
            dense
            :class="
                (fromMe(mappedChatRoomEvent) ? 'myMessage-content ' : ' ') +
                    'chat-message-content'
            "
        >
            <!-- Deleted Message -->
            <div
                v-if="mappedChatRoomEvent.isRedacted"
                style="font-style: italic"
            >
                <p
                    tabindex="0"
                    class="mb-0"
                    style="width: fit-content"
                >
                    Diese Nachricht wurde gelöscht.
                </p>
            </div>

            <!-- Text Message -->
            <ChatRoomEventListTextItem
                v-else-if="mappedChatRoomEvent.type === 'textMessage'"
                :chat-room-event="mappedChatRoomEvent"
                :chat-room-membership-role="chatRoomMembershipRole"
            />

            <!-- Audio Message -->
            <ChatRoomEventListAudioItem
                v-else-if="mappedChatRoomEvent.type === 'audioMessage'"
                :chat-room-event="mappedChatRoomEvent"
                :chat-room-membership-role="chatRoomMembershipRole"
            />

            <!-- Image Message -->
            <ChatRoomEventListImageItem
                v-else-if="mappedChatRoomEvent.type === 'imageMessage'"
                :chat-room-event="mappedChatRoomEvent"
                :chat-room-membership-role="chatRoomMembershipRole"
            />

            <!-- Poll Message -->
            <Poll
                v-else-if="mappedChatRoomEvent.type === 'pollMessage'"
                :poll-id="mappedChatRoomEvent.poll"
                :in-chat="true"
            />
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import lehrerIcon from "../../../../assets/Icons/teacher.svg";

import Poll from "@/components/Poll";
import ChatRoomEventListImageItem from "./ChatRoomEventListImageItem";
import ChatRoomEventListAudioItem from "./ChatRoomEventListAudioItem";
import ChatRoomEventListTextItem from "./ChatRoomEventListTextItem";

export default {
  name: "ChatRoomEventListItem",
  components: {
    Poll,
    ChatRoomEventListImageItem,
    ChatRoomEventListAudioItem,
    ChatRoomEventListTextItem,
  },
  props: {
    chatRoomEvent: { required: true, type: Object },

    // Is the current account a 'moderator' or a 'user' in this chatroom
    chatRoomMembershipRole: { required: true, type: String },
  },
  data: () => ({
    loadedTeacherPic: null,
    teacherPics: [],

    lehrerIcon,
  }),
  computed: {
    ...mapState("tooltips", ["showTooltips"]),
    ...mapState("auth", ["account"]),
    ...mapGetters("accounts", ["accountsById"]),
    mappedChatRoomEvent() {
      return {
        ...this.chatRoomEvent,
        authorDisplayName:
          this.accountsById[this.chatRoomEvent.author].displayName,
      };
    },
  },

  async unmounted() {
    if (this.loadedTeacherPic) URL.revokeObjectURL(this.loadedTeacherPic);
  },
  methods: {
    getTime(event) {
      if (event.sentTime) {
        return event.sentTime;
      }
      event.sentTime = new Date(event.createdAt).toString();

      // TODO make sent timez
      // TODO: proper format/hide date if message was sent todaFy and always show time
      return new Date(event.createdAt).toString();
    },
    //name is sliced as it ends with the gender char. eg. Fritzm means name=Fritz, gender=male

    fromMe(event) {
      return event.author === this.account._id;
    },
    getAccessibilityTime(time) {
      const charArray = time.split("");

      let spelledOutTime = "";
      if (charArray[0] !== "0") {
        spelledOutTime = spelledOutTime + charArray[0];
      }
      spelledOutTime = spelledOutTime + charArray[1];
      spelledOutTime = spelledOutTime + " Uhr ";
      if (charArray[3] !== "0") {
        spelledOutTime = spelledOutTime + charArray[3];
      }
      spelledOutTime = spelledOutTime + charArray[4];
      return spelledOutTime;
    },
    getTeacherPic(event) {
      const matchingTeacherPic = this.teacherPics.find((teacherPic) => {
        return teacherPic[1] == event.author;
      });
      if (matchingTeacherPic) {
        return matchingTeacherPic[2];
      }

      return null;
    },
  },
};
</script>

<style>
.chat-send-input .v-input__append-inner {
  margin-top: 8px !important;
}

.chat-send-input .v-text-field__slot textarea {
  padding-right: 0px !important;
}
</style>

<style lang="scss" scoped>
.chat-send-input {
  background-color: white;
  border-radius: 50px;
  width: 100%;
}

.chat-send-wrapper {
  display: inline-flex;
  background-color: #f2f2f2;
  padding: 0 12px;
  bottom: 0;
  left: 0;
  right: 0;
}

.group-chat-wrapper {
  flex: auto;
  max-height: calc(80vh - 55px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: white !important;
  border-radius: 0 0 15px 15px;
}

.chat-body {
  // position: fixed;
  // overflow-y: auto;
  flex: auto;
  //overflow-y: auto;
  //overflow-x: hidden;
  overflow: hidden;
}

.group-chat-header-btn {
  padding: 0 !important;
  min-width: 0 !important;
  border-radius: 8px;
  width: 36px !important;
  height: 36px !important;
}

.group-chat-header-text {
  color: white;
}

.group-chat-header {
  background-color: #95c94b; // 3caa68 - 5% Luminosity
  min-height: 56px;
  height: 56px;
}

.chat-header-btn {
  background-color: white !important;
  width: 35px;
  height: 35px !important;
}

.chat-header-img {
  height: 20px;
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
  position: relative;
  margin: auto;
  max-height: calc(80vh - 176px);
  height: calc(80vh - 179px);
  overflow: hidden;
  margin-bottom: 2px;
}

.scroll-area-disabled {
  position: relative;
  margin: auto;
  max-height: calc(80vh - 176px);
  height: calc(80vh - 179px);
  overflow: hidden;
  margin-bottom: 2px;
}

.chat-message {
  position: relative;
  border-radius: 5px;
  max-width: 75%;
  margin: 2px 2px 2px 19px;
}

.myMessage {
  margin-left: auto !important;
  margin-right: 19px !important;
}

/*removes darker background after element is clicked
also works without the specific class in front*/
.myMessage:before {
  opacity: 0 !important;
}
.chat-message:before {
  opacity: 0 !important;
}

.chat-message-content {
  border-radius: 0 5px 5px 5px;
  box-shadow: 1px 2px 3px silver;
  background-color: var(--v-blau-base);
  /*background-color: #9b91c8;*/ //use me when using chat gender colors again
  color: white;
  padding: 9px;
}
.male-chat {
  background-color: var(--v-blau-base) !important;
}
.female-chat {
  background-color: var(--v-pink-base) !important;
}

.myMessage-content {
  border-radius: 5px 5px 0 5px !important;
  background-color: var(--v-grau-base);
  color: black;
}

.progressbar {
  border-radius: 5px;
}

.icon {
  height: 20px;
}

.bigIcon {
  height: 30px;
}

.pointer {
  cursor: pointer;
}

.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.greyText {
  color: var(--v-dunkelgrau-base);
}

.iconToRecording {
  background-image: url("../../../../assets/Icons/recording-dot.svg") !important;
}

#micIcon {
  background-image: url("../../../../assets/Icons/mikrofon-22.svg");
  width: 30px;
  height: 30px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
h2 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

#mandatoryFloatBtn {
  position: absolute;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translateY(-50%);
  bottom: 2%;
}

.formHeader {
  font-size: 16px;
  margin-bottom: 20px !important;
}

.optionBtn {
  border-radius: 8px;
  min-width: 35px !important;
  height: 35px !important;
}

/* width */
::-webkit-scrollbar {
  width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 6px;
  width: 7px;
}
::-webkit-scrollbar-thumb:hover {
  background: #999;
  border-radius: 6px;
  width: 11px;
}
</style>