import * as backend from '../../api/backend';
import { checkResponseStatus } from '@/util/check';
import Vue from "vue";

const state = {
    accounts: [],
};

const getPrettyAccount = (account, rootGetters) => {
    let displayName;

    if (account.role === "maintainer") {
        displayName = "Verwaltung";
    } else if (account.role === "teacher") {
        const teacher = rootGetters['teachers/teachersByAccountId'][account._id];

        displayName = teacher && teacher.lastName ? `${teacher.name[0]}. ${teacher.lastName}` : `Unbekannter Lehrer (${account.accountName})`
    } else if (account.role === "pupil") {
        const pupil = rootGetters['pupils/pupilsByAccountId'][account._id];

        displayName = pupil && pupil.name? `${pupil.name} ${pupil.lastName[0]}.` : `Unbekannter Schüler (${account.accountName})`

    } else if (account.role === "parent") {
        const parent = rootGetters['parents/parentsByAccountId'][account._id];

        const pupilIds = parent?.pupils || [];
        const pupils = pupilIds
            .map((pupilId) => rootGetters['pupils/pupilsById'][pupilId]);
        const pupilNames = pupils
            .filter(pupil => !!pupil)
            .map(pupil => pupil.name? `${pupil.name} ${pupil.lastName[0]}.` : `Unbekannter Schüler`);

        // Example "Eltern Monika S., Dan D."
        displayName = parent ? `Eltern ${pupilNames.join(", ")}` : `Unbekannte Eltern (${account.accountName})`;
    }

    return {
        ...account,
        displayName,
    };
};

const getters = {
    accounts: (state, getters, rootState, rootGetters) => state.accounts
        .map(account => getPrettyAccount(account, rootGetters))
        .filter(account => account !== null), // filter out invalid accounts
    accountsById: (state, getters, rootState, rootGetters) => {
        return state.accounts.reduce((acc, account) => 
        {
            const prettyAccount = getPrettyAccount(account, rootGetters);
            if(!prettyAccount) {
                // Don't add invalid account;
                return acc
            }  
            acc[account._id] = getPrettyAccount(account, rootGetters);
            return acc;
        }, {});
    }
};

const mutations = {
    setAccounts: (state, accounts) => {
        state.accounts = accounts;
    },
};

const actions = {
    fetchAccounts: async ({ commit, dispatch }, params) => {
        try {
            const res = await backend.getAccounts(params);
            await checkResponseStatus(200, res);
            const accounts = await res.json();
            commit('setAccounts', accounts);
            return res.status;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
