<template>
    <v-menu
        max-width="200px"
        :position-y="1"
        absolute
        nudge-right="40"
        :attach="'#sideBarContainer'"
        :close-on-content-click="false"
        :close-on-click="true"
        style="top: 200px !important; left: 75px !important; border-radius: 1em;"
        @close="$emit('close')"
    >
        <template v-slot:activator="{ on: onMenu }">
            <v-tooltip right>
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                        id="interactiveElementsActivator"
                        aria-label="Interaktive Komponenten"
                        tabindex="17"
                        elevation="3"
                        x-small
                        class="mr-3 my-1 sidebarButton"
                        :disabled="previewActive"
                        v-on="{ ...onMenu, ...onTooltip }"
                        @click="$emit('opened')"
                    >
                        <img
                            height="35px"
                            :src="interactiveMenuIcon"
                        >
                    </v-btn>
                </template>
                <span>Interaktive Komponenten</span>
            </v-tooltip>
        </template>
        <div
            style="width: 200px; height: calc(100vh - 150px); background-color: rgba(100, 100, 100, 0.8); pointer-events: all"
            class="d-flex flex-column overflow-auto mr-10"
        >
            <div
                class="d-inline-flex justify-space-between align-center pa-1 ma-1"
            >
                <p class="itemsHeader">
                    Deutsch
                </p>
            </div>

            <div
                v-for="(item, index) in germanSubjectItems"
                :key="'german'+index"
                class="mx-auto my-2 d-flex flex-column"
                style="width: 150px;  background-color: var(--v-hellgrau-base); border-radius: 5px"
                tabindex="17"
                draggable="true"
                @dragstart="(event) => elementDragStart(event, item)"
            >
                <div
                    class="mx-auto my-2 d-flex flex-column align-center justify-center"
                    style="width: 100%"
                    @click="handleItemClick(item)"
                >
                    <div class="elementSmallIcons">
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="item.accessibility"
                                    class="mr-1"
                                    style="font-size: 12px"
                                    v-on="on"
                                >
                                    mdi-human
                                </v-icon>
                            </template>
                            <span>{{ item.accessibilityText ? item.accessibilityText : 'Barrierefreie Komponente' }}</span>
                        </v-tooltip>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="item.help"
                                    class="px-1"
                                    style="font-size: 12px"
                                    v-on="on"
                                    @click="(event) => {
                                        event.stopPropagation();
                                        currentHelp = item.help.content;
                                        showHelp = true;
                                    }"
                                >
                                    fas fa-question
                                </v-icon>
                            </template>
                            <span>{{ 'Hilfe' }}</span>
                        </v-tooltip>
                    </div>
                    <img
                        :src="item.icon"
                        width="100"
                        height="100"
                        class="mb-2"
                        :alt="item.text"
                        style="margin-top: -10px !important; pointer-events: none"
                    >
                    <p
                        style="text-align: center; margin-top: -25px !important;"
                        class="my-2"
                        v-text="item.text"
                    />
                </div>
            </div>

            <div
                class="d-inline-flex justify-space-between align-center pa-1 ma-1"
            >
                <p class="itemsHeader">
                    Mathe
                </p>
            </div>

            <div
                v-for="(item, index) in mathSubjectItems"
                :key="'math'+index"
                class="mx-auto my-2 d-flex flex-column"
                style="width: 150px;  background-color: var(--v-hellgrau-base); border-radius: 5px"
                tabindex="17"
                draggable="true"
                @dragstart="(event) => elementDragStart(event, item)"
            >
                <div
                    class="mx-auto my-2 d-flex flex-column align-center justify-center"
                    style="width: 100%"
                    @click="handleItemClick(item)"
                >
                    <div class="elementSmallIcons">
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="item.accessibility"
                                    class="mr-1"
                                    style="font-size: 12px"
                                    v-on="on"
                                >
                                    mdi-human
                                </v-icon>
                            </template>
                            <span>{{ item.accessibilityText ? item.accessibilityText : 'Barrierefreie Komponente' }}</span>
                        </v-tooltip>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="item.help"
                                    class="px-1"
                                    style="font-size: 12px"
                                    v-on="on"
                                    @click="(event) => {
                                        event.stopPropagation();
                                        currentHelp = item.help.content;
                                        showHelp = true;
                                    }"
                                >
                                    fas fa-question
                                </v-icon>
                            </template>
                            <span>{{ 'Hilfe' }}</span>
                        </v-tooltip>
                    </div>
                    <img
                        :src="item.icon"
                        width="100"
                        height="100"
                        class="mb-2"
                        :alt="item.text"
                        style="margin-top: -10px !important; pointer-events: none"
                    >
                    <p
                        style="text-align: center; margin-top: -25px !important;"
                        class="my-2"
                        v-text="item.text"
                    />
                </div>
            </div>

            <div
                class="d-inline-flex justify-space-between align-center pa-1 ma-1"
            >
                <p class="itemsHeader">
                    Übergreifend
                </p>
            </div>

            <div
                v-for="(item, index) in testSubjectItems"
                :key="'test'+index"
                class="mx-auto my-2 d-flex flex-column"
                style="width: 150px;  background-color: var(--v-hellgrau-base); border-radius: 5px"
                tabindex="17"
                draggable="true"
                @dragstart="(event) => elementDragStart(event, item)"
            >
                <div
                    class="mx-auto my-2 d-flex flex-column align-center justify-center"
                    style="width: 100%"
                    @click="handleItemClick(item)"
                >
                    <div class="elementSmallIcons">
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="item.accessibility"
                                    class="mr-1"
                                    style="font-size: 12px"
                                    v-on="on"
                                >
                                    mdi-human
                                </v-icon>
                            </template>
                            <span>{{ 'Barrierefreie Komponente' }}</span>
                        </v-tooltip>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="item.help"
                                    class="px-1"
                                    style="font-size: 12px"
                                    v-on="on"
                                    @click="(event) => {
                                        event.stopPropagation();
                                        currentHelp = item.help.content;
                                        showHelp = true;
                                    }"
                                >
                                    fas fa-question
                                </v-icon>
                            </template>
                            <span>{{ 'Hilfe' }}</span>
                        </v-tooltip>
                    </div>
                    <img
                        :src="item.icon"
                        width="100px"
                        height="100px"
                        class="mb-2"
                        :alt="item.text"
                        style="margin-top: -10px !important; pointer-events: none"
                    >
                    <p
                        style="text-align: center; margin-top: -25px !important;"
                        class="my-2"
                        v-text="item.text"
                    />
                </div>
            </div>

            <div
                class="d-inline-flex justify-space-between align-center pa-1 ma-1"
            >
                <p class="itemsHeader">
                    Allgemeine Komponenten
                </p>
            </div>

            <div
                v-for="(item, index) in items"
                :key="'general'+index"
                class="mx-auto my-2 d-flex flex-column"
                style="width: 150px;  background-color: var(--v-hellgrau-base); border-radius: 5px"
                tabindex="17"
                :draggable="isItemDraggable(item.type)"
                @dragstart="(event) => elementDragStart(event, item)"
            >
                <div
                    class="mx-auto my-2 d-flex flex-column align-center justify-center"
                    style="width: 100%"
                    @click="handleItemClick(item)"
                >
                    <div class="elementSmallIcons">
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="item.accessibility"
                                    class="mr-1"
                                    style="font-size: 12px"
                                    v-on="on"
                                >
                                    mdi-human
                                </v-icon>
                            </template>
                            <span>{{ item.accessibilityText ? item.accessibilityText : 'Barrierefreie Komponente' }}</span>
                        </v-tooltip>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="item.help"
                                    class="px-1"
                                    style="font-size: 12px"
                                    v-on="on"
                                    @click="(event) => {
                                        event.stopPropagation();
                                        currentHelp = item.help.content;
                                        showHelp = true;
                                    }"
                                >
                                    fas fa-question
                                </v-icon>
                            </template>
                            <span>{{ 'Hilfe' }}</span>
                        </v-tooltip>
                    </div>
                    <img
                        :src="item.icon"
                        width="50"
                        height="50"
                        class="mb-2"
                        :alt="item.text"
                        style="pointer-events: none"
                    >
                    <p
                        style="text-align: center"
                        class="my-2"
                        v-text="item.text"
                    />
                </div>

                <!-- Options for drag and drop -->
                <div
                    v-if="item.type === 'moveableText'"
                    id="dragDropMoreOptions"
                    :shouldBeOpen="dragDropHTML"
                    style="overflow: hidden"
                >
                    <v-row class="mt-1">
                        <v-col class="pb-1">
                            <v-select
                                v-model="sizeSelector"
                                label="Text Größe"
                                hide-details
                                outlined
                                dense
                                :items="sizesToSelect"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="textAreaValue"
                                hide-details
                                outlined
                                label="Text..."
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex justify-center">
                            <v-btn
                                color="gruen"
                                class="mx-auto"
                                @click="elementSelected(item, {fontSize: sizeSelector, value: textAreaValue})"
                            >
                                <p style="color: white">
                                    Platzieren
                                </p>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>

        <!-- lets hope this works -->
        <v-dialog
            v-if="showHelp"
            v-model="showHelp"
            role="dialog"
            scrollable
            width="85vw"
        >
            <v-card>
                <v-toolbar
                    color="orange"
                    dense
                    style="max-height: 65px"
                >
                    <v-toolbar-title class="white--text">
                        Editor Elemente erklärt: {{ currentHelp.title }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn
                        elevation="2"
                        x-small
                        class="ml-2 pa-0"
                        style="width: 40px; height: 40px; opacity: 0.8"
                        @click="showHelp = !showHelp"
                    >
                        <img
                            :src="schliesenIcon"
                            alt="Schließensymbol"
                            style="height: 35px;"
                        >
                    </v-btn>
                </v-toolbar>
                <div id="currentHelpContainer">
                    <!-- Disable v-html xss checking currentHelp.content is provided only by developers -->
                    <!-- eslint-disable vue/no-v-html -->
                    <div
                        class="mx-10 pa-2"
                        v-html="sanitizeHtml(currentHelp.content)"
                    />
                    <!-- eslint-enable vue/no-v-html -->
                </div>
            </v-card>
        </v-dialog>
    </v-menu>
</template>

<script>
import {taskMenuHelp} from "@/constants/editorHelpCollection";
import memoMenuIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_Sprachmemo.svg'
import taskIcon from "@/assets/Icons/EditorRedesign/Textfeld.svg";
import answerIcon from "@/assets/Icons/EditorRedesign/Antwortfeld.svg";
import dragDropIcon from "@/assets/Icons/FileCreator/EKLARA_Icons_editor_drag&drop.svg";
import multipleChoiceIcon from "@/assets/Icons/EditorRedesign/Checkbox_old.svg";
import schliesenIcon from "@/assets/Icons/abbrechen-08.svg";
import lueckentextIcon from '@/assets/Icons/EditorRedesign/Lueckentext.svg';
import suchselIcon from '@/assets/Icons/EditorRedesign/Suchsel.svg';
import lueckenrechnungIcon from '@/assets/Icons/EditorRedesign/Lueckenrechnung.svg';
import interactiveMenuIcon from '@/assets/Icons/EditorRedesign/komponente.svg';
import rechenmauerIcon from '@/assets/Icons/EditorRedesign/Rechenmauer.svg';
import pairingIcon from '@/assets/Icons/EditorRedesign/Pairing.svg';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import sanitizeHtml from '../../../util/sanitizeHtml';


export default {
    name: 'EditorInteractiveElementsList',
    props: {
        previewActive: { required: true, type: Boolean },
    },
    data: () => ({
        sanitizeHtml,

        currentHelp: null,
        dragDropHTML: false,
        showHelp: false,
        textAreaValue: '',
        sizeSelector: 0,
        sizesToSelect: [
            { value: 0, text: 'Normal'},
            { value: 1, text: 'Groß'},
            { value: 2, text: 'Extra groß'},
        ],
        items: [
            { type: 'multipleChoice', icon: multipleChoiceIcon, text: 'Frage-Antwort-Wahl', help: { type: 'text', content: taskMenuHelp[3] }, accessibility: true },
            // { type: 'texttask', icon: taskIcon, text: 'Textfeld', help: { type: 'text', content: taskMenuHelp[0] }, accessibility: true },
            // { type: 'texttask', icon: taskIcon, text: 'Textfeld mit Antwort', help: { type: 'text', content: taskMenuHelp[0] }, accessibility: true },
            { type: 'texttaskcombined', icon: taskIcon, text: 'Textaufgabe', help: { type: 'text', content: taskMenuHelp[0] }, accessibility: true },
            { type: 'answerFieldPlus', icon: answerIcon, text: 'Antwortfeld', help: { type: 'text', content: taskMenuHelp[1] } },
            { type: 'dragndrop', icon: dragDropIcon, text: 'Begriffe zuordnen', help: { type: 'text', content: taskMenuHelp[2] } },
            { type: 'dragndropfree', icon: dragDropIcon, text: 'Begriffe zuordnen 2', help: { type: 'text', content: taskMenuHelp[2] } },
            // { type: 'moveableText', icon: dragDropIcon, text: 'Verschiebbarer Text', help: { type: 'text', content: taskMenuHelp[2] } },
        ],
        germanSubjectItems: [
            {
                icon: lueckentextIcon,
                text: "Lückentext",
                type: 'cloze',
                help: { type: "text" },
                accessibility: true
            },
            {
                icon: suchselIcon,
                text: "Buchstabensuppe",
                type: 'buchstabensuppe',
                help: { type: "text", content: taskMenuHelp[1] },
            },
        ],
        mathSubjectItems: [
            { type: 'calculation', icon: lueckenrechnungIcon, text: 'Lückenrechnung', help: { type: 'calculation', content: taskMenuHelp[0] }, accessibility: true,  accessibilityText: 'Barrierefreie Komponente, es ist darauf zu achten, dass immer nur eine Reihe ausgeblendet ist.' },
            { type: 'wall', icon: rechenmauerIcon, text: 'Rechenmauer', help: { type: 'wall', content: taskMenuHelp[0] }, accessibility: true, accessibilityText: 'Barrierefreie Komponente, es ist darauf zu achten, dass die gesamte Mauer ausgeblendet ist.' },
        ],
        testSubjectItems: [
            {
                icon: pairingIcon,
                text: 'Paare finden',
                type: 'pairing',
                help: {type: "text" },
                accessibility: false,
            }
        ],

        //Icons
        schliesenIcon,
        interactiveMenuIcon,
    }),
    computed: {
        ...mapState("editorDragnDrop", ["dragndrop", "dragndropfields"]),
        ...mapGetters('pairing', ['spawnedPairing']),

        isItemDraggable() {
            return (type) => {
                return type !== 'moveableText';
            }
        }
    },
    methods: {
        ...mapActions('editorDragnDrop', ['setdragndrop']),
        ...mapMutations('pairing', [ 'setPairingEdit' ]),

        handleItemClick(item) {
            // if (item.type === 'multipleChoice') {
            //     // enable multiplechoice ui
            //     this.mQuestions = [{ text: '' , correct: false, checked: false }];
            //     this.mQuestionTitle = '';
            //     this.multipleChoiceHTML = !this.multipleChoiceHTML;
            // } else
                if (item.type === 'moveableText') {
                // enable moveableText ui
                this.dragDropHTML = !this.dragDropHTML;
            } else {
                this.elementSelected(item)
            }
        },
        elementDragStart(event, item) {
                if (this.isItemDraggable(item.type)) {
                    const payload = { type: item.type };
                    if (item.text === 'Textfeld mit Antwort') {
                        payload.text = true;
                    }
                    event.dataTransfer.setData('text/plain', JSON.stringify(payload));
                }
        },
        elementSelected(element, extraData = {}) {


            const options = {
                x: 30,
                y: 30,
                type: element.type
            };

            switch (element.text) {
                case 'Paare finden':
                    if(this.spawnedPairing) {
                        this.setPairingEdit(true);
                        return;
                    }
                    break;
                case 'Textfeld mit Antwort':
                    options.text = true;
                    break;
                case 'Frage-Antwort-Wahl':
                    options.fontSize = extraData.fontSize;
                    options.startTextTask = extraData.title;
                    options.multipleChoices = extraData.answers;
                    break;
                case 'Verschiebbarer Text':
                    options.text = extraData.value;
                    options.fontSize = extraData.fontSize;
                    break;
                case 'Begriffe zuordnen 2':

                    break;
            }
            if(element.type !== 'dragndropfree') {
                this.$emit('elementSelected', options)
            } else if(this.dragndropfields.length === 0) {
                this.setdragndrop(true);
                this.$emit('elementSelected', options)
            } else {
                this.setdragndrop(true);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.sidebarButton {
    width: 49px !important;
    height: 49px !important;
    border-radius: 8px;

    p {
        line-height: 0;
        font-size: 55px;
        margin-bottom: 0;
        font-weight: 300;
        color: #333;
    }
}

#interactiveElementsActivator {
    height: 150px !important;
    background-color: var(--v-orange-base) !important;
    pointer-events: all;

    img {
        filter: brightness(1000%);
    }
}

#interactiveElementsActivator:disabled {
    background-color: var(--v-grau-base) !important;

    img {
        filter: none;
    }
}

.elementSmallIcons {
    display: inline-flex;
    justify-content: flex-end;
    align-self: flex-end;
    width: 35px;
    height: 15px;
    margin-top: 2px;
}

.itemsHeader {
    text-transform: capitalize;
    color: white;
    text-align: start;
    width: 100%;
}

#dragDropMoreOptions {
    pointer-events: none;
    height: 0;
    opacity: 0;
    transition: all 0.25s ease;
    padding-inline: 5px;
}

#multipleChoiceMoreOptions {
    pointer-events: none;
    height: 0;
    opacity: 0;
    transition: all 0.25s ease;
    padding-inline: 5px;
}

#dragDropMoreOptions[shouldBeOpen=true] {
    pointer-events: auto;
    height: 200px;
    opacity: 1;
    transform: translateY(0);
}

#multipleChoiceMoreOptions[shouldBeOpen=true] {
    pointer-events: auto;
    height: auto;
    opacity: 1;
    transform: translateY(0);
}
</style>
