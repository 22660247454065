<template>
    <div>
        <v-row class="mx-0">
            <v-col class="pa-0">
                <div
                    class="whiteBg"
                    style="height: auto; width: 90%; min-height: 80vh; border-radius: 15px; box-shadow: 1px 5px 5px silver; margin: auto auto 5em;"
                >
                    <!--Header of Stundenplan widget-->
                    <div
                        style="background-color: #6995CD; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white;"
                        @click="readWidgetInfoText()"
                    >
                        <v-row
                            style="max-width: 100%; display: flex; align-items: center;"
                            class="mx-4"
                        >
                            <v-col
                                cols="9"
                                md="8"
                                lg="9"
                                class="pa-0 text-left d-flex align-center"
                                style="font-size: xx-large"
                            >
                                <img
                                    :src="stundenplanIcon"
                                    style="height: 30px;"
                                    class="iconToWhite"
                                    alt=""
                                >
                                <h1
                                    class="ma-0 ml-2 heading"
                                    tabindex="0"
                                    aria-label="Stundenplan"
                                >
                                    <span style="text-decoration: underline white">S</span>tundenplan
                                </h1>
                            </v-col>
                            <v-col
                                cols="3"
                                md="4"
                                lg="3"
                                class="pa-0 d-flex align-center justify-end"
                            >
                                <v-tooltip
                                    :disabled="!showTooltips"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                            x-small
                                            class="pa-0"
                                            style="background-color: #f8f8f800; height: 45px; width: 45px"
                                            v-on="on"
                                        >
                                            <img
                                                id="infoPlanBtn"
                                                :src="lautsprecherIcon"
                                                style="height: 42px;"
                                                alt="Vorlesen"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Vorlesen</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </div>

                    <!--date bar of Stundenplan-->
                    <div style="border-radius: 15px">
                        <v-row
                            cols="12"
                            style="width: 99%; margin: 0 auto 0.5em;"
                        >
                            <v-col
                                cols="12"
                                class="text-center pb-0"
                            >
                                <h2
                                    tabindex="0"
                                    style="display: inline-block; color: #6995CD; font-weight: bold; font-size: x-large"
                                >
                                    {{ getDisplayDate() }}
                                </h2>
                            </v-col>
                        </v-row>

                        <div
                            v-if="showLoading"
                            class="d-flex justify-center"
                        >
                            <v-progress-circular
                                color="#6995CD"
                                indeterminate
                                size="100"
                            />
                        </div>

                        <div v-else>
                            <div
                                v-if="babySlots.length === 0"
                                class="ma-3"
                                style=" font-size: large"
                            >
                                <p
                                    tabindex="0"
                                    class="mb-0 px-4"
                                    style="font-size: xx-large"
                                >
                                    Aktuell ist kein Unterricht. Du hast heute von <strong>{{ ("0" + new Date(slots[0].start).getHours()).slice(-2) + ":" + ("0" + new Date(slots[0].start).getMinutes()).slice(-2) }}</strong> Uhr
                                    bis <strong>{{ ("0" + new Date(this.slots[this.slots.length-1].end).getHours()).slice(-2) + ":" + ("0" + new Date(slots[this.slots.length-1].end).getMinutes()).slice(-2) }}</strong> Uhr
                                    Unterricht, der Unterricht hat entweder noch nicht begonnen oder ist schon vorbei.
                                </p>
                            </div>

                            <v-row
                                v-for="(item, index) in babySlots"
                                v-else
                                id="fachRow"
                                :key="'date_' + index"
                                cols="12"
                                class="pr-2"
                            >
                                <v-col
                                    v-if="isTimeslotCurrentlyRunningToday(item)"
                                    cols="2"
                                    style="display: flex; justify-content: center; align-items: center; font-weight: normal; font-size: larger;"
                                    class="text-center pa-2"
                                >
                                    <p
                                        class="ma-0"
                                        tabindex="0"
                                        :aria-label="('0' + new Date(item.start).getHours()).slice(-2) + ':' + ('0' + new Date(item.start).getMinutes()).slice(-2) + ' bis ' + ('0' + new Date(item.end).getHours()).slice(-2) + ':' + ('0' + new Date(item.end).getMinutes()).slice(-2)"
                                    >
                                        <span aria-hidden="true">
                                            {{ ("0" + new Date(item.start).getHours()).slice(-2) + ":" + ("0" + new Date(item.start).getMinutes()).slice(-2) }}
                                            <br>
                                            {{ ("0" + new Date(item.end).getHours()).slice(-2) + ":" + ("0" + new Date(item.end).getMinutes()).slice(-2) }}
                                        </span>
                                        <!-- Accessibility -->
                                        <span class="visually-hidden">
                                            {{ 'Von ' + getAccessibilityTime(('0' + new Date(item.start).getHours()).slice(-2)) + ' Uhr ' + getAccessibilityTime(('0' + new Date(item.start).getMinutes()).slice(-2)) + ' bis ' + getAccessibilityTime(('0' + new Date(item.end).getHours()).slice(-2)) + ' Uhr ' + getAccessibilityTime(('0' + new Date(item.end).getMinutes()).slice(-2)) }}
                                        </span>
                                    </p>
                                </v-col>
                                <v-col
                                    v-else
                                    cols="2"
                                    style="display: flex; justify-content: center; align-items: center; font-weight: normal; font-size: larger;"
                                    class="text-center pa-2"
                                >
                                    <p
                                        class="ma-0"
                                        :aria-label="('0' + new Date(item.start).getHours()).slice(-2) + ':' + ('0' + new Date(item.start).getMinutes()).slice(-2) + ' bis ' + ('0' + new Date(item.end).getHours()).slice(-2) + ':' + ('0' + new Date(item.end).getMinutes()).slice(-2)"
                                    >
                                        <span aria-hidden="true">
                                            {{ ("0" + new Date(item.start).getHours()).slice(-2) + ":" + ("0" + new Date(item.start).getMinutes()).slice(-2) }}
                                            <br>
                                            {{ ("0" + new Date(item.end).getHours()).slice(-2) + ":" + ("0" + new Date(item.end).getMinutes()).slice(-2) }}
                                        </span>
                                        <!-- Accessibility -->
                                        <span class="visually-hidden">
                                            {{ 'Von ' + getAccessibilityTime(('0' + new Date(item.start).getHours()).slice(-2)) + ' Uhr ' + getAccessibilityTime(('0' + new Date(item.start).getMinutes()).slice(-2)) + ' bis ' + getAccessibilityTime(('0' + new Date(item.end).getHours()).slice(-2)) + ' Uhr ' + getAccessibilityTime(('0' + new Date(item.end).getMinutes()).slice(-2)) }}
                                        </span>
                                    </p>
                                </v-col>
                                <v-col
                                    id="fachCol"
                                    cols="10"
                                    class="pa-2"
                                >
                                    <div v-if="getAppointmentsToday(item._id).length">
                                        <div
                                            v-for="el in getAppointmentsToday(item._id)"
                                            :key="el._id"
                                        >
                                            <FachSchuelerBaby
                                                style="width: 100%"
                                                :is-running="isTimeslotCurrentlyRunningToday(item)"
                                                :has-run="hasTimeslotRunAndRoomIsStillOpen(item, index)"
                                                :is-open="el.open"
                                                :appointment="el"
                                                :request-appointments="requestAppointments"
                                                :list-index="index"
                                                @pass-on-bbb-tut="receivedEmit"
                                            />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <FachSchuelerBaby
                                            style="width: 100%"
                                            :is-running="isTimeslotCurrentlyRunningToday(item)"
                                            :request-appointments="requestAppointments"
                                            :list-index="index"
                                            @pass-on-bbb-tut="receivedEmit"
                                        />
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row
                                v-for="(item, index) in babySlots"
                                id="previewRow"
                                :key="'app_' + index"
                                cols="12"
                                class="pr-2"
                            >
                                <v-col cols="1" />
                                <v-col
                                    cols="11"
                                    class="pa-2"
                                >
                                    <div>
                                        <div
                                            v-for="el in getAppointmentsToday(item._id)"
                                            :key="el._id"
                                        >
                                            <!-- Disable vue/no-v-html because appointment note is sanitized on backend-->
                                            <!-- eslint-disable vue/no-v-html -->
                                            <div
                                                v-if="el.note"
                                                style="display: flex; flex-flow: column; height: 100%; justify-content: center; padding-right: 20px;"
                                                v-html="sanitizeHtml(parseHtmlPreview(el.note))"
                                            />
                                            <!-- eslint-enable vue/no-v-html -->
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import sanitizeHtml from '../../util/sanitizeHtml'
import FachSchuelerBaby from "./FachSchuelerBaby"
import stundenplanIcon from '../../assets/Icons/timetable.svg'
import lautsprecherIcon from '../../assets/Icons/lautsprecher-weiss-13.svg'
import lautsprecherAusIcon from '../../assets/Icons/lautsprecher-weiss-aus-89.svg'

import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
import {tutorials} from '@/constants/tutorial';
import { parseHtml } from "@/util/filePreviewUtils";

export default {
    name: "PlanWidget",
    components: {
        FachSchuelerBaby,
    },
    props: {
        small: {required: false, default: false},
    },
    data() {
        return {
            sanitizeHtml,

            future: 0,
            past: 0,

            futureWeek: 0,
            pastWeek: 0,

            date: new Date(),    //wird beim Ändern des Tags im Kalendar geändert
            today: new Date(),   //nicht ändern! wird als Anhaltspkt zum Einfärben des akt. Tag genutzt
            appointments: [],
            person: {
                accessibility: {
                    screenreader: false,
                }
            },
            slots: [],
            babySlots: [], //nur aktueller und nächster Slot
            showLoading: false,

            account: '',

            stundenplanIcon,
            lautsprecherIcon,
        };
    },
    computed: {
        ...mapGetters('pupils', ['getTutorialProgress']),
        ...mapGetters('util', ['getServerTime']),
        ...mapState('util', ['serverTimeOffset']),
        ...mapState('translation', ['targetLang',]),
        ...mapState("tooltips", ["showTooltips"]),
    },
    watch: {
    },
    beforeCreate() {
        // Returns the ISO week of the date.
        Date.prototype.getWeek = function () {
            var date = new Date(this.getTime());
            date.setHours(0, 0, 0, 0);
            // Thursday in current week decides the year.
            date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
            // January 4 is always in week 1.
            var week1 = new Date(date.getFullYear(), 0, 4);
            // Adjust to Thursday in week 1 and count number of weeks from date to week1.
            return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                - 3 + (week1.getDay() + 6) % 7) / 7);
        }
    },
    async created() {
    },
    async mounted() {
        this.showLoading = true;
        await this.requestSlots();

        this.requestThreeSlots();

        this.date = await this.getDay();
        this.today = await this.getDay();

        await this.requestAppointments();

        this.showLoading = false;

        this.requestPerson();
        this.account = (await this.getMePupil()).account;

        this.jumpToCurrentDay();    //because somehow it jumps to a different day sometimes

        setTimeout(function(){ speechSynthesis.cancel(); }, 500);   //if you spam through tutorial this should cancel speaking
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions("teachers", ["getMeTeacher"]),
        ...mapActions("auth", ["logoutUser"]),
        ...mapActions('pupils', ['setTutorialProgress', 'createPupil', 'getMePupil',]),
        ...mapActions("util", ["loadServerTime"]),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang']),

        receivedEmit(callback) {
            /*emit to PlanSchueler*/
            this.$emit('start-bbb-tut', callback);
        },
        async checkParams() {
            let appointment = this.appointments.find((el) => {
                return this.$route.query.appointment == el._id
            })
            if (appointment) {
                this.date = new Date(appointment.day)
                appointment['open'] = true
            }
        },

        async requestAppointments() {
            this.appointments = await this.getAppointments();
            this.checkParams();
            this.countBadges();
            this.countBadgesWeek();
        },

        async requestSlots() {
            this.slots = await this.getSlots();
        },

        //babyView only need current, previous and next lesson
        async requestThreeSlots() {
            let temp = [];
            const currentTimeAndDay = new Date();
            let currentTimeString = currentTimeAndDay.toLocaleTimeString('de-DE');
            this.slots = await this.getSlots();

            for (let i = 0; i < this.slots.length; i++) {
                let startTime = new Date(this.slots[i].start).toLocaleTimeString('de-DE');
                let endTime = new Date(this.slots[i].end).toLocaleTimeString('de-DE');

                if (startTime <= currentTimeString && currentTimeString <= endTime) {

                    // Auskommentiert durch Feedback von Waldi muss evt. wieder eingefügt werden.

                    // // Get lesson before that because teachers might make breaks into timeslots because stoopid
                    // if (this.slots[i - 2]) {
                    //     temp.push(this.slots[i - 2]);
                    // }
                    //
                    // //get lesson before current
                    // if(this.slots[i - 1]) {
                    //     temp.push(this.slots[i - 1]);
                    // }

                    //current time is between start and end of timeslot
                    temp.push(this.slots[i]);

                    //get lesson after current
                    if (this.slots[i + 1]) {
                        temp.push(this.slots[i + 1]);
                    }

                    //if there is only one item return it with pop()
                    // else {
                    //     temp.pop();
                    // }
                    //else save array to babySlots
                    this.babySlots = temp;
                    break;
                } else {
                    //there is no current or next lesson \_(-.-)_/
                }
            }
        },

        async requestPerson() {
            this.person = await this.getMePupil();
            return this.person;
        },
        jumpToCurrentDay() {
            this.date = this.today;
        },
        getDisplayDate() {
            let heute = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
            let datum, tag;

            switch (heute.getDay()) {
                case 0:
                    tag = ("So.");
                    break;
                case 1:
                    tag = ("Mo.");
                    break;
                case 2:
                    tag = ("Di.");
                    break;
                case 3:
                    tag = ("Mi.");
                    break;
                case 4:
                    tag = ("Do.");
                    break;
                case 5:
                    tag = ("Fr.");
                    break;
                case 6:
                    tag = ("Sa.");
                    break;
                default:
                    tag = ("TEST");
            }
            datum = (tag + ", " + (heute.getDate()) + '.' + (heute.getMonth() + 1) + '.' + heute.getFullYear().toString().slice(-2));
            return datum;
        },
        async getDay() {
            let date = new Date(await this.loadServerTime());
            while (!(date .getDay() % 6)) {
                date = new Date(date .getFullYear(), date .getMonth(), date .getDate()-1);
            }
            return date;
        },
        getAppointmentToday(slot) {
            return this.appointments.find((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), this.date))
            })
        },
        getAppointmentsToday(slot) {
            return this.appointments.filter((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), this.date))
            })
        },
        countBadges() {
            this.future = 0;
            this.past = 0;
            this.appointments.forEach((el) => {
                if (!this.sameDay(new Date(el.day), this.date)) {
                    if (new Date(el.day) > this.date) {
                        this.future += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    } else {
                        this.past += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    }
                }
            })
        },

        countBadgesWeek() {
            this.futureWeek = 0;
            this.pastWeek = 0;
            this.appointments.forEach((el) => {
                if (!(new Date(el.day).getWeek() == this.date.getWeek())) {
                    if (new Date(el.day) > this.date) {
                        this.futureWeek += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    } else {
                        this.pastWeek += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    }
                }
            })
        },

        sameDay(first, second) {
            return first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate();
        },

        isTimeslotCurrentlyRunningToday(timeslot) {
            const currentTimeAndDay = this.today;
            const appointment = this.getAppointmentToday(timeslot._id);
            if (appointment) {
                const appointmentDate = new Date(appointment.day).toDateString();
                if (appointmentDate === currentTimeAndDay.toDateString()) {
                    // If we are in here that means the appointment is today
                    if (new Date(timeslot.start).toLocaleTimeString('de-DE') <= currentTimeAndDay.toLocaleTimeString('de-DE')
                        && new Date(timeslot.end).toLocaleTimeString('de-DE') > currentTimeAndDay.toLocaleTimeString('de-DE')) {
                        // Currenttime is between start and end of timeslot
                        return true;
                    }
                }
            }
            return false;
        },

        async readWidgetInfoText() {
            if(!this.person.accessibility.screenreader) {
                if ('speechSynthesis' in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis not supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                    return
                }

                let text = 'Du siehst deine Stunde die du jetzt hast und deine nächste.';

                let icon = document.getElementById('infoPlanBtn');

                if (window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel();
                    icon.src = lautsprecherIcon;
                } else {
                    if (this.targetLang !== 'de') {
                        text = await this.translateToTargetLang({
                            targetLang: this.targetLang, textToTranslate: text
                        });
                    }

                    icon.src = lautsprecherAusIcon;

                    this.setTranslatedText(text);

                    let msg = new SpeechSynthesisUtterance();
                    msg.text = text;
                    if (this.isLangPackageAvailable()) {
                        msg.lang = this.targetLang;
                        window.speechSynthesis.speak(msg);
                    }

                    msg.onend = function () {
                        icon.src = lautsprecherIcon;
                    }
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },

        /**
         * Needs check for when room isnt open anymore
         * @param timeslot
         * @param index
         * @returns {boolean}
         */
        hasTimeslotRunAndRoomIsStillOpen(timeslot, index) {
            return index < this.babySlots.length - 2 && this.isJoinable(timeslot);
        },

        isJoinable(timeslot) {
            const time = new Date(Date.now() + this.serverTimeOffset) ;

            const localeStart = new Date(timeslot.start);
            localeStart.setMinutes(localeStart.getMinutes() - 15);

            const localeEnd = new Date(timeslot.end);
            localeEnd.setMinutes(localeEnd.getMinutes() + 15);

            return localeStart.toLocaleTimeString('de-DE') <= time.toLocaleTimeString('de-DE')
                && localeEnd.toLocaleTimeString('de-DE') >= time.toLocaleTimeString('de-DE');
        },
        parseHtmlPreview(text){
            return parseHtml(true,text, 470, 250);
        },
        getAccessibilityTime(time) {
            let charArray = time.toString().split('');
            if(charArray[0] === '0' && charArray[1] === '0') {
                return '';
            } else if(charArray[0] === '0' && charArray[1] !== '0') {
                return charArray[1];
            } else {
                return charArray[0] + charArray[1];
            }
        }
    },
};
</script>

<style lang="scss" scoped>
table, th, td {
    border: none;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

th {
    vertical-align: top;
}

table tr th:first-child,
table tr td:first-child {
    padding-top: 10px;
    width: 10px !important;
}

table tr th:nth-child(2),
table tr td:nth-child(2) {
    width: 5px !important;
}

table tr th:last-child,
table tr td:last-child {
    width: 5px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table, tr, td {
    border: none;
}

.fach {
    cursor: pointer;
}

#fachRow {
    width: 99%;
    margin: 0.5em auto 0.5em auto;
}

h1 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

h2 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

@media only screen and (max-width: 399px) {
    #fachRow {
        padding-right: 0 !important;
    }

    #fachCol {
        padding-right: 0 !important;
    }
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.whiteBg {
    background-color: #ffffff;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 75px);
}
</style>
