<template>
    <div>
        <v-row class="mx-0 mt-3 mb-4">
            <div style="height: auto; width: 90%; margin: auto; border-radius: 15px; box-shadow: 1px 5px 5px silver;">
                <div style="background-color: #d7875f; border-radius: 15px 15px 0 0; font-size: larger; padding-top: 5px; padding-bottom: 20px; text-align: center; color: white;">
                    <div
                        class="d-flex align-center"
                        style="margin-right: 24px"
                    >
                        <img
                            :src="suchIcon"
                            style="height: 24px; margin-left: 24px; margin-top: 20px;
                        filter: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(19%) hue-rotate(65deg) brightness(104%) contrast(105%);"
                            alt="Lupen-Icon"
                        >

                        <v-text-field
                            v-model="search"
                            label="Nach Schüler suchen"
                            single-line
                            dark
                            hide-details
                            style="width: 300px !important; max-width: 300px"
                            class="pl-6"
                        />

                        <v-spacer />

                        <div style="align-self: end;">
                            <span>Schüler-Daten hochladen:</span>
                            <v-tooltip
                                bottom
                                :open-on-click="false"
                                :open-on-hover="true"
                                content-class="infoTextToolTip"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-bind="attrs"
                                        class="my-auto"
                                        v-on="on"
                                    >
                                        <v-btn
                                            color="#FFFFFF40"
                                            x-small
                                            class="mainFunctionButtons ml-3 my-auto"
                                            v-bind="attrs"
                                            @click="showExcelDialog = true"
                                            v-on="on"
                                        >
                                            <img
                                                :src="hochladen"
                                                alt="hochladen-Symbol"
                                            >
                                        </v-btn>
                                    </span>
                                </template>
                                <span>Hier können Sie eine Excel-Datei mit Schüler-Daten hochladen.</span>
                            </v-tooltip>
                        </div>
                    </div>
                </div>
                <div
                    style="display: flex; font-size: larger; font-family: Roboto; margin-top: 24px; margin-left: 26px"
                    class=""
                >
                    <img
                        :src="pupilIcon"
                        style="height: 24px; margin-right: 10px"
                        alt="Schüler-Symbol"
                    >
                    Schüler
                </div>
                <v-data-table
                    :headers="headers"
                    :items="allPupils"
                    :search="search"
                    sort-by="lastName"
                    disable-pagination
                    hide-default-footer
                    fixed-header
                    :height="height"
                    class="ma-3"
                    locale="de-DE"
                    no-data-text="Keine Daten verfügbar"
                    no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{ row.item.lastName }}</td>
                            <td> {{ row.item.name }}</td>
                            <td>{{ row.item.gender }}</td>
                            <td>
                                <v-btn
                                    class="editPupilButton mx-2"
                                    color="#EAEAEA"
                                    small
                                    elevation="1"
                                    light
                                    @click="editUser(row.item);"
                                >
                                    <img
                                        :src="bearbeitenIcon"
                                        alt="bearbeiten-Symbol"
                                    >
                                    Schülerprofil bearbeiten
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <v-overlay
                    absolute
                    :value="showLoadingOverlay"
                    style="width: 100% !important;"
                >
                    <v-progress-circular
                        v-if="showLoadingOverlay"
                        indeterminate
                        size="100"
                    />
                </v-overlay>
            </div>
        </v-row>

        <!-- Schüler anlegen / bearbeiten -->
        <v-row
            id="edit"
            class="mx-0 my-3"
        >
            <div class=" editContainer">
                <v-row
                    class="mx-0"
                    style="background-color: #d7875f; border-radius: 15px 15px 0 0"
                >
                    <v-col
                        style="color: white; font-size: larger;"
                        class="ma-1 ml-5"
                    >
                        <v-avatar
                            style="width: 24px;"
                            color="rgba(255,255,255,0.8)"
                        >
                            <img
                                :src="pupilIcon"
                                alt="Schüler-Symbol"
                            >
                        </v-avatar>
                        {{ accountId ? 'Schüler bearbeiten' : 'Neuen Schüler anlegen' }}
                    </v-col>
                    <v-col
                        class="mr-6"
                        style="display: flex; justify-content: flex-end;"
                    >
                        <v-btn
                            color="#FFFFFF40"
                            x-small
                            class="mainFunctionButtons ml-3 my-auto"
                            :disabled="!accountId"
                            @click="setDeleteDialog('pupil')"
                        >
                            <img
                                :src="loeschIcon"
                                alt="löschen-Symbol"
                            >
                        </v-btn>
                        <v-tooltip
                            top
                            nudge-right="100"
                            :open-on-click="true"
                            :open-on-hover="false"
                            content-class="infoTextToolTip"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="#FFFFFF40"
                                    x-small
                                    class="mainFunctionButtons ml-3 my-auto"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <img
                                        :src="infoIcon"
                                        alt="info-Symbol"
                                    >
                                </v-btn>
                            </template>
                            <span>Hier können Sie Schüler und Erziehungsberechtigte anlegen oder bearbeiten.</span>
                        </v-tooltip>
                        <v-btn
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#BDBDBD50"
                            dark
                            elevation="0"
                            @click="stopEdit"
                        >
                            <span>Abbrechen</span>
                        </v-btn>
                        <v-btn
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#69CD70"
                            dark
                            elevation="4"
                            @click="openPupilSavedOrEditedDialog"
                        >
                            <span>Speichern</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="mx-2 mb-2">
                    <!--child-->
                    <v-row>
                        <v-col class="mx-5">
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Persönliche Daten
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Vorname:
                                </v-col>
                                <v-col cols="7">
                                    <input
                                        v-model="name"
                                        type="text"
                                        class="input"
                                        :class="{ errorInput: isInvalidUserInput.name }"
                                        @change="() => { suggestAccountName(accountId); }"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="isInvalidUserInput.name && name.length === 0"
                                dense
                            >
                                <v-col
                                    class="pl-2; pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>
                            <v-row
                                v-else-if="isInvalidUserInput.name"
                                dense
                            >
                                <v-col
                                    class="pl-2; pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Name darf keine Zahlen enthalten.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Nachname:
                                </v-col>
                                <v-col cols="7">
                                    <input
                                        v-model="lastName"
                                        type="text"
                                        class="input"
                                        :class="{ errorInput: isInvalidUserInput.lastName }"
                                        @change="() => { suggestAccountName(accountId); generateParentAccountName() }"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="isInvalidUserInput.lastName && lastName.length === 0"
                                dense
                            >
                                <v-col
                                    class="pl-2; pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>
                            <v-row
                                v-else-if="isInvalidUserInput.lastName"
                                dense
                            >
                                <v-col
                                    class="pl-2; pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Name darf keine Zahlen enthalten.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Geschlecht:
                                </v-col>
                                <v-col cols="7">
                                    <select
                                        v-model="gender"
                                        class="input"
                                        :class="{ errorInput: isInvalidUserInput.gender }"
                                        @change="validateInput"
                                    >
                                        <option
                                            disabled
                                            selected
                                            value
                                        >
                                            Auswählen
                                        </option>
                                        <option value="m">
                                            Männlich
                                        </option>
                                        <option value="w">
                                            Weiblich
                                        </option>
                                        <option value="d">
                                            Divers
                                        </option>
                                        <option value="">
                                            Keine Angabe
                                        </option>
                                    </select>
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="isInvalidUserInput.gender"
                                dense
                            >
                                <v-col
                                    class="pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5">
                                    Notiz:
                                </v-col>
                                <v-col cols="7">
                                    <textarea
                                        v-model="note"
                                        class="input"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col
                            class="mx-5"
                            style="margin-right: auto"
                        >
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Schulisches
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Gruppen:
                                </v-col>
                                <v-col cols="7">
                                    <v-row
                                        v-for="(item, index) in groups"
                                        :key="index"
                                    >
                                        <div
                                            class="px-3 py-1 mb-1"
                                            style="min-width: 200px; background-color: var(--v-hellgrau-base); border-radius: 8px"
                                        >
                                            {{ item.name }}
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="8"
                                    style="height: 48px"
                                >
                                    <div
                                        class="d-flex"
                                        style="display: inline-flex; white-space: nowrap;"
                                    >
                                        Einfache Ansicht:
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    class="pl-2"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    fa-question-circle
                                                </v-icon>
                                            </template>
                                            <v-row class="ml-0">
                                                Einfache Ansicht:
                                            </v-row>
                                            <v-row>
                                                <img
                                                    style="height: 100px"
                                                    :src="easyInfoSvg"
                                                    alt="Vorschau einfache Ansicht"
                                                >
                                            </v-row>
                                            <v-row class="ml-0">
                                                Normale Ansicht:
                                            </v-row>
                                            <v-row>
                                                <img
                                                    style="height: 100px"
                                                    :src="hardInfoSvg"
                                                    alt="Vorschau normale Ansicht"
                                                >
                                            </v-row>
                                        </v-tooltip>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="3"
                                    style="height: 48px"
                                >
                                    <v-checkbox
                                        v-model="babyView"
                                        :ripple="false"
                                        class="mt-0 pt-0 babyViewCheckbox"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="8"
                                    style="height: 48px"
                                >
                                    <div
                                        class="d-flex"
                                        style="display: inline-flex; white-space: nowrap;"
                                    >
                                        Übersetzen erlauben:
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    class="pl-2"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    fa-question-circle
                                                </v-icon>
                                            </template>
                                            <p style="width: 250px">
                                                Sie entscheiden ob diese*r Schüler*in die Übersetzungsfunktion nutzen kann.
                                                Texte in eKlara werden mit Google Services übersetzt, aus datenschutzrechtlichen Gründen muss
                                                diese Entscheidung deshalb von einem Erwachsenen getroffen werden.
                                            </p>
                                        </v-tooltip>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="3"
                                    style="height: 48px"
                                >
                                    <v-checkbox
                                        v-model="translationAllowed"
                                        :ripple="false"
                                        class="mt-0 pt-0 babyViewCheckbox"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    style="height: 36px"
                                    class="pb-0"
                                >
                                    <div
                                        class="d-flex"
                                        style="display: inline-flex; text-overflow: ellipsis; white-space: nowrap;"
                                    >
                                        Barrierefreier Modus
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="1"
                                    style="height: 24px"
                                    class="py-0"
                                />
                                <v-col
                                    cols="7"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    Sehen
                                </v-col>
                                <v-col
                                    cols="3"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    <v-checkbox
                                        v-model="sehen"
                                        :ripple="false"
                                        class="mt-0 pt-0"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="2"
                                    style="height: 24px"
                                    class="py-0"
                                />
                                <v-col
                                    cols="6"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    <span
                                        style="margin-left: -8px"
                                        :style="sehen ? '' : 'color: rgba(0, 0, 0, 0.4) !important'"
                                    >
                                        ⮡</span><span
                                            style="margin-left: 10px; padding-right: 5px"
                                            :style="sehen ? '' : 'color: rgba(0, 0, 0, 0.4) !important'"
                                        >Screenreader</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                fa-question-circle
                                            </v-icon>
                                        </template>
                                        <span>Es werden Funktionen deaktiviert, welche für Screenreader Nutzer nutzlos sind (Vorlesefunktion, Stundenplan im Vollbild)</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col
                                    cols="3"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    <v-checkbox
                                        v-model="screenreader"
                                        :ripple="false"
                                        class="mt-0 pt-0"
                                        :disabled="!sehen"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="1"
                                    style="height: 24px"
                                    class="py-0"
                                />
                                <v-col
                                    cols="7"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    Hören
                                </v-col>
                                <v-col
                                    cols="3"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    <v-checkbox
                                        v-model="hoeren"
                                        :ripple="false"
                                        class="mt-0 pt-0"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="1"
                                    style="height: 24px"
                                    class="py-0"
                                />
                                <v-col
                                    cols="7"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    Motorik
                                </v-col>
                                <v-col
                                    cols="3"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    <v-checkbox
                                        v-model="motorik"
                                        :ripple="false"
                                        class="mt-0 pt-0"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            class="mx-5"
                        >
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Technisches
                                    </div>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        class="generatePasswordButton"
                                        elevation="0"
                                        icon
                                        @click="clickGeneratePassword"
                                    >
                                        <img
                                            :src="aktualisierenIcon"
                                            alt=""
                                        >
                                        Passwort generieren
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Login-ID*:
                                </v-col>
                                <v-col
                                    cols="7"
                                    @click="isAccountNameModified = true;"
                                >
                                    <input
                                        v-model="accountName"
                                        type="text"
                                        class="input"
                                        :class="{ errorInput: isInvalidUserInput.accountName }"
                                        :disabled="accountId"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="isInvalidUserInput.accountName"
                                dense
                            >
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Login-ID darf nur Zahlen und Buchstaben (keine Umlaute) enthalten.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Passwort:
                                </v-col>
                                <v-col
                                    cols="7"
                                    @click="isPasswordModified = true"
                                >
                                    <input
                                        v-model="password"
                                        :type="isPasswordModified ? 'text' : 'password'"
                                        :disabled="!isPasswordModified"
                                        class="input"
                                        :class="{ errorInput: isInvalidUserInput.password }"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="isInvalidUserInput.password"
                                dense
                            >
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Passwort erfüllt Richtlinie nicht.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pt-0"
                                >
                                    <p
                                        class="mb-2"
                                        style="color: #808080;"
                                    >
                                        Muss mind. 8 Zeichen lang sein und einen Buchstaben und eine Zahl enthalten.
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="7">
                                    <v-btn
                                        v-if="false"
                                        class="generatePasswordButton"
                                        elevation="0"
                                        icon
                                        @click="clickGeneratePassword"
                                    >
                                        <img
                                            :src="aktualisierenIcon"
                                            alt=""
                                        >
                                        Passwort generieren
                                    </v-btn>
                                </v-col>
                                <v-col cols="5">
                                    <v-btn
                                        v-if="false"
                                        class=""
                                        style="max-height: 25px;max-width: 100%; width: 100%; border-radius: 8px; background-color: rgba(230,34,30,0.41)"
                                        color=""
                                        small
                                        light
                                        @click="showDeleteDialog = true"
                                    >
                                        Löschen
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-spacer class="mt-1" />
                        </v-col>
                    </v-row>

                    <hr style="height: 1px; color: var(--v-hellgrau-base); background-color: var(--v-hellgrau-base); border: 0 none;">

                    <!--choose from existing parent-->
                    <v-row v-if="!parentId">
                        <v-col class="mx-5">
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Vorhandene Erziehungsberechtigte
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-select
                                        v-model="selectedParentId"
                                        :items="parents"
                                        item-text="account.accountName"
                                        item-value="_id"
                                        :disabled="createNewParentSelected"
                                        hide-details="true"
                                        class="ma-0 pa-0"
                                        :class="{ errorInput: isInvalidUserInput.parent }"
                                        no-data-text="Keine Eltern vorhanden"
                                    />
                                </v-col>

                                <v-col cols="6">
                                    <v-row>
                                        <v-col
                                            cols="11"
                                            class="pa-0 pl-4 d-flex align-start"
                                        >
                                            Neuen Erziehungsberechtigten anlegen:
                                        </v-col>
                                        <v-col
                                            cols="1"
                                            class="pa-0 d-flex justify-center align-start"
                                        >
                                            <v-checkbox
                                                v-model="createNewParentSelected"
                                                :class="{ errorInput: isInvalidUserInput.parent }"
                                                class="ma-0"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <!--new parent-->
                    <v-row v-if="parentId || createNewParentSelected">
                        <v-col class="mx-5">
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Erziehungsberechtigter
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5">
                                    Geschlecht:
                                </v-col>
                                <v-col cols="7">
                                    <select
                                        v-model="parentGender"
                                        class="input"
                                        :class="{ errorInput: isInvalidUserInput.parentGender }"
                                        @change="validateInput"
                                    >
                                        <option
                                            disabled
                                            selected
                                            value
                                        >
                                            Auswählen
                                        </option>
                                        <option value="m">
                                            Männlich
                                        </option>
                                        <option value="w">
                                            Weiblich
                                        </option>
                                        <option value="d">
                                            Divers
                                        </option>
                                        <option value="">
                                            Keine Angabe
                                        </option>
                                    </select>
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="isInvalidUserInput.parentGender"
                                dense
                            >
                                <v-col
                                    class="pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>
                            <v-row />
                        </v-col>
                        <v-col class="mx-5">
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Kontaktinformationen
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Telefonnummer:
                                </v-col>
                                <v-col cols="7">
                                    <input
                                        v-model="parentPhone"
                                        type="text"
                                        class="input"
                                        placeholder="Optional"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="isInvalidUserInput.parentPhone && parentPhone.length !== 0"
                                dense
                            >
                                <v-col
                                    class="pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Telefonnummer darf keine Buchstaben enthalten.</span>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            class="mx-5"
                        >
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Technisches
                                    </div>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        class="generatePasswordButton"
                                        elevation="0"
                                        icon
                                        @click="clickGenerateParentPassword"
                                    >
                                        <img
                                            :src="aktualisierenIcon"
                                            alt=""
                                        >
                                        Passwort generieren
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5">
                                    Login-ID*:
                                </v-col>
                                <v-col
                                    cols="7"
                                    @click="isParentAccountNameModified = true;"
                                >
                                    <input
                                        v-model="parentAccountName"
                                        type="text"
                                        class="input"
                                        :class="{ errorInput: isInvalidUserInput.parentAccountName || isInvalidUserInput.parentAccountNameDuplicate }"
                                        :disabled="parentId"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>

                            <v-row
                                v-if="isInvalidUserInput.parentAccountName"
                                dense
                            >
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Login-ID darf nur Zahlen und Buchstaben (keine Umlaute) enthalten.</span>
                                </v-col>
                            </v-row>

                            <v-row
                                v-if="isInvalidUserInput.parentAccountNameDuplicate"
                                dense
                            >
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Login-ID wird bereits verwendet.</span>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5">
                                    Passwort:
                                </v-col>
                                <v-col
                                    cols="7"
                                    @click="isParentPasswordModified = true"
                                >
                                    <input
                                        v-model="parentPassword"
                                        :type="isParentPasswordModified ? 'text' : 'password'"
                                        :disabled="!isParentPasswordModified"
                                        class="input"
                                        :class="{ errorInput: isInvalidUserInput.parentPassword }"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>

                            <v-row
                                v-if="isInvalidUserInput.parentPassword"
                                dense
                            >
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Passwort erfüllt Richtlinie nicht.</span>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pt-0"
                                >
                                    <p
                                        class="mb-2"
                                        style="color: #808080;"
                                    >
                                        Muss mind. 8 Zeichen lang sein und einen Buchstaben und eine Zahl enthalten.
                                    </p>
                                </v-col>
                            </v-row>

                            <v-row v-if="parentId">
                                <v-col>
                                    <v-btn
                                        class="generatePasswordButton"
                                        elevation="0"
                                        icon
                                        @click="setDeleteDialog('parent')"
                                    >
                                        <img
                                            :src="loeschIcon"
                                            alt=""
                                        >
                                        Eltern-Account löschen
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <!-- are these even used anymore? -->
                            <v-row>
                                <v-col cols="7">
                                    <v-btn
                                        v-if="false"
                                        class="generatePasswordButton"
                                        elevation="0"
                                        icon
                                        @click="clickGenerateParentPassword"
                                    >
                                        <img
                                            :src="aktualisierenIcon"
                                            alt=""
                                        >
                                        Passwort generieren
                                    </v-btn>
                                </v-col>
                                <v-col cols="5">
                                    <v-btn
                                        v-if="false"
                                        class=""
                                        style="max-height: 25px;max-width: 100%; width: 100%; border-radius: 8px; background-color: rgba(230,34,30,0.41)"
                                        color=""
                                        small
                                        light
                                        @click="showDeleteDialog = true"
                                    >
                                        Löschen
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-spacer class="mt-1" />
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-row>


        <!--#region dialogs -->
        <!-- delete dialog -->
        <v-dialog
            v-model="showDeleteDialog"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    {{ deleteDialogTitle }}
                </v-card-title>
                <v-card-text>{{ deleteDialogText }}</v-card-text>

                <!-- delete pupil account -->
                <v-card-actions v-if="deleteDialogSubject === 'pupil'">
                    <v-spacer />
                    <v-btn
                        color=""
                        text
                        @click="showDeleteDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="red red-1"
                        text
                        @click="() => {deleteUser(); showDeleteDialog = false}"
                    >
                        Löschen
                    </v-btn>
                </v-card-actions>

                <!-- delete parent account -->
                <v-card-actions v-else-if="deleteDialogSubject === 'parent'">
                    <v-spacer />
                    <v-btn
                        color=""
                        text
                        @click="showDeleteDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="red red-1"
                        text
                        @click="() => {deletePupilParent(); showDeleteDialog = false}"
                    >
                        Löschen
                    </v-btn>
                </v-card-actions>

                <!-- just in case the dialog is called somewhere without the deleteDialogSubject -->
                <v-card-actions v-else>
                    <v-spacer />
                    <v-btn
                        color=""
                        text
                        @click="showDeleteDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- create new account -->
        <v-dialog
            v-model="showAccountCreatedDialog"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    Accounterstellung
                </v-card-title>
                <v-card-text>
                    <b>WICHTIG:</b> Laden Sie direkt die Zugangsdaten für den Schüler/die Eltern herunter. Dies ist nachträglich aus Datenschutzgründen nicht mehr möglich.
                    <br> <br>
                    Insofern der Schüler/die Eltern die Zugangsdaten vergessen/verloren hat, klicken Sie auf "Passwort generieren" und "Speichern", um die neuen Zugangsdaten zu erhalten.

                    <br> <br>
                    <div>
                        <v-row
                            class="mt-5"
                            align="center"
                            justify="center"
                        >
                            <div style="width: 200px">
                                <div
                                    style="width: 200px; gap: 4px"
                                    class="d-flex flex-column align-center ma-0"
                                >
                                    <v-btn
                                        v-if="createNewParentSelected"
                                        class="text-none"
                                        color="success"
                                        @click="parentPdf()"
                                    >
                                        Download Zugangsdaten Eltern
                                    </v-btn>

                                    <v-btn
                                        class="text-none"
                                        color="success"
                                        @click="pdf()"
                                    >
                                        Download Zugangsdaten Schüler
                                    </v-btn>

                                    <v-btn
                                        class="text-none"
                                        @click="kuhR()"
                                    >
                                        Kuh-R Bastelvorlage
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="text-none"
                        @click="closeDialogs"
                    >
                        Fertig
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- edit account -->
        <v-dialog
            v-model="showAccountEditedDialog"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    Accountänderung
                </v-card-title>
                <v-card-text>
                    <b>WICHTIG:</b> Laden Sie direkt die neuen Zugangsdaten für den Schüler/die Eltern herunter. Dies ist nachträglich aus Datenschutzgründen nicht mehr möglich.
                    <br> <br>
                    Insofern der Schüler/die Eltern die Zugangsdaten vergessen/verloren hat, klicken Sie auf "Passwort generieren" und "Speichern", um die neuen Zugangsdaten zu erhalten.

                    <br> <br>
                    <div>
                        <v-row
                            class="mt-1"
                            align="center"
                            justify="center"
                        >
                            <div style="width: 200px">
                                <div
                                    style="width: 200px; gap: 4px"
                                    class="d-flex flex-column align-center ma-0"
                                >
                                    <v-btn
                                        v-if="isParentPasswordModified || createNewParentSelected"
                                        class="text-none"
                                        color="success"
                                        @click="parentPdf()"
                                    >
                                        Download Zugangsdaten Eltern
                                    </v-btn>

                                    <v-btn
                                        v-if="isPasswordModified"
                                        class="text-none"
                                        color="success"
                                        @click="pdf()"
                                    >
                                        Download Zugangsdaten Schüler
                                    </v-btn>

                                    <v-btn
                                        v-if="isPasswordModified"
                                        class="text-none"
                                        @click="kuhR()"
                                    >
                                        Kuh-R Bastelvorlage
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="text-none"
                        @click="closeDialogs"
                    >
                        Fertig
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Excel Upload -->
        <v-dialog
            v-model="showExcelDialog"
            persistent
            fullscreen
        >
            <v-card>
                <v-card-title
                    class="headline"
                    style="padding-bottom: 16px; background-color: var(--v-gelb-base); margin-bottom: 24px"
                >
                    <img
                        :src="hochladen"
                        style="height: 35px; margin-right: 8px; color: white"
                        class="iconToWhite"
                        alt="Excel hochladen"
                    >
                    <span style="color: white">Excel-Upload</span>
                    <v-spacer />
                    <v-btn
                        x-small
                        class="d-flex justify-end align-center"
                        style="background-color: #f8f8f880; width: 35px; height: 35px;"
                        @click="showExcelDialog = false"
                    >
                        <img
                            :src="schliessenIcon"
                            class="closeIcon"
                            alt="Dialog schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text style="font-size: 16px">
                    Hier können sie Schüler aus einer Excel (.xlsx) Datei hochladen. Dafür laden sie sich die <a
                        id="downloadVorlageBtn"
                        :href="excelSheet"
                        download
                    >Vorlage</a> herunter und füllen diese anhand des Beispiels aus.
                    <br>
                    Dabei müssen sie einige Regeln beachten:
                    <ul>
                        <li>Ändern sie nicht die Namen der Spalten (Benutzername, Vorname, ...) in der Beispieldatei.</li>
                        <li>Die Login-ID darf keine Umlaute oder Sonderzeichen enthalten. Lassen sie das Feld frei um automatisch aus dem Vornamen und dem ersten Buchstaben des Nachnamens die Login-ID zu generieren</li>
                        <li>Vor- und Nachname sind Pflichtfelder.</li>
                    </ul>
                    Anschließend laden sie die ausgefüllte Vorlage hoch. Der Import startet dann automatisch. Ist der Import abgeschlossen startet automatisch der Download eine ZIP Datei in der sich die PDFs mit allen Accountdaten zum Ausdrucken befinden.
                    <br>
                    <!--                    <v-checkbox -->
                    <!--                        :value="excelAlsoCreateParents"-->
                    <!--                        label="Accounts für Eltern automatisch mitgenerieren"-->
                    <!--                    /> Wenn Sie dieses Kästchen anklicken werden beim Import auch Elternaccounts generiert, damit sich die Eltern auch einloggen können. Die PDFs mit den Zugangsdaten werden auch mit heruntergeladen.-->
                    <!--                    <br>-->
                    <v-btn
                        class="ma-2"
                        @click="clickDownloadVorlage"
                    >
                        Vorlage herunterladen
                    </v-btn>
                    <v-btn
                        class="ma-2"
                        @click="clickExelUploadButton"
                    >
                        Ausgefüllte Vorlage hochladen
                    </v-btn>
                    <br>
                    Um möglichen Fehlern beim Import vorzubeugen befindet sich im Folgenden eine Konsole auf der mögliche Fehler (rot) und Warnungen (orange) ausgegeben werden. Bei einer roten Meldung wird der/die Nutzer/in nicht erstellt. Bei einer orangenen Meldung wird der Nutzer trotzdem erstellt. Die Fehlermeldungen beinhalten immer eine Zeilenangabe, sodass sie wissen in welcher Zeile ihrer Exceldatei ein Fehler aufgetreten ist.
                    <br>
                    <div
                        class="loggerDiv"
                        style="display: flex; flex-direction: column"
                    >
                        <p
                            v-for="(log, index) in excelImportLoggerFilterLogLevel"
                            :key="index"
                            :class="'loggerLevel' + log.level"
                        >
                            {{ log.message }}
                        </p>
                        <p
                            v-if="excelImportLogger.length === 0"
                            class="ml-2 mt-2"
                            style="font-size: x-large; opacity: 50%"
                        >
                            Konsole
                        </p>
                    </div>
                    <v-checkbox
                        v-model="excelImportLoggerShowErrors"
                        label="Fehler anzeigen"
                        hide-details
                        class="mt-0"
                    />
                    <v-checkbox
                        v-model="excelImportLoggerShowWarnings"
                        label="Warnungen anzeigen"
                        hide-details
                        class="mt-0"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="mr-4"
                        color="green darken-1"
                        dark
                        @click="closeDialogs(); excelImportLogger = []"
                    >
                        Fertig
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-overlay
                absolute
                :value="showLoadingOverlay"
                style="width: 100% !important;"
            >
                <div class="d-flex flex-column justify-center align-center">
                    <v-progress-linear
                        v-if="showLoadingOverlay"
                        v-model="excelProgressbarPercentage"
                        height="25"
                        style="width: 200px;"
                    >
                        <strong>{{ excelCurrentStatusOfImports + ' / ' + excelAmountOfImports }}</strong>
                    </v-progress-linear>
                    <p style="color: white; font-weight: bold; width: 500px; text-align: center">
                        Bitte warten. Dieser Vorgang kann bis zu 15 Minuten dauern.
                        Am Ende werden die Schülerausweise zum Herunterladen bereit gestellt.
                    </p>
                    <p style="color: white; font-weight: bold; width: 500px; text-align: center">
                        {{ excelExtraStatusMessage }}
                    </p>
                </div>
            </v-overlay>
        </v-dialog>
        <!--#endregion -->
        <iframe
            id="excelSheetIFrame"
            class="visually-hidden"
        />

        <input
            id="exelUploadInput"
            ref="exelUploadInput"
            type="file"
            hidden
            @change="() => exelUploadInputChange()"
        >
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import suchIcon from "../../assets/Icons/suche-26.svg";
import pupilIcon from "../../assets/Icons/profile.svg";
import hochladen from "../../assets/Icons/hochladen-15.svg";
import bearbeitenIcon from "../../assets/Icons/bearbeiten-normal-weiß-41.svg";
import infoIcon from "../../assets/Icons/ausrufezeichen-62.svg";
import loeschIcon from "../../assets/Icons/papierkorb-verwaltung-109.svg";

import aktualisierenIcon from "../../assets/Icons/aktualisieren-107.svg";
import roboto from "../../assets/Fonts/Roboto-Regular.ttf";
import robotoBold from "../../assets/Fonts/Roboto-Bold.ttf";
import schliessenIcon from "../../assets/Icons/abbrechen-08.svg";

import easyInfoSvg from "../../assets/einfache-Ansicht.svg";
import hardInfoSvg from "../../assets/normale-Ansicht.svg";

import excelSheet from "../../assets/excel_import_template.xlsx"

import generateRandomPassword from "../../util/generateRandomPassword";


import * as xlsx from "xlsx";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import {compress} from "shrink-string";

import LoginPdf from "../../util/loginPdf";

import * as backend from "@/api/backend";


export default {
    name: "Schueler",
    data: () => ({
        showLoadingOverlay: false,
        showAccountCreatedDialog: false,
        showAccountEditedDialog: false,

        id: null,
        name: "",
        lastName: "",
        accountId: null,
        gender: "",
        note: "",
        babyView: false,
        translationAllowed: false,
        sehen: false,
        screenreader: false,
        hoeren: false,
        motorik: false,
        accountName: "",
        password: "",
        qrPassword: generateRandomPassword({passwordLength: 12 }), // Initialize with random value, to avoid account creation with weak qrPassword
        groups: [],
        isPasswordModified: true,
        isAccountNameModified: false,

        parentGender: "",
        parentPhone: "",
        parentAccountName: "",
        parentPassword: "",
        parentQrPassword: generateRandomPassword({passwordLength: 12 }), // Initialize with random value, to avoid account creation with weak qrPassword
        parentId: "",
        parentAccount: null,
        serverParents: [],
        selectedParentId: null,
        createNewParentSelected: false,
        isParentAccountNameModified: true,
        isParentPasswordModified: false,


        isInvalidUserInput: {
            name: false,
            lastName: false,
            gender: false,
            accountName: false,
            password: false,
            parent: false,
            parentGender: false,
            parentPhone: false,
            parentAccountName: false,
            parentAccountNameDuplicate: false,
            parentPassword: false,
        },

        height: 0,
        search: "",
        headers: [
            {text: "Name", value: "lastName"},
            {text: "Vorname", value: "name"},

            {text: "Geschlecht", value: "gender"},
            {text: "", value: "name", sortable: false},
        ],
        allPupils: [],

        deleteDialogTitle: '',
        deleteDialogText: '',
        deleteDialogSubject: '',
        showDeleteDialog: false,

        showExcelDialog: false,
        excelAlsoCreateParents: false,
        excelSheet,
        excelImportLogger: [],
        excelImportLoggerShowErrors: true,
        excelImportLoggerShowWarnings: true,
        excelAmountOfImports: 0,
        excelCurrentStatusOfImports: 0,
        excelExtraStatusMessage: '',

        suchIcon,
        pupilIcon,
        bearbeitenIcon,

        easyInfoSvg,
        hardInfoSvg,

        infoIcon,
        loeschIcon,
        aktualisierenIcon,
        hochladen,
        schliessenIcon,

    }),
    computed: {
        ...mapGetters("accounts", ["accountsById"]),
        ...mapGetters("pupils", ["getPupilListStatus"]),
        parents() {
            if(!Object.values(this.accountsById).length > 0) {
                // accounts not loaded yet
                return [];
            }
            return this.serverParents.map(parent => ({
                ...parent,
                account: this.accountsById[parent.account],
            }));
        },
        excelProgressbarPercentage() {
            return (this.excelCurrentStatusOfImports / parseFloat(this.excelAmountOfImports)) * 100;
        },

        excelImportLoggerFilterLogLevel() {
            if(this.excelImportLoggerShowErrors && this.excelImportLoggerShowWarnings) {
                return this.excelImportLogger;
            } else if(this.excelImportLoggerShowErrors && !this.excelImportLoggerShowWarnings){
                return this.excelImportLogger.filter(log => log.level === "error");
            } else if(!this.excelImportLoggerShowErrors && this.excelImportLoggerShowWarnings) {
                return this.excelImportLogger.filter(log => log.level === "warning");
            } else {
                return [];
            }
        },

        isAccountNameFree() {
            for (let i = 0; i < this.allPupils.length; i++) {
                let pupil = this.allPupils[i];
                if (this.accountsById[pupil.account] && this.accountName === this.accountsById[pupil.account].accountName) {
                    return false;
                }
            }

            return true;
        },
        selectedParent() {
             return this.parents.find(parent => parent._id === this.selectedParentId);
        },
    },
    watch: {
        async selectedParentId(newVal) {
            this.createNewParentSelected = false;

            const parent = newVal 
                ?  await this.getParent(newVal) 
                : null;
            if (parent) {
                this.parentGender = parent.gender;
                this.parentPhone = parent.phone;
                this.parentAccountName = this.accountsById[parent.account].accountName;
                this.parentId = parent._id;
                this.parentAccount = parent.account;
                this.parentPassword =  generateRandomPassword({ length: 8});
                this.isParentPasswordModified = false;
                this.isParentAccountNameModified = false;
            } else {
                this.parentGender = "";
                this.parentPhone = "";
                this.parentAccountName = `${this.accountName}Eltern` ;
                this.parentId = "";
                this.parentAccount = null;
                this.parentPassword =  generateRandomPassword({ length: 8});
                this.isParentPasswordModified = true;
                this.isParentAccountNameModified = true;
            }
        },
        createNewParentSelected(newVal) {
            if(newVal) {
                this.parentId = null;
                this.parentAccount = null;
                this.parentGender = "";
                this.parentPhone = "";
                this.parentAccountName = `${this.accountName}Eltern` ;
                this.parentPassword = generateRandomPassword({passwordLength: 8});
                this.isParentLoginIdModified = true;
                this.isParentPasswordModified = true; 
            } else {
                this.isParentAccountNameModified = false;
                this.isParentPasswordModified = false; 
            }
        },
        getPupilListStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestPupils();
            }
        },
        password:  function () {
            this.qrPassword = generateRandomPassword({passwordLength: 12});
        },
        parentPassword: function () {
            this.parentQrPassword = generateRandomPassword({passwordLength: 12});
        },
    },
    async created() {
        this.height = window.innerHeight * 0.3;
    },
    async mounted() {
        this.showLoadingOverlay = true;
        this.requestPupils();
        this.requestParents();
    },
    methods: {
        ...mapGetters("accounts", ["accounts"]),
        ...mapActions("groups", ["getGroup", "getGroups"]),
        ...mapActions("pupils", ["createPupil", "getPupil", "getPupils", "editPupil", "deletePupile", "setPupilListStatus"]),
        ...mapActions("pupils", {
            vuexCreatePupil: 'createPupil',
            vuexEditPupil: 'editPupil',
        }),
        ...mapActions("parents", ["getParents", "getParent", "createParent", "editParent", "deleteParent"]),
        ...mapActions("parents", {
            vuexCreateParent: 'createParent',
            vuexEditParent: 'editParent',
        }),
        ...mapActions("auth", ["isAccountNameAvailable"]),
        ...mapMutations("snackbar", ["showSnackbar"]),

        clickDownloadVorlage() {
            document.getElementById("downloadVorlageBtn").click();
        },

        async exelUploadInputChange() {
            this.showLoadingOverlay = true;
            const input = this.$refs.exelUploadInput;
            const file = input.files[0];
            input.value = "";
            this.excelImportLogger = [];
            this.excelAmountOfImports = 0;
            this.excelCurrentStatusOfImports = 0;
            if (!file) {
                return;
            }
            if (
                file.type !==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
                this.showSnackbar({
                    message: 'Dateiformat nicht zugelassen. Bitte Excel-Datei auswählen! (".xlsx")',
                    color: 'error'
                });
                return;
            }
            let reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async (e) => {
                let data = new Uint8Array(reader.result);
                let workbook = xlsx.read(data, {type: "array"});

                // Excel accepts "Männlich" as a valid value
                // but the backend only accepts "m" for the male gender
                const mapXlsxGenderToBackendGender = (gender) => {
                    switch (gender) {
                        case "Männlich":
                        case "m":
                            return "m";
                        case "Weiblich":
                        case "w":
                            return "w";
                        default:
                            // changed from Divers to Keine Angabe
                            return "";
                    }
                };

                const existingAccounts = this.allPupils.map((pupil) => this.accountsById[pupil.account]);
                const noSpecialChar = new RegExp(/[^0-9a-zA-Z]+/g);
                // Convert workbook to array of users to import
                const xlsxUsers = xlsx.utils
                    .sheet_to_json(workbook.Sheets["Main"])
                    .map((row) => ({
                        // Get user data
                        accountName: row["Benutzername"],
                        name: row["Vorname"],
                        lastName: row["Nachname"],
                        gender: mapXlsxGenderToBackendGender(row["Geschlecht"]),
                        babyView: row["Einfache Ansicht"] === "Ja",
                        createParent: row["Eltern generieren"] === "Ja",
                        password: row["Passwort"] || generateRandomPassword({passwordLength: 8}),
                        qrPassword: generateRandomPassword({passwordLength: 12}),
                        note: JSON.stringify(row["Notiz"]) || "",
                        rowNumber: row["__rowNum__"],
                    }))
                    .map((user) => {
                        // Validate input data and if already exist on server
                        const checkAccountnameNotEmpty =
                            typeof user.accountName === "string" &&
                            user.accountName.length > 0;
                        const checkAccountnameContainsStrangeLetters =
                            this.containsAccountNameStrangeLetters(user.accountName);
                        const checkNameValid =
                            typeof user.name === "string" && user.name.length > 0;
                        const checkLastNameValid =
                            typeof user.lastName === "string" && user.lastName.length > 0;
                        const checkPasswordValid =
                            typeof user.password &&
                            user.password.length >= 8 &&
                            /\d/.test(user.password) && // Contains number
                            /[A-Za-z]/.test(user.password); // Contains character
                        return {
                            ...user,
                            accountName: user.accountName ? user.accountName.trim() : null,
                            accountNameNotEmpty: checkAccountnameNotEmpty,
                            accountNameContainsStrangeLetters: checkAccountnameContainsStrangeLetters,
                            nameValid: checkNameValid,
                            lastNameValid: checkLastNameValid,
                            passwordValid: checkPasswordValid,
                            alreadyExistsOnServer: existingAccounts.some(
                                (existingAccount) =>
                                    existingAccount.accountName === user.accountName
                            ),
                        };
                    });

                xlsxUsers.forEach((user) => {
                    const rowNumber = parseInt(user.rowNumber + 1);
                    if(!user.nameValid) {
                        this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Der/Die Nutzer/in in Zeile ' + rowNumber + ' hat keinen Vornamen (Pflichtfeld). Der/Die Nutzer/in wurde nicht erstellt.', level: 'error' });
                        user.invalidData = true;
                        return;
                    }
                    if(!user.lastNameValid) {
                        this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Der/Die Nutzer/in in Zeile ' + rowNumber + ' hat keinen Nachnamen (Pflichtfeld). Der/Die Nutzer/in wurde nicht erstellt.', level: 'error' });
                        user.invalidData = true;
                        return;
                    }

                    if(user.accountNameContainsStrangeLetters) {
                        const strangeName = user.accountName;
                        user.accountName = user.accountName.replace(noSpecialChar, "");
                        this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Die Login-ID "' + strangeName + '" beinhaltet Sonderzeichen und wurde durch "' + user.accountName + '" ersetzt. Falls die Login-ID noch nicht existiert (siehe nächste Zeile) wurde der/die Nutzer/in trotzdem erstellt.', level: 'warning' });
                    }

                    if(!user.accountNameNotEmpty) {
                        user.accountName = user.name.toLowerCase() + user.lastName.slice(0, 1).toLowerCase();
                        if(this.containsAccountNameStrangeLetters(user.accountName)) {
                            const strangeName = user.accountName;
                            user.accountName = user.accountName.replace(noSpecialChar, "");
                            if(user.accountName.length > 0) {
                                this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Die automatisch generierte Login-ID "' + strangeName + '" beinhaltet Sonderzeichen und wurde durch "' + user.accountName + '" ersetzt. Der/Die Nutzer/in wurde trotzdem erstellt.', level: 'warning' });
                            } else {
                                this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Aus Vor- und Nachname des/der Nutzer/Nutzerin in Zeile ' + rowNumber + ' konnte kein Login-ID generiert werden. Der/Die Nutzer/in wurde nicht erstellt.', level: 'error' });
                                user.invalidData = true;
                            }
                        }
                    }

                    if(existingAccounts.some(
                        (existingAccount) =>
                            existingAccount.accountName === user.accountName
                    )) {
                        this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Die Login-ID die aus Vor- und Nachname des/der Nutzer/Nutzerin in Zeile ' + rowNumber + ' generiert wurde existiert bereits. Legen sie manuell eine Login-ID fest. Der/Die Nutzer/in wurde nicht erstellt.', level: 'error' });
                        user.invalidData = true;
                    }

                    if(user.alreadyExistsOnServer) {
                        this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Die Login-ID des/der Nutzer/in in Zeile ' + rowNumber + ' existiert bereits. Möglicherweise existiert diese/r Nutzer/in bereits. Der/Die Nutzer/in wurde nicht erstellt.', level: 'error' });
                    }

                    let duplicateAccountNameRowNumbers = [];
                    for(let i=0; i<xlsxUsers.length; i++) {
                        if(xlsxUsers[i].accountName === user.accountName) {
                            duplicateAccountNameRowNumbers.push(parseInt(xlsxUsers[i].rowNumber) + 1);
                        }
                    }
                    if(duplicateAccountNameRowNumbers.length > 1) {
                        if(!user.accountNameNotEmpty) {
                            this.excelImportLogger.push({
                                message: '[Zeile ' + rowNumber + ']: Die aus Vor- und Nachnamen generierte Login-ID ' + user.accountName + ' existiert bereits. Tragen sie die Account-ID bitte manuell ein. Der/Die Nutzer/in wurde nicht erstellt.',
                                level: 'error'
                            });
                            user.invalidData = true;
                        } else {
                            this.excelImportLogger.push({
                                message: '[Zeile ' + rowNumber + ']: Die Login-ID ' + user.accountName + ' kommt mehrfach in der Exceldatei vor (in den Zeilen ' + duplicateAccountNameRowNumbers.toString() + '). Sie muss aber einmalig sein. Alle Nutzer mit der gleichen Account-ID wurden nicht erstellt.',
                                level: 'error'
                            });
                            user.invalidData = true;
                        }
                    }
                })

                const amountOfAllTriedUsers = xlsxUsers.length;

                const usersToImport = xlsxUsers.filter(
                    (user) => !user.alreadyExistsOnServer && !user.invalidData
                );

                const amountOfCreatedUsers = usersToImport.length;
                this.excelAmountOfImports = amountOfCreatedUsers;
                const amountOfFailedUsers = amountOfAllTriedUsers - amountOfCreatedUsers;

                const importResponses = await usersToImport.reduce(
                    async (importedUsersPromise, user) => {
                        const importedUsers = await importedUsersPromise;
                        try {
                            const pupil = await this.vuexCreatePupil(user);

                            let parentData = null;
                            if (user.createParent) {
                                parentData = {
                                    accountName: `${user.accountName.trim()}Eltern`.trim(),
                                    password: generateRandomPassword({passwordLength: 8}),
                                    qrPassword: generateRandomPassword({passwordLength: 12}),
                                    name: `Eltern von ${user.name}`,
                                    rowNumber: user.rowNumber,
                                    pupils: [pupil._id],
                                    gender: '',
                                    phone: ''
                                };
                                const responseParent = await this.vuexCreateParent(parentData);

                                // Parent already exists, do not generate
                                if(responseParent === 409) {
                                    user.parent = null,
                                    parentData = null;
                                }
                            }

                            this.excelCurrentStatusOfImports++;
                            return [
                                ...importedUsers,
                                {
                                    userToImport: user,
                                    parent: parentData,
                                    importedUser: pupil,
                                },
                            ];
                        } catch (error) {
                            console.error(error);

                            return [
                                ...importedUsers,
                                {
                                    userToImport: user,
                                    error,
                                },
                            ];
                        }
                    },
                    Promise.resolve([])
                );

                const usersToGeneratePDF = importResponses.filter(
                    (importResponse) => !importResponse.error
                );
                if(usersToGeneratePDF.length > 0) {
                    let pdfUsers = [];
                    let pdfParents = [];
                    for (const user of usersToGeneratePDF) {
                        const {userToImport} = user;

                        const pdfBytes = await LoginPdf.getPupilLoginPdf({
                            ...userToImport
                        });
                        pdfUsers.push({
                            userToImport, pdfBytes,
                        });

                        // create parent PDF if specified
                        if (user.parent ) {
                            const { parent } = user;
                            console.log(parent);
                            const pdfBytes = await  LoginPdf.getParentLoginPdf({
                                ...parent,
                            });
                            pdfUsers.push({
                                userToImport: parent, pdfBytes,
                            });
                        }
                    }


                    const zip = new JSZip();

                    // https://stackoverflow.com/a/10073788
                    // Add padding to numbers that are too short like 43 => 0043
                    const pad = (n, width, z) => {
                        z = z || "0";
                        n = n + "";
                        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
                    };
                    pdfUsers.forEach((pdfUser) => {
                        const pdfName = pad(pdfUser.userToImport.rowNumber, 3) + "_" + pdfUser.userToImport.accountName + ".pdf";
                        zip.file(pdfName, pdfUser.pdfBytes);
                    });

                    this.excelExtraStatusMessage = 'PDF Dokumente werden bereitgestellt...';
                    const content = await zip.generateAsync({type: "blob"});
                    saveAs(content, "Importierte_Schüler.zip");
                    this.excelExtraStatusMessage = '';
                }

                if(amountOfFailedUsers > 0 && amountOfCreatedUsers > 0) {
                    this.showSnackbar({ message: "Es wurden " + amountOfCreatedUsers + " NutzerInnen erstellt. Die Erstellung von " + amountOfFailedUsers + " NutzerInnen ist Fehlgeschlagen.", color: "orange" });
                } else if(amountOfFailedUsers > 0 && !amountOfCreatedUsers > 0){
                    this.showSnackbar({ message: "Es konnte keine Nutzer erstellt werden.", color: "error" });
                } else {
                    this.showSnackbar({ message: "Es wurden " + amountOfCreatedUsers + " NutzerInnen erstellt.", color: "success" });
                }

                await this.requestPupils();
            };
        },
        clickExelUploadButton() {
            document.getElementById("exelUploadInput").click();
        },
        async createPupil() {
            // create pupil
            let data = {
                name: this.name.trim(),
                lastName: this.lastName.trim(),
                gender: this.gender,
                note: this.note,
                babyView: this.babyView,
                translationAllowed: this.translationAllowed,
                accessibility: {
                    sehen: this.sehen,
                    screenreader: this.screenreader,
                    hoeren: this.hoeren,
                    motorik: this.motorik,
                },
                accountName: this.accountName.trim(),
                password: this.password,
                qrPassword: this.qrPassword,
            };
            return this.vuexCreatePupil(data);
        },
        async modifyPupil() {
            // changed pupil data
            let data = {
                _id: this.id,
                name: this.name,
                lastName: this.lastName,
                babyView: this.babyView,
                translationAllowed: this.translationAllowed,
                accessibility: {
                    sehen: this.sehen,
                    screenreader: this.screenreader,
                    hoeren: this.hoeren,
                    motorik: this.motorik,
                },
                gender: this.gender,
                note: this.note,
            };

            const accountPatchBody = {
                ...(this.isPasswordModified ? { 'password' : this.password } : {}),
                ...(this.isPasswordModified ? { 'qrPassword' : this.qrPassword } : {}),
                note: this.note,
            }
            const accountPatchRes = await backend.patchAccount(this.accountId, accountPatchBody);
            return this.vuexEditPupil(data);

        },
        async createParent(pupilIds) { 
            const parentData = {
                gender: this.parentGender,
                phone: this.parentPhone,
                accountName: this.parentAccountName.trim(),
                password: this.parentPassword,
                qrPassword: this.parentQrPassword,
                pupils: pupilIds,
            };
            return this.vuexCreateParent(parentData);
        },
        async modifyParent(pupilIds) {
            // patch parent
            const parentData = {
                _id: this.parentId,
                gender: this.parentGender,
                phone: this.parentPhone,
                pupils: pupilIds,
            };
            const accountPatchBody = {
                ...(this.isParentPasswordModified ? { 'password' : this.parentPassword } : {}),
                ...(this.isParentPasswordModified ? { 'qrPassword' : this.parentQrPassword } : {}),
            }
            const accountPatchRes = await backend.patchAccount(this.parentAccount, accountPatchBody);
            return this.vuexEditParent(parentData);
        },
        async openPupilSavedOrEditedDialog() {

            try {
                if (!(await this.validateInput())) {
                    this.showSnackbar({message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                    return;
                }

                // The next lines will take some time
                //  => show a loading indicator to the user 
                this.showLoadingOverlay = true;

                // Pupil create/modify
                let pupilResponse;
                if(!this.accountId) {
                    pupilResponse = await this.createPupil()
                } else {
                    pupilResponse = await this.modifyPupil()
                }

                if (Number.isInteger(pupilResponse) && pupilResponse === 409) {
                    this.showSnackbar({
                        message: 'Login-ID von SchÜler bereits vergeben. Bitte geben Sie eine andere Login-ID ein!',
                        color: 'error'
                    });
                    this.showLoadingOverlay = false;
                    return;
                } else if (Number.isInteger(pupilResponse)) {
                    this.showSnackbar({
                        message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                        color: 'error'
                    });
                    this.showLoadingOverlay = false;
                    return;
                }

                // Parent create/modify
                const { _id: pupilId } = pupilResponse; 
                let parentResponse;

                if (this.createNewParentSelected) {
                    parentResponse = await this.createParent([pupilId]); 
                } else if (this.selectedParentId) {
                    const isPupilAlreadyPartOfParent =  this.selectedParent.pupils.find(parentPupilId => parentPupilId === pupilId);
                    const newParentPupils = isPupilAlreadyPartOfParent
                         ? this.selectedParent.pupils
                         : [...this.selectedParent.pupils, pupilId];
                    parentResponse = await this.modifyParent(newParentPupils);
                }

                if (Number.isInteger(parentResponse) && parentResponse === 409) {
                    this.showSnackbar({
                        message: 'Login-ID der Eltern ist bereits vergeben. Bitte geben Sie eine andere Login-ID ein!',
                        color: 'error'
                    });
                    this.showLoadingOverlay = false;
                    return;
                } else if (Number.isInteger(parentResponse)) {
                    this.showSnackbar({
                        message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                        color: 'error'
                    });
                    this.showLoadingOverlay = false;
                    return;
                }

                if(!this.accountId) {
                    // Pupil created with password => show dialog with login pdfs to download
                    this.showAccountCreatedDialog = true;
                } else if (this.isPasswordModified || this.isParentPasswordModified) {
                    // Password modified show dialog with login pdfs to download
                    this.showAccountEditedDialog = true;
                } else {
                    // No password changes, so we show a snackbar instead of a dialog
                    this.showSnackbar({ message: "Änderungen gespeichert!"})
                }


                // Update pupils/parents lists 
                await this.requestPupils();
                await this.requestParents();
                this.showLoadingOverlay = false;
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }


            // if (!this.accountId) {
            //     // when creating a new account
            //     this.createUser();
            //     this.showAccountCreatedDialog = true;
            // } else if (!this.isPasswordModified && !this.isParentPasswordModified && !this.createParent) {
            //     // when changing stuff other than password
            //     await this.patchUser();
            //     this.stopEdit();
            // } else {
            //     // when changing password
            //     this.patchUser();
            //     this.showAccountEditedDialog = true;
            // }
        },
        stopEdit() {
            this.id = null,
            this.name = "";
            this.lastName = "";
            this.accountId = null;
            this.babyView = false;
            this.translationAllowed = false;
            this.sehen = false;
            this.screenreader = false;
            this.hoeren = false;
            this.motorik = false;
            this.gender = "";
            this.note = "";
            this.accountName = "";
            this.password = "";
           
            this.groups = [];

            this.isInvalidUserInput.name = false;
            this.isInvalidUserInput.lastName  = false;
            this.isInvalidUserInput.gender = false;

            this.isInvalidUserInput.accountName = false;
            this.isInvalidUserInput.password = false;
            this.isInvalidUserInput.parent = false;
            this.isInvalidUserInput.parentGender = false;
            this.isInvalidUserInput.parentPhone = false;
            this.isInvalidUserInput.parentPassword = false;
            this.isInvalidUserInput.parentAccountName = false;
            this.isInvalidUserInput.parentAccountNameDuplicate = false;

            this.isPasswordModified = true;
            this.isAccountNameModified = false;
            this.isParentAccountNameModified = true;
            this.isParentPasswordModified = true;

            this.createNewParentSelected = false;
            this.selectedParentId = null;

        },
        async editUser(profile) {
            this.$vuetify.goTo("#edit");
            this.groups = [];

            this.id = profile._id;
            this.name = profile.name;
            this.lastName = profile.lastName;
            this.accountId = profile.account;
            this.gender = profile.gender;
            this.note = profile.note;
            this.babyView = profile.babyView;
            this.accountName = this.accountsById[profile.account].accountName;
            this.translationAllowed = profile.translationAllowed;
            this.password = generateRandomPassword({ length: 8});
            this.insertGroupNames(this.accountsById[profile.account].groups);

            if (profile.accessibility) {
                this.sehen = profile.accessibility.sehen;
                this.screenreader = profile.accessibility.screenreader;
                this.hoeren = profile.accessibility.hoeren;
                this.motorik = profile.accessibility.motorik;
            } else {
                this.sehen = false;
                this.screenreader = false;
                this.hoeren = false;
                this.motorik = false;
            }
            this.isAccountNameModified = false;
            this.isPasswordModified = false;
            this.createNewParentSelected = false;

            const parent = profile.parent 
                ?  await this.getParent(profile.parent) 
                : null;
            if (parent) {
                this.parentGender = parent.gender;
                this.parentPhone = parent.phone;
                this.parentAccountName = this.accountsById[parent.account].accountName;
                this.parentId = parent._id;
                this.parentAccount = parent.account;
                this.parentPassword =  generateRandomPassword({ length: 8});
                this.isParentPasswordModified = false;
                this.isParentAccountNameModified = false;
                
                this.selectedParentId = parent._id;
            } else {
                this.parentGender = "";
                this.parentPhone = "";
                this.parentAccountName = `${this.accountName}Eltern` ;
                this.parentId = "";
                this.parentAccount = null;
                this.parentPassword =  generateRandomPassword({ length: 8});
                this.isParentPasswordModified = false;
                this.isParentAccountNameModified = false;

                this.selectedParentId = null;
            }
        },

        //delete parent of a pupil
        async deletePupilParent() {
            //this method will only be callable when the user has a parent, so this.parentId will be set
            await this.deleteParent(this.parentId);



            this.parentGender = "";
            this.parentPhone = "";
            this.parentPassword = "";
            this.parentAccountName = "";
            this.parentAccount = null;

            this.createNewParentSelected = false;
            this.selectedParentId = null;

            this.showSnackbar({message: 'Gespeichert!'});
            this.setPupilListStatus(true);
            await this.requestPupils();
            await this.requestParents();
        },

        async deleteUser() {
            try {
                this.showLoadingOverlay = true;

                let response = await this.deletePupile(this.id);

                await this.requestPupils();
                await this.requestParents();
                if (response) {
                    this.setPupilListStatus(true);

                    this.stopEdit();
                    this.showSnackbar({message: 'Gespeichert!'});
                } else {
                    this.showSnackbar({
                        message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                        color: 'error'
                    });
                }
                this.showLoadingOverlay = false;
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },
        async kuhR() {
             const pdfBytes = await LoginPdf.getKuhRLoginPdf({
                accountName: this.accountName,
                password: this.password,
                qrPassword:  this.qrPassword,
                name: this.name,
                lastName: this.lastName,
            })
            this.saveByteArray(this.accountName + "-kuhR.pdf", pdfBytes);
        },
        async pdf() {
             const pdfBytes = await LoginPdf.getPupilLoginPdf({
                accountName: this.accountName,
                password: this.password,
                qrPassword:  this.qrPassword,
            })
            this.saveByteArray(this.accountName + ".pdf", pdfBytes);
        },
        async parentPdf() {
            const pdfBytes = await LoginPdf.getParentLoginPdf({
                accountName: this.parentAccountName,
                password: this.parentPassword,
                qrPassword:  this.parentQrPassword,
            })
            this.saveByteArray(this.parentAccountName + ".pdf", pdfBytes);
        },
       
        saveByteArray(reportName, byte) {
            let blob = new Blob([byte], {type: "application/pdf"});
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            let fileName = reportName;
            link.download = fileName;
            link.click();
        },
        async validateInput() {
            let result = true;

            this.isInvalidUserInput.name = false;
            this.isInvalidUserInput.lastName = false;
            this.isInvalidUserInput.gender = false;

            this.isInvalidUserInput.accountName = false;

            //info: parent is not required
            this.isInvalidUserInput.parent = false;
            this.isInvalidUserInput.parentPhone = false;
            this.isInvalidUserInput.parentGender = false;
            this.isInvalidUserInput.parentAccountName = false;
            this.isInvalidUserInput.parentPassword = false;

            this.parentAccountNameDuplicate = false;

            if (this.name.replace(/\s/g, "") === "")  {
                this.isInvalidUserInput.name = true;
                result = false;
            }
            if (this.lastName.replace(/\s/g, "") === "") {
                this.isInvalidUserInput.lastName = true;
                result = false;
            }
            if (
                this.accountName.replace(/\s/g, "") === "" ||
                (!this.isAccountNameFree && !this.accountId) ||
                this.containsAccountNameStrangeLetters(this.accountName) ||
                this.accountName.trim().indexOf(" ") > -1
            ) {
                this.isInvalidUserInput.accountName = true;
                result = false;
            }

            if (this.isPasswordModified) {
                this.isInvalidUserInput.password = false;
                if (!this.checkPw()) {
                    this.isInvalidUserInput.password = true;
                    result = false;
                }
            }

            if (this.parentId) {
                //all fine, skip the next two elses
            } else if (this.createNewParentSelected) {
                if (this.parentPhone !== "") {
                    if (
                        this.parentPhone.replace(/\s/g, "") === "" ||
                        this.containsPhonenumberLetters(this.parentPhone)
                    ) {
                        this.isInvalidUserInput.parentPhone = true;
                        result = false;
                    }
                }
                if (
                    this.parentAccountName.replace(/\s/g, "") === "" ||
                    this.containsAccountNameStrangeLetters(this.parentAccountName)
                ) {
                    this.isInvalidUserInput.parentAccountName = true;
                    result = false;
                }
                //if neither a existing parent is selected nor the checkbox for new parent is checked
            } /*else if (!this.createNewParentSelected && !this.selectedParentId) {

                this.isInvalidUserInput.parent = true;
                result = false;
            }*/

            if (this.createNewParentSelected) {
                let available = await this.isAccountNameAvailable(this.parentAccountName);
                if (!available) {
                    this.isInvalidUserInput.parentAccountNameDuplicate = true;
                    result = false;
                } else {
                    this.isInvalidUserInput.parentAccountNameDuplicate = false;
                }
            }

            if (this.isParentPasswordModified) {
                this.isInvalidUserInput.parentPassword = false;
                if (!this.checkParentPw()) {
                    this.isInvalidUserInput.parentPassword = true;
                    result = false;
                }
            }

            return result;
        },
        containsAccountNameStrangeLetters(accountname) {
            // eslint-disable-next-line no-useless-escape
            const format = /[!@#$äüö%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            return format.test(accountname);
        },
        containsPhonenumberLetters(phonenumber) {
            // eslint-disable-next-line no-useless-escape
            const format = /[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]+/;
            return format.test(phonenumber);
        },

        closeDialogs() {
            this.stopEdit();


            this.showAccountEditedDialog = false;
            this.showAccountCreatedDialog = false;
            this.showExcelDialog = false;
            this.excelAlsoCreateParents = false;
        },

        async requestPupils() {
            this.showLoadingOverlay = true;

            // Create a copy of this.getPupils because vuex arrays shouldnt be modified
            this.allPupils = [...(await this.getPupils())]; 
            this.showLoadingOverlay = false;
            this.setPupilListStatus(false);
        },
        clickGeneratePassword() {
           this.password = generateRandomPassword({passwordLength: 8});
        //    this.showSnackbar({ message: "Neues Passwort generiert. Speichen um Änderungen azuwenden. });
           this.isPasswordModified = true;
           },
        clickGenerateParentPassword(event) {
            this.parentPassword = generateRandomPassword({passwordLength: 8});
            this.isParentPasswordModified = true;
        },

        async insertGroupNames(groups) {
            //TODO: Replace function to not call each group on its own
            for (let i = 0; i < groups.length; i++) {
                this.groups.push(await this.getGroup(groups[i]));
            }
            return this.groups;
        },
        async suggestAccountName(accountId) {
            if (!this.isAccountNameModified && !accountId) {
                let accountNameSuggestion =
                    this.name.toLowerCase() + this.lastName.slice(0, 1).toLowerCase();

                let available = await this.isAccountNameAvailable(accountNameSuggestion);

                if (available) {
                    this.accountName = accountNameSuggestion;
                } else {
                    let i = 1;
                    this.accountName = accountNameSuggestion + i;
                    for (let i = 1; !available; i++) {
                        accountNameSuggestion =
                            this.name.toLowerCase() +
                            this.lastName.slice(0, 1).toLowerCase() +
                            i;
                        available = await this.isAccountNameAvailable(accountNameSuggestion);
                        this.accountName = accountNameSuggestion;
                    }
                }
            }
            await this.validateInput();
        },
        //checks if password is at least of length 8 and contains one letter and one number
        checkPw() {
            const pwCheck = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})");
            return pwCheck.test(this.password);
        },
        checkParentPw() {
            const pwCheck = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})");
            return pwCheck.test(this.parentPassword);
        },

        async requestParents() {
            this.serverParents = await this.getParents();
        },

        setDeleteDialog(subject) {
            switch (subject) {
                case 'pupil':
                    this.deleteDialogTitle = 'Benutzer löschen?';
                    this.deleteDialogText = 'Sind Sie sicher, dass Sie den Benutzer löschen möchten?';
                    this.deleteDialogSubject = subject;
                    this.showDeleteDialog = true;
                    break;
                case 'parent':
                    this.deleteDialogTitle = 'Eltern-Account löschen?';
                    this.deleteDialogText = 'Sind Sie sicher, dass Sie den Eltern-Account löschen möchten?';
                    this.deleteDialogSubject = subject;
                    this.showDeleteDialog = true;
                    break;
                default:

            }
        },

        generateParentAccountName() {
            this.parentAccountName = this.lastName;
        },
    },
};
</script>

<style lang="scss" scoped>
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 10px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table,
tr,
td {
    border: none;
}

@media only screen and (max-width: 700px) {
    .babyViewCheckbox {
        margin-left: 40px
    }
}

.blackText {
    color: rgba(0, 0, 0, 0.87) !important;
}

.input {
    display: block;
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    border-width: 0;
    color: black;
    padding: 1px 5px 1px 5px;
    border: none !important;
    background-color: var(--v-hellgrau-base);
    overflow: hidden;
    appearance: none;
}

.errorInput {
    border: 2px solid red !important;
}

.input[disabled] {
    color: grey;
    background-color: #cacaca;
}

.noDisplay {
    display: none !important;
}

.editContainer {
    min-height: 40vh;
    height: fit-content;
    width: 90%;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto;
    margin-bottom: 5em;
}

.mainControlButtons {
    font-weight: 300;
    font-size: 10px;
    border-radius: 8px;

    & span {
        line-height: 0 !important;
    }
}

.mainFunctionButtons {
    width: 38px !important;
    height: 38px !important;
    border-radius: 8px;

    img {
        width: 27px !important;
        height: 27px !important;
        filter: brightness(1000%);
    }
}

.infoTextToolTip {
    max-width: 250px;
    background-color: var(--v-blau-base);
}

.editPupilButton {
    border-radius: 5px;
    text-transform: inherit;

    img {
        filter: brightness(10%);
        width: 15px;
        margin-right: 5px;
        margin-top: -2px;
    }
}

.generatePasswordButton {
    max-height: 25px;
    max-width: 100%;
    width: 100%;
    text-transform: inherit;
    border-radius: 8px;

    img {
        /*filter: brightness(10%);*/
        width: 15px;
        margin-right: 5px;
        margin-top: -2px;
    }
}

input[type="date"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.6rem;
}

.loggerDiv {
  border: 1px solid grey;
  min-height: 400px;
  max-height: 400px;
  overflow-y: scroll;
}

.loggerLevelerror {
  background-color: red;
  color: white;
}

.loggerLevelwarning {
  background-color: orange;
  color: white;
}

.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
  hue-rotate(36deg) brightness(104%) contrast(105%);
}

.closeIcon {
  height: 20px;
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}
</style>
