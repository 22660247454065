<template>
    <v-dialog
        :value="true"
        fullscreen
        @click:outside="{}"
    >
        <div class="outerContainer">
            <v-btn
                v-if="!forceTutorial"
                class="topControl colorLightBlue"
                style="left: 0"
                @click="endSlideTutorial"
            >
                Tutorial beenden
                <img
                    :src="rightArrow"
                    class="arrowImg lightBlueColorFilterFromGrey"
                    alt=""
                >
            </v-btn>

            <div class="topControl topRightControls">
                <v-btn
                    style="margin-right: 12px; height: 38px"
                    @click="ttsFinishedTalking ? ttsCurrentSlideSubtitle() : callTtsForPause()"
                >
                    <img
                        :src="ttsFinishedTalking ? speaker : speakerOff"
                        :alt="ttsFinishedTalking ? 'Vorlesen' : 'Vorlesen stoppen'"
                        class="lightBlueColorFilterFromBlack"
                    >
                </v-btn>
                <v-select
                    v-model="language"
                    :items="languages"
                    solo
                    dense
                    hide-details
                    @change="translateSubtitles()"
                />
            </div>

            <div class="contentContainer">
                <img
                    :src="content[currentSlideIndex]"
                    class="contentImg"
                    :style="getCorrectContentImgHeight()"
                    alt=""
                >
                <!-- Disable v-html xss checking currentSubtitleHeader is provided only by developers -->
                <!-- eslint-disable vue/no-v-html -->
                <p
                    v-if="subtitleHeadersGerman && subtitleHeadersGerman.length > 0"
                    class="contentSubtitleHeader"
                    v-html="currentSubtitleHeader"
                />
                <!-- Disable v-html xss checking currentSubtitle is provided only by developers -->
                <!-- eslint-disable vue/no-v-html -->
                <p
                    v-if="subtitlesGerman && subtitlesGerman.length > 0"
                    class="contentSubtitle"
                    :style="subtitleHeadersGerman && subtitleHeadersGerman.length > 0 ? '' : 'padding-top: 5vh'"
                    v-html="currentSubtitle"
                />
            </div>

            <v-btn
                v-if="currentSlideIndex >= 1"
                class="bottomControl colorLightBlue"
                style="left: 0"
                @click="previousSlide()"
            >
                <img
                    :src="leftArrow"
                    class="arrowImg lightBlueColorFilterFromGrey"
                    alt=""
                >
                Zurück
            </v-btn>

            <div
                class="bottomControl"
                style="bottom: 1vh; left: 50%"
            >
                <div style="position: relative; left: -50%; display: flex; flex-direction: row;">
                    <div
                        v-for="index in content.length"
                        :key="index"
                        :style="index - 1 === currentSlideIndex || forceTutorial ? '' : 'cursor: pointer'"
                        :class="index - 1 === currentSlideIndex ? 'backgroundColorLightBlue' : ''"
                        class="dot"
                        @click="index - 1 === currentSlideIndex || forceTutorial ? {} : stepWithDots(index - 1)"
                    >
                    </div>
                </div>
            </div>

            <v-btn
                class="bottomControl colorLightBlue"
                style="right: 0"
                @click="nextSlide()"
            >
                {{ currentSlideIndex >= content.length - 1 ? 'Beenden' : 'Weiter' }}
                <img
                    :src="rightArrow"
                    class="arrowImg lightBlueColorFilterFromGrey"
                    alt=""
                >
            </v-btn>
        </div>
    </v-dialog>
</template>

<script>
import leftArrow from '../../assets/Icons/links-filled-10.svg'
import rightArrow from '../../assets/Icons/rechts-filled-11.svg'
import speaker from '../../assets/Icons/lautsprecher-14.svg'
import speakerOff from '../../assets/Icons/lautsprecher-aus-90.svg'

import {textToSpeech} from "@/util/textToSpeech";
import translation from '../../store/modules/translation'
import sanitizeHtml from '../../util/sanitizeHtml'
import {mapActions} from "vuex";

export default {
    name: "SlideTutorial",
    props: {
        content: { required: true, type: Array },
        subtitlesGerman: { required: false, type: Array },
        subtitleHeadersGerman: { required: false, type: Array },
        // If the tutorial can be skipped in any way or not
        forceTutorial: { required: false, type: Boolean, default: true },
    },
    data() {
        return {
            sanitizeHtml,

            currentSlideIndex: 0,
            currentSubtitle: null,
            currentSubtitleHeader: null,
            language: 'Deutsch (German)',
            languages: ['Deutsch (German)', 'English (English)', 'Україна (Ukrainian)', 'France (French)', 'Italia (Italian)', 'Россия (Russian)', 'România (Romanian)', 'Türkiye (Turkish)', '(Arab) الإمارات العربية المتحدة ', 'Kurdî / کوردی (Kurdish)'],
            translatedSubtitles: [],
            ttsFinishedTalking: true,

            rightArrow,
            leftArrow,
            speaker,
            speakerOff,
        }
    },
    mounted() {
        if(this.subtitlesGerman) {
            this.currentSubtitle = this.subtitlesGerman[0];
        }
        if(this.subtitleHeadersGerman) {
            this.currentSubtitleHeader = this.subtitleHeadersGerman[0];
        }
    },
    methods: {
        ...mapActions('translation', ['translateToTargetLang']),

        nextSlide() {
            if(this.currentSlideIndex < this.content.length - 1) {
                this.currentSlideIndex++;
                this.setSlide();
            } else {
                this.endSlideTutorial();
            }
        },
        previousSlide() {
            if(!this.currentSlideIndex <= 0) {
                this.currentSlideIndex--;
                this.setSlide();
            }
        },
        stepWithDots(index) {
            this.currentSlideIndex = index;
            this.setSlide();
        },
        setSlide() {
            if(this.language === 'Deutsch (German)') {
                this.currentSubtitle = this.subtitlesGerman[this.currentSlideIndex];
                this.currentSubtitleHeader = this.subtitleHeadersGerman[this.currentSlideIndex];
            } else {
                const languageShort = this.resolveLangToShort(this.language);
                this.currentSubtitle = this.translatedSubtitles.find((el) => {
                    if(el.language === languageShort) {
                        return el;
                    }
                }).subtitles[this.currentSlideIndex];
                this.currentSubtitleHeader = this.translatedSubtitles.find((el) => {
                    if(el.language === languageShort) {
                        return el;
                    }
                }).subtitleHeaders[this.currentSlideIndex];
            }
            this.ttsCurrentSlideSubtitle();
        },
        endSlideTutorial() {
            this.$emit("close");
        },
        async translateSubtitles() {
            const targetLanguage = this.resolveLangToShort(this.language);
            let indexOfTranslatedSubtitles = this.translatedSubtitles.map((el) => {
                return el.language;
            }).indexOf(targetLanguage);
            if(indexOfTranslatedSubtitles === -1) {
                indexOfTranslatedSubtitles = this.translatedSubtitles.length;
                this.translatedSubtitles[indexOfTranslatedSubtitles] = {
                    language: targetLanguage,
                    subtitles: [],
                    subtitleHeaders: [],
                }
                for(const index of this.subtitlesGerman.keys()) {
                    if(this.subtitlesGerman) {
                        const translatedSubtitle = await translation.actions.translateToTargetLang({
                            commit: null, dispatch: null
                        }, {
                            targetLang: targetLanguage,
                            textToTranslate: this.subtitlesGerman[index],
                            textFormat: 'html',
                        });
                        this.translatedSubtitles[indexOfTranslatedSubtitles].subtitles.push(translatedSubtitle);
                    }
                    if(this.subtitleHeadersGerman) {
                        const translatedSubtitleHeader = await translation.actions.translateToTargetLang({
                            commit: null, dispatch: null
                        }, {
                            targetLang: targetLanguage,
                            textToTranslate: this.subtitleHeadersGerman[index],
                            textFormat: 'html',
                        });
                        this.translatedSubtitles[indexOfTranslatedSubtitles].subtitleHeaders.push(translatedSubtitleHeader);
                    }
                }
            }
        },
        async ttsCurrentSlideSubtitle() {
            const cleanedSubtitle = this.stripHtml(this.currentSubtitle);
            if(!this.ttsFinishedTalking) {
                await textToSpeech(cleanedSubtitle, this.resolveLangToShort(this.language), () => {}, () => {});
            }
            await textToSpeech(cleanedSubtitle, this.resolveLangToShort(this.language), () => {
                this.ttsFinishedTalking = false;
            }, () => {
                this.ttsFinishedTalking = true;
            });
        },
        async callTtsForPause() {
            await textToSpeech(this.currentSubtitle, this.resolveLangToShort(this.language), () => {}, () => {});
        },
        resolveLangToShort(language) {
            switch (language) {
                case 'Deutsch (German)':
                    return 'de';
                case 'English (English)':
                    return 'en';
                case 'Україна (Ukrainian)':
                    return 'uk';
                case 'France (French)':
                    return 'fr';
                case 'Italia (Italian)':
                    return 'it';
                case 'Россия (Russian)':
                    return 'ru';
                case 'România (Romanian)':
                    return 'ro';
                case 'Türkiye (Turkish)':
                    return 'tr';
                case '(Arab) الإمارات العربية المتحدة ':
                    return 'ar';
                case 'Kurdî / کوردی (Kurdish)':
                    return 'ku';
            }
        },
        stripHtml(html)
        {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        },

        getCorrectContentImgHeight() {
            const hasSubtitles = this.subtitlesGerman && this.subtitlesGerman.length > 0;
            const hasSubtitleHeaders = this.subtitleHeadersGerman && this.subtitleHeadersGerman.length > 0;
            if(hasSubtitles && hasSubtitleHeaders) {
                return 'height: 70%';
            } else if(hasSubtitles && !hasSubtitleHeaders) {
                return 'height: 80%';
            } else if(!hasSubtitles && hasSubtitleHeaders) {
                return 'height: 90%';
            } else {
                return 'height: 100%';
            }
        },
    }
}
</script>

<style scoped>
    .colorLightBlue {
        color: #5f8ebf;
    }

    .backgroundColorLightBlue {
        background-color: #5f8ebf !important;
    }

    .lightBlueColorFilterFromGrey {
        /* Set Color to #5f8ebf for img tags */
        filter: invert(0) sepia(1) saturate(2) hue-rotate(170deg);
    }

    .lightBlueColorFilterFromBlack {
        /* Set Color to #5f8ebf for img tags */
        filter: invert(55%) sepia(72%) saturate(296%) hue-rotate(169deg) brightness(84%) contrast(86%);
    }

    .outerContainer {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: white;
        overflow: hidden;
    }

    .contentContainer {
        width: 100%;
        height: 100%;
        padding: 5vh 0;
    }

    .contentImg {
        display: block;
        width: 100%;
        object-fit: contain;
        margin: auto;
    }

    .contentSubtitleHeader {
        width: 100%;
        height: 10%;
        margin: auto;
        font-size: 3rem;
        text-align: center;
    }

    .contentSubtitle {
        width: 100%;
        height: 20%;
        margin: auto;
        padding: 0 48px;
        font-size: 1.5rem;
        text-align: center;
    }

    .arrowImg {
        height: 24px;
    }

    .topControl {
        position: absolute;
        top: 0;
    }

    .topRightControls {
        right: 0;
        display: flex;
        flex-direction: row;
    }

    .bottomControl {
        position: absolute;
        bottom: 0;
    }

    .dot {
        width: 20px;
        height: 20px;
        background-color: #73737350;
        border-radius: 20px;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 0.55rem;
    }

    @media screen and (max-width: 900px) {
        .contentSubtitleHeader {
            font-size: 2rem;
        }

        .contentSubtitle {
            font-size: 1rem;
        }

        .topRightControls {
            top: 38px;
        }
    }
</style>
