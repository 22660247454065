<template>
    <quill-editor
        ref="advancedEditor"
        v-model="internalValue"
        style="font-size: 16px; overflow-y: scroll;"
        :options="editorOption"
        @change="onEditorChanged"
    />
</template>

<script>
export default {
    name: "AdvancedQuillEditor",
    props: {
        value: {
            required: false,
            type: String,
            default: ""
        },
        placeholder: {
            required: false,
            default: ""
        },
        hideImageBtn: {
            required: false,
            default: false
        }
    },
    data: () => ({
        editorOption: {
            placeholder: "",
            modules: {
                toolbar: [
                    [{'size': [false, 'large', 'huge']}],
                    [{'color': []}],
                    ['bold', 'italic', 'underline'],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    ['link', 'image', 'video'],
                ],
            },
            theme: "snow"
        },
        internalValue: "",
    }),
    watch: {
        value(val) {
            this.internalValue = val;
        }
    },
    mounted() {
        if(this.hideImageBtn){
            // Hide the button via css, since quill does not support dynamic changes.. :(
            document.getElementsByClassName('ql-image')[0].style.display = "none";
        }
        this.internalValue = this.value;
        // Set the placeholder
        this.$refs.advancedEditor.quill.root.dataset.placeholder = this.placeholder;

        // Register on text-change events to add link previews
        this.$refs.advancedEditor.quill.on('text-change', this.onEditorTextChanged);

        // Find the 'add video' button and center it - sometimes its off screen
        let els = document.getElementsByClassName("ql-video");
        if (els.length > 0) {
            els[0].onclick = () => {
                // Move the URL dialog to the center since its always offset by default
                let dialog = document.getElementsByClassName("ql-tooltip ql-editing");
                if (dialog.length > 0) {
                    dialog[0].style.left = 0;
                }
            };
        }
    },
    methods: {
        onEditorChanged(val) {
            // Trigger the input event for the v-model to work
            this.$emit('input', val.html);
        },
        onEditorTextChanged(delta, deltaOld, source) {
            // Only youtube auto preview is supported => Check if its youtube
            if (delta.ops && source === "user") {
                let url = "";
                // If the text box is empty
                if (delta.ops.length > 0 && delta.ops[0].insert &&
                    (delta.ops[0].insert.toString().startsWith("https://www.youtu") || delta.ops[0].insert.toString().startsWith("https://youtu"))) {
                    url = delta.ops[0].insert;
                } else if (delta.ops.length > 1 && delta.ops[1].insert &&
                    (delta.ops[1].insert.toString().startsWith("https://www.youtu") || delta.ops[1].insert.toString().startsWith("https://youtu"))) {
                    // If the text box is not empty
                    url = delta.ops[1].insert;
                }
                if (url.trim().length > 0) {
                    let quill = this.$refs.advancedEditor.quill;
                    let range = quill.getSelection(true);
                    this.$refs.advancedEditor.quill.insertText(range.index, '\n', "user");
                    // The link must be formatted as an youtube-embedded link
                    if (url.includes("watch?v="))    // If the browser url was used
                        url = url.replace("watch?v=", "embed/");
                    else if (!url.includes("embed") && url.toString().startsWith("https://youtu.be/"))   // If the url was copied from the video
                        url = url.replace("https://youtu.be/", "https://www.youtube-nocookie.com/embed/")
                    this.$refs.advancedEditor.quill.insertEmbed(range.index + 1, 'video', url, "user");
                }

            }
        }
    }

}
</script>

<style>
.ql-container .ql-editor {
    min-height: 120px;
}
</style>
