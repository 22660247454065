<template>
    <div>
        <!-- teacher View -->
        <div
            id="calculationWallOuter"
            ref="calculationWallOuter"
            class="calculationWallOuter"
            @click="$emit('focusMe')"
        >
            <v-container
                id="calculationWallInner"
                ref="calculationWallInner"
                fluid
                class="pa-0"
            >
                <!-- Zahlenraum Bereich -->
                <div
                    v-if="showTeacherUI"
                    class="greyDiv editorOptionsFade"
                    style="display: flex; justify-content: space-between; margin-bottom: 8px; font-size: xx-large"
                >
                    <span
                        style="cursor: pointer"
                        class="ml-2 editorColorPrimary"
                        :class="range === 10 ? 'controlsDisabled' : ''"
                        @click="reduceRange"
                    >&lt;</span>
                    <span>{{ '0 - ' + range }}</span>
                    <span
                        style="cursor: pointer"
                        class="mr-2 editorColorPrimary"
                        :class="range === 1000 ? 'controlsDisabled' : ''"
                        @click="increaseRange"
                    >&gt;</span>
                </div>
                <div class="mt-2 mx-auto">
                    <span class="visually-hidden">{{ selectedOperator === '+' ? 'Rechenmauer Addition:' : 'Rechenmauer Multiplikation:' }}</span>
                    <img
                        v-if="showPupilUI"
                        :src="selectedOperator === '+' ? addition : multiplikation"
                        aria-hidden="true"
                        style="position: absolute; height: 62px; top: 24px; left: 16px"
                    >
                    <div class="greyDivBorderColor">
                        <div
                            style="display: flex; flex-direction: column-reverse; padding: 34px 34px 32px 32px; margin: 8px"
                            :class="showTeacherUI ? 'editorGreyDivFade greyDiv' : ''"
                        >
                            <div
                                v-for="index in wallTree.length"
                                :key="index"
                            >
                                <div
                                    style="display: flex; justify-content: center; margin-top: -3px"
                                    :class="currentFontSize"
                                >
                                    <div
                                        v-for="index2 in wallTree.length - index + 1"
                                        :key="index2"
                                        style="display: flex; flex-direction: column"
                                    >
                                        <div
                                            class=""
                                            :class="!((accountRole === 'teacher' && state === 'teacher') || state === 'viewer') ? (wallTree[wallTree.length - index][index2 - 1].hidden ? 'hiddenForPupil' : '') : wallTree[wallTree.length - index][index2 - 1].hidden && !(wallTree[wallTree.length - index][index2 - 1].number === parseInt(wallTree[wallTree.length - index][index2 - 1].solution)) ? (showSolution ? 'editorColorPrimaryBackground' : 'editorColorWrongBackground') : ''"
                                            style="margin: 0px -1.5px; width: 75px; min-height: 50px; border-style: solid; border-width: 3px; text-align: center; cursor: default; overflow: auto; border-radius: 8px"
                                            :style="sizeState === 0 ? 'width: 75px' : sizeState === 1 ? 'width: 90px' : 'width: 105px'"
                                            @click="accountRole === 'teacher' && wallTree[wallTree.length - index][index2 - 1].hidden && !(wallTree[wallTree.length - index][index2 - 1].number === parseInt(wallTree[wallTree.length - index][index2 - 1].solution)) ? showSolution = !showSolution : {}"
                                        >
                                            <!-- Teacher View -->
                                            <div
                                                v-if="showTeacherUI"
                                                style="height: 100%;"
                                                :style="sizeState === 0 ? '' : sizeState === 1 ? 'min-height: 48px' : 'min-height: 57px'"
                                            >
                                                <!--                                            <v-tooltip v-if="index === wallTree.length && wallTree[wallTree.length - index][index2 - 1].number > range" v-model="resultOutOfRange" top class="editorOptionsFade">-->
                                                <!--                                                <template v-slot:activator="{  }">-->
                                                <!--                                                    <span :style="wallTree[wallTree.length - index][index2 - 1].hidden ? 'color: #777777' : ''">{{ wallTree[wallTree.length - index][index2 - 1].number }}</span>-->
                                                <!--                                                </template>-->
                                                <!--                                                <span style="font-size: x-small">Ergebnis außerhalb des Zahlenraums</span>-->
                                                <!--                                            </v-tooltip>-->
                                                <span
                                                    v-if="index !== 1"
                                                    style="line-height: 2.5"
                                                    :style="wallTree[wallTree.length - index][index2 - 1].hidden ? 'color: #777777' : ''"
                                                >{{ wallTree[wallTree.length - index][index2 - 1].number }}</span>
                                                <input
                                                    v-else
                                                    v-model="wallTree[wallTree.length - index][index2 - 1].number"
                                                    type="text"
                                                    style="height: 100%; border: none; text-align: center"
                                                    :style="sizeState === 0 ? 'width: 69px; max-width: 69px' : sizeState === 1 ? 'width: 84px; max-width: 84px' : 'width: 99px; max-width: 99px'"
                                                    @keyup="recalculate(); saveState()"
                                                >
                                            </div>

                                            <!-- Pupil View -->
                                            <div v-else-if="showPupilUI">
                                                <span v-if="!wallTree[wallTree.length - index][index2 - 1].hidden">{{ wallTree[wallTree.length - index][index2 - 1].number }}</span>
                                                <input
                                                    v-else
                                                    v-model="wallTree[wallTree.length - index][index2 - 1].solution"
                                                    type="text"
                                                    style="width: 69px; max-width: 69px; height: 44px; max-height: 44px; border: none; text-align: center"
                                                    :aria-label="getAriaLabel(wallTree.length - index, index2 - 1)"
                                                    @keydown="saveState"
                                                >
                                            </div>

                                            <!-- Correction View -->
                                            <div v-else-if="(accountRole === 'teacher' && state === 'teacher') || state === 'viewer'">
                                                <span v-if="!wallTree[wallTree.length - index][index2 - 1].hidden">{{ wallTree[wallTree.length - index][index2 - 1].number }}</span>
                                                <div v-else>
                                                    <span
                                                        v-if="wallTree[wallTree.length - index][index2 - 1].number === parseInt(wallTree[wallTree.length - index][index2 - 1].solution)"
                                                        class="editorColorPrimary"
                                                    >{{ wallTree[wallTree.length - index][index2 - 1].solution }}</span>
                                                    <v-tooltip
                                                        v-else
                                                        top
                                                        :disabled="!elementOnCurrentPage || accountRole === 'pupil'"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <span
                                                                v-if="wallTree[wallTree.length - index][index2 - 1].solution && wallTree[wallTree.length - index][index2 - 1].solution !== ''"
                                                                style="color: white"
                                                                v-on="on"
                                                            >{{ showSolution ? wallTree[wallTree.length - index][index2 - 1].number : wallTree[wallTree.length - index][index2 - 1].solution }}</span>
                                                            <span
                                                                v-else
                                                                style="color: white"
                                                                v-on="on"
                                                            >{{ showSolution ? wallTree[wallTree.length - index][index2 - 1].number : '-' }}</span>
                                                        </template>
                                                        <span style="color: white">{{ showSolution ? 'Lösung verstecken' : 'Lösung anzeigen' }}</span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Alle Controls des Lehrers -->
                    <div
                        v-if="showTeacherUI"
                        class="mt-2 editorOptionsFade"
                    >
                        <div style="display: flex; flex-direction: row">
                            <v-btn
                                class="editorColorPrimaryBackgroundOnHover greyDiv"
                                depressed
                                color="#ededed"
                                style="flex-grow: 1; height: 48px; min-height: 48px; cursor: pointer"
                                @click="addRow"
                            >
                                <span
                                    class="editorColorPrimary editorColorPrimaryOnHoverChildColorToWhite"
                                    style="font-size: xx-large"
                                >+</span>
                            </v-btn>
                            <v-btn
                                :style="wallTree.length > 3 ? '' : 'cursor: default; background-color: var(--v-editorGrey-base) !important'"
                                class="editorColorWrongBackgroundOnHover greyDiv"
                                depressed
                                color="#ededed"
                                style="height: 48px; min-height: 48px; cursor: pointer; width: 48px; min-width: 48px; margin-left: 4px"
                                @click="removeRow"
                            >
                                <span
                                    :class="wallTree.length > 3 ? 'editorColorWrongOnHoverChildColorToWhite editorColorWrong' : 'controlsDisabled'"
                                    style="font-size: xx-large"
                                >-</span>
                            </v-btn>
                        </div>
                        <!-- Controls rechts rausgeschoben -->
                        <div class="editorOptionsFade">
                            <div style="display: flex; flex-direction: column; position: absolute; right: -56px; top: 60px; width: 48px; height: 48px; min-width: 48px">
                                <v-tooltip
                                    right
                                    :disabled="!elementOnCurrentPage"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="editorColorPrimaryBackgroundOnHover editorComponentOptionsBtn"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="changeSize()"
                                        >
                                            <img
                                                :src="currentSizeIcon"
                                                style="height: 32px"
                                                class="iconToWhiteHover"
                                                alt="Größe des Elements verändern"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Größe ändern</span>
                                </v-tooltip>
                                <v-tooltip
                                    right
                                    :disabled="!elementOnCurrentPage"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="editorComponentOptionsBtn"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="randomizeInput"
                                        >
                                            <img
                                                style="height: 17px"
                                                :src="aktualisierenIcon"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Zahlen ändern</span>
                                </v-tooltip>
                                <v-tooltip
                                    right
                                    :disabled="!elementOnCurrentPage"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="editorComponentOptionsBtn"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="hideNumbers"
                                        >
                                            <img
                                                style="height: 40px"
                                                :src="hideMode === 'all' ? wallHiddenBase : wallHiddenRandom"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Zahlen ausblenden</span>
                                </v-tooltip>
                                <v-tooltip
                                    right
                                    :disabled="!elementOnCurrentPage"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="editorComponentOptionsBtn"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="switchOperator"
                                        >
                                            <span
                                                class="editorColorPrimary"
                                                style="font-size: xx-large"
                                                :style="selectedOperator === '*' ? 'margin-top: 15px' : ''"
                                            >{{ selectedOperator }}</span>
                                        </v-btn>
                                    </template>
                                    <span>Rechenoperator ändern</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import aktualisierenIcon from "../../../../assets/Icons/aktualisieren-green-107.svg";
import sizeSmallIcon from "@/assets/Icons/EditorRedesign/schrift_klein.svg";
import sizeMediumIcon from "@/assets/Icons/EditorRedesign/schrift_mittel.svg";
import sizeLargeIcon from "@/assets/Icons/EditorRedesign/schrift_groß.svg";
import wallHiddenBase from "@/assets/Icons/EditorRedesign/wallHiddenBase.svg";
import addition from "@/assets/Icons/EditorRedesign/addition.svg";
import multiplikation from "@/assets/Icons/EditorRedesign/multiplikation.svg";
import wallHiddenRandom from "@/assets/Icons/EditorRedesign/wallHiddenRandom.svg";

export default {
    name: 'EditorTextMessage',
    props: {
        config: { type: Object, required: true },
        state: { type: String, required: true },
        targetLang: { type: String, required: false, default: 'de' },
        translate: { type: Boolean, required: false, default: false },
        tabIndex: { type: Number, required: false, default: 0 },
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        elementOnCurrentPage: { type: Boolean, required: false },
        //toggle to switch ui to pupil for export procedure
        exportMode: { type: Boolean, required: false, default: false },
        previewPupilUI: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            wallTree: [
                [{number: 5, hidden: false, solution: null}],
                [{number: 2, hidden: false, solution: null},{number: 3, hidden: false, solution: null}],
                [{number: 1, hidden: false, solution: null},{number: 1, hidden: false, solution: null},{number: 2, hidden: false, solution: null}],
            ],
            calculationOperators: ['+','*'],
            selectedOperator: '+',
            range: 10,
            randomRange: [10, 100, 1000],
            sizeState: 0,
            hideMode: 'all',
            showSolution: false,

            aktualisierenIcon,
            sizeSmallIcon,
            sizeMediumIcon,
            sizeLargeIcon,
            wallHiddenBase,
            wallHiddenRandom,
            addition,
            multiplikation,
        };
    },
    computed: {
        ...mapGetters("auth",["accountRole"]),

        currentFontSize() {
            return this.sizeState ? (this.sizeState === 2 ? 'editorFontSize3' : 'editorFontSize2') : 'editorFontSize1';
        },

        currentSizeIcon() {
            return this.sizeState ? (this.sizeState === 2 ? this.sizeLargeIcon : this.sizeMediumIcon) : this.sizeSmallIcon;
        },
        showPupilUI() {
            return this.exportMode || this.state === 'pupil' || this.previewPupilUI;
        },
        showTeacherUI() {
            return !this.exportMode && !this.previewPupilUI && this.state === 'creator';
        },
    },
    watch: {
        sizeState: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        previewPupilUI: {
            handler(val, oldVal) {
                for (let index = this.wallTree.length - 1; index >= 0; index--) {
                    for (let index2 = this.wallTree[index].length - 1; index2 >= 0; index2--) {
                        delete this.wallTree[index][index2].solution;
                    }
                }
            }
        }
    },
    mounted() {
        // Initialisiere Rechenmauer

        if(this.config.initialize) {
            // console.log('initialize before: ', this.config);
            delete this.config.initialize;
            this.config.identifier = uuidv4();
            this.wallTree = this.config.wallConfig.wallTree;
            this.selectedOperator = this.config.wallConfig.selectedOperator;
            this.sizeState = this.config.wallConfig.sizeState;
            // console.log('initialize after: ', this.config);
        } else if (!this.config.wallConfig) {
            this.config.wallConfig = {};
            this.config.identifier = uuidv4();
            this.config.wallConfig.wallTree = this.wallTree;
            this.config.wallConfig.selectedOperator = this.selectedOperator;
            this.config.wallConfig.sizeState = this.sizeState;
        } else {
            if(this.config._id) {
                this.config.identifier = this.config._id;
            }
            this.wallTree = this.config.wallConfig.wallTree;
            this.selectedOperator = this.config.wallConfig.selectedOperator;
            this.sizeState = this.config.wallConfig.sizeState;
        }
    },
    methods: {
        ...mapActions('pupils', [ 'getMePupil' ]),
        getAriaLabel(index, index2) {
            if(index === this.wallTree.length - 2) {
               return this.wallTree[index + 1][index2].number + ' ' + this.selectedOperator + ' ' + this.wallTree[index + 1][index2 + 1].number + ' ='
            } else {
                // ._.
                // Verzweigter check ob vorherige Zahlen beide versteckt sind nur eine von beiden und ob man bereits die aktuelle Zahl ausfüllen kann
                if(this.wallTree[index + 1][index2].hidden && this.wallTree[index + 1][index2].solution && this.wallTree[index + 1][index2 + 1].hidden && this.wallTree[index + 1][index2 + 1].solution) {
                    return this.wallTree[index + 1][index2].solution + ' ' + this.selectedOperator + ' ' + this.wallTree[index + 1][index2 + 1].solution + ' ='
                } else if(!this.wallTree[index + 1][index2].hidden && this.wallTree[index + 1][index2 + 1].solution) {
                    return this.wallTree[index + 1][index2].number + ' ' + this.selectedOperator + ' ' + this.wallTree[index + 1][index2 + 1].solution + ' ='
                } else if(this.wallTree[index + 1][index2].solution && !this.wallTree[index + 1][index2 + 1].hidden) {
                    return this.wallTree[index + 1][index2].solution + ' ' + this.selectedOperator + ' ' + this.wallTree[index + 1][index2 + 1].number + ' ='
                } else {
                    return 'Zuerst die vorherigen Ergebnisse eintragen';
                }
            }
        },

        hideNumbers() {
            if(this.hideMode === 'all') {
                this.hideMode = 'random';

                // Clear im ganzen wallTree den hidden status
                for (let index = this.wallTree.length - 2; index >= 0; index--) {
                    for (let index2 = this.wallTree[index].length - 1; index2 >= 0; index2--) {
                        this.$set(this.wallTree[index], index2, {number: this.wallTree[index][index2].number, hidden: false})
                    }
                }

                // Zufällige Auswahl (60:40) welche Nummern des wallTree's versteckt werden sollen
                for (let index = 0; index <= this.wallTree.length - 1; index++) {
                    for (let index2 = 0; index2 <= this.wallTree[index].length - 1; index2++) {
                        // Oberere Reihen
                        if(index < this.wallTree.length - 1) {
                            this.$set(this.wallTree[index], index2, {number: this.wallTree[index][index2].number, hidden: this.getRandomBooleanTrue6040False()})
                        }
                        // Unterste Reihe
                        // Für die unterste Reihe ist ein Algorithmus nötig, der nur so viel ausblendet, dass die Rechenmauer noch berechenbar ist
                        // Bedeutet: Nummer darf nur ausgeblendet werden wenn einer der direkten Nachbar und der Nachfolger der beiden Nachfolger (Nummer oberhalb)
                        // nicht auch ausgeblendet sind (Achtung: In den Ecken gibt es nur einen direkten Nachbarn)
                        else {
                            // Unterste Reihe ganz links
                            if(index2 === 0) {
                                if(this.wallTree[index - 1][0].hidden || this.wallTree[index][index2 + 1].hidden) {
                                    this.$set(this.wallTree[index], index2, {number: this.wallTree[index][index2].number, hidden: false})
                                } else {
                                    this.$set(this.wallTree[index], index2, {number: this.wallTree[index][index2].number, hidden: this.getRandomBooleanTrue6040False()})
                                }
                            }
                            // Unterste Reihe ganz rechts
                            else if(index2 === this.wallTree.length - 1) {
                                if(this.wallTree[index - 1][this.wallTree[index - 1].length - 1].hidden || this.wallTree[index][index2 - 1].hidden) {
                                    this.$set(this.wallTree[index], index2, {number: this.wallTree[index][index2].number, hidden: false})
                                } else {
                                    this.$set(this.wallTree[index], index2, {number: this.wallTree[index][index2].number, hidden: this.getRandomBooleanTrue6040False()})
                                }
                            }
                            // Unterste Reihe gesamte Mitte
                            else {
                                if((!(this.wallTree[index][index2 - 1].hidden || this.wallTree[index - 1][index2 - 1].hidden)) && (!(this.wallTree[index][index2 + 1].hidden || this.wallTree[index - 1][index2].hidden))) {
                                    this.$set(this.wallTree[index], index2, {number: this.wallTree[index][index2].number, hidden: this.getRandomBooleanTrue6040False()})
                                } else {
                                    this.$set(this.wallTree[index], index2, {number: this.wallTree[index][index2].number, hidden: false})
                                }
                            }
                        }
                    }
                }
            } else if(this.hideMode === 'random') {
                this.hideMode = 'all';

                // Clear im ganzen wallTree den hidden status
                for (let index = this.wallTree.length - 1; index >= 0; index--) {
                    for (let index2 = this.wallTree[index].length - 1; index2 >= 0; index2--) {
                        this.$set(this.wallTree[index], index2, {number: this.wallTree[index][index2].number, hidden: false})
                    }
                }

                // Verstecke alle Nummern ausser die unterste Reihe
                for (let index = this.wallTree.length - 2; index >= 0; index--) {
                    for (let index2 = this.wallTree[index].length - 1; index2 >= 0; index2--) {
                        this.$set(this.wallTree[index], index2, {number: this.wallTree[index][index2].number, hidden: true})
                    }
                }
            }
        },

        changeSize() {
            switch (this.sizeState) {
                case 0:
                    this.sizeState = 1;
                    break;
                case 1:
                    this.sizeState = 2;
                    break;
                case 2:
                    this.sizeState = 0;
                    break;
                default:
                    break;
            }
        },

        increaseRange() {
            if(this.range !== 1000) {
                this.range = this.randomRange[this.randomRange.indexOf(this.range) + 1];
                this.randomizeInput();
            }
        },

        reduceRange() {
            if(this.range !== 10) {
                this.range = this.randomRange[this.randomRange.indexOf(this.range) - 1];
                this.randomizeInput();
            }
        },

        switchOperator() {
            let index = this.calculationOperators.indexOf(this.selectedOperator);
            if(index === this.calculationOperators.length - 1) {
                this.selectedOperator = this.calculationOperators[0];
            } else {
                this.selectedOperator = this.calculationOperators[index + 1];
            }
            this.randomizeInput();
        },
        addRow() {
            if(this.wallTree.length < 10) {
                for (let i = 0; i < this.wallTree.length; i++) {
                    let object = {
                        number: this.getRandomIntInclusive(0, this.range),
                        hidden: false,
                    }
                    this.wallTree[i].push(object);
                }
                let object = {
                    number: 0,
                    hidden: false,
                }
                this.wallTree.unshift([object]);
                this.randomizeInput();
            }
        },

        removeRow() {
            if(this.wallTree.length > 3) {
                this.wallTree.shift();
                for (let i = 0; i < this.wallTree.length; i++) {
                    this.wallTree[i].pop();
                }
            }
            this.recalculate();
        },

        // Berechnet den gesamte wallTree anhand der untersten Zahlen neu
        recalculate() {
            for (let index = this.wallTree.length - 1; index >= 0; index--) {
                for (let index2 = this.wallTree[index].length - 1; index2 >= 0; index2--) {
                    if (!(index === this.wallTree.length - 1)) {
                        let result = 0;
                        switch (this.selectedOperator) {
                            case "+":
                                result = parseInt(this.wallTree[index + 1][index2].number) + parseInt(this.wallTree[index + 1][index2 + 1].number);
                                break;
                            case "*":
                                result = this.wallTree[index + 1][index2].number * this.wallTree[index + 1][index2 + 1].number;
                                break;
                        }
                        if (isNaN(result)) {
                            result = '';
                        }

                        this.$set(this.wallTree[index], index2, {number: result, hidden: this.wallTree[index][index2].hidden});
                    }
                }
            }
            this.saveState();
        },

        randomizeInput() {
            // console.log('randomizeInput() from ', this.config.identifier);
            let firstIteration = true;
            let lastRandomNumbers = [];
            let lastRandomNumber;
            // Generiere und Berechne so lange die unterste Reihe neu, bis das Ergebnis in der range liegt
            while (this.wallTree[0][0].number > this.range || firstIteration) {
                // Iteriere durch die unterste Reihe
                for (let i = 0; i < this.wallTree[this.wallTree.length - 1].length; i++) {
                    // Beim ersten Mal wird Zufallszahl zwischen 0 bis range (bei Addition) und 1 - range (bei Multiplikation) generiert
                    if(firstIteration) {
                        if(this.selectedOperator === '*') {
                            lastRandomNumber = this.getRandomIntInclusive(1, this.range);
                        } else {
                            lastRandomNumber = this.getRandomIntInclusive(0, this.range);
                        }
                    }
                    // Bei weiteren Iterationen waren die letzten Zufallszahlen zu hoch und es wird eine Zufallszahl zwischen der 0/1 und
                    // der letzten Zufallszahl generiert -> Zahlen werden so lange kleiner generiert bis das Ergebnis in der range liegt
                    else {
                        if(this.selectedOperator === '*') {
                            lastRandomNumber = this.getRandomIntInclusive(1, lastRandomNumbers[i]);
                        } else {
                            lastRandomNumber = this.getRandomIntInclusive(0, lastRandomNumbers[i]);
                        }
                    }
                    let object = {
                        number: lastRandomNumber, hidden: false,
                    }
                    // Vue is not reactive on Arrays ._.
                    this.$set(this.wallTree[this.wallTree.length - 1], i, object);
                    this.$set(lastRandomNumbers, i, lastRandomNumber);
                }
                this.recalculate();
                firstIteration = false;
            }
        },

        getRandomIntInclusive(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min +1)) + min;
        },

        getRandomBooleanTrue6040False() {
            const randomInt = this.getRandomIntInclusive(0, 10);
            // Wahrscheinlichkeit für true liegt bei 60%
            return randomInt <= 6;
        },

        saveState() {
            // save values by overwriting the calcConfig properties
            // which get saved in the db schoolSubjectAppointmentUploadMessage
            if(!this.previewPupilUI) {
                this.config.wallConfig.wallTree = this.wallTree;
                this.config.wallConfig.selectedOperator = this.selectedOperator;
                this.config.wallConfig.sizeState = this.sizeState;
            }
        },
    }
}
</script>

<style scoped lang="scss">
    .hiddenForPupil {
      background-color: var(--v-fgrau-base);
    }

    .wallGapBetweenFade {
      margin: 0px -1.5px;
      transition: 0.2s ease-out 0s;
    }
    #elementOuter:hover {
      & .wallGapBetweenFade {
        margin: 4px 4px;
        transition: 0.2s ease-out 0s;
      }
    }
</style>
