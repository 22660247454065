<template>
    <v-snackbar
        v-model="snackbarVisible"
        :timeout="snackbarTimeout"
        :color="snackbarColor"
    >
        <template
            v-if="snackbarClosable"
            v-slot:action="{ attrs }"
        >
            <v-btn
                text
                v-bind="attrs"
                small
                @click="clickCloseSnackbar"
            >
                <img
                    style="max-width: 20px"
                    class="iconToWhite"
                    alt="Schließen"
                    :src="closeIcon"
                >
            </v-btn>
        </template>
        {{ snackbarMessage }}
    </v-snackbar>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import closeIcon from "../../assets/Icons/abbrechen-08.svg";

export default {
  name: "Snackbar",
  components: {},
  data: () => ({
    closeIcon,
    snackbarVisible: false,
  }),
  computed: {
    ...mapState("snackbar", [
      "snackbarMessage",
      "snackbarTimeout",
      "snackbarMessageNumber",
      "snackbarColor",
      "snackbarClosable",
    ]),
  },

  watch: {
    snackbarMessageNumber() {
      // Set first to false then true to reset timeout
      this.snackbarVisible = true;
    },
  },
  methods: {
    clickCloseSnackbar() {
      this.snackbarVisible = false;
    },
  },
};
</script>
<style scoped>
.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}
</style>
