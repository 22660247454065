<template>
    <div
        id="sideBarContainer"
        class="d-flex flex-column justify-space-between"
        style="z-index: 1"
    >
        <div class="d-flex flex-column">
            <div class="e-row">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            id="plusZoomButton"
                            aria-label="Vergrößern"
                            aria-hidden="true"
                            elevation="3"
                            x-small
                            class="mr-3 sidebarButton"
                            :style="windowWidth < 360 ? 'height: 40px !important; width: 40px !important; margin-bottom: 9px !important' : ''"
                            v-on="on"
                            @click="changeZoom(1)"
                        >
                            <p>+</p>
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span>Vergrößern</span>
                    </div>
                </v-tooltip>
            </div>
            <div class="e-row">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            aria-label="Verkleinern"
                            aria-hidden="true"
                            tabindex="0"
                            elevation="3"
                            x-small
                            class="mr-3 sidebarButton"
                            :style="windowWidth < 360 ? 'height: 40px !important; width: 40px !important; margin-bottom: 9px !important' : ''"
                            v-on="on"
                            @click="changeZoom(-1)"
                        >
                            <p>-</p>
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span>Verkleinern</span>
                    </div>
                </v-tooltip>
            </div>
            <div
                v-if="currentZoom > 1.0"
                class="e-row"
            >
                <p
                    class="pr-3 mb-0"
                    style="text-align: center"
                >
                    {{ `${(currentZoom * 100).toFixed(0)}%` }}
                </p>
            </div>

            <v-divider
                v-if="!isViewerOpen"
                aria-hidden="true"
                class="my-2 mr-3 ml-1"
            />
        </div>

        <div
            class="d-flex flex-column justify-start mb-10"
        >
            <!-- Interactive Elements -->
            <EditorInteractiveElementsList
                v-if="displayTools"
                :preview-active="previewActive"
                @opened="$emit('opened')"
                @elementSelected="elementSelected"
                @close="showInteractiveElements = false"
            />
        </div>

        <div style="min-height: 50px !important;">
            <!-- Activate Toolbar button  -->
            <v-tooltip
                v-if="isToolbarToggleVisible"
                right
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        aria-label="Toolbar Aktivieren"
                        aria-hidden="true"
                        tabindex="-1"
                        elevation="3"
                        x-small
                        class="mr-3 my-1 sidebarButton"
                        :style="`${localToolbarToggle ? `background-color: ${currentColor}` : ''}`"
                        :disabled="previewActive"
                        v-on="on"
                        @click="toggleToolbar"
                    >
                        <img
                            height="35px"
                            :src="stiftIcon"
                            style="padding: 2px"
                            :style="`${localToolbarToggle ? 'filter: brightness(1000%)' : ''}`"
                        >
                    </v-btn>
                </template>
                <span v-if="localToolbarToggle">Toolbar deaktivieren</span>
                <span v-else>Toolbar aktivieren</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import stiftIcon from '@/assets/Icons/bearbeiten-komplimentär-grau-88.svg';
import EditorInteractiveElementsList from "@/components/Editortools/Toolbar/EditorInteractiveElementsList";

export default {
    name: "EditorSideBar",
    components: {EditorInteractiveElementsList, /*MemoMenu, MediaMenu, FormMenu, TaskMenu*/ },
    props: {
        currentPageIndex: { required: true, type: Number },
        mode: { required: true, type: String },
        elementToEdit: { required: false, type: Object, default: null },
        previewActive: { required: false, type: Boolean, default: false },
        isToolbarToggleVisible: { required: true, type: Boolean, default: true },
        parentToolbarState: { required: false, type: Boolean, default: false },
        currentColor: { required: false, type: String, default: '#706F6F' },
        currentZoom: { required: false, type: Number, default: 1.0 },
        pageCount: { required: true, type: Number },
        windowWidth: { required: true, type: Number },
        simpleEditorForTeachers: { required: false, type: Boolean, default: false },
    },
    data() {
        return {
            stiftIcon,

            // Menu Toggles
            mediaMenu: false,
            taskMenu: false,
            localToolbarToggle: false,
            watcherForMenus: 0,

            showInteractiveElements: false,
        }
    },
    computed: {
        isViewerOpen() {
            return this.mode === 'viewer';
        },
        displayTools() {
          return (this.mode === 'creator' || this.mode === 'teacher') && !this.simpleEditorForTeachers;
        },
    },
    watch: {
        elementToEdit(newVal) {

            if (newVal && newVal.element) {
                if (newVal.element.messageType === 'multipleChoice' || newVal.element.messageType === 'moveableText') {
                    this.taskMenu = true;
                } else if (newVal.element.messageType === 'imageObject' || newVal.element.messageType === 'videoObject') {
                    this.mediaMenu = true;
                }

                this.$nextTick(() => this.watcherForMenus += 1);
            }
        },
        parentToolbarState(newVal, oldVal) {
            this.localToolbarToggle = newVal;
        }
    },
    methods: {
        changeZoom(factor) {
            if (factor > 0)
                this.$emit('zoomIn');
            if (factor < 0)
                this.$emit('zoomOut');
        },
        elementSelected(options) {
            const type = options.type;
            delete options.type;

            this.$emit('addElement', type, options);
        },
        toggleToolbar() {
            this.localToolbarToggle = !this.localToolbarToggle;
            this.$emit('toggleToolbar', this.localToolbarToggle);
        },
    }
}
</script>
<style scoped lang="scss">
#sideBarContainer {
    position: fixed;
    left: 10px;
    bottom: 15px;
    z-index: 4 !important;
    height: calc(var(--vh, 1vh) * 87.5);
    pointer-events: none;
}

.e-row {
    margin: 4px 0px 4px 0px;
}

#pageCount {
    font-weight: bold;
    font-size: larger;
    margin-bottom: 0;
    text-align: center;
}

.sidebarButton {
    z-index: 3;
    width: 49px !important;
    height: 49px !important;
    border-radius: 8px;
    pointer-events: all;

    p {
        line-height: 0;
        font-size: 55px;
        margin-bottom: 0;
        font-weight: 300;
        color: #333;
    }
}

.sidebarButtonActive {
    background-color: var(--v-orange-base) !important;

    img {
        filter: brightness(1000%);
    }
}

#interactiveElementsActivator {
    z-index: 3;
    height: 150px !important;

    img {
        filter: brightness(1000%);
    }
}
</style>
