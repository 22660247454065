<template>
    <div style="height: auto">
        <v-tooltip
            v-if="notificationsActivated"
            :disabled="!showTooltips"
            bottom
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    v-if="big"
                    class="mr-2 pa-0"
                    style="text-transform: inherit; height: 100%"
                    block
                    elevation="0"
                    v-on="on"
                    @click="deactivateNotifications"
                >
                    <div
                        class="d-flex align-center"
                        style="width: 100%"
                    >
                        <v-icon
                            class="mx-2"
                            style="font-size: 20px"
                        >
                            mdi-bell
                        </v-icon>
                        <p class="mr-auto">
                            Benachrichtigungen
                        </p>
                    </div>
                </v-btn>
                <v-btn
                    v-else
                    id="bell"
                    class="mr-2"
                    color="white"
                    icon
                    x-small
                    v-on="on"
                    @click="deactivateNotifications"
                >
                    <v-icon>mdi-bell</v-icon>
                </v-btn>
            </template>
            <span>Browserbenachrichtigungen deaktivieren</span>
        </v-tooltip>
        <v-tooltip
            v-else
            :disabled="!showTooltips"
            bottom
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    v-if="big"
                    class="mr-2 pa-0"
                    style="text-transform: inherit; height: 100%"
                    block
                    elevation="0"
                    v-on="on"
                    @click="activateNotifications"
                >
                    <div
                        class="d-flex align-center"
                        style="width: 100%"
                    >
                        <v-icon
                            class="mx-2"
                            style="font-size: 20px"
                        >
                            mdi-bell-off
                        </v-icon>
                        <p class="mr-auto">
                            Benachrichtigungen
                        </p>
                    </div>
                </v-btn>
                <v-btn
                    v-else
                    id="bell"
                    class="mr-2"
                    color="white"
                    icon
                    x-small
                    v-on="on"
                    @click="activateNotifications"
                >
                    <v-icon>mdi-bell-off</v-icon>
                </v-btn>
            </template>
            <span>Browserbenachrichtigungen aktivieren</span>
        </v-tooltip>

        <ActivateBrowserNotificationsDialog
            v-if="showActivateBrowserNotificationsDialog"
            @close="showActivateBrowserNotificationsDialog = false"
        />
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ActivateBrowserNotificationsDialog from "./ActivateBrowserNotificationsDialog";
import * as BackendApi from "../../../../api/backend";

export default {
  name: "ToggleChatNotificationButton",
  components: { ActivateBrowserNotificationsDialog },
  props: {
      big: { requried: false, default: false }
  },
  data: () => ({
    showActivateBrowserNotificationsDialog: false,
    devices: [],
  }),
  computed: {
    ...mapState("auth", ["deviceToken", "account"]),
    ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
    mappedDevices() {
      return this.devices.map((device) => ({
        ...device,
        isThisDevice: device.token === this.deviceToken,
        lastLoginPretty: new Date(device.lastLoginTime).toLocaleTimeString(),
      }));
    },
    thisDevice() {
      return this.mappedDevices.find((device) => device.isThisDevice) || {};
    },
    notificationsActivated() {
      // console.log(this.thisDevice);
      return this.thisDevice.subscription && Notification.permission === "granted";
    },
  },
  async mounted() {
    this.loadDevices();
  },
  methods: {
    ...mapActions("matrix", ["deleteMatrixRoom"]),
    ...mapMutations("snackbar", ["showSnackbar"]),
    async loadDevices() {
      const devicesResponse = await BackendApi.getDevices(this.account._id);
      this.devices = await devicesResponse.json();
    },
    activateNotifications() {
      this.subscribeUser();
    },
    deactivateNotifications() {
      this.unsubscribeUser();
    },
    openActivateBrowserNotificationsDialog(event) {
      this.showActivateBrowserNotificationsDialog = true;
    },
    async unsubscribeUser() {
      await BackendApi.unsubscribe({
        deviceToken: this.deviceToken,
      });
      this.loadDevices();
      this.showSnackbar({
        message: `Browserbenachrichtigungen deaktiviert`,
      });
    },
    async subscribeUser() {
      // urlB64ToUint8Array is a magic function that will encode the base64 public key
      // to Array buffer which is needed by the subscription option
      const urlB64ToUint8Array = (base64String) => {
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding)
          // eslint-disable-next-line no-useless-escape
          .replace(/\-/g, "+")
          .replace(/_/g, "/");
        const rawData = atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
      };

      const publicVapidKeyResponse = await BackendApi.getPublicVapidKey();
      const publicVapidKey = await publicVapidKeyResponse.json();
      // console.log("publicVapidKey", publicVapidKey);

      // console.log("serviceWorker in navigator", "serviceWorker" in navigator);

      if (!("serviceWorker" in navigator)) {
        this.showSnackbar({
          message:
            "Browserbenachrichtigungen werden auf diesem Gerät nicht unterstützt.",
          color: "Error",
        });
        return;
      }

      const serviceWorkerRegistrationExists = await navigator.serviceWorker.getRegistration();
      if (!serviceWorkerRegistrationExists) {
        this.showSnackbar({
          message:
            "Browserbenachrichtigungen können noch nicht aktiviert werden. Vielleicht hilft es die Seite neuzuladen.",
          color: "Error",
        });
        return;
      }

      const serviceWorkerRegistration = await navigator.serviceWorker.ready;
      if (!serviceWorkerRegistration.pushManager) {
        this.showSnackbar({
          message:
            "Benachrichtigungen werden auf diesem Browser nicht unterstützt. I.d.R. sind davon iOS Geräte und Safari von macOS betroffen.",
          color: "Error",
        });
        return;
      }

      try {
        const subscription =
          await serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(publicVapidKey),
          });

        await BackendApi.subscribe({
          subscription,
          deviceToken: this.deviceToken,
        });
        this.loadDevices();
        // console.log("Endpoint URL: ", subscription.endpoint);
        this.showSnackbar({
            message: `Browserbenachrichtigungen aktiviert`,
        });
      } catch (err) {
        if (Notification.permission === "denied") {
          this.showSnackbar({
            message: `Dein Browser blockiert das Aktivieren von Benachrichtigungen. Erlaube in deinen Browsereinstellungen das Empfangen von Benachrichtigungen`,
            color: "Error",
          });
          console.warn("Permission for notifications was denied", err);
        } else {
          this.showSnackbar({
            message: `Beim Aktivieren der Browserbenachrichtigungen ist ein unerwarteter Fehler aufgetreten. `,
            color: "Error",
          });
          console.error("Unable to subscribe to push", err);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
