<template>
    <div>
        <v-overlay
            v-if="supportPageOpen"
            absolute
            style="z-index: 99"
            overlay-opacity="0.8"
            @click.native="setSupportPageOpen(false)"
        />
        <Header style="position: fixed; width: 100%; z-index: 98;" />
        <keep-alive>
            <router-view style="width: calc(100% - 56px); margin-left: 56px; margin-top: calc(102px + 32px)" />
        </keep-alive>
        <ContractVerwaltung v-if="showDialogGDPR" />
        <SupportChat
            v-if="supportPageOpen"
            @resetTutorial="goToTutorial()"
        />
        <VideoTutorial
            v-if="videoTutorial && !videoTutorialClosed"
            :video-tutorial="videoTutorial"
            @close="videoTutorialClosed = true"
        />
    </div>
</template>

<script>
import Header from "../components/Verwaltung/Header";
import VideoTutorial from "../components/Tutorial/VideoTutorial.vue";
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import ContractVerwaltung from '../components/Verwaltung/ContractVerwaltung.vue';
import SupportChat from "@/components/Support/SupportChat";
import * as backend from "../api/backend";

export default {
    name: 'Main',
    components: {
        Header,
        VideoTutorial,
        ContractVerwaltung,
        SupportChat,
    },
    data: () => ({
        videoTutorial: false,
        videoTutorialClosed: false,
    }),
    computed: {
        ...mapGetters('videoTutorial', ['videoTutorialsByFilename']),
        ...mapGetters("maintainer", ["showDialogGDPR"]),
        ...mapGetters("support",["supportPageOpen"]),
        ...mapState('faq', ['faq']),
    },
    watch: {
        faq() {
            this.goToFaq();
        }
    },
    async mounted() {
        this.videoTutorial = this.videoTutorialsByFilename['Verwaltung_15Min_erklaert.mp4'];
    },
    methods: {
        ...mapMutations("maintainer", ["setShowDialogGDPR"]),
        ...mapMutations("support", ["setSupportPageOpen"]),

        goToTutorial() {
            this.$router.push('/verwaltung/tutorial');
        },

        goToFaq() {
            this.$router.push('/verwaltung/faq');
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
