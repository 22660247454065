<template>
    <v-menu
        v-model="vMenu"
        offset-y
        nudge-left="130px"
        nudge-bottom="20"
        :close-on-content-click="false"
        :attach="'#editorStaticElementBarColumn'"
    >
        <template v-slot:activator="{ on: onMenu, attrs }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                        v-if="mode === 'creator' || mode === 'teacher' || mode === 'pupil'"
                        class="squareBtn"
                        icon
                        aria-hidden="true"
                        v-bind="attrs"
                        @click="() => { menu = 'basic'; $emit('opened') }"
                        v-on="{ ...onMenu, ...onTooltip }"
                    >
                        <img
                            :src="plusIcon"
                            class="iconToWhite"
                            alt="Menü öffnen"
                        >
                    </v-btn>
                </template>
                <span>Medien und Formen</span>
            </v-tooltip>
        </template>

        <!-- component selection -->
        <div
            v-if="menu === 'basic'"
            class="menuBg"
        >
            <div class="d-flex pa-4">
                <div>
                    <div>
                        <p class="white--text">
                            Medien
                        </p>
                    </div>
                    <div class="vl mr-2">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                    x-small
                                    class="squareBtn mr-2"
                                    @click="addImage"
                                    v-on="tooltip"
                                >
                                    <img
                                        :src="bildIcon"
                                        alt="Bild hinzufügen"
                                    >
                                </v-btn>
                            </template>

                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span>Bild hinzufügen</span>
                            </div>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                    x-small
                                    class="squareBtn mr-2"
                                    @click="addVideo"
                                    v-on="tooltip"
                                >
                                    <img
                                        :src="videoIcon"
                                        alt="Video hinzufügen"
                                    >
                                </v-btn>
                            </template>

                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span>Video hinzufügen</span>
                            </div>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                    x-small
                                    class="squareBtn mr-2"
                                    @click="addAudio"
                                    v-on="tooltip"
                                >
                                    <img
                                        :src="audioIcon"
                                        alt="Audio hinzufügen"
                                    >
                                </v-btn>
                            </template>

                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span>Audio hinzufügen</span>
                            </div>
                        </v-tooltip>
                    </div>

                    <!-- surprise inputs that will help us later -->
                    <div>
                        <input
                            ref="imgUpload"
                            type="file"
                            style="display: none"
                            hidden
                            name="file"
                            accept="image/*"
                            @change="imgSelected"
                        >
                        <input
                            ref="videoUpload"
                            type="file"
                            style="display: none"
                            hidden
                            name="file"
                            accept="video/mp4,video/mpeg,video/mov,video/*"
                            @change="videoSelected"
                        >
                        <input
                            ref="audioUpload"
                            type="file"
                            style="display: none"
                            hidden
                            name="file"
                            accept="audio/m4a,.m4a,audio/mpeg,audio/ogg,audio/mp3,audio/*"
                            @change="audioSelected"
                        >
                    </div>
                </div>

                <div>
                    <div>
                        <p class="white--text">
                            Formen
                        </p>
                    </div>
                    <div>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                    x-small
                                    class="squareBtn mr-2"
                                    @click="clickedAddRect"
                                    v-on="tooltip"
                                >
                                    <img
                                        :src="rechteckIcon"
                                        alt="Rechteck hinzufügen"
                                    >
                                </v-btn>
                            </template>

                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span>Rechteck hinzufügen</span>
                            </div>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                    x-small
                                    class="squareBtn"
                                    @click="clickedAddLine"
                                    v-on="tooltip"
                                >
                                    <img
                                        :src="linieIcon"
                                        alt="Linie hinzufügen"
                                    >
                                </v-btn>
                            </template>

                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span>Linie hinzufügen</span>
                            </div>
                        </v-tooltip>
                    </div>
                </div>

                <!-- probably only in right sidebar -->
                <!--                <div>
                    <div>
                        <p class="white&#45;&#45;text">Notizen</p>
                    </div>
                    <div>
                        <v-btn x-small class="squareBtn mr-2">
                            <img :src="plusIcon" alt="Bild hinzufügen">
                        </v-btn>
                        <v-btn x-small class="squareBtn">
                            <img :src="plusIcon" alt="Bild hinzufügen">
                        </v-btn>
                    </div>
                </div>-->
            </div>
        </div>

        <!-- rectangle options -->
        <div
            v-if="menu === 'rect' || menu === 'line'"
            class="menuBg"
        >
            <div class="d-flex pa-4">
                <div>
                    <div>
                        <p class="white--text">
                            Farbauswahl
                        </p>
                    </div>

                    <div>
                        <v-color-picker
                            id="cPicker"
                            v-model="selectedColor"
                            class="ma-2 menuBg"
                            :swatches="swatches"
                            hide-mode-switch
                            hide-canvas
                            hide-inputs
                            show-swatches
                        />
                    </div>

                    <div
                        v-if="menu === 'rect'"
                        class="d-flex flex-column"
                    >
                        <span class="white--text">Mit Rahmen</span>
                        <v-checkbox
                            v-model="rectangleBorderState"
                            hide-details
                            dark
                            class="ma-0"
                        />
                    </div>

                    <div class="d-flex justify-space-between">
                        <v-btn
                            color="error"
                            class="text-capitalize ml-1"
                            @click="back"
                        >
                            Zurück
                        </v-btn>
                        <v-btn
                            color="success"
                            class="text-capitalize"
                            @click="spawnForm"
                        >
                            Hinzufügen
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-menu>
</template>

<script>
import plusIcon from "@/assets/Icons/hinzufuegen-07.svg";
import audioIcon from '@/assets/Icons/EditorRedesign/audio.svg';
import bildIcon from '@/assets/Icons/EditorRedesign/bild.svg';
import videoIcon from '@/assets/Icons/EditorRedesign/video.svg';
import linieIcon from '@/assets/Icons/EditorRedesign/linie.svg';
import rechteckIcon from '@/assets/Icons/EditorRedesign/rechteck.svg';

export default {
    name: "EditorStaticElementBar",
    props: {
        mode: { required: true, type: String },
    },
    data: () => ({
        plusIcon,
        audioIcon,
        bildIcon,
        videoIcon,
        linieIcon,
        rechteckIcon,

        vMenu: false,
        menu: '', // basic = shows components, rest shows the respective options for that component e.g. rect(angle)

        selectedItem: '',
        selectedColor: '#FFFFFF',
        swatches: [
            ['#FFFFFF', '#b2b2b2', '#707070'],
            ['#000000', '#1b5f99', '#9bcdc3'],
            ['#fcd20a', '#f0b423', '#ff6941'],
            ['#91c364', '#91c887', '#d7875f'],
            ['#9b91c8', '#ebaabe', '#6ec3dc'],
        ],
        rectangleBorderState: true,
    }),
    methods: {
        // general methods
        back() {
            this.menu = 'basic';
            this.selectedColor = '#FFFFFF';
            this.rectangleBorderState = true;
        },

        // FormMenu
        clickedAddRect() {
            this.menu = 'rect';
            this.selectedItem = 'rect';
        },
        clickedAddLine() {
            this.menu = 'line';
            this.selectedItem = 'line';
        },
        spawnForm() {
            if (this.selectedItem === 'rect') {
                this.spawnRectangle();
            } else if (this.selectedItem === 'line') {
                this.spawnLine();
            }
        },
        spawnRectangle() {
            this.$emit('addRectangle', this.selectedColor, (this.rectangleBorderState ? '1px solid black': ''));
            this.selectedColor = '#FFFFFF';
            this.vMenu = false;
        },
        spawnLine() {
            this.$emit('addLine', this.selectedColor);
            this.selectedColor = '#FFFFFF';
            this.vMenu = false;
        },
        addImage() {
            this.$refs.imgUpload.click();
            this.vMenu = false;
        },
        addVideo() {
            this.$refs.videoUpload.click();
            this.vMenu = false;
        },
        addAudio() {
            this.$refs.audioUpload.click();
            this.vMenu = false;
        },
        imgSelected() {
            if(this.$refs.imgUpload.files.length > 0) {
                let blob = this.$refs.imgUpload.files[0];
                let url = window.URL.createObjectURL(blob);
                this.$emit('addImageField', url, blob);
                this.$refs.imgUpload.value = "";
            }
        },
        videoSelected() {
            if(this.$refs.videoUpload.files.length > 0){
                let blob = this.$refs.videoUpload.files[0];
                let url = window.URL.createObjectURL(blob);
                this.$emit('addVideoField', url, blob);
                this.$refs.videoUpload.value = "";
            }
        },
        audioSelected() {
            if(this.$refs.audioUpload.files.length > 0){
                let blob = this.$refs.audioUpload.files[0];
                let url = window.URL.createObjectURL(blob);
                this.$emit('addAudioField', url, blob);
                this.$refs.audioUpload.value = "";
            }
        },
    },
}
</script>

<style scoped lang="scss">
.menuBg {
    background-color: rgba(100, 100, 100, 0.8)
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.squareBtn {
    width: 49px;
    height: 49px;
    min-width: 49px;
    min-height: 49px;
    border-radius: 8px;

    img {
        width: 35px;
        height: 35px;
    }
}

.vl {
    border-right: 2px solid white;
    border-radius: 1px;
    height: 49px;
}

#cPicker {
    .v-color-picker__controls {
        .v-color-picker__preview--hide-alpha {
            .v-color-picker__dot {
                border-radius: 0% !important;
            }

            .v-color-picker__dot {
                border: 1px solid gray;
                -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
                box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
            }

            .v-color-picker__sliders {
                display: none !important;
            }
        }
    }
}
</style>
