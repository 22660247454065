<template>
    <div style="padding-right: 3px; padding-left: -3px">
        <progress-bar
            title="Hochladen"
            :show-progress="showPupilUploadProgress"
            :progress="pupilUploadProgress"
            :abort-progress="pupilXmlHttpRequest ? () => { pupilXmlHttpRequest.abort() }: () => {}"
        />

        <NameFile
            v-model="showNameFile"
            :original-name="uploadFileName"
            :callback="fileNameCallback"
        />

        <v-dialog
            v-if="thisAppointment"
            id="menuPopup"
            v-model="menu"
            :max-width="windowWidth >= 2560 ? 800 : 400"
            scrollable
            class="dialog"
            overlay-opacity="0.8"
            @click:outside="resetDialogContent()"
        >
            <template v-slot:activator="{ attrs }">
                <!--fach with text and image-->
                <div
                    class="fach pa-2 white--text"
                    style="width: 90%; display: block; padding-left: 0 !important;"
                >
                    <v-row
                        cols="12"
                        class="ml-2 font-weight-medium"
                        justify="center"
                    >
                        <v-col
                            :cols="isRunning ? 9 : 12"
                            class="pa-0 ma-0"
                        >
                            <v-badge
                                color="red"
                                :content="thisAppointment.teacherUploads.filter((el) => !el.seenByMe && (!('canPip' in el) || el.canPip)).length"
                                :value="thisAppointment.teacherUploads.filter((el) => !el.seenByMe && (!('canPip' in el) || el.canPip)).length"
                                overlap
                                left
                                offset-x="5"
                                style="z-index: 8; width: 100%; height: 48px !important;"
                            >
                                <v-tooltip
                                    :value="shortcutTooltips"
                                    :disabled="!showTooltips || !isRunning"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <div
                                            :id="planVollbild ? appointment._id + '-fullscreen' : appointment._id"
                                            class="pa-3 ma-0 appointmentItem"
                                            v-bind="attrs"
                                            :class="{ inactiveAppointment: !isRunning }"
                                            :style="isRunning ? 'width: calc(100% - 3px) !important' : ''"
                                            :tabindex="planVollbild ? tabOrder : '0'"
                                            aria-haspopup="dialog"
                                            role="button"
                                            @keypress.enter="openFachDialog"
                                            @keypress.space="openFachDialog"
                                            @keydown.down="keyArrowDown()"
                                            @keydown.up="keyArrowUp()"
                                            @keydown.left="keyArrowLeft()"
                                            @keydown.right="keyArrowRight()"
                                            @click="openFachDialog()"
                                            v-on="on"
                                        >
                                            <img
                                                v-if="getSrc()"
                                                class="iconSelect"
                                                :src="isRunning ? getSrcWhite() : getSrc()"
                                                :style="isRunning ? getBackgroundColor() : 'background-color: transparent'"
                                                alt=""
                                            >

                                            <div
                                                v-else
                                                class="iconSelect"
                                            />
                                            <p
                                                class="mb-0 ml-1"
                                                style="display: inline-block"
                                            >
                                                {{ thisAppointment.schoolSubject.name }}
                                            </p>
                                            <!--bookmark-->
                                            <img
                                                :src="getColorIcon()"
                                                alt=""
                                                style="position: absolute; height: 25px; top: 0; right: 10px;"
                                            >
                                        </div>
                                        <!--paperclip-->
                                        <img
                                            v-if="thisAppointment.teacherUploads.length > 0"
                                            alt="Büroklammer"
                                            :src="paperclipIcon"
                                            :style="isRunning ? 'right: 30px' : 'right: 27px'"
                                            style="opacity: 60%; position: absolute; height: 21px; top: -9px;"
                                        >
                                    </template>
                                    <span>Aktuelle <span style="text-decoration: underline white">S</span>tunde (Alt + S)</span>
                                </v-tooltip>
                            </v-badge>
                        </v-col>

                        <!--Kamera-Icon neben Fach-->
                        <v-col
                            v-if="isRunning"
                            :cols="3"
                            class="pa-0 mr-0"
                            style="min-height: 100%"
                            @click="clickBeitreten"
                        >
                            <v-tooltip
                                :value="shortcutTooltips"
                                :disabled="!showTooltips"
                                bottom
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        :id="planVollbild ? 'enterBBB-fullscreen' : 'enterBBB'"
                                        :tabindex="planVollbild ? tabOrder : '0'"
                                        class="pa-0"
                                        small
                                        :class="{ isFachRunning: isRunning }"
                                        style="height: 48px; width: 100%; background-color: white; border-radius: 8px;"
                                        v-on="on"
                                        @keydown.down="keyArrowDown(true)"
                                        @keydown.up="keyArrowUp(true)"
                                        @keydown.left="keyArrowLeft(true)"
                                        @keydown.right="keyArrowRight(true)"
                                    >
                                        <img
                                            alt="Stunde beitreten"
                                            :src="beitretenKameraIcon"
                                            class="pa-0"
                                            :class="{ isFachRunningIcon: isRunning }"
                                            style="height: 35px !important; width: 35px !important;"
                                        >
                                    </v-btn>
                                </template>
                                <div style="display: flex; flex-direction: column; text-align: center">
                                    <span>Stunde <span style="text-decoration: underline white">b</span>eitreten (Alt + B)</span>
                                    <img
                                        v-if="hoeren"
                                        :src="beitretenMetacom"
                                        width="100"
                                        style="margin: auto"
                                        alt=""
                                    >
                                </div>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </div>
            </template>

            <!--menu that opens on click on fach in table-->
            <v-card
                role="dialog"
                tile
                style="color: var(--v-dunkelgrau-base);"
            >
                <v-card-title
                    class="pa-0 ma-0"
                    style="z-index: 1"
                >
                    <div
                        class="fach d-flex"
                        style="box-shadow: 0 5px 5px 0 rgba(0,0,0,0.3); font-size: large; align-items: center; padding: 0 20px; height: 59px; width: 100%;"
                    >
                        <v-col
                            cols="6"
                            class="d-flex align-center pl-0"
                        >
                            <img
                                alt=""
                                :src="getColorIcon()"
                                style="position: absolute; right: 20%; top: 0; height: 25px;"
                            >
                            <img
                                v-if="getSrc()"
                                alt=""
                                class="iconSelect"
                                :src="getSrcWhite()"
                                style="margin-bottom: 0"
                                :style="getBackgroundColor()"
                            >
                            <div class="pl-2">
                                <p
                                    :id="'fachName-' + thisAppointment._id"
                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                    class="mb-0"
                                >
                                    {{ thisAppointment.schoolSubject.name }}
                                </p>
                            </div>
                        </v-col>

                        <v-col
                            cols="4"
                            class="pa-0 pr-6 d-flex flex-column justify-start align-end"
                            :style="`font-size: ${windowWidth < 400 ? 'small': 'medium'}`"
                        >
                            <!--TODO add start and end time of lesson-->
                            <!--<div></div>-->
                        </v-col>

                        <v-col
                            cols="2"
                            align-self="end"
                            :class="windowWidth >= 2560 ? 'd-flex flex-row-reverse' : ''"
                        >
                            <v-btn
                                :tabindex="planVollbild ? tabOrderPopup + 1 : '3'"
                                elevation="2"
                                x-small
                                class="ml-2 pa-0 btnStyle"
                                style="width: 30px; height: 30px"
                                @click="menu = false; resetDialogContent(); setFocusBack();"
                            >
                                <img
                                    alt="Schließen"
                                    :src="schliesenIcon"
                                    style="height: 20px;"
                                >
                            </v-btn>
                        </v-col>
                    </div>
                </v-card-title>

                <v-card-text
                    class="pa-0 ma-0"
                    style="overflow-y: scroll; overflow-x: hidden"
                >
                    <!--                    <vue-custom-scrollbar class="scroll-area-fachPopup mb-2" :settings="settings">-->
                    <!-- Info area -->
                    <div class="mx-5">
                        <div class="mt-2">
                            <v-row>
                                <v-col class="font-weight-bold py-2 d-flex align-center">
                                    <img
                                        :src="infoIcon"
                                        style="height: 20px;"
                                        alt=""
                                    >
                                    <p
                                        :tabindex="planVollbild ? tabOrderPopup : '1'"
                                        style="width: fit-content; float: right; font-size: medium"
                                        class="ml-2 ma-0"
                                    >
                                        Info
                                    </p>
                                </v-col>
                                <v-col class="text-right py-1">
                                    <v-btn
                                        :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                        :tabindex="planVollbild ? tabOrderPopup : '1'"
                                        elevation="0"
                                        x-small
                                        class="btnStyle"
                                        @click="greet($event)"
                                    >
                                        <img
                                            alt="Vorlesen"
                                            :src="lautsprecherIcon"
                                            style="height: 20px;"
                                        >
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <!-- Disable vue/no-v-html because appointment note is sanitized on backend-->
                            <!-- eslint-disable vue/no-v-html -->
                            <p
                                v-if="thisAppointment.note"
                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                class="ql-editor mb-0"
                                style="overflow-y: auto; font-size: medium"
                                v-html="sanitizeHtml(urlify(thisAppointment.note))"
                            />
                            <!-- eslint-enable vue/no-v-html -->
                            <div
                                v-else
                                class="pb-1"
                            >
                                <p
                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                    class="mb-0"
                                >
                                    Bitte bereitet schonmal eure Unterlagen vor! Und ladet gegebenfalls vorhandene
                                    Materialien herunter
                                </p>
                            </div>
                        </div>
                    </div>

                    <hr
                        aria-hidden="true"
                        class="eHr"
                    >

                    <!-- Material area -->
                    <div class="mx-0 mb-4">
                        <div class="mt-2">
                            <v-row>
                                <v-col class="font-weight-bold py-2 mx-5 pt-0 d-flex align-center justify-space-between">
                                    <div class="d-flex">
                                        <img
                                            :src="materialIcon"
                                            style="height: 20px;"
                                            alt=""
                                        >
                                        <p
                                            :tabindex="planVollbild ? tabOrderPopup : '1'"
                                            style="width: fit-content; float: right; font-size: medium"
                                            class="ml-2 ma-0"
                                        >
                                            Material
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                            <div
                                v-if="thisAppointment.teacherUploads.filter(item => !wasMovedByMe(item)).length === 0"
                                class="mx-5 pb-1"
                                style="font-size: medium;"
                            >
                                <p
                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                    class="mb-0"
                                >
                                    Bis jetzt wurden zu diesem Fach noch keine
                                    Materialien hochgeladen.
                                </p>
                            </div>

                            <!--up to two items if collapsed-->
                            <div class="matShort">
                                <div
                                    v-for="(upload,index) in thisAppointment.teacherUploads.filter(item => !wasMovedByMe(item) && !item.editParent && (!('canPip' in item) || item.canPip)).slice(0, 2)"
                                    :key="upload + index"
                                    :class="{ brighterFileRow: index % 2 === 1}"
                                    style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%;"
                                >
                                    <div
                                        style="display: flex; flex-flow: column; width: 100%;"
                                        class="py-2 px-5 d-flex justify-space-between align-center"
                                    >
                                        <div
                                            style="width: 100%; background-color: rgba(112,112,112, 0.15); border-top-left-radius: 6px; border-top-right-radius: 6px;"
                                            class="py-2 px-5 d-flex justify-space-between align-center"
                                        >
                                            <div
                                                class="fileNameDiv"
                                                style="min-width: 63%; width: 63%"
                                            >
                                                <p
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    class="fileName mb-0"
                                                >
                                                    {{ upload.title }}
                                                </p>
                                            </div>
                                            <div style="display: flex; flex-wrap: nowrap">
                                                <v-badge
                                                    color="red"
                                                    content="1"
                                                    :value="!upload.seenByMe"
                                                    overlap
                                                    offset-x="15"
                                                    style="z-index: 9; display: flex"
                                                >
                                                    <v-tooltip
                                                        v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(upload.title.split('.').pop().toLowerCase())"
                                                        :disabled="!showTooltips"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                dark
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                :loading="uploadLoadings[index]"
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                style="background-color: #8CBD46;"
                                                                v-on="on"
                                                                @click="() => {play(upload, index); menu = false}"
                                                            >
                                                                <img
                                                                    :src="rechtsIcon"
                                                                    style="height: 20px; filter: brightness(1000%)"
                                                                    alt="Video abspielen"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Video abspielen</span>
                                                    </v-tooltip>

                                                    <v-tooltip
                                                        v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(upload.title.split('.').pop().toLowerCase())"
                                                        :disabled="!showTooltips"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                dark
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                :loading="uploadLoadings[index]"
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                style="background-color: #8CBD46;"
                                                                v-on="on"
                                                                @click="() => {playAudio(upload, index); menu = false}"
                                                            >
                                                                <img
                                                                    :src="rechtsIcon"
                                                                    style="height: 20px; filter: brightness(1000%)"
                                                                    alt="Audio abspielen"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Audio abspielen</span>
                                                    </v-tooltip>

                                                    <v-tooltip
                                                        v-else-if="!upload.seenByMe && ['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(upload.title.split('.').pop().toLowerCase())"
                                                        :disabled="!showTooltips"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                x-small
                                                                style="background-color: #8CBD46;"
                                                                class="btnStyle"
                                                                elevation="0"
                                                                v-on="on"
                                                                @click="alreadyEditedByMe(upload) ? openAlreadyEditedDialog(upload) : openEdit(upload.title, upload.file, upload._id)"
                                                            >
                                                                <img
                                                                    :src="stiftIcon"
                                                                    style="height: 20px;"
                                                                    alt="Bearbeiten"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <div style="display: flex; flex-direction: column; text-align: center">
                                                            <span>Bearbeiten</span>
                                                            <div style="margin: auto">
                                                                <img
                                                                    v-if="hoeren"
                                                                    :src="hausaufgabenMetacom"
                                                                    width="100"
                                                                    class="mr-1"
                                                                    alt=""
                                                                >
                                                                <img
                                                                    v-if="hoeren"
                                                                    :src="toDoMetacom"
                                                                    width="100"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </v-tooltip>

                                                    <v-tooltip
                                                        v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(upload.title.split('.').pop().toLowerCase())"
                                                        :disabled="!showTooltips"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                v-on="on"
                                                                @click="alreadyEditedByMe(upload) ? openAlreadyEditedDialog(upload) : openEdit(upload.title, upload.file, upload._id)"
                                                            >
                                                                <img
                                                                    :src="bearbeitenIcon"
                                                                    style="height: 20px;"
                                                                    alt="Bearbeiten"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <div style="display: flex; flex-direction: column; text-align: center">
                                                            <span>Bearbeiten</span>
                                                            <div style="margin: auto">
                                                                <img
                                                                    v-if="hoeren"
                                                                    :src="hausaufgabenMetacom"
                                                                    width="100"
                                                                    class="mr-1"
                                                                    alt=""
                                                                >
                                                                <img
                                                                    v-if="hoeren"
                                                                    :src="toDoMetacom"
                                                                    width="100"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </v-tooltip>

                                                    <div
                                                        v-else
                                                        style="display: flex"
                                                    >
                                                        <v-tooltip
                                                            :disabled="!showTooltips"
                                                            top
                                                        >
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn
                                                                    dark
                                                                    x-small
                                                                    class="btnStyle mr-2"
                                                                    elevation="0"
                                                                    style="background-color: #8CBD46;"
                                                                    v-on="on"
                                                                    @click="moveDialog = true; elementToMove = upload"
                                                                >
                                                                    <img
                                                                        :src="fertigIcon"
                                                                        style="height: 20px; filter: brightness(1000%)"
                                                                        alt="Ohne Abgabe abgeben"
                                                                    >
                                                                </v-btn>
                                                            </template>
                                                            <span>Ohne Abgabe abgeben</span>
                                                        </v-tooltip>

                                                        <v-tooltip
                                                            :disabled="!showTooltips"
                                                            top
                                                        >
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn
                                                                    dark
                                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                    x-small
                                                                    class="btnStyle"
                                                                    elevation="0"
                                                                    style="background-color: #8CBD46;"
                                                                    v-on="on"
                                                                    @click="fileNotSupported(upload)"
                                                                >
                                                                    <img
                                                                        :src="infoIcon"
                                                                        style="height: 20px; filter: brightness(1000%)"
                                                                        alt="Dateiformat nicht unterstützt"
                                                                    >
                                                                </v-btn>
                                                            </template>
                                                            <span>Dateiformat nicht unterstützt</span>
                                                        </v-tooltip>
                                                    </div>
                                                </v-badge>
                                                <div style="display: flex">
                                                    <v-tooltip
                                                        v-if="!upload.pipReference"
                                                        :disabled="!showTooltips"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                x-small
                                                                elevation="0"
                                                                class="btnStyle ml-2"
                                                                style="width: 20px; height: 20px"
                                                                v-on="on"
                                                                @click="() => pupilUploadFileAsAnswer(upload)"
                                                            >
                                                                <img
                                                                    :src="kameraIcon"
                                                                    style="height: 20px;"
                                                                    alt="Antwort hochladen"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <div style="display: flex; flex-direction: column; text-align: center">
                                                            <span>Antwort hochladen</span>
                                                            <img
                                                                v-if="hoeren"
                                                                :src="herunterladenMetacom"
                                                                width="100"
                                                                style="margin: auto"
                                                                alt=""
                                                            >
                                                        </div>
                                                    </v-tooltip>
                                                    <v-tooltip
                                                        v-if="!upload.pipReference"
                                                        :disabled="!showTooltips"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                x-small
                                                                elevation="0"
                                                                class="btnStyle ml-2"
                                                                style="width: 20px; height: 20px"
                                                                v-on="on"
                                                                @click="() => clickDownloadTeacherUpload(upload)"
                                                            >
                                                                <img
                                                                    :src="runterladenIcon"
                                                                    style="height: 20px;"
                                                                    alt="Herunterladen"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <div style="display: flex; flex-direction: column; text-align: center">
                                                            <span>Herunterladen</span>
                                                            <img
                                                                v-if="hoeren"
                                                                :src="herunterladenMetacom"
                                                                width="100"
                                                                style="margin: auto"
                                                                alt=""
                                                            >
                                                        </div>
                                                    </v-tooltip>

                                                    <!-- Show drop down for pip videos, so the user can choose which video to download -->
                                                    <v-menu
                                                        v-else-if="upload.pipReference"
                                                        offset-y
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                class="btnStyle ml-1"
                                                                elevation="0"
                                                                x-small
                                                                v-bind="attrs"
                                                                style="width: 20px; height: 20px"
                                                                v-on="on"
                                                            >
                                                                <img
                                                                    :src="arrowDown"
                                                                    :alt="downloadMenuOpen ? 'Menü einklappen' : 'Menü ausklappen'"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <div class="small-drop-down-menu">
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : 1"
                                                                style="text-transform: inherit; color: var(--v-dunkelgrau-base)"
                                                                text
                                                                class="d-flex justify-start"
                                                                @click="() => clickDownloadTeacherUpload(upload)"
                                                            >
                                                                <img
                                                                    :src="runterladenIcon"
                                                                    class="mr-1"
                                                                    style="height: 20px;"
                                                                    alt="Herunterladen"
                                                                >
                                                                Hauptvideo herunterladen
                                                            </v-btn>
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                style="text-transform: inherit; color: var(--v-dunkelgrau-base)"
                                                                text
                                                                class="d-flex justify-start"
                                                                @click="() => clickDownloadTeacherUpload(thisAppointment.teacherUploads.find(value => value._id === upload.pipReference))"
                                                            >
                                                                <img
                                                                    :src="runterladenIcon"
                                                                    class="mr-1"
                                                                    style="height: 20px;"
                                                                    alt="Herunterladen"
                                                                >
                                                                DGS-Video herunterladen
                                                            </v-btn>
                                                        </div>
                                                    </v-menu>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="urls[upload._id]"
                                            class="d-flex justify-center"
                                            style="max-height: 15vh; background-color: gray; width: 100%; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;"
                                        >
                                            <!-- Display image preview -->
                                            <img
                                                v-if="['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(upload.title.split('.').pop().toLowerCase())"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                :src="urls[upload._id]"
                                                style="cursor: pointer; max-width: 345px; max-height: 15vh;"
                                                alt=""
                                                @click="() => ['png', 'jpg', 'jpeg', 'bmp'].includes(upload.title.split('.').pop().toLowerCase()) ? openEdit(upload.title, upload.file, upload._id) : play(upload, index)"
                                                @keypress.enter="() => ['png', 'jpg', 'jpeg', 'bmp'].includes(upload.title.split('.').pop().toLowerCase()) ? openEdit(upload.title, upload.file, upload._id) : play(upload, index)"
                                                @keypress.space="() => ['png', 'jpg', 'jpeg', 'bmp'].includes(upload.title.split('.').pop().toLowerCase()) ? openEdit(upload.title, upload.file, upload._id) : play(upload, index)"
                                            >
                                            <!-- Display pdf preview -->
                                            <object
                                                v-else-if="['pdf'].includes(upload.title.split('.').pop().toLowerCase())"
                                                type="application/pdf"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                :data="urls[upload._id]"
                                                style="cursor: pointer; max-width: 100%; max-height: 100px;"
                                                @keypress.enter="() => openEdit(upload.title, upload.file, upload._id)"
                                                @keypress.space="() => openEdit(upload.title, upload.file, upload._id)"
                                                @click="() => openEdit(upload.title, upload.file, upload._id)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--all items if expanded-->
                            <div class="matLong">
                                <div
                                    v-for="(upload,index) in thisAppointment.teacherUploads.filter(item => !wasMovedByMe(item) && !item.editParent && (!('canPip' in item) || item.canPip))"
                                    :key="upload + index"
                                    :class="{ brighterFileRow: index % 2 === 1}"
                                    style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%;"
                                >
                                    <div
                                        style="display: flex; flex-flow: column; width: 100%;"
                                        class="py-2 px-5 d-flex justify-space-between align-center"
                                    >
                                        <div
                                            style="width: 100%; background-color: rgba(112,112,112, 0.15); border-top-left-radius: 6px; border-top-right-radius: 6px;"
                                            class="py-2 px-5 d-flex justify-space-between align-center"
                                        >
                                            <div
                                                class="fileNameDiv"
                                                style="min-width: 63%; width: 63%"
                                            >
                                                <p
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    class="fileName mb-0"
                                                >
                                                    {{ upload.title }}
                                                </p>
                                            </div>
                                            <div style="display: flex; flex-wrap: nowrap">
                                                <v-badge
                                                    color="red"
                                                    content="1"
                                                    :value="!upload.seenByMe"
                                                    overlap
                                                    offset-x="15"
                                                    style="z-index: 9; display: flex"
                                                >
                                                    <v-tooltip
                                                        v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(upload.title.split('.').pop().toLowerCase())"
                                                        :disabled="!showTooltips"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                dark
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                :loading="uploadLoadings[index]"
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                style="background-color: #8CBD46;"
                                                                v-on="on"
                                                                @click="() => {play(upload, index); menu = false}"
                                                            >
                                                                <img
                                                                    :src="rechtsIcon"
                                                                    style="height: 20px; filter: brightness(1000%)"
                                                                    alt="Video abspielen"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Video abspielen</span>
                                                    </v-tooltip>

                                                    <v-tooltip
                                                        v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(upload.title.split('.').pop().toLowerCase())"
                                                        :disabled="!showTooltips"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                dark
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                :loading="uploadLoadings[index]"
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                style="background-color: #8CBD46;"
                                                                v-on="on"
                                                                @click="() => {playAudio(upload, index); menu = false}"
                                                            >
                                                                <img
                                                                    :src="rechtsIcon"
                                                                    style="height: 20px; filter: brightness(1000%)"
                                                                    alt="Audio abspielen"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Audio abspielen</span>
                                                    </v-tooltip>

                                                    <v-tooltip
                                                        v-else-if="!upload.seenByMe && ['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(upload.title.split('.').pop().toLowerCase())"
                                                        :disabled="!showTooltips"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                x-small
                                                                style="background-color: #8CBD46;"
                                                                class="btnStyle"
                                                                elevation="0"
                                                                v-on="on"
                                                                @click="alreadyEditedByMe(upload) ? openAlreadyEditedDialog(upload) : openEdit(upload.title, upload.file, upload._id)"
                                                            >
                                                                <img
                                                                    :src="stiftIcon"
                                                                    style="height: 20px;"
                                                                    alt="Bearbeiten"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Bearbeiten</span>
                                                    </v-tooltip>

                                                    <v-tooltip
                                                        v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(upload.title.split('.').pop().toLowerCase())"
                                                        :disabled="!showTooltips"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                v-on="on"
                                                                @click="alreadyEditedByMe(upload) ? openAlreadyEditedDialog(upload) : openEdit(upload.title, upload.file, upload._id)"
                                                            >
                                                                <img
                                                                    :src="bearbeitenIcon"
                                                                    style="height: 20px;"
                                                                    alt="Bearbeiten"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <div style="display: flex; flex-direction: column; text-align: center">
                                                            <span>Bearbeiten</span>
                                                            <div style="margin: auto">
                                                                <img
                                                                    v-if="hoeren"
                                                                    :src="hausaufgabenMetacom"
                                                                    width="100"
                                                                    class="mr-1"
                                                                    alt=""
                                                                >
                                                                <img
                                                                    v-if="hoeren"
                                                                    :src="toDoMetacom"
                                                                    width="100"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </v-tooltip>

                                                    <div
                                                        v-else
                                                        style="display: flex"
                                                    >
                                                        <v-tooltip
                                                            :disabled="!showTooltips"
                                                            top
                                                        >
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn
                                                                    dark
                                                                    x-small
                                                                    class="btnStyle mr-2"
                                                                    elevation="0"
                                                                    style="background-color: #8CBD46;"
                                                                    v-on="on"
                                                                    @click="moveDialog = true; elementToMove = upload"
                                                                >
                                                                    <img
                                                                        :src="fertigIcon"
                                                                        style="height: 20px; filter: brightness(1000%)"
                                                                        alt="Ohne Abgabe abgeben"
                                                                    >
                                                                </v-btn>
                                                            </template>
                                                            <span>Ohne Abgabe abgeben</span>
                                                        </v-tooltip>

                                                        <v-tooltip
                                                            :disabled="!showTooltips"
                                                            top
                                                        >
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn
                                                                    dark
                                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                    x-small
                                                                    class="btnStyle"
                                                                    elevation="0"
                                                                    style="background-color: #8CBD46;"
                                                                    v-on="on"
                                                                    @click="fileNotSupported(upload)"
                                                                >
                                                                    <img
                                                                        :src="infoIcon"
                                                                        style="height: 20px; filter: brightness(1000%)"
                                                                        alt="Dateiformat nicht unterstützt"
                                                                    >
                                                                </v-btn>
                                                            </template>
                                                            <span>Dateiformat nicht unterstützt</span>
                                                        </v-tooltip>
                                                    </div>
                                                </v-badge>
                                                <div style="display: inline-flex">
                                                    <v-tooltip
                                                        v-if="!upload.pipReference"
                                                        :disabled="!showTooltips"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                x-small
                                                                elevation="0"
                                                                class="btnStyle ml-2"
                                                                style="width: 20px; height: 20px"
                                                                v-on="on"
                                                                @click="() => {}"
                                                            >
                                                                <img
                                                                    :src="kameraIcon"
                                                                    style="height: 20px;"
                                                                    alt="Antwort hochladen"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <div style="display: flex; flex-direction: column; text-align: center">
                                                            <span>Antwort hochladen</span>
                                                            <img
                                                                v-if="hoeren"
                                                                :src="herunterladenMetacom"
                                                                width="100"
                                                                style="margin: auto"
                                                                alt=""
                                                            >
                                                        </div>
                                                    </v-tooltip>
                                                    <v-tooltip
                                                        v-if="!upload.pipReference"
                                                        :disabled="!showTooltips"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                x-small
                                                                elevation="0"
                                                                class="btnStyle ml-2"
                                                                style="width: 20px; height: 20px"
                                                                v-on="on"
                                                                @click="() => clickDownloadTeacherUpload(upload)"
                                                            >
                                                                <img
                                                                    :src="runterladenIcon"
                                                                    style="height: 20px;"
                                                                    alt="Herunterladen"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <div style="display: flex; flex-direction: column; text-align: center">
                                                            <span>Herunterladen</span>
                                                            <img
                                                                v-if="hoeren"
                                                                :src="herunterladenMetacom"
                                                                width="100"
                                                                style="margin: auto"
                                                                alt=""
                                                            >
                                                        </div>
                                                    </v-tooltip>

                                                    <!-- Show drop down for pip videos, so the user can choose which video to download -->
                                                    <v-menu
                                                        v-else-if="upload.pipReference"
                                                        offset-y
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                class="btnStyle ml-1"
                                                                elevation="0"
                                                                x-small
                                                                v-bind="attrs"
                                                                style="width: 20px; height: 20px"
                                                                v-on="on"
                                                            >
                                                                <img
                                                                    :src="arrowDown"
                                                                    :alt="downloadMenuOpen ? 'Menü einklappen' : 'Menü ausklappen'"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <div class="small-drop-down-menu">
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : 1"
                                                                style="text-transform: inherit; color: var(--v-dunkelgrau-base)"
                                                                text
                                                                class="d-flex justify-start"
                                                                @click="() => clickDownloadTeacherUpload(upload)"
                                                            >
                                                                <img
                                                                    :src="runterladenIcon"
                                                                    class="mr-1"
                                                                    style="height: 20px;"
                                                                    alt="Herunterladen"
                                                                >
                                                                Hauptvideo herunterladen
                                                            </v-btn>
                                                            <v-btn
                                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                                style="text-transform: inherit; color: var(--v-dunkelgrau-base)"
                                                                text
                                                                class="d-flex justify-start"
                                                                @click="() => clickDownloadTeacherUpload(thisAppointment.teacherUploads.find(value => value._id === upload.pipReference))"
                                                            >
                                                                <img
                                                                    :src="runterladenIcon"
                                                                    class="mr-1"
                                                                    style="height: 20px;"
                                                                    alt="Herunterladen"
                                                                >
                                                                DGS-Video herunterladen
                                                            </v-btn>
                                                        </div>
                                                    </v-menu>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="urls[upload._id]"
                                            class="d-flex justify-center"
                                            style="max-height: 15vh; background-color: gray; width: 100%; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;"
                                        >
                                            <!-- Display image preview -->
                                            <img
                                                v-if="['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(upload.title.split('.').pop().toLowerCase())"
                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                :src="urls[upload._id]"
                                                style="cursor: pointer; max-width: 345px; max-height: 15vh;"
                                                alt=""
                                                @click="() => ['png', 'jpg', 'jpeg', 'bmp'].includes(upload.title.split('.').pop().toLowerCase()) ? openEdit(upload.title, upload.file, upload._id) : play(upload, index)"
                                                @keypress.enter="() => ['png', 'jpg', 'jpeg', 'bmp'].includes(upload.title.split('.').pop().toLowerCase()) ? openEdit(upload.title, upload.file, upload._id) : play(upload, index)"
                                                @keypress.space="() => ['png', 'jpg', 'jpeg', 'bmp'].includes(upload.title.split('.').pop().toLowerCase()) ? openEdit(upload.title, upload.file, upload._id) : play(upload, index)"
                                            >
                                            <!-- Display pdf preview -->
                                            <object
                                                v-else-if="['pdf'].includes(upload.title.split('.').pop().toLowerCase())"
                                                type="application/pdf"
                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                :data="urls[upload._id]"
                                                style="cursor: pointer; max-width: 100%; max-height: 100px;"
                                                @keypress.enter="() => openEdit(upload.title, upload.file, upload._id)"
                                                @keypress.space="() => openEdit(upload.title, upload.file, upload._id)"
                                                @click="() => openEdit(upload.title, upload.file, upload._id)"
                                            />
                                            <!-- Display video preview -->
                                            <!--                                                <video-->
                                            <!--                                                    v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"-->
                                            <!--                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"-->
                                            <!--                                                    @click="() => play(el, index)"-->
                                            <!--                                                    @keypress.enter="() => play(el, index)"-->
                                            <!--                                                    @keypress.space="() => play(el, index)"-->
                                            <!--                                                    :src="urls[el._id]"-->
                                            <!--                                                    style="cursor: pointer; max-width: 345px; max-height: 15vh;"/>-->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--expand and collapse btns-->
                            <div
                                v-if="thisAppointment.teacherUploads.filter(item => !wasMovedByMe(item) && !item.editParent && (!('canPip' in item) || item.canPip)).length > 2"
                                class="d-flex flex-column align-center justify-center mt-2"
                            >
                                <v-tooltip
                                    :disabled="!showTooltips"
                                    top
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            :tabindex="planVollbild ? tabOrderPopup : '1'"
                                            :style="`background-color: ${thisAppointment.schoolSubject.color};`"
                                            class="matShort"
                                            small
                                            elevation="0"
                                            v-on="on"
                                            @click="() => { hideEl('matShort'); showEl('matLong') }"
                                        >
                                            <img
                                                :src="arrowDown"
                                                style="height: 20px;"
                                                :style="`filter: ${whitenIconBasedOnBackground(thisAppointment.schoolSubject.color) ? 'brightness(1000%)' : ''}`"
                                                alt="Mehr anzeigen"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Mehr anzeigen</span>
                                </v-tooltip>
                                <p class="mb-0 matShort">
                                    Mehr anzeigen
                                </p>

                                <v-tooltip
                                    :disabled="!showTooltips"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            :tabindex="planVollbild ? tabOrderPopup : '1'"
                                            :style="`background-color: ${thisAppointment.schoolSubject.color};`"
                                            class="matLong"
                                            small
                                            elevation="0"
                                            v-on="on"
                                            @click="() => { hideEl('matLong'); showEl('matShort') }"
                                        >
                                            <img
                                                :src="arrowDown"
                                                style="height: 20px; transform: rotate(180deg)"
                                                :style="`filter: ${whitenIconBasedOnBackground(thisAppointment.schoolSubject.color) ? 'brightness(1000%)' : ''}`"
                                                alt="Weniger anzeigen"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Weniger anzeigen</span>
                                </v-tooltip>
                                <p class="mb-0 matLong">
                                    Weniger anzeigen
                                </p>
                            </div>
                        </div>
                    </div>

                    <hr
                        aria-hidden="true"
                        class="eHr"
                    >

                    <!-- Abgaben area -->
                    <div class="mx-0">
                        <div class="mt-2">
                            <v-row>
                                <v-col class="font-weight-bold pb-2 mx-5 pt-0 d-flex align-center">
                                    <img
                                        :src="abgabenIcon"
                                        alt=""
                                        style="height: 20px;"
                                    >
                                    <p
                                        :tabindex="planVollbild ? tabOrderPopup : '1'"
                                        class="ml-2 ma-0"
                                        style="width: fit-content; float: right; font-size: medium"
                                    >
                                        Abgaben
                                    </p>
                                </v-col>

                                <v-col class="text-right py-1">
                                    <input
                                        id="pupilUploadInput"
                                        ref="pupilUploadInput"
                                        type="file"
                                        hidden
                                        @change="() => pupilUploadInputChange(thisAppointment._id)"
                                    >
                                </v-col>
                            </v-row>

                            <div
                                v-if="thisAppointment.pupilUpload.length === 0"
                                class="mx-5"
                                style="font-size: medium;"
                            >
                                <p
                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                    class="mb-0"
                                >
                                    Du hast noch nichts abgegeben.
                                </p>
                            </div>

                            <div class="uploadsShort">
                                <v-row
                                    v-for="(el,index) in thisAppointment.pupilUpload.slice(0, 2)"
                                    :key="el + index"
                                    :class="{ brighterFileRow: index % 2 === 1, correctionAvailableClass: isCorrectionAvailable(el)}"
                                    class="py-2 px-5 ma-0 d-flex justify-space-between align-center"
                                    style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(112,112,112, 0.15);"
                                >
                                    <div
                                        class="fileNameDiv"
                                        style="max-width: 60% !important; min-width: 60% !important;"
                                    >
                                        <p
                                            :tabindex="planVollbild ? tabOrderPopup : '1'"
                                            class="fileName pb-0"
                                        >
                                            {{ el.title }}
                                        </p>
                                    </div>
                                    <div style="display: inline-block">
                                        <v-tooltip
                                            v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                            :disabled="!showTooltips"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    :loading="uploadLoadingsPuipl[index]"
                                                    x-small
                                                    class="btnStyle mx-1"
                                                    elevation="0"
                                                    v-on="on"
                                                    @click="() => {playPupil(el, index); menu = false}"
                                                >
                                                    <img
                                                        :src="rechtsIcon"
                                                        alt="Video abspielen"
                                                        style="height: 20px; "
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Video abspielen</span>
                                        </v-tooltip>

                                        <v-tooltip
                                            v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                            :disabled="!showTooltips"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    :loading="uploadLoadingsPuipl[index]"
                                                    x-small
                                                    class="btnStyle mx-1"
                                                    elevation="0"
                                                    v-on="on"
                                                    @click="() => {playAudioPupil(el, index); menu = false}"
                                                >
                                                    <img
                                                        :src="rechtsIcon"
                                                        alt="Audio abspielen"
                                                        style="height: 20px; "
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Audio abspielen</span>
                                        </v-tooltip>

                                        <v-tooltip
                                            v-else-if="!el.seenByMe && ['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                            :disabled="!showTooltips"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    x-small
                                                    class="btnStyle mx-1"
                                                    elevation="0"
                                                    v-on="on"
                                                    @click="() => handleOpenAnsichtClick(el)"
                                                >
                                                    <!--                                                <img :src="bearbeitenIcon" style="height: 20px;"/>-->
                                                    <img
                                                        :src="fasFaEye"
                                                        alt="Vorschau"
                                                        style="width: 20px; color: #939393"
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Vorschau</span>
                                        </v-tooltip>

                                        <v-tooltip
                                            v-else
                                            :disabled="!showTooltips"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    x-small
                                                    class="btnStyle mx-1"
                                                    elevation="0"
                                                    v-on="on"
                                                    @click="fileNotSupported(el)"
                                                >
                                                    <img
                                                        :src="infoIcon"
                                                        alt="Dateiformat nicht unterstützt"
                                                        style="height: 20px;"
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Dateiformat nicht unterstützt</span>
                                        </v-tooltip>

                                        <v-tooltip
                                            v-if="isCorrectionAvailable(el)"
                                            :disabled="!showTooltips"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    class="btnStyle"
                                                    elevation="0"
                                                    x-small
                                                    v-on="on"
                                                    @click="() => clickDownloadPupilCorrection(el)"
                                                >
                                                    <img
                                                        :src="herunterladenIcon"
                                                        alt="Herunterladen"
                                                        style="height: 20px;"
                                                    >
                                                </v-btn>
                                            </template>
                                            <div style="display: flex; flex-direction: column; text-align: center">
                                                <span>Herunterladen</span>
                                                <div style="margin: auto">
                                                    <img
                                                        v-if="hoeren"
                                                        :src="hausaufgabenMetacom"
                                                        width="100"
                                                        class="mr-1"
                                                        alt=""
                                                    >
                                                    <img
                                                        v-if="hoeren"
                                                        :src="toDoMetacom"
                                                        width="100"
                                                        alt=""
                                                    >
                                                </div>
                                            </div>
                                        </v-tooltip>

                                        <v-tooltip
                                            v-else
                                            :disabled="!showTooltips"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    elevation="0"
                                                    x-small
                                                    class="btnStyle mr-1"
                                                    v-on="on"
                                                    @click="() => clickDownloadPupilUpload(el)"
                                                >
                                                    <img
                                                        :src="offenIcon"
                                                        alt="Korrektur ausstehend"
                                                        style="height: 20px;"
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Korrektur ausstehend</span>
                                        </v-tooltip>

                                        <!--                                        <v-tooltip :disabled="!showTooltips" top>-->
                                        <!--                                            <template v-slot:activator="{ on }">-->
                                        <!--                                                <v-btn v-on="on"-->
                                        <!--                                                       @click="deleteDialog = true; elementToDelete = el" class="btnStyle"-->
                                        <!--                                                       x-small elevation="0">-->
                                        <!--                                                    <img :src="papierkorbIcon" alt="Datei löschen" style="height: 20px;"/>-->
                                        <!--                                                </v-btn>-->
                                        <!--                                            </template>-->
                                        <!--                                            <div style="display: flex; flex-direction: column; text-align: center">-->
                                        <!--                                                <span>Datei löschen</span>-->
                                        <!--                                                <img v-if="hoeren" :src="loeschenMetacom" width="100" style="margin: auto" alt="">-->
                                        <!--                                            </div>-->
                                        <!--                                        </v-tooltip>-->
                                    </div>
                                </v-row>
                            </div>

                            <div class="uploadsLong">
                                <v-row
                                    v-for="(el,index) in thisAppointment.pupilUpload"
                                    :key="el + index"
                                    :class="{ brighterFileRow: index % 2 === 1, correctionAvailableClass: isCorrectionAvailable(el)}"
                                    class="py-2 px-5 ma-0 d-flex justify-space-between align-center"
                                    style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; min-height: 49px ;background-color: rgba(112,112,112, 0.15);"
                                >
                                    <div
                                        class="fileNameDiv"
                                        style="max-width: 60% !important; min-width: 60% !important;"
                                    >
                                        <p
                                            :tabindex="planVollbild ? tabOrderPopup : '1'"
                                            class="fileName pb-0"
                                        >
                                            {{ el.title }}
                                        </p>
                                    </div>

                                    <div class="pa-0 ml-0">
                                        <v-tooltip
                                            v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                            :disabled="!showTooltips"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    :loading="uploadLoadingsPuipl[index]"
                                                    x-small
                                                    class="btnStyle mx-1"
                                                    elevation="0"
                                                    v-on="on"
                                                    @click="() => {playPupil(el, index); menu = false}"
                                                >
                                                    <img
                                                        :src="rechtsIcon"
                                                        alt="Video abspielen"
                                                        style="height: 20px; "
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Video abspielen</span>
                                        </v-tooltip>

                                        <v-tooltip
                                            v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                            :disabled="!showTooltips"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    :loading="uploadLoadingsPuipl[index]"
                                                    x-small
                                                    class="btnStyle mx-1"
                                                    elevation="0"
                                                    v-on="on"
                                                    @click="() => {playAudioPupil(el, index); menu = false}"
                                                >
                                                    <img
                                                        :src="rechtsIcon"
                                                        alt="Audio abspielen"
                                                        style="height: 20px; "
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Audio abspielen</span>
                                        </v-tooltip>

                                        <v-tooltip
                                            v-else-if="!el.seenByMe && ['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                            :disabled="!showTooltips"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    x-small
                                                    class="btnStyle mx-1"
                                                    elevation="0"
                                                    v-on="on"
                                                    @click="() => handleOpenAnsichtClick(el)"
                                                >
                                                    <!--                                                <img :src="bearbeitenIcon" style="height: 20px;"/>-->
                                                    <img
                                                        :src="fasFaEye"
                                                        alt="Vorschau"
                                                        style="width: 20px; color: #939393"
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Vorschau</span>
                                        </v-tooltip>

                                        <v-tooltip
                                            v-else
                                            :disabled="!showTooltips"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    x-small
                                                    class="btnStyle mx-1"
                                                    elevation="0"
                                                    v-on="on"
                                                    @click="fileNotSupported(el)"
                                                >
                                                    <img
                                                        :src="infoIcon"
                                                        alt="Dateiformat nicht unterstützt"
                                                        style="height: 20px;"
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Dateiformat nicht unterstützt</span>
                                        </v-tooltip>

                                        <v-tooltip
                                            v-if="isCorrectionAvailable(el)"
                                            :disabled="!showTooltips"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    class="btnStyle"
                                                    elevation="0"
                                                    x-small
                                                    v-on="on"
                                                    @click="() => clickDownloadPupilCorrection(el)"
                                                >
                                                    <img
                                                        :src="bearbeitetIcon"
                                                        alt="Bearbeiten"
                                                        style="height: 20px;"
                                                    >
                                                </v-btn>
                                            </template>
                                            <div style="display: flex; flex-direction: column; text-align: center">
                                                <span>Bearbeiten</span>
                                                <div style="margin: auto">
                                                    <img
                                                        v-if="hoeren"
                                                        :src="hausaufgabenMetacom"
                                                        width="100"
                                                        class="mr-1"
                                                        alt=""
                                                    >
                                                    <img
                                                        v-if="hoeren"
                                                        :src="toDoMetacom"
                                                        width="100"
                                                        alt=""
                                                    >
                                                </div>
                                            </div>
                                        </v-tooltip>

                                        <v-tooltip
                                            v-else
                                            :disabled="!showTooltips"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                    elevation="0"
                                                    x-small
                                                    class="btnStyle mr-1"
                                                    v-on="on"
                                                    @click="() => clickDownloadPupilUpload(el)"
                                                >
                                                    <img
                                                        :src="offenIcon"
                                                        alt="Korrektur ausstehend"
                                                        style="height: 20px;"
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Korrektur ausstehend</span>
                                        </v-tooltip>

                                        <v-tooltip
                                            :disabled="!showTooltips"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="btnStyle"
                                                    x-small
                                                    elevation="0"
                                                    v-on="on"
                                                    @click="deleteDialog = true; elementToDelete = el"
                                                >
                                                    <img
                                                        :src="papierkorbIcon"
                                                        alt="Datei löschen"
                                                        style="height: 20px;"
                                                    >
                                                </v-btn>
                                            </template>
                                            <div style="display: flex; flex-direction: column; text-align: center">
                                                <span>Datei löschen</span>
                                                <img
                                                    v-if="hoeren"
                                                    :src="loeschenMetacom"
                                                    width="100"
                                                    style="margin: auto"
                                                    alt=""
                                                >
                                            </div>
                                        </v-tooltip>
                                    </div>
                                </v-row>
                            </div>
                            <!-- v-if="thisAppointment.teacherUploads.filter(item => !item.editParent && (!('canPip' in item) || item.canPip)).length > 2" -->
                            <!--expand and collapse btns-->
                            <div
                                v-if="thisAppointment.pupilUpload.length > 2"
                                class="d-flex justify-center mt-2"
                            >
                                <div
                                    class="d-flex justify-center mt-2"
                                >
                                    <v-tooltip
                                        :disabled="!showTooltips"
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                class="uploadsShort"
                                                small
                                                elevation="0"
                                                v-on="on"
                                                @click="hideEl('uploadsShort'); showEl('uploadsLong')"
                                            >
                                                <img
                                                    :src="arrowDown"
                                                    alt="Mehr anzeigen"
                                                    style="height: 20px;"
                                                >
                                            </v-btn>
                                        </template>
                                        <span>Mehr anzeigen</span>
                                    </v-tooltip>

                                    <v-tooltip
                                        :disabled="!showTooltips"
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                class="uploadsLong"
                                                small
                                                elevation="0"
                                                v-on="on"
                                                @click="hideEl('uploadsLong'); showEl('uploadsShort')"
                                            >
                                                <img
                                                    :src="arrowDown"
                                                    alt="Weniger anzeigen"
                                                    style="height: 20px; transform: rotate(180deg)"
                                                >
                                            </v-btn>
                                        </template>
                                        <span>Weniger anzeigen</span>
                                    </v-tooltip>
                                </div>
                            </div>

                            <div class="my-4">
                                <div
                                    class="d-flex"
                                    style="justify-content: center"
                                >
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                elevation="0"
                                                class="text-capitalize px-2"
                                                style="color: var(--v-dunkelgrau-base)"
                                                v-bind="attrs"
                                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                                aria-label="Datei hochladen, unterstütze Formate, welche als Abgabe hochgeladen werden können: Audio: AAC, MP3, M4A, OGG, WAV, WMA. Bilder oder Texte: BMP, JPG, PDF, PNG, TIFF. Video: FLV, MOV, MPG, MP4, M4V, WEBM, WMV. MS Office Dateien (z.B. DOCX, PPTX, XLSX) müssen heruntergeladen und lokal bearbeitet werden"
                                                v-on="on"
                                            >
                                                <img
                                                    :src="hochladenIcon"
                                                    alt=""
                                                    style="height: 20px;"
                                                >
                                                <p class="ml-2">
                                                    Hochladen
                                                </p>
                                            </v-btn>
                                        </template>
                                        <div class="small-drop-down-menu">
                                            <v-btn
                                                tabindex="0"
                                                style="text-transform: inherit; color: var(--v-dunkelgrau-base)"
                                                text
                                                class="d-flex justify-start"
                                                @click="() => { fileWidgetUploadDialog = true; }"
                                            >
                                                <img
                                                    :src="dateiIcon"
                                                    class="iconSize mr-1"
                                                    alt=""
                                                >
                                                aus eKlara-Ordner
                                            </v-btn>
                                            <v-btn
                                                tabindex="0"
                                                style="text-transform: inherit; color: var(--v-dunkelgrau-base)"
                                                text
                                                class="d-flex justify-start"
                                                @click="clickPupilUploadButton"
                                            >
                                                <img
                                                    :src="dateiIcon"
                                                    class="iconSize mr-1"
                                                    alt=""
                                                >
                                                Lokales Foto, Video, Datei...
                                            </v-btn>
                                        </div>
                                    </v-menu>

                                    <v-btn
                                        id="createEditorABsButton"
                                        :tabindex="planVollbild ? tabOrderPopup : '1'"
                                        class="ml-1 py-0 px-2 text-capitalize"
                                        elevation="0"
                                        aria-label="Erstellen von eigenen Arbeitsblättern"
                                        @click="openEmptyEdit('empty')"
                                    >
                                        <img
                                            :src="neuesABIcon"
                                            class="iconToWhite"
                                            alt="Erstellmenü Symbol"
                                            height="20"
                                        >
                                        <p
                                            v-if="windowWidth > 500"
                                            class="ml-2"
                                        >
                                            Erstellen
                                        </p>
                                    </v-btn>

                                    <AudioUpload
                                        :tab-order="planVollbild ? tabOrderPopup : '1'"
                                        :upload="uploadFile"
                                        :appointment-id="thisAppointment._id"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--                    </vue-custom-scrollbar>-->
                </v-card-text>

                <v-card-actions
                    class="pa-0 ma-0"
                    style="overflow: hidden;"
                >
                    <!--Beitreten-Button in Fach-Popup-->
                    <v-row>
                        <hr
                            aria-hidden="true"
                            class="eHr mt-2"
                        >
                        <v-col class="text-center">
                            <v-btn
                                :tabindex="planVollbild ? tabOrderPopup : '2'"
                                elevation="0"
                                class="text-capitalize"
                                style="color: white; padding-left: 50px; padding-right: 50px"
                                :style="isJoinable ? 'background-color: #8CBD46' : 'background-color: grey'"
                                @click="clickBeitreten()"
                            >
                                <img
                                    :src="beitretenKameraIcon"
                                    alt="Stunde beitreten"
                                    class="mr-5"
                                    style="height: 20px;"
                                >
                                Beitreten
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--fach with no text and image-->
        <div
            v-else
            class="fach pa-2 white--text"
            style="width: 90%; display: block; padding-left: 0 !important;"
        >
            <v-row class="ml-2 font-weight-medium">
                <v-col
                    cols="12"
                    class="pa-0 ma-0"
                    style="height: 48px !important;"
                >
                    <div class="pa-3 ma-0 appointmentItem inactiveAppointment">
                        <img
                            class="iconSelectFrei"
                            alt=""
                        >
                        Hohlstunde
                    </div>
                </v-col>
            </v-row>
        </div>

        <!--#region dialogs and overlay -->
        <!-- Normal Video Popup -->
        <v-dialog
            v-if="videoUrl"
            :value="videoUrl"
            overlay-opacity=".9"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="videoUrl = null; $refs.normalVideo.stop();"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        large
                        dark
                        @click="videoUrl = null; primaryVideoUrl = null; secondaryVideoUrl = null; $refs.normalVideo.stop();"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <!--                    <video ref="normalVideo" :src="videoUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"-->
                    <!--                           :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">-->
                    <!--                        Your browser does not support the video tag.-->
                    <!--                    </video>-->
                    <video-in-video
                        ref="normalVideo"
                        :primary-video-src="videoUrl"
                        :subtitle-src="subtitleURL"
                    />
                </v-col>
            </v-row>
        </v-dialog>

        <!-- Video in Video Popup -->
        <v-dialog
            v-if="primaryVideoUrl && secondaryVideoUrl"
            :value="primaryVideoUrl && secondaryVideoUrl"
            overlay-opacity=".9"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop();"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        large
                        dark
                        @click="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop();"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video-in-video
                        ref="videoInVideo"
                        size-controls
                        :primary-video-src="primaryVideoUrl"
                        :secondary-video-src="secondaryVideoUrl"
                        :subtitle-src="subtitleURL"
                    />
                </v-col>
            </v-row>
        </v-dialog>

        <v-dialog
            v-model="moveDialog"
            max-width="350px"
        >
            <v-card>
                <v-card-title>
                    Abgeben bestätigen
                </v-card-title>

                <v-card-text>
                    Willst du die Datei wirklich <strong>ohne</strong> eine Lösung/Abgabe abgeben?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="rot"
                        dark
                        @click="moveDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            style="height: 20px"
                            class="iconToWhite mr-2"
                            alt=""
                        >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="gruen"
                        dark
                        @click="moveUploadToDone(elementToMove)"
                    >
                        <img
                            :src="fertigIcon"
                            style="height: 20px"
                            class="iconToWhite mr-2"
                            alt=""
                        >
                        Abgeben
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay
            v-if="audioUrl"
            opacity="0.95"
            z-index="95"
        >
            <v-icon
                large
                style="position: fixed; top: 50px; right: 50px; z-index: 99"
                dark
                @click="audioUrl = false"
            >
                fas fa-times
            </v-icon>
            <audio
                :src="audioUrl"
                controls
                :width="windowWidth > 900 ? '80%' : '40%%'"
                :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }"
            >
                Your browser does not support the video tag.
            </audio>
        </v-overlay>

        <v-dialog
            v-model="deleteDialog"
            max-width="350px"
        >
            <v-card>
                <v-card-title>
                    Löschen bestätigen
                </v-card-title>

                <v-card-text>
                    Willst du die Datei wirklich <strong>unwiderruflich</strong> löschen?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="rot"
                        dark
                        @click="clickDeleteUpload"
                    >
                        <img
                            :src="papierkorbIcon"
                            style="height: 20px; filter: brightness(1000%)"
                            class="mr-2"
                            alt=""
                        >
                        Löschen
                    </v-btn>

                    <v-btn
                        class="mr-2 text-capitalize"
                        color="gruen"
                        dark
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            style="height: 20px; filter: brightness(1000%)"
                            class="mr-2"
                            alt=""
                        >
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="alreadyEditedDialog"
            max-width="350px"
        >
            <v-card>
                <v-card-title>
                    Nochmal bearbeiten?
                </v-card-title>

                <v-card-text>
                    Du hast diese Aufgabe bereits bearbeitet und abgegeben. Wenn du sie nochmal bearbeitest hat der
                    Lehrer mehrere Abgaben von dir. Willst du die Aufgabe wirklich nochmal bearbeiten?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="rot"
                        dark
                        @click="openEdit(alreadyEditedUpload.title, alreadyEditedUpload.file, alreadyEditedUpload._id)"
                    >
                        <img
                            :src="bearbeitenIcon"
                            style="height: 20px; filter: brightness(1000%)"
                            class="mr-2"
                            alt=""
                        >
                        Bearbeiten
                    </v-btn>

                    <v-btn
                        class="mr-2 text-capitalize"
                        color="gruen"
                        dark
                        @click="alreadyEditedDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            style="height: 20px; filter: brightness(1000%)"
                            class="mr-2"
                            alt=""
                        >
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog for FileWidget upload -->
        <FileWidgetSelectionDialog
            :show="fileWidgetUploadDialog"
            :subject-apponintment="thisAppointment"
            :item-selected-callback="uploadViaFileWidget"
            @close="fileWidgetUploadDialog = false;"
        />
        <!--#endregion -->
        <VideoTutorial
            v-if="videoTutorial && showVideoTutorial"
            :video-tutorial="videoTutorial"
            @close="onVideoTutorialClose"
        />
    </div>
</template>

<script>
import * as backend from "../../api/backend";
import sanitizeHtml from '../../util/sanitizeHtml';
import beitretenKameraIcon from '../../assets/Icons/kamera-video-weiss-72.svg'
import schliesenIcon from '../../assets/Icons/abbrechen-08.svg'
import buchzeichenIcon from '../../assets/Icons/lesezeichen-01-43.svg'
import lesezeichenIcon from '../../assets/Icons/lesezeichen-01-43.svg'
import lautsprecherIcon from '../../assets/Icons/lautsprecher-13.svg'
import stiftIcon from '../../assets/Icons/bearbeiten-komplimentär-weiß-88.svg'
import bearbeitenIcon from '../../assets/Icons/bearbeiten-komplimentär-grau-88.svg'
import bearbeitetIcon from '../../assets/Icons/bearbeitet-17.svg'
import previewIcon from '../../assets/Icons/preview-85.svg'
import runterladenIcon from '../../assets/Icons/runterladen-14.svg'
import hochladenIcon from '../../assets/Icons/hochladen-15.svg'
import rechtsIcon from '../../assets/Icons/rechts-filled-11.svg'
import materialIcon from '../../assets/Icons/material-93.svg'
import abgabenIcon from "../../assets/Icons/bearbeiten-16.svg"
import arrowDown from '../../assets/Icons/unten-dropdown-filled-12.svg'
import offenIcon from "../../assets/Icons/offen-18.svg";
import paperclipIcon from "../../assets/Icons/Bueroklammer-94.svg";
import leeresBlatt from "../../assets/Icons/leeres-blatt-110.svg";
import kariertesBlatt from "../../assets/Icons/kariertes blatt-112.svg";
import liniertesBlatt from "../../assets/Icons/liniertes-blatt-111.svg";
import infoIcon from "../../assets/Icons/info-45.svg";
import fasFaEye from "../../assets/Icons/fas-fa-eye.svg"
import mikrofonIcon from '../../assets/Icons/mikrofon-22.svg';
import kameraIcon from '../../assets/Icons/kamera-21.svg';
import videoIcon from '../../assets/Icons/kamera-video-73.svg';
import exclamationIcon from '../../assets/Icons/ausrufezeichen-62.svg';
import papierkorbIcon from '../../assets/Icons/papierkorb-110.svg';
import fertigIcon from '../../assets/Icons/fertig-74.svg'
import dateiIcon from '../../assets/Icons/leere-korrektur-78.svg';
import neuesABIcon from '../../assets/Icons/neues-interaktives-arbeitsblatt.svg';
import herunterladenIcon from '../../assets/Icons/download-116.svg';

import bioIcon from '../../assets/Icons/f-biologie-35.svg'
import chemieIcon from '../../assets/Icons/f-chemie-37.svg'
import deutschIcon from '../../assets/Icons/f-deutsch-29.svg'
import englischIcon from '../../assets/Icons/f-englisch-30.svg'
import erdkundeIcon from '../../assets/Icons/f-erdkunde-31.svg'
import geschichteIcon from '../../assets/Icons/f-geschichte-40.svg'
import matheIcon from '../../assets/Icons/f-mathe-32.svg'
import musikIcon from '../../assets/Icons/f-musik-84.svg'
import physikIcon from '../../assets/Icons/f-physik-36.svg'
import politikIcon from '../../assets/Icons/f-politik-34.svg'
import religionIcon from '../../assets/Icons/f-religion-39.svg'
import sportIcon from '../../assets/Icons/f-sport-38.svg'
import wirtschaftIcon from '../../assets/Icons/f-wirtschaft-33.svg'

import bioIconWeiss from "@/assets/Icons/f-biologie-weiss-35.svg";
import chemieIconWeiss from "@/assets/Icons/f-chemie-weiss-37.svg";
import deutschIconWeiss from "@/assets/Icons/f-deutsch-weiss-29.svg";
import englischIconWeiss from "@/assets/Icons/f-englisch-weiss-30.svg";
import erdkundeIconWeiss from "@/assets/Icons/f-erdkunde-weiss-31.svg";
import geschichteIconWeiss from "@/assets/Icons/f-geschichte-weiss-40.svg";
import matheIconWeiss from "@/assets/Icons/f-mathe-weiss-32.svg";
import musikIconWeiss from "@/assets/Icons/f-musik-weiss-82.svg";
import physikIconWeiss from "@/assets/Icons/f-physik-weiss-36.svg";
import politikIconWeiss from "@/assets/Icons/f-politik-weiss-34.svg";
import religionIconWeiss from "@/assets/Icons/f-religion-weiss-39.svg";
import sportIconWeiss from "@/assets/Icons/f-sport-weiss-38.svg";
import wirtschaftIconWeiss from "@/assets/Icons/f-wirtschaft-weiss-33.svg";

import colorfcd20a from '../../assets/Icons/lesezeichen-gelb-47.svg'
import colorf0b423 from '../../assets/Icons/lesezeichen-orange-48.svg'
import colorff6941 from '../../assets/Icons/lesezeichen-rot-49.svg'
import color91c364 from '../../assets/Icons/lesezeichen-hellgrün-50.svg'
import color91c887 from '../../assets/Icons/lesezeichen-mintgrün-51.svg'
import colord7875f from '../../assets/Icons/lesezeichen-braun-52.svg'
import colorebaabe from '../../assets/Icons/lesezeichen-rosa-53.svg'
import color9b91c8 from '../../assets/Icons/lesezeichen-lila-54.svg'
import color6ec3dc from '../../assets/Icons/lesezeichen-blau-55.svg'
import color9bcdc3 from '../../assets/Icons/lesezeichen-minze-56.svg'
import colorb2b2b2 from '../../assets/Icons/lesezeichen-hellgrau-43.svg'
import color707070 from '../../assets/Icons/lesezeichen-dunkelgrau-44.svg'

import beitretenMetacom from '../../assets/METACOM/Stundenplan/unterricht.png'
import hausaufgabenMetacom from '../../assets/METACOM/Hausis/hausaufgaben.png'
import toDoMetacom from '../../assets/METACOM/Hausis/machen.png'
import doneMetacom from '../../assets/METACOM/Hausis/beenden.png'
import correctedMetacom from '../../assets/METACOM/Hausis/Korrektur.png'
import herunterladenMetacom from '../../assets/METACOM/Hausis/speichern.png'
import hochladenMetacom from '../../assets/METACOM/Hausis/fotoapparat.png'
import loeschenMetacom from '../../assets/METACOM/Allgemein/loeschendaten.png'

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import ProgressBar from "@/components/ProgressBar";
import NameFile from "@/components/NameFile";
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";
import AudioUpload from "../AudioUpload";
import VideoInVideo from "@/components/Utils/VideoInVideo";
import FileWidgetSelectionDialog from "@/components/Utils/FileWidgetSelectionDialog";
import VideoTutorial from "../../components/Tutorial/VideoTutorial.vue";

export default {
    name: "FachSchueler",
    components: {
        VideoInVideo,
        AudioUpload,
        NameFile,
        ProgressBar,
        FileWidgetSelectionDialog,
        VideoTutorial,
    },
    props: {
        appointment: {required: false, default: null},
        requestAppointments: {required: true},
        isRunning: {type: Boolean, required: false, default: false},
        isOpen: {type: Boolean, required: false, default: false},
        isJoinable: {type: Boolean, required: true, default: false},
        planVollbild: {required: false, default: false},
        tabOrder: {required: false, default: 0},
        tabOrderPopup: {required: false, default: 0},
        previousAppointment: {required: false, default: null},
        followingAppointment: {required: false, default: null},
        leftAppointment: {required: false, default: null},
        rightAppointment: {required: false, default: null},
        person: { default: null },
    },
    data() {
        return {
            sanitizeHtml,

            subtitleURL: null,
            noSubtitle: false,
            menu: false,
            thisAppointment: null,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false
            },
            showPupilUploadProgress: false,
            pupilUploadProgress: 0.0,
            pupilXmlHttpRequest: null,
            showNameFile: false,
            uploadFileName: '',
            fileNameCallback: () => {
            },
            videoUrl: null,
            audioUrl: null,
            primaryVideoUrl: null,
            secondaryVideoUrl: null,
            uploadLoadings: [],
            uploadLoadingsPuipl: [],

            bbbTutState: null,
            bbbTut: false,
            showTextTimeout: null,

            urls: [],

            supFormats: false,      //supported formats tooltip next to Hochladen-btn

            deleteDialog: false,
            elementToDelete: null,
            moveDialog: false,
            elementToMove: null,

            sehen: false,
            hoeren: false,
            motorik: false,

            //upload files from FileWidget
            me: null,
            fileWidgetUploadDialog: false,
            privateFolderFull: [],  //Full are the complete arrays
            subjectFolderFull: [],
            privateFolder: [],      //these are the arrays that are being displayed
            subjectFolder: [],      //and thus they may be sliced to show only
            privateFolderSliced: true,
            subjectFolderSliced: true,  //reference if folder-array is sliced currently
            loadFolders: false,

            alreadyEditedDialog: false,
            alreadyEditedUpload: null,

            //Icons
            beitretenKameraIcon,
            schliesenIcon,
            buchzeichenIcon,
            lautsprecherIcon,
            stiftIcon,
            bearbeitenIcon,
            bearbeitetIcon,
            offenIcon,
            runterladenIcon,
            hochladenIcon,
            rechtsIcon,
            lesezeichenIcon,
            materialIcon,
            abgabenIcon,
            arrowDown,
            paperclipIcon,
            leeresBlatt,
            kariertesBlatt,
            liniertesBlatt,
            infoIcon,
            previewIcon,
            fasFaEye,
            mikrofonIcon,
            kameraIcon,
            videoIcon,
            exclamationIcon,
            papierkorbIcon,
            fertigIcon,
            dateiIcon,
            neuesABIcon,
            herunterladenIcon,

            bioIcon,
            chemieIcon,
            deutschIcon,
            englischIcon,
            erdkundeIcon,
            geschichteIcon,
            matheIcon,
            musikIcon,
            physikIcon,
            politikIcon,
            religionIcon,
            sportIcon,
            wirtschaftIcon,

            bioIconWeiss,
            chemieIconWeiss,
            deutschIconWeiss,
            englischIconWeiss,
            erdkundeIconWeiss,
            geschichteIconWeiss,
            matheIconWeiss,
            musikIconWeiss,
            physikIconWeiss,
            politikIconWeiss,
            religionIconWeiss,
            sportIconWeiss,
            wirtschaftIconWeiss,

            colorfcd20a,
            colorf0b423,
            colorff6941,
            color91c364,
            color91c887,
            colord7875f,
            colorebaabe,
            color9b91c8,
            color6ec3dc,
            color9bcdc3,
            colorb2b2b2,
            color707070,

            beitretenMetacom,
            hausaufgabenMetacom,
            toDoMetacom,
            doneMetacom,
            correctedMetacom,
            herunterladenMetacom,
            hochladenMetacom,
            loeschenMetacom,

            // Show video Tutorial is set to true when button is clicked
            videoTutorial: false,
            showVideoTutorial: false,
        };
    },
    computed: {
        ...mapState("auth", ["token"]),
        ...mapState('translation', ['targetLang']),
        ...mapState("speechControl", ["word"]),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        ...mapState('arrowKeyControl', ['focusId']),
        ...mapState('util', ['keyboard', 'windowWidth']),
        ...mapGetters("auth",["accountRole"]),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),

        getAppropriateTeacherDisplayName() {
            let prefix = '';

            if (this.thisAppointment.teacher.gender === 'm') {
                prefix = 'Hr. ';
            } else if (this.thisAppointment.teacher.gender === 'w') {
                prefix = 'Fr. ';
            }

            let name = '';
            if (this.thisAppointment.teacher.name.length > 0) {
                name = this.thisAppointment.teacher.name.slice(0, 1) + '. ';
            }

            const lastName = this.thisAppointment.teacher.lastName;

            return prefix + name + lastName;
        },

        getTimeString() {
            let startObj = this.thisAppointment.timeslot;
            /*
            let start = startObj.toISOString();
            */

            return startObj;
        },
    },
    watch: {
        word(newVal) {
            if (newVal === 'starte unterricht' && this.isRunning) {
                this.joinBBB();
            }
        },
        appointment() {
            this.thisAppointment = this.appointment;
            this.loadImagePreviews();
        },
        async menu(newVal, oldVal) {
            if (newVal && !oldVal && this.thisAppointment) {
                await this.$router.replace({ query: { appointment: this.thisAppointment._id } });
            } else if (!newVal && oldVal) {
                await this.$router.replace({ query: { appointment: undefined } });
            }
        },
        isOpen() {
            if (!this.menu) {
                this.menu = this.isOpen;
                this.$router.replace({
                    query: {
                        appointment: undefined,
                    }
                })
            }
        },
        focusId() {
            if(this.appointment && this.focusId === this.appointment._id) {
                if(this.planVollbild) {
                    document.getElementById(this.appointment._id + '-fullscreen').focus();

                } else {
                    document.getElementById(this.appointment._id).focus();
                }
            }
        },
    },
    created() {
        this.checkTutorialState();
    },
    mounted() {
        this.checkTutorialState();
        this.thisAppointment = this.appointment;
        this.createUploadLoadArray();
        this.requestMe();
        this.loadImagePreviews();
        this.videoTutorial = this.videoTutorialsByFilename['Normal_bbb_in_3Min_erklaert.mp4'];
        window.addEventListener("keyup", this.handleKeyUp);
    },
    beforeDestroy() {
        window.removeEventListener("keyup", this.handleKeyUp);
    },
    methods: {
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        ...mapActions("util", ['toggleLoading', 'toggleOpenAppointment', 'toggleOpenTeacherUploadId', 'toggleCurrentUploadGroup']),
        ...mapActions('files', ['setSnackbar']),
        ...mapActions("tooltips", ["triggerShortcutTooltips"]),
        ...mapActions('pupils', ['getMePupil']),
        ...mapActions("arrowKeyControl", ["setFocusId"]),
        ...mapActions('appointments', [ 'postPupilUploadViaFileId', 'getAppointments' ]),
        ...mapMutations("snackbar", ["showSnackbar"]),

        openAlreadyEditedDialog(upload) {
            this.alreadyEditedUpload = upload;
            this.alreadyEditedDialog = true;
        },

        alreadyEditedByMe(upload) {
            if(upload.editLeafs.length > 0 && this.thisAppointment.pupilUpload.length > 0) {
                const editors = upload.editLeafs.map((leaf) => {
                    const pupilUpload = this.thisAppointment.pupilUpload.find(pupilUpload => pupilUpload._id === leaf);
                    if(pupilUpload && pupilUpload.uploader) {
                        return pupilUpload.uploader;
                    }
                })
                return editors.some(account => account === this.person.account);
            }
            return false;
        },

        onVideoTutorialClose() {
            this.videoTutorial = false;
            this.joinBBB();
        },
        async requestMe() {
            const role =  this.accountRole;
            if(role === 'pupil') {
                this.me = await this.getMePupil();
                this.sehen = this.me.accessibility ? this.me.accessibility.sehen : false;
                this.hoeren = this.me.accessibility ? this.me.accessibility.hoeren : false;
                this.motorik = this.me.accessibility ? this.me.accessibility.motorik : false;
            } else {
                this.sehen = false;
                this.hoeren = false;
                this.motorik = false;
                this.setGlobalButtonContrast('none', 'none');
            }
        },

        handleKeyUp() {
            if (event.altKey && event.key === 's' && this.isRunning) {
                this.openFachDialog();
                this.triggerShortcutTooltips();
            }
            if(event.altKey && event.key === 'b' && this.isRunning) {
                this.joinBBB();
                this.triggerShortcutTooltips();
            }
        },
        async loadImagePreviews() {
            if (this.thisAppointment && this.thisAppointment.teacherUploads) {
                this.thisAppointment.teacherUploads.forEach(async (file, index) => {
                    try {
                        let res;
                        res = await backend.getTeacherUploadThumbnail(this.thisAppointment._id, file.file);
                        if (res.ok && res.status === 200) {
                            let blob = await res.blob();
                            this.urls[file._id] = window.URL.createObjectURL(blob);
                        }
                    } catch (e) {

                        console.error(e);
                    }
                });
            }
        },
        urlify(text) {
            // eslint-disable-next-line no-useless-escape
            var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|software|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
            return text.toString().replace(urlRegex, function (url) {
                url = url.includes('http') ? url : 'http://' + url;
                return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
            })
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        },
        checkTutorialState() {
            this.bbbTutState = JSON.parse(localStorage.getItem('bbbTut'));
            if (this.bbbTutState) {
                this.bbbTut = false;
            }
        },
        getSrc() {
            return this.thisSubject ? this[this.thisSubject.icon] : (this.thisAppointment ? this[this.thisAppointment.schoolSubject.icon] : null);
        },

        getSrcWhite() {
            /**
             * Cant be done like the top one because the second part of string doesnt mathc with the normal icon, so a search is probably needed
             */
            let temp = this.thisSubject
                ? this[this.thisSubject.icon]
                : this.thisAppointment
                    ? this[this.thisAppointment.schoolSubject.icon]
                    : null;
            let searchString = temp.slice(0, temp.length - 16) + "-weiss";
            let returnString = '';

            for (let i = 0; i < Object.keys(this.$data).length; i++) {
                let key = Object.keys(this.$data)[i];
                if (this.$data[key] && this.$data[key].includes && this.$data[key].includes(searchString)) {
                    returnString = this.$data[key];
                    break;
                }
            }

            return returnString.length > 0 ? returnString : temp;
        },

        getColorIcon() {
            return this.thisSubject ? this['color' + this.thisSubject.color.substring(1)] : (this.thisAppointment ? this['color' + this.thisAppointment.schoolSubject.color.substring(1)] : this.colorb2b2b2);
        },
        whitenIconBasedOnBackground(backgroundColor) {

            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(backgroundColor);
            const rgb = [];
            rgb[0] = parseInt(result[1], 16);
            rgb[1] = parseInt(result[2], 16);
            rgb[2] = parseInt(result[3], 16);
            const brightness = Math.round(((parseInt(rgb[0]) * 299) +
                (parseInt(rgb[1]) * 587) +
                (parseInt(rgb[2]) * 114)) / 1000);
            // return (brightness < 160);
            return true;
        },

        /*Hintergrund-Farbe für Icon wenn es aktuell ist*/
        getBackgroundColor() {
            let returnString = "background-color: ";
            return returnString + this.thisAppointment.schoolSubject.color;
        },

        getTimeslotTimes() {
            const timeslot = this.$parent.$parent.slots.find((item) => item._id === this.thisAppointment.timeslot);

            if (timeslot) {
                return (
                    ("0" + new Date(timeslot.start).getHours()).slice(-2) + ":" + ("0" + new Date(timeslot.start).getMinutes()).slice(-2)
                    + ' - ' +
                    ("0" + new Date(timeslot.end).getHours()).slice(-2) + ":" + ("0" + new Date(timeslot.end).getMinutes()).slice(-2));
            }
        },
        async pupilUploadInputChange(appointmentId) {
            const input = this.$refs.pupilUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf('.');
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.uploadFile(appointmentId, newFileName, fileExtension);
                };
                this.showNameFile = true;
            }
        },

        async uploadFile(appointmentId, newFileName, fileExtension, file) {
            if (!file) {
                const input = this.$refs.pupilUploadInput;
                file = input.files[0];
            }
            if (file) {
                if (file.size > 1000000000) {
                    this.showSnackbar({ message: "Datei zu groß, max. 1 GB" });
                    return;
                }

                const formData = new FormData();
                formData.append('file', file, `${newFileName}.${fileExtension}`);

                this.pupilXmlHttpRequest = backend.postPupilUpload(appointmentId, file);

                this.pupilXmlHttpRequest.onerror = (e) => {
                    console.error('Pupil upload error:', e)
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.pupilUploadInput.value = '';
                };

                this.pupilXmlHttpRequest.onabort = (e) => {
                    console.warn('Pupil upload aborted');
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.pupilUploadInput.value = '';
                }

                this.pupilXmlHttpRequest.upload.addEventListener('progress', (e) => {
                    this.pupilUploadProgress = (e.loaded / e.total) * 100;
                });

                this.pupilXmlHttpRequest.addEventListener('load', (e) => {
                    if (this.pupilXmlHttpRequest.status !== 201)
                        console.error('Pupil upload failed:', this.pupilXmlHttpRequest.response);
                    this.showPupilUploadProgress = false;
                    this.showSnackbar({ message: "Gespeichert!"});
                    this.pupilXmlHttpRequest = null;
                    this.$refs.pupilUploadInput.value = '';
                    this.requestAppointments(true);
                });
                this.pupilUploadProgress = 0.0;
                this.showPupilUploadProgress = true;
                this.pupilXmlHttpRequest.send(formData);
            }
        },

        //method that sends request to backend
        async uploadViaFileWidget(file) {

            let data = {
                appointmentId: this.thisAppointment._id,
                fileName: file.uploadedName,
                fileId: file._id,
            };
            let res = await this.postPupilUploadViaFileId(data);
            if (res === true) {
                this.fileWidgetUploadDialog = false;
                this.showSnackbar({ message: "Gespeichert", color: 'success' });
            }

            await this.requestAppointments(true);
        },

        clickPupilUploadButton() {
            this.toggleOpenAppointment(this.thisAppointment);
            this.toggleCurrentUploadGroup('pupil');
            document.getElementById("uploadInput").click();
        },

        pupilUploadFileAsAnswer(item) {

            try {
                // To make sure seen by me is also set in backend
                backend.getTeacherUpload(this.thisAppointment._id, item.file);

                // Normal upload stuff
                this.toggleOpenAppointment(this.thisAppointment);
                this.toggleCurrentUploadGroup('baby');
                this.toggleOpenTeacherUploadId(item.file);
                document.getElementById("uploadInput").click();
            } catch (e) {
                console.warn(e);
            }
        },

        async clickDownloadTeacherUpload(file) {
            try {
                file.seenByMe = true;
                const res = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function(){
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            }catch (e) {
                console.error(e);
            }
        },

        async clickDeleteUpload() {
            let file = this.elementToDelete;
            const res = await backend.deleteUpload((file._id).toString());

            if (res.status === 204) {
                this.showSnackbar({ message: `Datei ${file.title} erfolgreich gelöscht` });
                this.deleteDialog = false;
            } else {
                this.showSnackbar({ message: "Beim Löschen ist ein Fehler aufgetreten", color: "error" });
                this.deleteDialog = false;
            }
            this.elementToDelete = null; //clear temp reference to file
            this.deleteDialog = false;
            // Update the appointments
            await this.requestAppointments(true);
        },

        async playAudio(file, index) {
            try{
                this.uploadLoadings[index] = true;
                file.seenByMe = true;
                const res = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
                let blob = await res.blob();
                this.audioUrl = window.URL.createObjectURL(blob);
                this.uploadLoadings[index] = false;
            }catch (e) {
                console.error(e);
            }
        },

        async playAudioPupil(file, index) {
            try {
                this.uploadLoadingsPuipl[index] = true;
                file.seenByMe = true;
                const res = await backend.getPupilUpload(this.thisAppointment._id, file.file);
                let blob = await res.blob();
                this.audioUrl = window.URL.createObjectURL(blob);
                this.uploadLoadingsPuipl[index] = false;
            }catch (e) {
                console.error(e);
            }
        },

        async play(file, index) {
            this.uploadLoadings[index] = true;
            file.seenByMe = true;
            let pipFile = null;
            if (file.pipReference)
                pipFile = this.thisAppointment.teacherUploads.find(value => value._id === file.pipReference);

            console.dir(this.thisAppointment.teacherUploads);
            if(file.subtitle) {
                const subtitle = await backend.getSubtitle(file.subtitle);
                this.subtitleURL = window.URL.createObjectURL(await subtitle.blob());
            } else {
                this.noSubtitle = true;
            }
            if (pipFile) {
                // PiP Video
                const primary = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
                const secondary = await backend.getTeacherUpload(this.thisAppointment._id, pipFile.file);
                this.primaryVideoUrl = window.URL.createObjectURL(await primary.blob());
                this.secondaryVideoUrl = window.URL.createObjectURL(await secondary.blob());
                this.uploadLoadings[index] = false;
            } else {
                const res = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
                this.uploadLoadings[index] = false;
                this.videoUrl = window.URL.createObjectURL(await res.blob());
            }
        },

        async playPupil(file, index) {
            try {
                this.uploadLoadingsPuipl[index] = true;
                file.seenByMe = true;
                const res = await backend.getPupilUpload(this.thisAppointment._id, file.file);
                let blob = await res.blob();
                this.videoUrl = window.URL.createObjectURL(blob);
                this.uploadLoadingsPuipl[index] = false;
            }catch (e) {
                console.error(e);
            }
        },

        async clickDownloadPupilUpload(file) {
            try {
                const res = await backend.getPupilUpload(this.thisAppointment._id, file.file);
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            }catch (e) {
                console.error(e);
            }
        },

        isCorrectionAvailable(pupilFile) {
            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.thisAppointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },

        getCurrentCorrection(pupilFile) {
            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.thisAppointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        return this.thisAppointment.teacherUploads[i];
                    }
                }
            }
            return null;
        },

        async clickDownloadPupilCorrection(file) {
            let teacherCorrection = null;

            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = file.editLeafs.indexOf(this.thisAppointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        teacherCorrection = this.thisAppointment.teacherUploads[i];
                    }
                }
            }

            if (teacherCorrection) {
                teacherCorrection.seenByMe = true;
                try {
                    const res = await backend.getTeacherUpload(this.thisAppointment._id, teacherCorrection.file);
                    let blob = await res.blob();
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = teacherCorrection.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                }catch (e) {
                    console.error(e);
                }
            }
        },
        //startBBBTut
        startBBB() {
            /*emit to PlanWidget*/
            this.$emit('pass-on-bbb-tut', this.joinBBB);
        },

        async clickBeitreten() {
            if (!this.isJoinable) {
                this.showSnackbar({ message: "Dieser Raum ist abgeschlossen.", color: "error"})
                return;
            }
            this.menu = false;

            if (!this.bbbTutState) {
                this.startBBB();
            } else if (this.bbbTutState) {
                await this.joinBBB();
            }
        },

        async joinBBB() {
            const res = await backend.joinSchoolSubjectAppointmentBbbMeetingUrl(
                this.thisAppointment._id
            );
            this.toggleLoading(true);
            const {redirectUrl} = await res.json();
            if (res.status === 422) {
                this.showSnackbar({ message: "Aktuell ist der Raum geschlossen!", color: "error" });
                this.toggleLoading(false);
                return;
            }
            if (this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                this.toggleLoading(false);
                return;
            }

            window.location.href = redirectUrl;
            this.toggleLoading(false);
        },

        async greet(event) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return
            }

            let text = this.thisAppointment.note;
            if (this.thisAppointment.note && this.thisAppointment.note !== '') {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = this.thisAppointment.note;
                text = 'Info: ' + tmp.textContent || tmp.innerText || "";
            } else {
                text = 'Info: Bitte bereitet schonmal eure Unterlagen vor! Und ladet gegebenfalls vorhandene Materialien herunter.';
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                event.target.src = lautsprecherIcon;
            } else {
                if (this.targetLang !== 'de') {
                    text = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: text
                    });

                    this.setTranslatedText(text);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)
                }

                event.target.src = lautsprecherAusIcon;
                let msg = new SpeechSynthesisUtterance();
                msg.text = text;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                } else {
                    event.target.src = lautsprecherIcon;
                }

                msg.onend = function () {
                    event.target.src = lautsprecherIcon;
                }
            }
        },
        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
        fileNotSupported(el) {
            this.showSnackbar({ message: "Dieses Dateiformat kann in eKlara nicht bearbeitet werden. Bitte lade die Datei herunter.", color: "error"})
        },
        openEdit(name, id, uploadId) {
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;

            this.$router.push({
                name: 'editor',
                params: { mode: 'pupil' },
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: name,
                        fId: id,
                        uploadid: uploadId
                    }
            });
        },

        handleOpenAnsichtClick(element) {
            if (this.isCorrectionAvailable(element)) {
                const fileToOpen = this.getCurrentCorrection(element);

                if (fileToOpen) {
                    this.openAnsicht(fileToOpen.title, fileToOpen.file, fileToOpen._id, 'pupil');
                }
            } else {
                this.openAnsicht(element.title, element.file, element._id, 'teacher')
            }
        },

        openAnsicht(name, id, uploadId, userGroup) {
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;

            this.$router.push({
                name: 'editor',
                params: { mode: 'viewer' },
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: name,
                        fId: id,
                        uploadid: uploadId,
                        ug: userGroup
                    }
            });
        },
        openEmptyEdit(newPageType) {
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;

            this.$router.push({
                name: 'editor',
                params: { mode: 'pupil' },
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: group + ' ' + new Date().toLocaleDateString(),
                        pagetype: newPageType,
                    }
            });
        },

        hideEl(element) {
            let x = [];
            x = document.getElementsByClassName(element);
            for (let i = 0; i < x.length; i++) {
                x[i].style.display = "none";
            }
        },

        showEl(element) {
            let x = [];
            x = document.getElementsByClassName(element);
            for (let i = 0; i < x.length; i++) {
                x[i].style.display = "block";
            }
        },

        resetDialogContent() {
            this.hideEl('matLong');
            this.showEl('matShort');
            this.hideEl('toCorrectLong');
            this.showEl('toCorrectShort');
            this.hideEl('toDoLong');
            this.showEl('toDoShort');
            this.hideEl('correctedLong');
            this.showEl('correctedShort');
        },

        createUploadLoadArray() {
            if (this.thisAppointment) {
                for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                    for (let k = 0; k < this.thisAppointment.teacherUploads[i].isMoved.length; k++) {
                        let upload = this.thisAppointment.teacherUploads[i];
                        if(upload.isMoved[k] === this.person.account
                            && !this.thisAppointment.pupilUpload.includes(upload)) {
                            this.thisAppointment.pupilUpload.push(upload);
                        }
                    }
                    this.uploadLoadings[i] = false;
                }
                for (let i = 0; i < this.thisAppointment.pupilUpload.length; i++) {
                    this.uploadLoadingsPuipl[i] = false;
                }
            }
        },

        //used for supFormats tooltip
        closeDialog() {
            this.supFormats = false;
        },
        include() {
            return [document.querySelector('.included')]
        },
        async openFachDialog() {
            this.menu = true;
            await this.$nextTick();
            document.getElementById("fachName-" + this.thisAppointment._id).focus();

            // setTimeout(() => {
            //     let el = document.getElementById("fachName-" + this.thisAppointment._id);
            //     el.focus();
            //
            // }, 500);
        },
        async moveUploadToDone(file) {
            file.seenByMe = true;
            await backend.getPupilUpload(this.thisAppointment._id, file.file);
            backend.postTeacherUploadMove(this.appointment._id, file._id);
            this.thisAppointment.pupilUpload.push(file);
            const index = this.thisAppointment.teacherUploads.indexOf(file);
            if(index > -1) {
                this.thisAppointment.teacherUploads.splice(index, 1);
            }
            this.elementToMove = null;
            this.moveDialog = false;
        },
        wasMovedByMe(teacherupload) {
            if(teacherupload.isMoved) {
                for (let i = 0; i < teacherupload.isMoved.length; i++) {
                    if (teacherupload.isMoved[i] === this.person.account) {
                        return true;
                    }
                }
            }
            return false;
        },
        keyArrowDown(enterBBB) {
            if(this.followingAppointment && this.followingAppointment[0] && !this.keyboard) {
                this.setFocusId(this.followingAppointment[0]._id);
            } else if(this.followingAppointment && this.followingAppointment[0] && enterBBB && !this.keyboard) {
                this.setFocusId(this.followingAppointment[0]._id);
            }
        },
        keyArrowUp(enterBBB) {
            if(this.previousAppointment && this.previousAppointment[0] && !this.keyboard) {
                this.setFocusId(this.previousAppointment[0]._id);
            } else if(this.previousAppointment && this.previousAppointment[0] && enterBBB && !this.keyboard) {
                this.setFocusId(this.previousAppointment[0]._id);
            }
        },
        keyArrowLeft(enterBBB) {
            if(!this.keyboard) {
                if (this.leftAppointment && this.leftAppointment[0] && !enterBBB) {
                    this.setFocusId(this.leftAppointment[0]._id);
                } else if (enterBBB) {
                    this.setFocusId(this.appointment._id);
                } else if (new Date(this.appointment.day).getDay() === 1 && this.planVollbild) {
                    this.setFocusId(null);
                    this.$emit("focusDateFullscreen");
                } else {
                    this.setFocusId(null);
                    this.$emit("focusDate");
                }
            }
        },
        keyArrowRight(enterBBB) {
            if(!this.keyboard) {
                if (this.rightAppointment && this.rightAppointment[0] && !this.isRunning) {
                    this.setFocusId(this.rightAppointment[0]._id);
                } else if (this.isRunning && !enterBBB) {
                    this.setFocusId(null);
                    if (this.planVollbild) {
                        document.getElementById('enterBBB-fullscreen').focus();
                    } else {
                        document.getElementById('enterBBB').focus();
                    }
                } else if (this.rightAppointment && this.rightAppointment[0] && enterBBB) {
                    this.setFocusId(this.rightAppointment[0]._id);
                }
            }
        },
        async setFocusBack() {
            await this.$nextTick();
            document.getElementById(this.thisAppointment._id).focus();
        }
    }
};
</script>

<style>
.ql-container {
    font-size: 16px !important;
    padding: 12px !important;
}

.ql-editor {
    padding: 0 !important;
    outline: revert !important;
}
</style>

<style lang="scss" scoped>
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 6em !important;
}

table tr th,
table tr td {
    width: 9em;
}

table,
tr,
td {
    border: none;
}

.fach {
    cursor: pointer;
}

.tinyIcon {
    width: 15px;
}

.iconSize {
    height: 20px;
}

.iconToWhite {
    filter: brightness(1000%);
}

.squareBtn {
    min-width: 36px !important;
    min-height: 36px !important;
    width: 36px !important;
    height: 36px !important;
}

.appointmentItem {
    position: relative;
    color: var(--v-dunkelgrau-base);
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FFF;
    width: 100%;
    height: 100%;
}

.inactiveAppointment {
    opacity: 0.4 !important;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: 200px;
}

.scroll-area2 {
    position: relative;
    margin: auto;
    max-height: 100px;
}

.scroll-area3 {
    position: relative;
    margin: auto;
    max-height: 50px;
}

.scroll-area-fachPopup {
    position: relative;
    margin: auto;
    max-height: calc(65vh - 30px);
}

.buttonIcon {
    align-self: start;
    background-color: var(--v-hellgrau-base)cc;
    padding: 2px;
    border-radius: 3px;
    margin-right: 1em;
}

//border-radius of v-menu
.v-menu__content {
    border-radius: 12px;
}

.iconSelect {
    display: inline-flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: rgba(234, 234, 234, 0.7);
}

.iconSelectFrei {
    display: inline-flex;
    width: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: transparent;
}

.smallFach {
    position: relative;
    color: var(--v-dunkelgrau-base);
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #ffffff;
}

.isFachRunning {
    color: white;
    background-color: #8cbd46 !important;
}

.isFachRunningIcon {
    filter: brightness(1000%);
}

.eHr {
    width: 90%;
    margin: auto;
    border-width: 0;
    height: 2px;
    color: var(--v-hellgrau-base);
    background-color: var(--v-hellgrau-base);
}

.eButton {
    width: 33px !important;
    min-width: 33px !important;
    height: 33px !important;
    min-height: 33px !important;
    border-radius: 4px !important;
    box-shadow: 0 5px 10px #00000025 !important;
}

#createEditorABsButton {
    color: white;
    background-color: var(--v-dunkelgrau-base);
}

.btnStyle {
    min-width: 36px;
    min-height: 36px;
    margin-top: -3px;
}

.fileNameDiv {
    max-width: 85%;
    min-width: 85%;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

.fileName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.matLong, .uploadsLong {
    display: none;
}

.brighterFileRow {
    background-color: rgba(224, 224, 224, 0.15) !important;
}

.correctionAvailableClass {
    background-color: rgba(140, 190, 70, 0.30) !important;
}

@media only screen and (max-width: 399px) {
    #fachPopup {
        max-width: 100vw !important;
    }

    #dialog {
        margin: 0;
    }
}

@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    .scroll-area-fachPopup {
        /*250px = 59px header, 48px join btn, 70px btmNavBar and paddings,margins*/
        max-height: calc(100vh - 250px);
    }
}

.small-drop-down-menu {
    display: block;
    background-color: #F8F8F8;

    & button {
        width: 100%;
    }
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}

/* width */
::-webkit-scrollbar {
  width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background: #999;
  border-radius: 6px;
}
</style>
