<template>
    <div>
        <div
            id="editorLibBarContainer"
            class="d-flex flex-column justify-space-between"
            style="align-items: flex-end"
        >
            <!-- Comment Containers -->
            <div
                class="d-flex flex-column justify-start"
                style="align-items: flex-end"
                aria-hidden="true"
            >
                <!-- Voice Note button  -->
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            aria-label="Sprachnachricht"
                            elevation="3"
                            x-small
                            class="ml-3 my-1 sidebarButton"
                            :style="windowWidth < 360 ? 'height: 40px !important; width: 40px !important; margin-bottom: 9px !important' : ''"
                            :disabled="previewActive"
                            tabindex="1"
                            v-on="on"
                            @click="() => $emit('addAudioMessage')"
                        >
                            <img
                                height="35px"
                                :src="audioIcon"
                            >
                        </v-btn>
                    </template>
                    <span>Sprachnachricht</span>
                </v-tooltip>
                <!-- Text Note button  -->
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            aria-label="Textnachricht"

                            elevation="3"
                            x-small
                            class="ml-3 my-1 sidebarButton"
                            :style="windowWidth < 360 ? 'height: 40px !important; width: 40px !important; margin-bottom: 9px !important' : ''"
                            :disabled="previewActive"
                            v-on="on"
                            @click="() => $emit('addTextMessage')"
                        >
                            <img
                                height="35px"
                                :src="textIcon"
                            >
                        </v-btn>
                    </template>
                    <span>Textnotiz</span>
                </v-tooltip>
            </div>
            <!-- Library Bar button  -->
            <div class="d-flex flex-column justify-start mb-10">
                <v-tooltip
                    v-if="isLibraryAccessible"
                    left
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            id="libraryActivator"
                            :disabled="previewActive"
                            aria-label="Bibliothek"

                            elevation="3"
                            x-small
                            color="dunkelgrau"
                            class="ml-3 my-1 sidebarButton"
                            v-on="on"
                            @click="() => { showLibrary = !showLibrary; $emit('opened') }"
                        >
                            <img
                                height="35px"
                                :src="libraryBarIcon"
                            >
                        </v-btn>
                    </template>
                    <span>Bilder-Bibliothek</span>
                </v-tooltip>
            </div>

            <!-- Change pages and Add Page Button -->
            <div style="align-items: flex-end !important;">
                <div id="addPageContainer">
                    <div style="display: flex; align-items: stretch;">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="changePageBtn"
                                    style="border-top-left-radius: 8px; border-bottom-left-radius: 8px;"
                                    :style="currentPageIndex - 1 < 0 ? 'color: grey' : ''"
                                    aria-label="vorherige Seite"
                                    :x-small="windowWidth < 345"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="() => { if((currentPageIndex - 1) >= 0) { $emit('changePage', -1) }}"
                                >
                                    <v-icon large>
                                        mdi-chevron-left
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Vorherige Seite</span>
                        </v-tooltip>

                        <div style="background-color: white; color: grey; font-size: large; display: flex; align-items: center">
                            <p
                                id="pageCount"
                                style="font-weight: normal"
                                aria-hidden="true"
                            >
                                Seite {{ currentPageIndex + 1 }}/{{ pageCount }}
                            </p>
                            <p class="visually-hidden">
                                Seite {{ currentPageIndex + 1 }} von {{ pageCount }}
                            </p>
                        </div>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="changePageBtn"
                                    style="border-top-right-radius: 0; border-bottom-right-radius: 0; margin-right: 3px;"
                                    :style="currentPageIndex + 1 >= documentPageCount ? 'color: grey' : ''"
                                    aria-label="nächste Seite"
                                    :x-small="windowWidth < 345"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="() => { if((currentPageIndex + 1) < documentPageCount) { $emit('changePage', 1) }}"
                                >
                                    <v-icon large>
                                        mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Nächste Seite</span>
                        </v-tooltip>

                        <v-tooltip
                            v-if="!isViewerOpen"
                            left
                        >
                            <template v-slot:activator="{ on: menu }">
                                <v-menu
                                    v-if="!isViewerOpen"
                                    v-model="addPageMenu"
                                    left
                                    nudge-left="75"
                                    nudge-top="345"
                                    transition="scale-transition"
                                    :close-on-content-click="false"
                                    :attach="'#addPageContainer'"
                                    max-width="unset"
                                >
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn
                                            aria-label="Seiten hinzufügen"
                                            style="box-shadow: none; background-color: white; border-top-left-radius: 0px; border-bottom-left-radius: 0px"

                                            elevation="0"
                                            x-small
                                            class="sidebarButton"
                                            :class="{ 'sidebarButtonActive': addPageMenu }"
                                            :disabled="previewActive"
                                            v-on="{ ...tooltip, ...menu }"
                                        >
                                            <img
                                                height="35px"
                                                :src="pageMenuIcon"
                                            >
                                        </v-btn>
                                    </template>
                                    <AddPageMenu
                                        :page-count="pageCount"
                                        @close="addPageMenu = false"
                                        @addAdditionalPage="(type) => $emit('addAdditionalPage', type)"
                                        @addCustomPage="(type) => $emit('addCustomPage', type)"
                                        @deleteActivePage="$emit('deleteActivePage')"
                                        @duplicateActivePage="$emit('duplicateActivePage')"
                                    />
                                </v-menu>
                            </template>
                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span aria-hidden="true">Seite hinzufügen: Leer, Liniert, Kariert, Haus</span>
                            </div>
                        </v-tooltip>
                    </div>
                </div>
            </div>

            <v-menu
                v-model="showLibrary"
                max-width="250px"
                nudge-left="40"
                absolute
                style="border-radius: 1em;"
                :close-on-content-click="false"
                :close-on-click="true"
                :attach="'#editorLibBarContainer'"
            >
                <div
                    style="width: 250px; background-color: rgba(100, 100, 100, 0.8); overflow-x: hidden; pointer-events: all"
                    class="d-flex flex-column pb-2"
                >
                    <div class="d-flex justify-space-between align-center">
                        <div
                            class="d-inline-flex justify-space-between align-center pa-1 ma-1"
                            style="background-color: var(--v-hellgrau-base); border-radius: 5px; width: 82.5%"
                        >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        :disabled="!allowPrev"
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="prevSelection()"
                                    >
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                </template>
                                <span v-if="allowPrev">Arbeitsblätter</span>
                            </v-tooltip>

                            <p>{{ `${currentLibrary === 'Worksheets' ? 'ABs' : 'Grafiken'}` }}</p>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        :disabled="!allowNext"
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="nextSelection()"
                                    >
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </template>
                                <span v-if="allowNext">Grafiken</span>
                            </v-tooltip>
                        </div>

                        <v-btn
                            icon
                            small
                            class="mr-1"
                            @click="() => { showLibraryFullscreen = !showLibraryFullscreen }"
                        >
                            <img
                                style="filter: brightness(1000%)"
                                :src="vollbildIcon"
                                alt="Wechsel zur Vollbild-Bibliothek"
                            >
                        </v-btn>
                    </div>
                    <EditorDocumentRegistry
                        v-if="currentLibrary === 'Worksheets'"
                        @onAddDocFromLibrary="(data) => $emit('onAddDocFromLibrary', data)"
                    />
                    <EditorImageLibrary
                        v-else
                        @addImageField="(src, blob) => $emit('addImageField', src, blob)"
                    />
                </div>
            </v-menu>
        </div>
        <!-- Library Bar (own Component) -->
        <EditorLibraryBrowser
            v-if="showLibraryFullscreen"
            :activator="showLibraryFullscreen"
            :mode="mode"
            :previously-open="currentLibrary === 'Worksheets' ? 0 : 1"
            @close="() => { showLibraryFullscreen = false; }"
            @onAddDocFromLibrary="(data) => $emit('onAddDocFromLibrary', data)"
            @addImageField="(src, blob) => $emit('addImageField', src, blob)"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import EditorDocumentRegistry from "./EditorDocumentRegistry/EditorDocumentRegistry.vue";
import EditorImageLibrary from "./EditorImageLibrary/EditorImageLibrary.vue";
import AddPageMenu from "@/components/Editortools/Toolbar/Menus/AddPageMenu";
import libraryBarIcon from '@/assets/Icons/EditorRedesign/bibliothek.svg';
import audioIcon from '@/assets/Icons/mikrofon-filled-22.svg';
import textIcon from '@/assets/Icons/text-note.svg';
import pageMenuIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_neue_seite.svg'
import vollbildIcon from '@/assets/Icons/fullscreen.svg'
import EditorLibraryBrowser from "@/components/Editortools/Toolbar/EditorLibraryBar/EditorLibraryBrowser";

export default {
    name: 'DemoEditorLibraryBar',
    components: {
        EditorLibraryBrowser,
        EditorDocumentRegistry,
        EditorImageLibrary,
        AddPageMenu,
    },
    props: {
        mode: { required: true, type: String },
        previewActive: { required: true, type: Boolean },
        documentPageCount: { required: true, type: Number },
        currentPageIndex: { required: true, type: Number },
        pageCount: { required: true, type: Number },
        // Boolean to identify if teacher wants simpler UI in Creator
        simpleEditorForTeachers: { required: false, type: Boolean, default: false },
    },
    data: () => ({
        showLibrary: false,
        showLibraryFullscreen: false,

        currentLibrary: 'Worksheets',
        addPageMenu: false,

        //state
        allowPrev: false,
        allowNext: true,

        //Icons
        libraryBarIcon,
        audioIcon,
        textIcon,
        pageMenuIcon,
        vollbildIcon,
    }),
    computed: {
        ...mapState('util', ['windowWidth']),
        isLibraryAccessible() {
            return (this.mode === 'creator' || this.mode === 'teacher') && !this.simpleEditorForTeachers;
        },
        isViewerOpen() {
            return this.mode === 'viewer';
        },
    },
    methods: {
        nextSelection() {
            this.allowPrev = true;
            this.allowNext = true;
            this.librarySelection = [];
            if (this.currentLibrary === 'Worksheets') {
                this.currentLibrary = 'Graphics';
                this.allowNext = false;
            }
        },
        prevSelection() {
            this.allowPrev = true;
            this.allowNext = true;
            this.librarySelection = [];
            if (this.currentLibrary === 'Graphics') {
                this.currentLibrary = 'Worksheets';
                this.allowPrev = false;
            }
        }
    }
}
</script>

<style scoped lang="scss">
#editorLibBarContainer {
    position: fixed;
    right: 10px;
    bottom: 15px;
    z-index: 4;
    height: calc(var(--vh, 1vh) * 87.5);
    pointer-events: none;
}

#libraryActivator {
    z-index: 3;
    height: 150px !important;
    pointer-events: all;

    img {
        filter: brightness(1000%);
    }
}

#libraryActivator:disabled {
    img {
        filter: none;
    }
}

.sidebarButton {
    z-index: 3;
    width: 49px !important;
    height: 49px !important;
    border-radius: 8px;
    pointer-events: all;

    p {
        line-height: 0;
        font-size: 55px;
        margin-bottom: 0;
        font-weight: 300;
        color: #333;
    }
}

.changePageBtn {
    background-color: white !important;
    box-shadow: none;
    width: 30px;
    height: 49px !important;
    pointer-events: all;
    border-radius: 0;
}

.changePageBtn:focus, .changePageBtn:hover {
    background-color: #dbdbdb !important;
}

#pageCount {
    font-weight: bold;
    font-size: larger;
    margin-bottom: 0;
    text-align: center;
}
</style>
