<template>
    <div>
        <v-overlay
            v-if="widgetOpen"
            opacity="0.80"
            z-index="10"
            @click.native="toggleWidgetOpen"
        />
        <div
            id="container"
            :style="popupFileWidget ? 'z-index: 11' : 'z-index: 9'"
        >
            <v-tooltip
                :value="shortcutTooltips"
                :disabled="!showTooltips"
                bottom
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        id="FileWidgetButton"
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        x-small
                        elevation="0"
                        class="ml-4"
                        :class="widgetOpen ? 'widgetClicked' : ''"
                        v-on="on"
                        @click="toggleWidgetOpen"
                    >
                        <!-- <img
                                :src="fileIcon"
                                style="max-width: 25px; max-height: 25px;"
                                :class="widgetOpen ? 'widgetColored' : 'widgetColored'"
                                :alt="widgetOpen ? 'Dateien schließen' : 'Dateien öffnen'"
                            > -->
                        <v-icon
                            :class="widgetOpen ? 'widgetWhite' : 'widgetColored'"
                        >
                            mdi-folder
                        </v-icon>
                    </v-btn>
                </template>
                <div style="display: flex; flex-direction: column; text-align: center">
                    <span><span style="text-decoration: underline white">D</span>ateien</span>
                    <img
                        v-if="hoeren"
                        :src="chatMetacom"
                        width="100"
                        style="margin: auto"
                    >
                </div>
            </v-tooltip>
            <FileWidget
                v-show="widgetOpen"
                id="FileWidget"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

import chatMetacom from '../../assets/METACOM/Chat/chatchatten.png'

import FileWidget from "@/components/FileWidget";

export default {
name: "BabyFileWidgetDropDown",
    components: {
        FileWidget,
    },
    props: {
        popupFileWidget: {required: false, default: false},
    },
    data() {
        return {
            widgetOpen: false,
            sehen: false,
            hoeren: false,
            babyView: false,
            chatMetacom,
        };
    },
    computed : {
        ...mapState("speechControl", ["word"]),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        ...mapGetters("gamepad", { gamepadYButtonPressed: "yButtonPressed"}),
    },
    watch: {
        word(newVal) {
            if(newVal === 'öffne dateien') {
                this.widgetOpen = true;
            }
            if(newVal === 'schließe dateien') {
                this.widgetOpen = false;
            }
        },
        popupFileWidget(newVal) {
            if(newVal) {
                this.widgetOpen = newVal;
            }
        },
        // gamepadYButtonPressed(newVal, oldVal) {
        //     // on keyup of gamepadYButton
        //     if(oldVal === true && newVal === false) {
        //         this.toggleWidgetOpen();
        //     }
        // }
    },
    mounted() {
        this.requestBabyViewAndAccessibilityMode();
        window.addEventListener("keydown", this.handleKeyUp);
    },
    beforeDestroy() {
        window.removeEventListener("keydown", this.handleKeyUp);
    },
    methods: {
        ...mapActions("tooltips", ["triggerShortcutTooltips"]),
        ...mapActions("pupils", ["getMePupil"]),
        handleKeyUp(event) {
            if (event.altKey && event.key === 'c') {
                this.toggleWidgetOpen();
                this.triggerShortcutTooltips();
            }
        },
        async requestBabyViewAndAccessibilityMode() {
            let temp = await this.getMePupil();
            this.babyView = temp.babyView;
            if(temp.accessibility) {
                this.sehen = temp.accessibility.sehen;
                this.hoeren = temp.accessibility.hoeren;
            } else {
                this.sehen = false;
                this.hoeren = false;
            }
            //to check if they are babys
            if (this.babyView) {
                this.babyViewTut = true;
            }
        },
        toggleWidgetOpen() {
            if(!this.widgetOpen) {
                document.getElementById("FileWidgetButton").focus();
            }
            this.widgetOpen = !this.widgetOpen;
            if (this.widgetOpen) {
                this.$emit('openPopup');
            }
        },
    },
}
</script>

<style lang="scss" scoped>
#container {
    position: relative;
}

#FileWidget {
    position: absolute !important;
    top: 100%;
    right: 0;
    z-index: 1;
    width: 30vw;
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0.0);
    background: linear-gradient(
            to right,
            rgba(112, 112, 112, 0.2) 0%,
            rgba(112, 112, 112, 0.2) 77%,
            rgba(112, 112, 112, 0.5) 92%,
            rgba(112, 112, 112, 0.0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

@keyframes shine{
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.widgetClicked {
    background-color: #F49F31 !important;
}

.widgetColored {
    color: #F49F31 !important;
}

.widgetWhite {
    color: white !important;
}
</style>
