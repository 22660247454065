<template>
    <div class="d-flex justify-space-between">
        <v-dialog
            v-model="showDialog"
            overlay-opacity="0.7"
            width="500"
            style="align-items: center"
        >
            <v-card class="d-flex pa-3 justify-space-between">
                <v-btn
                    elevation="2"
                    x-small
                    class="mx-2 pa-0"
                    style="width: 30px; height: 30px"
                    @click="showDialog = false"
                >
                    <img
                        alt="Hochladen abbrechen"
                        :src="schliesenIcon"
                        style="height: 20px"
                    >
                </v-btn>

                <audio
                    :src="audioUrl"
                    controls
                    style="width: 100%; height: 30px"
                >
                    Your browser does not support the audio tag.
                </audio>

                <v-btn
                    elevation="2"
                    x-small
                    class="pa-0 mx-2"
                    style="width: 30px; height: 30px"
                    color="secondary"
                    :aria-label="'Datei hochladen'"
                    @click="handleRecording(file)"
                >
                    <v-icon
                        x-small
                        dark
                    >
                        fas fa-check
                    </v-icon>
                </v-btn>
            </v-card>
        </v-dialog>
        <v-tooltip
            v-model="recordingTooltip"
            top
            :open-on-hover="false"
        >
            <template v-slot:activator="{ attrs }">
                <v-btn
                    v-long-press="500"
                    v-bind="attrs"
                    fab
                    icon
                    class="ma-0"

                    @long-press-start="startAudioRecording()"
                    @long-press-stop="stopAudioRecording(1)"

                    @keypress.enter="startAudioRecording()"
                    @keyup.enter="stopAudioRecording(2)"
                    @keypress.space="startAudioRecording()"
                    @keyup.space="stopAudioRecording(3)"

                    @click="
                        keyboard
                            ? recordingRunning
                                ? stopAudioRecording()
                                : startAudioRecording()
                            : null
                    "
                >
                    <img
                        :src="recordingRunning ? recording : micIcon"
                        style="height: 30px"
                    >
                </v-btn>
            </template>
            <span> {{ recordingTime }}</span>
        </v-tooltip>
    </div>
</template>
<script>
import * as backend from "../../../api/backend";
import { mapGetters, mapMutations, mapState } from "vuex";
import Recorder from "js-audio-recorder";
import schliesenIcon from "@/assets/Icons/abbrechen-08.svg";
import startSound from "@/assets/Sound/startAufnahme.mp3";
import stopSound from "@/assets/Sound/exchanged.mp3";
import recording from "@/assets/Icons/recording-dot.svg";
import micIcon from "@/assets/Icons/mikrofon-22.svg";

export default {
  name: "ChatRoomImageUploadButton",
  props: {
    chatRoomId: { required: true, type: String },
  },
  data: () => ({
    recordingTime: "",
    elapsedTimeIntervalRef: "",
    recordingTooltip: false,
    recordingRunning: false,

    audioBlobs: [] /*of type Blob[]*/,
    mediaRecorder: null /*of type MediaRecorder*/,
    streamBeingCaptured: null /*of type MediaStream*/,

    audioStart: new Audio(startSound),
    audioStop: new Audio(stopSound),
    audioUrl: null,

    file: null,
    showDialog: false,
    schliesenIcon,
    recording,
    micIcon,
  }),
  computed: {
    ...mapGetters("auth", ["accountRole", "accountId"]),
    ...mapState("util", ["keyboard", "windowWidth"]),
  },
  mounted() {
    // this.checkPermission();
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),
    // async checkPermission() {
    //   try {
    //     return true;
    //   } catch (error) {
    //     console.error(`${error.name} : ${error.message}`);
    //     return false;
    //   }
    // },
    // hasEklaraMicPermissions() {
    //   return new Promise((resolve) => {
    //     navigator.mediaDevices.getUserMedia({audio: true}).then(function(stream) {
    //         resolve(true);
    //       }).catch(function(err) {
    //         resolve(false);
    //       })
    //   })
    // },
    async startAudioRecording() {
      console.log("Start");
      try {
        if(navigator.mediaDevices === undefined) {
          this.showSnackbar({ message: "Mikrofonaufnahmen werden in diesem Browser nicht unterstützt.",  color: "error"});
          return;
        }
        await Recorder.getPermission();
      } catch(error) {
        console.error(error);
        this.showSnackbar({ message: "Fehler: Bitte aktiveren Sie Mikrofonaufnahmen für diese Seite.",  color: "error"});
        return;
      }


      //start recording using the audio recording API
      if (this.recordingRunning) {
        return; // Recording already running
      }

      const userMediaStream = await navigator.mediaDevices.getUserMedia(
        { audio: true } /*of type MediaStreamConstraints*/
      );

      //save the reference of the stream to be able to stop it when necessary
      this.streamBeingCaptured = userMediaStream;

      //create a media recorder instance by passing that stream into the MediaRecorder constructor
      this.mediaRecorder = new MediaRecorder(
        userMediaStream
      ); /*the MediaRecorder interface of the MediaStream Recording
      API provides functionality to easily record media*/

      //clear previously saved audio Blobs, if any
      this.audioBlobs = [];

      //add a dataavailable event listener in order to store the audio data Blobs when recording
      this.mediaRecorder.addEventListener("dataavailable", (event) => {
        //store audio Blob object
        this.audioBlobs.push(event.data);
      });

      this.audioStart.play();
      this.startTimer();
      this.mediaRecorder.start();

      //start the recording by calling the start method on the media recorder
      this.recordingRunning = true;
      this.recordingTooltip = true;
    },
    stopAudioRecording(source) {
      if (!this.recordingRunning) {
        return; // Recording not started yet
      }

      const mediaRecorder = this.mediaRecorder;

      //listen to the stop event in order to create & return a single Blob object
      mediaRecorder.addEventListener("stop", () => {
        //create a single blob object, as we might have gathered a few Blob objects that needs to be joined as one
        let audioBlob = new Blob(this.audioBlobs, {
          type: mediaRecorder.mimeType,
        });

        this.saveAudioRecording(audioBlob);

      });

      //stop the recording feature
      this.recordingTooltip = false;
      this.recordingRunning = false;
      this.audioStop.play();
      this.mediaRecorder.stop();

      //stop all the tracks on the active stream in order to stop the stream
      this.streamBeingCaptured
        .getTracks() //get all tracks from the stream
        .forEach((track) /*of type MediaStreamTrack*/ => track.stop()); //stop each one      //reset API properties for next recording

      this.mediaRecorder = null;
      this.streamBeingCaptured = null;
    },

    async saveAudioRecording(audioAsBlob) {
      //stop the recording using the audio recording API
      console.log("Stopping Audio Recording...");
      // const audioAsBlob = await this.stopRecording();
      console.log("stopped with audio Blob:", audioAsBlob);

      this.file = audioAsBlob;
      this.audioUrl = URL.createObjectURL(this.file);
      this.showDialog = true;
      setTimeout(() => {
        this.stopTimer();
      }, 500);
      // console.error(error);
      // //Error handling structure
      // switch (error.name) {
      //     case 'InvalidStateError': //error from the MediaRecorder.stop
      //         console.log("An InvalidStateError has occured.");
      //         break;
      //     default:
      //         console.log("An error occured with the error name " + error.name);
      // }
    },
    async handleRecording(blob) {
      const formData = new FormData();
      formData.append(
        "file",
        blob,
        new Date().toLocaleDateString().replaceAll(".", "_") + ".wav"
      );

      const createdChatRoomEventResponse = await backend.postChatRoomEvent({
        type: "audioMessage",
        chatRoom: this.chatRoomId,
      });
      const createdChatRoomEvent = await createdChatRoomEventResponse.json();

      const fileXmlHttpRequest = await backend.postChatRoomEventFile(
        createdChatRoomEvent._id
      );

      fileXmlHttpRequest.onerror = (e) => {
        console.error("attachment upload error:", e);
        // this.showAttachmentUploadProgress = false;
      };
      fileXmlHttpRequest.onabort = () => {
        console.warn("attachment upload aborted");
        // this.showAttachmentUploadProgress = false;
      };
      fileXmlHttpRequest.addEventListener("load", () => {
        if (
          createdChatRoomEventResponse.status === 201 &&
          fileXmlHttpRequest.status === 201
        ) {
          this.showSnackbar({ message: "Aufnahme versendet." });
        } else if (fileXmlHttpRequest.status !== 201) {
          this.showSnackbar({
            message: "Beim Hochladen der Aufnahme ist ein Fehler aufgetreten.",
            color: "error",
          });
        }
        // this.showAttachmentUploadProgress = false;
      });
      fileXmlHttpRequest.upload.addEventListener("progress", (e) => {
        // this.attachmentUploadProgress = (e.loaded / e.total) * 100;
      });
      // this.attachmentUploadProgress = 0.0;
      // this.showAttachmentUploadProgress = true;
      fileXmlHttpRequest.send(formData);
      this.showDialog = false;
    },
    startTimer() {
      var startTime = new Date();
      this.recordingTime = this.getElapsedTime(startTime);
      this.elapsedTimeIntervalRef = setInterval(() => {
        this.recordingTime = this.getElapsedTime(startTime);
      }, 1000);
    },
    stopTimer() {
      if (typeof this.elapsedTimeIntervalRef !== "undefined") {
        clearInterval(this.elapsedTimeIntervalRef);
        this.elapsedTimeIntervalRef = undefined;
      }
      this.recordingTime = "";
    },
    getElapsedTime(startTime) {
      // Record end time
      let endTime = new Date();
      // Compute time difference in milliseconds
      let timeDiff = endTime.getTime() - startTime.getTime();
      // Convert time difference from milliseconds to seconds
      timeDiff = timeDiff / 1000;
      // Extract integer seconds that don't form a minute using %
      let seconds = Math.floor(timeDiff % 60); //ignoring incomplete seconds (floor)
      // Pad seconds with a zero if necessary
      let secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";
      // Convert time difference from seconds to minutes using %
      timeDiff = Math.floor(timeDiff / 60);
      // Extract integer minutes that don't form an hour using %
      let minutes = timeDiff % 60; //no need to floor possible incomplete minutes, because they've been handled as seconds
      // Pad minutes with a zero if necessary
      let minutesAsString = minutes < 10 ? "0" + minutes : minutes + "";
      // Convert time difference from minutes to hours
      timeDiff = Math.floor(timeDiff / 60);
      // Extract integer hours that don't form a day using %
      let hours = timeDiff % 24; //no need to floor possible incomplete hours, because they've been handled as seconds
      // Convert time difference from hours to days
      timeDiff = Math.floor(timeDiff / 24);
      // The rest of timeDiff is number of days
      let days = timeDiff;
      let totalHours = hours + days * 24; // add days to hours
      let totalHoursAsString =
        totalHours < 10 ? "0" + totalHours : totalHours + "";
      if (totalHoursAsString === "00") {
        return minutesAsString + ":" + secondsAsString;
      } else {
        return (
          totalHoursAsString + ":" + minutesAsString + ":" + secondsAsString
        );
      }
    },
  },
};
</script>
<style scoped>
.iconToRecording {
  background-image: url("../../../assets/Icons/recording-dot.svg") !important;
}

#micIcon {
  background-image: url("../../../assets/Icons/mikrofon-22.svg");
  width: 30px;
  height: 30px;
}

.pointer {
  cursor: pointer;
}

.bigIcon {
  height: 30px;
}
</style>