import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';

const defaultState = {
  timeslotsInvalid: false,
};

const mutations = {
  setTimeslotsInvalid : (state, status) => {
    state.timeslotsInvalid = status;
  },
};

const getters = {
  getTimeslotsInvalid: state => state.timeslotsInvalid,
};

const actions = {
  setTimeslotsStatus({ commit }, status) {
    commit('setTimeslotsInvalid', status);
  },
  async createSlot ({ commit, dispatch }, params) {

    try {
      const res = await backend.postTimeslot(params);
      await checkResponseStatus(201, res);
      const slot = await res.json();
      return slot;
    }
    catch(err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async editSlot ({ commit, dispatch }, params) {
    try {
      const id = params._id
      delete params._id;
      const res = await backend.patchTimeslot(id, params);
      if(res.status === 409) {
        return 409;
      }
      const account = await res.json();
      return account;
    }
    catch(err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async deleteSlot ({ commit, dispatch }, id) {
    try {
      const res = await backend.deleteTimeslot(id);
      if(res.status === 409) {
        return 409;
      }
      await checkResponseStatus(204, res);
      return true;
    }
    catch(err) {
      console.error(err)
      return false;
    }
  },

  async getSlots ({ commit, dispatch }) {

    try {
      const res = await backend.getTimeslots();
      await checkResponseStatus(200, res);
      const Slots = await res.json();
      return Slots;
    }
    catch(err) {
      console.error(err);
            return err?.response?.status;
    }
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  mutations,
  actions,
};
