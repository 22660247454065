import backendWebSocket from "../api/backendWebSocket";

// Based on https://vuex.vuejs.org/guide/plugins.html#committing-mutations-inside-plugins
// When user loggs/authenticates in, then create a websocket connection to our ackend
// On logout close the connection 
const createBackendWebSocketPlugin = () => {
  return (store) => {
    // store.subscribe(mutation => {
    //   // login
    //   if (mutation.type === 'auth/login') {
    //     backendWebSocket.initWebSocket();
    //   }

    //   // logout
    //   if (mutation.type === 'auth/logout') {
    //     backendWebSocket.closeWebSocket();
    //   }
    // });
    
    backendWebSocket.post('/chat-room/:chatRoomId', () => store.dispatch('chat/fetchChats', { sinceUpdatedAt: store.state.chat.lastFetchTime }));
    backendWebSocket.patch('/chat-room/:chatRoomId', () => store.dispatch('chat/fetchChats', { sinceUpdatedAt: store.state.chat.lastFetchTime }));
    backendWebSocket.delete('/chat-room/:chatRoomId', () => store.dispatch('chat/fetchChats')); // don't fetch with sinceUpdatedAt becaue it doesnt contain the deleted ones 

    backendWebSocket.post('/chat-room-event/:chatRoomEventId', () => store.dispatch('chat/fetchChats', { sinceUpdatedAt: store.state.chat.lastFetchTime }));
    backendWebSocket.patch('/chat-room-event/:chatRoomEventId', () => store.dispatch('chat/fetchChats', { sinceUpdatedAt: store.state.chat.lastFetchTime }));
    backendWebSocket.delete('/chat-room-event/:chatRoomEventId', () => store.dispatch('chat/fetchChats'));  // don't fetch with sinceUpdatedAt becaue it doesnt contain the deleted ones 


    backendWebSocket.post('/account/:accountId', () => store.dispatch('accounts/fetchAccounts'));
    backendWebSocket.patch('/account/:accountId', () => store.dispatch('accounts/fetchAccounts'));
    backendWebSocket.delete('/account/:accountId', () => store.dispatch('accounts/fetchAccounts'));

    backendWebSocket.post('/account-set/:accountSetId', () => store.dispatch('accountSets/fetchAccountSets'));
    backendWebSocket.patch('/account-set/:accountSetId', () => store.dispatch('accountSets/fetchAccountSets'));
    backendWebSocket.delete('/account-set/:accountSetId', () => store.dispatch('accountSets/fetchAccountSets'));


    backendWebSocket.post('/teacher/:teacherId', () => store.dispatch('teachers/fetchTeachers'));
    backendWebSocket.patch('/teacher/:teacherId', () => store.dispatch('teachers/fetchTeachers'));
    backendWebSocket.delete('/teacher/:teacherId', () => store.dispatch('teachers/fetchTeachers'));

    backendWebSocket.post('/pupil/:pupilId', () => store.dispatch('pupils/fetchPupils'));
    backendWebSocket.patch('/pupil/:pupilId', () => store.dispatch('pupils/fetchPupils'));
    backendWebSocket.delete('/pupil/:pupilId', () => store.dispatch('pupils/fetchPupils'));


    backendWebSocket.post('/parent/:accountSetId', () => store.dispatch('parents/fetchParents'));
    backendWebSocket.patch('/parent/:accountSetId', () => store.dispatch('parents/fetchParents'));
    backendWebSocket.delete('/parent/:accountSetId', () => store.dispatch('parents/fetchParents'));

    backendWebSocket.post('/maintainer/:accountSetId', () => store.dispatch('maintainers/fetchMaintainers'));
    backendWebSocket.patch('/maintainer/:accountSetId', () => store.dispatch('maintainers/fetchMaintainers'));
    backendWebSocket.delete('/maintainer/:accountSetId', () => store.dispatch('maintainers/fetchMaintainers'));


    backendWebSocket.patch('/device/', () => { /* Currently unused, but avoid error message due to unhandled route */});
  }
}

export default {
  createBackendWebSocketPlugin,
}



