<template>
    <v-menu
        v-model="menuOpen"
        offset-y
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                text
                style="text-transform: inherit;"
                :dark="pupil.status && pupil.status !== 'normal'"
                v-on="on"
            >
                {{ readableStatus(pupil.status) }}
                <v-icon
                    right
                    style="font-size: small;"
                    :style="`transform: rotate(${menuOpen ? '180' : '0'}deg);
                        color: var(--v-${pupil.status && pupil.status !== 'normal' ? 'weiss' : 'dunkelgrau'}-base)`"
                >
                    fas fa-chevron-down
                </v-icon>
            </v-btn>
        </template>
        <div
            style="background-color: var(--v-hellgrau-base)"
            class="d-flex flex-column"
        >
            <v-btn
                text
                style="text-transform: inherit"
                @click="changePupilStatus('normal')"
            >
                Normale Versetzung
            </v-btn>
            <v-btn
                text
                style="text-transform: inherit"
                @click="changePupilStatus('notTransferred')"
            >
                Keine Versetzung
            </v-btn>
            <v-btn
                text
                style="text-transform: inherit"
                @click="changePupilStatus('changeSchools')"
            >
                Schulwechsel
            </v-btn>
            <v-menu
                v-if="otherClasses.length"
                offset-x
            >
                <template v-slot:activator="{ on: onSubmenu, attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        style="text-transform: inherit"
                        v-on="{ ...onSubmenu }"
                    >
                        Wechsel in Klasse
                        <v-icon
                            right
                            style="font-size: small; color: var(--v-dunkelgrau-base)"
                        >
                            fas fa-chevron-right
                        </v-icon>
                    </v-btn>
                </template>
                <div
                    class="d-flex flex-column"
                    style="overflow-y: scroll; max-height: 200px; background-color: var(--v-hellgrau-base)"
                >
                    <v-btn
                        v-for="(otherClass, index) in displayableOtherClasses"
                        :key="`${index}${otherClass}`"
                        text
                        style="text-transform: inherit"
                        :disabled="!!disabledClasses.find(currentClass => currentClass._id === otherClass._id)"
                        @click="differentClassSelected(otherClass)"
                    >
                        {{ otherClass.name }}
                    </v-btn>
                </div>
            </v-menu>
        </div>
    </v-menu>
</template>

<script>
export default {
    name: 'ManageStudentsMenuWithSubmenu',
    props: {
        pupil: { required: true, type: Object },
        otherClasses: { required: false, type: Array, default: () => [] },
        disabledClasses: { required: false, type: Array, default: () => [] },
    },
    data: () => ({
       menuOpen: false,
    }),
    computed: {
        readableStatus() {
            return (status) => {
                let result = 'Normal';
                //TODO Change status to enum

                if (status === 'changeSchools') {
                    result = 'Schulwechsel';
                }
                if (status === 'notTransferred') {
                    result = 'Keine Versetzung';
                }
                if (status === 'changedClass' && this.pupil.targetClass) {
                    result = 'Wechsel in ' + this.pupil.targetClass.name
                }

                return result;
            }
        },
        displayableOtherClasses() {
            return this.otherClasses.filter(group => group.status !== 'discontinued');
        }
    },
    methods: {
        changePupilStatus(newStatus) {
            this.$emit('pupilTransferStatusChanged', newStatus);
        },
        differentClassSelected(otherClass) {

            // this.pupil.status = 'changedClass';
            this.$set(this.pupil, 'status', 'changedClass');
            this.$emit('changedClass', otherClass._id);
            this.menuOpen = false;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
