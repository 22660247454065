<template>
    <div>
        <v-row class="mx-0 mt-3 mb-4">
            <div style="height: auto; width: 90%; margin: auto; border-radius: 15px; box-shadow: 1px 5px 5px silver;">
                <!-- Header -->
                <div
                    class="d-flex justify-space-between align-center"
                    style="background-color: #6995cd; border-radius: 15px 15px 0 0; font-size: larger; padding-top: 5px; padding-bottom: 20px; color: white;"
                >
                    <!--#region Left -->
                    <div class="d-flex align-center">
                        <img
                            :src="suchIcon"
                            style="height: 24px; margin-left: 24px; margin-top: 20px; filter: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(19%) hue-rotate(65deg) brightness(104%) contrast(105%);"
                            alt="Suchen-Symbol"
                        >
                        <v-autocomplete
                            v-model="group"
                            label="Nach Klasse/Gruppe suchen"
                            single-line
                            dark
                            hide-details
                            item-text="name"
                            class="pl-6 mr-4"
                            style="width: 300px"
                            :items="groups"
                            :filter="customFilter"
                            no-data-text="Keine passende Klasse/Gruppe"
                        />

                        <v-btn
                            class="text-capitalize"
                            style="background-color: #d6d6d6; filter: opacity(40%); color: black;"
                            elevation="0"
                            @click="jumpToCurrentDay()"
                        >
                            Heute
                        </v-btn>
                        <v-btn
                            class="mx-2"
                            style="background-color: #d6d6d6; filter: opacity(40%); color: black;"
                            icon
                            small
                            color="black"
                            @click="subWeek"
                        >
                            <img
                                :src="linksIcon"
                                class="boardIcon"
                                style="color: black !important"
                                alt="links-Symbol"
                            >
                        </v-btn>
                        <v-btn
                            class="mx-2"
                            style="background-color: #d6d6d6; filter: opacity(40%); color: black !important;"
                            icon
                            small
                            color="black"
                            @click="addWeek"
                        >
                            <img
                                :src="rechtsIcon"
                                class="boardIcon"
                                style="color: black"
                                alt="rechts-Symbol"
                            >
                        </v-btn>

                        <v-menu
                            v-model="datePickerMenu"
                            :close-on-content-click="false"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    style="display: inline-grid"
                                    v-on="on"
                                >
                                    {{ getMonthText(getMonday(date).getMonth()) + " " + getMonday(date).getFullYear() }}
                                </div>
                            </template>

                            <v-date-picker
                                v-model="pickerDate"
                                :allowed-dates="weekdaysOnly"
                                locale="de-DE"
                                first-day-of-week="1"
                                no-title
                                show-week
                            >
                                <v-spacer />
                                <v-btn
                                    text
                                    class="text-capitalize"
                                    color="gruen"
                                    @click="setPickerDate"
                                >
                                    Bestätigen
                                </v-btn>
                            </v-date-picker>
                        </v-menu>

                        <div style="display: inline-grid; width: 20px" />
                        <v-btn
                            id="calendarWeekBtn"
                            elevation="0"
                        >
                            KW {{ getMonday(date).getWeek() }}
                        </v-btn>
                    </div>
                    <!--#endregion -->

                    <!--#region Right -->
                    <div class="d-flex align-center">
                        <v-btn
                            to="/verwaltung/faecher"
                            active-class="activeLink"
                            elevation="0"
                            class="mr-6"
                        >
                            <img
                                :src="faecherIcon"
                                class="mx-2"
                                style="max-width: 25px; max-height: 25px;"
                                alt=""
                            >
                            <div class="mr-2 text-capitalize">
                                Fächer
                            </div>
                        </v-btn>
                    </div>
                    <!--#endregion -->
                </div>
                <v-row>
                    <v-overlay
                        absolute
                        :value="showLoadingOverlay"
                    >
                        <v-progress-circular
                            v-if="showLoadingOverlay"
                            indeterminate
                            size="100"
                        />
                    </v-overlay>
                </v-row>
                <table
                    :key="reload"
                    style="width: 99%; margin: 0.5em auto 5em auto"
                >
                    <tr>
                        <th>
                            <img
                                :src="uhrIcon"
                                style="width: 24px; height: 24px; margin-top: 5px"
                                alt=""
                            >
                        </th>
                        <th>
                            <!-- I need to be here for correct layout, leave me alone! -->
                        </th>

                        <th
                            v-if="isDisplayDateToday(getDisplayDateMonday())"
                            style="color: #6995cd"
                        >
                            Mo.,
                            {{
                                getMonday(date).getDate() +
                                    "." +
                                    (getMonday(date).getMonth() + 1) +
                                    "." +
                                    getMonday(date).getFullYear().toString().slice(-2)
                            }}
                        </th>
                        <th
                            v-else
                            style="color: var(--v-dunkelgrau-base)"
                        >
                            Mo.,
                            {{
                                getMonday(date).getDate() +
                                    "." +
                                    (getMonday(date).getMonth() + 1) +
                                    "." +
                                    getMonday(date).getFullYear().toString().slice(-2)
                            }}
                        </th>

                        <th
                            v-if="isDisplayDateToday(getDisplayDateTuesday())"
                            style="color: #6995cd; background-color: var(--v-hellgrau-base); border-collapse: initial; border-radius: 10px 10px 0 0;"
                        >
                            Di.,
                            {{
                                getTuesday(date).getDate() +
                                    "." +
                                    (getTuesday(date).getMonth() + 1) +
                                    "." +
                                    getTuesday(date).getFullYear().toString().slice(-2)
                            }}
                        </th>
                        <th
                            v-else
                            style="color: var(--v-dunkelgrau-base); background-color: var(--v-hellgrau-base); border-radius: 10px 10px 0 0;"
                        >
                            Di.,
                            {{
                                getTuesday(date).getDate() +
                                    "." +
                                    (getTuesday(date).getMonth() + 1) +
                                    "." +
                                    getTuesday(date).getFullYear().toString().slice(-2)
                            }}
                        </th>

                        <th
                            v-if="isDisplayDateToday(getDisplayDateWednesday())"
                            style="color: #6995cd"
                        >
                            Mi.,
                            {{
                                getWednesday(date).getDate() +
                                    "." +
                                    (getWednesday(date).getMonth() + 1) +
                                    "." +
                                    getWednesday(date).getFullYear().toString().slice(-2)
                            }}
                        </th>
                        <th
                            v-else
                            style="color: var(--v-dunkelgrau-base)"
                        >
                            Mi.,
                            {{
                                getWednesday(date).getDate() +
                                    "." +
                                    (getWednesday(date).getMonth() + 1) +
                                    "." +
                                    getWednesday(date).getFullYear().toString().slice(-2)
                            }}
                        </th>

                        <th
                            v-if="isDisplayDateToday(getDisplayDateThursday())"
                            style="color: #6995cd; background-color: var(--v-hellgrau-base); border-radius: 10px 10px 0 0;"
                        >
                            Do.,
                            {{
                                getThursday(date).getDate() +
                                    "." +
                                    (getThursday(date).getMonth() + 1) +
                                    "." +
                                    getThursday(date).getFullYear().toString().slice(-2)
                            }}
                        </th>
                        <th
                            v-else
                            style="color: var(--v-dunkelgrau-base); background-color: var(--v-hellgrau-base); border-radius: 10px 10px 0 0;"
                        >
                            Do.,
                            {{
                                getThursday(date).getDate() +
                                    "." +
                                    (getThursday(date).getMonth() + 1) +
                                    "." +
                                    getThursday(date).getFullYear().toString().slice(-2)
                            }}
                        </th>

                        <th
                            v-if="isDisplayDateToday(getDisplayDateFriday())"
                            style="color: #6995cd"
                        >
                            Fr.,
                            {{
                                getFriday(date).getDate() +
                                    "." +
                                    (getFriday(date).getMonth() + 1) +
                                    "." +
                                    getFriday(date).getFullYear().toString().slice(-2)
                            }}
                        </th>
                        <th
                            v-else
                            style="color: var(--v-dunkelgrau-base)"
                        >
                            Fr.,
                            {{
                                getFriday(date).getDate() +
                                    "." +
                                    (getFriday(date).getMonth() + 1) +
                                    "." +
                                    getFriday(date).getFullYear().toString().slice(-2)
                            }}
                        </th>

                        <th>
                        </th>
                    </tr>
                    <tr
                        v-for="(item, index) in slots"
                        :key="index"
                    >
                        <th
                            class="textGrau"
                            style="font-weight: normal"
                            @click="editTimeslot(item, index)"
                        >
                            <div
                                v-if="editingTimeslot === index"
                                class="d-flex-column justify-center align-center"
                            >
                                <input
                                    v-model="from"
                                    type="time"
                                    class="input mb-1"
                                    :class="{ errorInput: fields.from }"
                                >
                                <input
                                    v-model="till"
                                    type="time"
                                    class="input"
                                    :class="{ errorInput: fields.till }"
                                >
                                <v-btn
                                    x-small
                                    elevation="0"
                                    style="height: 26px; width: 26px; background-color: var(--v-hellgrau-base);"
                                    @click.stop="saveTimeslot(item)"
                                >
                                    <img
                                        :src="acceptIcon"
                                        alt="Bestätigen"
                                    >
                                </v-btn>
                            </div>
                            <div v-else>
                                {{
                                    ("0" + new Date(item.start).getHours()).slice(-2) +
                                    ":" +
                                    ("0" + new Date(item.start).getMinutes()).slice(-2)
                                }}
                                <br>
                                {{
                                    ("0" + new Date(item.end).getHours()).slice(-2) +
                                    ":" +
                                    ("0" + new Date(item.end).getMinutes()).slice(-2)
                                }}
                            </div>
                        </th>
                        <th />
                        <td style="background-color: white">
                            <FachVerwaltung
                                v-for="appointment in getAppointments(item._id, 'mon')"
                                :key="appointment._id"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getMonday(date)"
                                :appointment="appointment"
                            />
                            <FachVerwaltung
                                v-if="getAppointments(item._id, 'mon').length === 0"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getMonday(date)"
                                :appointment="null"
                            />
                        </td>
                        <td
                            v-if="index === slots.length - 1"
                            style="background-color: var(--v-hellgrau-base); border-radius: 0 0 10px 10px"
                        >
                            <FachVerwaltung
                                v-for="appointment in getAppointments(item._id, 'die')"
                                :key="appointment._id"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getTuesday(date)"
                                :appointment="appointment"
                            />
                            <FachVerwaltung
                                v-if="getAppointments(item._id, 'die').length === 0"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getTuesday(date)"
                                :appointment="null"
                            />
                        </td>
                        <td
                            v-else
                            style="background-color: var(--v-hellgrau-base)"
                        >
                            <FachVerwaltung
                                v-for="appointment in getAppointments(item._id, 'die')"
                                :key="appointment._id"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getTuesday(date)"
                                :appointment="appointment"
                            />
                            <FachVerwaltung
                                v-if="getAppointments(item._id, 'die').length === 0"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getTuesday(date)"
                                :appointment="null"
                            />
                        </td>
                        <td style="background-color: white">
                            <FachVerwaltung
                                v-for="appointment in getAppointments(item._id, 'mit')"
                                :key="appointment._id"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getWednesday(date)"
                                :appointment="appointment"
                            />
                            <FachVerwaltung
                                v-if="getAppointments(item._id, 'mit').length === 0"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getWednesday(date)"
                                :appointment="null"
                            />
                        </td>
                        <td
                            v-if="index === slots.length - 1"
                            style="background-color: var(--v-hellgrau-base); border-radius: 0 0 10px 10px"
                        >
                            <FachVerwaltung
                                v-for="appointment in getAppointments(item._id, 'don')"
                                :key="appointment._id"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getThursday(date)"
                                :appointment="appointment"
                            />
                            <FachVerwaltung
                                v-if="getAppointments(item._id, 'don').length === 0"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getThursday(date)"
                                :appointment="null"
                            />
                        </td>
                        <td
                            v-else
                            style="background-color: var(--v-hellgrau-base)"
                        >
                            <FachVerwaltung
                                v-for="appointment in getAppointments(item._id, 'don')"
                                :key="appointment._id"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getThursday(date)"
                                :appointment="appointment"
                            />
                            <FachVerwaltung
                                v-if="getAppointments(item._id, 'don').length === 0"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getThursday(date)"
                                :appointment="null"
                            />
                        </td>
                        <td style="background-color: white">
                            <FachVerwaltung
                                v-for="appointment in getAppointments(item._id, 'fre')"
                                :key="appointment._id"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getFriday(date)"
                                :appointment="appointment"
                            />
                            <FachVerwaltung
                                v-if="getAppointments(item._id, 'fre').length === 0"
                                :group="
                                    groups.find((el) => {
                                        return el.name === group;
                                    })
                                "
                                :request-group="requestGroups"
                                :timeslot="item"
                                :day="getFriday(date)"
                                :appointment="null"
                            />
                        </td>
                        <th />
                    </tr>
                </table>
            </div>
        </v-row>
        <v-alert
            v-model="copyMode"
            type="info"
            max-width="850"
            style="margin-left: auto !important; margin-right: auto !important;"
        >
            <span>Kopiermodus gestartet, klicken Sie auf einen freien Zeitslot um die kopierte Stunde einzufügen</span>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn
                        style="min-width: 36px !important; width: 36px !important; float: right"
                        v-on="on"
                        @click="turnOffCopyMode"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>Kopiermodus beenden</span>
            </v-tooltip>
        </v-alert>
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
import FachVerwaltung from "./FachVerwaltung";

import uhrIcon from "../../assets/Icons/uhr-04.svg";
import linksIcon from "../../assets/Icons/links-10.svg";
import rechtsIcon from "../../assets/Icons/rechts-11.svg";
import suchIcon from "../../assets/Icons/suche-26.svg";
import faecherIcon from '@/assets/Icons/fächer-05.svg'
import cancelIcon from '@/assets/Icons/abbrechen-08.svg';
import acceptIcon from '@/assets/Icons/fertig-74.svg';

import * as backend from "../../api/backend";

export default {
  name: "Plan",
  components: {
    FachVerwaltung,
  },
  props: {},
  data() {
    return {
      showLoadingOverlay: false,
      date: new Date(), //being changed when calendar day/week is changed
      today: this.getDay(), //reference to color current day

      height: 0,

        list: [],
        groups: [],
        slots: [],
        group: "",
        reload: false,

        pickerDate : new Date().toISOString().substr(0, 10),
        datePickerMenu: false,

        editingTimeslot: null,
        from: '',
        till: '',

        fields: {
            from: false,
            till: false,
        },

        uhrIcon,
        linksIcon,
        rechtsIcon,
        suchIcon,
        faecherIcon,
        cancelIcon,
        acceptIcon,
    };
  },
  computed: {
      ...mapGetters("groups", ["getGroupStatus"]),
      ...mapGetters('timeslots', [ 'getTimeslotsInvalid' ]),
      ...mapGetters('copypaste', [ 'isCopyModeActive' ]),
      ...mapState('copypaste', ['copyMode']),
  },
  watch: {
    group(newValue) {
      this.list = [];
      this.groups.forEach((el) => {
        if (el.name === newValue) {
          const temp = el.participants;
          temp.forEach((el) => {
            this.list.push(el);
          });
        }
      });
    },
    getGroupStatus(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.requestGroups();
      }
    },
    getTimeslotsInvalid(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.requestSlots();
      }
    },
  },
  async created() {
    this.height = window.innerHeight / 2;
  },
  beforeCreate() {
    // Returns the ISO week of the date.
    Date.prototype.getWeek = function () {
      var date = new Date(this.getTime());
      date.setHours(0, 0, 0, 0);
      // Thursday in current week decides the year.
      date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
      // January 4 is always in week 1.
      var week1 = new Date(date.getFullYear(), 0, 4);
      // Adjust to Thursday in week 1 and count number of weeks from date to week1.
      return (
        1 +
        Math.round(
          ((date.getTime() - week1.getTime()) / 86400000 -
            3 +
            ((week1.getDay() + 6) % 7)) /
            7
        )
      );
    };
  },
  async mounted() {
    this.turnOffCopyMode();
    this.showLoadingOverlay = true;
    this.requestGroups();
    this.requestSlots();

    // this.requestBlackboard();
  },
    async destroyed() {
        this.stopCopyMode();
    },
  methods: {
      ...mapActions("groups", ["getGroups", "setGroupInvalidStatus"]),
      ...mapActions("timeslots", ["getSlots", 'setTimeslotsStatus', 'editSlot']),
      ...mapActions("groups", ["getGroup"]),
      ...mapActions('copypaste', ['turnOffCopyMode', 'setAppointmentData', 'setCopyModeOriginFachId']),
      ...mapMutations("snackbar", ["showSnackbar"]),

    async requestSlots() {
      this.showLoadingOverlay = true;
      this.slots = await this.getSlots();
      //TODO: Evtl. geht das hier schöner ? evtl. direkt im Backend Sortieren ?
      this.slots.forEach(element => {
        let dateOldStart = new Date(element.start);
        dateOldStart.setFullYear(2020,11,18);
        let dateOldEnd = new Date(element.end);
        dateOldEnd.setFullYear(2020,11,18);
        element.start = dateOldStart.toISOString();
        element.end = dateOldEnd.toISOString();
      });
      this.slots.sort(function(a,b){
        return new Date(a.start) - new Date(b.start);
      });
      this.showLoadingOverlay = false;
      this.setTimeslotsStatus(false);
    },

    async requestGroups() {
      //this is a hotfix which works and I have no clue why so please don't break it
      const response = await backend.getGroups();
      const newArray = await response.json();
      this.groups = newArray.filter((el) => el.name !== "Alle");
      this.setGroupInvalidStatus(false);
      this.reload = !this.reload;

      let temp = JSON.parse(JSON.stringify(this.group));
      this.group = "";

      setTimeout(() => {
        this.group = temp;
      }, 500);
    },

    customFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    //vergleicht displayDate mit today, return true wenn gleich um heutigen Tag einzufärben
    isDisplayDateToday(compareDate) {
      let heute, monat, tag;
      let jahr = this.today.toString().slice(13, 15);
      let unshortenedDay = this.today.toString().slice(8, 10);

      //wenn Tag kleiner als 10, schneide erstes Zeichen weg
      if (unshortenedDay.slice(0, 1) === "0") {
        tag = unshortenedDay.slice(1, 2);
      } else {
        tag = unshortenedDay.slice(0, 2);
      }

      //von Monatskürzel zu Zahl (ohne führende null)
      switch (this.today.toString().slice(4, 7)) {
        case "Jan":
          monat = "1";
          break;
        case "Feb":
          monat = "2";
          break;
        case "Mar":
          monat = "3";
          break;
        case "Apr":
          monat = "4";
          break;
        case "May":
          monat = "5";
          break;
        case "Jun":
          monat = "6";
          break;
        case "Jul":
          monat = "7";
          break;
        case "Aug":
          monat = "8";
          break;
        case "Sep":
          monat = "9";
          break;
        case "Oct":
          monat = "10";
          break;
        case "Nov":
          monat = "11";
          break;
        case "Dec":
          monat = "12";
          break;
      }

      //this.date in umgewandelter Form zusammenbauen
      heute = tag + "." + monat + "." + jahr;

      if (
        compareDate.toString().slice(5, compareDate.toString().length) === heute
      ) {
        return true;
      } else {
        return false;
      }
    },
    getDisplayDateMonday() {
      return (
        "Mo., " +
        this.getMonday(this.date).getDate() +
        "." +
        (this.getMonday(this.date).getMonth() + 1) +
        "." +
        this.getMonday(this.date).getFullYear().toString().slice(-2)
      );
    },
    getDisplayDateTuesday() {
      return (
        "Di., " +
        this.getTuesday(this.date).getDate() +
        "." +
        (this.getTuesday(this.date).getMonth() + 1) +
        "." +
        this.getTuesday(this.date).getFullYear().toString().slice(-2)
      );
    },
    getDisplayDateWednesday() {
      return (
        "Mi., " +
        this.getWednesday(this.date).getDate() +
        "." +
        (this.getWednesday(this.date).getMonth() + 1) +
        "." +
        this.getWednesday(this.date).getFullYear().toString().slice(-2)
      );
    },
    getDisplayDateThursday() {
      return (
        "Do., " +
        this.getThursday(this.date).getDate() +
        "." +
        (this.getThursday(this.date).getMonth() + 1) +
        "." +
        this.getThursday(this.date).getFullYear().toString().slice(-2)
      );
    },
    getDisplayDateFriday() {
      return (
        "Fr., " +
        this.getFriday(this.date).getDate() +
        "." +
        (this.getFriday(this.date).getMonth() + 1) +
        "." +
        this.getFriday(this.date).getFullYear().toString().slice(-2)
      );
    },
    getDay() {
      let date = new Date();
      while (!(date.getDay() % 6)) {
        date = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 1
        );
      }
      return date;
    },
    jumpToCurrentDay() {
      this.date = this.today;
    },
    addWeek() {
      this.date = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate() + 7
      );
    },
    subWeek() {
      this.date = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate() - 7
      );
    },
    getMonday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    getTuesday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -5 : 2); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    getWednesday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -4 : 3); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    getThursday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -3 : 4); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    getFriday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -2 : 5); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    getAppointments(slot, day) {
      switch (day) {
        case "mon":
          day = this.getMonday(this.date);
          break;
        case "die":
          day = this.getTuesday(this.date);
          break;
        case "mit":
          day = this.getWednesday(this.date);
          break;
        case "don":
          day = this.getThursday(this.date);
          break;
        case "fre":
          day = this.getFriday(this.date);
          break;
      }
      if (this.groups && this.group) {
        const group = this.groups.find((element) => {
          return element.name === this.group;
        });
        let appointments = [];
        if (group) {
          appointments = group.appointments.filter((el) => {
            return el.timeslot === slot && this.sameDay(new Date(el.day), day);
          });
        }
        return appointments;
      } else {
        return [];
      }
    },

    sameDay(first, second) {
      return (
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
      );
    },

    getMonthText(month) {
      if (month + 1 === 1) {
        return "Januar";
      } else if (month + 1 === 2) {
        return "Februar";
      } else if (month + 1 === 3) {
        return "März";
      } else if (month + 1 === 4) {
        return "April";
      } else if (month + 1 === 5) {
        return "Mai";
      } else if (month + 1 === 6) {
        return "Juni";
      } else if (month + 1 === 7) {
        return "Juli";
      } else if (month + 1 === 8) {
        return "August";
      } else if (month + 1 === 9) {
        return "September";
      } else if (month + 1 === 10) {
        return "Oktober";
      } else if (month + 1 === 11) {
        return "November";
      } else if (month + 1 === 12) {
        return "Dezember";
      }
    },

      stopCopyMode() {
          let temp = {
              isCopy: null,
              copiedAppointment: null,
              copiedSubject: null,
              copiedTeachers: null,
              copiedIsWeekly: null,
              copiedWeeklyTill: null,
          };

          this.turnOffCopyMode();
          this.setAppointmentData(temp);
          this.setCopyModeOriginFachId(null);
      },
      //take date from date picker and reformat it to set it to this.date
      setPickerDate() {
          this.datePickerMenu = false;
          let pickerDate = new Date(this.pickerDate);
          this.date = new Date(pickerDate.getFullYear(), pickerDate.getMonth(), pickerDate.getDate());
      },

      //only allow weekdays to be selected in date picker
      weekdaysOnly(val) {
          let date = new Date(val);
          //saturday is weekday 0, sunday is weekday 6, so monday-friday is 1-5
          return date.getDay() > 0 && date.getDay() < 6;
      },

      editTimeslot(item, index) {
          this.editingTimeslot = index;
      },

      async saveTimeslot(item) {
          this.editingTimeslot = '';
          try {
              this.showLoadingOverlay = true;
              if (!this.validateInput()) {
                  this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                  this.showLoadingOverlay = false;
                  return;
              }

              // Use new Date(0) instead of new Date(),
              // because new Date() initializes with date of creation
              // When one timeslot is created in summertime (CEST; Central European Summertime; UTC+02:00)
              // and another timeslot is created in wintertime (CET; Central European Time; UTC+01:00)
              // then its sorting can get messed up.
              // Because 10:30 am CEST is before 10:00 am CET when converting the times to UTC
              // And on server the times are sorted by UTC and not local time
              // Note: This is only relevant because school starts e.g. 9am independently of summer or wintertime
              let fromDate = new Date(0);
              fromDate.setHours(Number(this.from.split(':')[0]));
              fromDate.setMinutes(Number(this.from.split(':')[1]));
              fromDate.setSeconds(0);

              let tillDate = new Date(0);
              tillDate.setHours(Number(this.till.split(':')[0]));
              tillDate.setMinutes(Number(this.till.split(':')[1]));
              tillDate.setSeconds(0);
              let data = {
                  _id: item._id,
                  start: fromDate,
                  end: tillDate,
              };

              let response = await this.editSlot(data);

              if (Number.isInteger(response)) {
                  this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
              } else {
                  const index = this.slots.indexOf(this.oldSlot);
                  if (index > -1) {
                      this.slots.splice(index, 1);
                  }
                  this.setTimeslotsStatus(true);
                  await this.requestSlots();
                  this.stopEdit();
                  this.showSnackbar({ message: 'Gespeichert!'});
              }
              this.showLoadingOverlay = false;
          } catch (e) {
              console.error(e);
              this.showLoadingOverlay = false;
          }
      },

      stopEdit() {
          this.from = '';
          this.till = '';

          this.fields.from = false;
          this.fields.till = false;
      },

      validateInput() {
          let result = true;

          this.fields.from = false;
          this.fields.till = false;

          if (!this.from) {
              this.fields.from = true;
              result = false;
          }

          if (!this.till) {
              this.fields.till = true;
              result = false;
          }

          return result;
      },
  },
};
</script>

<style lang="scss" scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

tr {
  height: 40px;
}

table tr th:first-child,
table tr td:first-child {
  width: 10px !important;
}

table tr th:nth-child(2),
table tr td:nth-child(2) {
  width: 5px !important;
}

table tr th:last-child,
table tr td:last-child {
  width: 5px !important;
}

table tr th,
table tr td {
  width: 9em;
  border: none;
}
table,
tr,
td {
  border: none;
}

.fach {
  cursor: pointer;
}

.iconSelect {
  width: 42px;
  padding: 2px;
  border: solid 2px #eaeaea;
  border-radius: 10px;
  margin-right: 2px;
}

.iconSelect[selected] {
  width: 42px;
  padding: 2px;
  border: solid 2px #737373;
  border-radius: 10px;
}

.boardIcon {
  height: 20px;
  filter: brightness(100) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.textGrau {
  color: var(--v-dunkelgrau-base);
}

.blur {
  z-index: 8;
  background-color: grey;
}

.diableHoverButton:hover {
  cursor: default;
}

//make text respect line breaks
.blackboardVCardText {
  white-space: pre-line;
}

input[type="date"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.6rem;
}

#calendarWeekBtn {
    display: inline-grid;
    background-color: #ffffff !important;
    filter: opacity(40%);
    color: black !important;
}

.input {
    width: 80px;
    border-radius: 5px;
    border-width: 0;
    color: black;
    border: none !important;
    background-color: var(--v-hellgrau-base);
}

.errorInput {
    border: 2px solid red !important;
}
</style>
