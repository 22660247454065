import * as backend from '../../api/backend'
import { checkResponseStatus } from "@/util/check";

const actions = {
    // get all needed data for the provided day
    async getDiary({ commit, dispatch }, params) {
        try {
            const res = await backend.getDay(params);
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    // create a new diary entry for a specific day and group
    async postDiary({ commit, dispatch }, params) {
        try {
            const res = await backend.postDay(params);
            await checkResponseStatus(201, res);
            return res;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
};

const mutations = {};

const getters = {};

const state = {};

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}
