import * as backend from '../../api/backend';
import { checkResponseStatus } from '@/util/check';
import backendWebSocket from '../../api/backendWebSocket';

const defaultState = {
  // Is false when backend is down or e.g. no dns entry is found
  chatRooms: [],
  chatRoomEvents: [],
  lastFetchTime: new Date(0), // When was chat last fetched
};

const mutations = {
  setBackendReachable(state, backendReachable) {
    state.backendReachable = backendReachable;
  },
  setChatRooms(state, chatRooms) {
    
    // TODO Add unread Messages
    // TODO Add can be deleted By Me
    // TODO Add Title
    state.chatRooms = chatRooms;  
  },
  setLastFetchTime(state, lastFetchTime) {
    state.lastFetchTime = lastFetchTime
  },
  setChatRoomEvents(state, chatRoomEvents) {
    state.chatRoomEvents = chatRoomEvents;
  },
};

const actions = {
  /**
   * @param {Object} vuexParams - Vuex environment parameters
   * @param {Object} callerParams - Parameters given from calling line
   * @param {Date} callerParams.sinceUpdatedAt - Optional: Only retrieve updated and created chats since the given date. 
   * If unset then retrieve all chats. This is also useful in order to know which chats have been delete (since they are not there anymore)
   */
  async fetchChats({ commit, dispatch, state }, { sinceUpdatedAt } = {}) {
    const chatRoomsResponse = await backend.getChatRooms(sinceUpdatedAt);
    const retrievedChatRooms = await chatRoomsResponse.json();

    // Merge or replace data depending if new data only contains changes or everything
    if(sinceUpdatedAt) {
      // Merge old chatRooms with chatRooms
      const chatRooms = retrievedChatRooms.reduce((acc, chatRoom) => {
      const oldChatRoomIndex = acc.findIndex((localChatRoom) => localChatRoom._id === chatRoom._id);

        // Remove old chatRoom if it exsits
        if(oldChatRoomIndex >= 0) {
          acc.splice(oldChatRoomIndex, 1);
        }

        acc.push(chatRoom);
        return acc;
      }, [...state.chatRooms])
      ;

      commit('setChatRooms', chatRooms);
    } else {
      commit('setChatRooms', retrievedChatRooms);
    }

    const chatRoomEventsResponse = await backend.getChatRoomEvents(sinceUpdatedAt);
    const retrievedChatRoomEvents = await chatRoomEventsResponse.json();

    // Merge or replace data depending if new data only contains changes or everything
    if(sinceUpdatedAt) {
      // Merge old events with new events
      const chatRoomEvents = retrievedChatRoomEvents.reduce((acc, chatRoomEvent) => {
        const oldChatRoomEventIndex = acc.findIndex((localEvent) => localEvent._id === chatRoomEvent._id);

        // Remove old event if it exsits
        if(oldChatRoomEventIndex >= 0) {
          acc.splice(oldChatRoomEventIndex, 1);
        }

        acc.push(chatRoomEvent);
        return acc;
      }, [...state.chatRoomEvents]);

      commit('setChatRoomEvents', chatRoomEvents);
    } else {
      // Replace old events with new Events
      commit('setChatRoomEvents', retrievedChatRoomEvents);
    }

    commit('setLastFetchTime', (new Date()).toISOString());
  },
  async sendTextMessage({ commit, dispatch }, { text, chatRoomId }) {
    const chatRoomsResponse = await backend.postChatRoomEvent({
      type: "textMessage",
      text,
      chatRoom: chatRoomId,
    });
  },
  async redactChatRoomEvent({ commit, dispatch }, { chatRoomEventId }) {
    await backend.redactChatRoomEvent(chatRoomEventId);
    dispatch("fetchChats");
  },

  async sendImageMessage({ commit, dispatch}, { imageFile, chatRoomId}) {
    const createdChatRoomEventResponse = await backend.postChatRoomEvent({
      type: "imageMessage",
      chatRoom: chatRoomId,
    });
    const createdChatRoomEvent = await createdChatRoomEventResponse.json();
    await backend.postChatRoomEventFile(createdChatRoomEvent._id, imageFile);
  }
};

const getChatRoomDisplayName = (chatRoom, rootGetters) => {
  if (chatRoom.type === 'privateChat') {
    const loggedInAccountId =  rootGetters['auth/accountId'];
    const accountId0 = getAddressedAccountsOfMembership(chatRoom.memberships[0], rootGetters)[0];
    const accountId1 = getAddressedAccountsOfMembership(chatRoom.memberships[1], rootGetters)[0];

    // Get account of the partner i am chatting with
    const chatPartnerAccountId = loggedInAccountId === accountId1 ? accountId0 : accountId1;
    const chatPartnerAccount = rootGetters['accounts/accountsById'][chatPartnerAccountId];

    if(chatPartnerAccount) {
      return `${chatPartnerAccount.displayName}`
    } else {
      // Legends tell that this case might have been true.
      return "Privater Chat";
    }
  }
  return chatRoom.name;
}

// A membership can be a membertype of 'accounts', 'allTeachers', 'groupLeaders', ....
// depending on this different accounts are affected/addressed/part of that membership
const getAddressedAccountsOfMembership = (membership, rootGetters) => {
  const accountSet = rootGetters['accountSets/accountSetsById'][membership.accountSet];
  if(accountSet) {
    return accountSet.addressedAccounts;
  } else {
    return [];
  }
}

const getters = {
  chatRooms(state, getters, rootState, rootGetters) {
    return state.chatRooms.map(chatRoom => ({
      ...chatRoom,
      displayName: getChatRoomDisplayName(chatRoom, rootGetters),
      memberships: chatRoom.memberships.map((membership) => ({
        ...membership,
        addressedAccounts: getAddressedAccountsOfMembership(membership, rootGetters),
      })),
    }));
  },
  chatRoomEvents(state) { return state.chatRoomEvents },
  chatRoomEventsByChatRoom(state) {
    return state.chatRoomEvents.reduce((acc, chatRoomEvent) => {
      if (acc[chatRoomEvent.chatRoom]) {
        acc[chatRoomEvent.chatRoom].push(chatRoomEvent);
      } else {
        acc[chatRoomEvent.chatRoom] = [chatRoomEvent];
      }
      return acc;
    }, {});
  },

  /**
   * Returns memberships of a account in all chatRooms
   * example return
   * {
   *  "accountId1": {
   *    "chatroomId1": [{ group: "groupId1", memberType: "groupLeaders", role: "moderator" }, ...],
   *    "chatroomId2": { ... },
   *  },
   *  "accountId2": { 
   *    ...
   *  }
   * }
   * This means that account "accountId1" has the role "moderator" 
   * in chatRoom "chatRoomId1" because it is a groupLeader in group "groupId1"
   */
  chatRoomMembershipsByAccountId(state, getters, rootState, rootGetters) {
    const getMembershipsInChatRoom = (accountId, chatRoom) => {
      return chatRoom.members
        .filter(membership => membership.addressedAccounts
          .some(addressedAccount => accountId === addressedAccount)
        )
    }

    return rootGetters['accounts/accounts']
      .reduce((acc, account) => {
        acc[account._id] = getters['chatRooms']
          .map(chatRoom => ({
            chatRoom,
            memberships: getMembershipsInChatRoom(account._id, chatRoom),
          }))
          .filter(({chatRoom, memberships}) => memberships.length > 0);

        return acc;
      }, {});
  }
};


export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};



// isPrivateChat
// joinevent
// leaveevent

// Parent => Teacher
// Pupil => Teacher
// Maintainer => Group<Teacher>
// Teacher => Group<Pupil>
// Pupil => Pupil (Not directly possible)

// Types
// Audio
// Video
// Poll
// File
// Text

// GroupChat vs Private Chat

// Group Chat has leaders
// - Members cannot leave the chat
// - Moderators can del events, leave the Chat (only when there is another Mod), del the Chat
// - Moderator can lock or unlock Chat for Members

// Private Chat => Everyone can delete his own event... and leave the Chat
// - Everyone can leave the chat
// - Everyone can only delete his own event

// Maintainer can write
// Teacher
// Parent
// Pupil

// Teacher can write
// Children of his Groups
// Parents of his Children
// Maintainer
// Teachers

// Parent can write
// His own children
// Teachers of the Children

// Child can write
// its parents
// its teachers (Group Leads)

// Appointment Leads (Lehrer der in einer Klasse unterrictet) can

// Group Leads  (Klassenlehrer) can
// - The Same as a Appointment Lead
// - Add/Remove Appointments and Set the Appintment Lead

// Appointment Leads can
// Appointment Leads in a Group
// Cached in a Group as Appointment Lead Array
// - Write Children
// - And such
//
