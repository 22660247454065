import { render, staticRenderFns } from "./FachSchueler.vue?vue&type=template&id=428b55fc&scoped=true&"
import script from "./FachSchueler.vue?vue&type=script&lang=js&"
export * from "./FachSchueler.vue?vue&type=script&lang=js&"
import style0 from "./FachSchueler.vue?vue&type=style&index=0&id=428b55fc&prod&lang=css&"
import style1 from "./FachSchueler.vue?vue&type=style&index=1&id=428b55fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "428b55fc",
  null
  
)

export default component.exports