import { render, staticRenderFns } from "./EditorAnswerField.vue?vue&type=template&id=3828d134&scoped=true&"
import script from "./EditorAnswerField.vue?vue&type=script&lang=js&"
export * from "./EditorAnswerField.vue?vue&type=script&lang=js&"
import style0 from "./EditorAnswerField.vue?vue&type=style&index=0&id=3828d134&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3828d134",
  null
  
)

export default component.exports