<template>
    <div>
        <v-row class="mx-0">
            <div class="whiteBg widgetContainer">
                <!-- header -->
                <div
                    style="background-color: var(--v-dunkelgrau-base); border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white;"
                    @click="readWidgetInfoText()"
                >
                    <v-row
                        style="max-width: 100%; display: flex; align-items: center; height: 35px"
                        class="mx-2"
                    >
                        <v-col
                            cols="9"
                            md="8"
                            lg="9"
                            style="white-space: nowrap;"
                            class="pa-0 pl-2 font-style d-flex align-center noselect"
                        >
                            <img
                                :src="brettIcon"
                                aria-hidden="true"
                                alt="Schwarzes Brett"
                                class="iconToWhite mr-1"
                                style="height: 20px;"
                            >
                            <h1
                                tabindex="0"
                                class="mb-0"
                            >
                                Schwarzes Brett
                            </h1>
                        </v-col>
                        <v-col
                            cols="3"
                            md="4"
                            lg="3"
                            class="pa-0 pr-2 d-flex align-center justify-end"
                        >
                            <img
                                :src="infoIcon"
                                alt=""
                                class="mr-4 infoIcon"
                                style="height: 15px"
                            >
                            <v-btn
                                v-if="(accountRole === 'teacher' || accountRole === 'maintainer') && myGroups[0]"
                                x-small
                                class="d-flex align-center"
                                style="background-color: #f8f8f880; width: 35px; height: 35px;"
                                @click="() => { eintragPopup = true; groupsBlackboard = [myGroups[0].name]; brettEintrag.groupId = myGroups[0]._id; setTitle() }"
                            >
                                <img
                                    :src="plusIcon"
                                    class="boardIconDialog"
                                    alt="Plus-Icon"
                                >
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <!-- body -->
                <scroll-area-magnifier
                    class="brettWidgetBody"
                    :usage="'brettWidget'"
                    :settings="settings"
                >
                    <div :key="updateBlackboard">
                        <!--placeholder if there are no entries-->
                        <div
                            v-if="blackboard.length === 0"
                            class="ma-4"
                            style="z-index: 0"
                        >
                            Die Schule hat noch keine Einträge verfasst
                        </div>

                        <!--Blackboard entry-->
                        <v-card
                            v-for="(item, index) in displayedBlackboardItems()"
                            :key="item._id + index + urls + reload"
                            class="ma-2"
                            style="z-index: 0;"
                        >
                            <v-badge
                                color="red"
                                content="1"
                                :value="!item.seenByMe"
                                overlap
                                offset-x="15"
                                style="z-index: 9; width:100%"
                            >
                                <v-card-title
                                    class="pa-1"
                                    style="color: var(--v-dunkelgrau-base); background-color: var(--v-hellgrau-base); max-width: 100%; flex-wrap: nowrap"
                                >
                                    <img
                                        :src="chooseIcon(item.icon)"
                                        alt=""
                                        class="boardIcon mr-2"
                                        :style="{ 'background-color': item.color}"
                                    >
                                    <h2 class="ellipsis mr-auto">
                                        <span
                                            aria-hidden="true"
                                            class="ellipsis mb-0 titleText"
                                            tabindex="0"
                                        >{{ getTitle(item.createdBy, item.group) }}</span>
                                        <!-- Accessibility -->
                                        <span class="visually-hidden">{{ getTitle(item.createdBy, item.group, true) }}</span>
                                    </h2>

                                    <v-tooltip
                                        :disabled="!showTooltips"
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                v-if="accountRole === 'pupil' || accountRole === 'parent'"
                                                :aria-hidden="me && me.accessibility && me.accessibility.screenreader"
                                                x-small
                                                class="pa-0 mr-2 preventColorInvert"
                                                style="background-color: #F8F8F880 !important; height: 35px;"
                                                v-on="on"
                                                @click="readText(item.message, $event)"
                                            >
                                                <img
                                                    :src="lautsprecherIcon"
                                                    alt="Vorlesen"
                                                    style="height: 20px; color: var(--v-dunkelgrau-base)"
                                                >
                                            </v-btn>
                                        </template>
                                        <span>Vorlesen</span>
                                    </v-tooltip>

                                    <v-btn
                                        v-if="myEntry(item.createdBy)"
                                        x-small
                                        class="pa-0 preventColorInvert"
                                        style="background-color: #F8F8F880 !important; height: 35px; width: 35px"
                                        @click="() => { bearbeiten = true; eintragPopup = true; setBrettPopup(item._id);}"
                                    >
                                        <img
                                            :src="bearbeitenIcon"
                                            alt=""
                                            style="height: 20px; color: var(--v-dunkelgrau-base)"
                                        >
                                    </v-btn>

                                    <v-tooltip
                                        :disabled="!showTooltips"
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                x-small
                                                class="pa-0"
                                                :style="`background-color: ${item.pin ? item.color : '#F8F8F880 !important'}`"
                                                style="height: 35px; width: 35px"
                                                v-on="on"
                                                @click="pinEntry(item._id)"
                                            >
                                                <img
                                                    :src="pinIcon"
                                                    :alt="item.pin ? 'Nachricht nicht mehr anheften' : 'Nachricht anheften'"
                                                    style="height: 20px"
                                                    :class="{iconToWhite: item.pin}"
                                                >
                                            </v-btn>
                                        </template>
                                        <span v-if="item.pin">Nachricht loslösen</span>
                                        <span v-else>Nachricht anheften</span>
                                    </v-tooltip>
                                </v-card-title>
                                <v-card-text class="pa-2">
                                    <!-- diable no-html rule because blackboard message is sanitized on backend create/patch -->
                                    <!-- eslint-disable vue/no-v-html -->
                                    <p
                                        tabindex="0"
                                        class="blackboardVCardText mb-0"
                                        style="font-size: medium; color: #444444"
                                        v-html="sanitizeHtml(urlify(item.message))"
                                    />
                                    <!-- eslint-enable vue/no-v-html -->
                                    <hr
                                        v-if="item.uploads.length"
                                        class="my-2"
                                    >
                                    <div
                                        v-for="(el, index2) in item.uploads"
                                        :key="el._id + index2"
                                    >
                                        <div
                                            v-if="urls[el._id]"
                                            class="my-2 d-flex align-center justify-center"
                                        >
                                            <!-- Display image preview -->
                                            <img
                                                v-if="['png', 'jpg', 'jpeg', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                :src="urls[el._id]"
                                                class="moveableItemShadow"
                                                style="cursor: pointer; max-width: 100%"
                                                width="auto"
                                                height="auto"
                                                alt=""
                                                @click="() => showFullImage(item._id, el)"
                                            >
                                            <div
                                                v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                style="position: relative"
                                            >
                                                <v-icon
                                                    color="grey darken-3"
                                                    size="100px"
                                                    style="top: 0; bottom: 0; margin-left: auto; margin-right: auto; left: 0; right: 0; position: absolute;"
                                                    @click="() => playVideo(item._id, el)"
                                                >
                                                    mdi-play-circle-outline
                                                </v-icon>
                                                <img
                                                    :src="urls[el._id]"
                                                    class="moveableItemShadow"
                                                    style="cursor: pointer; max-width: 100%;"
                                                    width="auto"
                                                    height="auto"
                                                    alt=""
                                                    @click="() => playVideo(item._id, el)"
                                                >
                                            </div>
                                            <!-- Display pdf preview -->
                                            <object
                                                v-else-if="['pdf'].includes(el.title.split('.').pop().toLowerCase())"
                                                type="application/pdf"
                                                :data="urls[el._id]"
                                                style="cursor: pointer; min-height: 300px; max-height: 400px; max-width: 100%"
                                                width="auto"
                                                height="auto"
                                            />
                                            <!-- Display video preview -->
                                            <!--                                            <video-->
                                            <!--                                                v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"-->
                                            <!--                                                :poster="videoPoster"-->
                                            <!--                                                controls-->
                                            <!--                                                style="cursor: pointer;" width="100%" height="auto" >-->
                                            <!--                                                <source :src="urls[el._id]" />-->
                                            <!--                                            </video>-->
                                            <v-container
                                                v-else-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                class="px-2 py-0 d-inline-flex "
                                                style="flex-wrap: wrap; justify-content: center;align-items: center;"
                                            >
                                                <v-flex
                                                    align-self-start
                                                    class="d-flex flex-row pa-2"
                                                    shrink="true"
                                                    style="align-items:center;background-color:var(--v-hellgrau-base); border-radius: 6px 6px 6px 6px;"
                                                >
                                                    <v-flex
                                                        class=" ellipsis"
                                                        style="max-width:80%; overflow-x:hidden; font-size: 16px;"
                                                    >
                                                        {{ el.title }}
                                                    </v-flex>

                                                    <v-tooltip
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon 
                                                                class="ml-1"
                                                                v-on="on"
                                                            >
                                                                mdi-information-outline
                                                            </v-icon>
                                                        </template>
                                                        <p>
                                                            Dieses Dateiformat kann in Eklara nicht angesehen und bearbeitet werden. Bitte laden Sie diese dazu herunter
                                                        </p>
                                                    </v-tooltip>

                                                    <v-btn
                                                        class="btnStyle"
                                                        x-small
                                                        elevation="0"
                                                        @click="() => clickDownload(el,item._id)"
                                                    >
                                                        <img
                                                            :src="runterladenIcon"
                                                            alt="Herunterladen"
                                                            style="height: 20px;"
                                                        >
                                                    </v-btn>
                                                </v-flex>
                                            </v-container>
                                        </div>
                                        <hr
                                            v-if="blackboard[index].uploads.length > index2 + 1"
                                            class="my-2"
                                        >
                                    </div>
                                    <div class="d-flex-y mt-3 mb-1 blackboardItemFooter">
                                        <span>
                                            {{ dayname[new Date(item.createdOn).getDay()] }}.
                                            {{ new Date(item.createdOn).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit'}) }}
                                            {{ new Date(item.createdOn).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'}) }}
                                        </span>

                                        <span v-if="accountRole === 'teacher' && item.checkMarkStyle!=='hide'"> {{ item.seenByCount>item.seenByTarget ? item.seenByTarget : item.seenByCount }}/{{ item.seenByTarget }}</span>
                                        <span v-if="accountRole === 'teacher' && item.checkMarkStyle==='none'">✔</span>
                                        <span v-if="accountRole === 'teacher' && item.checkMarkStyle==='some'">✔</span><span
                                            v-if="accountRole === 'teacher' && item.checkMarkStyle==='some'"
                                            style="margin-left: -7px;"
                                        >✔</span>
                                        <span
                                            v-if="accountRole === 'teacher' && item.checkMarkStyle==='all'"
                                            style="color:#6996cd;"
                                        >✔</span><span
                                            v-if="accountRole === 'teacher' && item.checkMarkStyle==='all'"
                                            style="margin-left: -7px; color:#6996cd;"
                                        >✔</span>
                                    </div>
                                </v-card-text>
                            </v-badge>
                        </v-card>
                    </div>
                </scroll-area-magnifier>
            </div>
        </v-row>

        <!--#region dialogs -->
        <!-- Schwarzes Brett Popup -->
        <v-dialog
            persistent
            color="white"
            :value="eintragPopup"
            class="dialog"
        >
            <v-row
                class="popUpRow"
                style="margin: auto; box-shadow: none !important;"
            >
                <v-card id="blackboardCardContainer">
                    <!-- popup title -->
                    <div
                        class="px-1 align-start"
                        style="display: inline-flex; width: 100%; background-color: var(--v-dunkelgrau-base); border-radius: 15px 15px 0 0; font-size: larger; padding-top: 20px; padding-bottom: 20px; color: white;"
                    >
                        <div
                            class="mt-1"
                            style="margin-left: 10px; display: flex; align-items: center;"
                        >
                            <img
                                :src="brettIcon"
                                class="mx-2 boardIconDialog"
                                alt="Schwarzes Brett"
                            >
                            <p
                                v-if="bearbeiten"
                                class="mx-0 mb-2"
                                style="display: inline-block;"
                            >
                                Eintrag bearbeiten
                            </p>
                            <p
                                v-else
                                class="ma-0"
                                style="display: inline-block;"
                            >
                                Neuer Eintrag
                            </p>
                        </div>

                        <v-spacer />

                        <v-btn
                            x-small
                            class="d-flex align-center mr-2"
                            style="background-color: #f8f8f880; width: 35px; height: 35px;"
                            @click="() => { eintragPopup = false; bearbeiten = false; resetPopup() }"
                        >
                            <img
                                :src="schliessenIcon"
                                class="boardIconDialog"
                                alt="schliessen-Icon"
                            >
                        </v-btn>
                    </div>

                    <!-- blackboard message -->
                    <div>
                        <!-- Header -->
                        <v-card-title style="color: var(--v-dunkelgrau-base); background-color: var(--v-hellgrau-base); padding-top: 10px; padding-bottom: 10px">
                            <v-menu bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-badge
                                        color="gruen"
                                        right
                                        bottom
                                        overlap
                                    >
                                        <template v-slot:badge>
                                            <v-btn
                                                class="editCircleBtn pb-2"
                                                style="margin-left: -2px; margin-right: -2px"
                                                icon
                                                x-small
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <img
                                                    :src="bearbeitenIcon"
                                                    class="icon iconToWhite"
                                                    alt="Icon bearbeiten"
                                                >
                                            </v-btn>
                                        </template>
                                        <img
                                            :src="chooseIcon(brettEintrag.icon)"
                                            :style="{ 'background-color': brettEintrag.color}"
                                            class="mx-2 boardIconEntries"
                                            alt="Icon"
                                        >
                                    </v-badge>
                                </template>

                                <!-- icon and color picker -->
                                <div
                                    class="pa-4"
                                    style="background-color: white"
                                >
                                    <p>Iconauswahl</p>
                                    <v-row>
                                        <v-col cols="12">
                                            <img
                                                class="iconSelect"
                                                :selected="brettEintrag.icon === 'sonneIcon'"
                                                :src="sonneIconNormal"
                                                alt="Sonne"
                                                @click="brettEintrag.icon = 'sonneIcon'"
                                            >
                                            <img
                                                class="iconSelect"
                                                :selected="brettEintrag.icon === 'erdkundeIcon'"
                                                :src="erdkundeIconNormal"
                                                alt="Globus"
                                                @click="brettEintrag.icon = 'erdkundeIcon'"
                                            >
                                            <img
                                                class="iconSelect"
                                                :selected="brettEintrag.icon === 'religionIcon'"
                                                :src="religionIconNormal"
                                                alt="Stern"
                                                @click="brettEintrag.icon = 'religionIcon'"
                                            >
                                            <img
                                                class="iconSelect"
                                                :selected="brettEintrag.icon === 'politikIcon'"
                                                :src="politikIconNormal"
                                                alt="Rednerpul"
                                                @click="brettEintrag.icon = 'politikIcon'"
                                            >
                                            <img
                                                class="iconSelect"
                                                :selected="brettEintrag.icon === 'bioIcon'"
                                                :src="bioIconNormal"
                                                alt="Blatt"
                                                @click="brettEintrag.icon = 'bioIcon'"
                                            >
                                            <img
                                                class="iconSelect"
                                                :selected="brettEintrag.icon === 'englischIcon'"
                                                :src="englischIconNormal"
                                                alt="Sprechblasen"
                                                @click="brettEintrag.icon = 'englischIcon'"
                                            >
                                        </v-col>
                                    </v-row>

                                    <p>Farbauswahl</p>
                                    <v-row>
                                        <v-col>
                                            <verwaltung-color-picker
                                                :callback="(result) => { brettEintrag.color = result }"
                                                :current-color="brettEintrag.color"
                                            />
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-menu>

                            <!-- group/receiver selection -->
                            <p
                                class="mb-0"
                                style="margin-left: 10px"
                            >
                                {{ bearbeiten ? brettEintrag.title : newEntryTitle }}
                            </p>

                            <p class="mb-0 ml-2">
                                →
                            </p>
                            <v-select
                                v-model="groupsBlackboard"
                                hide-details
                                item-text="name"
                                :items="myGroups"
                                :filter="customFilter"
                                no-data-text="Keine passende Gruppe"
                                class="ma-0 pa-0 ml-2"
                                style="max-width: 278px"
                                :multiple="!bearbeiten"
                            />
                        </v-card-title>

                        <!-- preview, content, attachment(s) -->
                        <v-card-text class="pa-2">
                            <div>
                                <advanced-quill-editor
                                    v-model="brettEintrag.message"
                                    style="max-height: 400px"
                                    placeholder="Text einfügen"
                                />

                                <v-row
                                    v-for="(item, index) in brettEintrag.uploads"
                                    :key="item._id + index"
                                    class="my-1"
                                >
                                    <v-col class="d-flex align-center">
                                        {{ item.title }}
                                    </v-col>

                                    <v-col style="text-align: right;">
                                        <v-btn
                                            dark
                                            class="ml-auto mr-0"
                                            color="rot"
                                            @click="removeUpload(item)"
                                        >
                                            <p class="text-none">
                                                Datei entfernen
                                            </p>
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="pr-0">
                                        <input
                                            id="files"
                                            ref="uploadInput"
                                            type="file"
                                            name="files"
                                            multiple
                                            class="mt-3"
                                            accept="image/*,video/*,application/pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx"
                                            @change="() => uploadInputChange()"
                                        >
                                    </v-col>
                                </v-row>

                                <!-- delete and send button -->
                                <v-row>
                                    <v-col class="d-flex justify-center">
                                        <!-- delete message -->
                                        <v-btn
                                            v-if="bearbeiten"
                                            dark
                                            color="rot"
                                            class="mr-2"
                                            @click="eintragLoeschen(); eintragPopup = false"
                                        >
                                            <p class="text-capitalize">
                                                Löschen
                                            </p>
                                        </v-btn>

                                        <!-- resend message -->
                                        <v-btn
                                            v-if="bearbeiten"
                                            dark
                                            color="gruen"
                                            @click="eintragBearbeiten"
                                        >
                                            <p class="text-capitalize">
                                                Versenden
                                            </p>
                                        </v-btn>

                                        <!-- send message -->
                                        <v-btn
                                            v-else
                                            dark
                                            color="gruen"
                                            @click="eintragErstellen"
                                        >
                                            <p class="text-capitalize">
                                                Versenden
                                            </p>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card-text>
                    </div>
                </v-card>
            </v-row>
        </v-dialog>

        <!-- Fullscreen video dialog -->
        <v-dialog
            v-if="videoUrl"
            :value="videoUrl"
            overlay-opacity=".9"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="videoUrl = null; $refs.normalVideo.stop();"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        large
                        dark
                        @click="videoUrl = null; primaryVideoUrl = null; secondaryVideoUrl = null; $refs.normalVideo.stop();"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <!--                    <video ref="normalVideo" :src="videoUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"-->
                    <!--                           :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">-->
                    <!--                        Your browser does not support the video tag.-->
                    <!--                    </video>-->
                    <video-in-video
                        ref="normalVideo"
                        :primary-video-src="videoUrl"
                        :subtitle-src="subtitleURL"
                    />
                </v-col>
            </v-row>
        </v-dialog>

        <FullscreenImageDialog
            :image="imgFullscreen"
            :file-name="'eKlara_schwarzes_brett_bild'"
            :image-alt-text="'Bild im Schwarzen Brett'"
            @close="imgFullscreen = null"
        />
        <!--#endregion -->
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import sanitizeHtml from '../util/sanitizeHtml';

import VerwaltungColorPicker from "./Verwaltung/VerwaltungColorPicker";

import brettIcon from '../assets/Icons/brett.svg';
import pinIcon from "../assets/Icons/pin.svg";
import lautsprecherIcon from "../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../assets/Icons/lautsprecher-aus-89.svg";
import infoIcon from "../assets/Icons/info-2-weiß-89.svg";

import sonneIcon from "../assets/Icons/sonne-weiss-46.svg";
import erdkundeIcon from '../assets/Icons/f-erdkunde-weiss-31.svg';
import religionIcon from '../assets/Icons/f-religion-weiss-39.svg';
import politikIcon from '../assets/Icons/f-politik-weiss-34.svg';
import bioIcon from '../assets/Icons/f-biologie-weiss-35.svg';
import englischIcon from "../assets/Icons/f-englisch-30.svg";
import englischIconWeiss from "../assets/Icons/f-englisch-weiss-30.svg";
import plusIcon from "../assets/Icons/hinzufuegen-07.svg";
import schliessenIcon from "../assets/Icons/abbrechen-08.svg";
import sendenIcon from "../assets/Icons/senden-weiß-23.svg";
import bearbeitenIcon from "../assets/Icons/bearbeiten-komplimentär-grau-88.svg";
import runterladenIcon from '../assets/Icons/download-116.svg';

import videoPoster from "@/assets/Bilder/videoposter.png";
import * as backend from "../api/backend";
import sonneIconNormal from "@/assets/Icons/sonne-46.svg";
import bioIconNormal from "@/assets/Icons/f-biologie-35.svg";
import englischIconNormal from "@/assets/Icons/f-englisch-30.svg";
import erdkundeIconNormal from "@/assets/Icons/f-erdkunde-31.svg";
import politikIconNormal from "@/assets/Icons/f-politik-34.svg";
import religionIconNormal from "@/assets/Icons/f-religion-39.svg";
import ScrollAreaMagnifier from "@/components/ScrollAreaMagnifier";
import AdvancedQuillEditor from "@/components/Utils/AdvancedQuillEditor";
import VideoInVideo from "@/components/Utils/VideoInVideo";
import {textToSpeech} from "@/util/textToSpeech";
import FullscreenImageDialog from "@/components/Utils/FullscreenImageDialog";

export default {
name: "BrettWidget",
    components: {
        FullscreenImageDialog,
        VideoInVideo,
        ScrollAreaMagnifier,
        AdvancedQuillEditor,
        VerwaltungColorPicker,
    },
    props: {
        showTooltips: {required: false, default: true},
        currentChild: {required: false, type: Object},
    },
    data() {
        return {
            imgFullscreen: null,
            urls: [],
            group: '',
            icon: '',
            color: '',
            message: '',
            timestamp: '',
            me: {
                accessibility: {
                    screenreader: false,
                }
            },
            role: '',
            reload: false,

            blackboard: [],
            updateBlackboard: true,
            eintragPopup: false,
            brettEintrag: {title: '', message: '', icon: 'sonneIcon', color: '#fcd20a', groupId: '', blackboardId: ''},
            groups: [],
            groupsBlackboard: [],
            bearbeiten: false,
            showLoadingOverlay: false,
            teachers: [],
            newEntryTitle: '',
            videoUrl: null,
            subtitleURL: null,
            editEntryDetails: false,

            editorOption: {
                placeholder: 'Text einfügen',
                modules: {
                    toolbar: {
                        container: [
                            [{'size': ['large', false]}],
                            [{'color': []}],
                            ['bold', 'italic', 'underline'],
                            [{'list': 'ordered'}, {'list': 'bullet'}],
                            ['link', 'video'],
                        ],
                    },
                }
            },

            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false
            },

            pinIcon,
            brettIcon,
            lautsprecherIcon,
            lautsprecherAusIcon,
            infoIcon,
            plusIcon,
            schliessenIcon,
            sendenIcon,
            bearbeitenIcon,
            videoPoster,

            sonneIconNormal,
            bioIconNormal,
            englischIconNormal,
            erdkundeIconNormal,
            politikIconNormal,
            religionIconNormal,
            runterladenIcon,

            //Icon array for dynamically picking the icon via string
            icons: [
                {icon: englischIcon, text: "englischIcon"},
                {icon: sonneIcon, text: "sonneIcon"},
                {icon: englischIconWeiss, text: "englischIconWeiss"},
                {icon: politikIcon, text: "politikIcon"},
                {icon: erdkundeIcon, text: "erdkundeIcon"},
                {icon: religionIcon, text: "religionIcon"},
                {icon: bioIcon, text: "bioIcon"},
            ],

            dayname: [
                'So',
                'Mo',
                'Di',
                'Mi',
                'Do',
                'Fr',
                'Sa',
            ],
            showTextTimeout: null,
            sanitizeHtml,
        };
    },
    computed: {
        ...mapState('translation', ['targetLang',]),
        ...mapGetters("auth",["accountRole"]),
        myGroups() {
            if (this.accountRole === 'maintainer') {
                return this.groups;
            } else {
                return this.groups.filter(group => group.leaders.includes(this.me._id));
            }
        },
    },
    watch: {
        async currentChild() {
            await this.requestBlackboard();

            // Disabled image previews for now due to too much traffic
            this.loadImagePreviews();
        },
        groupsBlackboard(newValue) {
            this.brettEintrag.groupIds = [];
            if(typeof newValue === 'string' || newValue instanceof String) {
                this.groups.forEach((group) => {
                    if(group.name === newValue) {
                        this.brettEintrag.groupIds.push(group._id);
                    }
                });
            } else {
                newValue.forEach((el) => {
                    this.groups.some((group) => {
                        if(group.name === el) {
                            this.brettEintrag.groupIds.push(group._id);
                            return true;
                        }
                    });
                })
            }
            this.setTitle();
        },
        getGroupStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestGroups();
            }
        },
    },
    async mounted() {
        //Reihenfolge bitte erhalten
        await this.requestBlackboard();
        await this.requestMe();
        await this.requestGroups();
        await this.requestTeachers();
        this.calculateBadge();
        // Disabled image previews for now due to too much traffic
        this.loadImagePreviews();
    },
    async unmounted() {
        this.urls.forEach(async (url) => {
            URL.revokeObjectURL(url);
        });
        URL.revokeObjectURL(this.videoUrl);
        URL.revokeObjectURL(this.subtitleURL);
    },
    methods: {
        ...mapActions("blackboard", ["getBlackboard", "getParentBlackboard", "createBlackboardEntry", "updateBlackboardEntry", "deleteBlackboardEntry", "deleteBlackboardUpload","getBlackboardUpload"]),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang' ]),
        ...mapActions('parents', [ 'getMeParent']),
        ...mapState('auth', ['account']),
        ...mapActions("groups", ['getGroups', 'getGroup', 'setGroupInvalidStatus', 'getGroupsInfo']),
        ...mapActions("teachers", ['getTeachers', 'getMeTeacher']),
        ...mapActions("pupils", [ 'getMePupil' ]),
        ...mapActions("maintainers", ['getMeMaintainer']),
        ...mapMutations('snackbar', [ 'showSnackbar' ]),
        ...mapMutations('badges', [ 'setBlackboardBadge' ]),
        
        async clickDownload(file,blackboardId) {
            const res = await backend.getBlackboardUpload(
                blackboardId,
                file.file
            );
            let blob = await res.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = file.title;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            setTimeout(function () {
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        },
        calculateBadge() {
            let count = 0;
            for (let i = 0; i < this.blackboard.length; i++) {
                if (!this.blackboard[i].seenByMe) {
                    count++;
                }
            }
            if (count <= 0) {
                this.setBlackboardBadge(false);
            } else {
                this.setBlackboardBadge(count);
            }
        },

        displayedBlackboardItems() {
            const calculateCheckmarkStyle = (blackBoardItem) =>  {
                // Don't show checkmark for when recipient is group alle
                if (!this.myGroups.filter(item => item._id === blackBoardItem.group).length) {
                    return 'hide';
                }
                // Everybody has seen the blackboard message
                if (blackBoardItem.seenByCount >= blackBoardItem.seenByTarget) {
                    return 'all';
                }
                // Nobody has seen the blackboard message
                if (blackBoardItem.seenByCount === 0) {
                    return 'none';
                }
                return 'some';
            }

            const calculateSeenByTarget = (blackBoardItem) => {
                const group = this.groups.find(group => group._id === blackBoardItem.group);
                return group ? group.participants.length: 0;
            };

            return this.blackboard
                .map(blackBoardItem => ({
                    ...blackBoardItem,
                    seenByTarget: calculateSeenByTarget(blackBoardItem),
                }))
                .map(blackBoardItem => ({
                    ...blackBoardItem,
                    checkMarkStyle: calculateCheckmarkStyle(blackBoardItem),
                }))
                .sort((a, b) => {
                    return (a.pin === b.pin)? 0 : a.pin ? -1 : 1;
                });
        },

        async setBrettPopup(id) {
            const eintrag = this.blackboard.find(item => item._id === id);
            this.currentEntryId = id;
            this.brettEintrag.color = eintrag.color;
            this.brettEintrag.icon = eintrag.icon;
            this.brettEintrag.message = eintrag.message;
            this.brettEintrag.groupIds = [eintrag.blackboardId];
            this.brettEintrag.uploads = eintrag.uploads;
            let group = '';
            this.groups.forEach((el) => {
                if(el._id === eintrag.group) {
                    group = el.name;
                }
            });
            this.brettEintrag.title = this.me.name.slice(0, 1) + '. ' + this.me.lastName;

            let gruppenObj = await this.getGroup(eintrag.group);
            this.groupsBlackboard = gruppenObj.name;
        },
        async requestGroups() { //this is a hotfix wich works and i have no clue why so please dont break it
            this.showLoadingOverlay = true;
            this.groups =  await this.getGroupsInfo();
            this.showLoadingOverlay = false;
        },

        async requestTeachers() {
            const response = await backend.getTeachers();
            this.teachers = await response.json();
        },

        customFilter (item, queryText, itemText) {
            const textOne = item.name.toLowerCase();
            const searchText = queryText.toLowerCase();

            return textOne.indexOf(searchText) > -1
        },
        async eintragBearbeiten() {
            try {
                this.showLoadingOverlay = true;
                const _id = this.currentEntryId;
                let data = {
                    _id: _id,
                    message: this.brettEintrag.message,
                    icon: this.brettEintrag.icon,
                    color: this.brettEintrag.color,
                    group: this.brettEintrag.groupIds[0],
                    requestAccountId: this.me.account,
                };
                await this.updateBlackboardEntry(data);
                const input = this.$refs.uploadInput;
                let inputsLength = input.files.length;
                input.files.forEach((el) => {
                    const formData = new FormData();
                    formData.append('file', el);
                    const XmlHttpRequest = backend.postBlackboardFile(_id, el);

                    XmlHttpRequest.addEventListener('load', (e) => {
                        inputsLength--;
                    });
                    XmlHttpRequest.send(formData)
                });
                // while (inputsLength > 0) {
                //     await this.sleep(100);
                // }
                await this.requestBlackboard();
                await this.resetPopup();
                // Disabled image previews for now due to too much traffic
                await this.loadImagePreviews();
                this.eintragPopup = false;
                this.showLoadingOverlay = false;
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },
        async eintragLoeschen() {
            const _id = this.currentEntryId;
            let data = {
                _id: _id,
                requestAccountId: this.me.account,
            };
            await this.deleteBlackboardEntry(data);
            this.resetPopup();
            this.blackboard = await this.getBlackboard();
        },
        async uploadInputChange() {
            const input = this.$refs.uploadInput;
            input.files.forEach((el) => {
                if(!(el['type'].split('/')[0] === 'image' || el['type'].split('/')[0] === 'video' || el['type'] === 'application/pdf'
                || ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.name.split('.').pop().toLowerCase()))) {
                    this.showSnackbar({message: "Dieses Format wird nicht unterstützt.", color: "error"});
                    input.value = '';
                }
            });
        },
        async eintragErstellen() {
            try {
                this.showLoadingOverlay = true;
                const input = this.$refs.uploadInput;
                await Promise.all(this.brettEintrag.groupIds.map(async (el) => {
                    let data = {
                        message: this.brettEintrag.message,
                        icon: this.brettEintrag.icon,
                        color: this.brettEintrag.color,
                        group: el,
                        createdBy: this.me.account,
                    };

                    const res = await this.createBlackboardEntry(data);
                    let inputsLength = input.files.length;
                    for (const file of input.files) {
                        const formData = new FormData();
                        formData.append('file', file);
                        const XmlHttpRequest = await backend.postBlackboardFile(res._id, file);

                        XmlHttpRequest.addEventListener('load', (e) => {
                            inputsLength--;
                        });
                        XmlHttpRequest.send(formData)
                    }
                }));
                // Even tho the upper code gets awaited completely the requestBlackboard below often returns entry's
                // that doesn't already have the uploads included, because of that we just wait for a few ms so
                // that the ImagePreviews will get loaded correctly
                await setTimeout(async () => {
                    await this.requestBlackboard();
                    await this.resetPopup();
                    await this.loadImagePreviews();
                    this.eintragPopup = false;
                    this.showLoadingOverlay = false;
                    this.showSnackbar({
                        message: 'Eintrag erfolgreich erstellt'
                    });
                }, 500);
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },
        async removeUpload(upload) {
            this.showLoadingOverlay = true;
            const params = {
                blackboardId: this.currentEntryId,
                fileId: upload.file,
            }
            await this.deleteBlackboardUpload(params);
            this.brettEintrag.uploads = this.brettEintrag.uploads.filter((el) => el !== upload);
            await this.requestBlackboard();
            this.showLoadingOverlay = false;

        },
        resetPopup() {
            this.currentEntryId = '';
            this.brettEintrag.title = '';
            this.brettEintrag.message = '';
            this.brettEintrag.icon = 'sonneIcon';
            this.brettEintrag.color = '#fcd20a';
            this.brettEintrag.groupIds = [];
            this.brettEintrag.blackboardId = '';
            this.brettEintrag.uploads = [];
            document.getElementById("files").value = "";
            this.bearbeiten = false;
        },

        async loadImagePreviews() {
            this.urls= [];
            this.blackboard.forEach((el) => {
                el.uploads.forEach(async (file, index) => {
                    if (file.file) {
                        let res;
                        // Try getting a thumbnail
                        res = await backend.getBlackboardUploadThumbnail(file.file);
                        if (res.ok && res.status === 200) {
                            let blob = await res.blob();
                            this.urls[file._id] = window.URL.createObjectURL(blob);
                        } else {
                            // If the thumbnail is not available => load the real file
                            res = await backend.getBlackboardUpload(el._id, file.file);
                            if (res.ok && res.status === 200) {
                                let blob = await res.blob();
                                this.urls[file._id] = window.URL.createObjectURL(blob);
                            }
                        }
                        this.reload = !this.reload
                    }
                });
            });
        },
        urlify(text) {
            // eslint-disable-next-line no-useless-escape
            var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|software|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
            return text.toString().replace(urlRegex, function(url) {
                url = url.includes('http') ? url : 'http://' + url;
                return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
            })
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        },

        getSrc() {
            return this.thisSubject ? this[this.thisSubject.icon] : (this.thisAppointment ? this[this.thisAppointment.schoolSubject.icon] : null);
        },

        getBackgroundColor() {
            let returnString =  "background-color: ";
            return (returnString + this.thisAppointment.schoolSubject.color);
        },

        async requestBlackboard() {
            this.role = this.accountRole;

            if (this.role === 'parent') {
                await this.requestGroups(); // hotfix: groups were sometimes not loaded correctly when loading/refreshing
                if (this.currentChild) {
                    this.blackboard = await this.getParentBlackboard(this.currentChild._id);
                }
            } else {
                this.blackboard = await this.getBlackboard();
            }

            this.blackboard.sort((a, b) => {
                let keyA = new Date(a.createdOn),
                    keyB = new Date(b.createdOn);
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });
            this.updatedPin();
        },

        pinEntry(entry) {
            let entries = JSON.parse(localStorage.getItem('blackboard'));
            if(!entries) {
                entries = [];
            }
            if(!entries.includes(entry)) {
                entries.push(entry);
            } else {
                entries.splice(entries.indexOf(entry), 1);
            }
            localStorage.setItem('blackboard', JSON.stringify(entries));
            this.updatedPin();
        },

        updatedPin() {
            let entries = JSON.parse(localStorage.getItem('blackboard'));
            if(!entries) {
                entries = [];
            }
            this.blackboard.forEach( (el) => {
                if(entries.includes(el._id)) {
                    el['pin'] = true;
                } else {
                    el['pin'] = false;
                }
            });
            this.updateBlackboard = !this.updateBlackboard;
        },

        chooseIcon(iconName) {
          for(let i of this.icons){
            if(i.text === iconName){
              return i.icon;
            }
          }
        },

        getTitle(creatorId, groupId, aria) {
            let creator = 'Verwaltung';
            this.teachers.forEach((el) => {
                if (el.account === creatorId) {
                    creator = el.name.slice(0, 1) + '. ' + el.lastName;
                    return creator; //Hope this works like break;
                }
            });

            let group = '';
            this.groups.forEach((el) => {
                if(el._id === groupId) {
                    group = el.name;
                    return group; //Hope this works like break;
                }
            });
            if(!aria) {
                if (this.role === 'teacher') {
                    return creator + ' → ' + group;
                } else {
                    return creator;
                }
            } else {
                return 'Nachricht von ' + creator + ' an ' + group;
            }
        },

        async readText(text, event) {

            let txt;
            if (text) {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = text;
                txt = tmp.textContent || tmp.innerText || "";
            } else {
                txt = 'Dieser Eintrag hat keinen Inhalt.';
            }

            if (this.targetLang !== 'de') {
                txt = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: txt
                });

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            await textToSpeech(txt, this.targetLang, () => {
                event.target.src = lautsprecherAusIcon;
            }, () => {
                event.target.src = lautsprecherIcon;
            });
        },

        async readWidgetInfoText() {
            if(!this.me.accessibility.screenreader) {
                if ('speechSynthesis' in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis Not Supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                    return
                }

                if (!this.eintragPopup) {
                    let text;
                    if (this.accountRole === 'pupil') {
                        text = 'Das Schwarze Brett zeigt dir die aktuellen Schulnachrichten an';
                    } else { //parent and teacher
                        text = 'Das Schwarze Brett zeigt Ihnen die aktuellen Schulnachrichten an.';
                    }

                    if (this.targetLang !== 'de') {
                        text = await this.translateToTargetLang({
                            targetLang: this.targetLang, textToTranslate: text
                        });
                    }

                    if (window.speechSynthesis.speaking) {
                        window.speechSynthesis.cancel();
                        this.showTranslation(false);
                    } else {
                        this.setTranslatedText(text);
                        this.showTranslation(true);

                        if (this.showTextTimeout) {
                            clearTimeout(this.showTextTimeout);
                            this.showTextTimeout = null;
                        }

                        this.showTextTimeout = setTimeout(() => {
                            this.showTranslation(false);
                            this.showTextTimeout = null;
                        }, 15000)

                        let msg = new SpeechSynthesisUtterance();
                        msg.text = text;
                        if (this.isLangPackageAvailable()) {
                            msg.lang = this.targetLang;
                            window.speechSynthesis.speak(msg);
                        }
                    }
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },

        expandText(event) {
            //from https://stackoverflow.com/questions/54459816/vue-text-highlight-event
            const isTextHighlighting = window.getSelection().toString().trim() !== '';

            //only expand when no text is being selected
            if (!isTextHighlighting) {
                event.target.classList.toggle('fade');
                event.target.classList.toggle('initHeight');
            }
        },
        async showFullImage(blackboardId, file){
            const res = await backend.getBlackboardUpload(blackboardId, file.file);
            if(res.ok && res.status === 200){
                let blob = await res.blob();
                this.imgFullscreen = window.URL.createObjectURL(blob);
            }
        },
        async playVideo(blackboardId, file){
            if(file.subtitle) {
                const subtitle = await backend.getSubtitle(file.subtitle);
                this.subtitleURL = window.URL.createObjectURL(await subtitle.blob());
            }
            const res = await backend.getBlackboardUpload(blackboardId, file.file);
            this.videoUrl = window.URL.createObjectURL(await res.blob());
        },
        setTitle() {
            const me = this.me.name.slice(0, 1) + '. ' + this.me.lastName;
            if (!this.bearbeiten) {
                this.newEntryTitle = me;
            } else {
                this.brettEintrag.title = me;
            }
        },

        async requestMe() {
            if(this.role === 'teacher') {
                this.me = await this.getMeTeacher();
                this.me.accessibility = {
                    screenreader: false,
                };
            } else if(this.role === 'pupil') {
                this.me = await this.getMePupil();
            } /*else if (this.role === 'maintainer') {
                console.log('requesting maintainer');
                this.me = await this.getMeMaintainer();
                console.log('me:', this.me);
            }*/
        },
        myEntry(creatorId) {
            return creatorId === this.me.account;
        },
    }
}
</script>

<style>
    .ql-video {
        max-width: 100%;
    }

    .v-dialog {
        box-shadow: none;
        overflow-x: hidden;
    }

</style>

<style lang="scss" scoped>

.scroll-area {
    flex: 1;
    display: flex;
}

.brettWidgetBody {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.whiteBg {
    background-color: #ffffff;
}

.widgetContainer {
    height: auto;
    width: 100%;
    max-width: 30vw;
    min-height: 80vh;
    max-height: 80vh;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto auto 5em;
    overflow:hidden;
    display: flex;
    flex-direction: column;
}


//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.icon {
    width: 15px;
    height: 15px;
}

.dialog {
    padding-top: 50px;
    z-index: 100;
    box-shadow: none !important;
    width: 600px;
    overflow: hidden;
}

.font-style {
  font-size: larger
}

.fade {
    width: 100%;
    overflow: hidden;
    position: relative;
    max-height: 44px;
}
.fade:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;
    height: 50%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

.initHeight {
    max-height: initial !important;
}

//make text respect line breaks
.blackboardVCardText {
    white-space: pre-line;
}

.boardIcon {
    height: 30px;
    color: white !important;
    border-radius: 4px;
    padding: 5px;
}

.boardIconDialog {
  height: 25px;
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.ellipsis {
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis
}

h1 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

h2 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

.blackboardItemFooter {
    float: right;
    font-size: small;
    color: #9e9e9e;
}

.checkMarks {
    white-space: nowrap;
    font-size: small;
    line-height: 1rem !important;
    text-align: right;
}

//make text not selectable
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@media only screen and (min-width: 901px) and (max-width: 1300px){
    .timeAndDate {
        display: none;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1500px){
  .titleText {
    font-size: large;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1300px){
  .titleText {
    font-size: medium;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1200px){
  .titleText {
    font-size: small;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1050px){
  .infoIcon {
    display: none;
  }
}

#blackboardCardContainer {
  margin: auto auto;
  border-radius: 15px;
  background: white;
    height: 100%;
}

.boardIconEntries {
  height: 30px;
  color: white !important;
  border-radius: 4px;
  padding: 5px;
}

.timeAndDate {
  white-space: nowrap;
  font-size: small;
  line-height: 1rem !important;
  text-align: right;
  opacity: 0.47;
}

.popUpRow {
  width: 95%;
  margin: auto;
}

@media only screen and (max-width: 900px){
  .popUpRow {
    margin-left: 33%;
    margin-right: 66%;
  }

    .widgetContainer {
        width: 90%;
        max-width: none;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1110px){
  .timeAndDate {
    display: none;
  }
}

.iconSelect {
  width: 42px;
  padding: 2px;
  border: solid 2px #EAEAEA;
  border-radius: 10px;
  margin-right: 2px;
}

.iconSelect[selected] {
  width: 42px;
  padding: 2px;
  border: solid 2px #737373;
  border-radius: 10px;
}
.btnStyle{
    min-width: 36px;
    min-height: 36px;
    margin-left:10px;
}
</style>
