<template>
    <div class="headerBar">
        <!--#region left buttons-->
        <div class="topNavMobile">
            <JoinLehrerZimmerButton style="margin: auto 0 !important;" />

            <v-btn
                ref="topMenuBtn"
                elevation="0"
                class="btmNavBtn pa-0 animateIcon"
                style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                x-small
                :style="topMenu ? 'background-color: #6995CD !important;' : ''"
                @click="handleTopMenuClick"
            >
                <img
                    :src="menuIcon"
                    style="max-width: 25px; max-height: 25px;"
                    :style="topMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'"
                    alt="Menü"
                >
            </v-btn>

            <!--menu content-->
            <div
                v-closable="{ exclude: ['topMenuBtn'], handler: 'onCloseTop' }"
                style="z-index: 2;"
                class="d-flex flex-column pa-2 topMenu"
                :class="topMenu ? '' : 'hideMe'"
            >
                <div
                    class="d-flex flex-column"
                    style="gap: 4px"
                >
                    <!-- Pupil -->
                    <v-btn
                        to="/verwaltung/schueler"
                        active-class="activeLink"
                        class="leftNavMenuBtn"
                        elevation="0"
                    >
                        <img
                            :src="schuelerIcon"
                            class="mx-2"
                            style="max-width: 25px; max-height: 25px;"
                            alt=""
                        >
                        <div class="mr-2">
                            1. Schüler
                        </div>
                    </v-btn>

                    <!-- Teacher -->
                    <v-btn
                        to="/verwaltung/lehrer"
                        active-class="activeLink"
                        class="leftNavMenuBtn"
                        elevation="0"
                    >
                        <img
                            :src="lehrerIcon"
                            class="mx-2"
                            style="max-width: 25px; max-height: 25px;"
                            alt=""
                        >
                        <div class="mr-2">
                            2. Lehrer
                        </div>
                    </v-btn>

                    <!-- Groups -->
                    <v-btn
                        to="/verwaltung/groups"
                        active-class="activeLink"
                        class="leftNavMenuBtn"
                        elevation="0"
                    >
                        <img
                            :src="gruppenIcon"
                            class="mx-2"
                            style="max-width: 25px; max-height: 25px;"
                            alt=""
                        >
                        <div class="mr-2">
                            3. Klassen
                        </div>
                    </v-btn>

                    <!-- Subjects -->
                    <v-btn
                        to="/verwaltung/faecher"
                        active-class="activeLink"
                        class="leftNavMenuBtn"
                        elevation="0"
                    >
                        <img
                            :src="faecherIcon"
                            class="mx-2"
                            style="max-width: 25px; max-height: 25px;"
                            alt=""
                        >
                        <div class="mr-2">
                            4. Fächer
                        </div>
                    </v-btn>

                    <!-- Timeslots -->
                    <v-btn
                        to="/verwaltung/zeitslots"
                        active-class="activeLink"
                        class="leftNavMenuBtn"
                        elevation="0"
                    >
                        <img
                            :src="uhrIcon"
                            class="mx-2"
                            style="max-width: 25px; max-height: 25px;"
                            alt=""
                        >
                        <div class="mr-2">
                            5. Zeiten
                        </div>
                    </v-btn>

                    <!-- Time table -->
                    <v-btn
                        to="/verwaltung/plan"
                        active-class="activeLink"
                        class="leftNavMenuBtn"
                        elevation="0"
                    >
                        <img
                            :src="stundenplanIcon"
                            class="mx-2"
                            style="max-width: 25px; max-height: 25px;"
                            alt=""
                        >
                        <div class="mr-2">
                            6. Plan
                        </div>
                    </v-btn>
                </div>
            </div>
        </div>

        <div class="topNavMenu">
            <JoinLehrerZimmerButton style="margin: auto 0 !important;" />

            <div>
                <div style="min-height: 54px; max-height: 54px" />
                <span><b>Einrichtung in 6 Schritten:</b></span>
            </div>

            <div
                class="topNavItem d-flex align-center"
                style="flex-direction: column; flex: 1;"
            >
                <v-btn
                    link
                    to="/verwaltung/schueler"
                    active-class="activeLink"
                    x-small
                    elevation="0"
                >
                    <img
                        :src="schuelerIcon"
                        alt=""
                    >
                </v-btn>
                <div class="text-center mt-1">
                    1. Schüler
                </div>
            </div>

            <div
                class="topNavItem d-flex align-center"
                style="flex-direction: column; flex: 1;"
            >
                <v-btn
                    link
                    to="/verwaltung/lehrer"
                    active-class="activeLink"
                    x-small
                    elevation="0"
                >
                    <img
                        :src="lehrerIcon"
                        alt=""
                    >
                </v-btn>
                <div class="text-center mt-1">
                    2. Lehrer
                </div>
            </div>

            <div
                class="topNavItem d-flex align-center"
                style="flex-direction: column; flex: 1;"
            >
                <v-btn
                    link
                    to="/verwaltung/groups"
                    active-class="activeLink"
                    x-small
                    elevation="0"
                >
                    <img
                        :src="gruppenIcon"
                        alt=""
                    >
                </v-btn>
                <div class="text-center mt-1">
                    3. Klassen
                </div>
            </div>

            <div
                class="topNavItem d-flex align-center"
                style="flex-direction: column; flex: 1;"
            >
                <v-btn
                    link
                    to="/verwaltung/faecher"
                    active-class="activeLink"
                    x-small
                    elevation="0"
                >
                    <img
                        :src="faecherIcon"
                        alt=""
                    >
                </v-btn>
                <div class="text-center mt-1">
                    4. Fächer
                </div>
            </div>

            <div
                class="topNavItem d-flex align-center"
                style="flex-direction: column; flex: 1;"
            >
                <v-btn
                    link
                    to="/verwaltung/zeitslots"
                    active-class="activeLink"
                    x-small
                    elevation="0"
                >
                    <img
                        :src="uhrIcon"
                        alt=""
                    >
                </v-btn>
                <div class="text-center mt-1">
                    5. Zeiten
                </div>
            </div>

            <div
                class="topNavItem d-flex align-center"
                style="flex-direction: column; flex: 1;"
            >
                <v-btn
                    link
                    to="/verwaltung/plan"
                    active-class="activeLink"
                    x-small
                    elevation="0"
                >
                    <img
                        :src="stundenplanIcon"
                        alt=""
                    >
                </v-btn>
                <div class="text-center mt-1">
                    6. Plan
                </div>
            </div>
        </div>

        <!--#endregion-->

        <!--#region right buttons-->
        <div
            class="d-flex justify-center"
            style="gap: 16px"
        >
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        to="../verwaltung/messages"
                        active-class="activeLink"
                        x-small
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <img
                            :src="chatIcon"
                            alt=""
                        >
                    </v-btn>
                </template>
                <span>Mitteilungen</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        x-small
                        elevation="0"
                        class="animateIcon"
                        v-bind="attrs"
                        @click="reloadPage()"
                        v-on="on"
                    >
                        <img
                            :src="reloadIcon"
                            style="max-width: 25px; max-height: 25px;"
                            alt=""
                        >
                    </v-btn>
                </template>
                <span>Aktualisieren</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        x-small
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => { setSupportPageOpen(true); this.rightMenu = false }"
                    >
                        <v-icon
                            alt
                            size="25"
                        >
                            mdi-face-agent
                        </v-icon>
                    </v-btn>
                </template>
                <span>Hilfe</span>
            </v-tooltip>

            <div>
                <v-btn
                    ref="rightMenuBtn"
                    elevation="0"
                    class="btmNavBtn pa-0"
                    style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                    x-small
                    :style="rightMenu ? 'background-color: #6995CD !important;' : ''"
                    @click="handleRightMenuClick"
                >
                    <img
                        :src="menuIcon"
                        style="max-width: 25px; max-height: 25px;"
                        :style="rightMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'"
                        alt="Menü rechts"
                    >
                </v-btn>

                <!--menu content-->
                <div
                    v-closable="{ exclude: ['rightMenuBtn'], handler: 'onCloseRight' }"
                    style="z-index: 2;"
                    class="d-flex flex-column pa-2 rightMenu"
                    :class="rightMenu ? '' : 'hideMe'"
                >
                    <v-btn
                        v-ripple
                        role="menuitem"
                        class="d-flex mb-2 pa-2 rightNavMenuBtn pointer"
                        depressed
                        @click="rightMenu = false; setShowDialogGDPR(true)"
                    >
                        <v-icon
                            color="grey"
                            size="25"
                        >
                            mdi-file-document-edit-outline
                        </v-icon>
                        <div class="ml-2 ma-0 text-capitalize">
                            Verträge
                        </div>
                    </v-btn>

                    <v-btn
                        v-ripple
                        role="menuitem"
                        class="d-flex mb-2 pa-2 rightNavMenuBtn pointer"
                        depressed
                        @click="changeYearDialog = true; rightMenu = false"
                    >
                        <v-icon
                            color="blau"
                            size="25"
                        >
                            mdi-swap-horizontal
                        </v-icon>
                        <p class="ml-2 ma-0 text-capitalize">
                            Schuljahrwechsel
                        </p>
                    </v-btn>

                    <v-btn
                        v-ripple
                        to="/verwaltung/profile"
                        role="menuitem"
                        class="d-flex mb-2 pa-2 rightNavMenuBtn pointer"
                        depressed
                        @click="handleProfileClick()"
                    >
                        <img
                            :src="profileIcon"
                            style="max-height: 25px"
                            alt=""
                        >
                        <p class="ml-2 ma-0 text-capitalize">
                            Profil
                        </p>
                    </v-btn>

                    <v-btn
                        v-ripple
                        role="menuitem"
                        class="d-flex pa-2 rightNavMenuBtn pointer"
                        depressed
                        @click="clickLogoutUser"
                    >
                        <img
                            :src="logoutIcon"
                            style="max-height: 25px"
                            alt=""
                        >
                        <p
                            class="ml-2 ma-0 text-capitalize"
                            style="color: #e6231e"
                        >
                            Abmelden
                        </p>
                    </v-btn>

                    <ChangeSchoolYear
                        v-if="changeYearDialog"
                        :change-year-dialog="changeYearDialog"
                        @close="() => {changeYearDialog = false}"
                    />
                </div>
            </div>
        </div>
        <!--#endregion-->
    </div>
</template>

<script>
import {mapMutations, mapActions, mapGetters, mapState} from "vuex";

import stundenplanIcon from '../../assets/Icons/timetable.svg'
import schuelerIcon from '../../assets/Icons/profile.svg'
import gruppenIcon from '../../assets/Icons/gruppen-25.svg'
import lehrerIcon from '../../assets/Icons/teacher.svg'
import faecherIcon from '../../assets/Icons/fächer-05.svg'
import uhrIcon from '../../assets/Icons/uhr-04.svg'
import logoutIcon from '../../assets/Icons/beitretensvg-red-06.svg'
import swbIcon from '../../assets/Icons/brett.svg'
import menuIcon from "@/assets/Icons/einstellungen-neu-103.svg";
import reloadIcon from "@/assets/Icons/aktualisieren-verwaltung-107.svg";
import chatIcon from "@/assets/Icons/chat-alternative-verwaltung-102.svg";
import letterIcon from "@/assets/Icons/letter.svg";
import profileIcon from "@/assets/Icons/profil-schueler.svg";
import ChangeSchoolYear from "@/components/Verwaltung/Schuljahrwechsel/ChangeSchoolYear";
import JoinLehrerZimmerButton from "@/components/Lehrer/JoinLehrerzimmerButton";
// This variable will hold the reference to document's click handler, used for the v-directive closable
let handleOutsideClick

export default {
    name: "Header",
    components: { ChangeSchoolYear, JoinLehrerZimmerButton },
    directives: {
        //directive from https://tahazsh.com/detect-outside-click-in-vue with changes from Peter Means (in comments)
        closable: {
            bind(el, binding, vnode) {
                // Here's the click/touchstart handler (it is registered below)
                handleOutsideClick = (e) => {
                    e.stopPropagation()
                    // Get the handler method name and the exclude array from the object used in v-closable
                    const { handler, exclude } = binding.value

                    // This variable indicates if the clicked element is excluded
                    let clickedOnExcludedEl = false
                    exclude.forEach(refName => {
                        // We only run this code if we haven't detected any excluded element yet
                        if (!clickedOnExcludedEl) {
                            // Get the element using the reference name
                            const excludedEl = vnode.context.$refs[refName];
                            let excludedDomEl = null;
                            if (excludedEl) {
                                // If it's a vue component grab the element, otherwise it is the element
                                excludedDomEl = excludedEl.$el ? excludedEl.$el : excludedEl;
                                clickedOnExcludedEl = excludedDomEl.contains(e.target);
                            }
                        }
                    })
                    // We check to see if the clicked element is not the dialog element and not excluded
                    if (!el.contains(e.target) && !clickedOnExcludedEl) {
                        // If the clicked element is outside the dialog and not the button,
                        // then call the outside-click handler from the same component this directive is used in
                        vnode.context[handler]()
                    }
                }
                // Register click/touchstart event listeners on the whole page
                document.addEventListener('click', handleOutsideClick)
                document.addEventListener('touchstart', handleOutsideClick)
            },

            unbind() {
                // If the element that has v-closable is removed, then
                // unbind click/touchstart listeners from the whole page
                document.removeEventListener('click', handleOutsideClick)
                document.removeEventListener('touchstart', handleOutsideClick)
            }
        }
    },
    data() {
        return {
            //#region Icons
            stundenplanIcon,
            schuelerIcon,
            gruppenIcon,
            lehrerIcon,
            faecherIcon,
            uhrIcon,
            chatIcon,
            letterIcon,
            logoutIcon,
            swbIcon,
            menuIcon,
            reloadIcon,
            profileIcon,
            //#endregion

            // Flags
            topMenu: false,
            rightMenu: false,
            changeYearDialog: false,
        };
    },
    computed: {
        ...mapGetters("support",["supportPageOpen"]),
        ...mapState("profileManagement", ["profileManagement"]),
    },
    methods: {
        ...mapActions("auth", ["logoutUser"]),
        ...mapActions("profileManagement", ["toggleProfileManagement", "closeProfileManagement"]),
        ...mapMutations("maintainer", ["setShowDialogGDPR"]),
        ...mapMutations("support", ["setSupportPageOpen"]),

        clickLogoutUser() {
            this.rightMenu = false;
            this.logoutUser();
            this.$router.push({ name: 'login' });
        },
        reloadPage() {
            this.$router.go();
        },
        /*#region topMenu methods*/
        handleTopMenuClick() {
            this.topMenu = !this.topMenu;
        },

        handleRightMenuClick() {
            this.rightMenu = !this.rightMenu;
        },
        /*#endregion*/
        // vue/no-unused-properties cannot detect methods by directives
        // and this is referenced by a directive, so make an exception
        // eslint-disable-next-line vue/no-unused-properties
        onCloseTop() {
            this.topMenu = false;
        },
        // vue/no-unused-properties cannot detect methods by directives
        // and this is also referenced by a directive, so make an exception
        // eslint-disable-next-line vue/no-unused-properties
        onCloseRight() {
            this.rightMenu = false;
        },
        handleProfileClick() {
            this.rightMenu = false;
            let profileOpen = this.profileManagement;

            if (profileOpen) {
                this.$router.push({ name: 'verwaltung.plan' });
            } else {
                this.toggleProfileManagement();
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.headerBar {
    background-color: #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 60px;
}

.topNavMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.leftNavMenuBtn {
    display: flex;
    justify-content: flex-start;
    min-height: 50px;
    height: 50px;
    border-radius: 8px;
    background-color: #F5F5F5;
    text-transform: none;
}

.rightNavMenuBtn {
    color: var(--v-dunkelgrau-base);
    background-color: var(--v-editorGrey-base);
    border-radius: 8px;
    justify-content: flex-start;
}

.headerAvatar {
    width: 20px;
    height: 20px;
}

@media only screen and (max-width: 500px),
    (max-width: 900px) and (orientation: portrait) {
    .displayHide {
        display: none;
    }

    .topPadding {
        padding-top: 12px;
    }

    .btmNav {
        display: block;
    }
}
.hideMe {
    display: none !important;
}

.topNavItem {
    width: 100px;

    // Vue buttn
    & a {
        border-radius: 8px !important;
        width: 50px !important;
        height: 50px !important;

        & img {
            max-width: 25px !important;
            max-height: 25px !important;
        }
    }
}

.topMenu {
    position: absolute;
    top: 74px;
    left: 76px;
    width: 200px;
    max-width: 200px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 4px silver;
}

.rightMenu {
    position: absolute;
    top: 102px;
    right: 60px;
    width: 200px;
    max-width: 200px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 4px silver;
}

.activeLink {
    background-color: rgba(105, 149, 205, 0.8) !important;

    & img {
        filter: brightness(1000%);
    }

    & div {
        color: white !important;
        filter: brightness(1000%);
    }
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0);
    background: linear-gradient(
        to right,
        rgba(112, 112, 112, 0.2) 0%,
        rgba(112, 112, 112, 0.2) 77%,
        rgba(112, 112, 112, 0.5) 92%,
        rgba(112, 112, 112, 0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

@keyframes shine {
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}

@media only screen and (max-width: 1300px) {
    .topNavMobile {
        display: flex;
    }

    .topNavMenu {
        display: none;
    }

    .rightMenu {
        top: 74px;
    }
}

@media only screen and (min-width: 1300px) {
    .topNavMobile {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .eklaraLogo {
        display: none;
    }
}
</style>
