var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticStyle:{"height":"100vh"}},[_c('v-progress-linear',{staticStyle:{"position":"fixed","top":"0 !important"},style:(_vm.showLoading ? {} : {opacity: 0}),attrs:{"indeterminate":"","buffer-value":"100","color":_vm.backendReachable ? 'primary' : 'error',"height":"10"}}),_c('progress-bar',{attrs:{"title":"Hochladen","show-progress":_vm.showPupilUploadProgress,"progress":_vm.pupilUploadProgress,"abort-progress":_vm.pupilXmlHttpRequest
                ? () => {
                    _vm.pupilXmlHttpRequest.abort();
                }
                : () => {}}}),_c('label',{staticStyle:{"display":"none"},attrs:{"for":"uploadInput"}},[_vm._v("Datei hochladen")]),_c('input',{ref:"uploadInput",attrs:{"id":"uploadInput","type":"file","hidden":"","label":"Datei hochladen","multiple":""},on:{"change":() => _vm.uploadInputChange()}}),_c('NameFile',{attrs:{"original-name":_vm.uploadFileName,"callback":_vm.fileNameCallback,"is-subtitle-ready":_vm.isSubtitleReady,"group":_vm.currentlyOpenAppointment ? _vm.currentlyOpenAppointment.group : null,"show-is-assignment":!(
                _vm.currentUploadGroup === 'teacherFolder' ||
                _vm.currentUploadGroup === 'privateFolder' ||
                _vm.currentUploadGroup === 'groupFolder'
            )},model:{value:(_vm.showNameFile),callback:function ($$v) {_vm.showNameFile=$$v},expression:"showNameFile"}}),(_vm.developmentMode)?_c('UserSwitch',{staticStyle:{"z-index":"5"}}):_vm._e(),_c('cookie-law'),_c('router-view'),_c('snackbar'),(_vm.showTranslatedText)?_c('v-card',{staticStyle:{"background-color":"var(--v-secondary-base)"},attrs:{"id":"translatedTextShow","type":"info"}},[_c('v-card-text',{staticClass:"py-2",staticStyle:{"height":"100%","text-align":"center"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('p',{staticClass:"mb-0 pb-2 mx-auto",staticStyle:{"color":"white !important","overflow-y":"auto"},attrs:{"id":"translatedTextText"},domProps:{"innerHTML":_vm._s(_vm.urlify(_vm.translatedText, true))}}),_c('v-icon',{staticClass:"mr-1",attrs:{"role":"button","aria-label":"Schließen","large":"","dark":"","color":"white"},on:{"click":function($event){return _vm.showTranslation(false)}}},[_vm._v(" fas fa-times ")])],1)])],1):_vm._e(),_c('keyboard'),(_vm.magnifier)?_c('magnifier'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }