import { v4 as uuidv4 } from 'uuid';
import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';
import { AuthorizationError } from '../../util/error';
import backendWebSocket from '../../api/backendWebSocket';

const defaultState = {
    stayLoggedIn: true,
    token: "",
    account: {},

    // Used for identifying users device,
    // should be generated once per device
    deviceToken: null,
};

const mutations = {
    login(state, token) {
        state.token = token;
    },
    setAccount(state, account) {
        state.account = account;
    },
    setStayLoggedIn(state, stay) {
        state.stayLoggedIn = stay;
    },
    setDeviceToken(state, deviceToken) {
        state.deviceToken = deviceToken;
    },
    logout(state) {
        state.token = "";
        state.account = null;
    },
};

const actions = {
    async loginUser({ commit, dispatch, state }, { accountName, password, twoFactor }) {
        commit('appointments/setAppointments', [], { root: true });
        const regexNoWhitespacesAtStartEnd = /^\s+|\s+$/gm;
        password = password.replace(regexNoWhitespacesAtStartEnd, '');

        if (!state.deviceToken) {
            commit('setDeviceToken', uuidv4());
        }

        const res = await backend.loginAccount({
            accountName,
            password,
            deviceToken: state.deviceToken,
        });

        if (res.status === 403 || res.status === 401) {
            commit('logout');
            throw new AuthorizationError('Login credentials seem to be wrong.');
        }
        await checkResponseStatus(200, res);

        const body = await res.json();

        // Login was valid, proceed w/ 2FA verification if applicable
        // invalid 2FA will force logout
        if (twoFactor && twoFactor.length > 0) {
            const twoFactorRes = await backend.verify2FA(body.account._id, { otp: twoFactor });
            if (twoFactorRes.status !== 201) {
                commit('logout');
                throw new AuthorizationError('2FactorAuth incorrect');
            }
        }

        const { token } = body;

        commit('login', token);
        const account = await dispatch('getAccount');

        return account;
    },
    // Request backend with current token to see if it is still valid
    // and return current user
    async revalidateUserLogin({ commit }) {
        try {
            const res = await backend.getCurrentAccount();
            await checkResponseStatus(200, res);
            const account = await res.json();
            commit('setAccount', account);
            return account;
        }
        catch (err) {
            commit('logout');
            return null
        }
    },
    async getAccount({ commit }) {
        const resCurrentAccount = await backend.getCurrentAccount();
        try {
            const account = await resCurrentAccount.json();
            commit('setAccount', account);
            return account;
        } catch (error) {
            commit('logout');

        }
    },

    async acceptTermsOfService({ dispatch }) {
        try {
            const res = await backend.acceptTermsOfService();
            await checkResponseStatus(200, res);
            dispatch('getAccount');
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async changeStayLoggedIn({ commit }, stay) {
        commit('setStayLoggedIn', stay);
    },
    async logoutUser({ commit, state }) {
        // await backend.unsubscribe({ deviceToken: state.deviceToken});
        commit('appointments/setAppointments', [], { root: true });
        commit('logout');
    },
    async isAccountNameAvailable({ commit, dispatch }, accountName) {
        try {
            const res = await backend.postNameAvailable({accountName});
            await checkResponseStatus(200, res);
            const available = await res.json();
            return available;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getCurrentAccount({ commit, dispatch }) {
        try {
            const res = await backend.getCurrentAccount();
            await checkResponseStatus(200, res);
            const account = await res.json();
            return account;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getSecurityQuestions({ commit, dispatch }) {
        try {
            const res = await backend.getSecurityQuestions();
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async updateSubscription({ commit, state }, subscription) {
        await backend.subscribe({
            subscription,
            deviceToken: this.deviceToken,
          });
    },
    async has2FA({ commit, dispatch }, accountName) {
        const res = await backend.has2FA({ accountName });
        return await res.json();
    },
    async get2FA({ commit, dispatch }, accountName) {
        const res = await backend.get2FA({ accountName });
        return await res.json();
    },
};

const getters = {
    accountId: state => state.account ? state.account._id : undefined,
    accountRole: state => state.account ? state.account.role : undefined,
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
};
