<template>
    <!-- eslint-enable vue/no-v-html -->
    <v-tooltip
        :disabled="!showTooltips"
        top
    >
        <template v-slot:activator="{ on }">
            <v-btn
                x-small
                class="pa-0"
                style="
          position: relative;
          float: right;
          width: 25px !important;
          min-width: 25px !important;
          height: 25px;
        "
                v-on="on"
            >
                <img
                    :src="papierkorbIcon"
                    height="20px"
                    alt="Nachricht löschen"
                    @click="showDeleteDialog = true"
                    @keypress.enter="showDeleteDialog = true"
                >
            </v-btn>
        </template>
        <span>Löschen</span>
        <ChatRoomEventListItemDeleteDialog
            v-if="showDeleteDialog"
            :chat-room-event="chatRoomEvent"
            @closeDialog="showDeleteDialog = false"
        />
    </v-tooltip>
</template>

<script>
import { mapState } from "vuex";
import papierkorbIcon from "../../../../assets/Icons/papierkorb-109.svg";
import ChatRoomEventListItemDeleteDialog from "./ChatRoomEventListItemDeleteDialog";

export default {
  name: "ChatRoomEventListItemDeleteButton",
  components: {
    ChatRoomEventListItemDeleteDialog,
  },
  props: {
    chatRoomEvent: { required: true, type: Object },
    // Is the current account a 'moderator' or a 'user' in this chatroom
  },
  data: () => ({
    showDeleteDialog: false,
    papierkorbIcon,
  }),
  computed: {
    ...mapState("tooltips", ["showTooltips"]),
  },
};
</script>