<template>
    <div
        id="textTaskOuter"
        ref="textTaskOuter"
        class="greyDivBorderColor"
        style="width: 100%"
    >
        <div
            :class="showTeacherUI && !isTextTaskDense ? 'greyDiv' : ''"
            :style="`margin: ${!isTextTaskDense ? '12px' : '0'}`"
        >
            <quill-editor
                v-if="showTeacherUI"
                ref="quillEditorTask"
                v-model="task"
                :class="sizeState === 0 ? 'quillEditorTextLarge' : sizeState === 1 ? 'quillEditorTextXLarge' : 'quillEditorTextXXLarge'"
                style="border: none !important"
                :style="`padding: ${!isTextTaskDense ? '12px' : '0'}`"
                :options="editorOption"
                @change="onEditorChanged"
            />
            <!-- Disable v-html xss checking entry.content is provided only by developers -->
            <!-- eslint-disable vue/no-v-html -->
            <p
                v-if="(showPupilUI || showCorrectionUI || showViewerUI)"
                style="padding: 12px"
                :class="currentFontSize"
                v-html="sanitizeHtml(task)"
            />
            <!-- eslint-enablbe vue/no-v-html -->
        </div>
        <!-- Unterer Bereich -->
        <div
            v-if="showTeacherUI"
            :class="showAnswerField ? '' : 'editorOptionsFade'"
            style="display: flex; flex-direction: column; margin: 0 12px 12px 12px"
        >
            <textarea
                v-if="showAnswerField"
                v-model="answer"
                type="text"
                placeholder="Antwort eingeben (für Autokorrektur)"
                style="padding: 24px; resize: none; outline: none !important;"
                :class="currentFontSize"
                class="greyDiv"
            />
            <v-btn
                class="greyDiv editorOptionsFade"
                depressed
                color="#ededed"
                style="flex-grow: 1; height: 48px; min-height: 48px; margin-top: 6px; cursor: pointer"
                @click="toggleAnswer"
            >
                <span
                    style="text-transform: none !important"
                    :class="showAnswerField ? 'editorColorWrong' : ''"
                >{{ showAnswerField ? 'Antwortfeld entfernen' : 'Antwortfeld hinzufügen' }}</span>
            </v-btn>
        </div>
        <div
            v-else-if="showPupilUI && showAnswerField"
            style="display: flex; flex-direction: column; margin: 0 12px 12px 12px"
        >
            <textarea
                v-model="solution"
                type="text"
                placeholder="Antwort eingeben"
                style="padding: 12px; width: 100%"
                :class="currentFontSize"
                class="greyDiv"
            />
        </div>
        <!-- Correction View -->
        <div
            v-else-if="showCorrectionUI && showAnswerField"
            class="greyDiv"
            style="display: flex; flex-direction: column; margin: 0 12px 12px 12px"
            :class="currentFontSize"
        >
            <p
                v-if="solution === answer"
                style="padding: 12px"
                class="editorColorPrimary"
            >
                {{ solution }}
            </p>
            <v-tooltip
                v-else
                bottom
                :disabled="!elementOnCurrentPage"
            >
                <template
                    v-slot:activator="{ on }"
                    :class="currentFontSize"
                >
                    <p
                        style="padding: 12px; border-radius: 12px; color: white"
                        :class="showSolution ? 'editorColorPrimaryBackground' : 'editorColorWrongBackground'"
                        v-on="on"
                        @click="showSolution = !showSolution"
                    >
                        {{ showSolution ? answer : (solution === undefined || solution === '') ? '-' : solution }}
                    </p>
                </template>
                <span style="color: white; font-size: medium !important">{{ showSolution ? 'Lösung verstecken' : 'Lösung anzeigen' }}</span>
            </v-tooltip>
        </div>
        <!-- Controls rechts rausgeschoben editorOptionsFade -->
        <div
            v-if="showTeacherUI"
            class="editorOptionsFade"
        >
            <div style="display: flex; flex-direction: column; position: absolute; right: -56px; top: 60px; width: 48px; height: 48px; min-width: 48px">
                <v-tooltip
                    right
                    :disabled="!elementOnCurrentPage"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="editorColorPrimaryBackgroundOnHover"
                            style="width: 48px; height: 48px; min-width: 48px; margin-bottom: 6px"
                            v-bind="attrs"
                            v-on="on"
                            @click="changeSize()"
                        >
                            <img
                                :src="currentSizeIcon"
                                style="height: 32px"
                                class="iconToWhiteHover"
                                alt="Größe des Elements verändern"
                            >
                        </v-btn>
                    </template>
                    <span>Größe ändern</span>
                </v-tooltip>
                <div
                    id="toolbarQuill"
                    style="display: flex; flex-direction: column; border: none !important; padding: 0 !important;"
                >
                    <!-- Color -->
                    <div
                        class="quillOptionContainer"
                        style="display: flex; flex-direction: row; border: none !important; padding: 0 !important; margin-bottom: 6px"
                    >
                        <div style="display: flex; flex-direction: row">
                            <div
                                class="quillOptionDiv"
                                style="margin-right: 6px; display: flex; flex-direction: row"
                            >
                                <v-tooltip
                                    top
                                    :disabled="!elementOnCurrentPage"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="editorColorPrimary quillOptionVBtn"
                                            style="margin-right: 6px"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon :color="selectedColor">
                                                mdi-circle
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Textfarbe ändern</span>
                                </v-tooltip>
                                <div
                                    class="colorMenuDiv quillOptionBtnExpanded"
                                    style="display: flex; flex-direction: row"
                                >
                                    <v-icon
                                        v-for="color in colors"
                                        :key="color"
                                        class="mx-2 my-auto"
                                        style="margin-top: 8px !important;"
                                        :color="color"
                                        @click="changeColor(color)"
                                    >
                                        mdi-circle
                                    </v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Formatting -->
                    <div
                        class="quillOptionContainer"
                        style="display: flex; flex-direction: row; border: none !important; padding: 0px !important;"
                    >
                        <v-tooltip
                            top
                            :disabled="!elementOnCurrentPage"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    style="display: flex; flex-direction: row"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-btn
                                        class="quillOptionVBtn editorColorPrimary"
                                        @click="formatText('underline')"
                                    >
                                        <v-icon>mdi-format-underline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        class="quillOptionVBtn quillOptionBtnExpanded editorColorPrimary"
                                        @click="formatText('bold')"
                                    >
                                        <v-icon>mdi-format-bold</v-icon>
                                    </v-btn>
                                    <v-btn
                                        class="quillOptionVBtn quillOptionBtnExpanded editorColorPrimary"
                                        @click="formatText('italic')"
                                    >
                                        <v-icon>mdi-format-italic</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                            <span>Textstil ändern</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>
        </div>

        <!-- Controls für Schüler -->
        <div
            v-else
            class="editorOptionsFade"
        >
            <div style="display: flex; flex-direction: column; position: absolute; right: -56px; top: 5px; width: 48px; height: 48px; min-width: 48px">
                <v-tooltip
                    right
                    :disabled="!elementOnCurrentPage"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="editorColorPrimaryBackgroundOnHover"
                            style="width: 48px; height: 48px; min-width: 48px; margin-bottom: 6px"
                            v-bind="attrs"
                            tabindex="-1"
                            v-on="on"
                            @click="readContents"
                        >
                            <img
                                :src="vorlesenIcon"
                                style="height: 32px"
                                class="iconToWhiteHover"
                                alt="Elementinhalt vorlesen"
                            >
                        </v-btn>
                    </template>
                    <span>Vorlesen</span>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { textToSpeech } from "@/util/textToSpeech";

import sizeSmallIcon from "@/assets/Icons/EditorRedesign/schrift_klein.svg";
import sizeMediumIcon from "@/assets/Icons/EditorRedesign/schrift_mittel.svg";
import sizeLargeIcon from "@/assets/Icons/EditorRedesign/schrift_groß.svg";
import lautsprecherIcon from '@/assets/Icons/lautsprecher-14.svg';
import lautsprecherAusIcon from '@/assets/Icons/lautsprecher-aus-90.svg';
import {v4 as uuidv4} from "uuid";
import sanitizeHtml from '../../util/sanitizeHtml';

export default {
    name: "EditorTextTask",
    props: {
        config: { type: Object, required: true },
        state: { type: String, required: true },
        tabIndex: { type: Number, required: false, default: 0 },
        canvasWidth: { type: Number, required: true },
        canvasHeight: { type: Number, required: true },
        targetLang: {type: String, required: false, default: 'de'},
        translate: {type: Boolean, required: false, default: false},
        elementOnCurrentPage: { type: Boolean, required: false },
        previewPupilUI: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            sanitizeHtml,

            task: '',
            showAnswerField: false,
            answer: '',
            solution: '',
            showSolution: false,
            sizeState: 0,

            colors: ['#8cbe46', '#e6231e', '#000000', '#8800FF', '#505faa', '#fac837', '#706f6f'],
            selectedColor: '#000000',
            sizeSmallIcon,
            sizeMediumIcon,
            sizeLargeIcon,

            // Quill Editor
            editorOption: {
                placeholder: "Text eingeben",
                formats: "size",
                modules: {
                    toolbar: '#toolbarQuill',
                },
                theme: "snow"
            },

            // Icons
            lautsprecherAusIcon,
            lautsprecherIcon,
            vorlesenIcon: lautsprecherIcon,

        }
    },
    computed: {
        ...mapGetters("auth",["accountRole", 'accountId']),

        currentSizeIcon() {
            return this.sizeState ? (this.sizeState === 2 ? this.sizeLargeIcon : this.sizeMediumIcon) : this.sizeSmallIcon;
        },
        currentFontSize() {
            return this.sizeState ? (this.sizeState === 2 ? 'editorFontSize3' : 'editorFontSize2') : 'editorFontSize1';
        },
        showPupilUI() {
            return this.exportMode || (this.state === 'pupil' && this.config.author !== this.accountId) || this.previewPupilUI;
        },
        showTeacherUI() {
            return !this.exportMode && !this.previewPupilUI && (this.state === 'creator' || this.config.author === this.accountId) && this.state !== 'teacher';
        },
        showCorrectionUI() {
            // New Modes viewerTeacher and viewerPupil to make sure pupils dont see corrections early
            return this.state === 'teacher' || this.state === 'viewerTeacher';
        },
        showViewerUI() {
            return this.state === 'viewer' || this.state === 'viewerPupil';
        },
        isTextTaskDense() {
            return this.config && this.config.taskConfig && this.config.taskConfig.dense;
        }
    },
    watch: {
        sizeState: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        answer: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        showAnswerField: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        solution: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        previewPupilUI: {
            handler(val, oldVal) {
                this.solution = "";
            }
        }
    },
    mounted() {
        // Register on text-change events to add link previews
        if(this.showTeacherUI) {
            this.$refs.quillEditorTask.quill.on('text-change', this.onEditorTextChanged);
            this.$refs.quillEditorTask.quill.on('selection-change', this.selectionChanged);
        }


        if (!this.config.taskConfig) {
            this.config.taskConfig = {};
            this.config.identifier = uuidv4();
            this.config.taskConfig.task = this.task;
            this.config.taskConfig.answer = this.answer;
            this.config.taskConfig.solution = this.solution;
            this.config.taskConfig.showAnswerField = this.showAnswerField;
            this.config.taskConfig.sizeState = this.sizeState;
        } else {
            if(this.config._id) {
                this.config.identifier = this.config._id;
            }
            this.task = this.config.taskConfig.task;
            this.answer = this.config.taskConfig.answer;
            this.solution = this.config.taskConfig.solution;
            this.showAnswerField = this.config.taskConfig.showAnswerField;
            this.sizeState = this.config.taskConfig.sizeState;
        }
    },
    methods: {
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation' ]),

        selectionChanged(val) {
            const quill = this.$refs.quillEditorTask.quill
            if(!val || (val && !val.index && !val.length)) {
                this.selectedColor = '#000000'
            } else {
                const color = quill.getFormat(val.index, val.length).color;
                if(!Array.isArray(color)) {
                    this.selectedColor = color;
                } else {
                    this.selectedColor = '#000000'
                }
            }
        },

        formatText(format) {
            const quill = this.$refs.quillEditorTask.quill
            let formats = quill.getFormat(quill.getSelection().index, quill.getSelection().length);
            switch(format) {
                case 'underline':
                    if(formats.underline) {
                        this.$refs.quillEditorTask.quill.format(format, false);
                    } else {
                        this.$refs.quillEditorTask.quill.format(format, true);
                    }
                    break;
                case 'bold':
                    if(formats.bold) {
                        this.$refs.quillEditorTask.quill.format(format, false);
                    } else {
                        this.$refs.quillEditorTask.quill.format(format, true);
                    }
                    break;
                case 'italic':
                    if(formats.italic) {
                        this.$refs.quillEditorTask.quill.format(format, false);
                    } else {
                        this.$refs.quillEditorTask.quill.format(format, true);
                    }
                    break;
            }
        },

        changeColor(color) {
            this.$refs.quillEditorTask.quill.format('color', color);
        },

        onEditorChanged(val) {
            // Trigger the input event for the v-model to work
            this.$emit('input', val.html);
        },
        onEditorTextChanged(delta, deltaOld, source) {
            // Only youtube auto preview is supported => Check if its youtube
            if (delta.ops && source === "user") {
                let url = "";
                // If the text box is empty
                if (delta.ops.length > 0 && delta.ops[0].insert &&
                    (delta.ops[0].insert.toString().startsWith("https://www.youtu") || delta.ops[0].insert.toString().startsWith("https://youtu"))) {
                    url = delta.ops[0].insert;
                } else if (delta.ops.length > 1 && delta.ops[1].insert &&
                    (delta.ops[1].insert.toString().startsWith("https://www.youtu") || delta.ops[1].insert.toString().startsWith("https://youtu"))) {
                    // If the text box is not empty
                    url = delta.ops[1].insert;
                }
                if (url.trim().length > 0) {
                    let quill = this.$refs.quillEditorTask.quill;
                    let range = quill.getSelection(true);
                    this.$refs.quillEditorTask.quill.insertText(range.index, '\n', "user");
                    // The link must be formatted as an youtube-embedded link
                    if (url.includes("watch?v="))    // If the browser url was used
                        url = url.replace("watch?v=", "embed/");
                    else if (!url.includes("embed") && url.toString().startsWith("https://youtu.be/"))   // If the url was copied from the video
                        url = url.replace("https://youtu.be/", "https://www.youtube-nocookie.com/embed/")
                    this.$refs.quillEditorTask.quill.insertEmbed(range.index + 1, 'video', url, "user");
                }

            }
            this.saveState();
        },
        toggleAnswer() {
            this.showAnswerField = !this.showAnswerField;
        },

        changeSize() {
            switch (this.sizeState) {
                case 0:
                    this.sizeState = 1;
                    break;
                case 1:
                    this.sizeState = 2;
                    break;
                case 2:
                    this.sizeState = 0;
                    break;
                default:
                    break;
            }
        },

        saveState() {
            if(!this.previewPupilUI) {
                this.config.taskConfig.task = this.task;
                this.config.taskConfig.answer = this.answer;
                this.config.taskConfig.solution = this.solution;
                this.config.taskConfig.showAnswerField = this.showAnswerField;
                this.config.taskConfig.sizeState = this.sizeState;
            }
        },

        async readContents() {
            let msg = '';
            try {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = this.task;
                msg = tmp.textContent || tmp.innerText || "";
            } catch (e) {
                msg = 'Die Aufgabe hat keinen Text zum Vorlesen.';
            }

            await textToSpeech(
                msg,
                this.targetLang,
                (text) => {
                    this.vorlesenIcon = this.lautsprecherAusIcon;
                    if (this.targetLang !== 'de') {
                        this.setTranslatedText(text);
                        this.showTranslation(true);
                    }
                },
                () => {
                    this.vorlesenIcon = this.lautsprecherIcon;
                }
            );
        },
    },
};
</script>

<style scoped lang="scss">
    input:focus {
        outline: none;
    }
    textarea:focus {
        outline: none;
    }

    .quillOptionDiv {
        width: 48px;
        min-width: 48px;
        height: 48px;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        background-color: #f5f5f5;
    }
    .colorMenuDiv {
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        background-color: #f5f5f5;
    }
    .quillOptionBtn {
        width: 32px !important;
        height: 32px !important;
        padding: 0px !important;
        margin: 8px !important;
    }

    .quillOptionVBtn {
        width: 48px !important;
        min-width: 48px !important;
        height: 48px !important;
    }

    .quillOptionBtnExpanded {
        opacity: 0;
    }

    .quillOptionContainer {
        opacity: 100;
    }

    .quillOptionContainer:hover .quillOptionBtnExpanded {
        opacity: 100;
    }
    </style>

    <style lang="scss">
    .quillEditorTextLarge div:only-child {
        font-size: large !important;
    }
    .quillEditorTextXLarge div:only-child {
        font-size: x-large !important;
    }
    .quillEditorTextXXLarge div:only-child {
        font-size: xx-large !important;
    }
    // Overriding stuff
    .ql-snow {
        border: none !important;
    }
    .ql-container {
        font-family: "eKlara Custom", sans-serif
    }
    .ql-container .ql-editor {
        min-height: 22px;
        height: 100%
    }
    .ql-editor.ql-blank::before {
        font-style: normal !important;
    }
</style>
