import * as backend from '../../api/backend';
import {checkResponseStatus} from '@/util/check';
import Vue from "vue";

const state = {
    appointments: [],
};

const getters = {
    appointments: state => state.appointments,
    appointment: state => appId => state.appointments.find(appointment => appointment._id === appId),
    uncorrectedPupilUploadsInAppointment: state => (appId, currentUpload) => {
        const appointment = state.appointments.find(appointment => appointment._id === appId);
        if (appointment) {
            return appointment.pupilUpload.find(upload =>
                upload._id !== currentUpload
                && !upload.editLeafs.length
                && ['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(upload.title.split('.').pop().toLowerCase())
            );
        }
        return null;
    },
};

const mutations = {
    setAppointments: (state, appointments) => {
        state.appointments = appointments;
    },
    addOrUpdateAppointment: (state, appointment) => {
        const index = state.appointments.findIndex(el => el.id === appointment._id);
        if (index >= 0)
            Vue.set(state.appointments, index, appointment);
        else
            state.appointments.push(appointment);
    },
    removeAppointment: (state, appointmentId) => {
        const index = state.appointments.findIndex(el => el.id === appointmentId);
        state.appointments.splice(index, 1);
    }
};

const actions = {
    async createAppointment({commit, dispatch}, params) {
        try {
            const res = await backend.postSchoolSubjectAppointment(params);
            await checkResponseStatus(201, res);
            const appointment = await res.json();
            if (appointment)
                commit('addOrUpdateAppointment', appointment);
            return appointment;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async editAppointment({commit, dispatch}, params) {
        try {
            const id = params._id
            delete params._id;
            const res = await backend.patchSchoolSubjectAppointment(id, params);
            if (res.status === 409) {
                return 409;
            }
            const appointment = await res.json();
            // Needs more work, response object isnt appointment as well
            // if (appointment)
            //     commit('addOrUpdateAppointment', appointment);
            return appointment;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async deleteAppointment({commit, dispatch}, params) {
        let res;
        let id;
        let ruleId;

        try {
            switch (params.type) {
                case 'onlyThis':
                    id = params.appointment._id;
                    res = await backend.deleteSchoolSubjectAppointment(id);
                    break;
                case 'thisAndFollowing':
                    id = params.appointment._id;
                    ruleId = params.appointment.rule;
                    res = await backend.deleteSchoolSubjectAppointmentFromRule(id, ruleId);
                    break;
                case 'allOfThem':
                    ruleId = params.appointment.rule;
                    res = await backend.deleteSchoolSubjectAppointmentRule(ruleId);
                    break;
            }

            if (res.status === 409) {
                return 409;
            }
            await checkResponseStatus(204, res);
            // Reload all the appointments, because a lot of them might have changed
            dispatch('getAppointments', true);
            return true;
        } catch (e) {
            console.error(e)
            if (res.status) {
                return res.status;
            }
            return false;
        }
    },

    async getAppointmentRuleById({commit, dispatch}, params) {
        try {
            const res = await backend.getSchoolSubjectAppointmentRuleFromId(params);
            await checkResponseStatus(200, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getAppointments({commit, dispatch, getters}, fetch = false) {
        try {
            if (getters.appointments.length > 0 && !fetch) {
                return getters.appointments;
            }
            const res = await backend.getSchoolSubjectAppointments();
            await checkResponseStatus(200, res);
            const appointments = await res.json();
            commit('setAppointments', appointments)
            return appointments;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getAppointmentsParent({commit, dispatch}, params) {

        try {
            const res = await backend.getSchoolSubjectAppointmentsParent(params);
            await checkResponseStatus(200, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async createTeacherUploadViaFileId({commit, dispatch}, params) {
        try {
            const appointmentId = params.appointmentId;
            delete params.appointmentId;
            const res = await backend.postTeacherUploadFromFileWidget(appointmentId, params);
            await checkResponseStatus(201, res);
            return true;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async postPupilUploadViaFileId({commit, dispatch}, params) {
        try {
            const appointmentId = params.appointmentId;
            delete params.appointmentId;
            const res = await backend.postPupilUploadFromFileWidget(appointmentId, params);
            await checkResponseStatus(201, res);
            return true;
        } catch (err) {
            console.error(err);
            console.error(err);
            return err?.response?.status;
        }
    }

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
