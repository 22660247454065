import '@fortawesome/fontawesome-free/css/all.css';
import 'quill/dist/quill.bubble.css'; // for bubble theme
import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import Vue from 'vue';
import LongPress from 'vue-directive-long-press';
import VueQuillEditor from 'vue-quill-editor';
import VueRouter from 'vue-router';
import vuescroll from 'vuescroll';
import App from './App.vue';
import Editor from "./components/Editor";
import PlanEltern from "./components/Eltern/PlanEltern";
import Faq from "./components/Faq";
import PlanLehrer from "./components/Lehrer/PlanLehrer";
import Login from "./components/Login";
import PlanSchuelerNormal from "./components/Schueler/PlanSchueler/PlanSchuelerNormal";
import PlanSchueler from "./components/Schueler/PlanSchueler";
import Version from "./components/Version";
import ChatVerwaltung from "./components/Verwaltung/ChatVerwaltung";
import Faecher from "./components/Verwaltung/Faecher";
import Groups from "./components/Verwaltung/Groups";
import Lehrer from "./components/Verwaltung/Lehrer";
import Plan from "./components/Verwaltung/Plan";
import Schueler from "./components/Verwaltung/Schueler";
import SchwarzesBrett from "./components/Verwaltung/SchwarzesBrett";
import ProfileManagement from "./components/ProfileManagement.vue";
import TutorialVerwaltung from "./components/Verwaltung/TutorialVerwaltung";
import Zeitslots from "./components/Verwaltung/Zeitslots";
import MessagesWidget from "./components/MessagesWidget";
import vuetify from './plugins/vuetify';
import Ripple from 'vuetify/lib/directives/ripple';
import { setServiceWorkerUpdatedHandler, setServiceWorkerUpdatingHandler } from './registerServiceWorker';
import store from './store';
import JoinExternal from "./views/JoinExternal";
import Verwaltung from "./views/Verwaltung";
import ChatV2 from './components/ChatV2/ChatWidgetV2';
import MessagesMaintainer from '@/components/Verwaltung/MessagesMaintainer';


Vue.use(VueQuillEditor, /* { default global options } */)

Vue.directive('long-press', LongPress);

Vue.config.productionTip = false;
Vue.use(VueRouter);

Vue.config.devtools = true

export const bus = new Vue();

// Direct only authenticated users with a given role to the destination route
// others will be redirected to login
const auth = (roles) => async (to, from, next) => {
    const { account, stayLoggedIn } = store.state.auth;

    // If user is not logged in on load
    if (!account) {
        next({ name: 'login' });
        return;
    }

    const revalidatedAccount = await store.dispatch('auth/revalidateUserLogin');
    if (!(revalidatedAccount && roles.find(role => role === revalidatedAccount.role))) {
        next({ name: 'login' });
        return;
    }

    // Handle cases where website is initially opened
    // e.g. when you open the page in a new tab or on relod
    if (from.name === null) {
        const eklaraRegex = /https:\/\/.*\.eklara\.de/
        // Capture cases where you come from bbb servers
        const isComingFromAEklaraUrl = eklaraRegex.test(document.referrer);
        // Capture cases where you reload from localhost or schule2go.
        const isComingFromOwnUrl = document.referrer.startsWith(location.origin);
        // If user is coming from a reload or from bbb then everything is okay
        // otherwise the user should not access the protected route and be redirected to the login
        if (!stayLoggedIn
            && !isComingFromAEklaraUrl
            && !isComingFromOwnUrl) {
            store.dispatch('auth/logoutUser');
            next({ name: 'login' });
        }
    }


    next();
}

// When user comes back from bbb a url like hn-nts.eklara.de/?appointmentId=<Insert Fancy Id Here> is called
// Based on the url eklara does not know where it should route the user if he has chosen to stay logged in.
// Because of this we lookup the saved user in vuex and route the window according to the user role.
const rerouteLoggedInUser = async (to, from, next) => {
    const { account } = store.state.auth;

    // If user is not logged in on load
    if (!account) {
        next({ name: 'login' });
        return;
    }

    const revalidatedAccount = await store.dispatch('auth/revalidateUserLogin');
    if (!revalidatedAccount) {
        next({ name: 'login' });
        return;
    }

    switch (revalidatedAccount.role) {
        case 'maintainer':
            next({ name: 'verwaltung.plan', query: to.query });
            return;
        case 'pupil':
            next({ name: 'schueler.plan', query: to.query });
            return;
        case 'teacher':
            next({ name: 'lehrer.plan', query: to.query });
            return;
        case 'parent':
            next({ name: 'eltern.plan', query: to.query });
            return;
        default:
            next({ name: 'login', query: to.query });
    }
};

const routes = [

    {
        path: '',
        beforeEnter: rerouteLoggedInUser,
    },
    {
        path: '*',
        beforeEnter: rerouteLoggedInUser,
    },
    { path: '*\\.map' }, // Exclude Javascript maps from vue router https://github.com/vuejs/vue-router/issues/927#issuecomment-350221829
    { path: '*\\.js' }, // Exclude js from vue router https://github.com/vuejs/vue-router/issues/927#issuecomment-350221829
    { path: '*\\.css' }, // Exclude css from vue router https://github.com/vuejs/vue-router/issues/927#issuecomment-350221829
    {
        path: '/version',
        name: 'version',
        component: Version,
    },
    {
        path: '/join-external/:externalBbbRoomId',
        name: 'joinExternal',
        component: JoinExternal,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/chat-v2',
        name: 'chat-v2',
        component: ChatV2,
        beforeEnter: auth(["pupil", "teacher", "parent", "maintainer"]),
    },
    {
        path: '/profile-managment',
        name: 'profile-managment',
        component: ProfileManagement,
        beforeEnter: auth(["pupil", "teacher", "parent"]),
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
        beforeEnter: auth(["pupil", "teacher", "parent"]),
    },
    {
        path: '/schueler/plan',
        name: 'schueler.plan',
        component: PlanSchueler,
        beforeEnter: auth(["pupil"]),
    },

    {
        path: '/eltern/plan',
        name: 'eltern.plan',
        component: PlanEltern,
        beforeEnter: auth(["parent"]),
    },
    {
        path: '/lehrer/plan',
        name: 'lehrer.plan',
        component: PlanLehrer,
        beforeEnter: auth(["teacher"]),
    },
    {
        path: '/editor/:mode',
        name: 'editor',
        component: Editor,
        beforeEnter: auth(["pupil", "teacher"]),
    },
    {
        path: '/verwaltung',
        component: Verwaltung,
        children: [
            {
                path: 'plan',
                name: 'verwaltung.plan',
                component: Plan,
                beforeEnter: auth(["maintainer"]),
            },
            {
                path: 'schwarzes-brett',
                name: 'verwaltung.blackboard',
                component: SchwarzesBrett,
                beforeEnter: auth(["maintainer"]),
            },
            {
                path: 'groups',
                name: 'verwaltung.groups',
                component: Groups,
                beforeEnter: auth(["maintainer"]),
            },
            {
                path: 'faecher',
                name: 'verwaltung.faecher',
                component: Faecher,
                beforeEnter: auth(["maintainer"]),
            },
            {
                path: 'lehrer',
                name: 'verwaltung.lehrer',
                component: Lehrer,
                beforeEnter: auth(["maintainer"]),
            },
            {
                path: 'schueler',
                name: 'verwaltung.schueler',
                component: Schueler,
                beforeEnter: auth(["maintainer"]),
            },
            {
                path: 'zeitslots',
                name: 'verwaltung.zeitslots',
                component: Zeitslots,
                beforeEnter: auth(["maintainer"]),
            },
            {
                path: 'tutorial',
                name: 'verwaltung.tutorial',
                component: TutorialVerwaltung,
                beforeEnter: auth(["maintainer"]),
            },
            {
                path: 'chat-verwaltung',
                name: 'verwaltung.chatWidget',
                component: ChatVerwaltung,
                beforeEnter: auth(["maintainer"]),
            },
            {
                path: 'parentalLetter',
                name: 'verwaltung.parentalLetter',
                component: MessagesWidget,
                beforeEnter: auth(["maintainer"]),
            },
            {
                path: 'faq',
                name: 'verwaltung.Faq',
                component: Faq,
                beforeEnter: auth(["maintainer"]),
            },
            {
                path: 'profile',
                name: 'verwaltung.profile',
                component: ProfileManagement,
                beforeEnter: auth(["maintainer"]),
            },
            {
                path: 'messages',
                name: 'verwaltung.messages',
                component: MessagesMaintainer,
                beforeEnter: auth(["maintainer"]),
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

// When Serviceworker is updated
// then the router should reload the window before a new page gets shown
// this updates the service worker on page switch
setServiceWorkerUpdatedHandler((event, registration) => {
    store.commit('snackbar/showSnackbar', { message: "Update heruntergeladen. Änderungen werden bei Seitenwechsel aktiviert." });
    router.afterEach((to, from, next) => {
        window.location.reload();
    });
    registration.pushManager.getSubscription().then(function (sub) {
        if (sub === null) {
            // Update UI to ask user to register for Push
            console.log('Not subscribed to push service!');
        } else {
            store.dispatch('account/updateSubscribtion', sub);
            // We have a subscription, update the database
            console.log('Subscription object: ', sub);
        }
    });

});

setServiceWorkerUpdatingHandler((serviceWorkerRegistration) => {
    store.commit('snackbar/showSnackbar', { message: "Update wird heruntergeladen" });
})

// You can set global config here.
Vue.use(vuescroll);

Vue.prototype.$vuescrollConfig = {
    bar: {
        background: '#0f0'
    }
};

function hasQueryParams(route) {
    return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
    if (!hasQueryParams(to) && hasQueryParams(from)) {
        next({ name: to.name, query: from.query });
    } else {
        next()
    }
});

const main = new Vue({
    store,
    router,
    vuetify,
    icons: {
        iconfont: 'fa',
    },
    directives: {
        Ripple,
    },
    render: h => h(App)
}).$mount('#app');

