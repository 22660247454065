<template>
    <div style="width: 90%; padding-right: 3px; padding-left: -3px">
        <progress-bar
            v-if="showTeacherUploadProgress"
            title="Hochladen"
            :show-progress="showTeacherUploadProgress"
            :progress="teacherUploadProgress"
            :abort-progress="teacherXmlHttpRequest ? () => { teacherXmlHttpRequest.abort() } : () => { }"
        />

        <div
            v-if="thisAppointment"
            class="fach pa-2 white--text"
            style="width: 100%; display: block; padding-left: 0 !important;"
        >
            <v-row
                cols="12"
                class="ml-2 font-weight-medium"
                justify="center"
                @click="menu = !menu"
            >
                <v-col
                    :cols="isRunning ? 9 : 12"
                    class="pa-0 ma-0"
                >
                    <v-badge
                        color="red"
                        :content="unseenPupilUploadsCount"
                        :value="unseenPupilUploadsCount"
                        overlap
                        left
                        offset-x="5"
                        style="z-index: 8; width: 100%; height: 48px !important;"
                    >
                        <div
                            class="pa-3 ma-0 appointmentItem"
                            :class="{ inactiveAppointment: !isRunning }"
                            :style="isRunning ? 'width: calc(100% - 3px) !important' : ''"
                            @click="() => { getMissingMembers() }"
                        >
                            <img
                                v-if="getSrc"
                                class="iconSelect"
                                :src="isRunning ? getSrcWhite : getSrc"
                                :style="isRunning ? getBackgroundColor : 'background-color: transparent'"
                                alt="Fachsymbol"
                            >

                            <div
                                v-else
                                class="iconSelect"
                            />
                            <p
                                class="mb-0 ml-1"
                                style="display: inline-block"
                            >
                                {{ thisAppointment.schoolSubject.name }}
                            </p>
                            <!--bookmark-->
                            <img
                                :src="getColorIcon"
                                alt="Fachsymbol"
                                style="position: absolute; height: 25px; top: 0; right: 10px;"
                            >
                        </div>
                        <!--paperclip-->
                        <img
                            v-if="thisAppointment.teacherUploads.length > 0 || thisAppointment.pupilUpload.length > 0"
                            alt="Anhangsymbol"
                            :src="paperclipIcon"
                            :style="isRunning ? 'right: 30px' : 'right: 27px'"
                            style="opacity: 60%; position: absolute; height: 21px; top: -9px;"
                        >
                        <!-- Class Name -->
                        <p
                            style="position: absolute; bottom: 3px; float: right; font-size: small; color: #9e9e9e;"
                            :style="`${isRunning ? 'right: 15px' : 'right: 12px; opacity: 0.4'}`"
                        >
                            {{ groupName }}
                        </p>
                    </v-badge>
                </v-col>

                <!--Kamera-Icon neben Fach-->
                <v-col
                    v-if="isRunning"
                    :cols="3"
                    class="pa-0 mr-0"

                    style="min-height: 100%"
                    @click="clickBeitreten"
                >
                    <v-btn
                        class="pa-0"
                        small
                        :class="{ isFachRunning: isRunning }"
                        style="height: 48px; width: 100%; background-color: white; border-radius: 8px;"
                    >
                        <img
                            :src="beitretenKameraIcon"
                            class="pa-0"
                            :class="{ isFachRunningIcon: isRunning }"
                            style="height: 35px !important; width: 35px !important;"
                            alt="Videokonferenz beitreten Symbol"
                        >
                    </v-btn>
                </v-col>
            </v-row>
        </div>

        <!--fach with no text and image-->
        <div
            v-else
            class="fach pa-2 white--text"
            style="width: 100%; height: 70.5px !important; display: block; padding-left: 0 !important;"
        >
            <v-row class="ml-2 font-weight-medium">
                <v-col
                    cols="12"
                    class="pa-0 ma-0"
                    style="height: 48px !important;"
                >
                    <div class="pa-3 ma-0 appointmentItem inactiveAppointment">
                        <img class="iconSelectFrei">
                        Hohlstunde
                    </div>
                </v-col>
            </v-row>
        </div>

        <v-dialog
            v-if="thisAppointment && menu"
            v-model="menu"
            :max-width="windowWidth >= 1000 ? 800 : 400"
            scrollable
            class="dialog"
            overlay-opacity="0.8"
            @click:outside="resetDialogContent()"
        >
            <!--menu that opens on click on fach in table-->
            <v-card
                id="fachPopup"
                tile
                style="color: var(--v-dunkelgrau-base);"
            >
                <v-card-title class="pa-0 ma-0">
                    <div
                        class="fach d-flex"
                        style="box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3); font-size: large; align-items: center; padding: 0 20px; height: 59px; width: 100%;"
                    >
                        <v-col
                            cols="6"
                            class="d-flex align-center pl-0"
                        >
                            <img
                                :src="getColorIcon"
                                alt="Fachsymbol"
                                style="position: absolute; right: 20%; top: 0; height: 25px"
                            >
                            <img
                                v-if="getSrc"
                                alt="Fachsymbol"
                                class="iconSelect mr-2"
                                :src="getSrcWhite"
                                style="margin-bottom: 0"
                                :style="getBackgroundColor"
                            >
                            <div style="flex: auto">
                                {{ thisAppointment.schoolSubject.name }}
                            </div>
                        </v-col>

                        <v-col
                            cols="4"
                            class="pa-0 pr-6 d-flex flex-column justify-start align-end"
                            :style="`font-size: ${windowWidth < 400 ? 'small' : 'medium'}`"
                        >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        style="text-decoration: underline;"
                                        v-on="on"
                                    >
                                        {{ groupName }}
                                    </div>
                                </template>
                                <div style="max-height: 80vh; overflow: hidden;">
                                    <span
                                        v-for="(item, index) in groupMembers"
                                        :key="item.lastName + index"
                                    >{{
                                        index === groupMembers.length - 1 ? item.name + ' ' + item.lastName.slice(0, 1) + '.' : item.name + ' ' + item.lastName.slice(0, 1) + '.' + ', '
                                    }} <br v-if="0 === (index+1) %4"></span>
                                </div>
                            </v-tooltip>
                            <!--                            <div>{{groupMembers}}</div>-->
                        </v-col>

                        <v-col
                            cols="2"
                            align-self="end"
                            :class="windowWidth >= 900 ? 'd-flex flex-row-reverse' : ''"
                        >
                            <v-btn
                                elevation="2"
                                x-small
                                class="ml-2 pa-0 btnStyle"
                                style="width: 30px; height: 30px"
                                @click="menu = false; resetDialogContent()"
                            >
                                <img
                                    :src="schliesenIcon"
                                    alt="Schließensymbol"
                                    style="height: 20px;"
                                >
                            </v-btn>
                        </v-col>
                    </div>
                </v-card-title>

                <v-card-text
                    class="pa-0 ma-0"
                    style="overflow: hidden;"
                >
                    <vue-custom-scrollbar
                        class="scroll-area-fachPopup mb-2"
                        :settings="settings"
                    >
                        <div class="mx-5">
                            <div class="mt-2">
                                <v-row>
                                    <v-col class="font-weight-bold py-2 d-flex align-center">
                                        <img
                                            :src="infoIcon"
                                            style="height: 20px;"
                                            alt="Informationssymbol"
                                        >
                                        <p class="pl-2 ma-0">
                                            Info
                                        </p>
                                    </v-col>
                                    <v-col class="text-right py-1">
                                        <v-btn
                                            v-if="update"
                                            x-small
                                            elevation="0"
                                            class="btnStyle"
                                            @click="saveNote"
                                        >
                                            <img
                                                :src="speichernIcon"
                                                alt="Speichernsymbol"
                                                style="height: 20px;"
                                            >
                                        </v-btn>
                                        <v-btn
                                            v-else
                                            x-small
                                            elevation="0"
                                            class="btnStyle"
                                            @click="updateNote"
                                        >
                                            <img
                                                :src="bearbeitenIcon"
                                                alt="Bearbeiten"
                                                style="height: 20px;"
                                            >
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <!--                                <v-textarea v-if="update" v-model="thisAppointment.note" autofocus></v-textarea>-->
                                <advanced-quill-editor
                                    v-if="update"
                                    v-model="thisAppointment.note"
                                    placeholder="Hier die Info der Stunde einfügen"
                                />
                                <vue-custom-scrollbar
                                    v-else-if="thisAppointment.note"
                                    style="font-size: medium; font-weight: normal; line-height: 25px"
                                    class="mb-4 mt-1 scroll-area"
                                    :settings="settings"
                                >
                                    <!-- Disable vue/no-v-html because appointment note is sanitized on backend-->
                                    <!-- eslint-disable vue/no-v-html -->
                                    <span
                                        class="ql-editor"
                                        v-html="sanitizeHtml(urlify(thisAppointment.note))"
                                    />
                                    <!-- eslint-enable vue/no-v-html -->
                                </vue-custom-scrollbar>
                                <div
                                    v-else
                                    class="pb-1"
                                    style="font-size: medium; color: var(--v-fgrau-base)"
                                >
                                    Tragen Sie hier die Info zum Fach ein
                                </div>
                                <v-expand-transition>
                                    <div
                                        v-show="!thisAppointment.note && update"
                                        id="infoBox"
                                        style="border: 1px solid lightgray; border-top: none;"
                                    >
                                        <v-row
                                            justify="center"
                                            align="center"
                                        >
                                            <v-col
                                                cols="auto"
                                                class="ml-1 pr-0"
                                            >
                                                <img
                                                    :src="infoIcon"
                                                    style="height: 18px;"
                                                >
                                            </v-col>
                                            <v-col
                                                class="caption pl-2"
                                                cols="10"
                                                style="display: flex; flex-flow: wrap;"
                                            >
                                                Benutzen Sie das
                                                <img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACXSURBVEhL7ZXRCcAgDETTDqRL6ESu4UZOoQu1tUQqajD9SEHoA+kJl3BGodtxAULs+BVh3ebdzJ1zkFLCHQ+tNXjvcffQJX/bOBNjRNWQk9cYY+5VNEXtoXzkzK21qGhmHrJ5CAEVzcwjmrx7LaUgp5oV157RKb59521aaq4c37oXKpp8OnMurJkrpVDxoWr+P9GQVZsDnKdflorH3FuAAAAAAElFTkSuQmCC"
                                                >
                                                -Symbol um Videos, und das
                                                <img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAChSURBVDhP5ZRRCsMgDECz0fPoKbyNf+Ip9NPT6Cn0Qu1qMzbbxuloP0ofSBLQR4jiY3wBB/PEeCjXkRYzVUpBSgmrNjjnYK3FaqHotFc4E2PE7EPRqRAixxBCjr+g9pMzlVLm9Q9dF+W9B2MMVjQDxg3OOcwW1kKtNWZbqp2+RWvhXNcgpZSwBVLaK/rmlCe1K+2l+k4ZY5i1s3fm1v8pwAT8ElNU+b/IfgAAAABJRU5ErkJggg=="
                                                >
                                                -Symbol um Bilder zu verlinken.
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-expand-transition>
                            </div>
                        </div>

                        <hr class="eHr">

                        <div class="mx-0">
                            <div class="mt-2">
                                <v-row>
                                    <v-col
                                        class="font-weight-bold py-2 mx-5 pt-0 d-flex align-center justify-space-between"
                                    >
                                        <div class="d-flex">
                                            <img
                                                :src="materialIcon"
                                                style="height: 20px;"
                                                alt="Materialsymbol"
                                            >
                                            <p class="pl-2 ma-0">
                                                Material
                                            </p>
                                        </div>

                                        <v-tooltip
                                            v-model="supFormats"
                                            v-click-outside="{ handler: closeDialog, include: include }"
                                            max-width="350"
                                            bottom
                                        >
                                            <template v-slot:activator="{}">
                                                <v-btn
                                                    elevation="0"
                                                    class="mr-1 squareBtn included"
                                                    @click="supFormats = !supFormats"
                                                >
                                                    <img
                                                        :src="infoIcon"
                                                        class="iconSize"
                                                    >
                                                </v-btn>
                                            </template>

                                            <span>Unterstützte Formate:</span>
                                            <div class="d-flex align-center">
                                                <img
                                                    :src="mikrofonIcon"
                                                    class="tinyIcon iconToWhite mr-1"
                                                >
                                                <span>Audio: AAC, MP3, M4A, OGG, WAV, WMA</span>
                                            </div>
                                            <div class="d-flex align-center">
                                                <img
                                                    :src="kameraIcon"
                                                    class="tinyIcon iconToWhite mr-1"
                                                >
                                                <span>Bilder/Texte: BMP, JPG, PDF, PNG, TIFF</span>
                                            </div>
                                            <div class="d-flex align-center">
                                                <img
                                                    :src="videoIcon"
                                                    class="tinyIcon iconToWhite mr-1"
                                                >
                                                <span>Video: FLV, MOV, MPG, MP4, M4V, WEBM, WMV</span>
                                            </div>
                                            <div class="d-flex align-center">
                                                <img
                                                    :src="exclamationIcon"
                                                    class="tinyIcon iconToWhite mr-1"
                                                >
                                                <span
                                                    style="line-height: 16px"
                                                >MS Office Dateien (z.B. DOCX, PPTX, XLSX) müssen heruntergeladen und lokal bearbeitet werden</span>
                                            </div>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <div
                                    v-if="materialUploads.length === 0"
                                    class="mx-5 pb-1"
                                    style="color: var(--v-fgrau-base); font-size: medium"
                                >
                                    Laden Sie hier Ihre Materialien hoch.
                                </div>

                                <!--up to two items if collapsed-->
                                <div class="matShort">
                                    <div
                                        v-for="(el,index) in materialUploads.slice(0, 2)"
                                        :key="el + index"
                                        :class="{ brighterFileRow: index % 2 === 1 }"
                                        style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%;"
                                    >
                                        <div
                                            style="display: flex; flex-flow: column; width: 100%;"
                                            class="py-2 px-5 d-flex justify-space-between align-center"
                                        >
                                            <div
                                                style="width: 100%; background-color: rgba(112,112,112, 0.15); border-top-left-radius: 6px; border-top-right-radius: 6px;"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                            >
                                                <div
                                                    class="fileName"
                                                    style="min-width: 42%; width: 42%"
                                                    :title="el.title"
                                                >
                                                    {{ el.title }}
                                                </div>
                                                <div class="pa-0 ml-2">
                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                            dark
                                                            x-small
                                                            class="btnStyle"
                                                            elevation="0"
                                                            style="background-color: #8CBD46;"
                                                            @click="() => { play(el); menu = false }"
                                                        >
                                                            <img
                                                                :src="rechtsIcon"
                                                                alt="Video abspielen"
                                                                style="height: 20px; filter: brightness(1000%)"
                                                            >
                                                        </v-btn>

                                                        <v-btn
                                                            v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                            dark
                                                            x-small
                                                            class="btnStyle"
                                                            elevation="0"
                                                            style="background-color: #8CBD46;"
                                                            @click="() => { playAudio(el); menu = false }"
                                                        >
                                                            <img
                                                                :src="rechtsIcon"
                                                                alt="Audio abspielen"
                                                                style="height: 20px; filter: brightness(1000%)"
                                                            >
                                                        </v-btn>
                                                        <v-btn
                                                            v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                            class="btnStyle"
                                                            x-small
                                                            elevation="0"
                                                            @click="() => openAnsicht(el.title, el.file, el._id, 'pupil')"
                                                        >
                                                            <img
                                                                :src="stiftIcon"
                                                                style="filter: brightness(60%); height: 20px;"
                                                                alt="Bearbeitensymbol"
                                                            >
                                                        </v-btn>
                                                        <v-btn
                                                            v-else
                                                            dark
                                                            x-small
                                                            class="btnStyle"
                                                            elevation="0"
                                                            style="background-color: #8CBD46;"
                                                            @click="clickShowSnackbarFileNotSupported"
                                                        >
                                                            <img
                                                                :src="infoIcon"
                                                                alt="Informationssymbol"
                                                                style="height: 20px; filter: brightness(1000%)"
                                                            >
                                                        </v-btn>
                                                    </div>

                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            class="btnStyle ml-2"
                                                            x-small
                                                            elevation="0"
                                                            @click="deleteDialog = true; elementToDelete = el"
                                                        >
                                                            <img
                                                                :src="papierkorbIcon"
                                                                style="height: 20px;"
                                                                alt="Löschensymbol"
                                                            >
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            class="btnStyle ml-2"
                                                            x-small
                                                            elevation="0"
                                                            @click="() => clickDownloadTeacherUpload(el)"
                                                        >
                                                            <img
                                                                :src="runterladenIcon"
                                                                alt="Herunterladen"
                                                                style="height: 20px;"
                                                            >
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-tooltip bottom>
                                                            <template
                                                                v-slot:activator="{ on, attrs }"
                                                            >
                                                                <v-btn
                                                                    v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase()) && (!('canPip' in el) || el.canPip)"
                                                                    v-bind="attrs"
                                                                    x-small
                                                                    class="btnStyle ml-2"
                                                                    elevation="0"
                                                                    v-on="on"
                                                                    @click="() => showUploadVideoInVideoPopup(el)"
                                                                >
                                                                    <v-icon
                                                                        color="#646464"
                                                                        size="17"
                                                                        class="fas fa-photo-video"
                                                                    />
                                                                </v-btn>
                                                            </template>
                                                            <span>Video in Video für Hörgeschädigte</span>
                                                        </v-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="urls[el._id]"
                                                class="d-flex justify-center"
                                                style="max-height: 15vh; background-color: gray; width: 100%; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;"
                                            >
                                                <!-- Display image preview -->
                                                <img
                                                    v-if="['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                    :src="urls[el._id]"
                                                    style="cursor: pointer; max-width: 345px; max-height: 15vh;"
                                                    @click="() => ['png', 'jpg', 'jpeg', 'bmp'].includes(el.title.split('.').pop().toLowerCase()) ? openAnsicht(el.title, el.file, el._id, 'pupil') : play(el, index)"
                                                >
                                                <!-- Display pdf preview -->
                                                <object
                                                    v-else-if="['pdf'].includes(el.title.split('.').pop().toLowerCase())"
                                                    type="application/pdf"
                                                    :data="urls[el._id]"
                                                    style="cursor: pointer; max-height: 100px; width: 100%"
                                                    @click="() => openEdit(el.title, el.file, el._id)"
                                                />
                                                <!-- Display video preview -->
                                                <!--                                                <video-->
                                                <!--                                                    v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"-->
                                                <!--                                                    @click="() => play(el, index)"-->
                                                <!--                                                    :src="urls[el._id]"-->
                                                <!--                                                    style="cursor: pointer; max-width: 345px; max-height: 15vh;"/>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--all items if expanded-->
                                <div class="matLong">
                                    <div
                                        v-for="(el,index) in materialUploads"
                                        :key="el + index"
                                        :class="{ brighterFileRow: index % 2 === 1 }"
                                        style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%;"
                                    >
                                        <div
                                            style="display: flex; flex-flow: column; width: 100%;"
                                            class="py-2 px-5 d-flex justify-space-between align-center"
                                        >
                                            <div
                                                style="width: 100%; background-color: rgba(112,112,112, 0.15); border-top-left-radius: 6px; border-top-right-radius: 6px;"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                            >
                                                <div
                                                    class="fileName"
                                                    style="min-width: 42%; width: 42%;"
                                                    :title="el.title"
                                                >
                                                    {{ el.title }}
                                                </div>
                                                <div class="pa-0 ml-2">
                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                            dark
                                                            x-small
                                                            class="btnStyle"
                                                            elevation="0"
                                                            style="background-color: #8CBD46;"
                                                            @click="() => { play(el); menu = false }"
                                                        >
                                                            <img
                                                                :src="rechtsIcon"
                                                                alt="Video abspielen Symbol"
                                                                style="height: 20px; filter: brightness(1000%)"
                                                            >
                                                        </v-btn>

                                                        <v-btn
                                                            v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                            dark
                                                            x-small
                                                            class="btnStyle"
                                                            elevation="0"
                                                            style="background-color: #8CBD46;"
                                                            @click="() => { playAudio(el); menu = false }"
                                                        >
                                                            <img
                                                                :src="rechtsIcon"
                                                                alt="Audio abspielen Symbol"
                                                                style="height: 20px; filter: brightness(1000%)"
                                                            >
                                                        </v-btn>
                                                        <v-btn
                                                            v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                            class="btnStyle"
                                                            x-small
                                                            elevation="0"
                                                            @click="() => openAnsicht(el.title, el.file, el._id, 'pupil')"
                                                        >
                                                            <!--                                                    <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                            <!--                                                    <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>-->
                                                            <img
                                                                :src="stiftIcon"
                                                                alt="Bearbeitensymbol"
                                                                style="filter: brightness(60%); height: 20px;"
                                                            >
                                                        </v-btn>
                                                        <v-btn
                                                            v-else
                                                            dark
                                                            x-small
                                                            class="btnStyle"
                                                            elevation="0"
                                                            style="background-color: #8CBD46;"
                                                            @click="clickShowSnackbarFileNotSupported"
                                                        >
                                                            <img
                                                                :src="infoIcon"
                                                                alt="Informationssymbol"
                                                                style="height: 20px; filter: brightness(1000%)"
                                                            >
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            class="btnStyle ml-2"
                                                            x-small
                                                            elevation="0"
                                                            @click="deleteDialog = true; elementToDelete = el"
                                                        >
                                                            <img
                                                                :src="papierkorbIcon"
                                                                alt="Löschensymbol"
                                                                style="height: 20px;"
                                                            >
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            class="btnStyle ml-2"
                                                            x-small
                                                            elevation="0"
                                                            @click="() => clickDownloadTeacherUpload(el)"
                                                        >
                                                            <img
                                                                :src="runterladenIcon"
                                                                alt="Herunterladensymbol"
                                                                style="height: 20px;"
                                                            >
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-tooltip bottom>
                                                            <template
                                                                v-slot:activator="{ on, attrs }"
                                                            >
                                                                <v-btn
                                                                    v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase()) && (!('canPip' in el) || el.canPip)"
                                                                    v-bind="attrs"
                                                                    x-small
                                                                    class="btnStyle ml-2"
                                                                    elevation="0"
                                                                    v-on="on"
                                                                    @click="() => showUploadVideoInVideoPopup(el)"
                                                                >
                                                                    <v-icon
                                                                        color="#646464"
                                                                        size="17"
                                                                        class="fas fa-photo-video"
                                                                    />
                                                                </v-btn>
                                                            </template>
                                                            <span>Video in Video für Hörgeschädigte</span>
                                                        </v-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="urls[el._id]"
                                                class="d-flex justify-center"
                                                style="max-height: 15vh; background-color: gray; width: 100%; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;"
                                            >
                                                <!-- Display image preview -->
                                                <img
                                                    v-if="['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                    :src="urls[el._id]"
                                                    style="cursor: pointer; max-width: 345px; max-height: 15vh;"
                                                    @click="() => ['png', 'jpg', 'jpeg', 'bmp'].includes(el.title.split('.').pop().toLowerCase()) ? openAnsicht(el.title, el.file, el._id, 'pupil') : play(el, index)"
                                                >
                                                <!-- Display pdf preview -->
                                                <object
                                                    v-else-if="['pdf'].includes(el.title.split('.').pop().toLowerCase())"
                                                    type="application/pdf"
                                                    :data="urls[el._id]"
                                                    style="cursor: pointer; max-height: 100px; width: 100%"
                                                    @click="() => openEdit(el.title, el.file, el._id)"
                                                />
                                                <!-- Display video preview -->
                                                <!--                                                    <video-->
                                                <!--                                                        v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"-->
                                                <!--                                                        @click="() => play(el, index)"-->
                                                <!--                                                        :src="urls[el._id]"-->
                                                <!--                                                        style="cursor: pointer; max-width: 345px; max-height: 15vh;"/>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--expand and collapse btns-->
                                <div
                                    v-if="materialUploads.length > 2"
                                    class="d-flex flex-column align-center justify-center mt-2"
                                >
                                    <v-btn
                                        class="matShort"
                                        small
                                        :style="`background-color: ${thisAppointment.schoolSubject.color};`"
                                        elevation="0"
                                        @click="() => { hideEl('matShort'); showEl('matLong') }"
                                    >
                                        <img
                                            :src="arrowDown"
                                            alt="Pfeil nach unten Symbol"
                                            style="height: 20px; filter: brightness(1000%)"
                                        >
                                    </v-btn>
                                    <p class="mb-0 matShort">
                                        Mehr anzeigen
                                    </p>

                                    <v-btn
                                        class="matLong"
                                        small
                                        :style="`background-color: ${thisAppointment.schoolSubject.color};`"
                                        elevation="0"
                                        @click="() => { hideEl('matLong'); showEl('matShort') }"
                                    >
                                        <img
                                            :src="arrowDown"
                                            alt="Pfeil nach oben Symbol"
                                            style="height: 20px; transform: rotate(180deg); filter: brightness(1000%)"
                                        >
                                    </v-btn>
                                    <p class="mb-0 matLong">
                                        Weniger anzeigen
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="my-4">
                            <input
                                id="teacherUploadInput"
                                ref="teacherUploadInput"
                                type="file"
                                hidden
                                @change="() => teacherUploadInputChange(thisAppointment._id)"
                            >

                            <div
                                class="d-flex"
                                style="justify-content: center"
                            >
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            elevation="0"
                                            class="text-capitalize px-2"
                                            style="color: var(--v-dunkelgrau-base)"
                                            v-bind="attrs"
                                            aria-label="Datei hochladen, unterstütze Formate, welche als Abgabe hochgeladen werden können: Audio: AAC, MP3, M4A, OGG, WAV, WMA. Bilder oder Texte: BMP, JPG, PDF, PNG, TIFF. Video: FLV, MOV, MPG, MP4, M4V, WEBM, WMV. MS Office Dateien (z.B. DOCX, PPTX, XLSX) müssen heruntergeladen und lokal bearbeitet werden"
                                            v-on="on"
                                        >
                                            <img
                                                :src="hochladenIcon"
                                                alt=""
                                                style="height: 20px;"
                                            >
                                            <p class="ml-2">
                                                Hochladen
                                            </p>
                                        </v-btn>
                                    </template>
                                    <div class="small-drop-down-menu">
                                        <v-btn
                                            tabindex="0"
                                            style="text-transform: inherit; color: var(--v-dunkelgrau-base)"
                                            text
                                            class="d-flex justify-start"
                                            @click="() => { fileWidgetUploadDialog = true; }"
                                        >
                                            <img
                                                :src="dateiIcon"
                                                class="iconSize mr-1"
                                            >
                                            aus eKlara-Ordner
                                        </v-btn>
                                        <v-btn
                                            tabindex="0"
                                            style="text-transform: inherit; color: var(--v-dunkelgrau-base)"
                                            text
                                            class="d-flex justify-start"
                                            @click="clickTeacherUploadButton"
                                        >
                                            <img
                                                :src="dateiIcon"
                                                class="iconSize mr-1"
                                            >
                                            Lokales Foto, Video, Datei...
                                        </v-btn>
                                    </div>
                                </v-menu>

                                <v-btn
                                    id="createEditorABsButton"
                                    class="ml-1 py-0 px-2 text-capitalize"
                                    elevation="0"
                                    aria-label="Menü zum Erstellen von eigenen Arbeitsblättern"
                                    :style="`background-color: ${thisAppointment.schoolSubject.color || 'var(--v-dunkelgrau-base)'}`"
                                    @click="openEmptyEdit('empty')"
                                >
                                    <img
                                        :src="neuesABIcon"
                                        class="iconToWhite"
                                        alt="Erstellmenü Symbol"
                                        height="20"
                                    >
                                    <p
                                        v-if="windowWidth > 500"
                                        class="ml-2"
                                    >
                                        Erstellen
                                    </p>
                                </v-btn>

                                <AudioUpload
                                    :upload="uploadFile"
                                    :appointment-id="thisAppointment._id"
                                    :teacher="true"
                                    :group="thisAppointment.group"
                                />
                            </div>
                        </div>

                        <hr class="eHr">

                        <div class="mx-0">
                            <div class="mt-2">
                                <v-row class="mb-2">
                                    <v-col
                                        class="font-weight-bold pb-0 mx-5 pt-0 d-flex align-center"
                                    >
                                        <img
                                            :src="abgabenIcon"
                                            style="height: 20px;"
                                            alt="Abgabensymbol"
                                        >
                                        <p class="pl-2 ma-0">
                                            Abgaben
                                        </p>
                                    </v-col>
                                </v-row>

                                <div>
                                    <!-- if atleast one pupil has uploaded homework -->
                                    <div v-if="thisAppointment.pupilUpload.length > 0 || isCorrectionAvailable(thisAppointment.pupilUpload) || missingAppointments.length > 0">
                                        <!-- Zu korrigieren-section -->
                                        <v-row class="mb-2">
                                            <v-col class="font-weight-bold pb-0 mx-5 pt-0 d-flex align-center">
                                                <img
                                                    :src="offenIcon"
                                                    style="height: 20px;"
                                                    alt="Zu korrigieren-Symbol"
                                                >
                                                <p class="pl-2 ma-0">
                                                    Zu korrigieren
                                                </p>
                                            </v-col>
                                        </v-row>

                                        <v-row
                                            v-if="!pupilUploadsToCorrect.length"
                                            dense
                                            class="ma-0 fileUploadPlaceholderText"
                                            align-content="center"
                                        >
                                            <p class="mb-0">
                                                Aktuell sind keine Aufgaben bereit zum Korrigieren.
                                            </p>
                                        </v-row>

                                        <!-- up to two items if collapsed -->
                                        <div class="toCorrectShort">
                                            <div
                                                v-for="(el, index) in pupilUploadsToCorrect.slice(0,2)"
                                                :key="el + index"
                                                :class="{ brighterToCorrectRow: index % 2 === 1 }"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(128, 128, 128, 0.25)"
                                            >
                                                <div
                                                    class="fileName"
                                                    style="min-width: 60%; width: 60%;"
                                                    @click="clickDownloadPupilUpload(el)"
                                                >
                                                    {{ el.title }}
                                                </div>
                                                <div class="d-flex align-center">
                                                    <div class="pa-0">
                                                        <v-badge
                                                            color="red"
                                                            content="1"
                                                            :value="!el.seenByMe"
                                                            overlap
                                                            offset-x="45"
                                                            style="z-index: 9"
                                                        >
                                                            <v-btn
                                                                v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                                dark
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                style="background-color: #8CBD46;"
                                                                @click="() => { playPupil(el, index); menu = false }"
                                                            >
                                                                <img
                                                                    :src="rechtsIcon"
                                                                    alt="Video abspielen Symbol"
                                                                    style="height: 20px; filter: brightness(1000%)"
                                                                >
                                                            </v-btn>

                                                            <v-btn
                                                                v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                                dark
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                style="background-color: #8CBD46;"
                                                                @click="() => { playAudioPupil(el, index); menu = false }"
                                                            >
                                                                <img
                                                                    :src="rechtsIcon"
                                                                    alt="Audio abspielen Symbol"
                                                                    style="height: 20px; filter: brightness(1000%)"
                                                                >
                                                            </v-btn>

                                                            <v-btn
                                                                v-else-if="!el.seenByMe && ['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                                x-small
                                                                style="background-color: #8CBD46;"
                                                                class="btnStyle"
                                                                elevation="0"
                                                                @click="() => openEdit(el.title, el.file, el._id)"
                                                            >
                                                                <img
                                                                    :src="stiftIcon"
                                                                    alt="Bearbeiten Symbol"
                                                                    style="height: 20px;"
                                                                >
                                                            </v-btn>

                                                            <v-btn
                                                                v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                @click="() => openEdit(el.title, el.file, el._id)"
                                                            >
                                                                <img
                                                                    :src="stiftIcon"
                                                                    alt="Bearbeiten Symbol"
                                                                    style="filter: brightness(60%); height: 20px;"
                                                                >
                                                            </v-btn>

                                                            <v-btn
                                                                v-else
                                                                dark
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                style="background-color: #8CBD46;"
                                                                @click="fileNotSupported(el)"
                                                            >
                                                                <img
                                                                    :src="infoIcon"
                                                                    alt="Informationssymbol"
                                                                    style="height: 20px; filter: brightness(1000%)"
                                                                >
                                                            </v-btn>
                                                        </v-badge>
                                                    </div>

                                                    <div class="pa-0 ml-2">
                                                        <!-- teacher did correction that can be downloaded -->
                                                        <v-btn
                                                            v-if="isCorrectionAvailable(el)"
                                                            x-small
                                                            class="btnStyle whiteBg"
                                                            elevation="0"
                                                            @click="() => clickDownloadPupilCorrection(el)"
                                                        >
                                                            <img
                                                                :src="bearbeitetIcon"
                                                                style="height: 20px;"
                                                                alt="Korrektur herunterladen Symbol"
                                                            >
                                                        </v-btn>

                                                        <!-- teacher hasn't done correction -->
                                                        <v-btn
                                                            v-else
                                                            elevation="0"
                                                            x-small
                                                            class="btnStyle whiteBg"
                                                            @click="clickDownloadPupilUpload(el)"
                                                        >
                                                            <img
                                                                :src="offenIcon"
                                                                style="height: 20px;"
                                                                alt="Korrektur ausstehend Symbol"
                                                            >
                                                        </v-btn>
                                                    </div>

                                                    <div class="pa-0 ml-2">
                                                        <v-btn
                                                            class="btnStyle whiteBg"
                                                            x-small
                                                            elevation="0"
                                                            @click="deleteDialog = true; elementToDelete = el"
                                                        >
                                                            <img
                                                                :src="papierkorbIcon"
                                                                style="height: 20px;"
                                                                alt="löschen-Symbol"
                                                            >
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- all items if expanded -->
                                        <div class="toCorrectLong">
                                            <div
                                                v-for="(el, index) in pupilUploadsToCorrect"
                                                :key="el + index"
                                                :class="{ brighterToCorrectRow: index % 2 === 1 }"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(128, 128, 128, 0.25)"
                                            >
                                                <div
                                                    class="fileName"
                                                    style="min-width: 60%; width: 60%;"
                                                    @click="clickDownloadPupilUpload(el)"
                                                >
                                                    {{ el.title }}
                                                </div>
                                                <div class="d-flex">
                                                    <div class="pa-0">
                                                        <v-badge
                                                            color="red"
                                                            content="1"
                                                            :value="!el.seenByMe"
                                                            overlap
                                                            offset-x="45"
                                                            style="z-index: 9"
                                                        >
                                                            <v-btn
                                                                v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                                dark
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                style="background-color: #8CBD46;"
                                                                @click="() => { playPupil(el, index); menu = false }"
                                                            >
                                                                <img
                                                                    :src="rechtsIcon"
                                                                    alt="Video abspielen Symbol"
                                                                    style="height: 20px; filter: brightness(1000%)"
                                                                >
                                                            </v-btn>

                                                            <v-btn
                                                                v-else-if="['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                                dark
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                style="background-color: #8CBD46;"
                                                                @click="() => { playAudioPupil(el, index); menu = false }"
                                                            >
                                                                <img
                                                                    :src="rechtsIcon"
                                                                    alt="Audio abspielen Symbol"
                                                                    style="height: 20px; filter: brightness(1000%)"
                                                                >
                                                            </v-btn>

                                                            <v-btn
                                                                v-else-if="!el.seenByMe && ['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                                x-small
                                                                style="background-color: #8CBD46;"
                                                                class="btnStyle"
                                                                elevation="0"
                                                                @click="() => openEdit(el.title, el.file, el._id)"
                                                            >
                                                                <img
                                                                    :src="stiftIcon"
                                                                    alt="Bearbeiten Symbol"
                                                                    style="height: 20px;"
                                                                >
                                                            </v-btn>

                                                            <v-btn
                                                                v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                @click="() => openEdit(el.title, el.file, el._id)"
                                                            >
                                                                <img
                                                                    :src="stiftIcon"
                                                                    alt="Bearbeiten Symbol"
                                                                    style="height: 20px;"
                                                                >
                                                            </v-btn>

                                                            <v-btn
                                                                v-else
                                                                dark
                                                                x-small
                                                                class="btnStyle"
                                                                elevation="0"
                                                                style="background-color: #8CBD46;"
                                                                @click="fileNotSupported(el)"
                                                            >
                                                                <img
                                                                    :src="infoIcon"
                                                                    alt="Informationssymbol"
                                                                    style="height: 20px; filter: brightness(1000%)"
                                                                >
                                                            </v-btn>
                                                        </v-badge>
                                                    </div>
                                                    <div class="pa-0 ml-2">
                                                        <!-- teacher did correction that can be downloaded -->
                                                        <v-btn
                                                            v-if="isCorrectionAvailable(el)"
                                                            x-small
                                                            class="btnStyle whiteBg"
                                                            elevation="0"
                                                            @click="() => clickDownloadPupilCorrection(el)"
                                                        >
                                                            <img
                                                                :src="bearbeitetIcon"
                                                                style="height: 20px;"
                                                                alt="Korrektur herunterladen Symbol"
                                                            >
                                                        </v-btn>
                                                        <!-- teacher hasn't done correction -->
                                                        <v-btn
                                                            v-else
                                                            elevation="0"
                                                            x-small
                                                            class="btnStyle whiteBg"
                                                            @click="clickDownloadPupilUpload(el)"
                                                        >
                                                            <img
                                                                :src="offenIcon"
                                                                style="height: 20px;"
                                                                alt="Korrektur ausstehend Symbol"
                                                            >
                                                        </v-btn>
                                                    </div>
                                                    <div class="pa-0 ml-2">
                                                        <v-btn
                                                            class="btnStyle whiteBg"
                                                            x-small
                                                            elevation="0"
                                                            @click="deleteDialog = true; elementToDelete = el"
                                                        >
                                                            <img
                                                                :src="papierkorbIcon"
                                                                style="height: 20px;"
                                                                alt="löschen-Symbol"
                                                            >
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--expand and collapse btns-->
                                        <div
                                            v-if="thisAppointment.pupilUpload.length > 2"
                                            class="d-flex justify-center mt-2"
                                        >
                                            <v-btn
                                                class="toCorrectShort"
                                                small
                                                elevation="0"
                                                @click="hideEl('toCorrectShort'); showEl('toCorrectLong')"
                                            >
                                                <img
                                                    :src="arrowDown"
                                                    style="height: 20px;"
                                                    alt="Mehr anzeigen"
                                                >
                                            </v-btn>

                                            <v-btn
                                                class="toCorrectLong"
                                                small
                                                elevation="0"
                                                @click="hideEl('toCorrectLong'); showEl('toCorrectShort')"
                                            >
                                                <img
                                                    :src="arrowDown"
                                                    style="height: 20px; transform: rotate(180deg)"
                                                    alt="Weniger anzeigen"
                                                >
                                            </v-btn>
                                        </div>

                                        <!--Nicht abgegeben-section-->
                                        <v-row class="mb-2">
                                            <v-col
                                                class="font-weight-bold pb-0 mx-5 d-flex align-center"
                                            >
                                                <img
                                                    :src="keinDocument"
                                                    style="height: 20px;"
                                                    alt="Nicht abgegeben Symbol"
                                                >
                                                <p class="pl-2 ma-0">
                                                    Nicht abgegeben
                                                </p>
                                            </v-col>
                                        </v-row>

                                        <!--up to two items if collapsed-->
                                        <div class="toDoShort">
                                            <div
                                                v-for="(el, index) in missingAppointments.slice(0,2)"
                                                :key="el + index"
                                                :class="getRowStyling(index, el)"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(230, 35, 30, 0.25)"
                                            >
                                                <div class="fileNameUndoneFiles">
                                                    {{ el.name + ' ' + el.lastName.slice(0,1) + '.: ' + el.undoneFiles.join(', ') }}
                                                </div>
                                                <!--Schüler haben noch keine Abgabe abgegeben-->
                                                <div style="display: flex">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                text
                                                                plain
                                                                elevation="0"
                                                                x-small
                                                                class="btnStyle"
                                                                color="rgba(0,0,0,0)"
                                                                v-on="on"
                                                            >
                                                                <img
                                                                    :src="keinDocument"
                                                                    alt="fehlende Abgabe-Symbol"
                                                                    style="height: 20px;"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Der Schüler hat noch nicht alle Abgaben erledigt</span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                        </div>

                                        <!--all items if expanded-->
                                        <div class="toDoLong">
                                            <div
                                                v-for="(el, index) in missingAppointments"
                                                :key="el + index"
                                                :class="getRowStyling(index, el)"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(230, 35, 30, 0.25)"
                                            >
                                                <div class="fileNameUndoneFiles">
                                                    {{ el.name + ' ' + el.lastName.slice(0,1) + '.: ' + el.undoneFiles.join(', ') }}
                                                </div>
                                                <!-- Pupil hasn't done all homework yet -->
                                                <div style="display: flex">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                text
                                                                plain
                                                                elevation="0"
                                                                x-small
                                                                class="btnStyle"
                                                                color="rgba(0,0,0,0)"
                                                                v-on="on"
                                                            >
                                                                <img
                                                                    :src="keinDocument"
                                                                    alt="fehlende Abgabe-Symbol"
                                                                    style="height: 20px;"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Der Schüler hat noch nicht alle Abgaben erledigt</span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                        </div>

                                        <!--expand and collapse btns-->
                                        <div
                                            v-if="missingAppointments.length > 2"
                                            class="d-flex justify-center mt-2"
                                        >
                                            <v-btn
                                                class="toDoShort"
                                                small
                                                elevation="0"
                                                @click="hideEl('toDoShort'); showEl('toDoLong')"
                                            >
                                                <img
                                                    :src="arrowDown"
                                                    alt="Pfeil nach unten Symbol"
                                                    style="height: 20px;"
                                                >
                                            </v-btn>

                                            <v-btn
                                                class="toDoLong"
                                                small
                                                elevation="0"
                                                @click="hideEl('toDoLong'); showEl('toDoShort')"
                                            >
                                                <img
                                                    :src="arrowDown"
                                                    alt="Pfeil nach oben Symbol"
                                                    style="height: 20px; transform: rotate(180deg)"
                                                >
                                            </v-btn>
                                        </div>

                                        <!--Korrigiert-section-->
                                        <v-row class="mb-2">
                                            <v-col
                                                class="font-weight-bold pb-0 mx-5 d-flex align-center"
                                            >
                                                <img
                                                    :src="bearbeitetIcon"
                                                    style="height: 20px;"
                                                    alt="Korrigiert Symbol"
                                                >
                                                <p class="pl-2 ma-0">
                                                    Korrigiert
                                                </p>
                                            </v-col>
                                        </v-row>

                                        <v-row
                                            v-if="!pupilUploadsCorrected.length"
                                            dense
                                            class="ma-0 fileUploadPlaceholderText"
                                            align-content="center"
                                        >
                                            <p class="mb-0">
                                                Noch ist nichts korrigiert.
                                            </p>
                                        </v-row>

                                        <div class="correctedShort">
                                            <div
                                                v-for="(el, index) in pupilUploadsCorrected.slice(0,2)"
                                                :key="el + index"
                                                :class="{ brighterCorrectedRow: index % 2 === 1 }"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%;"
                                                :style="wasMoved(el.isMoved) ? 'background-color: rgba(230, 103, 30, 0.28)' : 'background-color: rgba(60, 170, 105, 0.25)'"
                                                @click="() => wasMoved(el.isMoved) ? '' : clickDownloadPupilCorrection(el)"
                                            >
                                                <div
                                                    class="fileName"
                                                    style="width: 75%; min-width: 75%"
                                                >
                                                    {{ el.title }}
                                                </div>
                                                <div class="pa-0 ml-2">
                                                    <v-tooltip
                                                        v-if="wasMoved(el.isMoved)"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                text
                                                                plain
                                                                elevation="0"
                                                                x-small
                                                                class="btnStyle"
                                                                color="rgba(0,0,0,0)"
                                                                v-on="on"
                                                            >
                                                                <img
                                                                    :src="infoIcon"
                                                                    alt="Der Schüler hat die Datei ohne eine Abgabe abgegeben"
                                                                    style="height: 20px;"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Der Schüler hat die Datei ohne eine Abgabe abgegeben</span>
                                                    </v-tooltip>

                                                    <div
                                                        v-else
                                                        style="display: inline-block"
                                                    >
                                                        <v-btn
                                                            v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                            dark
                                                            x-small
                                                            class="btnStyle"
                                                            elevation="0"
                                                            style="background-color: #8CBD46;"
                                                            @click="clickShowSnackbarFileNotSupported"
                                                        >
                                                            <img
                                                                :src="infoIcon"
                                                                alt="Informationssymbol"
                                                                style="height: 20px; filter: brightness(1000%)"
                                                            >
                                                        </v-btn>
                                                        <v-btn
                                                            v-else
                                                            class="btnStyle"
                                                            x-small
                                                            elevation="0"
                                                            color="rgba(0,0,0,0)"
                                                            @click="(event) => { event.stopPropagation(); handleOpenAnsichtClick(el) }"
                                                        >
                                                            <v-icon style="width: 20px; color: #939393">
                                                                fas fa-eye
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                    <div
                                                        v-if="!wasMoved(el.isMoved)"
                                                        style="display: inline-block"
                                                    >
                                                        <v-btn
                                                            elevation="0"
                                                            x-small
                                                            class="btnStyle"
                                                            color="rgba(0,0,0,0)"
                                                        >
                                                            <img
                                                                :src="bearbeitetIcon"
                                                                alt="Bearbeitet-Symbol"
                                                                style="height: 20px;"
                                                            >
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="correctedLong">
                                            <div
                                                v-for="(el, index) in pupilUploadsCorrected"
                                                :key="el + index"
                                                :class="{ brighterCorrectedRow: index % 2 === 1 }"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%;"
                                                :style="wasMoved(el.isMoved) ? 'background-color: rgba(230, 103, 30, 0.28)' : 'background-color: rgba(60, 170, 105, 0.25)'"
                                                @click="() => wasMoved(el.isMoved) ? '' : clickDownloadPupilCorrection(el)"
                                            >
                                                <div
                                                    class="fileName"
                                                    style="width: 75%"
                                                >
                                                    {{ el.title }}
                                                </div>
                                                <div class="pa-0 ml-2">
                                                    <v-tooltip
                                                        v-if="wasMoved(el.isMoved)"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                text
                                                                plain
                                                                elevation="0"
                                                                x-small
                                                                class="btnStyle"
                                                                color="rgba(0,0,0,0)"
                                                                v-on="on"
                                                            >
                                                                <img
                                                                    :src="infoIcon"
                                                                    alt="Hinweis-Symbol"
                                                                    style="height: 20px;"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Der Schüler hat die Datei ohne eine Abgabe abgegeben</span>
                                                    </v-tooltip>
                                                    <div
                                                        v-else
                                                        style="display: inline-block"
                                                    >
                                                        <v-btn
                                                            v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                            dark
                                                            x-small
                                                            class="btnStyle"
                                                            elevation="0"
                                                            style="background-color: #8CBD46;"
                                                            @click="clickShowSnackbarFileNotSupported"
                                                        >
                                                            <img
                                                                :src="infoIcon"
                                                                alt="Informationssymbol"
                                                                style="height: 20px; filter: brightness(1000%)"
                                                            >
                                                        </v-btn>
                                                        <v-btn
                                                            v-else
                                                            class="btnStyle"
                                                            x-small
                                                            elevation="0"
                                                            color="rgba(0,0,0,0)"
                                                            @click="(event) => { event.stopPropagation(); handleOpenAnsichtClick(el) }"
                                                        >
                                                            <!--                                                            <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                            <v-icon
                                                                style="width: 20px; color: #939393"
                                                            >
                                                                fas fa-eye
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                    <div
                                                        v-if="!wasMoved(el.isMoved)"
                                                        style="display: inline-block"
                                                    >
                                                        <v-btn
                                                            elevation="0"
                                                            x-small
                                                            class="btnStyle"
                                                            color="rgba(0,0,0,0)"
                                                            @click="() => clickDownloadPupilCorrection(el)"
                                                        >
                                                            <img
                                                                :src="bearbeitetIcon"
                                                                alt="Bearbeitet-Symbol"
                                                                style="height: 20px;"
                                                            >
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--expand and collapse btns-->
                                        <div
                                            v-if="pupilUploadsCorrected.length > 2"
                                            class="d-flex justify-center mt-2"
                                        >
                                            <v-btn
                                                class="correctedShort"
                                                small
                                                elevation="0"
                                                @click="hideEl('correctedShort'); showEl('correctedLong')"
                                            >
                                                <img
                                                    :src="arrowDown"
                                                    alt="Mehr anzeigen"
                                                    style="height: 20px;"
                                                >
                                            </v-btn>

                                            <v-btn
                                                class="correctedLong"
                                                small
                                                elevation="0"
                                                @click="hideEl('correctedLong'); showEl('correctedShort')"
                                            >
                                                <img
                                                    :src="arrowDown"
                                                    alt="Weniger anzeigen"
                                                    style="height: 20px; transform: rotate(180deg)"
                                                >
                                            </v-btn>
                                        </div>
                                    </div>

                                    <!--there are no pupil uploads to view or correct-->
                                    <div
                                        v-else
                                        class="mx-5"
                                        style="color: var(--v-fgrau-base); font-size: medium"
                                    >
                                        Es hat noch kein Schüler eine Abgabe getätigt.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vue-custom-scrollbar>
                </v-card-text>

                <v-card-actions
                    class="pa-0 ma-0"
                    style="overflow: hidden;"
                >
                    <!--Beitreten-Button in Fach-Popup-->
                    <v-row>
                        <hr class="eHr mt-2">
                        <v-col class="text-center">
                            <v-btn
                                elevation="0"
                                class="text-capitalize"
                                style="color: white; background-color: #8CBD46; padding-left: 50px; padding-right: 50px"
                                @click="clickBeitreten"
                            >
                                <img
                                    :src="beitretenKameraIcon"
                                    alt="Videochat beitreten Symbol"
                                    class="mr-5"
                                    style="height: 20px;"
                                >
                                Beitreten
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <FileWidgetSelectionDialog
            v-if="fileWidgetUploadDialog"
            :show="fileWidgetUploadDialog"
            :subject-apponintment="thisAppointment"
            :item-selected-callback="prepareUploadViaFileWidget"
            @close="fileWidgetUploadDialog = false;"
        />

        <!-- name file and set parameters to upload from FileWidget -->
        <NameFile
            v-if="fileWidgetNameDialog"
            :show-dialog="fileWidgetNameDialog"
            :group="groupN"
            :original-name="newFileName"
            :callback="uploadViaFileWidget"
            @close="fileWidgetNameDialog = false"
        />

        <!-- Upload Video in Video Popup -->
        <UploadVideoInVideoPopup
            v-if="showUploadVideoInVideoDialog"
            ref="uploadVideoInVideo"
            :on-close="onUploadVideoDone"
        />

        <!-- popups, overlays and snackbars -->
        <v-dialog
            v-if="deleteDialog"
            v-model="deleteDialog"
            max-width="350px"
        >
            <v-card>
                <v-card-title>Löschen bestätigen</v-card-title>

                <v-card-text>
                    Wollen Sie die Datei wirklich
                    <strong>unwiderruflich</strong> löschen?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="rot"
                        dark
                        @click="clickDeleteUpload"
                    >
                        <img
                            :src="papierkorbIcon"
                            style="height: 20px; filter: brightness(1000%)"
                            class="mr-2"
                        >
                        Löschen
                    </v-btn>

                    <v-btn
                        class="mr-2 text-capitalize"
                        color="gruen"
                        dark
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            style="height: 20px; filter: brightness(1000%)"
                            class="mr-2"
                        >
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Normal Video Popup -->
        <v-dialog
            v-if="videoUrl && (subtitleURL || noSubtitle)"
            :value="videoUrl && (subtitleURL || noSubtitle)"
            overlay-opacity=".9"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="videoUrl = null; $refs.normalVideo.stop();"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        large
                        dark
                        @click="videoUrl = null; primaryVideoUrl = null; secondaryVideoUrl = null; $refs.normalVideo.stop(); subtitleURL = null; noSubtitle = false"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <!--                    <video ref="normalVideo" :src="videoUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"-->
                    <!--                           :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">-->
                    <!--                        Your browser does not support the video tag.-->
                    <!--                    </video>-->
                    <video-in-video
                        ref="normalVideo"
                        :primary-video-src="videoUrl"
                        :subtitle-src="subtitleURL"
                    />
                </v-col>
            </v-row>
        </v-dialog>

        <!-- Video in Video Popup -->
        <v-dialog
            v-if="primaryVideoUrl && secondaryVideoUrl && (subtitleURL || noSubtitle)"
            :value="primaryVideoUrl && secondaryVideoUrl && (subtitleURL || noSubtitle)"
            overlay-opacity=".9"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop(); subtitleURL = null; noSubtitle = false"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        large
                        dark
                        @click="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop(); subtitleURL = null; noSubtitle = false"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video-in-video
                        ref="videoInVideo"
                        :primary-video-src="primaryVideoUrl"
                        :secondary-video-src="secondaryVideoUrl"
                        :subtitle-src="subtitleURL"
                    />
                </v-col>
            </v-row>
        </v-dialog>

        <v-overlay
            v-if="audioUrl"
            opacity="0.8"
            z-index="95"
        >
            <v-icon
                large
                style="position: fixed; top: 50px; right: 50px; z-index: 99"
                dark
                @click="audioUrl = false"
            >
                fas fa-times
            </v-icon>
            <audio
                :src="audioUrl"
                controls
                :width="windowWidth > 900 ? '80%' : '40%%'"
                :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }"
            >Your browser does not support the video tag.
            </audio>
        </v-overlay>

        <VideoTutorial
            v-if="videoTutorial && showVideoTutorial"
            :video-tutorial="videoTutorial"
            @close="onVideoTutorialClose"
        />
    </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import * as backend from "../../api/backend";
import sanitizeHtml from '../../util/sanitizeHtml';


import beitretenKameraIcon from "../../assets/Icons/kamera-video-weiss-72.svg";
import schliesenIcon from "../../assets/Icons/abbrechen-08.svg";
import buchzeichenIcon from "../../assets/Icons/lesezeichen-01-43.svg";
import lesezeichenIcon from "../../assets/Icons/lesezeichen-01-43.svg";
import speichernIcon from "../../assets/Icons/speichern-64.svg";
import abgabenIcon from "../../assets/Icons/bearbeiten-16.svg";
import stiftIcon from "../../assets/Icons/bearbeiten-komplimentär-weiß-88.svg";
import bearbeitenIcon from "../../assets/Icons/bearbeiten-komplimentär-grau-88.svg";
import bearbeitetIcon from "../../assets/Icons/bearbeitet-17.svg";
import runterladenIcon from "../../assets/Icons/runterladen-14.svg";
import hochladenIcon from "../../assets/Icons/hochladen-15.svg";
import rechtsIcon from "../../assets/Icons/rechts-11.svg";
import offenIcon from "../../assets/Icons/offen-18.svg";
import keinDocument from '../../assets/Icons/kein-dokument-68.svg';
import uhrIcon from '../../assets/Icons/uhr-04.svg';
import toDoIcon from '../../assets/Icons/noch-zu-machen-86.svg';
import doneIcon from '../../assets/Icons/erledigt-87.svg';
import correctedIcon from '../../assets/Icons/korrigiert-88.svg';
import materialIcon from '../../assets/Icons/material-93.svg';
import arrowDown from '../../assets/Icons/unten-dropdown-filled-12.svg';
import paperclipIcon from '../../assets/Icons/Bueroklammer-94.svg';
import papierkorbIcon from '../../assets/Icons/papierkorb-110.svg';
import infoIcon from '../../assets/Icons/info-45.svg';
import mikrofonIcon from '../../assets/Icons/mikrofon-22.svg';
import kameraIcon from '../../assets/Icons/kamera-21.svg';
import videoIcon from '../../assets/Icons/kamera-video-73.svg';
import dateiIcon from '../../assets/Icons/leere-korrektur-78.svg';
import exclamationIcon from '../../assets/Icons/ausrufezeichen-62.svg';
import neuesABIcon from '../../assets/Icons/EditorRedesign/komponente.svg';

import bioIcon from "../../assets/Icons/f-biologie-35.svg";
import chemieIcon from "../../assets/Icons/f-chemie-37.svg";
import deutschIcon from "../../assets/Icons/f-deutsch-29.svg";
import englischIcon from "../../assets/Icons/f-englisch-30.svg";
import erdkundeIcon from "../../assets/Icons/f-erdkunde-31.svg";
import geschichteIcon from "../../assets/Icons/f-geschichte-40.svg";
import matheIcon from "../../assets/Icons/f-mathe-32.svg";
import musikIcon from '../../assets/Icons/f-musik-84.svg'
import physikIcon from "../../assets/Icons/f-physik-36.svg";
import politikIcon from "../../assets/Icons/f-politik-34.svg";
import religionIcon from "../../assets/Icons/f-religion-39.svg";
import sportIcon from "../../assets/Icons/f-sport-38.svg";
import wirtschaftIcon from "../../assets/Icons/f-wirtschaft-33.svg";

import bioIconWeiss from "../../assets/Icons/f-biologie-weiss-35.svg";
import chemieIconWeiss from "../../assets/Icons/f-chemie-weiss-37.svg";
import deutschIconWeiss from "../../assets/Icons/f-deutsch-weiss-29.svg";
import englischIconWeiss from "../../assets/Icons/f-englisch-weiss-30.svg";
import erdkundeIconWeiss from "../../assets/Icons/f-erdkunde-weiss-31.svg";
import geschichteIconWeiss from "../../assets/Icons/f-geschichte-weiss-40.svg";
import matheIconWeiss from "../../assets/Icons/f-mathe-weiss-32.svg";
import musikIconWeiss from "@/assets/Icons/f-musik-weiss-82.svg";
import physikIconWeiss from "../../assets/Icons/f-physik-weiss-36.svg";
import politikIconWeiss from "../../assets/Icons/f-politik-weiss-34.svg";
import religionIconWeiss from "../../assets/Icons/f-religion-weiss-39.svg";
import sportIconWeiss from "../../assets/Icons/f-sport-weiss-38.svg";
import wirtschaftIconWeiss from "../../assets/Icons/f-wirtschaft-weiss-33.svg";

import colorfcd20a from "../../assets/Icons/lesezeichen-gelb-47.svg";
import colorf0b423 from "../../assets/Icons/lesezeichen-orange-48.svg";
import colorff6941 from "../../assets/Icons/lesezeichen-rot-49.svg";
import color91c364 from "../../assets/Icons/lesezeichen-hellgrün-50.svg";
import color91c887 from "../../assets/Icons/lesezeichen-mintgrün-51.svg";
import colord7875f from "../../assets/Icons/lesezeichen-braun-52.svg";
import colorebaabe from "../../assets/Icons/lesezeichen-rosa-53.svg";
import color9b91c8 from "../../assets/Icons/lesezeichen-lila-54.svg";
import color6ec3dc from "../../assets/Icons/lesezeichen-blau-55.svg";
import color9bcdc3 from "../../assets/Icons/lesezeichen-minze-56.svg";
import colorb2b2b2 from "../../assets/Icons/lesezeichen-hellgrau-43.svg";
import color707070 from "../../assets/Icons/lesezeichen-dunkelgrau-44.svg";
// new colors
import colorb748c7 from "../../assets/Icons/lesezeichen-custom-lila.svg";
import color5c55a7 from "../../assets/Icons/lesezeichen-custom-blau.svg";
import color529f65 from "../../assets/Icons/lesezeichen-custom-gruen.svg";
import colorf0f0f0 from "../../assets/Icons/lesezeichen-custom-weiss.svg";

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import ProgressBar from "@/components/ProgressBar";
import AudioUpload from "../AudioUpload";
import UploadVideoInVideoPopup from "@/components/Lehrer/UploadVideoInVideoPopup";
import VideoInVideo from "@/components/Utils/VideoInVideo";
import AdvancedQuillEditor from "@/components/Utils/AdvancedQuillEditor";
import leeresBlatt from "@/assets/Icons/leeres-blatt-110.svg";
import kariertesBlatt from "@/assets/Icons/kariertes blatt-112.svg";
import liniertesBlatt from "@/assets/Icons/liniertes-blatt-111.svg";
import dgs_hands from "@/assets/Bilder/dgs_hands.jpg";
import FileWidgetSelectionDialog from "@/components/Utils/FileWidgetSelectionDialog";
import NameFile from "@/components/NameFile";
import VideoTutorial from "../../components/Tutorial/VideoTutorial.vue";

export default {
    name: "FachLehrer",
    components: {
        NameFile,
        FileWidgetSelectionDialog,
        AdvancedQuillEditor,
        VideoInVideo,
        UploadVideoInVideoPopup,
        AudioUpload,
        ProgressBar,
        vueCustomScrollbar,
        VideoTutorial,
    },
    props: {
        appointment: {required: false, default: null},
        requestAppointments: {required: true},
        isRunning: {type: Boolean, required: false, default: false},
        isOpen: {type: Boolean, required: false, default: false},
    },
    data() {
        return {
            sanitizeHtml,

            subtitleURL: null,
            noSubtitle: false,
            urls: [],
            missingAppointments: [],
            pupilUploadsCorrected: [],
            menu: false,
            thisAppointment: null,
            update: false,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
            showTeacherUploadProgress: false,
            teacherUploadProgress: 0.0,
            teacherXmlHttpRequest: null,
            showNameFile: false,
            uploadFileName: "",
            fileNameCallback: () => {
            },
            group: null,
            bbbTutState: null,
            bbbTut: false,
            deleteDialog: false,
            elementToDelete: null,

            teacherMaterials: 0,

            primaryVideoUrl: null,
            secondaryVideoUrl: null,
            videoUrl: null,
            audioUrl: null,

            groupName: '',
            groupMembers: '',
            uploadingVideoInVideo: false,

            supFormats: false,      //supported formats tooltip next to Hochladen-btn

            //upload files from FileWidget
            me: null,
            fileWidgetUploadDialog: false,
            privateFolderFull: [],  //Full are the complete arrays
            subjectFolderFull: [],
            teacherFolderFull: [],
            privateFolder: [],      //these are the arrays that are being displayed
            subjectFolder: [],      //and thus they may be sliced to show only
            teacherFolder: [],      //a few elements
            privateFolderSliced: true,
            subjectFolderSliced: true,  //reference if folder-array is sliced currently
            teacherFolderSliced: true,  //to toggle between full and sliced array
            loadFolders: false,

            fileWidgetNameDialog: false,
            modal: false,
            fileToUpload: null,
            groupN: null,
            newFileName: '',
            selectedUsers: [],
            startDate: new Date().toISOString().substr(0, 10),
            isAssignment: null,
            showUploadVideoInVideoDialog: false,

            dgs_hands,
            beitretenKameraIcon,
            schliesenIcon,
            buchzeichenIcon,
            speichernIcon,
            abgabenIcon,
            stiftIcon,
            bearbeitenIcon,
            bearbeitetIcon,
            runterladenIcon,
            hochladenIcon,
            rechtsIcon,
            lesezeichenIcon,
            offenIcon,
            keinDocument,
            uhrIcon,
            toDoIcon,
            doneIcon,
            correctedIcon,
            materialIcon,
            arrowDown,
            paperclipIcon,
            papierkorbIcon,
            infoIcon,
            mikrofonIcon,
            kameraIcon,
            videoIcon,
            dateiIcon,
            exclamationIcon,
            neuesABIcon,
            leeresBlatt,
            kariertesBlatt,
            liniertesBlatt,

            bioIcon,
            chemieIcon,
            deutschIcon,
            englischIcon,
            erdkundeIcon,
            geschichteIcon,
            matheIcon,
            musikIcon,
            physikIcon,
            politikIcon,
            religionIcon,
            sportIcon,
            wirtschaftIcon,

            bioIconWeiss,
            chemieIconWeiss,
            deutschIconWeiss,
            englischIconWeiss,
            erdkundeIconWeiss,
            geschichteIconWeiss,
            matheIconWeiss,
            musikIconWeiss,
            physikIconWeiss,
            politikIconWeiss,
            religionIconWeiss,
            sportIconWeiss,
            wirtschaftIconWeiss,

            colorfcd20a,
            colorf0b423,
            colorff6941,
            color91c364,
            color91c887,
            colord7875f,
            colorebaabe,
            color9b91c8,
            color6ec3dc,
            color9bcdc3,
            colorb2b2b2,
            color707070,
            // new colors
            colorb748c7,
            color5c55a7,
            color529f65,
            colorf0f0f0,

            // Show video Tutorial is set to true when button is clicked
            videoTutorial: false,
            showVideoTutorial: false,
        };
    },
    computed: {
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
        ...mapState("auth", ["token"]),
        ...mapState('util', ['windowWidth']),
        pupilUploadsToCorrect() {
            return this.thisAppointment.pupilUpload.filter(item => !item.editLeafs || item.editLeafs.length === 0);
        },
        unseenPupilUploadsCount() {
            return this.thisAppointment.pupilUpload.filter(item => !item.seenByMe).length || 0;
        },
        materialUploads() {
            return this.thisAppointment.teacherUploads.filter((item) => !item.editParent);
        },
        getSrc() {
            return this.thisSubject
                ? this[this.thisSubject.icon]
                : this.thisAppointment
                    ? this[this.thisAppointment.schoolSubject.icon]
                    : null;
        },
        getSrcWhite() {
            /**
             * Cant be done like the top one because the second part of string doesnt match with the normal icon,
             * so a search is probably needed
             */
            let temp = this.getSrc;
            let searchString = temp.slice(0, temp.length - 16) + "-weiss";
            let returnString = '';

            for (let i = 0; i < Object.keys(this.$data).length; i++) {
                let key = Object.keys(this.$data)[i];
                if (this.$data[key] && this.$data[key].includes && this.$data[key].includes(searchString)) {
                    returnString = this.$data[key];
                    break;
                }
            }

            return returnString.length > 0 ? returnString : temp;
        },

        getColorIcon() {
            return this.thisSubject
                ? this["color" + this.thisSubject.color.substring(1)]
                : this.thisAppointment
                    ? this["color" + this.thisAppointment.schoolSubject.color.substring(1)]
                    : this.colorb2b2b2;
        },

        getBackgroundColor() {
            let returnString = "background-color: ";
            return returnString + this.thisAppointment.schoolSubject.color;
        }
    },
    watch: {
        appointment(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.thisAppointment = JSON.parse(JSON.stringify(this.appointment));
                this.getGroupName();
            }
        },
        async menu(newVal, oldVal) {
            if (newVal && !oldVal && this.thisAppointment) {
                await this.loadImagePreviews();
                await this.$router.replace({query: {appointment: this.thisAppointment._id}});
            } else if (!newVal && oldVal) {
                await this.$router.replace({query: {appointment: undefined}});
            }
        },
        isOpen() {
            if (!this.menu) {
                this.menu = this.isOpen;
                this.getMissingMembers();
                this.$router.replace({
                    query: {
                        appointment: undefined,
                    },
                });
            }
        },
    },
    created() {
        this.checkTutorialState();
        this.thisAppointment = JSON.parse(JSON.stringify(this.appointment));

        if(this.thisAppointment) {
            this.getGroupName();
        }
    },
    async mounted() {
        if (this.isOpen && !this.menu) {
            this.menu = this.isOpen;
            await this.getMissingMembers();
            await this.$router.replace({
                query: {
                    appointment: undefined,
                },
            });
        }
        this.videoTutorial = this.videoTutorialsByFilename['Lehrer_bbb_erklaert.mp4'];
    },
    async beforeDestroy() {
        URL.revokeObjectURL(this.audioUrl);
        URL.revokeObjectURL(this.videoUrl);
        URL.revokeObjectURL(this.primaryVideoUrl);
        URL.revokeObjectURL(this.secondaryVideoUrl);
        for (const urlKey in this.urls) {
            URL.revokeObjectURL(this.urls[urlKey]);
        }
    },
    methods: {
        ...mapActions('appointments', ['editAppointment', 'deletePupilUpload', 'createTeacherUploadViaFileId']),
        ...mapActions("teachers", ["deleteUpload", "getMeTeacher"]),
        ...mapActions("util", ['toggleLoading', 'toggleOpenAppointment', 'toggleCurrentUploadGroup']),
        ...mapActions("groups", ["getGroup"]),
        ...mapActions("teacherFolder", ["getVFSFolderUploads"]),
        ...mapActions("subjectFolder", ["getSubjectFolder"]),
        ...mapMutations("snackbar", ["showSnackbar"]),

        onVideoTutorialClose() {
            this.videoTutorial = false;
            this.joinBBB();
        },
        async loadImagePreviews() {
            if (this.thisAppointment && this.thisAppointment.teacherUploads) {
                this.urls = await this.thisAppointment.teacherUploads.reduce(async (previousPromise, currentUpload) => {
                    const previous = await previousPromise;
                    try {
                        const res = await backend.getTeacherUploadThumbnail(this.thisAppointment._id, currentUpload.file);
                        if (res.ok && res.status === 200) {
                            let blob = await res.blob();
                            previous[currentUpload._id] = window.URL.createObjectURL(blob);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                    return previous;
                }, Promise.resolve([]));
            }
        },
        async play(file) {

            file.seenByMe = true;
            let pipFile = null;
            if (file.subtitle) {
                const subtitle = await backend.getSubtitle(file.subtitle);
                this.subtitleURL = window.URL.createObjectURL(await subtitle.blob());
            } else {
                this.noSubtitle = true;
            }
            if (file.pipReference)
                pipFile = this.thisAppointment.teacherUploads.find(value => value._id === file.pipReference);
            if (pipFile) {
                // PiP Video
                const primary = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
                const secondary = await backend.getTeacherUpload(this.thisAppointment._id, pipFile.file);
                this.primaryVideoUrl = window.URL.createObjectURL(await primary.blob());
                this.secondaryVideoUrl = window.URL.createObjectURL(await secondary.blob());
            } else {
                const res = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
                this.videoUrl = window.URL.createObjectURL(await res.blob());
            }
        },

        async playPupil(file) {
            file.seenByMe = true;
            const res = await backend.getPupilUpload(this.thisAppointment._id, file.file);
            this.videoUrl = window.URL.createObjectURL(await res.blob());
        },

        async playAudio(file) {
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
            this.audioUrl = window.URL.createObjectURL(await res.blob());
        },

        async playAudioPupil(file) {
            file.seenByMe = true;
            const res = await backend.getPupilUpload(this.thisAppointment._id, file.file);
            this.audioUrl = window.URL.createObjectURL(await res.blob());
        },
        showUploadVideoInVideoPopup(file) {
            this.showUploadVideoInVideoDialog = true;
            this.$refs.uploadVideoInVideo.show(file, this.appointment);
        },
        onUploadVideoDone() {
            this.showUploadVideoInVideoDialog = false;
            this.requestAppointments(true);
        },
        urlify(text) {
            // eslint-disable-next-line no-useless-escape
            var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|software|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
            return text.toString().replace(urlRegex, function (url) {
                url = url.includes('http') ? url : 'http://' + url;
                return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
            });
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        },
        checkTutorialState() {
            this.bbbTutState = JSON.parse(localStorage.getItem('bbbTut'));
            if (this.bbbTutState) {
                this.bbbTut = false;
            }
        },

        async teacherUploadInputChange(appointmentId) {
            const input = this.$refs.teacherUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf(".");
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.uploadFile(appointmentId, newFileName, fileExtension);
                };
                this.showNameFile = true;
            }
        },

        fileNotSupported(el) {
            this.showSnackbar({message: "Dieses Dateiformat kann in eKlara nicht bearbeitet werden. Bitte lade die Datei herunter."});
        },
        async uploadFile(appointmentId, newFileName, fileExtension, file, selectedUser) {
            if (!file) {
                const input = this.$refs.teacherUploadInput;
                file = input.files[0];
            }
            if (file) {

                if (file.size > 1000000000) {
                    this.showSnackbar({message: "Datei zu groß, max. 1 GB", color: "error"});
                    return;
                }

                const formData = new FormData();
                formData.append("file", file, `${newFileName}.${fileExtension}`);
                formData.append('visibleFor', JSON.stringify(selectedUser));
                this.teacherXmlHttpRequest = backend.postTeacherUpload(
                    appointmentId,
                    file
                );

                this.teacherXmlHttpRequest.onerror = (e) => {
                    console.error("Teacher upload error:", e);
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = "";
                };

                this.teacherXmlHttpRequest.onabort = (e) => {
                    console.warn("Teacher upload aborted");
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = "";
                };

                this.teacherXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.teacherUploadProgress = (e.loaded / e.total) * 100;
                });

                this.teacherXmlHttpRequest.addEventListener("load", (e) => {
                    if (this.teacherXmlHttpRequest.status !== 201)
                        console.error(
                            "Teacher upload failed:",
                            this.teacherXmlHttpRequest.response
                        );
                    this.showTeacherUploadProgress = false;

                    this.showSnackbar({message: "Gespeichert!", color: "success"});
                    this.requestAppointments(true);
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = "";
                });
                this.teacherUploadProgress = 0.0;
                this.showTeacherUploadProgress = true;
                this.teacherXmlHttpRequest.send(formData);
            }
        },

        async clickDownloadTeacherUpload(file) {
            try {
                const res = await backend.getTeacherUpload(
                    this.thisAppointment._id,
                    file.file
                );
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function () {
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            } catch (e) {
                console.error(e);
            }
        },

        async clickDeleteUpload() {
            /*//setting reopenAppointment to open popup after deleting is done
            this.$route.query.appointment = this.thisAppointment._id;*/

            let file = this.elementToDelete;
            const res = await backend.deleteUpload((file._id).toString());

            if (res.status === 204) {
                this.showSnackbar({message: `Datei ${file.title} erfolgreich gelöscht`, color: "success"});
            } else {
                this.showSnackbar({message: `Beim Löschen ist ein Fehler aufgetreten`});
            }
            this.elementToDelete = null; //clear temp reference to file
            this.deleteDialog = false;
            // Re-fetching the appointments using the store to update the remaining uploads
            // Causes instant deleting of uploads now instead of waiting 3s.. :D
            await this.requestAppointments(true);
        },

        async clickDownloadPupilUpload(file) {
            try {
                file.seenByMe = true;
                const res = await backend.getPupilUpload(
                    this.thisAppointment._id,
                    file.file
                );
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function () {
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            } catch (e) {
                console.error(e);
            }
        },
        updateNote() {
            this.update = true;
        },
        async saveNote() {
            this.thisAppointment.note = this.thisAppointment.note.replaceAll('</p>', ' </p>');
            this.thisAppointment.note = this.thisAppointment.note.replaceAll('</span>', ' </span>');
            this.thisAppointment.note = this.thisAppointment.note.replaceAll('</li>', ' </li>');
            this.thisAppointment.note = this.thisAppointment.note.replaceAll('</em>', ' </em>');
            this.thisAppointment.note = this.thisAppointment.note.replaceAll('</strong>', ' </strong>');
            let data = {
                _id: this.thisAppointment._id,
                note: this.thisAppointment.note,
            };
            try {
                let response = await this.editAppointment(data);
                if (Number.isInteger(response)) {
                    this.showSnackbar({message: "Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!"});
                } else {
                    this.showSnackbar({message: "Gespeichert!", color: "success"});
                    this.update = false;
                    this.thisAppointment.note = this.thisAppointment.note.replaceAll('</p>', ' </p>');
                    this.thisAppointment.note = this.thisAppointment.note.replaceAll('</span>', ' </span>');
                    this.thisAppointment.note = this.thisAppointment.note.replaceAll('</li>', ' </li>');
                    this.thisAppointment.note = this.thisAppointment.note.replaceAll('</em>', ' </em>');
                    this.thisAppointment.note = this.thisAppointment.note.replaceAll('</strong>', ' </strong>');
                }
            } catch (e) {
                this.showSnackbar({message: "Die Notiz ist zu groß. Bitte benutzen Sie für große Dateien den Material-Upload."});
                console.error(e);
            }
        },
        clickTeacherUploadButton() {
            this.toggleOpenAppointment(this.thisAppointment);
            this.toggleCurrentUploadGroup('teacher');
            /*localStorage.setItem('reopenAppointment', this.thisAppointment._id);*/
            document.getElementById("uploadInput").click();
        },

        openEdit(name, id, uploadId) {

            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;

            this.$router.push({
                name: 'editor',
                params: {mode: 'teacher'},
                query: {
                    group: group,
                    ug: 'teacher',
                    color: color,
                    aId: appointmentId,
                    title: name,
                    fId: id,
                    uploadid: uploadId,
                },
            });
        },
        openEmptyEdit(newPageType) {
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;
            this.toggleOpenAppointment(this.thisAppointment);

            this.$router.push({
                name: 'editor',
                params: {mode: 'creator'},
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: group + ' ' + new Date().toLocaleDateString(),
                        pagetype: newPageType,
                    }
            });
        },
        handleOpenAnsichtClick(element) {
            if (this.isCorrectionAvailable(element)) {
                const fileToOpen = this.getCurrentCorrection(element);

                if (fileToOpen) {
                    this.openAnsicht(fileToOpen.title, fileToOpen.file, fileToOpen._id, 'pupil', true);
                }
            } else {
                this.openAnsicht(element.title, element.file, element._id, 'teacher')
            }
        },

        openAnsicht(name, id, uploadId, userGroup, correction) {

            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;

            const query = {
                group: group,
                color: color,
                aId: appointmentId,
                title: name,
                fId: id,
                uploadid: uploadId,
                ug: userGroup
            };

            if (correction) {
                query.correction = correction;
            }

            this.$router.push({
                name: 'editor',
                params: {mode: correction ? 'viewer' : 'creator'},
                query,
            });

        },

        //startBBBTut
        startBBB() {
            /*emit to PlanWidgetLehrer*/
            this.$emit('pass-on-bbb-tut', this.joinBBB);
        },

        async clickBeitreten() {
            this.menu = false;

            if (!this.bbbTutState) {
                this.startBBB();
            } else if (this.bbbTutState) {
                await this.joinBBB();
            }
        },

        async joinBBB() {
            const res = await backend.joinSchoolSubjectAppointmentBbbMeetingUrl(
                this.thisAppointment._id
            );
            this.toggleLoading(true);
            const {redirectUrl} = await res.json();
            if (res.status === 422) {
                this.showSnackbar({ message: "Aktuell ist der Raum geschlossen!", color: "error" });
                this.toggleLoading(false);
                return;
            }
            if (this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                this.toggleLoading(false);
                return;
            }

            window.location.href = redirectUrl;
            this.toggleLoading(false);
        },

        isCorrectionAvailable(pupilFile) {
            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(
                        this.thisAppointment.teacherUploads[i]._id
                    );
                    if (index !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },

        getCurrentCorrection(pupilFile) {
            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.thisAppointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        return this.thisAppointment.teacherUploads[i];
                    }
                }
            }
            return null;
        },

        async clickShowSnackbarFileNotSupported() {
            this.showSnackbar({
                message: "Dieses Dateiformat kann in eKlara nicht bearbeitet werden. Bitte laden Sie die Datei herunter.",
                color: "error"
            });
        },

        async clickDownloadPupilCorrection(file) {
            let teacherCorrection = null;

            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = file.editLeafs.indexOf(
                        this.thisAppointment.teacherUploads[i]._id
                    );
                    if (index !== -1) {
                        teacherCorrection = this.thisAppointment.teacherUploads[i];
                    }
                }
            }

            if (teacherCorrection) {
                teacherCorrection.seenByMe = true;
                try {
                    const res = await backend.getTeacherUpload(
                        this.thisAppointment._id,
                        teacherCorrection.file
                    );
                    let blob = await res.blob();
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = teacherCorrection.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    setTimeout(function () {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                } catch (e) {
                    console.error(e);
                }
            }
        },

        // Don't simplify it, "return isMoved.length > 0" will not do the same
        wasMoved(isMoved) {
            return isMoved.length <= 0 ? false : true;
        },

        // Prepares the file lists for not done (Nicht abgegeben) and corrected (abgegeben)
        // Checks which homework has not been done yet and saves it in missingAppointments
        // Puts done homework in pupilUploadsCorrected
        async getMissingMembers() {
            this.missingAppointments = [];
            this.pupilUploadsCorrected = [];
            let pupilUploadData = this.appointment.pupilUpload;
            let idListUploads = [];

            //IDs of pupils who have done homework
            for (let k = 0; k < pupilUploadData.length; k++) {
                idListUploads.push(pupilUploadData[k].uploader);
            }

            //get all members of the group
            const appointmentGroup = await this.getGroup(this.appointment.group);
            if (Object.keys(appointmentGroup).length) {
                this.groupMembers = appointmentGroup.participants;
            }

            let teacherUploads = this.appointment.teacherUploads;
            let visibleFor = [];

            //go through teacherUploads to collect the visibleFor array but only if the upload is flagged as Assignment
            for (let i = 0; i < teacherUploads.length; i++) {
                if (teacherUploads[i].isAssignment || teacherUploads[i].isAssignment === undefined) {
                    visibleFor = visibleFor.concat(teacherUploads[i].visibleFor);
                }
            }

            //remove done homework (idListUploads) from visibleFor, so only the undone homework is left
            //result is in visibleFor array
            idListUploads.forEach(function (val) {
                var foundIndex = visibleFor.indexOf(val);
                if (foundIndex !== -1) {
                    visibleFor.splice(foundIndex, 1);
                }
            });

            // Find the files that were only moved and add them into pupilUploadsCorrected
            for (let k = 0; k < this.appointment.teacherUploads.length; k++) {
                const teacherUpload = this.appointment.teacherUploads[k]
                if (teacherUpload.isMoved) {
                    const pupilThatMoved = this.appointment.teacherUploads[k].isMoved;
                    for (let i = 0; i < pupilThatMoved.length; i++) {
                        let pupil = this.groupMembers.find(obj => obj.account === pupilThatMoved[i]);
                        let push = {
                            isMoved: true,
                            title: '' + pupil.name + '_' + teacherUpload.title,
                            uploader: pupil.account,
                            editParent: teacherUpload._id
                        };
                        this.pupilUploadsCorrected.push(push);
                    }
                }
            }
            // Add the regular corrected files to pupilUploadsCorrected
            let corrected = this.thisAppointment.pupilUpload.filter(item => item.editLeafs && item.editLeafs.length > 0);
            if (corrected.length > 0) {
                corrected.forEach(el => this.pupilUploadsCorrected.push(el));
            }

            //groupMembers = all pupils of this appointment's group with ID and name
            //visibleFor = IDs of pupils who haven't done homework (includes duplicates
            //e.g. pupilA hasn't done homework apples and coins)
            for (let i = 0; i < visibleFor.length; i++) {
                let el = this.groupMembers.find(obj => obj.account === visibleFor[i]);
                if (el && !this.missingAppointments.find(e => e._id === el._id)) {
                    this.missingAppointments.push(el);
                }
            }
            this.missingAppointments = [...new Set(this.missingAppointments)]; //remove duplicate names

            // Add field undoneFiles for each pupil
            this.missingAppointments.forEach(el => el.undoneFiles = []);

            // Find the files which the pupil has not done yet. Add the title of each undone file to undoneFiles
            // this is used to display each undoneFile's title for each pupil in the fachPopup
            for (let i = 0; i < this.appointment.teacherUploads.length; i++) {
                const teacherUpload = this.appointment.teacherUploads[i];
                if (teacherUpload.editParent) {
                    // If teacherUpload has editParent it's a correction and not relevant for us here -> skip iteration
                    continue;
                }
                for (let j = 0; j < this.missingAppointments.length; j++) {
                    let currPup = this.missingAppointments[j];
                    let fileToCorrect = this.appointment.pupilUpload.filter(up => up.uploader === currPup.account && up.editParent === teacherUpload._id);
                    let fileCorrected = this.pupilUploadsCorrected.filter(up => up.uploader === currPup.account && up.editParent === teacherUpload._id);
                    let fileMoved = this.pupilUploadsCorrected.filter(up => up.uploader === currPup.account && up.isMoved && up.editParent === teacherUpload._id);
                    if (fileToCorrect.length > 0) {
                        // Homework is either to correct, corrected or moved (handed in by pupil without working on it)
                    } else if (fileCorrected.length > 0) {
                        //
                    }
                    else if (fileMoved.length > 0) {
                        //
                    }
                    else {
                        this.missingAppointments[j].undoneFiles.push(teacherUpload.title);
                    }
                }
            }
            // Filter out entries where undoneFiles is empty, this can happen for isMoved files,
            // which will be put into missingAppointments, but should be considered done homework
            this.missingAppointments = this.missingAppointments.filter(el => el.undoneFiles.length > 0);

            //sort array by firstname
            this.missingAppointments.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
        },

        hideEl(element) {
            let x = [];
            x = document.getElementsByClassName(element);
            for (let i = 0; i < x.length; i++) {
                x[i].style.display = "none";
            }
        },

        showEl(element) {
            let x = [];
            x = document.getElementsByClassName(element);
            for (let i = 0; i < x.length; i++) {
                x[i].style.display = "block";
            }
        },

        resetDialogContent() {
            this.hideEl('matLong');
            this.showEl('matShort');
            this.hideEl('toCorrectLong');
            this.showEl('toCorrectShort');
            this.hideEl('toDoLong');
            this.showEl('toDoShort');
            this.hideEl('correctedLong');
            this.showEl('correctedShort');
        },

        async getGroupName() {
            const group = await this.getGroup(this.thisAppointment.group)
            this.thisAppointment.group = group || {};

            this.groupName = group.name;
        },

        //used for supFormats tooltip
        closeDialog() {
            this.supFormats = false;
        },
        include() {
            return [document.querySelector('.included')]
        },

        prepareUploadViaFileWidget(file) {
            this.groupN = this.thisAppointment.group;
            let name;
            // teacherFolder uses nested file.file.*, privateFolder uses file.*
            if (file.uploadedName) {
                name = file.uploadedName;
                this.fileToUpload = file;
            } else if (file.file.uploadedName) {
                name = file.file.uploadedName;
                this.fileToUpload = file.file;
            } else {
                console.error('invalid file format');
            }
            let lastDot = name.lastIndexOf('.');
            this.newFileName = name.substring(0, lastDot);
            this.fileWidgetNameDialog = true;
        },

        // Method that sends request to backend
        async uploadViaFileWidget(newFileName) {
            let data = {
                appointmentId: this.thisAppointment._id,
                fileName: newFileName,
                fileId: this.fileToUpload._id,
                visibleFor: JSON.stringify(this.selectedUsers),
                startDate: this.startDate,
                isAssignment: this.isAssignment,
            };
            let res = await this.createTeacherUploadViaFileId(data);
            if (res === true) {
                this.fileWidgetNameDialog = false;
                this.fileWidgetUploadDialog = false;
                this.showSnackbar({message: "Gespeichert", color: 'success'});
            }
            await this.requestAppointments(true);
        },

        toggleSlice(folder) {
            switch (folder) {
                case 'private':
                    if (this.privateFolderSliced) {
                        this.privateFolder = this.privateFolderFull;
                        this.privateFolderSliced = false;
                    } else {
                        this.privateFolder = this.privateFolderFull.slice(0, 2);
                        this.privateFolderSliced = true;
                    }
                    break;
                case 'subject':
                    if (this.subjectFolderSliced) {
                        this.subjectFolder = this.subjectFolderFull;
                        this.subjectFolderSliced = false;
                    } else {
                        this.subjectFolder = this.subjectFolderFull.slice(0, 2);
                        this.subjectFolderSliced = true;
                    }
                    break;
                default:
                    if (this.teacherFolderSliced) {
                        this.teacherFolder = this.teacherFolderFull;
                        this.teacherFolderSliced = false;
                    } else {
                        this.teacherFolder = this.teacherFolderFull.slice(0, 2);
                        this.teacherFolderSliced = true;
                    }
            }
        },
        getRowStyling(index, el) {
            if (index % 2 === 1 && !el.isMoved) {
                return 'brighterToDoRow'
            } else if (index % 2 === 1 && el.isMoved) {
                return 'brighterEmptyToDoRow'
            }
        },
    },
};
</script>

<style>
.ql-container {
    font-size: 16px !important;
    padding: 12px !important;
}

.ql-editor {
    padding: 0 !important;
}
</style>

<style lang="scss" scoped>
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 6em !important;
}

table tr th,
table tr td {
    width: 9em;
}

table,
tr,
td {
    border: none;
}

.fach {
    cursor: pointer;
}

.whiteBg {
    background-color: white !important;
}

.tinyIcon {
    width: 15px;
}

.iconSize {
    height: 20px;
}

.iconToWhite {
    filter: brightness(1000%);
}

.greyText {
    color: var(--v-dunkelgrau-base);
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.squareBtn {
    min-width: 20px !important;
    min-height: 20px !important;
    width: 36px;
    height: 36px;
}

.appointmentItem {
    position: relative;
    color: var(--v-dunkelgrau-base);
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #fff;
    width: 100%;
    height: 100%;
}

.inactiveAppointment {
    opacity: 0.4 !important;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: 200px;
}

.scroll-area2 {
    position: relative;
    margin: auto;
    max-height: 80px;
}

.scroll-area3 {
    position: relative;
    margin: auto;
    max-height: 50px;
}

.scroll-area-fachPopup {
    position: relative;
    margin: auto;
    height: calc(65vh - 30px);
}

.scroll-area-fileUpload {
    position: relative;
    margin: auto;
    max-height: 75vh;
}

.buttonIcon {
    align-self: start;
    background-color: var(--v-hellgrau-base) cc;
    padding: 2px;
    border-radius: 3px;
    margin-right: 1em;
}

//border-radius of v-menu
.v-menu__content {
    border-radius: 12px;
}

.iconSelect {
    display: inline-flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: rgba(234, 234, 234, 0.7);
}

.iconSelectFrei {
    display: inline-flex;
    width: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: transparent;
}

.smallFach {
    position: relative;
    color: var(--v-dunkelgrau-base);
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #ffffff;
}

.isFachRunning {
    color: white;
    background-color: #8cbd46 !important;
}

.isFachRunningIcon {
    filter: brightness(1000%);
}

.fileUploadPlaceholderText {
    p {
        color: #bbbbbb;
        width: 100%;
        text-align: center;
    }
}

.eHr {
    width: 90%;
    margin: auto;
    border-width: 0;
    height: 2px;
    color: var(--v-hellgrau-base);
    background-color: var(--v-hellgrau-base);
}

.eButton {
    width: 33px !important;
    min-width: 33px !important;
    height: 33px !important;
    min-height: 33px !important;
    border-radius: 4px !important;
    box-shadow: 0 5px 10px #00000025 !important;
}

.btnStyle {
    min-width: 36px;
    min-height: 36px;
    margin-top: -3px;
}

.fileName {
    max-width: 75%;
    min-width: 75%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

// as these elements contain multiple file names joined together a line break is needed instead of ellipsis
.fileNameUndoneFiles {
    max-width: 95%;
    min-width: 95%;
    display: inline-block;
    word-wrap: break-word;
}

.matLong,
.toCorrectLong,
.toDoLong,
.correctedLong {
    display: none;
}

.brighterFileRow {
    background-color: rgba(224, 224, 224, 0.15) !important;
}

.brighterToCorrectRow {
    background-color: rgba(128, 128, 128, 0.15) !important;
}

.brighterToDoRow {
    background-color: rgba(230, 35, 30, 0.15) !important;
}

.brighterEmptyToDoRow {
    background-color: rgba(230, 103, 30, 0.2) !important;
}

.brighterCorrectedRow {
    background-color: rgba(60, 170, 105, 0.15) !important;
}

.small-drop-down-menu {
    display: block;
    background-color: #F8F8F8;

    & button {
        width: 100%;
    }
}

#createEditorABsButton {
    color: white;
}

.fileDiv {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    width: 99%;
    cursor: pointer;
}

@media only screen and (max-width: 399px) {
    #fachPopup {
        max-width: 100vw !important;
    }

    #dialog {
        margin: 0;
    }
}

@media only screen and (max-width: 500px),
(max-width: 900px) and (orientation: portrait) {
    .scroll-area-fachPopup {
        /*250px = 59px header, 48px join btn, 70px btmNavBar and paddings,margins*/
        max-height: calc(100vh - 250px);
    }
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}
</style>
