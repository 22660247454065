<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
            <v-menu
                max-width="300"
                offset-y
                left
                style="z-index: 12"
            >
                <template v-slot:activator="{ on: menu }">
                    <v-btn
                        id="plusMenuToggle"
                        :disabled="accountRole === 'pupil' && folderType === 'groupFolder'"
                        x-small
                        color="rgba(248, 248, 248, 0.4)"
                        class="ml-2 pa-0 optionBtn"
                        style="width: 30px; height: 30px; float: right"
                        v-on="{ ...tooltip, ...menu }"
                    >
                        <img
                            :src="plusIcon"
                            class="pa-1"
                            style="height: 30px; filter: brightness(1000%)"
                        >
                    </v-btn>
                </template>
                <div>
                    <!-- Menu Content -->
                    <v-btn
                        v-if="uploadLocalFileFunction"
                        elevation="0"
                        class="fileSystemHeaderMenuButton subMenu"
                        @click="uploadLocalFileFunction"
                    >
                        Hochladen
                    </v-btn>
                    <v-btn
                        v-if="folderType === 'teacherFolder'"
                        elevation="0"
                        class="fileSystemHeaderMenuButton subMenu"
                        @click="uploadFileFromPrivateFunction"
                    >
                        Laden aus privatem Ordner
                    </v-btn>
                    <v-btn
                        v-if="folderType === 'privateFolder' && accountRole === 'teacher'"
                        elevation="0"
                        class="fileSystemHeaderMenuButton subMenu"
                        @click="uploadFileFromSharedFunction"
                    >
                        Laden aus geteiltem Ordner
                    </v-btn>
                    <v-btn
                        elevation="0"
                        class="fileSystemHeaderMenuButton subMenu"
                        @click="startFolderCreation"
                    >
                        Neuer Ordner
                    </v-btn>
                </div>
            </v-menu>
        </template>
        <span>Aktionsmenü</span>
    </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';
import plusIcon from "@/assets/Icons/hinzufuegen-07.svg";

export default {
    name: 'FileSystemPlusMenu',
    props: {
        folderType: {required: true, type: String},
        uploadLocalFileFunction: { required: true, type: Function },
        startFolderCreation: { required: true, type: Function },
        uploadFileFromSharedFunction: { required: false, type: Function },
        uploadFileFromPrivateFunction: { required: false, type: Function },
    },
    data: () => ({
       plusIcon,
    }),
    computed: {
        ...mapGetters('auth', [ 'accountRole' ]),
    }
}
</script>

<style scoped>
.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}

.fileSystemHeaderMenuButton {
    width: 100%;
    border-radius: 0;
}

.subMenu {
    justify-content: flex-start;
    text-transform: none;
}
</style>
