<template>
    <div style="display: inline-flex; width: 100%">
        <div
            v-if="!eventFromMe"
            style="position: absolute; top: 30px; right: -42px"
            :style="''"
        >
            <v-tooltip
                :disabled="!showTooltips"
                top
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        :aria-hidden="true"
                        elevation="0"
                        icon
                        class="ml-2 pa-0 prchatRoomEventColorInvert"
                        style="width: 40px; height: 40px"
                        v-on="on"
                        @click="greet(chatRoomEvent.text, $event)"
                    >
                        <img
                            :src="isReading ? lautsprecherAusIcon : lautsprecherIcon"
                            :alt="isReading ? 'Vorlesen stoppen' : 'Nachricht vorlesen'"
                            style="height: 30px"
                        >
                    </v-btn>
                </template>
                <span>Nachricht vorlesen</span>
            </v-tooltip>
        </div>

        <div
            v-else
            style="position: absolute; top: 30px; left: -50px"
            :style="''"
        >
            <v-tooltip
                :disabled="!showTooltips"
                top
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        :aria-hidden="true"
                        elevation="0"
                        icon
                        class="ml-2 pa-0 prchatRoomEventColorInvert"
                        style="width: 40px; height: 40px"
                        v-on="on"
                        @click="greet(chatRoomEvent.text, $event)"
                    >
                        <img
                            :src="isReading ? lautsprecherAusIcon : lautsprecherIcon"
                            :alt="isReading ? 'Vorlesen stoppen' : 'Nachricht vorlesen'"
                            style="height: 30px"
                        >
                    </v-btn>
                </template>
                <span>Nachricht vorlesen</span>
            </v-tooltip>
        </div>
        <!-- Nachricht -->
        <!-- Disable v-html xss checking entry.content is provided only by developers -->
        <!-- eslint-disable vue/no-v-html -->
        <p
            tabindex="0"
            style="
        min-width: calc(100% - 25px);
        max-width: 225px;
        word-wrap: break-word;
        white-space: pre-wrap;
      "
            class="mb-0"
            v-html="sanitizeHtml(urlify(chatRoomEvent.text))"
        />

        <ChatRoomEventListItemDeleteButton
            v-if="eventFromMe || chatRoomMembershipRole === 'moderator'"
            :chat-room-event="chatRoomEvent"
        />
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import sanitizeHtml from "../../../../util/sanitizeHtml";

import ChatRoomEventListItemDeleteButton from "./ChatRoomEventListItemDeleteButton";

import lautsprecherIcon from "../../../../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../../../../assets/Icons/lautsprecher-aus-89.svg";

export default {
  name: "ChatRoomEventListTextItem",
  components: {
    ChatRoomEventListItemDeleteButton,
  },
  props: {
    chatRoomEvent: { type: Object, required: true },
    chatRoomMembershipRole: { required: true, type: String },
  },
  data: () => ({
    sanitizeHtml,
    isReading: false,

    showTextTimeout: null,

    lautsprecherIcon,
  }),
  computed: {
    ...mapState("auth", ["account"]),
    ...mapState("tooltips", ["showTooltips"]),
    ...mapState('translation', ['targetLang']),
    eventFromMe() {
            return this.chatRoomEvent.author === this.account._id;
    }
  },
  mounted() {},
  methods: {
    ...mapActions("translation", [
      "showTranslation",
      "setTranslatedText",
      "translateToTargetLang",
    ]),
    async greet(message, event) {
      if ("speechSynthesis" in window) {
        // Speech Synthesis supported 🎉
      } else {
        // Speech Synthesis Not Supported 😣
        alert("Sorry, your browser doesn't support text to speech!");
        return;
      }

      if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
        event.target.src = lautsprecherIcon;
      } else {
         console.log({
            targetLang: this.targetLang,
            textToTranslate: message,
          })
        if (this.targetLang !== "de") {
         
          message = await this.translateToTargetLang({
            targetLang: this.targetLang,
            textToTranslate: message,
          });

          this.setTranslatedText(message);
          this.showTranslation(true);

          if (this.showTextTimeout) {
            clearTimeout(this.showTextTimeout);
            this.showTextTimeout = null;
          }

          this.showTextTimeout = setTimeout(() => {
            this.showTranslation(false);
            this.showTextTimeout = null;
          }, 15000);
        }

        event.target.src = lautsprecherAusIcon;
        let msg = new SpeechSynthesisUtterance();
        msg.text = message;
        if (this.isLangPackageAvailable()) {
          msg.lang = this.targetLang;
          window.speechSynthesis.speak(msg);
        } else {
          event.target.src = lautsprecherIcon;
        }

        msg.onend = () => {
          event.target.src = lautsprecherIcon;
        };
      }
    },
    isLangPackageAvailable() {
      for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
        if (
          window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)
        ) {
          return true;
        }
      }

      return false;
    },
    urlify(text) {
      // eslint-disable-next-line no-useless-escape
      var urlRegex =
        /(([a-z]+:\/\/)?(([a-z0-9-]+\.)+([a-z]{2}|aero|arpa|biz|com|software|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-.~]+)*(\/([a-z0-9_\-.]*)(\?[a-z0-9+_\-.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
      return text.toString().replace(urlRegex, function (url) {
        url = url.includes("http") ? url : "http://" + url;
        return (
          '<a href="' +
          url +
          '" target="_blank" rel="noopener noreferrer">' +
          url +
          "</a>"
        );
      });
      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    },
  },
};
</script>
<style scoped>
.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.icon {
  height: 20px;
}
</style>