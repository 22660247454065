<template>
    <v-dialog
        v-model="showDialog"
        width="400"
    >
        <v-card>
            <v-card-title class="d-flex align-center justify-space-between">
                {{ groupName }}
                <v-btn
                    icon
                    @click="showDialog = false"
                >
                    <v-icon style="transform: rotate(45deg)">
                        mdi-plus
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <h1 class="formHeader">
                    {{ groupParticipants.length }} Teilnehmer
                </h1>

                <p
                    v-for="participant in groupParticipants"
                    :key="participant"
                    class="mt-2"
                    style="font-size: 16px"
                >
                    {{ participant }}
                </p>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ChatRoomInfoDialog",
  props: {
    chatRoom: { required: true, type: Object },
  },
  data: () => ({
    groupParticipants: [],
    groupName: "",
    showDialog: true,
  }),
  watch: {
    showDialog(newState, oldState) {
      if (newState === false) {
        this.$emit("closeDialog");
      }
    },
  },
  async mounted() {
    this.groupName = this.checkRoomName(this.chatRoom.name);
    this.groupParticipants = await this.getMatrixUsersFullNameByRoomId(
      this.chatRoom._id
    );
    this.groupParticipants.sort();
  },
  methods: {
    ...mapActions("matrix", ["getMatrixUsersFullNameByRoomId"]),
    checkRoomName(name) {
      // group room names look like: group Room <id, groupName>, so return substring between , and >
      if (name.includes("group Room")) {
        const start = name.indexOf(",");
        const end = name.indexOf(">");
        return name.slice(start + 1, end).trim();
      }
      const id = name
        .replace(this.accountId, "")
        .replace("private Room", "")
        .replace("<", "")
        .replace(">", "")
        .replace(",", "")
        .replace(" ", "");
      let secondUser = this.matrixUsers.find((el) => {
        return el.account === id;
      });
      if (secondUser) {
        return secondUser.lastName + " " + secondUser.name;
      } else {
        if (this.accountRole === "teacher") {
          secondUser = this.maintainers.find((el) => {
            return el.account === id;
          });
          if (secondUser) {
            return "Verwaltung";
          } else {
            return "Unbekannter Nutzer";
          }
        }
        return "Unbekannter Nutzer";
      }
    },
  },
};
</script>

<style>
</style>