<template>
    <div>
        <img
            :src="suchIcon"
            alt="Suchensymbol"
            style="height: 20px; margin-left: 0; margin-top: 2px"
            class="iconToWhite"
        >
        <v-autocomplete
            id="searchArea"
            v-model="selectedPrivateChatAccountSetId"
            label="Nach Kontakt suchen"
            single-line
            dark
            hide-details
            item-text="displayName"
            item-key="_id"
            item-value="_id"
            class="pl-2 pt-0 mt-0"
            item-color="gruen"
            height="24"
            style="z-index: 10"
            :items="selectableAccountSets"
            :filter="customFilter"
            no-data-text="Kein passender Kontakt"
        >
            <template
                v-if="
                    this.accountRole === 'teacher' || this.accountRole === 'maintainer'
                "
                v-slot:prepend-item
            >
                <v-list-item
                    style="height: 48px"
                    @click="clickCreateNewGroup"
                >
                    <v-list-item-icon class="my-1 mr-2">
                        <v-avatar
                            fab
                            color="#6994cc"
                            size="40"
                        >
                            <img
                                :src="groupIcon"
                                class="icon"
                                alt="Gruppensymbol"
                            >
                        </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title> Neue Gruppe </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>
        <v-tooltip
            :disabled="!showTooltips"
            top
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    x-small
                    fab
                    plain
                    text
                    min-width="20"
                    style="height: 20px; width: 20px; margin-top: 2px"
                    class="ml-2"
                    v-on="on"
                    @click="close"
                >
                    <img
                        :src="abbrechenIcon"
                        alt="Abbrechen"
                        style="height: 20px"
                        class="iconToWhite"
                    >
                </v-btn>
            </template>
            <span>Abbrechen</span>
        </v-tooltip>
        <v-tooltip
            :disabled="!showTooltips"
            top
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    x-small
                    fab
                    plain
                    text
                    min-width="20"
                    style="height: 20px; width: 20px; margin-top: 2px"
                    class="ml-2"
                    v-on="on"
                    @click="startPrivateRoom"
                >
                    <img
                        :src="erledigtIcon"
                        alt="Fertig"
                        style="height: 20px"
                        class="iconToWhite"
                    >
                </v-btn>
            </template>
            <span>Fertig</span>
        </v-tooltip>
        <ChatRoomCreateGroupChatDialog
            v-if="showCreateGroupChatDialog"
            @closeDialog="close"
        />
    </div>
</template>

<script>
import * as backend from "../../../api/backend";

import suchIcon from "../../../assets/Icons/suche-26.svg";
import abbrechenIcon from "../../../assets/Icons/abbrechen-08.svg";
import erledigtIcon from "../../../assets/Icons/fertig-74.svg";

import groupIcon from "../../../assets/Icons/gruppen-normal-25.svg";
import searchIcon from "@/assets/Icons/lupe-18.svg";
import ChatRoomCreateGroupChatDialog from "../Components/ChatRoomCreateGroupChatDialog";

import { mapGetters, mapState } from "vuex";

export default {
  name: "CreateChatRoomPage",
  components: {
    ChatRoomCreateGroupChatDialog,
  },
  data: () => ({
    selectedPrivateChatAccountSetId: "", 

    suchIcon,
    abbrechenIcon,
    erledigtIcon,
    // lehrerIcon,
    // schuelerIcon,
    groupIcon,

    showCreateGroupChatDialog: false,
  }),
  computed: {
    ...mapState("tooltips", ["showTooltips"]),
    ...mapGetters("auth", ["accountRole"]),
    ...mapGetters("accountSets", ["accountSets"]),
    ...mapGetters("accounts", ["accountsById"]),
    ...mapGetters("groups", ["groupsById"]),
    ...mapState("auth", ["account"]),
    myAccountSet() {
      return this.accountSets.find(
        (accountSet) =>
          accountSet.type === "account" &&
          accountSet.account === this.account._id
    )},
    selectableAccountSets() {
      const getAccountSetDisplayName = (accountSet) => {
        if (accountSet.type === "account") {
          const account = this.accountsById[accountSet.account];
          return account ? account.displayName : account._id;
        }
      };

      return this.accountSets
        .filter((accountSet)=> accountSet.type === "account")
        .filter((accountSet) => accountSet._id !== this.myAccountSet?._id)
        .map((accountSet) => ({
          ...accountSet,
          displayName: getAccountSetDisplayName(accountSet),
        }))
        .filter((accountSet) => !accountSet.displayName.includes("PersonAlle"));
    },
  },
  methods: {
    clickCreateNewGroup() {
      this.addNew = false;
      this.selectedPrivateChatAccountSetId = "";
      this.showCreateGroupChatDialog = true;
    },
    customFilter(item, queryText, itemText) {
      const textOne = itemText.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    async startPrivateRoom() {
      // if (this.selectedPrivateChatAccountSetId) {
        // if (!this.chatIds.includes(this.selectedPrivateChatAccountSetId)) {
          const otherMembership = {
            accountSet: this.selectedPrivateChatAccountSetId,
            role: "member",
          };
          const myMembership = {
            accountSet: this.myAccountSet._id,
            role: "member",
          };

          await backend.postChatRoom({
            memberships: [otherMembership, myMembership],
            type: "privateChat",
          });

          this.addNew = false;
          this.selectedPrivateChatAccountSetId = "";
        // } else {
          // this.showSnackbar({
          //   message: "Sie haben schon einen Chat mit diesem Nutzer",
          // });
        // }
      // }
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  height: 20px;
}

.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}
</style>