<template>
    <!-- teacher -->
    <div>
        <v-btn
            v-if="big"
            class="pa-0"
            style="text-transform: inherit; height: 100%"
            block
            elevation="0"
            @click.stop.prevent="leaveChatRoom"
        >
            <div
                class="d-flex align-center"
                style="width: 100%"
            >
                <img
                    :src="verlassenIcon"
                    alt="Chat verlassen"
                    class="mx-2"
                    style="filter: brightness(0); height: 15px;"
                >
                <p
                    class="mr-auto"
                    style="color: red"
                >
                    Chat verlassen
                </p>
            </div>
        </v-btn>
        <v-btn
            v-else
            id="verlassenBtn"
            icon
            @click.stop.prevent="leaveChatRoom"
        >
            <img
                :src="verlassenIcon"
                alt="Chat verlassen"
            >
        </v-btn>
    </div>
</template>

<script>
import verlassenIcon from "../../assets/Icons/beitretensvg-06.svg";
import schlossOffenIcon from "../../assets/Icons/lock-open.svg";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import * as backend from "../../api/backend.js";

export default {
  name: "LeaveChatRoomButton",
  props: {
    chatRoom: { required: true, type: Object },
    big: { required: false, type: Boolean, default: false }
  },
  data: () => ({
    verlassenIcon,
  }),
  computed: {
    ...mapGetters("auth", ["accountRole"]),
    ...mapState("translation", ["targetLang"]),
    ...mapState("util", ["windowWidth"]),
  },

  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),

    async leaveChatRoom() {
      this.$emit("leaveChatRoom");
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn {
  border-radius: 8px;
}



#verlassenBtn {
  img {
    width: 24px;
    height: 24px;
    filter: brightness(1000%);
  }
}

</style>
