<template>
    <div>
        <v-row class="mx-0">
            <div class="whiteBg widgetContainer">
                <!--Header of Stundenplan-->
                <div style="background-color: #6995CD; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white;">
                    <v-row
                        v-if="windowWidth > 600"
                        style="max-width: 100%; height: 35px"
                        class="mx-2 d-flex align-center justify-space-between"
                    >
                        <v-col
                            cols="9"
                            md="8"
                            lg="8"
                            class="pa-0 pl-2 d-flex align-center"
                            style="font-size: larger"
                        >
                            <img
                                :src="stundenplanIcon"
                                alt=""
                                style="height: 20px;"
                                class="iconToWhite mr-1"
                            >
                            <h1
                                id="stundenplanHeading"
                                tabindex="0"
                                class="ma-0"
                                aria-label="Stundenplan"
                            >
                                <span style="text-decoration: underline white">S</span>tundenplan
                            </h1>
                        </v-col>

                        <div class="d-flex justify-end align-center">
                            <p
                                :aria-label="'Kalenderwoche ' + getMonday(date).getWeek()"
                                tabindex="0"
                                class="pa-0 ma-0 calWeek"
                                style="font-size: medium"
                            >
                                KW {{ getMonday(date).getWeek() }}
                            </p>

                            <div class="pa-0">
                                <v-tooltip
                                    :disabled="!showTooltips"
                                    top
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-if="!small"
                                            id="StundenplanVollbildButton"
                                            :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                            x-small
                                            class="ml-2"
                                            style="background-color: #f8f8f880; width: 35px; height: 35px"
                                            v-on="on"
                                            @click="() => {
                                                planVollbild = true;
                                                setFocusOn('StundenplanVollbildHeading');
                                                requestAppointments()
                                            }"
                                        >
                                            <img
                                                :src="fullscreenIcon"
                                                alt="Stundenplan Wochenansicht im Vollbild"
                                                style="height: 20px;"
                                                class="iconToWhite"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Wochenansicht</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </v-row>

                    <!-- Header for devices with windowWidth < 600px -->
                    <v-row
                        v-else
                        style="max-width: 100%; height: 35px; max-height: 35px;"
                        class="d-flex align-center justify-space-between"
                        :class="windowWidth > 340 ? 'mx-2' : 'mx-1'"
                    >
                        <v-col
                            class="pa-0 d-flex align-center justify-space-between"
                            :class="dateMargin(windowWidth)"
                            :cols="calcCols(windowWidth)"
                        >
                            <v-badge
                                color="red"
                                :content="past"
                                :value="past"
                                overlap
                                left
                            >
                                <v-tooltip
                                    :disabled="!showTooltips"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            icon
                                            v-on="on"
                                            @click="subDay"
                                        >
                                            <img
                                                :src="linksIcon"
                                                class="iconToWhite"
                                                style="height: 20px;"
                                                alt="Vorheriger Tag"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Vorheriger Tag</span>
                                </v-tooltip>
                            </v-badge>

                            <h2
                                tabindex="0"
                                class="planWidgetDisplayDate mb-0 mx-1"
                                style="color: #ffffff; font-weight: 500"
                            >
                                {{ getDisplayDateShort() }}
                            </h2>

                            <v-badge
                                color="red"
                                :content="future"
                                :value="future"
                                overlap
                            >
                                <v-tooltip
                                    :disabled="!showTooltips"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            icon
                                            v-on="on"
                                            @click="addDay"
                                        >
                                            <img
                                                :src="rechtsIcon"
                                                class="iconToWhite"
                                                style="height: 20px;"
                                                alt="Nächster Tag"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Nächster Tag</span>
                                </v-tooltip>
                            </v-badge>
                        </v-col>

                        <v-col
                            cols="4"
                            class="pa-0 d-flex align-center justify-end"
                            :class="todayMargin(windowWidth)"
                        >
                            <p
                                :aria-label="'Kalenderwoche ' + getMonday(date).getWeek()"
                                tabindex="0"
                                class="calWeek mr-1 mb-0"
                                :style="windowWidth > 370 ? 'font-size: medium' : 'font-size: 12px'"
                            >
                                KW {{ getMonday(date).getWeek() }}
                            </p>

                            <v-btn
                                aria-label="Zum heutigen Tag springen"
                                elevation="0"
                                small
                                style="background-color: #f8f8f880; color: #ffffff"
                                class="ml-1 pa-0 text-capitalize"
                                @click="jumpToCurrentDay()"
                            >
                                Heute
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <div
                    style="
                        border-radius: 15px;
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                    "
                    class="whiteBg"
                >
                    <!--menu bar of Stundenplan-->
                    <div
                        v-if="windowWidth > 600"
                        style="width: 99%; margin: 0 auto 0.5em;"
                        class="whiteBg d-flex justify-space-between pt-3"
                    >
                        <div
                            class="d-flex align-center justify-center pl-2 mr-2"
                            :style="windowWidth < 1100 ? 'width: 44px' : 'width: 64px'"
                        >
                            <v-btn
                                aria-label="Zum heutigen Tag springen"
                                elevation="0"
                                :class="isDisplayDateToday(getDisplayDate()) ? 'planWidgetTodayButton' : 'planWidgetTodayButtonActive'"
                                class="text-capitalize pa-0"
                                :small="windowWidth < 1100"
                                @click="jumpToCurrentDay()"
                            >
                                Heute
                            </v-btn>
                        </div>

                        <div
                            class="d-flex align-center justify-space-between"
                            :style="windowWidth < 1100 ? 'width: calc(100% - 44px)' : 'width: calc(100% - 64px)'"
                        >
                            <div
                                class="d-flex justify-center"
                                :class="windowWidth < 950 ? 'px-1' : 'px-2'"
                            >
                                <v-badge
                                    color="red"
                                    :content="past"
                                    :value="past"
                                    overlap
                                    left
                                >
                                    <v-tooltip
                                        :disabled="!showTooltips"
                                        bottom
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                icon
                                                small
                                                v-on="on"
                                                @click="subDay"
                                            >
                                                <img
                                                    :src="linksIcon"
                                                    class="iconToBlue"
                                                    style="height: 24px;"
                                                    alt="Vorheriger Tag"
                                                >
                                            </v-btn>
                                        </template>
                                        <span>Vorheriger Tag</span>
                                    </v-tooltip>
                                </v-badge>
                            </div>

                            <div class="text-center d-flex justify-center align-center">
                                <h2
                                    tabindex="0"
                                    class="planWidgetDisplayDate"
                                    :style="isDisplayDateToday(getDisplayDate()) ? 'color: #6995CD; font-weight: bold !important': 'color: var(--v-dunkelgrau-base); font-weight: 500'"
                                >
                                    {{ getDisplayDate() }}
                                </h2>
                            </div>

                            <div
                                class="d-flex justify-center"
                                :class="windowWidth < 950 ? 'px-1' : 'px-2'"
                            >
                                <v-badge
                                    color="red"
                                    :content="future"
                                    :value="future"
                                    overlap
                                >
                                    <v-tooltip
                                        :disabled="!showTooltips"
                                        bottom
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                icon
                                                small
                                                v-on="on"
                                                @click="addDay"
                                            >
                                                <img
                                                    :src="rechtsIcon"
                                                    class="iconToBlue"
                                                    style="height: 24px;"
                                                    alt="Nächster Tag"
                                                >
                                            </v-btn>
                                        </template>
                                        <span>Nächster Tag</span>
                                    </v-tooltip>
                                </v-badge>
                            </div>
                        </div>
                    </div>

                    <!--scrollable Stundenplan-->
                    <vue-custom-scrollbar
                        class="scroll-area"
                        :settings="settings"
                    >
                        <table style="width: 99%; margin: 0.5em auto 5em auto; color: var(--v-dunkelgrau-base)">
                            <v-row
                                v-for="(item,index) in slots"
                                :key="'date_' + index"
                                cols="12"
                                style="width: 99%; margin: 0.1em auto 0.1em auto;"
                            >
                                <v-col
                                    cols="2"
                                    style="font-weight: normal; color: var(--v-dunkelgrau-base)"
                                    class="text-center pa-2"
                                >
                                    <p
                                        :id="item._id"
                                        :aria-label="('0' + new Date(item.start).getHours()).slice(-2) + ':' + ('0' + new Date(item.start).getMinutes()).slice(-2) + ' bis ' + ('0' + new Date(item.end).getHours()).slice(-2) + ':' + ('0' + new Date(item.end).getMinutes()).slice(-2)"
                                        tabindex="0"
                                        class="ma-0"
                                        @keydown.right="arrowKeyRight(index)"
                                        @keydown.down="setFocusOnDateVertical(index, 'down')"
                                        @keydown.up="setFocusOnDateVertical(index, 'up')"
                                    >
                                        <span aria-hidden="true">
                                            {{ ("0" + new Date(item.start).getHours()).slice(-2) + ":" + ("0" + new Date(item.start).getMinutes()).slice(-2) }}
                                            <br> <!-- br is needed so the times are not in one line at ~ 900px display width -->
                                            {{ ("0" + new Date(item.end).getHours()).slice(-2) + ":" + ("0" + new Date(item.end).getMinutes()).slice(-2) }}
                                        </span>
                                        <!-- Accessibility -->
                                        <span class="visually-hidden">
                                            {{ 'Von ' + getAccessibilityTime(('0' + new Date(item.start).getHours()).slice(-2)) + ' Uhr ' + getAccessibilityTime(('0' + new Date(item.start).getMinutes()).slice(-2)) + ' bis ' + getAccessibilityTime(('0' + new Date(item.end).getHours()).slice(-2)) + ' Uhr ' + getAccessibilityTime(('0' + new Date(item.end).getMinutes()).slice(-2)) }}
                                        </span>
                                    </p>
                                </v-col>
                                <v-col
                                    cols="10"
                                    class="pa-0 pl-4"
                                >
                                    <div v-if="getAppointmentsToday(item._id).length">
                                        <div
                                            v-for="el in getAppointmentsToday(item._id)"
                                            :key="el._id"
                                        >
                                            <FachSchueler
                                                :person="person"
                                                :previous-appointment="getPreviousAppointmentDaily(index)"
                                                :following-appointment="getFollowingAppointmentDaily(index)"
                                                :is-running="isTimeslotCurrentlyRunningToday(item)"
                                                :is-joinable="isJoinable(item)"
                                                :is-open="el.open"
                                                :appointment="el"
                                                :request-appointments="requestAppointments"
                                                @pass-on-bbb-tut="receivedEmit"
                                                @update-me="requestAppointments"
                                                @focusDate="setFocusOnDate(index)"
                                            />
                                            <!-- Disable vue/no-v-html because appointment note is sanitized on backend-->
                                            <!-- eslint-disable vue/no-v-html -->
                                            <div
                                                v-if="el.note"
                                                style="display: flex; flex-flow: column; height: 100%; justify-content: center; padding-right: 20px;"
                                                v-html="sanitizeHtml(parseHtmlPreview(el.note))"
                                            />
                                            <!-- eslint-enable vue/no-v-html -->
                                        </div>
                                    </div>

                                    <div v-else>
                                        <FachSchueler
                                            :person="person"
                                            :is-running="isTimeslotCurrentlyRunningToday(item)"
                                            :request-appointments="requestAppointments"
                                            :is-joinable="isJoinable(item)"
                                            @pass-on-bbb-tut="receivedEmit"
                                            @update-me="requestAppointments"
                                        />
                                    </div>
                                </v-col>
                            </v-row>
                        </table>
                    </vue-custom-scrollbar>
                </div>
            </div>
        </v-row>

        <!--fullscreen/weekly view of stundenplan-->
        <v-dialog
            v-model="planVollbild"
            overlay-opacity="0.8"
            style="border-radius: 15px"
            @click:outside="planVollbild = false"
        >
            <v-row
                style="z-index: 15; overflow-x: hidden"
                class="ma-0"
            >
                <div style="border-radius: 15px; width: 100%; box-shadow: 1px 5px 5px silver; background: white;">
                    <div style="background-color: #6995CD; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white">
                        <v-row
                            class="mx-4"
                            style="display: flex; align-items: center;"
                        >
                            <v-col
                                cols="4"
                                class="pa-0 text-left"
                                style="font-size: x-large; display: flex; align-content: center"
                            >
                                <img
                                    :src="stundenplanIcon"
                                    alt=""
                                    style="height: 35px;"
                                    class="iconToWhite"
                                >
                                <p
                                    id="StundenplanVollbildHeading"
                                    tabindex="1"
                                    class="ml-2 mb-0"
                                    aria-label="Stundenplan"
                                >
                                    <span style="text-decoration: underline white">S</span>tundenplan
                                </p>
                            </v-col>

                            <v-col
                                cols="3"
                                class="pa-0"
                            >
                                <v-btn
                                    aria-label="Zum heutigen Tag springen"
                                    tabindex="2"
                                    class="mr-4 text-capitalize"
                                    style="background-color: #f8f8f880; color: white"
                                    @click="jumpToCurrentDay()"
                                >
                                    Heute
                                </v-btn>
                                <v-badge
                                    color="red"
                                    :content="pastWeek"
                                    :value="pastWeek"
                                    overlap
                                    left
                                >
                                    <v-btn
                                        tabindex="3"
                                        class="mx-2"
                                        style="background-color: #f8f8f880"
                                        elevation="2"
                                        icon
                                        small
                                        @click="subWeek"
                                    >
                                        <img
                                            :src="linksIcon"
                                            alt="Vorherige Woche"
                                            style="height: 20px;"
                                            class="iconToWhite"
                                        >
                                    </v-btn>
                                </v-badge>

                                <v-badge
                                    color="red"
                                    :content="futureWeek"
                                    :value="futureWeek"
                                    overlap
                                >
                                    <v-btn
                                        tabindex="4"
                                        class="mx-2"
                                        style="background-color: #f8f8f880"
                                        elevation="2"
                                        icon
                                        small
                                        @click="addWeek"
                                    >
                                        <img
                                            :src="rechtsIcon"
                                            alt="Nächste Woche"
                                            style="height: 20px;"
                                            class="iconToWhite"
                                        >
                                    </v-btn>
                                </v-badge>
                            </v-col>

                            <v-col
                                cols="2"
                                class="px-0 text-right"
                            >
                                <p
                                    style="width: fit-content; float: right"
                                    tabindex="5"
                                    class="mb-0"
                                >
                                    {{ getDisplayMonth() + ' ' + getMonday(date).getFullYear() }}
                                </p>
                            </v-col>

                            <v-col
                                cols="2"
                                class="px-0 text-right"
                                style="font-size: medium"
                            >
                                <p
                                    :aria-label="'Kalenderwoche ' + getMonday(date).getWeek()"
                                    style="width: fit-content; float: right"
                                    tabindex="6"
                                    class="mb-0"
                                >
                                    KW {{ getMonday(date).getWeek() }}
                                </p>
                            </v-col>

                            <v-col
                                cols="1"
                                class="px-0 text-right"
                            >
                                <v-btn
                                    :tabindex="slots.length + 40"
                                    x-small
                                    elevation="2"
                                    style="background-color: #f8f8f880; width: 48px; height: 48px"
                                    @click="() => { planVollbild = false; setFocusOn('StundenplanVollbildButton') }"
                                >
                                    <img
                                        :src="schliessenIcon"
                                        alt="Wochenansicht schließen"
                                        style="height: 40px;"
                                        class="iconToWhite"
                                    >
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- Body of fullscreen View -->
                    <v-row style="width: 99%; margin: 1em auto 0 auto;">
                        <th
                            scope="col"
                            style="width: 10%"
                        >
                            <img
                                :src="uhrIcon"
                                alt=""
                                width="24"
                                height="24"
                            >
                        </th>

                        <v-row style="width: 1%" />

                        <v-row
                            style="width: 17%"
                            class="centerHeader"
                        >
                            <th
                                v-if="isDisplayDateTodayShort(getDisplayDateMonday())"
                                scope="col"
                                class="colHeaderCurrent text-center"
                                style="width: 100%"
                            >
                                <p
                                    tabindex="7"
                                    class="mb-0 mx-auto"
                                    style="width: fit-content;"
                                    @focus="selectedDay = 'Mo'"
                                >
                                    Mo., {{ (getMonday(date).getDate())+'.'+(getMonday(date).getMonth()+1) +'.'+getMonday(date).getFullYear().toString().slice(-2) }}
                                </p>
                            </th>
                            <th
                                v-else
                                scope="col"
                                class="colHeader text-center"
                                style="width: 100%"
                            >
                                <p
                                    tabindex="7"
                                    class="mb-0 mx-auto"
                                    style="width: fit-content;"
                                    @focus="selectedDay = 'Mo'"
                                >
                                    Mo., {{ (getMonday(date).getDate())+'.'+(getMonday(date).getMonth()+1) +'.'+getMonday(date).getFullYear().toString().slice(-2) }}
                                </p>
                            </th>
                        </v-row>

                        <v-row style="width: 1%" />

                        <v-row
                            style="width: 17%"
                            class="greyDayHeader centerHeader"
                        >
                            <th
                                v-if="isDisplayDateTodayShort(getDisplayDateTuesday())"
                                scope="col"
                                class="colHeaderCurrent text-center"
                                style="width: 100%"
                            >
                                <p
                                    :tabindex="slots.length + 8"
                                    class="mb-0 mx-auto"
                                    style="width: fit-content;"
                                    @focus="selectedDay = 'Di'"
                                >
                                    Di., {{ (getTuesday(date).getDate())+'.'+(getTuesday(date).getMonth()+1) +'.'+getTuesday(date).getFullYear().toString().slice(-2) }}
                                </p>
                            </th>
                            <th
                                v-else
                                scope="col"
                                class="colHeader text-center"
                                style="width: 100%"
                            >
                                <p
                                    :tabindex="slots.length + 8"
                                    class="mb-0 mx-auto"
                                    style="width: fit-content;"
                                    @focus="selectedDay = 'Di'"
                                >
                                    Di., {{ (getTuesday(date).getDate())+'.'+(getTuesday(date).getMonth()+1) +'.'+getTuesday(date).getFullYear().toString().slice(-2) }}
                                </p>
                            </th>
                        </v-row>

                        <v-row style="width: 1%" />

                        <v-row
                            style="width: 17%"
                            class="centerHeader"
                        >
                            <th
                                v-if="isDisplayDateTodayShort(getDisplayDateWednesday())"
                                scope="col"
                                class="colHeaderCurrent text-center"
                                style="width: 100%"
                            >
                                <p
                                    :tabindex="slots.length * 2 + 8"
                                    class="mb-0 mx-auto"
                                    style="width: fit-content;"
                                    @focus="selectedDay = 'Mi'"
                                >
                                    Mi., {{ (getWednesday(date).getDate())+'.'+(getWednesday(date).getMonth()+1) +'.'+getWednesday(date).getFullYear().toString().slice(-2) }}
                                </p>
                            </th>
                            <th
                                v-else
                                scope="col"
                                class="colHeader text-center"
                                style="width: 100%"
                            >
                                <p
                                    :tabindex="slots.length * 2 + 8"
                                    class="mb-0 mx-auto"
                                    style="width: fit-content;"
                                    @focus="selectedDay = 'Mi'"
                                >
                                    Mi., {{ (getWednesday(date).getDate())+'.'+(getWednesday(date).getMonth()+1) +'.'+getWednesday(date).getFullYear().toString().slice(-2) }}
                                </p>
                            </th>
                        </v-row>

                        <v-row style="width: 1%" />

                        <v-row
                            style="width: 17%"
                            class="greyDayHeader centerHeader"
                        >
                            <th
                                v-if="isDisplayDateTodayShort(getDisplayDateThursday())"
                                scope="col"
                                class="colHeaderCurrent text-center"
                                style="width: 100%"
                            >
                                <p
                                    :tabindex="slots.length * 3 + 8"
                                    class="mb-0 mx-auto"
                                    style="width: fit-content;"
                                    @focus="selectedDay = 'Do'"
                                >
                                    Do., {{ (getThursday(date).getDate())+'.'+(getThursday(date).getMonth()+1) +'.'+getThursday(date).getFullYear().toString().slice(-2) }}
                                </p>
                            </th>
                            <th
                                v-else
                                scope="col"
                                class="colHeader text-center"
                                style="width: 100%"
                            >
                                <p
                                    :tabindex="slots.length * 3 + 8"
                                    class="mb-0 mx-auto"
                                    style="width: fit-content;"
                                    @focus="selectedDay = 'Do'"
                                >
                                    Do., {{ (getThursday(date).getDate())+'.'+(getThursday(date).getMonth()+1) +'.'+getThursday(date).getFullYear().toString().slice(-2) }}
                                </p>
                            </th>
                        </v-row>

                        <v-row style="width: 1%" />

                        <v-row
                            style="width: 17%"
                            class="centerHeader"
                        >
                            <th
                                v-if="isDisplayDateTodayShort(getDisplayDateFriday())"
                                scope="col"
                                class="colHeaderCurrent text-center"
                                style="width: 100%"
                            >
                                <p
                                    :tabindex="slots.length * 4 + 8"
                                    class="mb-0 mx-auto"
                                    style="width: fit-content;"
                                    @focus="selectedDay = 'Fr'"
                                >
                                    Fr., {{ (getFriday(date).getDate())+'.'+(getFriday(date).getMonth()+1) +'.'+getFriday(date).getFullYear().toString().slice(-2) }}
                                </p>
                            </th>
                            <th
                                v-else
                                scope="col"
                                class="colHeader text-center"
                                style="width: 100%"
                            >
                                <p
                                    :tabindex="slots.length * 4 + 8"
                                    class="mb-0 mx-auto"
                                    style="width: fit-content;"
                                    @focus="selectedDay = 'Fr'"
                                >
                                    Fr., {{ (getFriday(date).getDate())+'.'+(getFriday(date).getMonth()+1) +'.'+getFriday(date).getFullYear().toString().slice(-2) }}
                                </p>
                            </th>
                        </v-row>
                    </v-row>

                    <vue-custom-scrollbar
                        class="scroll-area"
                        :settings="settings"
                    >
                        <v-row
                            v-for="(item,index) in slots"
                            :key="'date2_' + index"
                            style="width: 99%; margin: 0 auto 0 auto;"
                        >
                            <th
                                scope="row"
                                style="width: 10%; color: var(--v-dunkelgrau-base); font-weight: normal;"
                                class="d-flex align-center justify-center pa-2"
                            >
                                <p
                                    :id="item._id + '-fullscreen'"
                                    :tabindex="getTabIndex(index)"
                                    class="mb-0"
                                    :aria-label="('0' + new Date(item.start).getHours()).slice(-2) + ':' + ('0' + new Date(item.start).getMinutes()).slice(-2) + ' bis ' + ('0' + new Date(item.end).getHours()).slice(-2) + ':' + ('0' + new Date(item.end).getMinutes()).slice(-2)"
                                    @keydown.right="arrowKeyRight(index)"
                                    @keydown.down="setFocusOnDateVertical(index, 'down')"
                                    @keydown.up="setFocusOnDateVertical(index, 'up')"
                                >
                                    {{ ("0" + new Date(item.start).getHours()).slice(-2) + ":" + ("0" + new Date(item.start).getMinutes()).slice(-2) }}
                                    <br>
                                    {{ ("0" + new Date(item.end).getHours()).slice(-2) + ":" + ("0" + new Date(item.end).getMinutes()).slice(-2) }}
                                </p>
                            </th>

                            <v-row style="width: 1%" />

                            <v-row
                                style="width: 17%"
                                class="centerFach"
                            >
                                <div
                                    v-if="getAppointmentsPerDay(item._id, 'mon').length"
                                    style="width: 100%"
                                >
                                    <div
                                        v-for="(el) in getAppointmentsPerDay(item._id, 'mon')"
                                        :key="el._id + 'mon'"
                                    >
                                        <FachSchueler
                                            :person="person"
                                            :is-running="isTimeslotCurrentlyRunning(item, 'mon')"
                                            :appointment="el"
                                            :previous-appointment="getPreviousAppointment(index, 'mon')"
                                            :following-appointment="getFollowingAppointment(index, 'mon')"
                                            :left-appointment="getLeftAppointment(index, 'mon')"
                                            :right-appointment="getRightAppointment(index, 'mon')"
                                            :request-appointments="requestAppointments"
                                            :is-joinable="isJoinable(item)"
                                            :plan-vollbild="planVollbild"
                                            :tab-order="index + 8"
                                            :tab-order-popup="slots.length * 5 + 8"
                                            @pass-on-bbb-tut="receivedEmit"
                                            @focusDateFullscreen="setFocusOnDateFullscreen(index)"
                                            @update-me="requestAppointments"
                                        />
                                    </div>
                                </div>
                                <div
                                    v-else
                                    style="width: 100%"
                                >
                                    <FachSchueler
                                        :person="person"
                                        :is-running="isTimeslotCurrentlyRunning(item, 'mon')"
                                        :request-appointments="requestAppointments"
                                        :is-joinable="isJoinable(item)"
                                        :plan-vollbild="planVollbild"
                                        :tab-order="index + 8"
                                        :tab-order-popup="slots.length * 5 + 8"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                    />
                                </div>
                            </v-row>

                            <v-row style="width: 1%" />

                            <v-row
                                style="width: 17%"
                                class="centerFach greyDayBody"
                                :style="index != Object.keys(slots).length-1 ? '' : 'border-radius: 0 0 10px 10px'"
                            >
                                <div
                                    v-if="getAppointmentsPerDay(item._id, 'die').length"
                                    style="width: 100%"
                                >
                                    <div
                                        v-for="el in getAppointmentsPerDay(item._id, 'die')"
                                        :key="el._id + 'tue'"
                                    >
                                        <FachSchueler
                                            :person="person"
                                            :is-running="isTimeslotCurrentlyRunning(item, 'die')"
                                            :appointment="el"
                                            :previous-appointment="getPreviousAppointment(index, 'die')"
                                            :following-appointment="getFollowingAppointment(index, 'die')"
                                            :left-appointment="getLeftAppointment(index, 'die')"
                                            :right-appointment="getRightAppointment(index, 'die')"
                                            :request-appointments="requestAppointments"
                                            :is-joinable="isJoinable(item)"
                                            :plan-vollbild="planVollbild"
                                            :tab-order="slots.length + index + 8"
                                            :tab-order-popup="slots.length * 5 + 8"
                                            @pass-on-bbb-tut="receivedEmit"
                                            @update-me="requestAppointments"
                                        />
                                    </div>
                                </div>
                                <div
                                    v-else
                                    style="width: 100%"
                                >
                                    <FachSchueler
                                        :person="person"
                                        :is-running="isTimeslotCurrentlyRunning(item, 'die')"
                                        :request-appointments="requestAppointments"
                                        :is-joinable="isJoinable(item)"
                                        :plan-vollbild="planVollbild"
                                        :tab-order="slots.length + index + 8"
                                        :tab-order-popup="slots.length * 5 + 8"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                    />
                                </div>
                            </v-row>

                            <v-row style="width: 1%" />

                            <v-row
                                style="width: 17%"
                                class="centerFach"
                            >
                                <div
                                    v-if="getAppointmentsPerDay(item._id, 'mit').length"
                                    style="width: 100%"
                                >
                                    <div
                                        v-for="el in getAppointmentsPerDay(item._id, 'mit')"
                                        :key="el._id + 'wed'"
                                    >
                                        <FachSchueler
                                            :person="person"
                                            :is-running="isTimeslotCurrentlyRunning(item, 'mit')"
                                            :appointment="el"
                                            :previous-appointment="getPreviousAppointment(index, 'mit')"
                                            :following-appointment="getFollowingAppointment(index, 'mit')"
                                            :left-appointment="getLeftAppointment(index, 'mit')"
                                            :right-appointment="getRightAppointment(index, 'mit')"
                                            :request-appointments="requestAppointments"
                                            :is-joinable="isJoinable(item)"
                                            :plan-vollbild="planVollbild"
                                            :tab-order="slots.length * 2 + index + 8"
                                            :tab-order-popup="slots.length * 5 + 8"
                                            @pass-on-bbb-tut="receivedEmit"
                                            @update-me="requestAppointments"
                                        />
                                    </div>
                                </div>
                                <div
                                    v-else
                                    style="width: 100%"
                                >
                                    <FachSchueler
                                        :person="person"
                                        :is-running="isTimeslotCurrentlyRunning(item, 'mit')"
                                        :request-appointments="requestAppointments"
                                        :is-joinable="isJoinable(item)"
                                        :plan-vollbild="planVollbild"
                                        :tab-order="slots.length * 2 + index + 8"
                                        :tab-order-popup="slots.length * 5 + 8"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                    />
                                </div>
                            </v-row>

                            <v-row style="width: 1%" />

                            <v-row
                                style="width: 17%"
                                class="centerFach greyDayBody"
                                :style="index != Object.keys(slots).length-1 ? '' : 'border-radius: 0 0 10px 10px'"
                            >
                                <div
                                    v-if="getAppointmentsPerDay(item._id, 'don').length"
                                    style="width: 100%"
                                >
                                    <div
                                        v-for="el in getAppointmentsPerDay(item._id, 'don')"
                                        :key="el._id + 'thr'"
                                    >
                                        <FachSchueler
                                            :person="person"
                                            :is-running="isTimeslotCurrentlyRunning(item, 'don')"
                                            :appointment="el"
                                            :previous-appointment="getPreviousAppointment(index, 'don')"
                                            :following-appointment="getFollowingAppointment(index, 'don')"
                                            :left-appointment="getLeftAppointment(index, 'don')"
                                            :right-appointment="getRightAppointment(index, 'don')"
                                            :request-appointments="requestAppointments"
                                            :is-joinable="isJoinable(item)"
                                            :plan-vollbild="planVollbild"
                                            :tab-order="slots.length * 3 + index + 8"
                                            :tab-order-popup="slots.length * 5 + 8"
                                            @pass-on-bbb-tut="receivedEmit"
                                            @update-me="requestAppointments"
                                        />
                                    </div>
                                </div>
                                <div
                                    v-else
                                    style="width: 100%"
                                >
                                    <FachSchueler
                                        :person="person"
                                        :is-running="isTimeslotCurrentlyRunning(item, 'don')"
                                        :request-appointments="requestAppointments"
                                        :is-joinable="isJoinable(item)"
                                        :tab-order="slots.length * 3 + index + 8"
                                        :tab-order-popup="slots.length * 5 + 8"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                    />
                                </div>
                            </v-row>

                            <v-row style="width: 1%" />

                            <v-row
                                style="width: 17%"
                                class="centerFach"
                            >
                                <div
                                    v-if="getAppointmentsPerDay(item._id, 'fre').length"
                                    style="width: 100%"
                                >
                                    <div
                                        v-for="el in getAppointmentsPerDay(item._id, 'fre')"
                                        :key="el._id + 'fri'"
                                    >
                                        <FachSchueler
                                            :person="person"
                                            :is-running="isTimeslotCurrentlyRunning(item, 'fre')"
                                            :appointment="el"
                                            :previous-appointment="getPreviousAppointment(index, 'fre')"
                                            :following-appointment="getFollowingAppointment(index, 'fre')"
                                            :left-appointment="getLeftAppointment(index, 'fre')"
                                            :right-appointment="getRightAppointment(index, 'fre')"
                                            :request-appointments="requestAppointments"
                                            :is-joinable="isJoinable(item)"
                                            :plan-vollbild="planVollbild"
                                            :tab-order="slots.length * 4 + index + 8"
                                            :tab-order-popup="slots.length * 5 + 8"
                                            @pass-on-bbb-tut="receivedEmit"
                                            @update-me="requestAppointments"
                                        />
                                    </div>
                                </div>
                                <div
                                    v-else
                                    style="width: 100%"
                                >
                                    <FachSchueler
                                        :person="person"
                                        :is-running="isTimeslotCurrentlyRunning(item, 'fre')"
                                        :request-appointments="requestAppointments"
                                        :is-joinable="isJoinable(item)"
                                        :plan-vollbild="planVollbild"
                                        :tab-order="slots.length * 4 + index + 8"
                                        :tab-order-popup="slots.length * 5 + 8"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                    />
                                </div>
                            </v-row>
                        </v-row>
                    </vue-custom-scrollbar>
                </div>
            </v-row>
        </v-dialog>
    </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import sanitizeHtml from '../../util/sanitizeHtml'
import FachSchueler from "./FachSchueler"
import uhrIcon from '../../assets/Icons/uhr-04.svg'
import fullscreenIcon from '../../assets/Icons/fullscreen.svg'
import linksIcon from '../../assets/Icons/links-filled-10.svg'
import rechtsIcon from '../../assets/Icons/rechts-filled-11.svg'
import schliessenIcon from '../../assets/Icons/abbrechen-08.svg'
import stundenplanIcon from '../../assets/Icons/timetable.svg'
import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
import { parseHtml } from "@/util/filePreviewUtils";

export default {
    name: "PlanWidget",
    components: {
        FachSchueler,
        vueCustomScrollbar,
    },
    props: {
        small: { required: false, default: false },
    },
    data() {
        return {
            sanitizeHtml,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
                //left out keyboard
                handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch']
            },
            date: new Date(),    //is being changed when date in calendar is changed
            today: new Date(),   //don't change! reference to highlight current day
            appointments: [],
            person: {
                accessibility: {
                    screenreader: false,
                }
            },
            slots: [],
            blackboard: [],
            days: ['mon', 'die', 'mit', 'don', 'fre'],

            future: 0,
            past: 0,

            futureWeek: 0,
            pastWeek: 0,

            selectedDay: 'Mo',

            planVollbild: false,
            uhrIcon,
            linksIcon,
            rechtsIcon,
            schliessenIcon,
            stundenplanIcon,
            fullscreenIcon,
        };
    },
    computed: {
        ...mapState("tooltips", ["showTooltips"]),
        ...mapState('util', ['keyboard', 'serverTimeOffset', 'windowWidth']),
        ...mapGetters('util', ['currentlyOpenAppointment']),
    },
    watch: {
        currentlyOpenAppointment(newVal, oldVal) {
            if (!newVal && oldVal) {
                this.requestAppointments(true);
            }
        }
    },
    beforeCreate() {
        // Returns the ISO week of the date.
        Date.prototype.getWeek = function() {
            var date = new Date(this.getTime());
            date.setHours(0, 0, 0, 0);
            // Thursday in current week decides the year.
            date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
            // January 4 is always in week 1.
            var week1 = new Date(date.getFullYear(), 0, 4);
            // Adjust to Thursday in week 1 and count number of weeks from date to week1.
            return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                - 3 + (week1.getDay() + 6) % 7) / 7);
        }
    },
    async mounted() {
        this.date = await this.getDay();
        this.today = await this.getDay();
        this.requestSlots();
        await this.requestAppointments();
        this.requestBlackboard();
        // this.jumpToCurrentDay();    //because somehow it jumps to a different day sometimes

        setTimeout(function(){ speechSynthesis.cancel(); }, 500);   //if you spam through tutorial this should cancel speaking
    },

    async beforeMount() {
        this.requestPerson();
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions("pupils", ["getMePupil"]),
        ...mapActions("auth", ["logoutUser"]),
        ...mapActions("blackboard", ["getBlackboard"]),
        ...mapActions("util", ["loadServerTime"]),
        ...mapActions("arrowKeyControl", ["setFocusId"]),
        ...mapMutations('badges', [ 'setTimetableBadge' ]),

        getTabIndex(index) {
            switch(this.selectedDay) {
                case 'Mo':
                    return index + 8;
                case 'Di':
                    return this.slots.length + index + 8;
                case 'Mi':
                    return this.slots.length * 2 + index + 8;
                case 'Do':
                    return this.slots.length * 3 + index + 8;
                case 'Fr':
                    return this.slots.length * 4 + index + 8;
                default:
                    return index + 8;
            }
        },

        receivedEmit(callback) {
            /*emit to PlanSchueler*/
            this.$emit('start-bbb-tut', callback);
        },

        async requestAppointments(update = false) {
            this.appointments = await this.getAppointments(update);
            this.checkParams();
            this.countBadges();
            this.countBadgesWeek();
        },

        async checkParams() {
            let appointment = this.appointments.find(el => this.$route.query.appointment === el._id);
            if (appointment) {
                this.date = new Date(appointment.day);
                appointment['open'] = true;

                // remove needed query because they fulfilled their purpose
                setTimeout(async () => {
                    await this.$router.replace({ query: { appointment: undefined } });
                }, 1000);
            }
        },

        async requestSlots() {
            this.slots = await this.getSlots();
        },

        async requestPerson() {
            this.person = await this.getMePupil();
            return this.person;
        },

        async requestBlackboard() {
            this.blackboard = await this.getBlackboard();
        },

        jumpToCurrentDay() {
            this.date = this.today;
        },

        addWeek() {
            this.date = new Date(this.date .getFullYear(), this.date .getMonth(), this.date .getDate()+7);
            this.countBadges();
            this.countBadgesWeek();
        },
        subWeek() {
            this.date = new Date(this.date .getFullYear(), this.date .getMonth(), this.date .getDate()-7);
            this.countBadges();
            this.countBadgesWeek();
        },
        addDay() {
            do {
                this.date = new Date(this.date .getFullYear(), this.date .getMonth(), this.date .getDate()+1);
            } while (!(this.date .getDay() % 6))
            this.countBadges();
            this.countBadgesWeek();
        },
        subDay() {
            do {
                this.date = new Date(this.date .getFullYear(), this.date .getMonth(), this.date .getDate()-1);
            } while (!(this.date .getDay() % 6))
            this.countBadges();
            this.countBadgesWeek();
        },
        //compare displayDate with today, return true if equal, to color current day
        isDisplayDateToday(compareDate) {
            let heute, monat;
            let jahr = (this.today).toString().slice(13, 15);
            let tag = (this.today).toString().slice(8, 10);

            //from month string to number
            switch ((this.today).toString().slice(4, 7)) {
                case "Jan":
                    monat = "01"; break;
                case "Feb":
                    monat = "02"; break;
                case "Mar":
                    monat = "03"; break;
                case "Apr":
                    monat = "04"; break;
                case "May":
                    monat = "05"; break;
                case "Jun":
                    monat = "06"; break;
                case "Jul":
                    monat = "07"; break;
                case "Aug":
                    monat = "08"; break;
                case "Sep":
                    monat = "09"; break;
                case "Oct":
                    monat = "10"; break;
                case "Nov":
                    monat = "11"; break;
                case "Dec":
                    monat = "12"; break;
            }

            heute = tag + "." + monat + "." + jahr;

            if ((compareDate.toString()).slice(5, compareDate.toString().length) === heute) {
                return true;
            }
            else {
                return false;
            }
        },

        //WEEKLY VIEW Version | compare displayDate with today, return true if equal, to color current day
        isDisplayDateTodayShort(compareDate) {
            let heute, monat, tag;
            let jahr = (this.today).toString().slice(13, 15);
            let unshortenedDay = (this.today).toString().slice(8, 10);

            //if day shorter than 10, cut of first character
            if (unshortenedDay.slice(0,1) === "0") {
                tag = unshortenedDay.slice(1,2);
            }
            else {
                tag = unshortenedDay.slice(0,2);
            }

            //from month string to number
            switch ((this.today).toString().slice(4, 7)) {
                case "Jan":
                    monat = "1"; break;
                case "Feb":
                    monat = "2"; break;
                case "Mar":
                    monat = "3"; break;
                case "Apr":
                    monat = "4"; break;
                case "May":
                    monat = "5"; break;
                case "Jun":
                    monat = "6"; break;
                case "Jul":
                    monat = "7"; break;
                case "Aug":
                    monat = "8"; break;
                case "Sep":
                    monat = "9"; break;
                case "Oct":
                    monat = "10"; break;
                case "Nov":
                    monat = "11"; break;
                case "Dec":
                    monat = "12"; break;
            }

            heute = tag + "." + monat + "." + jahr;

            if ((compareDate.toString()).slice(5, compareDate.toString().length) === heute) {
                return true;
            }
            else {
                return false;
            }
        },
        //to display month string in header
        getDisplayMonth() {
            let monat;
            //from shortened month string to full month string
            switch ((this.date).toString().slice(4, 7)) {
                case "Jan":
                    monat = "Januar"; break;
                case "Feb":
                    monat = "Februar"; break;
                case "Mar":
                    monat = "März"; break;
                case "Apr":
                    monat = "April"; break;
                case "May":
                    monat = "Mai"; break;
                case "Jun":
                    monat = "Juni"; break;
                case "Jul":
                    monat = "Juli"; break;
                case "Aug":
                    monat = "August"; break;
                case "Sep":
                    monat = "September"; break;
                case "Oct":
                    monat = "Oktober"; break;
                case "Nov":
                    monat = "November"; break;
                case "Dec":
                    monat = "Dezember"; break;
            }
            return monat;
        },
        getDisplayDate() {
            let heute = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
            let datum, tag;

            switch (heute.getDay()) {
                case 0:
                    tag = ("So.");
                    break;
                case 1:
                    tag = ("Mo.");
                    break;
                case 2:
                    tag = ("Di.");
                    break;
                case 3:
                    tag = ("Mi.");
                    break;
                case 4:
                    tag = ("Do.");
                    break;
                case 5:
                    tag = ("Fr.");
                    break;
                case 6:
                    tag = ("Sa.");
                    break;
                default:
                    tag = ("TEST");
            }
            //add leading zeros to date
            //for day and month
            if (heute.getDate() < 10 && heute.getMonth()+1 < 10) {
                datum = (tag + ", 0" + (heute.getDate()) + '.0' + (heute.getMonth()+1) + '.' + heute.getFullYear().toString().slice(-2));
            } //for day
            else if (heute.getDate() < 10 ) {
                datum = (tag + ", 0" + (heute.getDate()) + '.' + (heute.getMonth()+1) + '.' + heute.getFullYear().toString().slice(-2));
            } //for month
            else if (heute.getMonth()+1 < 10 ) {
                datum = (tag + ", " + (heute.getDate()) + '.0' + (heute.getMonth()+1) + '.' + heute.getFullYear().toString().slice(-2));
            } //regular
            else {
                datum = (tag + ", " + (heute.getDate()) + '.' + (heute.getMonth()+1) + '.' + heute.getFullYear().toString().slice(-2));
            }
            return datum;
        },
        getDisplayDateShort() {
            return this.getDisplayDate().slice(0,11);
        },
        getDisplayDateMonday() {
            return "Mo., " + (this.getMonday(this.date).getDate()) +'.' + (this.getMonday(this.date).getMonth()+1) + '.' + this.getMonday(this.date).getFullYear().toString().slice(-2)
        },
        getDisplayDateTuesday() {
            return "Di., " + (this.getTuesday(this.date).getDate()) +'.' + (this.getTuesday(this.date).getMonth()+1) + '.' + this.getTuesday(this.date).getFullYear().toString().slice(-2)
        },
        getDisplayDateWednesday() {
            return "Mi., " + (this.getWednesday(this.date).getDate()) +'.' + (this.getWednesday(this.date).getMonth()+1) + '.' + this.getWednesday(this.date).getFullYear().toString().slice(-2)
        },
        getDisplayDateThursday() {
            return "Do., " + (this.getThursday(this.date).getDate()) +'.' + (this.getThursday(this.date).getMonth()+1) + '.' + this.getThursday(this.date).getFullYear().toString().slice(-2)
        },
        getDisplayDateFriday() {
            return "Fr., " + (this.getFriday(this.date).getDate()) +'.' + (this.getFriday(this.date).getMonth()+1) + '.' + this.getFriday(this.date).getFullYear().toString().slice(-2)
        },
        async getDay() {
            let date = new Date(await this.loadServerTime());
            while (!(date .getDay() % 6)) {
                date = new Date(date .getFullYear(), date .getMonth(), date .getDate()-1);
            }
            return date;
        },
        getMonday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getTuesday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -5:2); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getWednesday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -4:3); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getThursday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -3:4); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getFriday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -2:5); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },

        getAppointmentToday(slot) {
            return this.appointments.find((el) => { return (el.timeslot === slot && this.sameDay(new Date(el.day), this.date))})
        },
        getAppointmentsToday(slot) {
            return this.appointments.filter((el) => { return (el.timeslot === slot && this.sameDay(new Date(el.day), this.date))})
        },
        getAppointment(slot, day) {
            switch (day) {
                case 'mon':
                    day = this.getMonday(this.date);
                    break;
                case 'die':
                    day = this.getTuesday(this.date);
                    break;
                case 'mit':
                    day = this.getWednesday(this.date);
                    break;
                case 'don':
                    day = this.getThursday(this.date);
                    break;
                case 'fre':
                    day = this.getFriday(this.date);
                    break;
            }
            return this.appointments.find((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), day))
            })
        },

        getAppointmentsPerDay(slot, day) {
            switch (day) {
                case 'mon':
                    day = this.getMonday(this.date);
                    break;
                case 'die':
                    day = this.getTuesday(this.date);
                    break;
                case 'mit':
                    day = this.getWednesday(this.date);
                    break;
                case 'don':
                    day = this.getThursday(this.date);
                    break;
                case 'fre':
                    day = this.getFriday(this.date);
                    break;
            }
            return this.appointments.filter((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), day))
            })
        },

        countBadges() {
            this.future = 0;
            this.past = 0;
            let today = 0;
            if (!Array.isArray(this.appointments)) return;
            this.appointments.forEach((el) => {
                if(!this.sameDay(new Date(el.day), this.date)){
                    if(new Date(el.day) > this.date) {
                        this.future += el.teacherUploads.filter((element) => !element.seenByMe).length;
                    } else {
                        this.past += el.teacherUploads.filter((element) => !element.seenByMe).length;
                    }
                } else {
                    today += el.teacherUploads.filter((element) => !element.seenByMe).length;
                }
            })
            this.setTimetableBadge(this.future + today + this.past);
        },

        countBadgesWeek() {
            this.futureWeek = 0;
            this.pastWeek = 0;
            if (!Array.isArray(this.appointments)) return;
            this.appointments.forEach((el) => {
                if(!(new Date(el.day).getWeek() == this.date.getWeek())){
                    if(new Date(el.day) > this.date) {
                        this.futureWeek += el.teacherUploads.filter((element) => !element.seenByMe).length;
                    } else {
                        this.pastWeek += el.teacherUploads.filter((element) => !element.seenByMe).length;
                    }
                }
            })
        },

        sameDay(first, second) {
            return   first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate();
        },

        // Not the cleanest code i know :/ - Jan
        isTimeslotCurrentlyRunning(timeslot, day) {
            const currentTimeAndDay = this.today;
            const appointment = this.getAppointment(timeslot._id, day);
            if (appointment) {
                const appointmentDate = new Date(appointment.day).toDateString();
                if (appointmentDate === currentTimeAndDay.toDateString()) {
                    // If we are in here that means the appointment is today
                    if (new Date(timeslot.start).toLocaleTimeString('de-DE') <= currentTimeAndDay.toLocaleTimeString('de-DE')
                        && new Date(timeslot.end).toLocaleTimeString('de-DE') > currentTimeAndDay.toLocaleTimeString('de-DE')) {
                        // Current time is between start and end of timeslot
                        return true;
                    }
                }
            }
            return false;
        },

        isTimeslotCurrentlyRunningToday(timeslot) {
            const currentTimeAndDay = this.today;
            const appointment = this.getAppointmentToday(timeslot._id);
            if (appointment) {
                const appointmentDate = new Date(appointment.day).toDateString();
                if (appointmentDate === currentTimeAndDay.toDateString()) {
                    // If we are in here that means the appointment is today
                    if (new Date(timeslot.start).toLocaleTimeString('de-DE') <= currentTimeAndDay.toLocaleTimeString('de-DE')
                        && new Date(timeslot.end).toLocaleTimeString('de-DE') > currentTimeAndDay.toLocaleTimeString('de-DE')) {
                        // Current time is between start and end of timeslot
                        return true;
                    }
                }
            }
            return false;
        },

        //lesson is joinable: 15 minutes before start of timeslot until 15 minutes after end of timeslot
        isJoinable(timeslot) {
            const time = new Date(Date.now() + this.serverTimeOffset) ;
            const localeStart = new Date(timeslot.start);
            localeStart.setMinutes(localeStart.getMinutes() - 15);

            const localeEnd = new Date(timeslot.end);
            localeEnd.setMinutes(localeEnd.getMinutes() + 15);
            return localeStart.toLocaleTimeString('de-DE') <= time.toLocaleTimeString('de-DE')
                && localeEnd.toLocaleTimeString('de-DE') >= time.toLocaleTimeString('de-DE');
        },

        calcCols(width) {
            if (width > 450) {
                return '5'
            }
            else if (width > 380) {
                return '6'
            }
            else {
                return '7'
            }
        },

        dateMargin(width) {
            if (width > 400) {
                return 'ml-4'
            }
            else if (width > 350) {
                return 'ml-2'
            }
            else {
                return 'ml-1'
            }
        },

        todayMargin(width) {
            if (width > 460) {
                return 'mr-8'
            }
            else if (width > 400) {
                return 'mr-6'
            }
            else if (width > 355) {
                return 'mr-4'
            }
            else {
                return 'mr-2'
            }
        },
        parseHtmlPreview(text){
            return parseHtml(false,text);
        },
        getFollowingAppointment(index, day) {
            if(!(index + 1 < 0 || index + 1 > this.slots.length - 1)) {
                return this.getAppointmentsPerDay(this.slots[index + 1]._id, day);
            } else {
                return null;
            }
        },
        getPreviousAppointment (index, day) {
            if(!(index - 1 < 0 || index - 1 > this.slots.length - 1)) {
                return this.getAppointmentsPerDay(this.slots[index - 1]._id, day);
            } else {
                return null;
            }
        },
        getLeftAppointment (index, day) {
            let leftDay = null;
            for(let i = 0; i < this.days.length; i++) {
                if(this.days[i] === day && i !== 0) {
                    leftDay = this.days[i - 1];
                    break;
                }
            }
            if(leftDay) {
                return this.getAppointmentsPerDay(this.slots[index]._id, leftDay);
            } else {
                return null;
            }
        },
        getRightAppointment (index, day) {
            let rightDay = null;
            for(let i = 0; i < this.days.length; i++) {
                if(this.days[i] === day && i !== 4) {
                    rightDay = this.days[i + 1];
                    break;
                }
            }
            if(rightDay) {
                return this.getAppointmentsPerDay(this.slots[index]._id, rightDay);
            } else {
                return null;
            }
        },
        arrowKeyRight(index) {
            if(!this.keyboard) {
                if(this.planVollbild) {
                    let appointment = this.getAppointmentsPerDay(this.slots[index]._id, 'mon');
                    if (appointment && appointment[0]) {
                        this.setFocusId(appointment[0]._id);
                    }
                } else {
                    let appointment = this.getAppointmentsToday(this.slots[index]._id);
                    if (appointment && appointment[0]) {
                        this.setFocusId(appointment[0]._id);
                    }
                }
            }
        },
        setFocusOnDateVertical(index, direction) {
            if(!this.keyboard) {
                if (direction === 'down') {
                    if (this.slots[index + 1]) {
                        if(this.planVollbild) {
                            document.getElementById(this.slots[index + 1]._id + '-fullscreen').focus();
                        } else {
                            document.getElementById(this.slots[index + 1]._id).focus();
                        }
                    }
                } else if (direction === 'up') {
                    if (this.slots[index - 1]) {
                        if(this.planVollbild) {
                            document.getElementById(this.slots[index - 1]._id + '-fullscreen').focus();
                        } else {
                            document.getElementById(this.slots[index - 1]._id).focus();
                        }
                    }
                }
            }
        },
        setFocusOnDate(index) {
            document.getElementById(this.slots[index]._id).focus();
        },
        setFocusOnDateFullscreen(index) {
            document.getElementById(this.slots[index]._id + '-fullscreen').focus();
        },
        async setFocusOn(elementId) {
            await this.$nextTick();
            document.getElementById(elementId).focus();
        },
        getFollowingAppointmentDaily(index) {
            if(!(index + 1 < 0 || index + 1 > this.slots.length - 1)) {
                return this.getAppointmentsToday(this.slots[index + 1]._id);
            } else {
                return null;
            }
        },
        getPreviousAppointmentDaily(index) {
            if(!(index - 1 < 0 || index - 1 > this.slots.length - 1)) {
                return this.getAppointmentsToday(this.slots[index - 1]._id);
            } else {
                return null;
            }
        },
        getAccessibilityTime(time) {
            let charArray = time.toString().split('');
            if(charArray[0] === '0' && charArray[1] === '0') {
                return '';
            } else if(charArray[0] === '0' && charArray[1] !== '0') {
                return charArray[1];
            } else {
                return charArray[0] + charArray[1];
            }
        }
    },
};
</script>

<style lang="scss" scoped>
table, th, td {
    border: none;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

th {
    vertical-align: top;
}

table tr th:first-child,
table tr td:first-child {
    padding-top: 10px;
    width: 10px !important;
}

table tr th:last-child,
table tr td:last-child {
    width: 5px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table, tr, td {
    border: none;
}

h1 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

h2 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

.fach {
    cursor: pointer;
}

.whiteBg {
    background-color: #ffffff;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.iconToBlue {
    filter: invert(61%) sepia(43%) saturate(515%) hue-rotate(175deg) brightness(87%) contrast(82%);
}

.scroll-area {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
}

.widgetContainer {
    height: auto;
    width: 100%;
    min-height: 80vh;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto auto 5em;
    display: flex;
    flex-direction: column;
}

.planWidgetDisplayDate {
    display: inline-block;
    font-weight: normal;
    font-size: large;
}

.planWidgetTodayButtonActive {
    background-color: #6995CD !important;
    color: white;
    border-radius: 8px;
}

.planWidgetTodayButton {
    background-color: var(--v-hellgrau-base) !important;
    color: var(--v-dunkelgrau-base);
    border-radius: 8px;
    box-shadow: 1px 5px 5px silver;
}

.colHeader{
  color: var(--v-dunkelgrau-base);
  width: 18%;
}

.colHeaderCurrent{
  color: #6995CD;
  width: 18%;
}

.greyDayHeader{
  background-color: var(--v-hellgrau-base);
  border-radius: 10px 10px 0 0;
}

.greyDayBody{
  background-color: var(--v-hellgrau-base);
}

.centerFach {
    padding-left: 1%;
}

.centerHeader {
    display: flex;
    align-content: center;
}

@media only screen and (min-width: 901px) and (max-width: 1010px) {
    .planWidgetDisplayDate {
        font-size: medium !important;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1050px) {
    .calWeek {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .widgetContainer {
        width: 90%;
    }
}
</style>
