import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';

const defaultState = {
  subjectListInvalid: false,
  subjects: []
};

const mutations = {
  setSubjectListInvalid: (state, status) => {
    state.subjectListInvalid = status;
  },
};

const getters = {
  getSubjectInvalidStatus: state => state.subjectListInvalid,
  subjects: state => state.subjects,
};

const actions = {
  setSubjectListStatus ({ commit }, status) {
    commit('setSubjectListInvalid', status);
  },
  async createSubject ({ commit, dispatch }, params) {

    try {
      const res = await backend.postSchoolSubject(params);
      await checkResponseStatus(201, res);
      const subject = await res.json();
      return subject;
    }
    catch(err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async editSubject ({ commit, dispatch }, params) {

    try {
      const id = params._id
      delete params._id;
      const res = await backend.patchSchoolSubject(id, params);
      if(res.status === 409) {
        return 409;
      }
      const subject = await res.json();
      return subject;
    }
    catch(err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async deleteSubject ({ commit, dispatch }, id) {
    try {
      const res = await backend.deleteSchoolSubject(id);
      if(res.status === 404) {
        return 404;
      }
      await checkResponseStatus(204, res);
      return true;
    }
    catch(err) {
      console.error(err)
      return false;
    }
  },

    async getSubjects ({ commit, dispatch, getters }, update = false) {

    try {
        if(getters.subjects && getters.subjects.length > 0 && !update)
        return getters.subjects;
        const res = await backend.getSchoolSubjects();
        await checkResponseStatus(200, res);
        const subjects = await res.json();
        return subjects;
    }
    catch(err) {
        console.error(err);
            return err?.response?.status;
    }
    },

    async getThumbnail ({ commit, dispatch }, fileId) {
        
        if(typeof fileId === undefined || !fileId || fileId === null) {
            return null;
        }
        try {
            const res = await backend.getThumbnail(fileId);
            if(res.status === 404) {
                return null;
            }
            const thumbnail = await res.blob();
            // 
            return thumbnail;
        }
        catch(err) {
            // console.error(err);
            return null;
        }
    },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  getters,
  actions,
};
